import SidePanelReportPrintSettings from 'src/pages/report-print/components/side-panel/SidePanelReportPrintSettings'
import SidePanelBoxPlotChartSettings from '../../../../chart-box-plot/components/side-panel/SidePanelBoxPlotChartSettings'
import SidePanelCapabilitySummaryChartSettings from '../../../../chart-capability-summary/components/side-panel/SidePanelCapabilitySummaryChartSettings'
import SidePanelCorrelationChartSettings from '../../../../chart-correlation/components/side-panel/SidePanelCorrelationChartSettings'
import SidePanelHistogramChartSettings from '../../../../chart-histogram/components/side-panel/SidePanelHistogramChartSettings'
import SidePanelLinearRegressionChartSettings from '../../../../chart-linear-regression/components/side-panel/SidePanelLinearRegressionChartSettings'
import SidePanelParetoChartSettings from '../../../../chart-pareto/components/side-panel/SidePanelParetoChartSettings'
import SidePanelPositionalTolerancesChartSettings from '../../../../chart-positional-tolerances/components/side-panel/SidePanelPositionalTolerancesChartSettings'
import SidePanelProbabilityPlotChartSettings from '../../../../chart-probability-plot/components/side-panel/SidePanelProbabilityPlotChartSettings'
import SidePanelQualityControlChartSettings from '../../../../chart-quality-control/components/side-panel/SidePanelQualityControlChartSettings'
import SidePanelValueChartSettings from '../../../../chart-value/components/side-panel/SidePanelValueChartSettings'
import SidePanelSummaryListSettings from '../../../../list-summary/components/side-panel/SidePanelSummaryListSettings'
import SidePanelValueSummaryListSettings from '../../../../list-value-summary/components/side-panel/SidePanelValueSummaryListSettings'
import SidePanelValueListSettings from '../../../../list-value/components/side-panel/SidePanelValueListSettings'
import SidePanelMainSettings from '../../../../monitoring/components/side-panel/SidePanelMonitoringSettings'
import SidePanelOverviewSettings from '../../../../overview/components/side-panel/SidePanelOverviewSettings'
import SidePanelDecisionTreeSettings from '../../../../chart-decision-tree/components/side-panel/SidePanelDecisionTreeSettings'
import './SidePanelDQSettingsForm.scss'
import SidePanelScrollBoardSettings from 'src/pages/scroll-board/components/side-panel/SidePanelScrollBoardSettings'
import { ScrollView } from 'devextreme-react'
import SidePanelDatabaseSettings from 'src/pages/database/components/side-panel/SidePanelDatabaseSettings'
import SidePanelReportPrintMultiFunctionSettings from 'src/pages/report-print-multi-function/components/side-panel/SidePanelReportPrintMultiFunctionSettings'

export default function SidePanelDQSettingsForm(props) {
  const { pageKey } = props

  return (
    <>
      {pageKey === 'database' && <SidePanelDatabaseSettings pageKey={pageKey} />}
      {pageKey === 'overview' && <SidePanelOverviewSettings pageKey={pageKey} />}
      {pageKey === 'monitoring' && <SidePanelMainSettings pageKey={pageKey} />}
      {pageKey === 'valueChart' && <SidePanelValueChartSettings pageKey={pageKey} />}
      {pageKey === 'histogramChart' && <SidePanelHistogramChartSettings pageKey={pageKey} />}
      {pageKey === 'positionalTolerancesChart' && <SidePanelPositionalTolerancesChartSettings pageKey={pageKey} />}
      {pageKey === 'boxPlotChart' && <SidePanelBoxPlotChartSettings pageKey={pageKey} />}
      {pageKey === 'linearRegressionChart' && <SidePanelLinearRegressionChartSettings pageKey={pageKey} />}
      {pageKey === 'correlationChart' && <SidePanelCorrelationChartSettings pageKey={pageKey} />}
      {pageKey === 'qualityControlChart' && <SidePanelQualityControlChartSettings pageKey={pageKey} />}
      {pageKey === 'capabilitySummaryChart' && <SidePanelCapabilitySummaryChartSettings pageKey={pageKey} />}
      {pageKey === 'paretoChart' && <SidePanelParetoChartSettings pageKey={pageKey} />}
      {pageKey === 'probabilityPlotChart' && <SidePanelProbabilityPlotChartSettings pageKey={pageKey} />}
      {pageKey === 'summaryList' && <SidePanelSummaryListSettings pageKey={pageKey} />}
      {pageKey === 'valueSummaryList' && <SidePanelValueSummaryListSettings pageKey={pageKey} />}
      {pageKey === 'valueList' && <SidePanelValueListSettings pageKey={pageKey} />}
      {pageKey === 'scrollBoard' && <SidePanelScrollBoardSettings pageKey={pageKey} />}
      {pageKey === 'reportPrint' && <SidePanelReportPrintSettings pageKey={pageKey} />}
      {pageKey === 'decisionTreeChart' && <SidePanelDecisionTreeSettings pageKey={pageKey} />}
      {pageKey === 'reportPrintMultiFunction' && <SidePanelReportPrintMultiFunctionSettings pageKey={pageKey} />}
    </>
  )
}
