import { Button, TreeList } from 'devextreme-react'
import './PushSettingsRibbonTreeList.scss'
import { Column, RowDragging } from 'devextreme-react/tree-list'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { batch } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import produce from 'immer'

const makeTreeListDataSource = (userRole, items, entireItems) => {
  const treeListDataSource = []
  const firstDepth = items.filter((item) => item.parent === '')
  const secondDepth = items.filter((item) => item.parent !== '')

  let i = 0
  firstDepth.forEach((firstEl) => {
    const targetItem = entireItems.find((item) => item.name === firstEl.name)

    if (!targetItem) {
      return
    }

    const name = firstEl.name.charAt(0).toUpperCase() + firstEl.name.slice(1)
    const label = dqTranslateMsg(`Navigation_${name}`)
    treeListDataSource.push({
      id: i++,
      parent_id: -1,
      name: firstEl.name,
      icon: targetItem.icon,
      label: label,
      isDataNeed: firstEl.isDataNeed,
      type: firstEl.type,
      // orderNo: element.orderNo,
    })

    const subItems = secondDepth.filter((secondEl) => secondEl.parent === firstEl.name)

    if (subItems) {
      subItems.forEach((subItem) => {
        const targetItem = entireItems.find((item) => item.name === subItem.name)
        const name = subItem.name.charAt(0).toUpperCase() + subItem.name.slice(1)
        const label = dqTranslateMsg(`Navigation_${name}`)

        const targetParent = treeListDataSource.find((item) => item.name === subItem.parent)

        treeListDataSource.push({
          id: i++,
          parent_id: targetParent.id,
          name: subItem.name,
          icon: targetItem.icon,
          label: label,
          isDataNeed: subItem.isDataNeed,
          parent: subItem.parent,
          type: subItem.type,
          // orderNo: subItem.orderNo,
        })
      })
    }
  })

  return treeListDataSource
}

const makeTreeListDataSourceToSavedItems = (treeListDataSource) => {
  // Helper function to find a tree item by ID
  const findTreeItemById = (id) => {
    return treeListDataSource.find((item) => item.id === id)
  }

  return treeListDataSource.map((treeItem) => {
    const parentItem = treeItem.parent_id === -1 ? null : findTreeItemById(treeItem.parent_id)
    return {
      name: treeItem.name,
      isDataNeed: treeItem.isDataNeed,
      parent: parentItem ? parentItem.name : '',
      type: treeItem.type,
    }
  })
}

const PushSettingsRibbonTreeList = ({ title, userRole, savedItems, entireItems, setSavedItems }) => {
  const [dataSource, setDataSource] = useState([])
  const [expandedRowKeys, setExpendedRowKeys] = useState([])

  useEffect(() => {
    if (savedItems && entireItems) {
      const newDataSource = makeTreeListDataSource(userRole, savedItems, entireItems)
      const newExpandedRowKeys = [...new Set(newDataSource.filter((item) => item.type === 'directory').map((item) => item.id))]
      batch(() => {
        setDataSource(newDataSource)
        setExpendedRowKeys(newExpandedRowKeys)
      })
    }
  }, [userRole, savedItems, entireItems])

  const onTreeListReorder = useCallback(
    (e) => {
      const visibleRows = e.component.getVisibleRows()
      let sourceData = e.itemData
      const updatedEmployees = [...dataSource]
      const sourceIndex = updatedEmployees.indexOf(sourceData)

      if (e.dropInsideItem) {
        sourceData = { ...sourceData, parent_id: visibleRows[e.toIndex].name }
        updatedEmployees.splice(sourceIndex, 1)
        updatedEmployees.splice(e.toIndex, 0, sourceData)
      } else {
        const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex
        let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null

        // 하위는 page type 이 directory 에만 넣을수있도록 한다.
        if (sourceData && targetData) {
          if (!((sourceData.type === 'directory' && targetData.type === 'page') || (sourceData.type === 'directory' && targetData.type === 'directory'))) {
            if (targetData && e.component.isRowExpanded(targetData.id)) {
              sourceData = { ...sourceData, parent_id: targetData.id }
              targetData = null
            } else {
              const headId = targetData ? targetData.parent_id : -1
              if (sourceData.parent_id !== headId) {
                sourceData = { ...sourceData, parent_id: headId }
              }
            }

            updatedEmployees.splice(sourceIndex, 1)
            const targetIndex = updatedEmployees.indexOf(targetData) + 1
            updatedEmployees.splice(targetIndex, 0, sourceData)
          }
        }
      }

      setDataSource(updatedEmployees)
      const items = makeTreeListDataSourceToSavedItems(updatedEmployees)
      setSavedItems(items)
    },
    [dataSource]
  )

  const onIconCellRender = (e) => {
    const isDevExpressIcon = e.data.name === 'uploadFile'
    if (isDevExpressIcon) {
      return (
        <i
          class={`dx-icon-${e.data.icon}`}
          style={{
            fontSize: '30px',
            // textAlign: 'center',
          }}
        ></i>
      )
    } else {
      return <img style={{ width: '30px', height: '30px' }} src={e.data.icon} />
    }
  }

  const typeTranslateCellRender = (e) => {
    let str = ''
    if (e.displayValue === 'page') {
      str = dqTranslateMsg('Page_AdminSettings_' + 'Page')
    } else if (e.displayValue === 'directory') {
      str = dqTranslateMsg('Page_AdminSettings_' + 'Directory')
    } else {
      str = dqTranslateMsg('Page_AdminSettings_' + 'None')
    }

    return <div>{str}</div>
  }

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '4%',
          fontSize: '20px',
        }}
      >
        {/* {dqTranslateMsg('Page_Configuration_' + 'PartField')} */}
        {title}
      </div>
      <TreeList
        id="hierarchyTreeList"
        style={{ width: '100%', height: '96%' }}
        dataSource={dataSource}
        rootValue={-1}
        expandedRowKeys={expandedRowKeys}
        keyExpr="id"
        parentIdExpr="parent_id"
        paging={{ enabled: false }}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        rowAlternationEnabled={false}
        hoverStateEnabled={true}
        scrolling={{
          columnRenderingMode: 'standard',
          rowRenderingMode: 'standard',
          mode: 'standard',
          showScrollbar: 'onHover',
          useNative: false,
        }}
        noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
      >
        <RowDragging
          // onDragChange={onDragChange}
          onReorder={onTreeListReorder}
          allowDropInsideItem={true}
          allowReordering={true}
          showDragIcons={true}
        />
        <Column dataField="icon" caption={'Symbol'} alignment="center" width={'auto'} cellRender={onIconCellRender} />
        <Column dataField="label" alignment="center" />
        <Column dataField="type" alignment="center" width={'20%'} cellRender={typeTranslateCellRender} />
      </TreeList>
    </Fragment>
  )
}
export default PushSettingsRibbonTreeList
