import React from 'react'
import { useTheme } from 'src/contexts/theme'

function PageWarningMessage({ fontSize = 20, message }) {
  const { getTheme } = useTheme()
  const curTheme = getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'
  const msgColor = curTheme === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // Ensures the content takes the full height of the viewport
        width: '100%', // Ensures the content takes the full width of the container
        textAlign: 'center',
      }}
    >
      <img src={'/images/color/warning-exclamation.svg'} width="30" height="30" alt="Warning" />
      <div style={{ fontSize: `${fontSize}px`, color: msgColor, marginLeft: '10px' }}>{message}</div>
    </div>
  )
}

export default PageWarningMessage
