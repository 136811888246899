// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-form-container {
  width: 100%;
  height: 100%;
}
.admin-form-container .grid-tab-container {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/AdminForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":[".admin-form-container {\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  .grid-tab-container {\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
