import { FilterBuilder } from 'devextreme-react'
import { Button } from 'devextreme-react'
import { FilterOperationDescriptions } from 'devextreme-react/filter-builder'
import { SimpleItem } from 'devextreme-react/form'
import { dqTranslateMsg } from '../dq-convert/DQLanguage'

const AdvancedFilterItem = ({ colSpan, fields, value, maxGroupLevel, onValueChanged, onContentReady }) => {
  const filterOperationDescriptions = {
    contains: dqTranslateMsg('SidePanel_' + 'Contains'),
    equal: dqTranslateMsg('SidePanel_' + 'Equal'),
  }

  return (
    <SimpleItem colSpan={colSpan}>
      <div style={{ maxWidth: '100%', overflow: 'auto' }}>
        <FilterBuilder
          fields={fields}
          value={value}
          maxGroupLevel={maxGroupLevel}
          groupOperations={['and', 'or']}
          filterOperationDescriptions={filterOperationDescriptions}
          onValueChanged={onValueChanged}
          width={'100%'}
          onContentReady={onContentReady}
        />
      </div>
    </SimpleItem>
  )
}

export default AdvancedFilterItem
