// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.dx-theme-background-color .push-settings-permissions-main-container {
  width: 100%;
  height: 96%;
  display: flex;
  flex-direction: row;
}
.dx-theme-background-color .push-settings-permissions-control-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 4%;
}
.dx-theme-background-color .push-settings-permissions-control-container .button-container {
  width: 120px;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/push-settings/data/components/permissions/PushSettingsPermissions.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AACJ;AAAI;EACI,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AAER;AACI;EACI,aAAA;EACA,yBAAA;EACA,WAAA;EACA,UAAA;AACR;AACQ;EACI,YAAA;EACA,YAAA;AACZ","sourcesContent":[".dx-theme-background-color {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    .push-settings-permissions-main-container {\r\n        width: 100%;\r\n        height: 96%;\r\n        display: flex;\r\n        flex-direction: row; // 세로로 배치, 가로로 배치하려면 'row'로 변경\r\n    }\r\n\r\n    .push-settings-permissions-control-container {\r\n        display: flex;\r\n        justify-content: flex-end;\r\n        width: 100%;\r\n        height: 4%;\r\n\r\n        .button-container {\r\n            width: 120px;\r\n            height: 100%;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
