import { ColorBox, Form, ScrollView } from 'devextreme-react'
import { Button } from 'devextreme-react/button'
import { NumberBox, Button as NumberBoxButton } from 'devextreme-react/number-box'
import SelectBox from 'devextreme-react/select-box'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { histogramChartSettingsPrm } from 'src/pages/chart-histogram/components/settings/HistogramChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import * as sd from '../../../components/side-panel-form/component/define/SettingsDefine'
import DQToast from 'src/functions/DQToast'
import DQSaveReset from 'src/functions/DQSaveReset'
import ThemeBarColor from './components/theme/ThemeBarColor'
import ThemeQuantileLimit from './components/theme/ThemeQuantileLimit'
import ThemeAverageLimit from './components/theme/ThemeAverageLimit'
import ThemeSpecificationLimit from './components/theme/ThemeSpecificationLimit'
import ThemeBellCurveColor from './components/theme/ThemeBellCurveColor'
import './SidePanelHistogramChartTheme.scss'
import ThemePlausibilityLimit from './components/theme/ThemePlausibilityLimit'
import ThemeScrapLimit from './components/theme/ThemeScrapLimit'
import ThemeAcceptanceLimit from './components/theme/ThemeAcceptanceLimit'

export default function SidePanelHistogramChartTheme(props) {
  const { pageKey } = props
  const dispatch = useDispatch()

  const [scrollPosition, setScrollPosition] = useState(0)
  const { user } = useAuth()
  const parentRef = useRef(null)
  const toastRef = useRef(null)

  const common = useSelector((state) => state.common)
  const curPage = common.pages.histogramChart
  const configPage = common.pages.configuration

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }
  const handleSaveButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }
  const handleResetButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.entries(histogramChartSettingsPrm.barChart).forEach(([key, value]) => {
        draft.barChart[key] = value
      })
      Object.entries(histogramChartSettingsPrm.lineChart).forEach(([key, value]) => {
        draft.lineChart[key] = value
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  const handleScroll = (e) => {
    setScrollPosition(e.scrollOffset.top)
  }

  const items = (pageState, configState) => [
    ThemeBarColor(pageKey, pageState, configState),
    ThemeBellCurveColor(pageKey, pageState, configState),
    ThemeSpecificationLimit(pageKey, pageState, configState),
    ThemeQuantileLimit(pageKey, pageState, configState),
    ThemeAverageLimit(pageKey, pageState, configState),
    ThemePlausibilityLimit(pageKey, pageState, configState),
    ThemeScrapLimit(pageKey, pageState, configState),
    ThemeAcceptanceLimit(pageKey, pageState, configState),
  ]

  return (
    <div ref={parentRef} style={{ padding: '5px', width: '100%', height: '100%' }}>
      <DQToast ref={toastRef} />
      <div style={{ width: '100%', height: '3%' }}>
        <DQSaveReset
          saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
          resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
          saveCallback={handleSaveButtonClicked}
          resetCallback={handleResetButtonClicked}
        />
      </div>
      <div style={{ width: '100%', height: '97%' }}>
        <ScrollView
          className="side-panel-tab-container"
          showScrollbar={'onHover'}
          useNative={false}
          onScroll={handleScroll}
          onContentReady={(e) => {
            e.component.scrollTo({ top: scrollPosition })
          }}
        >
          <Form id="side-panel-histogram-chart-theme-form" style={{ paddingTop: '5px' }} labelMode={'outside'} formData={curPage.page} showColonAfterLabel={false}>
            {items(curPage, configPage)}
          </Form>
        </ScrollView>
      </div>
    </div>
  )
}
