class DTOAdminCompany {
    constructor({
        company = null,
        plant = null,
        company_admin_config_1 = null,
        company_admin_config_2 = null,
        company_admin_config_3 = null,
        company_admin_config_4 = null,
        company_admin_config_5 = null,
        company_admin_config_6 = null,
        company_admin_config_7 = null,
        company_admin_config_8 = null,
        company_admin_config_9 = null,
        company_admin_config_10 = null,
        company_admin_config_11 = null,
        company_admin_config_12 = null,
        company_admin_config_13 = null,
        company_admin_config_14 = null,
        company_admin_config_15 = null,
        company_admin_config_16 = null,
        company_admin_config_17 = null,
        company_admin_config_18 = null,
        company_admin_config_19 = null,
        company_admin_config_20 = null,
    } = {}) {
        Object.assign(this, {
            company,
            plant,
            company_admin_config_1,
            company_admin_config_2,
            company_admin_config_3,
            company_admin_config_4,
            company_admin_config_5,
            company_admin_config_6,
            company_admin_config_7,
            company_admin_config_8,
            company_admin_config_9,
            company_admin_config_10,
            company_admin_config_11,
            company_admin_config_12,
            company_admin_config_13,
            company_admin_config_14,
            company_admin_config_15,
            company_admin_config_16,
            company_admin_config_17,
            company_admin_config_18,
            company_admin_config_19,
            company_admin_config_20,
        })
    }
}

export default DTOAdminCompany
