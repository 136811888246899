import React from 'react'
import { EmptyItem, GroupItem, SimpleItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
export function RectChartTheme(props) {
  const { pageKey, pageState, configState, noTitle } = props
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  const sbPageSettings = pageState

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onCheckBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onColorBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={'rect-chart-theme-group-item'} colCount={4} caption={!noTitle && dqTranslateMsg('SidePanel_' + 'TreeMapChart')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'FontSize'),
        labelLocation: 'top',
        name: 'treeMapChartFontSize',
        min: 1,
        max: 30,
        step: 1,
        value: sbPageSettings.theme.treeMapChartFontSize,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'treeMapChartFontSize'),
      })}
      <EmptyItem />
      <EmptyItem />
      <EmptyItem />
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 4,
        label: dqTranslateMsg('SidePanel_' + 'TileFontColorCustomEnable'),
        labelLocation: 'right',
        name: 'treeMapChartFontColorCustomize',
        value: sbPageSettings.theme.treeMapChartFontColorCustomize,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'treeMapChartFontColorCustomize'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'TileFontColor'),
        labelLocation: 'top',
        name: 'treeMapChartFontColor',
        value: sbPageSettings.theme.treeMapChartFontColor,
        disabled: !sbPageSettings.theme.treeMapChartFontColorCustomize,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'treeMapChartFontColor'),
      })}
      <EmptyItem />
      <EmptyItem />
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'NgSizeRelative'),
        labelLocation: 'top',
        name: 'treeMapChartNGRectSize',
        min: 1,
        max: 30,
        step: 0.1,
        value: sbPageSettings.theme.treeMapChartNGRectSize,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'treeMapChartNGRectSize'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'WarnSizeRelative'),
        labelLocation: 'top',
        name: 'treeMapChartWarnRectSize',
        min: 1,
        max: 30,
        step: 0.1,
        value: sbPageSettings.theme.treeMapChartWarnRectSize,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'treeMapChartWarnRectSize'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'GoodSizeRelative'),
        labelLocation: 'top',
        name: 'treeMapChartGoodRectSize',
        min: 1,
        max: 30,
        step: 0.1,
        value: sbPageSettings.theme.treeMapChartGoodRectSize,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'treeMapChartGoodRectSize'),
      })}
    </GroupItem>
  )
}
