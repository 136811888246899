import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

export default function ThemeDefectUnitOption() {
  const dispatch = useDispatch()
  const pageKey = 'paretoChart'

  const pageState = useSelector((state) => state.common.pages[pageKey])

  const onCheckBoxDefectUnitStatusColorValueChanged = (e, type, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.barChart[type][dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`defect-unit-option-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DefectUnitBarColor')}>
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'NGColor'),
        labelLocation: 'top',
        name: 'ngColor',
        value: pageState.barChart.error.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onCheckBoxDefectUnitStatusColorValueChanged(e, 'error', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'WarnColor'),
        labelLocation: 'top',
        name: 'warnColor',
        value: pageState.barChart.warn.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onCheckBoxDefectUnitStatusColorValueChanged(e, 'warn', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'GoodColor'),
        labelLocation: 'top',
        name: 'goodColor',
        value: pageState.barChart.good.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onCheckBoxDefectUnitStatusColorValueChanged(e, 'good', 'color'),
      })}
    </GroupItem>
  )
}
