import { TextBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'
import React from 'react'

const TextBoxSimpleItem = ({ colSpan, ref, label, labelLocation, name, value, disabled = false, onValueChanged }) => {
  return (
    <SimpleItem colSpan={colSpan}>
      {/* <Label text={label} location={labelLocation} style={{ width: '100%' }} /> */}
      <TextBox ref={ref} name={name} label={label} labelMode={labelLocation} value={value} disabled={disabled} onValueChanged={onValueChanged} />
    </SimpleItem>
  )
}

export default TextBoxSimpleItem
