import { GroupItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'

const DBFieldSaveFilter = (pageKey, pageState, configState) => {
    const dispatch = useDispatch()
    const filterNameTbRef = useRef(null)

    const onApplyButtonClick = () => {
        console.log('applied')

        if (filterNameTbRef.current) {
            const filterName = filterNameTbRef.current.instance.option('value')
            if (filterName === '') {
                alert('Please Enter Filter Name')
                return
            }

            const filterFormat = {
                filterName,
                common: pageState.filters.quick.common,
                others: pageState.filters.quick.others,
                data: pageState.filters.quick.data,
            }

            const sameNameFilter = othersPage.databaseSavedQuickFilterList.find((filter) => filter.filterName === filterFormat.filterName)
            if (sameNameFilter) {
                alert('same name filter is exist')
                return
            }

            const next = produce(pageState, (draft) => {
                draft.filters.quick.savedFilters.push(filterFormat)
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }
    }

    const onClearButtonClick = () => {
        console.log('clear')
        const next = produce(pageState, (draft) => {
            draft.filters.quick.savedFilters = []
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <GroupItem
            key={`db-field-quick-save-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'SaveFilter')}
        >
            {TextBoxNoValueSimpleItem({
                colSpan: 2,
                label: dqTranslateMsg('SidePanel_' + 'FilterName'),
                labelLocation: 'top',
                ref: filterNameTbRef,
            })}
            {ButtonSimpleItem({
                colSpan: 1,
                type: 'normal',
                text: dqTranslateMsg('SidePanel_' + 'Apply'),
                icon: 'check',
                width: '100%',
                height: '100%',
                onClick: onApplyButtonClick,
            })}
            {ButtonSimpleItem({
                colSpan: 1,
                type: 'normal',
                text: dqTranslateMsg('SidePanel_' + 'Clear'),
                icon: 'clear',
                width: '100%',
                height: '100%',
                onClick: onClearButtonClick,
            })}
        </GroupItem>
    )
}

export default DBFieldSaveFilter
