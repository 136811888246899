// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-panel-filter-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.side-panel-filter-container .side-panel-filter-container::-webkit-scrollbar {
  width: 5px;
}
.side-panel-filter-container .side-panel-filter-container::-webkit-scrollbar-thumb {
  background-color: #3e4451;
}
.side-panel-filter-container .side-panel-filter-container::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
.side-panel-filter-container .button-container {
  width: 100%;
  height: 30px;
}

#side-panel-form .dx-form-group-with-caption {
  padding-top: 5px;
  border-top: 1px solid #515159;
}

#side-panel-form .dx-form-group-content {
  border-top: 0px solid #515159;
  padding: 0px;
  padding-left: 0px;
}

#side-panel-form .dx-field-item {
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
}

#side-panel-form .dx-field-button-item {
  padding-left: 2px;
  padding-right: 2px;
  width: "100%";
  height: "100%";
}

#side-panel-form .dx-field-item-content {
  width: auto;
  flex-grow: 0;
}`, "",{"version":3,"sources":["webpack://./src/template/side-panel/SidePanelTemplate.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,UAAA;AACJ;AACE;EACE,yBAAA;AACJ;AACE;EACE,wCAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,6BAAA;AADF;;AAIA;EACE,6BAAA;EACA,YAAA;EACA,iBAAA;AADF;;AAIA;EACE,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADF;;AAIA;EACE,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;AADF","sourcesContent":[".side-panel-filter-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow-y: auto;\r\n\r\n  .side-panel-filter-container::-webkit-scrollbar {\r\n    width: 5px;\r\n  }\r\n  .side-panel-filter-container::-webkit-scrollbar-thumb {\r\n    background-color: #3e4451;\r\n  }\r\n  .side-panel-filter-container::-webkit-scrollbar-track {\r\n    background-color: rgba(255, 255, 255, 0);\r\n  }\r\n\r\n  .button-container {\r\n    width: 100%;\r\n    height: 30px;\r\n  }\r\n}\r\n\r\n#side-panel-form .dx-form-group-with-caption {\r\n  padding-top: 5px;\r\n  border-top: 1px solid #515159;\r\n}\r\n\r\n#side-panel-form .dx-form-group-content {\r\n  border-top: 0px solid #515159;\r\n  padding: 0px;\r\n  padding-left: 0px;\r\n}\r\n\r\n#side-panel-form .dx-field-item {\r\n  padding-left: 2px;\r\n  padding-right: 2px;\r\n  padding-top: 3px;\r\n  padding-bottom: 3px;\r\n}\r\n\r\n#side-panel-form .dx-field-button-item {\r\n  padding-left: 2px;\r\n  padding-right: 2px;\r\n  width: '100%';\r\n  height: '100%';\r\n}\r\n\r\n#side-panel-form .dx-field-item-content {\r\n  width: auto;\r\n  flex-grow: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
