import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

class CatalogUtils {
  static validCatalog = ['v0005', 'v0007', 'v0008', 'v0010', 'v0011', 'v0012']
  static validCatalogNoPreFix = ['5', '7', '8', '10', '11', '12']

  static convertByCatalog(type, value, catalogs) {
    if (!this.validCatalog.includes(type) && catalogs) {
      return value
    }

    if (value === null || value === 0 || value === '0') {
      return 'Unknown'
    }

    if (catalogs?.hasOwnProperty(type)) {
      const targetCatalogList = catalogs[type] // 직접 type을 키로 사용

      // 특별한 경우에만 타입 변환
      if (type === 'v0005' || type === 'v0011') {
        const target = targetCatalogList.find((item) => item.no === value)

        if (target) {
          return target.desc
        } else {
          return value
        }
        // return value !== null ? value : ''
      } else {
        // `find` 메서드를 사용해 해당하는 카탈로그 아이템을 찾음
        const target = targetCatalogList.find((item) => item.no === value)

        if (target) {
          return target.desc
        } else {
          return value
        }
      }
    } else {
      return value
    }

    // 찾은 아이템의 설명을 반환하거나, 찾지 못했다면 원래 값을 반환
    // return target ? target.desc : value
  }

  static convertByCatalogNoPrefix(type, value, catalogs) {
    const typeWithPrefix = this.convertToValidCatalog(type)
    if (!this.validCatalog.includes(typeWithPrefix) && catalogs) {
      return value
    }

    if (value === null || value === 0 || value === '0') {
      return 'Unknown'
    }

    if (catalogs?.hasOwnProperty(typeWithPrefix)) {
      const targetCatalogList = catalogs[typeWithPrefix] // 직접 type을 키로 사용

      // 특별한 경우에만 타입 변환
      if (typeWithPrefix === 'v0005' || typeWithPrefix === 'v0011') {
        const target = targetCatalogList.find((item) => item.no === value)

        if (target) {
          return target.desc
        } else {
          return value
        }
        // return value !== null ? value : ''
      } else {
        // `find` 메서드를 사용해 해당하는 카탈로그 아이템을 찾음
        const target = targetCatalogList.find((item) => item.no === Number(value))

        if (target) {
          return target.desc
        } else {
          // return Number(value)
          return value
        }
      }
    } else {
      // return Number(value)
      return value
    }
  }

  static convertToValidCatalog(item) {
    return item.length === 1 ? `v000${item}` : `v00${item}`
  }

  static isCatalogField(type) {
    return this.validCatalog.includes(type)
  }

  static isCatalogFieldNoPrefix(type) {
    return this.validCatalogNoPreFix.includes(type)
  }

  static getCatalogFieldName(type, useFieldList) {
    const allSettings = [...useFieldList.part, ...useFieldList.char, ...useFieldList.value]
    const foundSetting = allSettings.find((setting) => setting.value === type)
    return foundSetting ? dqTranslateMsg('Db_field_Value_' + foundSetting.text) : 'Not Fount'
  }
}

export default CatalogUtils
