// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 50px);
}
.admin-container .admin-content-container {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/admin.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,0BAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":[".admin-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  height: calc(100vh - 50px);\r\n\r\n  .admin-content-container {\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
