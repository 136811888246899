// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  padding-top: 0px;
  padding-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,+BAAA;EACA,gBAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"../../themes/generated/variables.base.scss\";\r\n\r\n.footer {\r\n  display: block;\r\n  color: rgba($base-text-color, alpha($base-text-color) * 0.7);\r\n  padding-top: 0px;\r\n  padding-bottom: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
