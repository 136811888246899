// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    versionInfo: {},
    partInfoAll: [],
    partInfo: [],
    charInfoAll: [],
    charInfo: [],
    valueInfo: [],
}

export const DBDataSlice = createSlice({
    name: 'chartRawData',
    initialState,
    reducers: {
        setChartRowInitialization: (state, action) => {
            return initialState
        },
        setAllDataInfo: (state, action) => {
            state.versionInfo = action.payload.versionInfo
            state.partInfoAll = [...action.payload.partInfoAll]
            state.partInfo = [...action.payload.partInfo]
            state.charInfoAll = [...action.payload.charInfoAll]
            state.charInfo = [...action.payload.charInfo]
            state.valueInfo = [...action.payload.valueInfo]
        },
        setPartInfoAll: (state, action) => {
            state.partInfoAll = [...action.payload]
        },
        setPartInfo: (state, action) => {
            state.partInfo = [...action.payload]
        },
        setCharInfoAll: (state, action) => {
            state.charInfoAll = [...action.payload]
        },
        setCharInfo: (state, action) => {
            state.charInfo = [...action.payload]
        },
        setValueInfo: (state, action) => {
            state.valueInfo = [...action.payload]
        },
    },
})

// export const { setSelectedPartInfo, setSelectedCharInfo, setSelectedValueInfo, setAllDataInfo, clearAllDataInfo } = DBDataSlice.actions
export const { setChartRowInitialization, setAllDataInfo, setPartInfoAll, setPartInfo, setCharInfoAll, setCharInfo, setValueInfo } = DBDataSlice.actions

export default DBDataSlice.reducer
