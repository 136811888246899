import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export const getOverviewPieChartOption = (settings, errCnt, warnCnt, goodCnt, noneCnt) => {
  return {
    title: {
      text: dqTranslateMsg('PartOverview'),
      left: 'center',
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'item',
      appendToBody: true,
      textStyle: {
        fontFamily: 'Arial',
      },
    },

    legend: {
      orient: 'vertical',
      left: 'left',
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: [
          {
            value: goodCnt,
            name: dqTranslateMsg('Page_Overview_' + 'Good'),
            label: { fontFamily: 'Arial', formatter: '{b} / {c} / {d}%' },
            itemStyle: {
              color: settings.pieChart.good.color,
            },
          },
          {
            value: warnCnt,
            name: dqTranslateMsg('Page_Overview_' + 'Warning'),
            label: { fontFamily: 'Arial', formatter: '{b} / {c} / {d}%' },
            itemStyle: {
              color: settings.pieChart.warn.color,
            },
          },
          {
            value: errCnt,
            name: dqTranslateMsg('Page_Overview_' + 'NG'),
            label: { fontFamily: 'Arial', formatter: '{b} / {c} / {d}%' },
            itemStyle: {
              color: settings.pieChart.error.color,
            },
          },
          {
            value: noneCnt,
            name: dqTranslateMsg('Page_Overview_' + 'None'),
            label: { fontFamily: 'Arial', formatter: '{b} / {c} / {d}%' },
            itemStyle: {
              color: settings.pieChart.none.color,
            },
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: settings.pieChart.none.color,
          },
        },
      },
    ],
  }
}
