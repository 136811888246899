export const decisionTreeChartSettingsPrm = {
  page: {
    importanceType: 'Cart',
    importanceDisplayCount: 'all', // 0 : all , 1, top10, 2: top5, 3: top:3
    shiftUpField: [],
    srcColumns: [],
    srcTarget: '',
    useAutoCalc: false, // true false
    useAddedInfoTopChar: true,
    advanced: {
      // it will use when useAutoCalc == false
      trainSize: 0.5, // 0~1
      useMaxDepth: true, // true false
      maxDepth: 10, // 1~10000
      useRandomState: true, // true false
      randomState: 100, // 1~100
      criterion: 'gini',
      splitter: 'best',
      shuffle: false, // true false
    },
  },
}
