import ReactECharts from 'echarts-for-react'
import { useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { useLanguage } from 'src/contexts/languages'
import { useTheme } from 'src/contexts/theme'
import CPUtils from 'src/functions/CommonPageUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './PositionalTolerancesChartForm.scss'
import { getPositionalTolerancesChartOption } from './chart-option/PositionalTolerancesChartOption'
import { getXAxisChartOption } from './chart-option/XAxisChartOption'
import { getYAxisChartOption } from './chart-option/YAxisChartOption'

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(PositionalTolerancesChartForm)

function PositionalTolerancesChartForm({ pageKey, common, chartRawData }) {
    const { getTheme } = useTheme()
    const { language } = useLanguage()

    // For initialRendering
    const isInitialRender = useRef(true)

    const ptChartRef = useRef(null)
    const xAxisRef = useRef(null)
    const yAxisRef = useRef(null)

    const { parts, chars, values } = useMemo(() => CPUtils.loadRefChartData(chartRawData), [chartRawData])

    const { charArray, curPage, configPage, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const othersPage = common.pages.others

    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)

    const numCharts = chars.length // Specify the number of charts you want to display
    const ptChartRefs = new Array(numCharts).fill(null).map(() => useRef({ element: null, chartElement: null, id: null }))

    const widthPerChart = `${100 / curPage.page.layout.column}%`
    const heightPerChart = `${100 / curPage.page.layout.row}%`

    let options = []
    if (charArray.length >= 3) {
        let ptTargetValue
        let xAxisTargetValue
        let yAxisTargetValue

        for (let idx = 0; idx < charArray.length; idx = idx + 3) {
            const info = {
                part_id: charArray[idx].part_id,
                char_id: charArray[idx].char_id,
            }
            ptTargetValue = valuesArranged.find((value) => value.part_id === charArray[idx].part_id && value.char_id === charArray[idx].char_id)
            xAxisTargetValue = valuesArranged.find((value) => value.part_id === charArray[idx + 1].part_id && value.char_id === charArray[idx + 1].char_id)
            yAxisTargetValue = valuesArranged.find((value) => value.part_id === charArray[idx + 2].part_id && value.char_id === charArray[idx + 2].char_id)

            const ptOption = getPositionalTolerancesChartOption(
                curPage,
                configPage,
                charArray[idx],
                charArray[idx + 1],
                charArray[idx + 2],
                xAxisTargetValue.valueRaw.filter((item) => item.v0002 === 0),
                yAxisTargetValue.valueRaw.filter((item) => item.v0002 === 0)
            )
            const xAxisOption = getXAxisChartOption(
                curPage,
                configPage,
                othersPage,
                charArray[idx + 1],
                xAxisTargetValue.valueRaw.filter((item) => item.v0002 === 0)
            )

            const yAxisOption = getYAxisChartOption(
                curPage,
                configPage,
                othersPage,
                charArray[idx + 2],
                yAxisTargetValue.valueRaw.filter((item) => item.v0002 === 0)
            )
            options.push({ ptOption, xAxisOption, yAxisOption, info })
        }
    }

    useEffect(() => {
        const targetRefId = `${currentSel.part_id}_${currentSel.char_id}`
        ptChartRefs.forEach((ref) => {
            if (ref.current.element && ref.current.id === targetRefId) {
                ref.current.element.classList.add('target-element')
                ref.current.element.scrollIntoView()
            } else if (ref.current.element) {
                ref.current.element.classList.remove('target-element')
            }
        })
    }, [currentSel, ptChartRefs])

    return (
        <div className='dx-theme-background-color'>
            <div className='chart-form-content'>
                {options.length > 0 ? (
                    options.map((target, index) => {
                        return (
                            <div
                                style={{
                                    width: widthPerChart,
                                    height: heightPerChart,
                                    padding: '5px',
                                }}
                            >
                                <div
                                    className='positional-tolerances-flex-container'
                                    key={`${target.info.part_id}_${target.info.char_id}`}
                                    ref={(e) => {
                                        ptChartRefs[index].current.element = e
                                        ptChartRefs[index].current.id = `${target.info.part_id}_${target.info.char_id}`
                                    }}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        padding: '10px',
                                        borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                                    }}
                                >
                                    <ReactECharts
                                        ref={ptChartRef}
                                        key={`${target.info.part_id}_${target.info.char_id}_pt-chart`}
                                        style={{ width: '50%', height: '100%' }}
                                        option={target.ptOption}
                                        theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                                        opts={{
                                            renderer: 'svg',
                                            locale: ETCUtils.checkLangForECharts(language),
                                        }}
                                    />

                                    <div style={{ width: '50%', height: '100%' }}>
                                        <ReactECharts
                                            ref={xAxisRef}
                                            key={`${target.info.part_id}_${target.info.char_id}_x-bar-chart`}
                                            style={{ width: '100%', height: '50%' }}
                                            option={target.xAxisOption}
                                            theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                                            opts={{
                                                renderer: 'svg',
                                                locale: ETCUtils.checkLangForECharts(language),
                                            }}
                                        />
                                        <ReactECharts
                                            ref={yAxisRef}
                                            key={`${target.info.part_id}_${target.info.char_id}_y-axis-chart`}
                                            style={{ width: '100%', height: '50%' }}
                                            option={target.yAxisOption}
                                            theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                                            opts={{
                                                renderer: 'svg',
                                                locale: ETCUtils.checkLangForECharts(language),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <>
                        <img
                            src={'/images/color/warning-exclamation.svg'}
                            width='30'
                            height='30'
                        />
                        <div style={{ fontSize: '20px' }}>{dqTranslateMsg('Page_PositionalTolerancesChart_' + 'DataIsNotSelected')}</div>
                    </>
                )}
            </div>
        </div>
    )
}
