import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

function ValueChartItem(props) {
    const { partId = 25, charId = 1, style, common, valuePage, othersPage } = props
    // const { partId = 25, charId = 1, style } = props

    const ref = useRef(null)
    // const common = useSelector((state) => state.common)
    // const valuePage = common.pages.valueChart
    // const othersPage = common.pages.others

    const [vcCalcData, setVcCalcData] = useState([])
    const [vcOptions, setVcOptions] = useState([])
    const [valueChartImg, setValueChartImg] = useState(null)
    const [arimaShowFlag, setArimaShowFlag] = useState(valuePage.page.arima.show)

    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray, curPage, configPage, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeInvalidInValues(selectedValueWithDummy, curPage.page.showDeletedValue)

    useEffect(() => {
        const makeValueChart = async () => {
            let tempArimaChartData = []

            const valueArray = valuesArranged.find((array) => array.part_id === partId && array.char_id === charId)
            const targetChar = valueArray.charRaw
            const targetValue = valueArray.valueRaw

            if (targetValue.length < 1) {
                tempArimaChartData.push({ targetChar, targetValue, arimaData: null })
                return
            }

            let responseData = null
            if (arimaShowFlag === false && valuePage.page.arima.show === true) {
                const { sampleSize, forecastSize } = VCUtils.adjustedForecastSampleSize(valuePage.page.arima.forecastSize, valuePage.page.arima.sampleSize, targetValue.length)
                const entireSrc = targetValue.map((value) => value.v0001)
                const sampleSrc = entireSrc.slice(-sampleSize)
                responseData = await makeArimaOption(valuePage, configPage, forecastSize, sampleSrc)
                tempArimaChartData.push({ targetChar, targetValue, arimaData: responseData })
            } else {
                if (valuePage.page.arima.show === true) {
                    const savedTarget = vcCalcData.find((data) => data.targetChar.part_id === value.part_id && data.targetChar.char_id === value.char_id)
                    if (!savedTarget) {
                        const { sampleSize, forecastSize } = VCUtils.adjustedForecastSampleSize(valuePage.page.arima.forecastSize, valuePage.page.arima.sampleSize, targetValue.length)
                        const entireSrc = targetValue.map((value) => value.v0001)
                        const sampleSrc = entireSrc.slice(-sampleSize)
                        responseData = await makeArimaOption(valuePage, configPage, forecastSize, sampleSrc)
                        tempArimaChartData.push({ targetChar, targetValue, arimaData: responseData })
                    } else {
                        tempArimaChartData.push(savedTarget)
                    }
                } else {
                    tempArimaChartData.push({ targetChar, targetValue, arimaData: null })
                }
            }

            tempArimaChartData.sort((a, b) => {
                if (a.targetChar.part_id !== b.targetChar.part_id) {
                    return a.targetChar.part_id - b.targetChar.part_id
                } else {
                    return a.targetChar.char_id - b.targetChar.char_id
                }
            })

            const valueOptions = tempArimaChartData.map((data) => {
                const targetChar = data.targetChar
                const targetValue = data.targetValue
                const arimaData = data.arimaData

                const pcSet = VCUtils.makePCData(configPage, targetChar, targetValue)
                if (pcSet === null) {
                    return { targetChar, targetValue, option: null, error: { name: 'PCSettingsError', extra: 'warn offset' } }
                }
                const chartSet = VCUtils.makeChartData(valuePage, configPage, pcSet, targetChar, targetValue)
                if (chartSet === null) {
                    return { targetChar, targetValue, option: null, error: { name: 'ChartSettingsError', extra: 'chart data' } }
                }
                let valueChartOption = VCUtils.makeChartOption(pageKey, valuePage, configPage, othersPage, pcSet, chartSet, targetChar, targetValue, getTheme())
                if (valueChartOption === null) {
                    return { targetChar, targetValue, option: null, error: { name: 'MakeChartOptionError', extra: null } }
                }

                if (valuePage.page.arima.show === true) {
                    if (arimaData === null) {
                        return { targetChar, targetValue, option: valueChartOption, error: { name: 'ArimaSettingError', extra: null } }
                    }
                    const expectFieldType = ['low_pred', 'pred', 'upper_pred']
                    const forecastFormat = { expectation: [] }
                    const minMaxValues = []

                    expectFieldType.forEach((field) => {
                        forecastFormat.expectation.push({
                            name: field,
                            data: arimaData[field],
                        })
                        minMaxValues.push(...arimaData[field])
                    })

                    const min = Math.min(...minMaxValues)
                    const max = Math.max(...minMaxValues)
                    const addDataLength = forecastFormat.expectation[0].data.length

                    const chartXAxis = valueChartOption.xAxis
                    let chartXAxisLength = chartXAxis.data.length + addDataLength
                    const chartOneSeries = valueChartOption.series.find((series) => series.name === dqTranslateMsg('Page_ValueChart_' + 'ValueInfo'))
                    let charDataLength = chartOneSeries.data.length

                    const xAxisData = []
                    for (let i = 1; i <= chartXAxisLength; i++) {
                        xAxisData.push([i])
                    }

                    const next = produce(valueChartOption, (draft) => {
                        forecastFormat.expectation.forEach((item) => {
                            let index = charDataLength + 1
                            draft.series.push({
                                type: 'line',
                                id: item.name,
                                name: arimaNameTranslate(item.name),
                                data: item.data.map((data) => [index++, data]),
                                itemStyle: {
                                    color: item.name === 'low_pred' || item.name === 'upper_pred' ? valuePage.lineChart.arimaChartLines.outer.color : valuePage.lineChart.arimaChartLines.inner.color,
                                },
                                lineStyle: {
                                    color: item.name === 'low_pred' || item.name === 'upper_pred' ? valuePage.lineChart.arimaChartLines.outer.color : valuePage.lineChart.arimaChartLines.inner.color,
                                },
                            })
                            draft.xAxis.data = xAxisData
                            draft.xAxis.min = 0
                            draft.xAxis.max = xAxisData.length

                            draft.yAxis.min = valueChartOption.yAxis.min < min ? draft.yAxis.min : min
                            draft.yAxis.max = valueChartOption.yAxis.max > max ? draft.yAxis.max : max
                        })
                    })

                    return { targetChar, targetValue, option: next, error: { name: null, extra: null } }
                } else {
                    return { targetChar, targetValue, option: valueChartOption, error: { name: null, extra: null } }
                }
            })
            setArimaShowFlag(valuePage.page.arima.show)
            setVcCalcData(tempArimaChartData)
            setVcOptions(valueOptions)
            setValueChartImg(getChartImg())
        }
        makeValueChart()
    }, [])

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {valueChartImg && (
                <Image
                    style={style}
                    src={valueChartImg}
                />
            )}

            <ReactECharts
                ref={(e) => {
                    chartRefs.current[index].element = e
                    chartRefs.current[index].id = `${vcOptions[index].part_id}_${vcOptions[index].char_id}`
                }}
                style={{ width: '300px', height: '200px', display: 'none' }}
                option={vcOptions[index].option}
                opts={{
                    renderer: 'png',
                }}
            />
        </div>
    )
}

export default ValueChartItem
