import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

const displayType = () => [
  {
    value: 'barChart',
    text: dqTranslateMsg('SidePanel_' + 'BarChart'),
  },
  {
    value: 'runChart',
    text: dqTranslateMsg('SidePanel_' + 'RunChart'),
  },
]

export default function SettingsDisplayType() {
  const dispatch = useDispatch()
  const pageKey = 'capabilitySummaryChart'

  const pageState = useSelector((state) => state.common.pages[pageKey])

  const onSelectBoxDisplayTypeValueChanged = (e, dataField) => {
    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`display-type-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DisplayType')}>
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Type'),
        labelLocation: 'top',
        name: 'displayType',
        items: displayType(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.displayType,
        disabled: false,
        onItemClick: (e) => onSelectBoxDisplayTypeValueChanged(e, 'displayType'),
      })}
    </GroupItem>
  )
}
