// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-panel-report-print-container {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.side-panel-report-print-container .side-panel-function-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.side-panel-report-print-container .dx-fieldset-header {
  margin: 5px;
  text-align: left;
}
.side-panel-report-print-container .dx-field {
  margin-bottom: 5px;
  margin-right: 10px;
  font-family: Arial;
}
.side-panel-report-print-container .dx-field-label {
  text-align: center;
}
.side-panel-report-print-container #form {
  margin-top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/report-print/components/side-panel/SidePanelReportPrintSettings.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AAEE;EACE,WAAA;EACA,gBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,kBAAA;EACA,kBAAA;AADJ;AAIE;EACE,kBAAA;AAFJ;AAKE;EACE,eAAA;AAHJ","sourcesContent":[".side-panel-report-print-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n\r\n  .side-panel-function-buttons {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 5px;\r\n  }\r\n\r\n  .dx-fieldset-header {\r\n    margin: 5px;\r\n    text-align: left;\r\n  }\r\n\r\n  .dx-field {\r\n    margin-bottom: 5px;\r\n    margin-right: 10px;\r\n    font-family: Arial;\r\n  }\r\n\r\n  .dx-field-label {\r\n    text-align: center;\r\n  }\r\n\r\n  #form {\r\n    margin-top: 0px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
