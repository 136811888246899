import ReactECharts from 'echarts-for-react'
import { batch, useSelector } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import './BoxPlotChartForm.scss'
import { getBoxPlotChartOption } from './chart-option/BoxPlotChartOption'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { useEffect, useMemo, useRef, useState } from 'react'
import CPUtils from 'src/functions/CommonPageUtils'
import { connect } from 'react-redux'
import { useLanguage } from 'src/contexts/languages'
import * as bpImpl from './chart-option/BoxPlotChartOptionImpl'

function BoxPlotChartForm({ pageKey, common, chartRawData }) {
    const { getTheme } = useTheme()
    const { language } = useLanguage()

    const boxPlotRef = useRef(null)

    const [xAxisData, setXAxisData] = useState(null)
    const [chartOptions, setChartOptions] = useState(null)
    const [highlightedIndex, setHighlightedIndex] = useState(null)

    const curPage = common.pages.boxPlotChart
    const configPage = common.pages.configuration
    const currentSel = common.current_sel
    const selectedChar = common.loaded_all[pageKey]

    // make options

    useEffect(() => {
        const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
        const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)
        const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
        const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)

        let chartOptions = null
        if (valuesArranged.length > 0) {
            const { names, decimalPlacesArray, boxPlotData, boxPlotRefData } = bpImpl.makeBoxPlotDataSet(curPage, chars, valuesArranged, configPage)
            chartOptions = getBoxPlotChartOption(curPage, configPage, chars, valuesArranged, names, decimalPlacesArray, boxPlotData, boxPlotRefData)
            batch(() => {
                setXAxisData(names)
                setChartOptions(chartOptions)
            })
        } else {
            setXAxisData(null)
            setChartOptions({})
        }
    }, [selectedChar, curPage, configPage])

    useEffect(() => {
        if (boxPlotRef && xAxisData) {
            const chartInstance = boxPlotRef.current.getEchartsInstance() // Get chart instance
            const delimiter = '\u000f'

            let targetInfo = null
            xAxisData.forEach((element, index) => {
                const xAxisArray = element.split(delimiter)
                if (currentSel.part_id === parseInt(xAxisArray[1]) && currentSel.char_id === parseInt(xAxisArray[2])) {
                    targetInfo = { index: index, name: xAxisArray[0] }
                    return
                }
            })

            chartInstance.dispatchAction({
                type: 'dataZoom',
                startValue: targetInfo.name,
                endValue: targetInfo.name,
            })

            // Clear the previous highlight
            if (highlightedIndex !== null) {
                chartInstance.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: highlightedIndex, // Remove highlight from the previously highlighted item
                })
            }

            chartInstance.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: targetInfo.index,
            })

            chartInstance.dispatchAction({
                type: 'showTip',
                seriesIndex: 0,
                dataIndex: targetInfo.index,
            })

            // Update the state with the new highlighted index
            setHighlightedIndex(targetInfo.index)
        }
    }, [currentSel])

    return (
        <div className={'dx-theme-background-color'}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    padding: '10px',
                    borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                }}
            >
                <ReactECharts
                    ref={boxPlotRef}
                    style={{ width: '100%', height: '100%' }} // Add space after subtraction operator
                    notMerge={true}
                    option={chartOptions ? chartOptions : {}}
                    theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                    opts={{
                        renderer: 'svg',
                        locale: ETCUtils.checkLangForECharts(language),
                    }}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(BoxPlotChartForm)
