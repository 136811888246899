import { useAuth } from 'src/contexts/auth'
import './profile.scss'

export default function Profile() {
  const { user } = useAuth()

  return (
    <div className="profile-container">
      <div className={'profile-content dx-theme-background-color'}>
        <div className={'profile-top-container'}></div>
        <div className={'profile-middle-container'}>
          <div className="profile-middle-img-id-container">
            <div className="profile-middle-img-container">
              <div className="form-avatar"></div>
            </div>
            <div className="profile-middle-id-container">
              <div className="profile-middle-id-content">{user.user_id}</div>
              <div className="profile-middle-name-job-content">Developer</div>
            </div>
          </div>
        </div>
        <div className={'profile-bottom-container'}></div>
      </div>
    </div>
  )
}
