import produce from 'immer'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DQToast from 'src/functions/DQToast'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'

export function handleSaveButtonClicked(dispatch, user, pageKey, curPage, settings, toastRef) {
  const next = produce(curPage, (draft) => {
    Object.keys(settings).forEach((key) => {
      draft[key] = settings[key]
    })
  })

  dqsImpl.setCurPageSettings(dispatch, user, pageKey, next)
  toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
}

export function handleResetButtonClicked(dispatch, user, pageKey, curPage, defaultSettings, toastRef) {
  const next = produce(curPage, (draft) => {
    Object.entries(defaultSettings).forEach(([key, value]) => {
      draft[key] = value
    })
  })

  dqsImpl.setCurPageSettings(dispatch, user, pageKey, next)
  toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
}

export function handleSwitchFilterMode(curPage, dispatch, pageKey, currentFilterMode) {
  const next = produce(curPage, (draft) => {
    draft.page.filterMode = currentFilterMode === 'advanced' ? 'quick' : 'advanced'
  })

  dispatch(ps[pageKey].setPageSettingsToRedux(next))
}

export function filterButtonDescription(currentFilterMode) {
  let nextMode = ''
  if (currentFilterMode === 'advanced') {
    nextMode = dqTranslateMsg('SidePanel_' + 'DBFieldFilter')
  } else {
    nextMode = dqTranslateMsg('SidePanel_' + 'DBFieldAdvancedFilter')
  }
  return dqTranslateMsg('SidePanel_' + 'SwitchFilterMode') + ' > ' + nextMode
}
