import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

export default function SettingsDefectUnitOption(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'paretoChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onCheckBoxArimaValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page.displayDefectUnit[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`defect-unit-option-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DefectUnitBarState')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'NGBar'),
        labelLocation: 'right',
        name: 'ng',
        value: pageState.page.displayDefectUnit.ng,
        disabled: false,
        onValueChanged: (e) => onCheckBoxArimaValueChanged(e, 'ng'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'WarnBar'),
        labelLocation: 'right',
        name: 'warn',
        value: pageState.page.displayDefectUnit.warn,
        disabled: false,
        onValueChanged: (e) => onCheckBoxArimaValueChanged(e, 'warn'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'GoodBar'),
        labelLocation: 'right',
        name: 'good',
        value: pageState.page.displayDefectUnit.good,
        disabled: false,
        onValueChanged: (e) => onCheckBoxArimaValueChanged(e, 'good'),
      })}
    </GroupItem>
  )
}
