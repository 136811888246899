export const groupTbDescription = {
    dbConfig: 'group_admin_config_1',
    ribbon: 'group_admin_config_2',
    encoding: 'group_admin_config_3',
    findDistribution: 'group_admin_config_4',
    commonSettings: 'group_admin_config_5',
    reserved_3: 'group_admin_config_6',
    reserved_4: 'group_admin_config_7',
    reserved_5: 'group_admin_config_8',
    language: 'group_admin_config_9',
    permissions: 'group_admin_config_10',
}
