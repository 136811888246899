import * as DBUtils from 'src/functions/DatabaseUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'

class DisplayNumberUtils {
  /**
   * cp cpk x50 x99.865 x0.135 will calculate in backend server
   */

  /**
   * check data
   * 0 : Cannot Evaluated
   * 1 : Good
   * 2 : Bad
   */

  static formatToDecimalPlaces(value, decimalPlaces) {
    return value.toFixed(decimalPlaces)
  }

  static truncateToDecimalPlaces(value, decimalPlaces) {
    const factor = Math.pow(10, decimalPlaces)
    return Math.floor(value * factor) / factor
  }

  static roundToDecimalPlaces(value, decimalPlaces) {
    const factor = Math.pow(10, decimalPlaces)
    return Math.round(value * factor) / factor
  }

  static getValueArrayMaxDecimalPlaces(values) {
    let maxDecimals = 0
    values.forEach((item) => {
      const decimalPart = item.v0001.toString().split('.')[1]
      if (decimalPart) {
        maxDecimals = Math.max(maxDecimals, decimalPart.length)
      }
    })
    return maxDecimals
  }

  static getAxisDecimalPlaces(char, configSettings) {
    // usl, lsl is nullable.
    const usl = PCUtils.getUsl(char)
    const lsl = PCUtils.getLsl(char)
    const { calcDisplayDecimalPlaces } = configSettings.commonSettings

    const getDecimalPlaces = (num) => {
      if (num === null) return null
      const numStr = num.toString()
      const decimalIndex = numStr.indexOf('.')
      return decimalIndex === -1 ? 0 : numStr.length - decimalIndex - 1
    }

    const uslDecimalPlaces = getDecimalPlaces(usl)
    const lslDecimalPlaces = getDecimalPlaces(lsl)

    if (uslDecimalPlaces === null && lslDecimalPlaces === null) {
      return calcDisplayDecimalPlaces
    }

    const validUslDecimalPlaces = uslDecimalPlaces === null ? 0 : uslDecimalPlaces
    const validLslDecimalPlaces = lslDecimalPlaces === null ? 0 : lslDecimalPlaces

    return Math.max(validUslDecimalPlaces, validLslDecimalPlaces)
  }

  static getValueDecimalPlaces(char, configSettings) {
    const MIN_DECIMAL_PLACES = 0
    const MAX_DECIMAL_PLACES = 15

    try {
      const dbDecimalPlaces = DBUtils.getFieldData(char, 'c2022')
      const { displayDecimalPlaces } = configSettings.commonSettings

      if (dbDecimalPlaces === null) {
        return displayDecimalPlaces
      }

      const clampedDecimalPlaces = Math.max(dbDecimalPlaces, MIN_DECIMAL_PLACES)
      const resultDecimalPlaces = Math.min(clampedDecimalPlaces, MAX_DECIMAL_PLACES)

      return resultDecimalPlaces
    } catch (error) {
      console.error('An error occurred while getting value decimal places:', error)
      return MIN_DECIMAL_PLACES // 기본 값 반환
    }
  }

  static getCalcDisplayDecimalPlaces(configSettings) {
    const MIN_DECIMAL_PLACES = 0
    const MAX_DECIMAL_PLACES = 15

    try {
      const { calcDisplayDecimalPlaces } = configSettings.commonSettings

      const clampedDecimalPlaces = Math.max(calcDisplayDecimalPlaces, MIN_DECIMAL_PLACES)
      const resultDecimalPlaces = Math.min(clampedDecimalPlaces, MAX_DECIMAL_PLACES)

      return resultDecimalPlaces
    } catch (error) {
      console.error('An error occurred while getting calc display decimal places:', error)
      return MIN_DECIMAL_PLACES // 기본 값 반환
    }
  }

  static formatToExponential(value, decimalThreshold) {
    try {
      const valueString = String(value)
      const decimalPlaces = valueString.includes('.') ? valueString.split('.')[1].length : 0
      if (decimalPlaces >= decimalThreshold) {
        return Number(value).toExponential()
      } else {
        return value
      }
    } catch (error) {
      console.error('An error occurred while formatting the number:', error)
      throw error
    }
  }

  static fixedValueDisplay(value, dpValue) {
    const decimalPlaces = dpValue
    const truncatedValue = this.truncateToDecimalPlaces(value, decimalPlaces)
    const formattedValue = this.formatToDecimalPlaces(truncatedValue, decimalPlaces)
    return formattedValue
  }

  static calcValueDisplay(value, configSettings) {
    const decimalPlaces = this.getCalcDisplayDecimalPlaces(configSettings)
    const truncatedValue = this.truncateToDecimalPlaces(value, decimalPlaces)
    const formattedValue = this.formatToDecimalPlaces(truncatedValue, decimalPlaces)
    return formattedValue
  }

  static valueDisplay(value, char, configSettings) {
    const decimalThreshold = configSettings.commonSettings.isExponentialActive
    const decimalPlaces = this.getValueDecimalPlaces(char, configSettings)
    const truncatedValue = this.truncateToDecimalPlaces(value, decimalPlaces)
    const fitDpValue = this.formatToDecimalPlaces(truncatedValue, decimalPlaces)
    const formattedValue = this.formatToExponential(fitDpValue, decimalThreshold)
    return formattedValue
  }

  static axisValueDisplay(value, char, configSettings) {
    const decimalPlaces = this.getAxisDecimalPlaces(char, configSettings)
    const truncatedValue = this.truncateToDecimalPlaces(value, decimalPlaces)
    const formattedValue = this.formatToDecimalPlaces(truncatedValue, decimalPlaces)
    return formattedValue
  }
}

export default DisplayNumberUtils
