import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export const symbolShapeDefine = () => [
  {
    value: 'emptyCircle',
    text: dqTranslateMsg('SidePanel_' + 'EmptyCircle'),
  },
  { value: 'circle', text: dqTranslateMsg('SidePanel_' + 'Circle') },
  { value: 'rect', text: dqTranslateMsg('SidePanel_' + 'Rect') },
  {
    value: 'roundRect',
    text: dqTranslateMsg('SidePanel_' + 'RoundRect'),
  },
  { value: 'triangle', text: dqTranslateMsg('SidePanel_' + 'Triangle') },
  { value: 'diamond', text: dqTranslateMsg('SidePanel_' + 'Diamond') },
  { value: 'pin', text: dqTranslateMsg('SidePanel_' + 'Pin') },
  { value: 'arrow', text: dqTranslateMsg('SidePanel_' + 'Arrow') },
  { value: 'none', text: dqTranslateMsg('SidePanel_' + 'None') },
]

export const lineTypeDefine = () => [
  { value: 'solid', text: dqTranslateMsg('SidePanel_' + 'Solid') },
  { value: 'dotted', text: dqTranslateMsg('SidePanel_' + 'Dotted') },
  { value: 'dashed', text: dqTranslateMsg('SidePanel_' + 'Dashed') },
]

export const splitItemsDefine = [
  'none',
  // 'v0001',
  // 'v0002',
  'v0004',
  'v0005',
  'v0006',
  'v0007',
  'v0008',
  // 'v0009',
  'v0010',
  'v0011',
  'v0012',
  'v0014',
  'v0016',
  'v0017',
  'v0053',
  'v0054',
  'v0055',
  'v0056',
  'v0057',
  'v0058',
  'v0059',
  'v0060',
  'v0061',
  'v0062',
  'v0063',
]

export const xAxisLabelItems = {
  // v0001: false,
  // v0002: false,
  v0004: false,
  v0005: false,
  v0006: false,
  v0007: false,
  v0008: false,
  v0009: false,
  v0010: false,
  v0011: false,
  v0012: false,
  v0014: false,
  v0016: false,
  v0017: false,
  v0053: false,
  v0054: false,
  v0055: false,
  v0056: false,
  v0057: false,
  v0058: false,
  v0059: false,
  v0060: false,
  v0061: false,
  v0062: false,
  v0063: false,
}

export const iconDefine = () => [
  { value: 'arrow', text: dqTranslateMsg('SidePanel_' + 'Arrow') },
  { value: 'smile', text: dqTranslateMsg('SidePanel_' + 'Smile') },
  { value: 'thumbs', text: dqTranslateMsg('SidePanel_' + 'Thumbs') },
]

export const onOffTypeDefine = [true, false]
export const sortTypeDefine = () => [
  { value: 'string', text: dqTranslateMsg('Page_Database_' + 'String') },
  { value: 'numeric', text: dqTranslateMsg('Page_Database_' + 'Numeric') },
]
// export const easyLoadTypeDefine = ['count', 'hour', 'day', 'week']
export const easyLoadTypeDefine = () => [
  { value: 'count', text: dqTranslateMsg('Page_Database_' + 'Count') },
  { value: 'hour', text: dqTranslateMsg('Page_Database_' + 'Hour') },
  { value: 'day', text: dqTranslateMsg('Page_Database_' + 'Day') },
  { value: 'week', text: dqTranslateMsg('Page_Database_' + 'Week') },
]
export const dateTimeDisplayDefine = () => [
  {
    value: 'date/time/sec',
    text: dqTranslateMsg('SidePanel_' + 'DateTimeSecItem'),
  },
  { value: 'date/time', text: dqTranslateMsg('SidePanel_' + 'DateTimeItem') },
  { value: 'date', text: dqTranslateMsg('SidePanel_' + 'DateItem') },
  { value: 'time/sec', text: dqTranslateMsg('SidePanel_' + 'TimeSecItem') },
  { value: 'time', text: dqTranslateMsg('SidePanel_' + 'TimeItem') },
]
export const yAxisType = () => [
  {
    value: 'Theoretical-Value',
    text: dqTranslateMsg('SidePanel_' + 'TheoreticalValue'),
  },
  {
    value: 'Theoretical-Quantile',
    text: dqTranslateMsg('SidePanel_' + 'TheoreticalQuantile'),
  },
]

export const printContent = ['Characteristics', 'Summary']
export const displayTypeDefine = ['Description', 'Chart']
export const monitoringTypeDefine = ['Automatic', 'Manual']
export const valueOrderDefine = ['Latest', 'Oldest']
export const charOrderDefine = ['fromError', 'fromGood']

export const monitoringIntervalDefine = () => [
  { value: '1minute', text: dqTranslateMsg('SidePanel_' + 'OneMinute') },
  { value: '1hour', text: dqTranslateMsg('SidePanel_' + 'OneHour') },
  { value: '1day', text: dqTranslateMsg('SidePanel_' + 'OneDay') },
  { value: '1week', text: dqTranslateMsg('SidePanel_' + 'OneWeek') },
  { value: 'custom', text: dqTranslateMsg('SidePanel_' + 'Custom') },
]
export const defaultDistributionSideMethodDefine = () => [
  { value: 'none', text: dqTranslateMsg('SidePanel_' + 'None') },
  { value: 'one-side', text: dqTranslateMsg('SidePanel_' + 'OneSide') },
  { value: 'two-side', text: dqTranslateMsg('SidePanel_' + 'TwoSide') },
]

export const defaultDistributionSideTypeDetailsDefine = () => [
  {
    value: 0,
    text: dqTranslateMsg('SidePanel_' + 'AutoCalculation'),
  },
  {
    value: 1,
    text: dqTranslateMsg('SidePanel_' + 'NormalDistribution'),
  },
  {
    value: 2,
    text: dqTranslateMsg('SidePanel_' + 'LogNormalDistribution'),
  },
  {
    value: 3,
    text: dqTranslateMsg('SidePanel_' + 'FoldedNormalDistribution'),
  },
  {
    value: 4,
    text: dqTranslateMsg('SidePanel_' + 'WeibullDistribution'),
  },
]

export const linearNormalizationTypeDefine = () => [
  { value: 'none', text: dqTranslateMsg('SidePanel_' + 'None') },
  { value: 'min-max', text: dqTranslateMsg('SidePanel_' + 'MinMax') },
  { value: 'max-abs', text: dqTranslateMsg('SidePanel_' + 'MaxAbs') },
  { value: 'robust', text: dqTranslateMsg('SidePanel_' + 'Robust') },
  { value: 'standard', text: dqTranslateMsg('SidePanel_' + 'Standard') },
]

export const qualityControlCalculationTypeDefine = () => [
  { value: 'fixed', text: dqTranslateMsg('SidePanel_' + 'Fixed') },
  { value: 'moving', text: dqTranslateMsg('SidePanel_' + 'Moving') },
]
export const linearRegressionCompareType = () => [
  {
    value: 'scatter+slope',
    text: dqTranslateMsg('SidePanel_' + 'ScatterSlope'),
  },
  { value: 'slope', text: dqTranslateMsg('SidePanel_' + 'Slope') },
  { value: 'scatter', text: dqTranslateMsg('SidePanel_' + 'Scatter') },
]
