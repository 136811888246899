import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import { connect } from 'react-redux'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import * as VCArima from 'src/functions/ValueChartArima'
import * as VCUtils from 'src/functions/ValueChartUtils'
import { useLanguage } from 'src/contexts/languages'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'

const getViewPortTheme = (pageKey, theme) => {
  if (pageKey !== 'reportPrint') {
    return theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'
  }
  return 'light'
}

const ApacheEChartValueChartComponent = ({ common, rawData = [], info = { part_id: 1, char_id: 1 }, option = [], theme, pageKey }) => {
  const { key, parts, chars, valuesArranged, charArray, curPage, configPage } = rawData
  const { language } = useLanguage()
  const chartRef = useRef(null)

  return (
    <Fragment>
      <ReactECharts
        key={key}
        ref={chartRef}
        style={{ width: '100%', height: '100%' }}
        notMerge={true}
        option={option ? option : {}}
        theme={getViewPortTheme(pageKey, theme)}
        opts={{
          renderer: 'svg',
          locale: ETCUtils.checkLangForECharts(language),
        }}
      />
    </Fragment>
  )
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(ApacheEChartValueChartComponent)
