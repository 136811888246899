import ReactECharts from 'echarts-for-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { batch, connect } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import CPUtils from 'src/functions/CommonPageUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './ParetoChartForm.scss'
import * as pco from './chart-option/ParetoChartOption'
import * as pcoImpl from './chart-option/ParetoChartOptionImpl'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { useLanguage } from 'src/contexts/languages'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { AutoSizer, Grid } from 'react-virtualized'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ParetoChartForm)

const styles = {
    chartContainer: {
        padding: '5px',
    },
    chartInnerContainer: {
        width: 'calc(100%)', // Adjust width to account for the margin
        height: 'calc(100%)', // Adjust height to account for the margin
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
        overflow: 'visible',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
    },
    gridCell: {},
}

function checkShowUslLslDCharacteristics(displayData, settings) {
    const isShowEmptyCharacteristicsOff = settings.page.showEmptyCharacteristics === false
    const isDisplayDefectUnitNg = settings.page.displayDefectUnit.ng === true
    const isDisplayDefectUnitWarn = settings.page.displayDefectUnit.warn === true
    const isDisplayDefectUnitGood = settings.page.displayDefectUnit.good === true

    if (
        isShowEmptyCharacteristicsOff &&
        (!isDisplayDefectUnitNg || displayData.uniqueTypeCnt.ngCnt[0] === 0) &&
        (!isDisplayDefectUnitWarn || displayData.uniqueTypeCnt.warnCnt[0] === 0) &&
        (!isDisplayDefectUnitGood || displayData.uniqueTypeCnt.goodCnt[0] === 0)
    ) {
        return false
    }

    return true
}

function ParetoChartForm({ pageKey, common, chartRawData }) {
    const { getTheme } = useTheme()
    const { language } = useLanguage()
    const [isLoadingPanel, setIsLoadingPanel] = useState(false)

    // For initialRendering
    const paretoChartRefs = useRef(null)
    const [chartOptions, setChartOptions] = useState([])

    // const { parts, chars, values } = useMemo(() => CPUtils.loadRefChartData(chartRawData), [chartRawData])
    // const { charArray, curPage, configPage, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)
    // const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    // const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)

    // const numCharts = chars.length
    // const paretoChartRefs = new Array(numCharts).fill(null).map(() => useRef({ element: null, chartElement: null, id: null }))
    // const paretoChartInnerRefs = new Array(numCharts).fill(null).map(() => useRef(null))
    const gridRef = useRef(null) // Create a ref for the Grid component

    // load settings
    // const checkSummary = curPage.page.summary
    // const displayType = curPage.page.displayType // default = event

    const curPage = common.pages.paretoChart
    const othersPage = common.pages.others
    const configPage = common.pages.configuration
    const currentSel = common.current_sel
    const selectedChar = common.loaded_all[pageKey]

    const columnCount = curPage.page.layout.column
    const rowCount = Math.ceil(chartOptions.length / columnCount)

    useEffect(() => {
        const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
        const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)
        const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
        const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)
        const numCharts = chars.length
        paretoChartRefs.current = new Array(numCharts).fill(null).map(() => ({ element: null, chartElement: null, id: null }))

        let tempChartOptions = []

        if (valuesArranged.length > 0) {
            valuesArranged.map((value) => {
                // const char = chars.find((char) => char.part_id === value.part_id && char.char_id === value.char_id)
                const char = value.charRaw

                const info = {
                    part_id: char.part_id,
                    char_id: char.char_id,
                }

                let displayData = {}

                const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(char, configPage)
                const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
                const filteredValue = outlierFilterValues(limits, char, value.valueRaw, configPage)

                if (curPage.page.displayType === 'event') {
                    displayData = pcoImpl.makeNumberOfEvent(configPage, othersPage, char, filteredValue)

                    const option = pco.getParetoChartOption(curPage, configPage, othersPage, char, displayData)
                    tempChartOptions.push({ option, info, displayData })
                } else {
                    displayData = pcoImpl.makeDataByLslUsl(curPage, configPage, char, filteredValue)

                    const option = pco.getParetoChartOption(curPage, configPage, othersPage, char, displayData)
                    tempChartOptions.push({ option, info, displayData })
                }
            })

            tempChartOptions = tempChartOptions.filter((chart) => {
                let isTrue = false

                if (curPage.page.displayType === 'event') {
                    isTrue = curPage.page.showEmptyCharacteristics !== true ? chart.displayData.uniqueType.length > 0 && chart.displayData.uniqueTypeCnt.length > 0 : true
                } else {
                    isTrue = checkShowUslLslDCharacteristics(chart.displayData, curPage)
                }

                return isTrue
            })

            batch(() => {
                // setCalculationData(histogramData)
                setChartOptions(tempChartOptions)
            })
        } else {
            batch(() => {
                // setCalculationData([])
                setChartOptions([])
            })
        }
    }, [selectedChar, curPage, configPage])

    useEffect(() => {
        const targetRefId = `${currentSel.part_id}_${currentSel.char_id}`
        const targetIndex = chartOptions.findIndex((chart) => `${chart.info.part_id}_${chart.info.char_id}` === targetRefId)

        if (targetIndex !== -1 && gridRef.current) {
            const rowIndex = Math.floor(targetIndex / columnCount)
            const columnIndex = targetIndex % columnCount

            gridRef.current.scrollToCell({ columnIndex, rowIndex })

            setTimeout(() => {
                const targetElement = paretoChartRefs?.current[targetIndex]?.element
                if (targetElement) {
                    targetElement.classList.add('target-element')
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })

                    targetElement.addEventListener(
                        'animationend',
                        () => {
                            targetElement.classList.remove('target-element')
                        },
                        { once: true }
                    )
                }
            }, 0)
        }
    }, [currentSel, paretoChartRefs, chartOptions, columnCount])

    // paretoChartInnerRefs.forEach((element) => {
    //   useEChartsResize(element)
    // })
    const renderCell = ({ columnIndex, rowIndex, style, key, parent }) => {
        const index = rowIndex * columnCount + columnIndex
        if (index >= chartOptions.length) return null

        const chart = chartOptions[index]

        // if (isTrue) {
        return (
            <div
                key={`${chart.info.part_id}_${chart.info.char_id}`}
                ref={(e) => {
                    paretoChartRefs.current[index].element = e
                    paretoChartRefs.current[index].id = `${chart.info.part_id}_${chart.info.char_id}`
                }}
                style={{ ...style, padding: '3px' }} // Apply padding for the gap
                // onClick={() => handleCellClick(index)}
            >
                <div style={styles.chartInnerContainer}>
                    <ReactECharts
                        // ref={paretoChartInnerRefs[index]}
                        key={`${chart.info.part_id}_${chart.info.char_id}_chart`}
                        style={{ width: '100%', height: '100%' }}
                        notMerge={true}
                        option={chart.option}
                        theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                        opts={{
                            renderer: 'svg',
                            locale: ETCUtils.checkLangForECharts(language),
                        }}
                    />
                </div>
            </div>
        )
        // }
    }

    return (
        <div className={'dx-theme-background-color'}>
            <DQLoadPanel open={isLoadingPanel} />
            <div
                className='chart-form-content'
                style={{ width: '100%', height: '100%' }}
            >
                {chartOptions.length > 0 ? (
                    <AutoSizer>
                        {({ height, width }) => (
                            <Grid
                                ref={gridRef} // Assign the ref to the Grid component
                                className='custom-scrollbar'
                                columnCount={columnCount}
                                columnWidth={width / curPage.page.layout.column} // Adjust column width to account for the margin
                                height={height}
                                rowCount={rowCount}
                                rowHeight={height / curPage.page.layout.row} // Adjust row height to account for the margin
                                width={width}
                                cellRenderer={renderCell}
                                style={{ overflowX: 'hidden', overflowY: 'auto' }}
                            />
                        )}
                    </AutoSizer>
                ) : null}
            </div>
        </div>
    )

    // return <PageWarningMessage message={dqTranslateMsg('Page_ParetoChart_' + 'DataIsNotSelected')} />
}
