import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'

export default function ThemeBarChartOption(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'capabilitySummaryChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onColorBoxCpValueChanged = (e, state, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      if (!draft.barChart.cp) {
        draft.barChart.cp = {}
      }
      if (!draft.barChart.cp[state]) {
        draft.barChart.cp[state] = {}
      }
      draft.barChart.cp[state][dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onColorBoxCpkValueChanged = (e, state, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      if (!draft.barChart.cpk) {
        draft.barChart.cpk = {}
      }
      if (!draft.barChart.cpk[state]) {
        draft.barChart.cpk[state] = {}
      }
      draft.barChart.cpk[state][dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }
  return (
    <GroupItem key={`bar-chart-option-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'BarColor')}>
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'CpNGBar'),
        labelLocation: 'top',
        name: 'errorColor',
        value: pageState.barChart.cp?.error?.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxCpValueChanged(e, 'error', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'CpGoodBar'),
        labelLocation: 'top',
        name: 'goodColor',
        value: pageState.barChart.cp?.good?.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxCpValueChanged(e, 'good', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'CpkNGBar'),
        labelLocation: 'top',
        name: 'errorColor',
        value: pageState.barChart.cpk?.error?.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxCpkValueChanged(e, 'error', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'CpkGoodBar'),
        labelLocation: 'top',
        name: 'goodColor',
        value: pageState.barChart.cpk?.good?.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxCpkValueChanged(e, 'good', 'color'),
      })}
    </GroupItem>
  )
}
