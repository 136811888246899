import { Button, LoadIndicator, Toast } from 'devextreme-react'
import { Column, ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, DataGrid, GroupPanel, Position, Scrolling } from 'devextreme-react/data-grid'
import { Popup } from 'devextreme-react/popup'
import ReactECharts from 'echarts-for-react'
import produce from 'immer'
import { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import ApiStatus from 'src/api/defines/ApiStatus'
import * as localApi from 'src/api/local'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useAuth } from 'src/contexts/auth'
import { useTheme } from 'src/contexts/theme'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { makeArrangedData } from 'src/pages/components/dq-arrange/DQArrange'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import MonitoringDetailForm from './MonitoringDetailForm'
import './MonitoringForm.scss'
import * as mImpl from './MonitoringFormImpl'
import CPUtils from 'src/functions/CommonPageUtils'
import { useLanguage } from 'src/contexts/languages'
import { getCurrentFilterData } from 'src/functions/DatabaseUtils'
import DQToast from 'src/functions/DQToast'

function convertToCustomFormat(devExpressDate) {
    const year = devExpressDate.getFullYear()
    const month = (devExpressDate.getMonth() + 1).toString().padStart(2, '0')
    const day = devExpressDate.getDate().toString().padStart(2, '0')
    const hours = devExpressDate.getHours().toString().padStart(2, '0')
    const minutes = devExpressDate.getMinutes().toString().padStart(2, '0')
    const seconds = devExpressDate.getSeconds().toString().padStart(2, '0')

    return `${year}${month}${day}${hours}${minutes}${seconds}`
}

function convertToCustomDisplayFormat(devExpressDate) {
    const year = devExpressDate.slice(0, 4)
    const month = devExpressDate.slice(4, 6)
    const day = devExpressDate.slice(6, 8)
    const hour = devExpressDate.slice(8, 10)
    const minute = devExpressDate.slice(10, 12)
    const second = devExpressDate.slice(12, 14)

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

function checkMonitoringTime(settings, type) {
    let mil = 0
    if (type === '1minute') {
        mil = 60 * 1000
    } else if (type === '1hour') {
        mil = 60 * 60 * 1000
    } else if (type === '1day') {
        mil = 24 * 60 * 60 * 1000
    } else if (type === '1week') {
        // 1week
        mil = 7 * 24 * 60 * 60 * 1000
    } else {
        mil = settings.page.monitoringInterval.customInterval * 1000
    }
    return mil
}

function getDateTime(settings) {
    if (settings.page.easySettingsDateTime === 'custom') {
        return {
            startDateTime: settings.page.customStartDateTime,
            endDateTime: settings.page.customEndDateTime,
        }
    } else {
        const curTime = new Date()
        const preTime = new Date()
        if (settings.page.easySettingsDateTime === '1minute') {
            preTime.setMinutes(preTime.getMinutes() - 1)
        } else if (settings.page.easySettingsDateTime === '1hour') {
            preTime.setHours(preTime.getHours() - 1)
        } else if (settings.page.easySettingsDateTime === '1day') {
            preTime.setDate(preTime.getDate() - 1)
        } else {
            // 1week
            preTime.setDate(preTime.getDate() - 7)
        }

        return {
            startDateTime: convertToCustomFormat(preTime),
            endDateTime: convertToCustomFormat(curTime),
        }
    }
}

function MonitoringForm({ pageKey, common, chartRawData }) {
    const { getTheme } = useTheme()
    const { language } = useLanguage()
    const { user } = useAuth()

    const toastRef = useRef(null)

    const [isRunning, setIsRunning] = useState(false)

    const [monitoringState, setMonitoringState] = useState({
        language: language,
        popup: {
            show: false,
            params: {
                part_id: 0,
                char_id: 0,
                value_id: 0,
            },
            data: [],
        },
        gridDataSource: [],
    })

    const monitoringDgRef = useRef(null)

    // For chart display data.
    // const curPage = common.pages.monitoring
    const pages = common.pages
    const databasePage = common.pages.database
    const valuePage = common.pages.valueChart
    const othersPage = common.pages.others
    const summaryPage = common.pages.valueSummaryList
    // const configPage = common.pages.configuration
    const databaseFilter = getCurrentFilterData(pages, othersPage)

    const dataChartRefs = useRef({})

    const { parts, chars, values } = useMemo(() => CPUtils.loadRefChartData(chartRawData), [chartRawData])

    const { curPage, configPage } = CPUtils.loadRefOtherData(common, chars, pageKey)

    const handleStartMonitorButtonClicked = () => {
        localApi.connectLocalDB('demo')
        setIsRunning(true)
    }
    const handleStopMonitorButtonClicked = () => {
        setIsRunning(false)
    }

    const hide = () => {
        const next = produce(monitoringState, (draft) => {
            draft.popup.show = false
            draft.popup.params.part_id = 0
            draft.popup.params.char_id = 0
            draft.popup.params.value_id = 0
        })

        setMonitoringState(next)
    }

    function handleMonitorClick(chartData, cellData) {
        if (isRunning === false) {
            const next = produce(monitoringState, (draft) => {
                draft.popup.show = true
                draft.popup.params.part_id = cellData.row.data.part_id
                draft.popup.params.char_id = cellData.row.data.char_id
                draft.popup.params.value_id = cellData.data.referenceData[chartData.data[0]].value_id
            })

            setMonitoringState(next)
        } else {
            alert(dqTranslateMsg('Page_Monitoring_' + 'ErrorMsgRequireStopMonitoring'))
        }
    }

    function makeStatusString(localStatus) {
        let strStatus = ''
        if (localStatus === 1) {
            strStatus = 'Not Processed Yet'
        } else if (localStatus === 2) {
            strStatus = 'OK'
        } else if (localStatus === 3) {
            strStatus = 'NOK'
        }

        return strStatus
    }

    const toolTipBalloonCallback = (args, cellData, settings) => {
        let displayStr = ''

        displayStr += 'Value : ' + cellData.data.referenceData[args.dataIndex].value + ' '
        displayStr += 'DateTime : ' + cellData.data.referenceData[args.dataIndex].dateTime + '<br>'
        displayStr += 'Status : ' + makeStatusString(cellData.data.referenceData[args.dataIndex].local_status) + '<br>'
        return displayStr
    }

    function renderTimeDate(cellData) {
        return convertToCustomDisplayFormat(cellData.displayValue)
    }

    function renderData(cellData) {
        const uniqueRefKey = cellData.data.ID
        const MAX_DISPLAY_COUNT = 10
        const option = {
            animationDuration: 300,
            backgroundColor: 'rgba(255, 255, 255, 0)',
            animation: false,
            tooltip: {
                trigger: 'item',
                appendToBody: true,
                textStyle: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                },
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                extraCssText: 'width: 250px',
                position: function (pos, params, dom, rect, size) {
                    let obj = { top: 60 }
                    obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
                    return obj
                },
                formatter: function (args) {
                    return toolTipBalloonCallback(args, cellData, curPage)
                },
            },
            grid: {
                top: '0%',
                bottom: '0%',
                left: '0%',
                right: '0%',
            },
            xAxis: {
                type: 'category',
                axisLine: {
                    show: false,
                },
            },
            yAxis: {
                type: 'value',
                // data: dataSample.map((data, index) => index),
                splitLine: {
                    show: false,
                },
            },
            series: [
                {
                    type: 'bar',
                    data: cellData.data.data.map((data) => {
                        const index = data[0]
                        const value = data[1] === null ? 0 : 1
                        return [index, value]
                    }),
                    barWidth: '90%',
                    emphasis: {
                        focus: 'self',
                    },
                    itemStyle: {
                        color: (params) => {
                            if (params.data[1] !== null) {
                                if (cellData.data.referenceData[params.dataIndex] !== undefined) {
                                    return cellData.data.referenceData[params.dataIndex].barColor
                                }
                            }
                        },
                    },
                    barMinWidth: 1,
                },
            ],
        }

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <ReactECharts
                    ref={(e) => {
                        dataChartRefs.current[uniqueRefKey] = e
                    }}
                    notMerge={true}
                    option={option}
                    style={{ height: '30px', width: '100%' }}
                    theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                    opts={{
                        renderer: 'svg',
                        locale: ETCUtils.checkLangForECharts(language),
                    }}
                    onEvents={{
                        click: (chartData) => handleMonitorClick(chartData, cellData),
                        // finished: () => handleResize,
                    }}
                />
            </div>
        )
    }
    function renderTotal(cellData) {
        return <div style={{ background: 'gray' }}>{cellData.data.totalCnt}</div>
    }
    function renderGood(cellData) {
        return <div style={{ background: 'green' }}>{cellData.data.goodCnt}</div>
    }
    function renderWarn(cellData) {
        return <div style={{ background: 'yellow' }}>{cellData.data.warnCnt}</div>
    }
    function renderNg(cellData) {
        return <div style={{ background: 'red' }}>{cellData.data.ngCnt}</div>
    }

    useEffect(() => {
        console.log('updated')

        const setLocalStatus = (updateDataSource) => {
            console.log('setLocalStatus')
            console.log(updateDataSource)

            const resultArray = updateDataSource.reduce((acc, item) => {
                // Filter the referenceData for local_status === 1
                const filteredReferenceData = item.referenceData.filter((refData) => refData.local_status === 0)

                // Map the filtered referenceData to the desired structure
                const mappedData = filteredReferenceData.map((refData) => ({
                    part_id: refData.part_id,
                    char_id: refData.char_id,
                    value_id: refData.value_id,
                    // local_status: refData.local_status,
                    status: 1,
                }))

                // Concatenate the mapped data to the accumulator
                return acc.concat(mappedData)
            }, [])

            if (resultArray.length > 0) {
                localApi.setLocalStatus('demo', resultArray)
            }
        }
        const updateDataSource = () => {
            // display type
            if (curPage.page.displayType === 'Description') {
                let partInfo = []
                let charInfo = []
                let valueInfo = []

                localApi.getLocalPartInfo('demo', 0, null).then((data) => {
                    partInfo = data
                    const ids = partInfo.reduce((acc, { part_id }) => {
                        acc.push({ part_id, char_ids: [0] })
                        return acc
                    }, [])

                    localApi.getLocalCharInfo('demo', ids, null).then((data) => {
                        charInfo = data
                        const ids = charInfo.reduce((acc, { part_id }) => {
                            const existingEntry = acc.find((entry) => entry.part_id === part_id)

                            if (!existingEntry) {
                                acc.push({ part_id, char_ids: [0] })
                            }
                            return acc
                        }, [])

                        const { startDateTime, endDateTime } = getDateTime(curPage)

                        localApi.getLocalValueInfo('demo', 'ASCENDING', startDateTime, endDateTime, ids, 0, null).then((data) => {
                            valueInfo = data

                            if (valueInfo.length === 0) {
                                toastRef.current.handleToastDisplay('warning', dqTranslateMsg('Page_Monitoring_' + 'MonitoringNoDataMsg'))
                                return
                            }

                            const arrangeOpt = databaseFilter.others.arrangement
                            const latestOpt = databaseFilter.others.ignoreDuplicatedValue
                            const sortOpt = databaseFilter.common.dateTimeSort
                            const arrangedData = makeArrangedData(arrangeOpt, latestOpt, sortOpt, partInfo, charInfo, valueInfo)

                            const charArranged = arrangedData.filter((data) => data.valueRaw.length > 0)
                            const valuesArranged = arrangedData.filter((value) => {
                                return charArranged.some((char) => char.part_id === value.part_id && char.char_id === value.char_id)
                            })

                            const { updateDataSource } = mImpl.makeMonitoringDataSource(curPage, configPage, partInfo, charArranged, valuesArranged, startDateTime, endDateTime)

                            const next = produce(monitoringState, (draft) => {
                                draft.popup.data = valuesArranged
                                draft.gridDataSource = updateDataSource
                            })
                            setMonitoringState(next)

                            setTimeout(() => setLocalStatus(updateDataSource), 1000)
                        })
                    })
                })
            } else {
                console.log('chart')
            }
        }

        // updateDataSource()

        let readTimerId
        if (isRunning) {
            readTimerId = setInterval(updateDataSource, checkMonitoringTime(curPage, curPage.page.monitoringInterval.type))
        }

        // // Add the resize event listener
        // window.addEventListener('resize', handleResize)

        // return () => {
        //   clearInterval(readTimerId)
        //   window.removeEventListener('resize', handleResize)
        // }
        return () => {
            clearInterval(readTimerId)
        }
    }, [curPage, isRunning])

    const circleStyle = {
        height: '30px',
        width: '30px',
        borderRadius: '15px',
        backgroundColor: isRunning ? 'green' : 'red',
    }

    const handleDataGridContentReady = () => {
        Object.values(dataChartRefs.current).forEach((ref) => {
            const dataChartInstance = ref?.getEchartsInstance() // Assuming instance is directly accessible
            if (dataChartInstance) {
                dataChartInstance.resize() // Or any other method you need to call
            }
        })
    }

    return (
        <div className='dx-theme-background-color'>
            <DQToast ref={toastRef} />
            <>
                <div className='monitoring-form-monitor-content'>
                    <div className='monitoring-form-monitor-start-container'>
                        <Button
                            ref={monitoringDgRef}
                            id='monitorStartBtn'
                            className='monitoring-form-monitor-start-button'
                            icon='/images/color/start-green.svg'
                            text={dqTranslateMsg('Page_Monitoring_' + 'MonitoringStart')}
                            // disabled={monitoringState.buttonDisable}
                            disabled={isRunning}
                            width={'100%'}
                            height={'100%'}
                            onClick={handleStartMonitorButtonClicked}
                        />
                    </div>
                    <div className='monitoring-form-monitor-status-container'>
                        <div
                            className='monitoring-form-monitor-icon'
                            style={circleStyle}
                        >
                            <LoadIndicator
                                id='indicator'
                                visible={isRunning}
                                height={30}
                                width={30}
                            />
                        </div>
                        <div className='monitoring-form-monitor-text'>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    fontSize: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                {isRunning === true ? dqTranslateMsg('Page_Monitoring_' + 'Operating') : dqTranslateMsg('Page_Monitoring_' + 'Stopped')}
                            </div>
                        </div>
                    </div>
                    <div className='monitoring-form-monitor-stop-container'>
                        <Button
                            icon='/images/color/stop-red.svg'
                            text={dqTranslateMsg('Page_Monitoring_' + 'MonitoringStop')}
                            // disabled={!monitoringState.buttonDisable}
                            disabled={!isRunning}
                            width={'100%'}
                            height={'100%'}
                            onClick={handleStopMonitorButtonClicked}
                        />
                    </div>
                </div>
                <div className='monitoring-form-content-container'>
                    <div className='monitoring-grid-container'>
                        <DataGrid
                            className='monitoring-grid-container'
                            id='monitoring-datagrid'
                            ref={monitoringDgRef}
                            keyExpr='ID'
                            dataSource={monitoringState.gridDataSource}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={'widget'}
                            filterRow={{ visible: false }}
                            noDataText={dqTranslateMsg('Page_Monitoring_' + 'NoData')}
                            paging={{ enabled: false }}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            hoverStateEnabled={true}
                            onContentReady={handleDataGridContentReady}
                        >
                            <GroupPanel
                                visible={true}
                                emptyPanelText={dqTranslateMsg('Page_Monitoring_' + 'EmptyPanelText')}
                            />
                            <Scrolling
                                mode='standard'
                                rowRenderingMode='standard'
                                useNative={false}
                            />
                            <Column
                                dataField='part'
                                caption={dqTranslateMsg('Page_Monitoring_' + 'Part')}
                                width={'10%'}
                                alignment='center'
                                groupIndex={0}
                            />
                            <Column
                                dataField='characteristics'
                                width={'10%'}
                                caption={dqTranslateMsg('Page_Monitoring_' + 'Characteristics')}
                                alignment='center'
                            />
                            <Column
                                dataField='data'
                                caption={dqTranslateMsg('Page_Monitoring_' + 'Data')}
                                width={'50%'}
                                alignment='center'
                                cellRender={renderData}
                            />
                            <Column
                                dataField='v0004'
                                caption={dqTranslateMsg('Page_Monitoring_' + 'TimeDate')}
                                width={'10%'}
                                alignment='center'
                                cellRender={renderTimeDate}
                            />
                            <Column
                                dataField='v0014'
                                caption={dqTranslateMsg('Page_Monitoring_' + 'PartIdent')}
                                width={'5%'}
                                alignment='center'
                            />
                            <Column
                                dataField='v0053'
                                caption={dqTranslateMsg('Page_Monitoring_' + 'OrderNumber')}
                                width={'5%'}
                                alignment='center'
                                visible={false}
                            />
                            <Column
                                dataField='total'
                                caption={dqTranslateMsg('Page_Monitoring_' + 'Total')}
                                width={'5%'}
                                alignment='center'
                                cellRender={renderTotal}
                            />
                            <Column
                                dataField='good'
                                caption={dqTranslateMsg('Page_Monitoring_' + 'Good')}
                                width={'5%'}
                                alignment='center'
                                cellRender={renderGood}
                            />
                            <Column
                                dataField='warn'
                                caption={dqTranslateMsg('Page_Monitoring_' + 'Warn')}
                                width={'5%'}
                                alignment='center'
                                cellRender={renderWarn}
                            />
                            <Column
                                dataField='ng'
                                caption={dqTranslateMsg('Page_Monitoring_' + 'NG')}
                                width={'5%'}
                                alignment='center'
                                cellRender={renderNg}
                            />
                            <ColumnChooser
                                enabled={true}
                                mode={'select'}
                                width={300}
                                height={300}
                                title={dqTranslateMsg('Page_Monitoring_' + 'ColumnChooserTitle')}
                                emptyPanelText={dqTranslateMsg('Page_Monitoring_' + 'ColumnChooserEmptyPanelText')}
                            >
                                <Position
                                    my='center'
                                    at='center'
                                    of={window}
                                />
                                <ColumnChooserSearch
                                    enabled={true}
                                    editorOptions={{
                                        placeholder: dqTranslateMsg('Page_Monitoring_' + 'ColumnChooserPlaceHoler'),
                                    }}
                                />
                                <ColumnChooserSelection
                                    allowSelectAll={false}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                        </DataGrid>
                    </div>
                </div>
            </>
            {monitoringState.popup.show ? (
                <Popup
                    className='monitoring-form-popup-container'
                    width={'90%'}
                    height={'90%'}
                    visible={monitoringState.popup.show}
                    onHiding={hide}
                    hideOnOutsideClick={true}
                    showCloseButton={true}
                    title='Monitoring Detail'
                >
                    <MonitoringDetailForm
                        style={{ width: '100%', height: '100%' }}
                        ids={monitoringState}
                        settings={curPage}
                        valueChartSettings={valuePage}
                        summaryListSettings={summaryPage}
                        configSettings={configPage}
                        othersPage={othersPage}
                    />
                </Popup>
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(MonitoringForm)
