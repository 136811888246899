import { currentTheme, refreshTheme } from 'devextreme/viz/themes'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { storageKey } from '../utils/theme-constants'
import { useSelector } from 'react-redux'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles'
import themes from 'devextreme/ui/themes'
const themeData = [
  'generic.carmine.compact',
  'generic.dark.compact',
  'generic.darkmoon.compact',
  'generic.darkviolet.compact',
  'generic.greenmist.compact',
  'generic.light.compact',
  'material.blue.dark.compact',
  'material.blue.light.compact',
  'material.lime.dark.compact',
  'material.lime.light.compact',
  'material.orange.dark.compact',
  'material.orange.light.compact',
  'material.purple.dark.compact',
  'material.purple.light.compact',
  'material.teal.dark.compact',
]
const getThemeData = () => [
  {
    text: 'Orange Light',
    value: 'orange.light',
    ImageSrc: '/icons/Component1.svg',
  },
  {
    text: 'Blue Light',
    value: 'blue.light',
    ImageSrc: '/icons/Component2.svg',
  },
  {
    text: 'Purple Light',
    value: 'purple.light',
    ImageSrc: '/icons/Component3.svg',
  },
  {
    text: 'Purple Dark',
    value: 'purple.dark',
    ImageSrc: '/icons/Component4.svg',
  },
]

const ThemeContext = createContext({})
const useTheme = () => useContext(ThemeContext)

function ThemeProvider({ theme, ...props }) {
  const themeState = useSelector((state) => state.common.pages.configuration.theme || 'generic.light.compact')

  const [_theme, setTheme] = useState(themeState)

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: _theme?.includes('dark') ? 'dark' : 'light',
        },
      }),
    [_theme]
  )

  const getTheme = () => {
    return themeState || window.localStorage[storageKey] || 'generic.light.compact'
  }

  const applySwatchVariables = useCallback((accent) => {
    if (accent === 'light.compact') {
      document.documentElement.style.setProperty('--base-border-color', '#F3F3F3')
      document.documentElement.style.setProperty('--base-bg', 'rgba(0, 0, 0, 0.16)')
      document.documentElement.style.setProperty('--icon-color', 'rgba(0, 0, 0, 0.54)')
    } else {
      document.documentElement.style.setProperty('--base-border-color', '#464650')
      document.documentElement.style.setProperty('--base-bg', 'rgba(255, 255, 255, 0.10)')
      document.documentElement.style.setProperty('--icon-color', 'rgba(255, 255, 255, 0.87)')
    }
  }, [])

  const applyTheme = useCallback(
    (theme) => {
      theme = getTheme()

      let accent = theme?.substring(theme?.indexOf('.') + 1)
      applySwatchVariables(accent)

      window.localStorage[storageKey] = theme
      refreshTheme()
    },
    [getTheme]
  )

  useEffect(() => {
    const themeToUse = themeData.includes(_theme) ? _theme : 'generic.light.compact'
    applyTheme(themeToUse)
    themes.current(themeToUse)
  }, [_theme, applyTheme])

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeContext.Provider value={{ getThemeData, getTheme, setTheme }} {...props} />
    </MuiThemeProvider>
  )
}

export { ThemeProvider, useTheme }
