// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.end-users-title-container {
  width: 100%;
  height: 3%;
  font-family: "Arial";
  font-size: 15px;
}

.end-users-content-container {
  width: 100%;
  height: 97%;
}

.end-users-data-grid-tree-list-inner-content {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/end-users/AdminEndUsers.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,UAAA;EACA,oBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".end-users-title-container {\r\n  width: 100%;\r\n  height: 3%;\r\n  font-family: 'Arial';\r\n  font-size: 15px;\r\n}\r\n\r\n.end-users-content-container {\r\n  width: 100%;\r\n  height: 97%;\r\n}\r\n\r\n.end-users-data-grid-tree-list-inner-content {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
