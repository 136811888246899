// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-panel-theme-container {
  width: 100%;
  height: 100%;
}
.side-panel-theme-container .side-panel-theme-container::-webkit-scrollbar {
  width: 5px;
}
.side-panel-theme-container .side-panel-theme-container::-webkit-scrollbar-thumb {
  background-color: #3e4451;
}
.side-panel-theme-container .side-panel-theme-container::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
.side-panel-theme-container .button-container {
  width: 100%;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/side-panel-form/component/side-panel-theme-form/SidePanelThemeForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AAEE;EACE,UAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,wCAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ","sourcesContent":[".side-panel-theme-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  // overflow-y: auto;\r\n\r\n  .side-panel-theme-container::-webkit-scrollbar {\r\n    width: 5px;\r\n  }\r\n  .side-panel-theme-container::-webkit-scrollbar-thumb {\r\n    background-color: #3e4451;\r\n  }\r\n  .side-panel-theme-container::-webkit-scrollbar-track {\r\n    background-color: rgba(255, 255, 255, 0);\r\n  }\r\n\r\n  .button-container {\r\n    width: 100%;\r\n    height: 30px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
