import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export function getCapabilitySummaryChartOption(settings, configSettings, chars, values) {
  const charNameArray = [] //cpCpkArray.map((item) => item.charName)

  return {
    animationDuration: 300,
    grid: {
      top: '10%',
      bottom: '6%',
      left: '0%',
      right: '0%',
      containLabel: true,
    },
    backgroundColor: 'rgba(255, 255, 255, 0)',
    legend: {
      data: [
        {
          name: dqTranslateMsg('Page_CapabilitySummaryChart_' + 'CapabilityCp'),
          itemStyle: { color: settings.barChart.cp?.good?.color },
        },
        { name: dqTranslateMsg('Page_CapabilitySummaryChart_' + 'CapabilityCpk'), itemStyle: { color: settings.barChart.cpk?.good?.color } },
        { name: dqTranslateMsg('Page_CapabilitySummaryChart_' + 'Criteria'), itemStyle: { color: settings.markLine.color }, lineStyle: { color: settings.markLine.color } },
      ],
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'item',
      appendToBody: true,
      position: 'inside',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      axisPointer: {
        type: 'shadow',
        label: {
          textStyle: {
            fontFamily: 'Arial',
          },
        },
      },
      textStyle: {
        fontFamily: 'Arial',
      },
      formatter: function (arg) {
        let displayStr = ''
        if (arg.componentType === 'series') {
          displayStr += arg.seriesName + '<br>'
          displayStr += `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${arg.color}; border-radius: 50%;"></span> `
          displayStr += arg.name + '&emsp;'
          displayStr += `<b>${DNUtils.calcValueDisplay(Number(arg.value), configSettings)}</b>`
        } else {
          // markLine
          displayStr += 'Criteria' + '<br>'
          displayStr += `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${arg.borderColor}; border-radius: 50%;"></span> `
          displayStr += '&emsp;'
          displayStr += `<b>${DNUtils.calcValueDisplay(Number(arg.value), configSettings)}</b>`
        }
        return displayStr
      },
    },
    xAxis: {
      type: 'category',
      nameGap: 30,
      data: charNameArray,
      splitArea: {
        show: false,
      },
      splitLine: {
        show: true,
      },
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        rotate: 90,
      },
    },
    yAxis: {
      type: 'value',
      scale: true,
      splitArea: {
        show: false,
      },
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        formatter: function (value) {
          return DNUtils.calcValueDisplay(Number(value), configSettings)
        },
      },
      axisLine: {
        onZero: false,
      },
    },
    dataZoom: [
      {
        show: true,
        type: 'inside',
        filterMode: 'none',
      },
      {
        show: true,
        type: 'slider',
        filterMode: 'none',
      },
    ],
    series: [
      {
        type: 'line',
        name: 'Criteria',
        markLine: {
          show: true,
          // silent: true,
          name: 'Criteria',
          label: 'Criteria',
          precision: 10,
          animation: false,
          symbol: 'none',
          label: {
            show: true, // Ensure label is shown
            formatter: 'Criteria', // Label text
          },
          data: [
            {
              yAxis: settings.page.importanceLine,
              name: 'Criteria',
              lineStyle: {
                type: settings.markLine.type,
                width: settings.markLine.width,
                color: settings.markLine.color,
              },
              emphasis: {
                lineStyle: {
                  width: settings.markLine.width,
                  color: settings.markLine.color,
                  type: settings.markLine.type,
                },
              },
            },
          ],
        },
      },
    ],
  }
}
