import ScrollView from 'devextreme-react/scroll-view'
import './TitleFunctionCard.scss'
import { Button } from 'devextreme-react'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export default function TitleFunctionCard({
  title,
  buttonEnable,
  onButtonClick,
  children,
}) {
  return (
    <ScrollView width={'100%'} height={'auto'} className={'single-card'}>
      <div
        className={'dx-card'}
        style={{
          width: '100%',
          height: '100%',
          padding: 0,
          margin: 0,
          border: 'none',
        }}
      >
        <div
          className={'header'}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0,
            paddingBottom: 10,
            margin: 0,
          }}
        >
          <div
            style={{
              width: '75%',
              height: '100%',
              padding: 0,
              margin: 0,
              fontSize: '16px',
            }}
          >
            {title}
          </div>
          {buttonEnable ? (
            <Button
              // className={'description'}
              text={dqTranslateMsg('SidePanel_' + 'Apply')}
              style={{
                width: '25%',
                height: '100%',
                padding: 0,
                margin: 0,
              }}
              onClick={onButtonClick}
            />
          ) : null}
        </div>
        {children}
      </div>
    </ScrollView>
  )
}
