import ReactECharts from 'echarts-for-react'
import { useRef } from 'react'
import { connect } from 'react-redux'
import { useLanguage } from 'src/contexts/languages'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'

const getViewPortTheme = (pageKey, theme) => {
  if (pageKey !== 'reportPrint') {
    return theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'
  }

  return 'light'
}

const ApacheEChartHistogramComponent = ({ common, rawData = [], info = { part_id: 1, char_id: 1 }, option = [], theme, pageKey }) => {
  const { key, parts, chars, values, charArray, curPage, configPage, valuesArranged } = rawData

  const { language } = useLanguage()
  const chartRef = useRef(null)

  return (
    <ReactECharts
      key={key}
      ref={chartRef}
      style={{ width: '100%', height: '100%' }}
      notMerge={true}
      option={option ? option : {}}
      theme={getViewPortTheme(pageKey, theme)}
      opts={{
        renderer: 'svg',
        locale: ETCUtils.checkLangForECharts(language),
      }}
    />
  )
}
const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ApacheEChartHistogramComponent)
