import { Button } from 'devextreme-react/button'
import RadioGroup from 'devextreme-react/radio-group'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { valueChartSettingsPrm } from 'src/pages/chart-value/components/settings/ValueChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { setValueChartPage } from 'src/store/common'
import './SidePanelReportPrintSettings.scss'
import TextTitle from 'src/functions/TextTitle'
import { reportPrintSettingsPrm } from '../settings/ReportPrintSettings'
import DQToast from 'src/functions/DQToast'

export default function SidePanelReportPrintSettings(props) {
    const { pageKey } = props
    const dispatch = useDispatch()

    const toastRef = useRef(null)

    const [scrollPosition, setScrollPosition] = useState(0)
    const { user } = useAuth()
    const common = useSelector((state) => state.common)
    const othersPage = common.pages.others
    const curPage = common.pages.reportPrint
    const configPage = common.pages.configuration

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }
    const divRef = useRef(null)
    const splitRef = useRef(null)
    const arimaRef = useRef(null)

    const printBodyContent = async () => {
        // Open the browser's print dialog
        const target = document.getElementById('content')
        if (!divRef.current) return
        try {
            const div = divRef.current
            const canvas = await html2canvas(div, { scale: 2 })
            canvas.toBlob((blob) => {
                if (blob !== null) {
                    saveAs(blob, 'result.png')
                }
            })
        } catch (error) {
            console.error('Error converting div to image:', error)
        }
    }

    const handleCurPageSettingsSave = (updatePageData) => {
        dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
    }

    const handleAllSettingsSave = () => {
        const next = produce(curPage, (draft) => {
            Object.keys(curPage).forEach((key) => {
                draft[key] = curPage[key]
            })
            draft.updateFlag = !draft.updateFlag
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }
    const handleAllSettingsReset = () => {
        const next = produce(curPage, (draft) => {
            Object.entries(reportPrintSettingsPrm.page).forEach(([key, value]) => {
                draft.page[key] = value
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    const handlePrintContent = (e) => {
        const next = produce(curPage, (draft) => {
            draft.page.printContent = e.value
        })

        dispatch(setValueChartPage(next))
    }

    return (
        <div
            ref={divRef}
            className='side-panel-value-chart-container'
        >
            <DQToast ref={toastRef} />

            {/* Function Buttons */}
            <div className='side-panel-function-buttons'>
                <Button
                    className='save'
                    icon='save'
                    text={dqTranslateMsg('SidePanel_' + 'Save')}
                    width={'50%'}
                    onClick={handleAllSettingsSave}
                />
                <Button
                    className='undo'
                    icon='undo'
                    text={dqTranslateMsg('SidePanel_' + 'Reset')}
                    width={'50%'}
                    onClick={handleAllSettingsReset}
                />
            </div>
            {/* Print Content */}
            <TextTitle
                text={dqTranslateMsg('SidePanel_' + 'PrintContent')}
                width='40%'
                childrenWidth={'60%'}
                fontSize={'14px'}
            >
                <RadioGroup
                    disabled={true}
                    style={{ fontSize: '10px' }}
                    layout='vertical'
                    items={sd.printContent}
                    value={curPage.page.dateTimeLabelDisplayFormat}
                    onValueChanged={handlePrintContent}
                />
            </TextTitle>
        </div>
    )
}
