export function saveDQPageSettingsToLS(updated) {
    const dataString = JSON.stringify(updated)
    localStorage.setItem('dqPageSettings', dataString)
    console.log(`Save Page Settings : ${JSON.stringify(updated)}`)
}

export function loadDQPageSettingsFromLS() {
    const dataString = localStorage.getItem('dqPageSettings')
    const dataJson = JSON.parse(dataString)
    return dataJson
}

export function saveDQBaseSettingsToLS(updated) {
    const dataString = JSON.stringify(updated)
    localStorage.setItem('dqPageSettings', dataString)
    console.log(`Save Page Settings : ${JSON.stringify(updated)}`)
}

export function loadDQBaseSettingsFromLS() {
    const dataString = localStorage.getItem('dqBaseSettings')
    const dataJson = JSON.parse(dataString)
    return dataJson
}

export function saveUserToLS(updated) {
    const dataString = JSON.stringify(updated)
    sessionStorage.setItem('dq-user', dataString)
}

export function loadUserFromLS() {
    const dataString = sessionStorage.getItem('dq-user')

    let dataJson
    if (dataString !== 'undefined') {
        dataJson = JSON.parse(dataString)
        return dataJson
    }
    dataJson = 'undefined'
    return dataJson
}

export function removeUserToLS() {
    sessionStorage.removeItem('dq-user')
}
