import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

const importanceTypeList = () => [
    {
        value: 'Cart',
        text: dqTranslateMsg('SidePanel_' + 'Cart'),
    },
    {
        value: 'RandomForest',
        text: dqTranslateMsg('SidePanel_' + 'RandomForest'),
    },
]
const importanceDispCntList = () => [
    {
        value: 'all',
        text: dqTranslateMsg('SidePanel_' + 'All'),
    },
    {
        value: 'top10',
        text: dqTranslateMsg('SidePanel_' + 'Top10'),
    },
    {
        value: 'top5',
        text: dqTranslateMsg('SidePanel_' + 'Top5'),
    },
    {
        value: 'top3',
        text: dqTranslateMsg('SidePanel_' + 'Top3'),
    },
]

export default function Importance(pageKey, pageState, configState, othersPage) {
    const dispatch = useDispatch()
    // const pageKey = 'decisionTreeChart'

    // const pageState = useSelector((state) => state.common.pages[pageKey])
    // const configState = useSelector((state) => state.common.pages.configuration)

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }

    const useValueFilterField = useFieldList.value.map((item) => {
        return { ...item, text: dqTranslateMsg('Db_field_Value_' + item.text) }
    })

    const onSelectBoxImportanceValueChanged = (e, dataField) => {
        const value = e.itemData.value
        const next = produce(pageState, (draft) => {
            draft.page[dataField] = value
        })

        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <GroupItem
            key={`importance-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'Importance')}
        >
            {SelectBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'Type'),
                labelLocation: 'top',
                name: 'importanceType',
                items: importanceTypeList(),
                valueExpr: 'value',
                displayExpr: 'text',
                value: pageState.page.importanceType,
                disabled: false,
                onItemClick: (e) => onSelectBoxImportanceValueChanged(e, 'importanceType'),
            })}
            {SelectBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'DisplayCount'),
                labelLocation: 'top',
                name: 'importanceDisplayCount',
                items: importanceDispCntList(),
                valueExpr: 'value',
                displayExpr: 'text',
                value: pageState.page.importanceDisplayCount,
                disabled: false,
                onItemClick: (e) => onSelectBoxImportanceValueChanged(e, 'importanceDisplayCount'),
            })}
        </GroupItem>
    )
}
