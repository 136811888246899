export const qualityControlChartSettingsPrm = {
  page: {
    fontSize: 18,
    calculationCount: 5,
    calculationType: 'fixed',
    layout: {
      column: 2,
      row: 2,
    },
    takeoverFromDataset: false,
    // subGroupType: 'fixed',
    // subGroupCount: 5,
    locationChartType: 'average', // average , raw
    handlingIncompleteSubgroup: 'delete', // calculate , delete
    xAxisDenseLabel: true,
    xBarToolTipUseSpecLimits: true,
    xBarToolTipUseWarnLimits: true,
    xBarToolTipUseCustomLimits: true,
    rangeToolTipUseCustomLimits: true,
    specificationLimitEnable: true,
    warningLimitEnable: true,
    controlLimitEnable: true,
    averageLimitEnable: true,
    nominalLimitEnable: true,
    plausibilityLimitEnable: true,
    scrapLimitEnable: true,
    acceptanceLimitEnable: true,
    specificationLimitLabelEnable: true,
    warningLimitLabelEnable: true,
    controlLimitLabelEnable: true,
    averageLimitLabelEnable: true,
    nominalLimitLabelEnable: true,
    plausibilityLimitLabelEnable: true,
    scrapLimitLabelEnable: true,
    acceptanceLimitLabelEnable: true,
  },
  lineChart: {
    symbols: {
      error: {
        upper: {
          shape: 'triangle',
          size: 5,
          color: 'rgba(255, 0, 0, 1)',
        },
        lower: {
          shape: 'path://M 100 100 L 300 100 L 200 300 z',
          size: 5,
          color: 'rgba(255, 0, 0, 1)',
        },
      },
      warn: {
        upper: {
          shape: 'rect',
          size: 3,
          color: 'rgba(255, 136, 0, 1)',
        },
        lower: {
          shape: 'rect',
          size: 3,
          color: 'rgba(255, 136, 0, 1)',
        },
      },
      good: {
        shape: 'none',
        size: 3,
        color: 'rgba(2, 152, 212, 1)',
      },
    },
    chartLines: {
      type: 'solid',
      width: 1,
      color: 'rgba(2, 152, 212, 1)',
    },
    markLines: {
      precision: 10,
      specLimit: {
        show: true,
        type: 'solid',
        color: 'rgba(255, 0, 0, 1)',
        width: 1,
      },
      warnLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(255, 136, 0, 1)',
        width: 1,
      },
      controlLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 127, 207, 1)',
        width: 1,
      },
      center: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 127, 207, 1)',
        width: 1,
      },
      nominal: {
        show: true,
        type: 'dashed',
        color: 'rgba(128, 0, 128, 1)',
        width: 1,
      },
      average: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      plausibilityLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      scrapLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      acceptanceLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
    },
    markAreas: {
      error: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 8, 8, 0.0)',
      },
      warn: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 255, 0, 0.0)',
      },
      good: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 255, 0, 0)',
      },
      outOfControl: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(127, 127, 127, 0.0)',
      },
    },
  },
}
