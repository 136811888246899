import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as ptImpl from './PositionalTolerancesChartOptionImpl'
import CCUtils from 'src/functions/CommonCalcUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'

const formatLimitValue = (label, value, char, configSettings) => {
  return value !== undefined && typeof value === 'number' ? `${label} : ${DNUtils.axisValueDisplay(value, char, configSettings)}<br>` : ''
}

const toolTipBalloonCallback = (args, xChar, yChar, settings, configSettings) => {
  let displayStr = ''

  if (args.seriesName) {
    displayStr += `${args.seriesName}<br>`
  }

  if (args.data) {
    displayStr += `X ${dqTranslateMsg('Page_PositionalTolerancesChart_' + 'Value')} : ${DNUtils.valueDisplay(args.data[0], xChar, configSettings)}<br>`
    displayStr += `Y ${dqTranslateMsg('Page_PositionalTolerancesChart_' + 'Value')} : ${DNUtils.valueDisplay(args.data[1], yChar, configSettings)}<br>`
  }

  const useSpecLimits = settings.page.xAxisPTToolTipUseSpecLimits || settings.page.yAxisPTToolTipUseSpecLimits
  if (useSpecLimits) {
    displayStr += `<br>${dqTranslateMsg('Page_PositionalTolerancesChart_' + 'Limits')}<br>`

    if (settings.page.xAxisPTToolTipUseSpecLimits) {
      displayStr += formatLimitValue('X_USL', settings.x_usl, xChar, configSettings)
      displayStr += formatLimitValue('X_LSL', settings.x_lsl, xChar, configSettings)
    }

    if (settings.page.yAxisPTToolTipUseSpecLimits) {
      displayStr += formatLimitValue('Y_USL', settings.y_usl, yChar, configSettings)
      displayStr += formatLimitValue('Y_LSL', settings.y_lsl, yChar, configSettings)
    }
  }

  return displayStr
}

// other
function calculateEllipsePoints(x, y, rx, ry) {
  const points = []

  for (let angle = 0; angle <= 360; angle += 0.1) {
    const radians = (angle * Math.PI) / 180
    const pointX = x + rx * Math.cos(radians)
    const pointY = y + ry * Math.sin(radians)
    points.push([pointX, pointY])
  }

  return points
}

export const getPositionalTolerancesChartOption = (settings, configSettings, ptTargetChar, xTargetChar, yTargetChar, xValueArray, yValueArray) => {
  const titleText = `${ptTargetChar.c2002 ?? 'empty'} P:${ptTargetChar.part_id ?? '?'} / C:${ptTargetChar.char_id ?? '?'}`
  const x_lsl = PCUtils.getLsl(xTargetChar)
  const x_usl = PCUtils.getUsl(xTargetChar)
  const y_lsl = PCUtils.getLsl(yTargetChar)
  const y_usl = PCUtils.getUsl(yTargetChar)
  const x_nominal = PCUtils.getNominal(xTargetChar)
  const y_nominal = PCUtils.getNominal(yTargetChar)
  // const x_gap = x_usl - x_nominal
  // const y_gap = y_usl - y_nominal
  const xGapUslLsl = PCUtils.getUslLslGap(x_usl, x_lsl)
  const yGapUslLsl = PCUtils.getUslLslGap(y_usl, y_lsl)
  // const x_warnOffset = x_gap / 5 // 20% 설정으로 나중에 빼야된다.
  // const y_warnOffset = y_gap / 5 // 20% 설정으로 나중에 빼야된다.
  const x_warnOffset = PCUtils.getWarningOffset(configSettings.commonSettings.warningLimitPercentage)
  const y_warnOffset = PCUtils.getWarningOffset(configSettings.commonSettings.warningLimitPercentage)
  const x_lwl = PCUtils.getUwl(x_lsl, xGapUslLsl, x_warnOffset)
  const x_uwl = PCUtils.getUwl(x_usl, xGapUslLsl, x_warnOffset)
  const y_lwl = PCUtils.getUwl(y_lsl, yGapUslLsl, y_warnOffset)
  const y_uwl = PCUtils.getUwl(y_usl, yGapUslLsl, y_warnOffset)
  const x_values = xValueArray.map((item) => {
    return item.v0001
  })
  const y_values = yValueArray.map((item) => {
    return item.v0001
  })
  const xMaxValue = CCUtils.getMax(x_values)
  const xMinValue = CCUtils.getMin(x_values)
  const yMaxValue = CCUtils.getMax(y_values)
  const yMinValue = CCUtils.getMin(y_values)

  // const xAxisTop = ptImpl.makeXAxisTop(settings, x_usl, x_gap, xMaxValue)
  // const xAxisBottom = ptImpl.makeXAxisBottom(settings, x_lsl, x_gap, xMinValue)
  // const yAxisTop = ptImpl.makeYAxisTop(settings, y_usl, y_gap, yMaxValue)
  // const yAxisBottom = ptImpl.makeYAxisBottom(settings, y_lsl, y_gap, yMinValue)
  const xAxisTop = ptImpl.makeXAxisTop(settings, x_usl, x_lsl, null, null, xMaxValue, xMinValue)
  const xAxisBottom = ptImpl.makeXAxisBottom(settings, x_usl, x_lsl, null, null, xMaxValue, xMinValue)
  const yAxisTop = ptImpl.makeYAxisTop(settings, y_usl, y_lsl, null, null, yMaxValue, yMinValue)
  const yAxisBottom = ptImpl.makeYAxisBottom(settings, y_usl, y_lsl, null, null, yMaxValue, yMinValue)

  const center_x = x_nominal
  const center_y = y_nominal

  const radius_x = x_usl - x_nominal
  const radius_y = y_usl - y_nominal

  const xyValueArray = x_values.map((x, index) => [x, y_values[index]])

  const dataArray = xyValueArray

  const insidePoints = []
  const outsidePoints = []

  const circleBoundaryData = calculateEllipsePoints(center_x, center_y, y_usl - y_nominal, x_usl - x_nominal)

  for (const point of dataArray) {
    const x = point[0]
    const y = point[1]

    const normalizedX = (x - center_x) / radius_x
    const normalizedY = (y - center_y) / radius_y
    const isOutsideEllipse = normalizedX * normalizedX + normalizedY * normalizedY > 1

    if (isOutsideEllipse == false) {
      insidePoints.push(point)
    } else {
      outsidePoints.push(point)
    }
  }
  const xDecimalPlaces = DNUtils.getValueDecimalPlaces(xTargetChar, configSettings)
  const yDecimalPlaces = DNUtils.getValueDecimalPlaces(yTargetChar, configSettings)
  // const xDecimalPlaces = xTargetChar.c2022 ?? configSettings.commonSettings.displayDecimalPlaces
  // const yDecimalPlaces = yTargetChar.c2022 ?? configSettings.commonSettings.displayDecimalPlaces

  // chart setting reorganization
  const addedSettings = {
    ...settings,
    titleText: titleText,
    x_lsl: x_lsl,
    x_usl: x_usl,
    y_lsl: y_lsl,
    y_usl: y_usl,
    x_lwl: x_lwl,
    x_uwl: x_uwl,
    y_lwl: y_lwl,
    y_uwl: y_uwl,
    x_nominal: x_nominal,
    y_nominal: y_nominal,
    x_warnOffset: x_warnOffset,
    y_warnOffset: y_warnOffset,
    xAxisTop: xAxisTop,
    xAxisBottom: xAxisBottom,
    yAxisTop: yAxisTop,
    yAxisBottom: yAxisBottom,
    xDecimalPlaces: xDecimalPlaces,
    yDecimalPlaces: yDecimalPlaces,
  }

  // 원의 크기 계산
  return {
    title: {
      text: addedSettings.titleText,
      left: 'center',
      textStyle: {
        fontFamily: 'Arial',
        fontSize: (18 / (addedSettings.page.layout.row + addedSettings.page.layout.column)) * 2,
      },
    },
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    grid: {
      top: '20%',
      bottom: '20%',
      left: '0%',
      right: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      min: addedSettings.xAxisBottom, // Y-axis minimum value
      max: addedSettings.xAxisTop, // Y-axis maximum value
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        rotate: 90,
        fontSize: ((addedSettings.page.fontSize - 2) / (addedSettings.page.layout.row + addedSettings.page.layout.column)) * 1.7,
        formatter: function (value) {
          // return DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(value, xDecimalPlaces), isExponential)
          return DNUtils.axisValueDisplay(value, xTargetChar, configSettings)
        },
      },
      axisLine: {
        onZero: false,
      },
    },
    yAxis: {
      type: 'value',
      min: addedSettings.yAxisBottom, // Y-axis minimum value
      max: addedSettings.yAxisTop, // Y-axis maximum value
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        fontSize: ((addedSettings.page.fontSize - 2) / (addedSettings.page.layout.row + addedSettings.page.layout.column)) * 1.7,
        formatter: function (value) {
          return DNUtils.axisValueDisplay(value, yTargetChar, configSettings)
        },
      },
      axisLine: {
        onZero: false,
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'item',
      appendToBody: true,
      textStyle: {
        fontFamily: 'Arial',
      },
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      // position: function (pos, params, dom, rect, size) {
      //   let obj = { top: 60 }
      //   obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
      //   return obj
      // },
      formatter: function (args) {
        return toolTipBalloonCallback(args, xTargetChar, yTargetChar, addedSettings, configSettings)
      },
    },
    legend:
      settings.page.layout.column > 3
        ? null
        : {
            top: '5%',
            orient: 'horizontal',
            textStyle: {
              fontFamily: 'Arial',
            },
          },
    series: [
      {
        id: 'ok',
        name: dqTranslateMsg('Page_PositionalTolerancesChart_' + 'GoodValueInfo'),
        type: 'scatter',
        data: insidePoints,
        symbol: (value, params) => ptImpl.makeSeriesGoodShape(value, params, addedSettings),
        symbolSize: (value, params) => ptImpl.makeSeriesGoodSize(value, params, addedSettings),
        itemStyle: {
          // color: (params) => ptImpl.makeSeriesGoodColor(params, addedSettings),
          color: addedSettings.lineChart.symbols.good.color,
        },
        lineStyle: {
          // color: (params) => ptImpl.makeSeriesGoodColor(params, addedSettings),
          color: addedSettings.lineChart.symbols.good.color,
        },
        // tooltip: ptImpl.makeSeriesTooltip(),
      },
      {
        id: 'ng',
        name: dqTranslateMsg('Page_PositionalTolerancesChart_' + 'NGValueInfo'),
        type: 'scatter',
        data: outsidePoints,
        symbol: (value, params) => ptImpl.makeSeriesErrorShape(value, params, addedSettings),
        symbolSize: (value, params) => ptImpl.makeSeriesErrorSize(value, params, addedSettings),
        itemStyle: {
          color: addedSettings.lineChart.symbols.error.upper.color,
        },
        lineStyle: {
          color: addedSettings.lineChart.symbols.error.upper.color,
        },
        // tooltip: ptImpl.makeSeriesTooltip(),
      },
      {
        type: 'line',
        data: circleBoundaryData,
        symbol: 'none',
        lineStyle: {
          type: addedSettings.ptChart.chartLines.type,
          width: addedSettings.ptChart.chartLines.width,
          color: addedSettings.ptChart.chartLines.color,
        },
      },
      {
        type: 'line',
        markLine: {
          silent: true,
          symbol: 'none',
          precision: 10,
          lineStyle: {
            color: 'red',
            width: 2,
            type: 'line',
          },
          data: ptImpl.convertToMarkLine(addedSettings, configSettings),
        },
      },
    ],
  }
}
