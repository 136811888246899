import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'

export default function ChartSplit(pageKey, pageState, configState, othersPage) {
    const dispatch = useDispatch()
    // const pageKey = 'valueChart'

    // const pageState = useSelector((state) => state.common.pages[pageKey])
    // const configState = useSelector((state) => state.common.pages.configuration)

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }

    const useNoneValueFilterField = CPUtils.getConfigItemListFromUseField('Value', true, null, useFieldList.value, configState)
    const useValueFilterField = CPUtils.getConfigItemListFromUseField('Value', false, null, useFieldList.value, configState)
    const xAxisLabelItems = CPUtils.makeItemListFromSetting('Value', pageState.page.xAxisLabelItems, useFieldList.value, configState)
    const toolTipInfoItems = CPUtils.makeItemListFromSetting('Value', pageState.page.toolTipInfoItems, useFieldList.value, configState)

    const onSelectBoxChartSplitValueChanged = (e, dataField) => {
        const value = e.itemData.value
        const next = produce(pageState, (draft) => {
            draft.page[dataField] = []
            if (value === 'None') {
                draft.page[dataField] = []
            } else {
                draft.page[dataField].push(value)
            }
        })

        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <GroupItem
            key={`chart-split-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'ChartSplit')}
        >
            {SelectBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'SplitItem'),
                labelLocation: 'top',
                name: 'splitItem',
                items: useNoneValueFilterField,
                valueExpr: 'value',
                displayExpr: 'text',
                value: pageState.page.splitItem.length > 0 ? useNoneValueFilterField.find((item) => item.value === pageState.page.splitItem[0]).value : useNoneValueFilterField[0].value,
                disabled: pageState.page.arima.show === true,
                onItemClick: (e) => onSelectBoxChartSplitValueChanged(e, 'splitItem'),
            })}
        </GroupItem>
    )
}
