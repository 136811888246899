// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: calc(100vh - 50px);
}
.dx-theme-background-color .side-panel-tab-container {
  width: 100%;
  height: 100%;
}
.dx-theme-background-color .side-panel-tab-container-three .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
  width: 33.3333333333%;
}
.dx-theme-background-color .side-panel-tab-container-two .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
  width: 50%;
}
.dx-theme-background-color .side-panel-tab-container-one .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
  width: 100%;
}

.dx-theme-material .dx-theme-background-color {
  width: 100%;
  height: calc(100vh - 50px);
}
.dx-theme-material .dx-theme-background-color .side-panel-tab-container {
  width: 100%;
  height: 100%;
}
.dx-theme-material .dx-theme-background-color .side-panel-tab-container-three .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
  width: 33.3333333333%;
}
.dx-theme-material .dx-theme-background-color .side-panel-tab-container-two .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
  width: 50%;
}
.dx-theme-material .dx-theme-background-color .side-panel-tab-container-one .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/side-panel-form/SidePanelForm.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,0BAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,qBAAA;AADJ;AAIE;EACE,UAAA;AAFJ;AAKE;EACE,WAAA;AAHJ;;AASE;EACE,WAAA;EACA,0BAAA;AANJ;AAQI;EACE,WAAA;EACA,YAAA;AANN;AASI;EACE,qBAAA;AAPN;AAUI;EACE,UAAA;AARN;AAWI;EACE,WAAA;AATN","sourcesContent":["// .side-panel-form-container {\r\n.dx-theme-background-color {\r\n  width: 100%;\r\n  height: calc(100vh - 50px);\r\n\r\n  .side-panel-tab-container {\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n\r\n  .side-panel-tab-container-three .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {\r\n    width: calc(100% / 3);\r\n  }\r\n\r\n  .side-panel-tab-container-two .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {\r\n    width: calc(100% / 2);\r\n  }\r\n\r\n  .side-panel-tab-container-one .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {\r\n    width: calc(100% / 1);\r\n  }\r\n}\r\n\r\n// 마테리얼 테마를 적용하기 위한 추가 스타일\r\n.dx-theme-material {\r\n  .dx-theme-background-color {\r\n    width: 100%;\r\n    height: calc(100vh - 50px);\r\n\r\n    .side-panel-tab-container {\r\n      width: 100%;\r\n      height: 100%;\r\n    }\r\n\r\n    .side-panel-tab-container-three .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {\r\n      width: calc(100% / 3);\r\n    }\r\n\r\n    .side-panel-tab-container-two .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {\r\n      width: calc(100% / 2);\r\n    }\r\n\r\n    .side-panel-tab-container-one .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {\r\n      width: calc(100% / 1);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
