import Form, { Item, Label } from 'devextreme-react/form'
import { useCallback, useRef } from 'react'
import TitleFunctionCard from './TitleFucntionCard'
import { connect } from 'react-redux'
import produce from 'immer'
import {
  setValueChartPage,
  setHistogramChartPage,
  setPTChartPage,
  setQCChartPage,
  setCSChartPage,
  setParetoChartPage,
  setProbPlotChartPage,
  setScrollBoardPage,
} from 'src/store/common'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

const dispatchPageFunc = {
  valueChart: setValueChartPage,
  histogramChart: setHistogramChartPage,
  positionalTolerancesChart: setPTChartPage,
  qualityControlChart: setQCChartPage,
  capabilitySummaryChart: setCSChartPage,
  paretoChart: setParetoChartPage,
  probabilityPlotChart: setProbPlotChartPage,
  scrollBoard: setScrollBoardPage,
}

function LabelTemplate(iconName) {
  return function template(data) {
    return (
      <div>
        {/* <i className={`dx-icon dx-icon-${iconName}`}></i> */}
        {data.text}
      </div>
    )
  }
}

function LayoutForm({
  pageKey,
  title,
  min,
  max,
  common,
  dispatchPageSettings,
}) {
  const settings = common.pages[pageKey]
  const formRef = useRef(null)
  const formData = useRef({
    column: settings.page.layout.column,
    row: settings.page.layout.row,
  })

  const columnEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Column',
    showSpinButtons: true,
    mode: 'number',
    format: 'fixedPoint',
    min: min,
    max: max,
  }

  const rowEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Row ',
    showSpinButtons: true,
    mode: 'number',
    format: 'fixedPoint',
    min: min,
    max: max,
  }

  const onApply = useCallback(
    async (e) => {
      console.log(`Settings page = ${pageKey}`)
      const { column, row } = formData.current
      console.log(`Column = ${column}`)
      console.log(`Row = ${row}`)

      const nextSettings = produce(settings, (draft) => {
        draft.page.layout.column = column
        draft.page.layout.row = row
      })

      const updatePageSettings = dispatchPageSettings(pageKey)
      updatePageSettings(nextSettings)
    },
    [settings, pageKey, dispatchPageSettings]
  )

  return (
    <TitleFunctionCard
      title={title}
      buttonEnable={true}
      onButtonClick={onApply}
    >
      <form style={{ width: '100%', height: 'auto' }}>
        <Form
          ref={formRef}
          formData={formData.current}
          labelMode={'outside'}
          labelLocation={'top'}
          colCount={2}
        >
          <Item
            dataField={'column'}
            editorType={'dxNumberBox'}
            editorOptions={columnEditorOptions}
            label={{ text: dqTranslateMsg('SidePanel_' + 'Column') }}
          >
            <Label render={LabelTemplate('column')} />
          </Item>
          <Item
            dataField={'row'}
            editorType={'dxNumberBox'}
            editorOptions={rowEditorOptions}
            label={{ text: dqTranslateMsg('SidePanel_' + 'Row') }}
          >
            <Label render={LabelTemplate('row')} />
          </Item>
        </Form>
      </form>
    </TitleFunctionCard>
  )
}

const mapStateToProps = (state) => ({
  common: state.common,
})

const mapDispatchToProps = (dispatch) => ({
  dispatchPageSettings: (pageKey) => (settings) =>
    dispatch(dispatchPageFunc[pageKey](settings)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutForm)
