// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-account-form .policy-info {
  margin: 10px 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-style: normal;
}
.create-account-form .policy-info a {
  color: rgba(255, 255, 255, 0.7);
}
.create-account-form .login-link {
  color: #cddc39;
  font-size: 16px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/create-account-form/CreateAccountForm.scss","webpack://./src/themes/generated/variables.base.scss"],"names":[],"mappings":"AAGE;EACE,cAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;AAFJ;AAII;EACE,+BAAA;AAFN;AAME;EACE,cCfU;EDgBV,eAAA;EACA,kBAAA;AAJJ","sourcesContent":["@import \"../../themes/generated/variables.base.scss\";\r\n\r\n.create-account-form {\r\n  .policy-info {\r\n    margin: 10px 0;\r\n    color: rgba($base-text-color, alpha($base-text-color) * 0.7);\r\n    font-size: 14px;\r\n    font-style: normal;\r\n\r\n    a {\r\n      color: rgba($base-text-color, alpha($base-text-color) * 0.7);\r\n    }\r\n  }\r\n\r\n  .login-link {\r\n    color: $base-accent;\r\n    font-size: 16px;\r\n    text-align: center;\r\n  }\r\n}\r\n","$base-accent: #cddc39;\n$base-text-color: #ffffff;\n$base-bg: #363640;\n$base-border-color: #515159;\n$base-border-radius: 2px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
