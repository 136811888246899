import { Column, DataGrid, GroupPanel, Grouping, MasterDetail } from 'devextreme-react/data-grid'
import { batch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './AdminPushSettings.scss'
import { useEffect, useRef, useState } from 'react'
import { getAdminDefault } from 'src/api/admin/AdminDefaultApi'
import { getAdminCompany, getAdminCompanyList, getAdminCompanyPlantList, updateAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { getAdminGroup, getAdminGroupList, updateAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminCompanyListPrms, makeGetAdminCompanyPlantListPrms, makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { makeGetAdminGroupListPrms, makeGetAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import { makeGetAdminDefaultPrms } from 'src/api/admin/AdminDefaultApiHelper'
import { useApi } from 'src/contexts/api'
import ApiStatus from 'src/api/defines/ApiStatus'
import { CheckBox, TabPanel, TreeList } from 'devextreme-react'
import { ColumnChooser, Item, Selection, Toolbar } from 'devextreme-react/tree-list'
import Button from 'devextreme-react/button'
import { getUserList } from 'src/api/auth'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import AdminPushSettingsData from './data/AdminPushSettingsData'
import AdminPushSettingsPermissions from './permissions/AdminPushSettingsPermissions'
import AdminEndUsers from '../end-users/AdminEndUsers'

const makeDataSourceForTreeView = (dataSource) => {
    let treeData = []
    let idCounter = 100 // Start with a high number to avoid collisions with existing ids
    let companyMap = {}
    let plantMap = {}

    dataSource.forEach((item) => {
        // Company Level
        if (!companyMap[item.company]) {
            const companyNode = { id: idCounter++, name: item.company, level: 'company', parentId: null }
            treeData.push(companyNode)
            companyMap[item.company] = companyNode.id
        }

        // Plant Level
        const companyId = companyMap[item.company]
        const plantKey = `${item.company}-${item.plant}`
        if (!plantMap[plantKey]) {
            const plantNode = { id: idCounter++, name: item.plant, level: 'plant', parentId: companyId }
            treeData.push(plantNode)
            plantMap[plantKey] = plantNode.id
        }

        // Group Level
        const plantId = plantMap[plantKey]
        const groupNode = { id: item.id, name: item.group, level: 'group', parentId: plantId }
        treeData.push(groupNode)
    })

    return treeData
}

const makeDataSourceForDataGrid = () => {
    let settingsList = ['DBInfo', 'Ribbon', 'Encoding', 'FindDistribution']
    let dataSource = settingsList.map((setting, index) => {
        return {
            id: index,
            content: setting,
        }
    })

    return dataSource
}

export default function AdminPushSettings(pageKey) {
    const { spring } = useApi()
    const treeListRef = useRef(null)
    const dataGridRef = useRef(null)
    const common = useSelector((state) => state.common)
    const configPage = common.pages.configuration
    const adminPage = common.pages.adminSettings

    const [adminTreeDataSource, setAdminTreeDataSource] = useState(null)
    const [expandedTreeRowKeys, setExpandedTreeRowKeys] = useState([])
    const [selectedTreeRowKeys, setSelectedTreeRowKeys] = useState([])

    const [adminGridDataSource, setAdminGridDataSource] = useState(null)
    const [expandedGridRowKeys, setExpandedGridRowKeys] = useState([])
    const [selectedGridRowKeys, setSelectedGridRowKeys] = useState([])

    const [checkCompany, setCheckCompany] = useState(false)
    const [checkGroup, setCheckGroup] = useState(false)
    const [checkUser, setCheckUser] = useState(false)
    const [checkDBInfo, setCheckDBInfo] = useState(false)
    const [checkRibbon, setCheckRibbon] = useState(false)
    const [checkEncoding, setCheckEncoding] = useState(false)
    const [checkFindDistribution, setCheckFindDistribution] = useState(false)

    useEffect(() => {
        const getDataForPushSettings = async () => {
            try {
                const companyList = await getAdminCompanyList(spring, makeGetAdminCompanyListPrms())
                const uniqueCList = [...new Set(companyList)]

                const admCompanyPlantList = await Promise.all(
                    uniqueCList.map(async (company) => {
                        const plantList = await getAdminCompanyPlantList(spring, makeGetAdminCompanyPlantListPrms(company))
                        return { company, plantList }
                    })
                )

                const admDataConverted = []

                await Promise.all(
                    admCompanyPlantList.flatMap(async ({ company, plantList }) => {
                        return Promise.all(
                            plantList.map(async (plant) => {
                                const groupList = await getAdminGroupList(spring, makeGetAdminGroupListPrms(company, plant))

                                groupList.forEach((group) => {
                                    admDataConverted.push({ company, plant, group })
                                })
                            })
                        )
                    })
                )

                // Sort by company name
                const sortedData = admDataConverted.sort((a, b) => a.company.localeCompare(b.company))
                sortedData.forEach((item, index) => {
                    item.id = index + 1
                })

                const treeDataSource = makeDataSourceForTreeView(sortedData)
                const allRowTreeKeys = treeDataSource.map((item) => item.id)

                const gridDataSource = makeDataSourceForDataGrid()
                const allRowGridKeys = gridDataSource.map((item) => item.id)

                batch(() => {
                    // Tree
                    setAdminTreeDataSource(treeDataSource)
                    setExpandedTreeRowKeys(allRowTreeKeys)
                    setSelectedTreeRowKeys(allRowTreeKeys)

                    // Grid
                    setAdminGridDataSource(gridDataSource)
                    setExpandedGridRowKeys(allRowGridKeys)
                    setSelectedGridRowKeys(allRowGridKeys)
                })
            } catch (error) {
                console.error('Error fetching push settings data:', error)
            }
        }

        getDataForPushSettings()
    }, [])

    const onTreeListSelectionChanged = (e) => {
        setSelectedTreeRowKeys(e.selectedRowKeys)
    }
    const onDataGridSelectionChanged = (e) => {
        setSelectedGridRowKeys(e.selectedRowKeys)
    }

    const handleClickPushSettings = (e) => {
        console.log('handleClickPushSettings')
        const pushTargetItem = dataGridRef.current.instance.getSelectedRowsData()
        const pushTargetUser = treeListRef.current.instance.getSelectedRowsData()

        if (!pushTargetItem || pushTargetItem.length === 0) {
            console.error('not Selected Data')
            return
        }

        if (!pushTargetUser || pushTargetUser.length === 0) {
            console.error('not Selected User')
            return
        }

        console.log('pushTargetItem')
        console.log(pushTargetItem)
        console.log('pushTargetUser')
        console.log(pushTargetUser)
        console.log('handleClickPushSettings End')
    }

    useEffect(() => {
        if (treeListRef.current) {
            treeListRef.current.instance.selectAll()
        }
        if (dataGridRef.current) {
            dataGridRef.current.instance.selectAll()
        }
    }, [])

    return (
        <div
            className='dx-theme-background-color'
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            <TabPanel
                className='tab-panel-item-container'
                showNavButtons={true}
                repaintChangesOnly={true}
            >
                <Item
                    className='grid-tab-container'
                    title={dqTranslateMsg('Page_AdminSettings_' + 'Company') + ' / ' + dqTranslateMsg('Page_AdminSettings_' + 'Plant')}
                >
                    <AdminEndUsers pageKey={pageKey} />
                </Item>
                {/* <Item title={getPageMsg(msgType.AdminSettings, 'Permissions')}>
                    <AdminPushSettingsPermissions pageKey={pageKey} />
                </Item> */}
                <Item title={getPageMsg(msgType.AdminSettings, 'Data')}>
                    <AdminPushSettingsData pageKey={pageKey} />
                </Item>
            </TabPanel>
        </div>
    )
}
