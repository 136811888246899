// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#side-panel-correlation-chart-theme-form .dx-form-group-with-caption {
  padding-top: 5px;
  border-top: 1px solid #515159;
}

#side-panel-correlation-chart-theme-form .dx-form-group-content {
  border-top: 0px solid #515159;
  padding: 0px;
  padding-left: 0px;
}

#side-panel-correlation-chart-theme-form .dx-field-item {
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
}

#side-panel-correlation-chart-theme-form .dx-field-button-item {
  padding-left: 2px;
  padding-right: 2px;
  width: "100%";
  height: "100%";
}

#side-panel-correlation-chart-theme-form .dx-field-item-content {
  width: auto;
  flex-grow: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/chart-correlation/components/side-panel/SidePanelCorrelationChartTheme.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,6BAAA;AACF;;AAEA;EACE,6BAAA;EACA,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":["#side-panel-correlation-chart-theme-form .dx-form-group-with-caption {\r\n  padding-top: 5px;\r\n  border-top: 1px solid #515159;\r\n}\r\n\r\n#side-panel-correlation-chart-theme-form .dx-form-group-content {\r\n  border-top: 0px solid #515159;\r\n  padding: 0px;\r\n  padding-left: 0px;\r\n}\r\n\r\n#side-panel-correlation-chart-theme-form .dx-field-item {\r\n  padding-left: 2px;\r\n  padding-right: 2px;\r\n  padding-top: 3px;\r\n  padding-bottom: 3px;\r\n}\r\n\r\n#side-panel-correlation-chart-theme-form .dx-field-button-item {\r\n  padding-left: 2px;\r\n  padding-right: 2px;\r\n  width: '100%';\r\n  height: '100%';\r\n}\r\n\r\n#side-panel-correlation-chart-theme-form .dx-field-item-content {\r\n  width: auto;\r\n  flex-grow: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
