import { backendPath } from './defines/DBInfo'
import JwtUtils from 'src/functions/JwtUtils'
import ApiStatus from 'src/api/defines/ApiStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
const path = backendPath

export const SUCCESS = 0

export const ERR_NOT_A_MEMBER = 1000

export const ERR_PARAMETER_INVALID = 2000

export const ERR_SAVE_FAIL = 3000

export const ERR_UNDEFINED_ERROR = 9999

function getTimeFormatted(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${year}${month}${day}${hours}${minutes}${seconds}`
}

export const setValueInfo = async (user, requestMsg) => {
    const company = user.user_company
    const plant = user.user_plant
    const group = user.user_group

    const makeReqBody = (requestMsg) => {
        let requestBody = { company: company, plant: plant, group: group, requestMsg }

        return requestBody
    }

    try {
        const accessToken = JwtUtils.loadToken()
        const requestBody = JSON.stringify(makeReqBody(requestMsg))

        const response = await fetch(path + 'data/setValueInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: requestBody,
        })

        const result = await response.json()
        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }
        return result.data
    } catch (error) {
        throw error
    }
}

export const deleteValue = async (user, info) => {
    const company = user.user_company
    const plant = user.user_plant
    const group = user.user_group

    const makeReqBody = (info) => {
        let requestBody = {
            company: company,
            plant: plant,
            group: group,
            part_id: info.part_id,
            char_id: info.char_id,
            value_id: info.value_id,
        }

        return requestBody
    }

    try {
        const accessToken = JwtUtils.loadToken()
        const requestBody = JSON.stringify(makeReqBody(info))

        const response = await fetch(path + 'data/deleteValue', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: requestBody,
        })

        const result = await response.json()
        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        return result.data
    } catch (error) {
        throw error
    }
}
