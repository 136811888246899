import { withNavigationWatcher } from './contexts/navigation'
import {
  DevelopmentFunctionPage,
  AdminPage,
  CapabilitySummaryChartPage,
  ChartBoxPlotPage,
  ChartCorrelationPage,
  ChartHistogramPage,
  ChartLinearRegressionPage,
  ChartPositionalTolerancesPage,
  ChartQCCPage,
  ChartValuePage,
  ConfigPage,
  DatabasePage,
  DecisionTreeChartPage,
  InfoCompanyPage,
  ListSummaryPage,
  ListValuePage,
  ListValueSummaryPage,
  MonitoringPage,
  OverviewPage,
  ParetoChartPage,
  ProbabilityPlotChartPage,
  ProfilePage,
  ScrollBoardPage,
  ReportPrintPage,
  ReportPrintMultiFunctionPage,
  UploadFilePage,
  TasksPage,
} from './pages'
import { pageName } from 'src/functions/constants/pageName'

const routes = [
  {
    path: '/tasks',
    element: TasksPage,
  },
  {
    path: '/profile',
    element: ProfilePage,
  },
  {
    path: '/database',
    element: () => DatabasePage(pageName.database),
  },
  {
    path: '/overview',
    element: () => OverviewPage(pageName.overview),
  },
  {
    path: '/monitoring',
    element: () => MonitoringPage(pageName.monitoring),
  },
  {
    path: '/list-value-summary',
    element: () => ListValueSummaryPage(pageName.valueSummaryList),
  },
  {
    path: '/list-value',
    element: () => ListValuePage(pageName.valueList),
  },
  {
    path: '/list-summary',
    element: () => ListSummaryPage(pageName.summaryList),
  },
  {
    path: '/info-company',
    element: InfoCompanyPage,
  },
  {
    path: '/chart-value',
    element: () => ChartValuePage(pageName.valueChart),
  },
  {
    path: '/chart-quality-control',
    element: () => ChartQCCPage(pageName.qualityControlChart),
  },
  {
    path: '/chart-positional-tolerances',
    element: () => ChartPositionalTolerancesPage(pageName.positionalTolerancesChart),
  },
  {
    path: '/chart-linear-regression',
    element: () => ChartLinearRegressionPage(pageName.linearRegressionChart),
  },
  {
    path: '/chart-histogram',
    element: () => ChartHistogramPage(pageName.histogramChart),
  },
  {
    path: '/chart-correlation',
    element: () => ChartCorrelationPage(pageName.correlationChart),
  },
  {
    path: '/chart-box-plot',
    element: () => ChartBoxPlotPage(pageName.boxPlotChart),
  },
  {
    path: '/chart-probability-plot',
    element: () => ProbabilityPlotChartPage(pageName.probabilityPlotChart),
  },
  {
    path: '/chart-pareto',
    element: () => ParetoChartPage(pageName.paretoChart),
  },
  {
    path: '/chart-decision-tree',
    element: () => DecisionTreeChartPage(pageName.decisionTreeChart),
  },
  {
    path: '/chart-capability-summary',
    element: () => CapabilitySummaryChartPage(pageName.capabilitySummaryChart),
  },
  {
    path: '/scroll-board',
    element: () => ScrollBoardPage(pageName.scrollBoard),
  },
  {
    path: '/report-print',
    element: () => ReportPrintPage(pageName.reportPrint),
  },
  {
    path: '/report-print-multi-function',
    element: () => ReportPrintMultiFunctionPage(pageName.reportPrintMultiFunction),
  },
  {
    path: '/upload-file',
    element: () => UploadFilePage(pageName.uploadFile),
  },
  {
    path: '/admin',
    element: () => AdminPage(pageName.adminSettings),
  },
  {
    path: '/config',
    element: () => ConfigPage(pageName.configuration),
  },
  {
    path: '/development-functions',
    element: DevelopmentFunctionPage,
  },
]

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  }
})
