import styled from 'styled-components'
import React, { useEffect, useRef } from 'react'
import service from './ScrollBoardDiagramImpl.js'
import ArrayStore from 'devextreme/data/array_store'
import Diagram, { Nodes, Edges } from 'devextreme-react/diagram'

const StyledDiagram = styled(Diagram)`
  width: 100%;
  height: 100%;
`
const flowNodesDataSource = new ArrayStore({
  key: 'id',
  data: service.getFlowNodes(),
})
const flowEdgesDataSource = new ArrayStore({
  key: 'id',
  data: service.getFlowEdges(),
})

function convertDataSource(data) {
  console.log(data)
  let converted = []
  data.map((item, index) => {
    converted.push({
      id: index,
      result_id: item.result_id,
      result_key: item.result_key,
      result_field: item.result_field,
      result_user_id: item.result_user_id,
      result_key: item.result_key,
      result_good_count: item.result_good_count,
      result_warning_count: item.result_warning_count,
      result_ng_count: item.result_ng_count,
      result_total_count: item.result_total_count,
    })
  })

  return converted
}

export default function ScrollBoardDiagram({
  id,
  style,
  pageLength,
  currentStep,
  setCurrentStep,
}) {
  // const onSeriesClick = () => {
  //   const nextStep = currentStep < pageLength ? currentStep + 1 : 1
  //   setCurrentStep(nextStep)
  // }
  return (
    <div id={id} style={style}>
      <StyledDiagram autoZoomMode="fitContent">
        <Nodes dataSource={flowNodesDataSource} keyExpr="id" textExpr="text" />
        <Edges
          dataSource={flowEdgesDataSource}
          keyExpr="id"
          fromExpr="from"
          toExpr="to"
        />
      </StyledDiagram>
    </div>
  )
}
