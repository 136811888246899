// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.dx-theme-background-color .company-copyright-content-container {
  width: 100%;
  height: 100%;
}
.dx-theme-background-color .company-powered-by {
  font-size: 15px;
  text-align: right;
  vertical-align: middle;
}
.dx-theme-background-color .company-copyright {
  font-size: 15px;
  text-align: right;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/pages/info-company/components/main-view/CompanyCopyRightForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,sBAAA;AAAJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,sBAAA;AAAJ","sourcesContent":[".dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n\r\n  .company-copyright-content-container {\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n\r\n  .company-powered-by {\r\n    font-size: 15px;\r\n    text-align: right;\r\n    vertical-align: middle;\r\n  }\r\n  .company-copyright {\r\n    font-size: 15px;\r\n    text-align: right;\r\n    vertical-align: middle;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
