import React from 'react'
import { EmptyItem, GroupItem } from 'devextreme-react/form'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

const AutoPageOption = (pageKey, pageState, configState) => {
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  const sbPageSettings = pageState

  const getMaxCountRestartIndex = () => {
    const orderChartField = sbPageSettings.page.orderChartField
    const fieldData = orderChartField.split('|')
    return fieldData.length
  }

  const onNumberBoxRestartIndexValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const valueValid = value === null ? minOption : value
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = valueValid > getMaxCountRestartIndex() ? getMaxCountRestartIndex() : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxLayoutValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onCheckBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`auto-page-option-group-item`} colCount={6} caption={dqTranslateMsg('SidePanel_' + 'AutoPage')}>
      {NumberBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'LayoutColumn'),
        labelLocation: 'top',
        name: 'autoPageValueChartLayoutX',
        min: 1,
        max: 3,
        step: 1,
        // value: sbPageSettings.page.autoPageValueChartLayoutX,
        value: sbPageSettings.page.autoPageValueChartLayoutX,
        onValueChanged: (e) => onNumberBoxLayoutValueChanged(e, 'autoPageValueChartLayoutX'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'LayoutRow'),
        labelLocation: 'top',
        name: 'autoPageValueChartLayoutY',
        min: 1,
        max: 3,
        step: 1,
        // value: sbPageSettings.page.autoPageValueChartLayoutY,
        value: sbPageSettings.page.autoPageValueChartLayoutY,
        onValueChanged: (e) => onNumberBoxLayoutValueChanged(e, 'autoPageValueChartLayoutY'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'RestartIndex'),
        labelLocation: 'top',
        name: 'autoPageRestartIndex',
        min: getMaxCountRestartIndex() === 0 ? 0 : 1,
        max: getMaxCountRestartIndex(),
        step: 1,
        value: sbPageSettings.page.autoPageRestartIndex > getMaxCountRestartIndex() ? getMaxCountRestartIndex() : sbPageSettings.page.autoPageRestartIndex,
        onValueChanged: (e) => onNumberBoxRestartIndexValueChanged(e, 'autoPageRestartIndex'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'PageUpdateSec'),
        labelLocation: 'top',
        name: 'autoPageUpdateTime',
        min: 5,
        max: 100000,
        step: 1,
        value: sbPageSettings.page.autoPageUpdateTime,
        unit: 'SecondsShort',
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'autoPageUpdateTime'),
      })}
      <EmptyItem />
      <EmptyItem />
      <EmptyItem />
      <EmptyItem />
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'GraphicUpdateEnable'),
        labelLocation: 'right',
        name: 'autoGraphicUpdateTimeEnable',
        value: sbPageSettings.page.autoGraphicUpdateTimeEnable,
        disable: false,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'autoGraphicUpdateTimeEnable'),
      })}
      <EmptyItem />
      <EmptyItem />
      <EmptyItem />
      <EmptyItem />
      {NumberBoxSimpleItem({
        colSpan: 4,
        label: dqTranslateMsg('SidePanel_' + 'GraphicUpdateMin'),
        labelLocation: 'top',
        name: 'autoGraphicUpdateTime',
        min: 1,
        max: 100000,
        step: 1,
        value: sbPageSettings.page.autoGraphicUpdateTime,
        disabled: !sbPageSettings.page.autoGraphicUpdateTimeEnable,
        unit: 'MinutesShort',
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'autoGraphicUpdateTime'),
      })}
    </GroupItem>
  )
}

export default AutoPageOption
