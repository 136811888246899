export function getInvertedRGBA(rgbaString) {
  try {
    // Step 1: Extract RGBA
    const rgbaPattern = /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d(\.\d+)?|1|0(\.\d+)?)\)$/
    const match = rgbaString.match(rgbaPattern)

    if (!match) {
      throw new Error("Invalid RGBA format. Expected format: 'rgba(R, G, B, A)'.")
    }

    const r = parseInt(match[1], 10)
    const g = parseInt(match[2], 10)
    const b = parseInt(match[3], 10)
    const a = parseFloat(match[4])

    if ([r, g, b].some((c) => c < 0 || c > 255) || a < 0 || a > 1) {
      throw new Error('RGBA values out of range. RGB should be 0-255 and Alpha should be 0-1.')
    }

    // Step 2: Invert RGBA
    const invertedRGBA = [255 - r, 255 - g, 255 - b, a]

    // Step 3: Reconstruct RGBA String
    const invertedRGBAString = `rgba(${invertedRGBA[0]}, ${invertedRGBA[1]}, ${invertedRGBA[2]}, ${invertedRGBA[3]})`

    // Output
    console.log('Original RGBA:', rgbaString)
    console.log('Inverted RGBA:', invertedRGBAString)

    return invertedRGBAString
  } catch (error) {
    console.error(error.message)
    return null
  }
}

const getViewPortTheme = (theme) => {
  return theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'
}

export function setBorderColor(theme) {
  let borderColor = 'black'

  if (getViewPortTheme(theme) === 'dark') {
    borderColor = 'white'
  }

  return borderColor
}
