import { EmptyItem, GroupItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import TextAreaSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextAreaSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

export default function SettingsDisplayValueOption(props) {
    const dispatch = useDispatch()
    const pageState = props.stateAll
    const setState = props.setState

    const onNumberBoxDisplayDecimalPlacesValueChanged = (e, dataField) => {
        if (e.event === undefined) return
        const value = e.value
        const minOption = e.component.option('min')
        const next = produce(pageState, (draft) => {
            draft.commonSettings[dataField] = value === null ? minOption : value
        })
        // dispatch(ps[pageKey].setPageSettingsToRedux(next))
        setState(next)
    }

    const onNumberBoxValueChange = (e, dataField) => {
        if (e.event === undefined) return

        let value = 0
        const maxOption = 20
        if (e.value === null) {
            value = maxOption
        } else {
            value = 100 - e.value
        }
        // const value = 100 - e.value

        const next = produce(pageState, (draft) => {
            draft.commonSettings[dataField] = value === null ? maxOption : value
        })
        // dispatch(ps[pageKey].setPageSettingsToRedux(next))
        setState(next)
    }

    const onCheckBoxValueChanged = (e, dataField) => {
        if (e.event === undefined) return
        const value = e.value
        const next = produce(pageState, (draft) => {
            draft.commonSettings[dataField] = value
        })
        // dispatch(ps[pageKey].setPageSettingsToRedux(next))
        setState(next)
    }

    return (
        <GroupItem
            key={`common-display-value-option-group-item`}
            colCount={2}
            caption={dqTranslateMsg('Page_Configuration_' + 'DisplayValueOption')}
        >
            {CheckBoxSimpleItem({
                ref: null,
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'DisplayFieldNameWithNumber'),
                labelLocation: 'right',
                name: 'displayFieldNameWithNumber',
                value: pageState.commonSettings.displayFieldNameWithNumber,
                disabled: false,
                onValueChanged: (e) => onCheckBoxValueChanged(e, 'displayFieldNameWithNumber'),
            })}
            <EmptyItem />
            {NumberBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('Page_Configuration_' + 'DefaultDecimalPlaces'),
                labelLocation: 'top',
                name: 'displayDecimalPlaces',
                min: 0,
                max: 15,
                step: 1,
                value: pageState.commonSettings.displayDecimalPlaces,
                disabled: false,
                onValueChanged: (e) => onNumberBoxDisplayDecimalPlacesValueChanged(e, 'displayDecimalPlaces'),
            })}
            {NumberBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('Page_Configuration_' + 'DefaultCalculatedDecimalPlaces'),
                labelLocation: 'top',
                name: 'calcDisplayDecimalPlaces',
                min: 0,
                max: 15,
                step: 1,
                value: pageState.commonSettings.calcDisplayDecimalPlaces,
                disabled: false,
                onValueChanged: (e) => onNumberBoxDisplayDecimalPlacesValueChanged(e, 'calcDisplayDecimalPlaces'),
            })}
            {NumberBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('Page_Configuration_' + 'ExponentialPosition'),
                labelLocation: 'top',
                name: 'isExponentialActive',
                min: 0,
                max: 15,
                step: 1,
                value: pageState.commonSettings.isExponentialActive,
                disabled: false,
                onValueChanged: (e) => onNumberBoxDisplayDecimalPlacesValueChanged(e, 'isExponentialActive'),
            })}
        </GroupItem>
    )
}
