export const valueChartSettingsPrm = {
  page: {
    layout: {
      column: 1,
      row: 1,
    },
    current_sel: { part_id: null, char_id: null },
    splitItem: [],
    xAxisLabelItems: ['v0014'],
    dateTimeLabelDisplayFormat: 'date/time',
    xAxisDenseLabel: true,
    toolTipInfoItems: ['v0001', 'v0004', 'v0010', 'v0014'],
    dateTimeTooltipFormat: 'date/time/sec',
    // toolTipUseSpecLimits: true,
    // toolTipUseWarnLimits: true,
    specificationLimitEnable: true,
    warningLimitEnable: true,
    averageLimitEnable: true,
    nominalLimitEnable: true,
    plausibilityLimitEnable: true,
    scrapLimitEnable: true,
    acceptanceLimitEnable: true,
    specificationLimitLabelEnable: true,
    warningLimitLabelEnable: true,
    averageLimitLabelEnable: true,
    nominalLimitLabelEnable: true,
    plausibilityLimitLabelEnable: true,
    scrapLimitLabelEnable: true,
    acceptanceLimitLabelEnable: true,
    decimalPlaces: 3,
    fontSize: 18,
    arima: {
      show: false,
      pValue: 2,
      dValue: 0,
      qValue: 2,
      forecastSize: 100,
      sampleSize: 200,
      auto: false,
    },
    showDeletedValue: false,
  },
  lineChart: {
    symbols: {
      error: {
        upper: {
          shape: 'triangle',
          size: 5,
          color: 'rgba(255, 0, 0, 1)',
        },
        lower: {
          shape: 'path://M 100 100 L 300 100 L 200 300 z',
          size: 5,
          color: 'rgba(255, 0, 0, 1)',
        },
      },
      warn: {
        upper: {
          shape: 'circle',
          size: 4,
          color: 'rgba(255, 136, 0, 1)',
        },
        lower: {
          shape: 'circle',
          size: 4,
          color: 'rgba(255, 136, 0, 1)',
        },
      },
      good: {
        shape: 'none',
        size: 4,
        color: 'rgba(2, 152, 212, 1)',
      },
    },
    chartLines: {
      type: 'solid',
      width: 1,
      color: 'rgba(2, 152, 212, 1)',
    },
    arimaChartLines: {
      outer: {
        color: 'rgba(255, 0, 0, 1)',
      },
      inner: {
        color: 'rgba(55, 0, 255, 1)',
      },
    },
    markLines: {
      precision: 10,
      specLimit: {
        show: true,
        type: 'solid',
        color: 'rgba(255, 0, 0, 1)',
        width: 1,
      },
      warnLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(255, 234, 3, 1)',
        width: 1,
      },
      controlLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 127, 207, 1)',
        width: 1,
      },
      center: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 127, 207, 1)',
        width: 1,
      },
      nominal: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      average: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      plausibilityLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      scrapLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      acceptanceLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
    },
    markAreas: {
      error: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 8, 8, 0.09)',
      },
      warn: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 255, 0, 0.11)',
      },
      good: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 255, 0, 0)',
      },
    },
  },
}
