import ApiStatus from '../defines/ApiStatus'

// Delete Admin Default
export const deleteAdminDefault = async (apiClient, data) => {
  try {
    const response = await apiClient.post('admin/deleteAdminDefault', data) // Adjust the endpoint as needed
    if (!ApiStatus.isSuccess(response.status)) {
      throw new Error(getErrCommMsg(response.status))
    }
    return response.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}

// Get Admin Default
export const getAdminDefault = async (apiClient, data) => {
  try {
    const response = await apiClient.post('admin/getAdminDefault', data) // Adjust the endpoint as needed
    if (!ApiStatus.isSuccess(response.status)) {
      throw new Error(getErrCommMsg(response.status))
    }
    return response.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}

// Update Admin Default
export const updateAdminDefault = async (apiClient, data) => {
  try {
    const response = await apiClient.post('admin/updateAdminDefault', data) // Adjust the endpoint as needed
    if (!ApiStatus.isSuccess(response.status)) {
      throw new Error(getErrCommMsg(response.status))
    }
    return response.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}
