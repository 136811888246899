import React from 'react'
import { GroupItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
export function GraphicsTheme(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  const sbPageSettings = pageState

  const onColorBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={'graphics-theme-group-item'} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'QualityStatusColors')}>
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'GoodColor'),
        labelLocation: 'top',
        name: 'graphicGoodSetting',
        value: sbPageSettings.theme.graphicGoodSetting,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'graphicGoodSetting'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'WarnColor'),
        labelLocation: 'top',
        name: 'graphicWarningSetting',
        value: sbPageSettings.theme.graphicWarningSetting,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'graphicWarningSetting'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'BadColor'),
        labelLocation: 'top',
        name: 'graphicBadSetting',
        value: sbPageSettings.theme.graphicBadSetting,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'graphicBadSetting'),
      })}
    </GroupItem>
  )
}
