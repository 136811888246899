import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import produce from 'immer'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import DateBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/DateBoxSimpleItem'
import { GroupItem } from 'devextreme-react/form'

function formatDateBoxValue(dateString) {
  const year = parseInt(dateString.substring(0, 4), 10)
  const month = parseInt(dateString.substring(4, 6), 10) - 1 // Month is 0-indexed
  const day = parseInt(dateString.substring(6, 8), 10)
  const hour = parseInt(dateString.substring(8, 10), 10)
  const minute = parseInt(dateString.substring(10, 12), 10)
  const second = parseInt(dateString.substring(12, 14), 10)

  return new Date(year, month, day, hour, minute, second)
}

function convertToCustomFormat(devExpressDate) {
  const year = devExpressDate.getFullYear()
  const month = (devExpressDate.getMonth() + 1).toString().padStart(2, '0')
  const day = devExpressDate.getDate().toString().padStart(2, '0')
  const hours = devExpressDate.getHours().toString().padStart(2, '0')
  const minutes = devExpressDate.getMinutes().toString().padStart(2, '0')
  const seconds = devExpressDate.getSeconds().toString().padStart(2, '0')

  return `${year}${month}${day}${hours}${minutes}${seconds}`
}

export default function DateTime(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'monitoring'
  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onRadioGroupDisplayTypeValueChanged = (e, dataField) => {
    const next = produce(pageState, (draft) => {
      const curTime = new Date()
      const preTime = new Date()
      if (e.value === 'custom') {
        draft.page[dataField] = e.value
      } else if (e.value === '1minute') {
        draft.page[dataField] = e.value
        preTime.setMinutes(preTime.getMinutes() - 1)
      } else if (e.value === '1hour') {
        draft.page[dataField] = e.value
        preTime.setHours(preTime.getHours() - 1)
      } else if (e.value === '1day') {
        draft.page[dataField] = e.value
        preTime.setDate(preTime.getDate() - 1)
      } else {
        // 1week
        draft.page[dataField] = e.value
        preTime.setDate(preTime.getDate() - 7)
        draft.page.endDateTime = convertToCustomFormat(curTime)
        draft.page.startDateTime = convertToCustomFormat(preTime)
      }
    })

    dispatch(ps.monitoring.setPageSettingsToRedux(next))
  }

  const onDateBoxStartDateTimeValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const selectedDate = new Date(value)
    const converted = format(selectedDate, 'yyyyMMddHHmmss')
    const endTime = parseInt(pageState.page.endDateTime)

    if (parseInt(converted) > endTime) {
      // Reset to previous value and show an error
      const oldValue = e.previousValue
      e.component.option('value', oldValue)
      alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgInvalidDateTime'))
    } else {
      const next = produce(pageState, (draft) => {
        draft.page[dataField] = converted
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  const onDateBoxEndDateTimeValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const selectedDate = new Date(value)
    const converted = format(selectedDate, 'yyyyMMddHHmmss')
    const startTime = parseInt(pageState.page.startDateTime)

    if (startTime > parseInt(converted)) {
      // Show an error if end time is less than start time
      alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgInvalidDateTime'))
      // Optionally reset to previous value if needed
      const oldValue = e.previousValue
      e.component.option('value', oldValue)
    } else {
      const next = produce(pageState, (draft) => {
        draft.page[dataField] = converted
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  return (
    <GroupItem key={`date-time-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DateTime')}>
      {RadioGroupSimpleItem({
        ref: null,
        colSpan: 2,
        label: '',
        labelLocation: 'top',
        name: 'type',
        items: sd.monitoringIntervalDefine(),
        layout: 'vertical',
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.easySettingsDateTime,
        disabled: false,
        onValueChanged: (e) => onRadioGroupDisplayTypeValueChanged(e, 'easySettingsDateTime'),
      })}
      {DateBoxSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'StartDateTime'),
        labelLocation: 'top',
        name: 'customStartDateTime',
        pickerType: 'calendar',
        displayFormat: 'yyyy-MM-dd HH:mm:ss',
        value: formatDateBoxValue(pageState.page.customStartDateTime),
        disabled: pageState.page.easySettingsDateTime !== 'custom',
        onValueChanged: (e) => onDateBoxStartDateTimeValueChanged(e, 'customStartDateTime'),
      })}
      {DateBoxSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'EndDateTime'),
        labelLocation: 'top',
        name: 'customEndDateTime',
        pickerType: 'calendar',
        displayFormat: 'yyyy-MM-dd HH:mm:ss',
        value: formatDateBoxValue(pageState.page.customEndDateTime),
        disabled: pageState.page.easySettingsDateTime !== 'custom',
        onValueChanged: (e) => onDateBoxEndDateTimeValueChanged(e, 'customEndDateTime'),
      })}
    </GroupItem>
  )
}
