import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function ThemeQCCWarningSymbol(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onSelectBoxSymbolShapeValueChanged = (e, dataField) => {
    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      draft.theme.qualityControlChart.lineChart.symbols.warn.upper[dataField] = value
      draft.theme.qualityControlChart.lineChart.symbols.warn.lower[dataField] = value === 'triangle' ? 'path://M 100 100 L 300 100 L 200 300 z' : value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxSymbolSizeValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.theme.qualityControlChart.lineChart.symbols.warn.upper[dataField] = value === null ? minOption : value
      draft.theme.qualityControlChart.lineChart.symbols.warn.lower[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onColorBoxSymbolColorValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.theme.qualityControlChart.lineChart.symbols.warn.upper[dataField] = value
      draft.theme.qualityControlChart.lineChart.symbols.warn.lower[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`theme-warning-symbol-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'WarningSymbol')}>
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Shape'),
        labelLocation: 'top',
        name: 'shape',
        items: sd.symbolShapeDefine(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.theme.qualityControlChart.lineChart.symbols.warn.upper.shape,
        disabled: false,
        onItemClick: (e) => onSelectBoxSymbolShapeValueChanged(e, 'shape'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Size'),
        labelLocation: 'top',
        name: 'size',
        min: 1,
        max: 20,
        step: 1,
        value: pageState.theme.qualityControlChart.lineChart.symbols.warn.upper.size,
        onValueChanged: (e) => onNumberBoxSymbolSizeValueChanged(e, 'size'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'Color'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.theme.qualityControlChart.lineChart.symbols.warn.upper.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxSymbolColorValueChanged(e, 'color'),
      })}
    </GroupItem>
  )
}
