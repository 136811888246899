import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function SettingsRelationLevel() {
  const dispatch = useDispatch()
  const pageKey = 'correlationChart'

  const pageState = useSelector((state) => state.common.pages[pageKey])

  const onNumberBoxRelationLevelValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page.relation.level[dataField] = value === null ? minOption : value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`relation-level-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'RelationLevel')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Value'),
        labelLocation: 'top',
        name: 'strong',
        min: 0,
        max: 1,
        step: 0.01,
        value: pageState.page.relation.level.strong,
        disabled: pageState.page.displayMethod === 'heatMap',
        onValueChanged: (e) => onNumberBoxRelationLevelValueChanged(e, 'strong'),
      })}
    </GroupItem>
  )
}
