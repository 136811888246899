import { Form, ScrollView } from 'devextreme-react'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import DQSaveReset from 'src/functions/DQSaveReset'
import DQToast from 'src/functions/DQToast'
import { qualityControlChartSettingsPrm } from 'src/pages/chart-quality-control/components/settings/QualityControlChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import './SidePanelQualityControlChartSettings.scss'
import SettingsLayout from './components/settings/SettingsLayout'
import SettingsLimitsEnable from './components/settings/SettingsLimitsEnable'
import SettingsLimitsLabelEnable from './components/settings/SettingsLimitsLabelEnable'
import SettingsXAxis from './components/settings/SettingsXAxis'
import SettingsQccOption from './components/settings/SettingsQccOption'
import SettingsQccChartOption from './components/settings/SettingsQccChartOption'

export default function SidePanelQualityControlChartSettings(props) {
    const { pageKey } = props
    const dispatch = useDispatch()

    const [scrollPosition, setScrollPosition] = useState(0)
    const { user } = useAuth()
    const parentRef = useRef(null)
    const toastRef = useRef(null)

    const common = useSelector((state) => state.common)
    const othersPage = common.pages.others
    const curPage = common.pages.qualityControlChart
    const configPage = common.pages.configuration

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }

    const handleCurPageSettingsSave = (updatePageData) => {
        dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
    }
    const handleSaveButtonClicked = () => {
        const next = produce(curPage, (draft) => {
            Object.keys(curPage).forEach((key) => {
                draft[key] = curPage[key]
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }
    const handleResetButtonClicked = () => {
        const next = produce(curPage, (draft) => {
            Object.entries(qualityControlChartSettingsPrm.page).forEach(([key, value]) => {
                draft.page[key] = value
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    const handleScroll = (e) => {
        setScrollPosition(e.scrollOffset.top)
    }

    // const items = [SettingsLayout(), SettingsXAxis(), SettingsToolTipXBar(), SettingsToolTipRange(), SettingsLimitsEnable(), SettingsLimitsLabelEnable()]
    const items = (pageState, configState) => [
        SettingsLayout(pageKey, pageState, configState),
        SettingsQccOption(pageKey, pageState, configState),
        SettingsQccChartOption(pageKey, pageState, configState),
        SettingsXAxis(pageKey, pageState, configState),
        SettingsLimitsEnable(pageKey, pageState, configState),
        SettingsLimitsLabelEnable(pageKey, pageState, configState),
    ]

    return (
        <div
            ref={parentRef}
            style={{ padding: '5px', width: '100%', height: '100%' }}
        >
            <DQToast ref={toastRef} />
            <div style={{ width: '100%', height: '3%' }}>
                <DQSaveReset
                    saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
                    resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
                    saveCallback={handleSaveButtonClicked}
                    resetCallback={handleResetButtonClicked}
                />
            </div>
            <div style={{ width: '100%', height: '97%' }}>
                <ScrollView
                    className='side-panel-tab-container'
                    showScrollbar={'onHover'}
                    useNative={false}
                    onScroll={handleScroll}
                    onContentReady={(e) => {
                        e.component.scrollTo({ top: scrollPosition })
                    }}
                >
                    <Form
                        id='side-panel-qcc-chart-settings-form'
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        formData={curPage.page}
                        showColonAfterLabel={false}
                    >
                        {items(curPage, configPage)}
                    </Form>
                </ScrollView>
            </div>
        </div>
    )
}
