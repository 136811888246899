import styled from 'styled-components'
import IL from 'src/functions/IconLoader'
import { useTheme } from 'src/contexts/theme'
import HeaderIcon from 'src/functions/HeaderIcon'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

const StyledImageNav = styled(ImageNav)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.theme.selected ? props.theme.hoverColor : props.theme.nHoverColor)};
  width: calc(100% / ${(props) => props.theme.count});
  height: 100%;
  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
  }
`
const convertTranslation = (field) => {
  let keyPrefix = ''
  let fieldPrefix = ''
  let numberField = Number(field)
  if (numberField >= 1000 && numberField < 2000) {
    fieldPrefix = 'P'
  } else if (numberField >= 2000 && numberField < 9000) {
    fieldPrefix = 'C'
  } else {
    fieldPrefix = 'V'
  }

  switch (fieldPrefix) {
    case 'P':
      keyPrefix = 'Db_field_Part_'
      break
    case 'C':
      keyPrefix = 'Db_field_Characteristics_'
      break
    case 'V':
      keyPrefix = 'Db_field_Value_'
      break
    default:
      // 기본값이 필요한 경우 처리
      keyPrefix = 'Db_field_Default_'
      break
  }

  const formatted = field.toString().padStart(4, '0')

  const translated = dqTranslateMsg(keyPrefix + fieldPrefix + formatted)
  return translated
}

export default function ImageNavs(props) {
  const { className, data, configPage, onClick } = props
  const { setTheme, getTheme } = useTheme()

  const renderAllImageNav = (count, types, configPage, onClick) => {
    const hoverColor = getTheme().includes('light') ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)'
    const nHoverColor = getTheme().includes('light') ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)'

    return types.map((item, index) => {
      const selected = data.pageNo === index + 1 ? true : false
      return (
        <StyledImageNav
          key={`image-nav-${index + 1}`}
          id={`${item.type}_${index + 1}`}
          theme={{
            count: count,
            hoverColor: hoverColor,
            nHoverColor: nHoverColor,
            selected: selected,
          }}
          data={{
            field: item.field,
            type: item.type,
            index: index,
          }}
          configPage={configPage}
          // onClick={onClick}
        />
      )
    })
  }

  return <div className={className}>{renderAllImageNav(data.imageCount, data.imageTypes, configPage, onClick)}</div>
}

function ImageNav(props) {
  const { className, key, id, data, configPage, onClick } = props
  const { setTheme, getTheme } = useTheme()

  const path = (type) => {
    const mode = HeaderIcon.getIconMenuPath(configPage.menuIconType)
    const theme = getTheme()

    switch (type) {
      case 'BarChart':
        return IL.getNavImgPath(mode, theme, IL.NAV.HISTOGRAM_CHART)
      case 'Diagram':
        return IL.getNavImgPath(mode, theme, IL.NAV.SUMMARY_LIST)
      case 'TreeMap':
        return IL.getNavImgPath(mode, theme, IL.NAV.VALUE_LIST)
      case 'ValueChart':
        return IL.getNavImgPath(mode, theme, IL.NAV.VALUE_CHART)
      default:
        return <div>Invalid Type</div>
    }
  }

  return (
    <div key={key} id={id} className={className}>
      {/* <div key={id + 'desc'} style={{ width: '100%', height: '30%' }}>{`${data.index + 1} - ${convertTranslation(data.field)}`}</div> */}
      <div key={key + 'img'} id={id} style={{ width: '100%', height: '70%' }} onClick={onClick}>
        {data.type === 'BarChart' ? (
          <img key={key + 'img-inner'} src={path(data.type)} height="100%" style={{ transform: `rotate(${270}deg) scaleY(-1)` }} />
        ) : (
          <img key={key + 'img-inner'} src={path(data.type)} height="100%" />
        )}
      </div>
    </div>
  )
}
