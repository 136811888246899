import { Button, DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import { Fragment, useEffect, useState } from 'react'
import { batch } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

const PushSettingsRibbonDataGrid = ({ title, userRole, savedItems, entireItems, setSavedItems }) => {
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        if (entireItems && savedItems) {
            const dataSource = entireItems.map((item, index) => {
                const savedItem = savedItems.find((saved) => saved.name === item.name)
                const name = item.name.charAt(0).toUpperCase() + item.name.slice(1)
                const label = dqTranslateMsg(`Navigation_${name}`)
                return {
                    id: index,
                    showWithData: item.isDataNeed,
                    icon: item.icon,
                    name: item.name,
                    type: item.parent,
                    using: !!savedItem, // savedItem이 있으면 true, 없으면 false
                    label: label,
                }
            })
            setDataSource(dataSource)
        }
    }, [entireItems])

    const handleUseRibbonMenuButton = ({ data, rowIndex }) => {
        const onClick = () => {
            let newSavedItems
            if (data.using) {
                newSavedItems = savedItems.filter((item) => item.name !== data.name)
            } else {
                const target = entireItems.find((item) => item.name === data.name)
                newSavedItems = [...savedItems, target]
            }

            const copiedEntireDataSource = [...dataSource]
            copiedEntireDataSource[rowIndex].using = !data.using

            batch(() => {
                setDataSource(copiedEntireDataSource)
                setSavedItems(newSavedItems)
            })
        }

        const removeStr = dqTranslateMsg('Page_AdminSettings_' + 'Remove')
        const addStr = dqTranslateMsg('Page_AdminSettings_' + 'Add')

        if (['admin', 'superuser', 'DQS'].includes(userRole)) {
            // if (data.name === 'adminSettings') {
            //     return (
            //         <Button
            //             width={'100%'}
            //             stylingMode='filled'
            //             disabled={false}
            //             type={data.using ? 'danger' : 'success'}
            //             text={data.using ? removeStr : addStr}
            //             onClick={onClick}
            //         />
            //     )
            // }

            return (
                <Button
                    width={'100%'}
                    stylingMode='filled'
                    disabled={false}
                    type={data.using ? 'danger' : 'success'}
                    text={data.using ? removeStr : addStr}
                    onClick={onClick}
                />
            )
        } else {
            if (data.name === 'adminSettings') {
                return (
                    <Button
                        width={'100%'}
                        stylingMode='filled'
                        disabled={true}
                        type={data.using ? 'danger' : 'success'}
                        text={data.using ? removeStr : addStr}
                        onClick={onClick}
                    />
                )
            }

            return (
                <Button
                    width={'100%'}
                    stylingMode='filled'
                    disabled={false}
                    type={data.using ? 'danger' : 'success'}
                    text={data.using ? removeStr : addStr}
                    onClick={onClick}
                />
            )
        }
    }
    const onIconCellRender = (e) => {
        const isDevExpressIcon = e.data.name === 'uploadFile'
        if (isDevExpressIcon) {
            return (
                <i
                    class={`dx-icon-${e.data.icon}`}
                    style={{
                        fontSize: '30px',
                        // textAlign: 'center',
                    }}
                ></i>
            )
        } else {
            return (
                <img
                    style={{ width: '30px', height: '30px' }}
                    src={e.data.icon}
                />
            )
        }
    }

    return (
        <Fragment>
            <div style={{ width: '100%', height: '4%', fontSize: '20px' }}>{title}</div>
            <DataGrid
                dataSource={dataSource}
                style={{ width: '100%', height: '96%' }}
                paging={{ enabled: false }}
                columnResizingMode='widget'
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={false}
                hoverStateEnabled={true}
                scrolling={{ useNative: false }}
                noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
            >
                <Column
                    dataField='icon'
                    caption={'Symbol'}
                    alignment='center'
                    width={'15%'}
                    cellRender={onIconCellRender}
                />
                <Column
                    dataField='label'
                    alignment='center'
                />
                <Column
                    caption='Function'
                    alignment='center'
                    width={'20%'}
                    cellRender={handleUseRibbonMenuButton}
                />
            </DataGrid>
        </Fragment>
    )
}

export default PushSettingsRibbonDataGrid
