import { boxPlotChartSettingsPrm } from 'src/pages/chart-box-plot/components/settings/BoxPlotChartSettings'
import { capabilitySummaryChartSettingsPrm } from 'src/pages/chart-capability-summary/components/settings/CapabilitySummaryChartSettings'
import { correlationChartSettingsPrm } from 'src/pages/chart-correlation/components/settings/CorrelationChartSettings'
import { decisionTreeChartSettingsPrm } from 'src/pages/chart-decision-tree/components/settings/DecisionTreeSettings'
import { histogramChartSettingsPrm } from 'src/pages/chart-histogram/components/settings/HistogramChartSettings'
import { linearRegressionChartSettingsPrm } from 'src/pages/chart-linear-regression/components/settings/LinearRegressionChartSettings'
import { paretoChartSettingsPrm } from 'src/pages/chart-pareto/components/settings/ParetoChartSettings'
import { positionalTolerancesChartSettingsPrm } from 'src/pages/chart-positional-tolerances/components/settings/PositionalTolerancesChartSettings'
import { probabilityPlotChartSettingsPrm } from 'src/pages/chart-probability-plot/components/settings/ProbabilityPlotChartSettings'
import { qualityControlChartSettingsPrm } from 'src/pages/chart-quality-control/components/settings/QualityControlChartSettings'
import { valueChartSettingsPrm } from 'src/pages/chart-value/components/settings/ValueChartSettings'
import { databaseSettingsPrm } from 'src/pages/database/components/settings/DatabaseSettings'
import { summaryListSettingsPrm } from 'src/pages/list-summary/components/settings/SummaryListSettings'
import { valueSummaryListSettingsPrm } from 'src/pages/list-value-summary/components/settings/ValueSummaryListSettings'
import { valueListSettingsPrm } from 'src/pages/list-value/components/settings/ValueListSettings'
import { monitoringSettingsPrm } from 'src/pages/monitoring/components/settings/MonitoringSettings'
import { overviewSettingsPrm } from 'src/pages/overview/components/settings/OverviewSettings'
import { reportPrintSettingsPrm } from 'src/pages/report-print/components/settings/ReportPrintSettings'
import { scrollBoardSettingsPrm } from 'src/pages/scroll-board/components/settings/ScrollBoardSettings'
import { configurationSettingsPrm, defaultCatalogs, defaultEncoding, defaultUseFieldList } from 'src/pages/config/components/settings/ConfigSettings'
import { adminSettingsPrm, defaultDBConfig, defaultLanguage, defaultPermissions, defaultRibbon } from 'src/pages/admin/components/settings/AdminSettings'
import { reportPrintMultiFunctionSettingsPrm } from 'src/pages/report-print-multi-function/components/settings/ReportPrintMultiFunctionSettings'
import { dbAdvancedFilterSettings, dbFilterSettings, dbQuickFilterSettings } from './DBFilterSettings'

export const pageSettings = {
    boxPlotChart: boxPlotChartSettingsPrm,
    capabilitySummaryChart: capabilitySummaryChartSettingsPrm,
    correlationChart: correlationChartSettingsPrm,
    histogramChart: histogramChartSettingsPrm,
    linearRegressionChart: linearRegressionChartSettingsPrm,
    paretoChart: paretoChartSettingsPrm,
    positionalTolerancesChart: positionalTolerancesChartSettingsPrm,
    probabilityPlotChart: probabilityPlotChartSettingsPrm,
    qualityControlChart: qualityControlChartSettingsPrm,
    valueChart: valueChartSettingsPrm,
    database: databaseSettingsPrm,
    decisionTreeChart: decisionTreeChartSettingsPrm,
    summaryList: summaryListSettingsPrm,
    valueList: valueListSettingsPrm,
    valueSummaryList: valueSummaryListSettingsPrm,
    scrollBoard: scrollBoardSettingsPrm,
    reportPrint: reportPrintSettingsPrm,
    monitoring: monitoringSettingsPrm,
    overview: overviewSettingsPrm,
    configuration: configurationSettingsPrm,
    adminSettings: adminSettingsPrm,
    reportPrintMultiFunction: reportPrintMultiFunctionSettingsPrm,
    // others settings
    others: {
        encoding: defaultEncoding,
        useFieldList: defaultUseFieldList,
        catalogs: defaultCatalogs,
        dbConfig: defaultDBConfig,
        ribbon: defaultRibbon.default,
        permissions: [],
        language: defaultLanguage,
        databaseSavedQuickFilterList: dbQuickFilterSettings,
        databaseSavedAdvancedFilterList: dbAdvancedFilterSettings,
    },
}
