import { EmptyItem, GroupItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'

export default function MonitoringInterval(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'monitoring'
  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onRadioGroupMonitoringIntervalTypeValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page.monitoringInterval[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const handleNumberBoxIntervalCustomCountChange = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page.monitoring[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`monitoring-interval-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'MonitoringInterval')}>
      {RadioGroupSimpleItem({
        ref: null,
        colSpan: 2,
        label: '',
        labelLocation: 'top',
        name: 'type',
        items: sd.monitoringIntervalDefine(),
        layout: 'vertical',
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.monitoringInterval.type,
        disabled: false,
        onValueChanged: (e) => onRadioGroupMonitoringIntervalTypeValueChanged(e, 'type'),
      })}
      <EmptyItem />
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'SecMin5'),
        labelLocation: 'top',
        name: 'customInterval',
        min: 1,
        max: 100000,
        step: 1,
        value: pageState.page.monitoringInterval.customInterval,
        disabled: pageState.page.monitoringInterval.type !== 'custom',
        onValueChanged: (e) => handleNumberBoxIntervalCustomCountChange(e, 'customInterval'),
      })}
    </GroupItem>
  )
}
