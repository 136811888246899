// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dg-first-child {
  width: 100%;
  height: 100%;
  padding-right: 15px;
}

.dg-last-child {
  width: 100%;
  height: 100%;
  padding-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/push-settings/data/components/ribbon/PushSettingsRibbonTreeList.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF","sourcesContent":[".dg-first-child {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding-right: 15px;\r\n}\r\n\r\n.dg-last-child {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding-left: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
