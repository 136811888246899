// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.dx-theme-background-color .tab-panel-item-container {
  width: 100%;
  height: 100%;
}
.dx-theme-background-color .tab-panel-item-container .key-info-config {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/config/components/main-view/ConfigForm.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;AAAN","sourcesContent":["// .config-form-container {\r\n.dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: row;\r\n\r\n  .tab-panel-item-container {\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    .key-info-config {\r\n      width: 100%;\r\n      height: 100%;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
