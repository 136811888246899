export default {
  user_id: 'superuser',
  user_password: 'superuser',
  user_info: 'superuser',
  user_role: 0,
  license: null,
  web_config: null,
  db_config: null,
  chart_config_1: null,
  chart_config_2: null,
  chart_config_3: null,
  chart_config_4: null,
  chart_config_5: null,
  chart_config_6: null,
  chart_config_7: null,
  chart_config_8: null,
  chart_config_9: null,
  chart_config_10: null,
  chart_config_11: null,
  chart_config_12: null,
  chart_config_13: null,
  chart_config_14: null,
  chart_config_15: null,
  chart_config_16: null,
  chart_config_17: null,
  chart_config_18: null,
  chart_config_19: null,
  chart_config_20: null,
}
