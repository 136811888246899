import { GroupItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import { getDatabaseInfo } from 'src/api/db'
import * as rd from 'src/store/common'
import { updateUserInfoForSettings } from 'src/api/user'
import { useAuth } from 'src/contexts/auth'

const QuickModifyButton = (currentFilter, setCurrentFilter, isVisibleFilterPopup, setIsVisibleFilterPopup, pageKey, pageState, configState, othersPage) => {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const onSaveAllButtonClick = async () => {
        console.log('Save All')
        const targetIndex = othersPage.databaseSavedQuickFilterList.findIndex((item) => item.filterName === currentFilter.filterName)

        if (targetIndex !== -1) {
            const next = produce(othersPage, (draft) => {
                draft.databaseSavedQuickFilterList[targetIndex] = currentFilter
            })
            // setCurrentFilter(next)
            setIsVisibleFilterPopup(false)
            dispatch(rd.setDatabaseSavedQuickFilterList(next.databaseSavedQuickFilterList))
            await updateUserInfoForSettings(user.user_id, 'chart_config_29', JSON.stringify(next.databaseSavedQuickFilterList))
        }
    }

    const onCloseButtonClick = () => {
        setIsVisibleFilterPopup(false)
    }

    return (
        <GroupItem
            key={`db-field-quick-modify-group-item`}
            colCount={2}
            caption={''}
        >
            {ButtonSimpleItem({
                colSpan: 1,
                type: 'normal',
                text: dqTranslateMsg('SidePanel_' + 'Save'),
                icon: 'add',
                width: '100%',
                height: '100%',
                onClick: onSaveAllButtonClick,
            })}
            {ButtonSimpleItem({
                colSpan: 1,
                type: 'normal',
                text: dqTranslateMsg('SidePanel_' + 'Close'),
                icon: 'close',
                width: '100%',
                height: '100%',
                onClick: onCloseButtonClick,
            })}
        </GroupItem>
    )
}

export default QuickModifyButton
