import React from 'react'
import { EmptyItem, GroupItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
export function TopOrderTheme(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  const sbPageSettings = pageState

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }
  const onCheckBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onColorBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={'top-order-theme-group-item'} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'TopOrder')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'FontSize'),
        labelLocation: 'top',
        name: 'topOrderTextFontSize',
        min: 1,
        max: 30,
        step: 1,
        value: sbPageSettings.theme.topOrderTextFontSize,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'topOrderTextFontSize'),
      })}
      <EmptyItem />
      <EmptyItem />
      <EmptyItem />
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'TopOrderColorCustomEnable'),
        labelLocation: 'right',
        name: 'topOrderTextFontCustomColor',
        value: sbPageSettings.theme.topOrderTextFontCustomColor,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'topOrderTextFontCustomColor'),
      })}
      <EmptyItem />
      <EmptyItem />
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'TextColor'),
        labelLocation: 'top',
        name: 'topOrderTextFontColor',
        value: sbPageSettings.theme.topOrderTextFontColor,
        disabled: !sbPageSettings.theme.topOrderTextFontCustomColor,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'topOrderTextFontColor'),
      })}
    </GroupItem>
  )
}
