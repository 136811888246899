const flowNodes = [
  {
    id: 101,
    text: 'Development',
  },
  {
    id: 102,
    text: 'Javascript\nTeam',
  },
  {
    id: 103,
    text: 'ASP.NET\nTeam',
  },
]
const flowEdges = [
  {
    id: 121,
    from: 101,
    to: 102,
  },
  {
    id: 122,
    from: 101,
    to: 103,
  },
]

export default {
  getFlowNodes() {
    return flowNodes
  },
  getFlowEdges() {
    return flowEdges
  },
}
