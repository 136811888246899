import React, { useRef } from 'react'
import Cytoscape from 'cytoscape'
import COSEBilkent from 'cytoscape-cose-bilkent'
import CytoscapeComponent from 'react-cytoscapejs'
import { connect } from 'react-redux'
import { WorkFlowSimpleChartContainer } from './WorkFlowSimpleChartContainer'
import './DecisionTreeChartForm.scss'

Cytoscape.use(COSEBilkent)

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(DecisionTreeChartForm)

function DecisionTreeChartForm({ pageKey, common, chartRawData }) {
  return (
    <div className="dx-theme-background-color">
      <div className="decision-tree-control-container">
        <WorkFlowSimpleChartContainer
          pageKey={pageKey}
          common={common}
          chartRawData={chartRawData}
        />
      </div>
    </div>
  )
}
