export const capabilitySummaryChartSettingsPrm = {
  page: {
    displayType: 'barChart',
    importanceLine: 1.33,
  },
  barChart: {
    cp: {
      error: { color: 'rgba(255, 8, 12, 1)' },
      good: { color: 'rgba(5, 184, 255, 1)' },
    },
    cpk: {
      error: { color: 'rgba(255, 90, 8, 1)' },
      good: { color: 'rgba(5, 255, 147, 1)' },
    },
  },
  lineChart: {
    symbols: {
      error: {
        shape: 'path://M 100 100 L 300 100 L 200 300 z',
        size: 5,
        color: 'rgba(255, 0, 0, 1)',
      },
      good: {
        shape: 'circle',
        size: 5,
        color: 'rgba(2, 152, 212, 1)',
      },
    },
    chartLines: {
      cp: {
        type: 'solid',
        width: 2,
        color: 'rgba(0, 255, 0, 1)',
      },
      cpk: {
        type: 'solid',
        width: 2,
        color: 'rgba(255, 255, 0, 1)',
      },
    },
  },
  markLine: {
    type: 'dashed',
    width: 3,
    color: 'rgba(255,0,0,1)',
  },
}
