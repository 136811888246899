import * as DBUtils from 'src/functions/DatabaseUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'

export const outlierFilterValues = (limits, char, values, configSettings) => {
    const { usePlausibilityFunc, useScrapFunc, useNaturalBoundary, useAdjustNormalTolerances, tolerancesPercentage } = configSettings.commonSettings
    // const { warningLimitPercentage, warningLimitNatPercentage } = configSettings.commonSettings
    const { usl, lsl, uwl, lwl, upl, lpl, url, lrl, gap, warnOffset } = limits

    const isLowerNatBoundary = PCUtils.getTypeLowerWarningLimit(char) === 2 || false
    const isUpperNatBoundary = PCUtils.getTypeUpperWarningLimit(char) === 2 || false

    let filteredValues = values
    if (usePlausibilityFunc) {
        filteredValues = lpl !== null ? filteredValues.filter((item) => item.v0001 > lpl) : filteredValues
        filteredValues = upl !== null ? filteredValues.filter((item) => item.v0001 < upl) : filteredValues
    }
    if (useScrapFunc) {
        filteredValues = lrl !== null ? filteredValues.filter((item) => item.v0001 > lrl) : filteredValues
        filteredValues = url !== null ? filteredValues.filter((item) => item.v0001 < url) : filteredValues
    }
    if (useNaturalBoundary) {
        if (isLowerNatBoundary) {
            filteredValues = lsl !== null ? filteredValues.filter((item) => item.v0001 > lsl) : filteredValues
        } else if (isUpperNatBoundary) {
            filteredValues = usl !== null ? filteredValues.filter((item) => item.v0001 < usl) : filteredValues
        } else if (isLowerNatBoundary && isUpperNatBoundary) {
            filteredValues = lsl !== null ? filteredValues.filter((item) => item.v0001 > lsl) : filteredValues
            filteredValues = usl !== null ? filteredValues.filter((item) => item.v0001 < usl) : filteredValues
        } else {
            filteredValues = filteredValues
        }
    }
    if (useAdjustNormalTolerances) {
        if (lsl !== null) {
            //   const lowerToleranceDegree = lsl - gap * warnOffset * (tolerancesPercentage / 100 - 1)
            const lowerToleranceDegree = lsl - gap * (tolerancesPercentage / 100 - 1)
            filteredValues = filteredValues.filter((item) => item.v0001 > lowerToleranceDegree)
        }
        if (usl !== null) {
            //   const upperToleranceDegree = usl + gap * warnOffset * (tolerancesPercentage / 100 - 1)
            const upperToleranceDegree = usl + gap * (tolerancesPercentage / 100 - 1)
            filteredValues = filteredValues.filter((item) => item.v0001 < upperToleranceDegree)
        }
    }

    return filteredValues
}
