const createDTOChar = ({ element, configSettings }) => {
  return {
    c2001: element.c2001 !== undefined && element.c2001 !== null ? element.c2001 : null,
    c2002: element.c2002 !== undefined && element.c2002 !== null ? element.c2002 : null,
    c2003: element.c2003 !== undefined && element.c2003 !== null ? element.c2003 : null,
    c2004: element.c2004 !== undefined && element.c2004 !== null ? element.c2004 : null,
    c2005: element.c2005 !== undefined && element.c2005 !== null ? element.c2005 : null,
    c2006: element.c2006 !== undefined && element.c2006 !== null ? element.c2006 : null,
    c2007: element.c2007 !== undefined && element.c2007 !== null ? element.c2007 : null,
    c2008: element.c2008 !== undefined && element.c2008 !== null ? element.c2008 : null,
    c2009: element.c2009 !== undefined && element.c2009 !== null ? element.c2009 : null,
    c2011: element.c2011 !== undefined && element.c2011 !== null ? element.c2011 : null,
    c2015: element.c2015 !== undefined && element.c2015 !== null ? element.c2015 : null,
    c2016: element.c2016 !== undefined && element.c2016 !== null ? element.c2016 : null,
    c2021: element.c2021 !== undefined && element.c2021 !== null ? element.c2021 : null,
    c2022: element.c2022 !== undefined && element.c2022 !== null ? element.c2022 : null,
    c2035: element.c2035 !== undefined && element.c2035 !== null ? element.c2035 : null,
    c2041: element.c2041 !== undefined && element.c2041 !== null ? element.c2041 : null,
    c2042: element.c2042 !== undefined && element.c2042 !== null ? element.c2042 : null,
    c2043: element.c2043 !== undefined && element.c2043 !== null ? element.c2043 : null,
    c2051: element.c2051 !== undefined && element.c2051 !== null ? element.c2051 : null,
    c2091: element.c2091 !== undefined && element.c2091 !== null ? element.c2091 : null,
    c2092: element.c2092 !== undefined && element.c2092 !== null ? element.c2092 : null,
    c2093: element.c2093 !== undefined && element.c2093 !== null ? element.c2093 : null,
    c2100: element.c2100 !== undefined && element.c2100 !== null ? element.c2100 : null,
    c2101: element.c2101 !== undefined && element.c2101 !== null ? element.c2101 : null,
    c2110: element.c2110 !== undefined && element.c2110 !== null ? element.c2110 : null,
    c2111: element.c2111 !== undefined && element.c2111 !== null ? element.c2111 : null,
    c2112: element.c2112 !== undefined && element.c2112 !== null ? element.c2112 : null,
    c2113: element.c2113 !== undefined && element.c2113 !== null ? element.c2113 : null,
    c2114: element.c2114 !== undefined && element.c2114 !== null ? element.c2114 : null,
    c2115: element.c2115 !== undefined && element.c2115 !== null ? element.c2115 : null,
    c2116: element.c2116 !== undefined && element.c2116 !== null ? element.c2116 : null,
    c2117: element.c2117 !== undefined && element.c2117 !== null ? element.c2117 : null,
    c2120: element.c2120 !== undefined && element.c2120 !== null ? element.c2120 : null,
    c2121: element.c2121 !== undefined && element.c2121 !== null ? element.c2121 : null,
    c2130: element.c2130 !== undefined && element.c2130 !== null ? element.c2130 : null,
    c2131: element.c2131 !== undefined && element.c2131 !== null ? element.c2131 : null,
    c2142: element.c2142 !== undefined && element.c2142 !== null ? element.c2142 : null,
    c2161: element.c2161 !== undefined && element.c2161 !== null ? element.c2161 : null,
    c2162: element.c2162 !== undefined && element.c2162 !== null ? element.c2162 : null,
    c2163: element.c2163 !== undefined && element.c2163 !== null ? element.c2163 : null,
    c2202: element.c2202 !== undefined && element.c2202 !== null ? element.c2202 : null,
    c2205: element.c2205 !== undefined && element.c2205 !== null ? element.c2205 : null,
    c2213: element.c2213 !== undefined && element.c2213 !== null ? element.c2213 : null,
    c2220: element.c2220 !== undefined && element.c2220 !== null ? element.c2220 : null,
    c2221: element.c2221 !== undefined && element.c2221 !== null ? element.c2221 : null,
    c2222: element.c2222 !== undefined && element.c2222 !== null ? element.c2222 : null,
    c2225: element.c2225 !== undefined && element.c2225 !== null ? element.c2225 : null,
    c2226: element.c2226 !== undefined && element.c2226 !== null ? element.c2226 : null,
    c2227: element.c2227 !== undefined && element.c2227 !== null ? element.c2227 : null,
    c2243: element.c2243 !== undefined && element.c2243 !== null ? element.c2243 : null,
    c2244: element.c2244 !== undefined && element.c2244 !== null ? element.c2244 : null,
    c2245: element.c2245 !== undefined && element.c2245 !== null ? element.c2245 : null,
    c2246: element.c2246 !== undefined && element.c2246 !== null ? element.c2246 : null,
    c2302: element.c2302 !== undefined && element.c2302 !== null ? element.c2302 : null,
    c2402: element.c2402 !== undefined && element.c2402 !== null ? element.c2402 : null,
    c2410: element.c2410 !== undefined && element.c2410 !== null ? element.c2410 : null,
    c2413: element.c2413 !== undefined && element.c2413 !== null ? element.c2413 : null,
    c2415: element.c2415 !== undefined && element.c2415 !== null ? element.c2415 : null,
    c2802: element.c2802 !== undefined && element.c2802 !== null ? element.c2802 : null,
    c2812: element.c2812 !== undefined && element.c2812 !== null ? element.c2812 : null,
    c2822: element.c2822 !== undefined && element.c2822 !== null ? element.c2822 : null,
    c2832: element.c2832 !== undefined && element.c2832 !== null ? element.c2832 : null,
    c2842: element.c2842 !== undefined && element.c2842 !== null ? element.c2842 : null,
    c2852: element.c2852 !== undefined && element.c2852 !== null ? element.c2852 : null,
    c2862: element.c2862 !== undefined && element.c2862 !== null ? element.c2862 : null,
    c2872: element.c2872 !== undefined && element.c2872 !== null ? element.c2872 : null,
    c2882: element.c2882 !== undefined && element.c2882 !== null ? element.c2882 : null,
    c2892: element.c2892 !== undefined && element.c2892 !== null ? element.c2892 : null,
    c2900: element.c2900 !== undefined && element.c2900 !== null ? element.c2900 : null,
    c2999: element.c2999 !== undefined && element.c2999 !== null ? element.c2999 : null,
    c8006: element.c8006 !== undefined && element.c8006 !== null ? element.c8006 : null,
    c8007: element.c8007 !== undefined && element.c8007 !== null ? element.c8007 : null,
    c8011: element.c8011 !== undefined && element.c8011 !== null ? element.c8011 : null,
    c8012: element.c8012 !== undefined && element.c8012 !== null ? element.c8012 : null,
    c8013: element.c8013 !== undefined && element.c8013 !== null ? element.c8013 : null,
    c8014: element.c8014 !== undefined && element.c8014 !== null ? element.c8014 : null,
    c8015: element.c8015 !== undefined && element.c8015 !== null ? element.c8015 : null,
    c8106: element.c8106 !== undefined && element.c8106 !== null ? element.c8106 : null,
    c8107: element.c8107 !== undefined && element.c8107 !== null ? element.c8107 : null,
    c8111: element.c8111 !== undefined && element.c8111 !== null ? element.c8111 : null,
    c8112: element.c8112 !== undefined && element.c8112 !== null ? element.c8112 : null,
    c8113: element.c8113 !== undefined && element.c8113 !== null ? element.c8113 : null,
    c8114: element.c8114 !== undefined && element.c8114 !== null ? element.c8114 : null,
    c8115: element.c8115 !== undefined && element.c8115 !== null ? element.c8115 : null,
    c8500: element.c8500 !== undefined && element.c8500 !== null ? element.c8500 : null,
    c8501: element.c8501 !== undefined && element.c8501 !== null ? element.c8501 : null,
    c8531: element.c8531 !== undefined && element.c8531 !== null ? element.c8531 : null,
    c8532: element.c8532 !== undefined && element.c8532 !== null ? element.c8532 : null,
    c8540: element.c8540 !== undefined && element.c8540 !== null ? element.c8540 : null,
    char_id: element.char_id !== undefined && element.char_id !== null ? element.char_id : null,
    group_index: element.group_index !== undefined && element.group_index !== null ? element.group_index : null,
    part_id: element.part_id !== undefined && element.part_id !== null ? element.part_id : null,
    value_quantity: element.value_quantity !== undefined && element.value_quantity !== null ? element.value_quantity : null,
  }
}

export default createDTOChar
