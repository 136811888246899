import { Button } from 'devextreme-react/button'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import DQToast from 'src/functions/DQToast'
import { linearRegressionChartSettingsPrm } from 'src/pages/chart-linear-regression/components/settings/LinearRegressionChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'

export default function SidePanelLinearRegressionChartTheme(props) {
    const { pageKey } = props
    const dispatch = useDispatch()

    const toastRef = useRef(null)

    const [scrollPosition, setScrollPosition] = useState(0)
    const { user } = useAuth()
    const common = useSelector((state) => state.common)
    const curPage = common.pages.linearRegressionChart

    const handleCurPageSettingsSave = (updatePageData) => {
        dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
    }
    const handleAllSettingsSave = () => {
        const next = produce(curPage, (draft) => {
            Object.keys(curPage).forEach((key) => {
                draft[key] = curPage[key]
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }
    const handleAllSettingsReset = () => {
        const next = produce(curPage, (draft) => {
            Object.entries(linearRegressionChartSettingsPrm.lineChart).forEach(([key, value]) => {
                draft.lineChart[key] = value
            })
            Object.entries(linearRegressionChartSettingsPrm.scatterChart).forEach(([key, value]) => {
                draft.scatterChart[key] = value
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    return (
        <div className='side-panel-value-chart-container'>
            <DQToast ref={toastRef} />
            {/* Function Buttons */}
            <div className='side-panel-function-buttons'>
                <Button
                    className='save'
                    icon='save'
                    text={dqTranslateMsg('SidePanel_' + 'Save')}
                    width={'50%'}
                    onClick={handleAllSettingsSave}
                />
                <Button
                    className='undo'
                    icon='undo'
                    text={dqTranslateMsg('SidePanel_' + 'Reset')}
                    width={'50%'}
                    onClick={handleAllSettingsReset}
                />
            </div>
        </div>
    )
}
