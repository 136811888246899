import { jwtDecode } from 'jwt-decode'

class JwtUtils {
  // now: Use local Storage
  // later: cooki. httponly, refreshtoken, accesstoken

  static saveAccessToken(token) {
    sessionStorage.setItem('accessToken', token)
  }

  static loadToken() {
    return sessionStorage.getItem('accessToken')
  }

  static removeAccessToken() {
    sessionStorage.removeItem('accessToken')
  }

  /**
   * 
   * @param {String} token 
   * @returns 
   * { 
      userId: "xxx",
      exp: 1393286893,
      iat: 1393268893  
      }
  */
  static parseJwtToken(token) {
    try {
      const decoded = jwtDecode(token)
      return decoded
    } catch (error) {
      alert(error)
    }
  }

  static isTokenExpired(decodedToken) {
    const currentTime = Date.now().valueOf() / 1000

    if (decodedToken.exp < currentTime) {
      return true
    }

    return false
  }

  static isTokenExpiredTest(decodedToken) {
    return true
  }
}

export default JwtUtils
