import { TextArea, TextBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'
import React from 'react'

const TextAreaSimpleItem = ({ colSpan, label, labelLocation, name, height, value, disabled = false, onValueChanged }) => {
  return (
    <SimpleItem colSpan={colSpan} label={{ text: label, location: 'top' }}>
      {/* <Label text={label} location={labelLocation} /> */}
      <TextArea
        name={name}
        value={value}
        height={height}
        readOnly={false}
        // label={label}
        labelMode={labelLocation}
        disabled={disabled}
        autoResizeEnabled={true}
        // onValueChanged={onValueChanged}
        // style={{
        //   border: '1px solid',
        // }}
        spellcheck={false}
        inputAttr={{ style: { overflow: 'hidden', color: 'white' } }}
      />
    </SimpleItem>
  )
}

export default TextAreaSimpleItem
