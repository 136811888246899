// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-file-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 50px);
}
.upload-file-container .upload-file-form-container {
  width: 100%;
  height: 100%;
}
.upload-file-container .upload-file-form-container .upload-file-form-content::-webkit-scrollbar {
  width: 5px;
}
.upload-file-container .upload-file-form-container .upload-file-form-content::-webkit-scrollbar-thumb {
  background-color: #3e4451;
}
.upload-file-container .upload-file-form-container .upload-file-form-content::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}`, "",{"version":3,"sources":["webpack://./src/pages/upload-file/upload-file.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,0BAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AACI;EACE,UAAA;AACN;AACI;EACE,yBAAA;AACN;AACI;EACE,wCAAA;AACN","sourcesContent":[".upload-file-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  width: 100%;\r\n  height: calc(100vh - 50px);\r\n\r\n  .upload-file-form-container {\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    .upload-file-form-content::-webkit-scrollbar {\r\n      width: 5px;\r\n    }\r\n    .upload-file-form-content::-webkit-scrollbar-thumb {\r\n      background-color: #3e4451;\r\n    }\r\n    .upload-file-form-content::-webkit-scrollbar-track {\r\n      background-color: rgba(255, 255, 255, 0);\r\n    }\r\n  }\r\n\r\n  // .side-panel-container {\r\n  //   width: 15%;\r\n  //   height: 100%;\r\n  // }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
