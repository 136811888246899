// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-button {
  height: 100%; /* Adjust the size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  font-size: 15px; /* Adjust the font size as needed */
  padding: 0; /* Ensure no padding */
  box-sizing: border-box; /* Include border in the element's width and height */
}`, "",{"version":3,"sources":["webpack://./src/functions/CircleButton.scss"],"names":[],"mappings":"AAAA;EACE,YAAA,EAAA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,eAAA,EAAA,mCAAA;EACA,UAAA,EAAA,sBAAA;EACA,sBAAA,EAAA,qDAAA;AACF","sourcesContent":[".circle-button {\r\n  height: 100%; /* Adjust the size as needed */\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin: 0 5px;\r\n  font-size: 15px; /* Adjust the font size as needed */\r\n  padding: 0; /* Ensure no padding */\r\n  box-sizing: border-box; /* Include border in the element's width and height */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
