// Language
export const reportModeKey = 'ReportMode'
export const reportModeKeyEditorMode = 'EditorMode'
export const reportModeKeyUserMode = 'UserMode'
export const savedTemplateKey = 'SavedTemplates'
export const currentTemplateKey = 'CurrentTemplate'
export const pageLayoutPaddingKey = 'PagePaddings'
export const elementListKey = 'ElementLists'
export const elementTypeKey = 'ElementTypes'
export const elementItemsKey = 'ElementItems'

// Settings Define
export const backgroundColor = '#ffffff'
