import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DNUtils from 'src/functions/DisplayNumberUtils'

export function makeOption(importance, columnsName, targetName, settings, configSettings) {
  const combinedList = columnsName.map((item, index) => {
    return {
      name: item,
      importance: settings.page.importanceType === 'Cart' ? importance.importance[index] * 100 : importance.rf_importance[index] * 100,
    }
  })

  // sort from big importance
  combinedList.sort((a, b) => b.importance - a.importance)

  let cutList
  if (settings.page.importanceDisplayCount === 'top10') {
    const numberToCut = 10
    cutList = combinedList.length >= numberToCut ? combinedList.slice(0, numberToCut) : combinedList
  } else if (settings.page.importanceDisplayCount === 'top5') {
    const numberToCut = 5
    cutList = combinedList.length >= numberToCut ? combinedList.slice(0, numberToCut) : combinedList
  } else if (settings.page.importanceDisplayCount === 'top3') {
    const numberToCut = 3
    cutList = combinedList.length >= numberToCut ? combinedList.slice(0, numberToCut) : combinedList
  } else {
    // Do Nothing
    cutList = combinedList
  }

  const preTitle = {
    text: settings.page.importanceType === 'Cart' ? dqTranslateMsg('Page_DecisionTreeChart_' + 'CartImportance') : dqTranslateMsg('Page_DecisionTreeChart_' + 'RandomForestImportance'),
  }

  const preYAxis = cutList.map((item) => item.name)
  const preSeries = cutList.map((item) => item.importance)

  return {
    title: preTitle,
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    grid: {
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      axisPointer: {
        type: 'shadow',
      },
      // formatter: function () {
      //   DNUtils.calcValueDisplay(
      // }
      formatter: function (arg) {
        let displayStr = ''
        if (arg[0].componentType === 'series') {
          displayStr += arg[0].axisValue + '<br>'
          displayStr += `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${arg[0].color}; border-radius: 50%;"></span> `
          displayStr += arg[0].seriesName + '&emsp;'
          displayStr += `<b>${DNUtils.calcValueDisplay(Number(arg[0].value), configSettings)}</b>`
        }
        return displayStr
      },
    },
    xAxis: {
      // min: 0,
      // max: 100,
      max: 'dataMax',
    },
    yAxis: {
      type: 'category',
      inverse: true,
      data: preYAxis,
    },
    series: [
      {
        name: dqTranslateMsg('Page_DecisionTreeChart_' + 'Importance'),
        type: 'bar',
        colorBy: 'data',
        data: preSeries,
      },
    ],
  }
}
