import { Template } from 'devextreme-react/core/template'
import Drawer from 'devextreme-react/drawer'
import ScrollView from 'devextreme-react/scroll-view'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Footer, Header, SideNavigationMenu } from '../../components'
import { useScreenSize } from '../../utils/media-query'
import { useMenuPatch } from '../../utils/patches'
import './side-nav-outer-toolbar.scss'

// import deMessages from "devextreme/localization/messages/de.json";
export default function SideNavOuterToolbar({ title, children }) {
  const scrollViewRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isXSmall, isLarge } = useScreenSize()
  const [patchCssClass, onMenuReady] = useMenuPatch()
  const [menuStatus, setMenuStatus] = useState(isLarge ? MenuStatus.Opened : MenuStatus.Closed)

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus((prevMenuStatus) => (prevMenuStatus === MenuStatus.Closed ? MenuStatus.Opened : MenuStatus.Closed))
    event.stopPropagation()
  }, [])

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus((prevMenuStatus) => (prevMenuStatus === MenuStatus.Closed ? MenuStatus.TemporaryOpened : prevMenuStatus))
  }, [])

  const onNavigationChanged = useCallback(
    ({ itemData, event, node }) => {
      if (menuStatus === MenuStatus.Closed || !itemData.path || node.selected) {
        event.preventDefault()
        return
      }

      navigate(itemData.path)
      scrollViewRef.current.instance.scrollTo(0)

      if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
        setMenuStatus(MenuStatus.Closed)
        // dispatch(setStateNavSidePanel(MenuStatus.Closed))
        event.stopPropagation()
      }
    },
    [navigate, menuStatus, isLarge]
  )

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Header menuToggleEnabled toggleMenu={toggleMenu} title={title} />
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          overflow: 'auto',
        }}
      >
        <Drawer
          className={['drawer', patchCssClass].join(' ')}
          position={'before'}
          // closeOnOutsideClick={onOutsideClick}
          openedStateMode={isLarge ? 'shrink' : 'overlap'}
          revealMode={isXSmall ? 'slide' : 'expand'}
          minSize={isXSmall ? 0 : 40} // 2023-08-20 brian nav Icon area changed 60 -> 40
          maxSize={250}
          shading={isLarge ? false : true}
          opened={menuStatus === MenuStatus.Closed ? false : true}
          template={'menu'}
        >
          <div className={'container'}>
            <ScrollView ref={scrollViewRef} className={'layout-body'}>
              <div className={'content'}>
                {React.Children.map(children, (item) => {
                  return item.type !== Footer && item
                })}
              </div>
            </ScrollView>
          </div>
          <Template name={'menu'}>
            <SideNavigationMenu compactMode={menuStatus === MenuStatus.Closed} selectedItemChanged={onNavigationChanged} openMenu={temporaryOpenMenu} onMenuReady={onMenuReady}></SideNavigationMenu>
          </Template>
        </Drawer>
      </div>
    </div>
  )
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
}
