import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import ReactECharts from 'echarts-for-react'

import produce from 'immer'

import { connect } from 'react-redux'

import ApiStatus from 'src/api/defines/ApiStatus'
import { getProbability } from 'src/api/probability-plot'
import { useLanguage } from 'src/contexts/languages'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import * as probImpl from 'src/pages/chart-probability-plot/components/main-view/chart-option/ProbabilityPlotChartOptionImpl'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as DBUtils from 'src/functions/DatabaseUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import CCUtils from 'src/functions/CommonCalcUtils'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ApacheEChartPTComponent)

const getViewPortTheme = (pageKey, theme) => {
  if (pageKey !== 'reportPrint') {
    return theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'
  }

  return 'light'
}

function ApacheEChartPTComponent({ common, rawData = [], info = { part_id: 1, char_id: 1 }, option = [], theme, pageKey }) {
  const { key, parts, chars, values, charArray, curPage, configPage, valuesArranged } = rawData

  const { language } = useLanguage()
  const chartRef = useRef(null)

  return (
    <ReactECharts
      key={key}
      ref={chartRef}
      style={{ width: '100%', height: '100%' }}
      notMerge={true}
      option={option ? option : {}}
      theme={getViewPortTheme(pageKey, theme)}
      opts={{
        renderer: 'svg',
        locale: ETCUtils.checkLangForECharts(language),
      }}
    />
  )
}
