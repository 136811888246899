import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'

export const getCorrelationBarChartOption = (settings, configSettings, corrNameList, corrDataList, corrDpList, targetValue) => {
  const titleText = `${DBUtils.getFieldData(targetValue.charRaw, 'c2002') ?? 'empty'} P:${DBUtils.getFieldData(targetValue.charRaw, 'part_id') ?? ''}/C:${
    DBUtils.getFieldData(targetValue.charRaw, 'char_id') ?? ''
  }`

  return {
    title: {
      text: titleText,
      left: 'center',
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      position: 'right',
      formatter: function (args) {
        return args[0].name + '<br>' + `${dqTranslateMsg('Page_CorrelationChart_' + 'CorrelationValue')}` + ' : ' + DNUtils.calcValueDisplay(args[0].data, configSettings)
      },
      axisPointer: {
        type: 'shadow',
        label: {
          textStyle: {
            fontFamily: 'Arial',
          },
        },
      },
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    grid: {
      top: '10%',
      bottom: '7%',
      left: '0%',
      right: '5%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: corrNameList,
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        rotate: 90,
      },
      axisLine: {
        onZero: false,
      },
    },
    yAxis: {
      type: 'value',
      position: 'top',
      min: -1,
      max: 1,
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        formatter: function (value) {
          // return DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(value, calcDecimalPlaces), isExponential)
          return value
        },
      },
      axisLine: {
        onZero: false,
      },
    },
    dataZoom: [
      {
        show: true,
        type: 'slider',
        orient: 'horizontal',
        xAxisIndex: 0,
        height: `3%`,
        bottom: '3%',
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'slider',
        orient: 'vertical',
        width: `3%`,
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'none',
        zoomLock: false, // Allow zooming
        zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
      },
      {
        show: true,
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none',
      },
    ],
    series: [
      {
        name: 'Correlation Data',
        type: 'bar',
        data: corrDataList,
        itemStyle: {
          color: (params) => {
            if (Math.abs(params.data) > settings.page.relation.level.strong) {
              return settings.barChart.relation.color.strong
            } else {
              return settings.barChart.relation.color.other
            }
          },
        },
      },
    ],
  }
}

export function getCorrelationHeatMapChartOption(settings, configSettings, corrNameList, corrDataList) {
  return {
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      // position: 'right',
      // position: function (pos, params, dom, rect, size) {
      //   let obj = { top: 60 }
      //   obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
      //   return obj
      // },
      appendToBody: true,
      position: function (pos, params, dom, rect, size) {
        let obj = { top: pos[1] + 20 } // Follow the mouse pointer's Y position with an offset of 20 pixels
        obj['left'] =
          pos[0] < size.viewSize[0] / 2
            ? pos[0] + 20 // If the tooltip is on the left side, place it to the right of the pointer with an offset
            : pos[0] - size.contentSize[0] - 20 // If the tooltip is on the right side, place it to the left of the pointer with an offset
        return obj
      },
      backgroundColor: 'rgba(255, 255, 255, 1)',
      textStyle: {
        fontFamily: 'Arial',
      },
      formatter: function (args) {
        try {
          let displayStr = ''
          if (args.data !== undefined) {
            displayStr += 'TargetA : ' + args.data[0] + '<br>'
            displayStr += 'TargetB : ' + args.data[1] + '<br>'
            displayStr += 'Relation Value : ' + DNUtils.calcValueDisplay(args.data[2], configSettings)
            return displayStr
          } else {
            console.log('correlation tooltip data undefined')
          }
        } catch (error) {
          console.log(error)
        }
      },
    },
    xAxis: {
      type: 'category',
      data: corrNameList,
      axisLabel: {
        fontFamily: 'Arial',
        rotate: 90,
      },
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: 'category',
      data: corrNameList,
      splitArea: {
        show: true,
      },
    },
    grid: {
      top: '10%',
      bottom: 40,
      left: '2%',
      right: '5%',
      containLabel: true,
    },
    dataZoom: [
      {
        show: true,
        type: 'slider',
        orient: 'horizontal',
        height: `3%`,
        brushSelect: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'slider',
        orient: 'vertical',
        width: `2%`,
        brushSelect: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none',
      },
    ],
    visualMap: {
      type: 'continuous',
      precision: configSettings.commonSettings.calcDisplayDecimalPlaces,
      min: -1,
      max: 1,
      calculable: true,
      orient: 'horizontal',
      top: 'top',
      left: 'center',
      inRange: {
        color: [settings.heatMap.relation.color.minusStrong, 'white', settings.heatMap.relation.color.plusStrong],
      },
      outOfRange: {
        color: 'rgba(190, 190, 190, 1)',
      },
    },
    series: [
      {
        type: 'heatmap',
        data: corrDataList,
        label: {
          show: true,
          overflow: 'truncate',
          ellipsis: '...',
          fontSize: 12 - corrNameList.length / 12,
          formatter: function (args) {
            return DNUtils.calcValueDisplay(args.data[2], configSettings)
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.5)',
          },
        },
      },
    ],
  }
}
