import { useTheme } from 'src/contexts/theme'
import './CompanyInfoForm.scss'

export default function CompanyInfoForm(props) {
  const { getTheme } = useTheme()
  return (
    <div className={'dx-theme-background-color'}>
      <div className="company-info-image-container">
        {getTheme().includes('dark') || getTheme().includes('contrast') ? (
          <img
            src={'/images/color/corporate_white.svg'}
            width="100%"
            height="100%"
          />
        ) : (
          <img
            src={'/images/color/corporate_black.svg'}
            width="100%"
            height="100%"
          />
        )}
      </div>
    </div>
  )
}
