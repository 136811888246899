// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textbox-container {
  display: flex;
  align-items: flex-end; /* Corrected from 'end' to 'flex-end' */
  text-align: right; /* Corrected from 'end' to 'right' */
  gap: 5px;
}

.textbox-unit {
  font-size: 14px;
  color: #333;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA,EAAA,uCAAA;EACA,iBAAA,EAAA,oCAAA;EACA,QAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;AACF","sourcesContent":[".textbox-container {\r\n  display: flex;\r\n  align-items: flex-end; /* Corrected from 'end' to 'flex-end' */\r\n  text-align: right; /* Corrected from 'end' to 'right' */\r\n  gap: 5px;\r\n}\r\n\r\n.textbox-unit {\r\n  font-size: 14px;\r\n  color: #333;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
