// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.dx-theme-background-color .value-list-grid-container {
  width: 100%;
  height: 100%;
}
.dx-theme-background-color .value-list-grid-container .dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {
  padding-top: 1px;
  padding-bottom: 1px;
  vertical-align: middle;
}
.dx-theme-background-color .value-list-grid-container .dx-header-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  height: 26px;
  font-size: 13px;
  vertical-align: middle;
}
.dx-theme-background-color .value-list-grid-container .dx-group-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  vertical-align: middle;
}
.dx-theme-background-color .value-list-cp-cpk-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.even-column {
  background-color: rgba(150, 150, 150, 0.1); /* Light gray background for even columns */
}

.odd-column {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/pages/list-value/components/main-view/ValueListForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,gBAAA;EACA,mBAAA;EACA,sBAAA;AAAJ;AAGE;EAEE,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;AAFJ;AAKE;EAEE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;AAJJ;AAME;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;AAJJ;;AAcA;EACE,0CAAA,EAAA,2CAAA;AAXF;;AAcA;EACE,6BAAA;AAXF","sourcesContent":[".dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n\r\n  .value-list-grid-container {\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n\r\n  .value-list-grid-container .dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .value-list-grid-container .dx-header-row td {\r\n    // background-color: rgba(27, 197, 189, 0.7);\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    height: 26px;\r\n    font-size: 13px;\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .value-list-grid-container .dx-group-row td {\r\n    // background-color: rgba(27, 197, 189, 0.17);\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    font-size: 12px;\r\n    vertical-align: middle;\r\n  }\r\n  .value-list-cp-cpk-container {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n\r\n  // .value-list-grid-container .dx-data-row td {\r\n  //   padding-top: 1px;\r\n  //   padding-bottom: 1px;\r\n  //   font-size: 12px;\r\n  // }\r\n}\r\n\r\n.even-column {\r\n  background-color: rgba(150, 150, 150, 0.1); /* Light gray background for even columns */\r\n}\r\n\r\n.odd-column {\r\n  background-color: transparent;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
