import { Column, DataGrid, Scrolling } from 'devextreme-react/data-grid'
import ReactECharts from 'echarts-for-react'
import { useTheme } from 'src/contexts/theme'
import { getDeviationChartOption } from './ValueSummaryListDeviationChartOption'
import './ValueSummaryListMasterDetail.scss'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import IconLoader from 'src/functions/IconLoader'
import { useRef } from 'react'
import { useLanguage } from 'src/contexts/languages'
import CPUtils from 'src/functions/CommonPageUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'

const columns = ['no', 'desc', 'nominal', 'usl', 'lsl', 'char_time', 'result', 'value', 'deviation']

export default function ValueSummaryListMasterDetail(props, datagridRef, settings, configSettings, common) {
  const { getTheme } = useTheme()
  const { language } = useLanguage()

  const detailGridRef = useRef(null) // Ref for the MasterDetail DataGrid

  const historyData = props.data.data.subRows

  const numCharts = 10
  const chartRefs2 = new Array(numCharts).fill(null).map(() => useRef(null))

  function renderDeviationChart(cellData) {
    const index = cellData.rowIndex
    let trendChartOption
    const lsl = cellData.data.lsl
    const usl = cellData.data.usl
    const nominal = cellData.data.nominal
    const deviationValue = cellData.data.deviation

    if (deviationValue !== null || (lsl === null && usl === null)) {
      trendChartOption = getDeviationChartOption(lsl, usl, nominal, deviationValue)
      return (
        <ReactECharts
          // className="value-summary-list-deviation-container"
          id="styled-deviation-chart"
          ref={(e) => {
            chartRefs2[index].current = e
          }}
          option={trendChartOption}
          style={{ height: '32px', width: '100%' }}
          theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
          opts={{
            width: 'auto',
            height: 'auto',
            renderer: 'svg',
            locale: ETCUtils.checkLangForECharts(language),
          }}
        />
      )
    } else {
      return (
        <>
          <img src={'/images/color/warning-exclamation.svg'} width="25" height="25" />
          {/* <div style={{ fontSize: '10px' }}>Data cannot be evaluated</div> */}
        </>
      )
    }
  }

  function renderResult(cellData, settings, configSettings) {
    const iconType = settings.theme.iconType.result

    if (cellData.data.result === 1) {
      return (
        <img
          src={`${IconLoader.getImgPath(
            configSettings.menuIconType, // mode
            IconLoader.TYPES_DEF.find((type) => type.path === iconType).id,
            getTheme().includes('dark') ? 1 : 0,
            IconLoader.STATE_DEF.find((type) => type.path === 'good').id
          )}`}
          width="20"
          height="20"
        />
      ) // NG
    } else if (cellData.data.result === 0) {
      return (
        <img
          src={`${IconLoader.getImgPath(
            configSettings.menuIconType, // mode
            IconLoader.TYPES_DEF.find((type) => type.path === iconType).id,
            getTheme().includes('dark') ? 1 : 0,
            IconLoader.STATE_DEF.find((type) => type.path === 'bad').id
          )}`}
          width="20"
          height="20"
        />
      ) // NG
    } else {
      return (
        <>
          <img src={'/images/color/warning-exclamation.png'} width="20" height="20" />
          {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
        </>
      )
    }
  }

  function renderNominal(cellData, configSettings) {
    return cellData.data.nominal
  }
  function renderUsl(cellData, configSettings) {
    if (cellData.data.usl === null) {
      return null
    } else {
      return DNUtils.axisValueDisplay(cellData.data.usl, cellData.data.char, configSettings)
    }
  }

  function renderLsl(cellData, configSettings) {
    if (cellData.data.lsl === null) {
      return null
    } else {
      return DNUtils.axisValueDisplay(cellData.data.lsl, cellData.data.char, configSettings)
    }
  }
  function renderV0014(cellData) {
    if (cellData.data.v0014 === null) {
      return ''
    } else {
      return cellData.data.v0014
    }
  }
  function renderV0053(cellData) {
    if (cellData.data.v0053 === null) {
      return ''
    } else {
      return cellData.data.v0053
    }
  }

  function renderDateTime(cellData, settings) {
    const value = cellData.data.char_time

    let displayStr = ''
    const dateTimeFormat = settings.page.dateTimeDisplayFormat ?? 'date/time/sec'

    displayStr = CPUtils.getDisplayDateTimeSec(dateTimeFormat, value)

    return displayStr
  }
  function renderValue(cellData, configSettings) {
    if (cellData.data.value > cellData.data.usl || cellData.data.value < cellData.data.lsl) {
      return (
        <div className="value-summary-list-cell-container">
          <div style={{ color: 'red' }}>{DNUtils.valueDisplay(cellData.data.value, cellData.data.char, configSettings)}</div>
          <img src={'/images/color/warning-exclamation.svg'} width="20" height="20" />
        </div>
      )
    }

    return (
      <div className="value-summary-list-cell-container">
        <div>{DNUtils.valueDisplay(cellData.data.value, cellData.data.char, configSettings)}</div>
      </div>
    )
  }

  const syncMasterDetailColumns = () => {
    if (datagridRef.current && detailGridRef.current) {
      // 메인 그리드와 디테일 그리드의 컬럼 가져오기
      const columns = datagridRef.current.instance.getVisibleColumns()
      const detailColumns = detailGridRef.current.instance.getVisibleColumns()

      // 각 컬럼의 dataField 추출
      const columnDataFields = columns.map((col) => col.dataField)
      const detailColumnDataFields = detailColumns.map((col) => col.dataField)

      // 'part_name', 'no', 'desc' 제외한 컬럼 선택
      const useColumns = columnDataFields.filter((item) => item !== 'part_name' && item !== 'no' && item !== 'desc')

      // 디테일 그리드에서 사용할 컬럼 구분
      const useDetailColumns = useColumns.filter((item) => detailColumnDataFields.includes(item))
      const notUseDetailColumns = detailColumnDataFields.filter((item) => !useColumns.includes(item))

      // 디테일 그리드의 컬럼을 동기화 (필요한 컬럼은 보이고, 그렇지 않은 컬럼은 숨김)
      detailGridRef.current.instance.beginUpdate()

      // useDetailColumns의 컬럼을 visible: true로 설정
      useDetailColumns.forEach((column) => {
        detailGridRef.current.instance.columnOption(column, 'visible', true)
        detailGridRef.current.instance.columnOption(column, 'visibleIndex', useColumns.indexOf(column))
      })

      // notUseDetailColumns의 컬럼을 visible: false로 설정
      notUseDetailColumns.forEach((column) => {
        detailGridRef.current.instance.columnOption(column, 'visible', false)
      })

      detailGridRef.current.instance.endUpdate()
    }
  }

  const handleDataGridContentReady = () => {
    chartRefs2.forEach((ref) => {
      const devInstance = ref.current?.getEchartsInstance()
      if (devInstance) {
        devInstance.resize()
      }
    })
    syncMasterDetailColumns()
  }

  return (
    <div className="value-summary-list-mater-detail-container">
      <DataGrid
        className="value-summary-list-master-detail"
        id="value-summary-list-master-detail-datagrid"
        ref={detailGridRef}
        dataSource={historyData}
        columnAutoWidth={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        rowAlternationEnabled={false}
        // onOptionChanged={(e) => {
        //   // Handle column visibility changes from the column chooser
        //   if (e.fullName.includes('columns') || e.name === 'columnOption') {
        //     syncMasterDetailColumns() // Sync columns when the column option changes
        //   }
        // }}
        onContentReady={handleDataGridContentReady}
      >
        <Scrolling mode="standard" rowRenderingMode="standard" useNative={false} />
        <Column width={'34%'} alignment="center" />
        <Column
          dataField="nominal"
          caption={dqTranslateMsg('Page_ValueSummaryList_' + 'Nominal')}
          width={'10%'}
          alignment="center"
          cellRender={(cellData) => renderNominal(cellData, configSettings)}
        />
        <Column dataField="lsl" caption={dqTranslateMsg('Page_ValueSummaryList_' + 'LSL')} width={'10%'} alignment="center" cellRender={(cellData) => renderLsl(cellData, configSettings)} />
        <Column dataField="usl" caption={dqTranslateMsg('Page_ValueSummaryList_' + 'USL')} width={'10%'} alignment="center" cellRender={(cellData) => renderUsl(cellData, configSettings)} />
        <Column dataField="v0014" caption={dqTranslateMsg('Db_field_Value_' + 'V0014')} width={'10%'} alignment="center" cellRender={(cellData) => renderV0014(cellData)} />
        <Column dataField="v0053" caption={dqTranslateMsg('Db_field_Value_' + 'V0053')} width={'10%'} alignment="center" cellRender={(cellData) => renderV0053(cellData)} />
        <Column
          dataField="char_time"
          caption={dqTranslateMsg('Page_ValueSummaryList_' + 'MeasurementTime')}
          alignment="center"
          width={'15%'}
          cellRender={(cellData) => renderDateTime(cellData, settings)}
        />
        <Column
          dataField="result"
          caption={dqTranslateMsg('Page_ValueSummaryList_' + 'Result')}
          width={'10%'}
          alignment="center"
          cellRender={(cellData) => renderResult(cellData, settings, configSettings)}
        />
        <Column dataField="value" caption={dqTranslateMsg('Page_ValueSummaryList_' + 'Value')} width={'10%'} alignment="center" cellRender={(cellData) => renderValue(cellData, configSettings)} />
        <Column dataField="deviation" caption={dqTranslateMsg('Page_ValueSummaryList_' + 'Deviation')} width={'14%'} alignment="center" cellRender={renderDeviationChart} />
      </DataGrid>
    </div>
  )
}
