import React, { useState, useEffect, useRef } from 'react'
import { Button, CheckBox, TextBox } from 'devextreme-react'
import { Popup } from 'devextreme-react/popup'
import { DataGrid, Column } from 'devextreme-react/data-grid'
// import 'devextreme/dist/css/dx.light.css'

export const CustomColumnChooser = ({ dataGridRef, visible, onHiding, title, emptyPanelText, placeholder }) => {
  const [allSelected, setAllSelected] = useState(false)
  const [columns, setColumns] = useState([])
  const [searchText, setSearchText] = useState('')
  const searchRef = useRef(null)

  useEffect(() => {
    if (dataGridRef.current) {
      const visibleColumns = dataGridRef.current.instance.getVisibleColumns().map((col, index) => ({
        ...col,
        index,
      }))
      setColumns(visibleColumns)
    }
  }, [visible, dataGridRef])

  const handleSelectAllChange = (e) => {
    const selected = e.value
    setAllSelected(selected)
    if (dataGridRef.current) {
      columns.forEach((col) => {
        dataGridRef.current.instance.columnOption(col.index, 'visible', selected)
      })
      setColumns(columns.map((col) => ({ ...col, visible: selected })))
    }
  }

  const handleColumnVisibilityChange = (columnIndex, isVisible) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.columnOption(columns[columnIndex].index, 'visible', isVisible)
      setColumns(columns.map((col, index) => (index === columnIndex ? { ...col, visible: isVisible } : col)))
    }
  }

  const handleSearchChange = (e) => {
    setSearchText(e.value)
  }

  const filteredColumns = columns.filter((col) => col.caption.toLowerCase().includes(searchText.toLowerCase()))

  return (
    <Popup visible={visible} onHiding={onHiding} dragEnabled={true} closeOnOutsideClick={true} showCloseButton={true} title={title} width={300} height={500}>
      <div>
        <TextBox value={searchText} onValueChanged={handleSearchChange} placeholder={placeholder} ref={searchRef} showClearButton={true} style={{ marginBottom: 10 }} />
        <CheckBox value={allSelected} text="Select All" onValueChanged={handleSelectAllChange} style={{ marginBottom: 10 }} />
        {filteredColumns.length > 0 ? (
          filteredColumns.map((column, index) => (
            <div key={index}>
              <CheckBox value={column.visible} text={column.caption} onValueChanged={(e) => handleColumnVisibilityChange(index, e.value)} />
            </div>
          ))
        ) : (
          <div>{emptyPanelText}</div>
        )}
      </div>
    </Popup>
  )
}
