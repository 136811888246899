import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Toast } from 'devextreme-react/toast'

const DQToast = forwardRef((props, ref) => {
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: '',
  })

  useImperativeHandle(ref, () => ({
    handleToastDisplay(type, message) {
      setToastConfig({
        isVisible: true,
        type,
        message,
      })
    },
  }))

  const onHiding = () => {
    setToastConfig((prev) => ({
      ...prev,
      isVisible: false,
    }))
  }

  return (
    <Toast
      width={400}
      visible={toastConfig.isVisible}
      message={toastConfig.message}
      type={toastConfig.type}
      onHiding={onHiding}
      displayTime={6000} // Set to 6000 for 6 seconds display
    />
  )
})

export default DQToast
