// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}`, "",{"version":3,"sources":["webpack://./src/layouts/side-nav-outer-toolbar/side-nav-outer-toolbar.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".side-nav-outer-toolbar {\r\n  flex-direction: column;\r\n  display: flex;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n\r\n.layout-header {\r\n  z-index: 1501;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
