import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import produce from 'immer'
import { getCommonMsg, getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import { useDispatch } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'

const valueListContentOrder = () => {
    const dateTimeAscStr = dqTranslateMsg('SidePanel_' + 'DateTime') + ' ' + dqTranslateMsg('SidePanel_' + 'Ascending')
    const dateTimeDescStr = dqTranslateMsg('SidePanel_' + 'DateTime') + ' ' + dqTranslateMsg('SidePanel_' + 'Descending')
    const partIdentAscStr = dqTranslateMsg('SidePanel_' + 'PartIdent') + ' ' + dqTranslateMsg('SidePanel_' + 'Ascending')
    const partIdentDescStr = dqTranslateMsg('SidePanel_' + 'PartIdent') + ' ' + dqTranslateMsg('SidePanel_' + 'Descending')
    return [
        { value: 'none', text: dqTranslateMsg('SidePanel_' + 'None') },
        { value: 'date-time-asc', text: dateTimeAscStr },
        { value: 'date-time-desc', text: dateTimeDescStr },
        { value: 'part-ident-asc', text: partIdentAscStr },
        { value: 'part-ident-desc', text: partIdentDescStr },
    ]
}

function SettingsValueListOptions(pageKey, pageState, configState) {
    const dispatch = useDispatch()

    const sbPageSettings = pageState

    const onContentOrderValueChanged = (e, dataField) => {
        const value = e.itemData.value
        const next = produce(sbPageSettings, (draft) => {
            draft.page[dataField] = value
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <GroupItem
            key={`value-list-option-group-item`}
            colCount={2}
            caption={getCommonMsg(msgType.SidePanel, 'ValueListOptions')}
        >
            {SelectBoxSimpleItem({
                colSpan: 1,
                label: getCommonMsg(msgType.SidePanel, 'ContentOrder'),
                labelLocation: 'top',
                name: 'contentOrder',
                items: valueListContentOrder(),
                valueExpr: 'value',
                displayExpr: 'text',
                value: sbPageSettings.page.valueListContentOrder,
                onItemClick: (e) => onContentOrderValueChanged(e, 'valueListContentOrder'),
            })}
        </GroupItem>
    )
}

export default SettingsValueListOptions
