export const reportPrintMultiFunctionSettingsPrm = {
  page: {
    layout: {
      column: 1,
      row: 1,
    },
    reportMode: 'user', // editor, user
    debugMode: true,
    selectedTemplate: 0,
    templates: [
      {
        template: {
          name: 'template-name-1',
          hint: 'template-hint-1',
        },
        page: {
          size: 'A4',
          orientation: 'landscape',
          wrap: false,
          style: {
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: '#AAAAAA',
          }, // px
          debug: false,
          dpi: 72,
        },
        elements: [
          {
            name: 'element1',
            style: {
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              height: '10%',
              backgroundColor: '#333333',
            },
            columnCount: 1,
            columnContent: [{ type: 'text', style: { fontStyle: 'bold', color: 'white', textAlign: 'center' }, content: 'Process Artz Process Capability' }],
          },
          {
            name: 'element2',
            style: {
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              height: '20%',
              backgroundColor: '#444444',
            },
            columnCount: 1,
            columnContent: [{ type: 'valueChart', style: { width: '30%', height: '100%' }, content: 'valueChart' }],
          },
          {
            name: 'element3',
            style: {
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              height: '20%',
              backgroundColor: '#444444',
            },
            columnCount: 2,
            columnContent: [
              { type: 'valueChart', style: { width: '30%', height: '100%' }, content: 'valueChart' },
              { type: 'histogramChart', style: { width: '30%', height: '100%' }, content: 'valueChart' },
            ],
          },
          {
            name: 'element4',
            style: {
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              height: '20%',
              backgroundColor: '#444444',
            },
            columnCount: 3,
            columnContent: [
              { type: 'valueChart', style: { width: '30%', height: '100%' }, content: 'valueChart' },
              { type: 'histogramChart', style: { width: '30%', height: '100%' }, content: 'valueChart' },
              { type: 'probabilityPlotChart', style: { width: '30%', height: '100%' }, content: 'valueChart' },
            ],
          },
        ],
      },
      {
        template: {
          name: 'template-name-1',
          hint: 'template-hint-1',
        },
        page: {
          size: 'A4',
          orientation: 'portrait',
          wrap: false,
          style: {
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: '#AAAAAA',
          }, // px
          debug: false,
          dpi: 72,
        },
        elements: [
          {
            name: 'element1',
            style: {
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              height: '10%',
              backgroundColor: '#333333',
            },
            columnCount: 3,
            columnContent: [
              { type: 'text', style: { color: 'white', textAlign: 'center' }, content: 'Title text2' },
              { type: 'text', style: { fontStyle: 'bold', color: 'red', textAlign: 'center' }, content: 'Title text2' },
              { type: 'text', style: { fontStyle: 'bold', color: 'white', textAlign: 'left' }, content: 'Title text2' },
            ],
          },
          {
            name: 'element2',
            style: {
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              height: '20%',
              backgroundColor: '#444444',
            },
            columnCount: 1,
            columnContent: [{ type: 'valueChart', style: { color: 'white', textAlign: 'center' }, content: 'valueChart' }],
          },
          {
            name: 'element3',
            style: {
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              height: '20%',
              backgroundColor: '#444444',
            },
            columnCount: 1,
            columnContent: [{ type: 'histogramChart', style: { color: 'white', textAlign: 'center' }, content: 'valueChart' }],
          },
          {
            name: 'element4',
            style: {
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              height: '20%',
              backgroundColor: '#444444',
            },
            columnCount: 1,
            // columnContent: [{ type: 'valueChart', style: { color: 'white', textAlign: 'center' }, content: 'valueChart' }],
            // columnContent: [{ type: 'histogramChart', style: { color: 'white', textAlign: 'center' }, content: 'valueChart' }],
            columnContent: [{ type: 'probabilityPlotChart', style: { color: 'white', textAlign: 'center' }, content: 'valueChart' }],
          },
        ],
      },
    ],
  },
  theme: {},
}
