import { GroupItem } from 'devextreme-react/form'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'

const TAB_SIZE = 4

function prepareItem(item, spaces) {
    return Array.isArray(item[0]) ? formatValue(item, spaces + TAB_SIZE) : JSON.stringify(item)
}
function formatValue(value, spaces = TAB_SIZE) {
    if (value && Array.isArray(value[0])) {
        const formattedValue = value.map((item) => prepareItem(item, spaces)).join(`,${getLineBreak(spaces)}`)
        return `[${getLineBreak(spaces)}${formattedValue}${getLineBreak(spaces - TAB_SIZE)}]`
    }
    return JSON.stringify(value)
}
function getLineBreak(spaces) {
    return `\r\n${new Array(spaces + 1).join(' ')}`
}

function makeFieldsByFieldList(useFieldListAll) {
    const fields = useFieldListAll.map((fieldInfo) => {
        const field = {
            dataField: fieldInfo.numValue,
            caption: fieldInfo.text + ` (${fieldInfo.numValue})`,
            dataType: 'string',
            filterOperations: ['contains', '='],
        }

        if (CatalogUtils.isCatalogField(fieldInfo.value)) {
            field.lookup = {
                dataSource: ['1', '2', '3'],
            }
        }

        if (field.dataField === '0004') {
            field.dataType = 'datetime'
            field.filterOperations = ['<=', '>=']
        }

        return field
    })

    return fields
}

const DBFieldAdvancedPreviewFilter = (pageKey, pageState, configState, othersPage) => {
    const dispatch = useDispatch()
    const configSettings = configState

    const [filters2, setFilters] = useState(null)

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }
    const usePartFilterField = CPUtils.getConfigItemListFromUseField('Part', false, null, useFieldList.part, configState)
    const useCharFilterField = CPUtils.getConfigItemListFromUseField('Characteristics', false, null, useFieldList.char, configState)
    const useValueFilterField = CPUtils.getConfigItemListFromUseField('Value', false, null, useFieldList.value, configState)

    const filters = othersPage.databaseSavedAdvancedFilterList.data
    useFieldList = [...usePartFilterField, ...useCharFilterField, ...useValueFilterField]
    const fields = makeFieldsByFieldList(useFieldList)

    const onApplyButtonClick = () => {
        if (!validateFilters(filters)) {
            alert(getErrProcMsg(procErrTable.E00001))
            return
        }
        console.log('applied')
        const next = produce(pageState, (draft) => {
            draft.filters.advanced.data = filters
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const onClearButtonClick = () => {
        console.log('clear')
        const next = produce(pageState, (draft) => {
            draft.filters.advanced.data = null
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const onValueChanged = (e) => {
        console.log(e)
        // setFilters(e.value)
    }

    return (
        <GroupItem
            key={`db-field-advanced-preview-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'SelectedFilter')}
        >
            {AdvancedFilterItem({
                colSpan: 2,
                fields: fields,
                value: filters,
                maxGroupLevel: 1,
                onValueChanged: onValueChanged,
                // onContentReady: onContentReady,
            })}
            {ButtonSimpleItem({
                colSpan: 1,
                type: 'normal',
                text: dqTranslateMsg('SidePanel_' + 'Apply'),
                icon: 'check',
                width: '100%',
                height: '100%',
                onClick: onApplyButtonClick,
            })}
            {ButtonSimpleItem({
                colSpan: 1,
                type: 'normal',
                text: dqTranslateMsg('SidePanel_' + 'ClearAll'),
                icon: 'clear',
                width: '100%',
                height: '100%',
                onClick: onClearButtonClick,
            })}
        </GroupItem>
    )
}
export default DBFieldAdvancedPreviewFilter
