// ** Language Imports
import LoadPanel from 'devextreme-react/load-panel'

import { useEffect } from 'react'
import { connect } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
import { useAuth } from 'src/contexts/auth'

import Content from './Content'
import UnauthenticatedContent from './UnauthenticatedContent'
import DevelopmentFunctionForm from './pages/development-functions/main-view/DevelopmentFunctionForm'

function App() {
  const { user } = useAuth()

  if (user) {
    return <Content />
  }
  return <UnauthenticatedContent />
}

const mapStateToProps = (state) => ({
  // common: state.common,
})

const ConnectedApp = connect(mapStateToProps)(App)

export default ConnectedApp
