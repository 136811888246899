// arrange function api
// input data = DB all (part, char, value)
// arrange target = value data
/**
 * 형식 :
 * 1. data는 db field형식을따른다.
 * 2. arrange 설정을 y축, 각 part, char를 x축으로 해서 데이터를 생성한다.
 * 3. value를 x y축에 맞게 배치한다.
 *   3-1. 배치사양2(Sort) - None, Ascending, Descending
 *   3-2. 배치사양1(Arrange) - None, Date/Time, Part ID, Order Number
 *      3-2-1. None 은 Sort된 항목 그대로 표시하는 것 y축은 카운트
 *      3-2-2. Date/Time 은 Date/Time을 y축으로 해서 데이터 배치
 *      3-2-3. Part ID 는 Date/Time을 y축으로 해서 데이터 배치
 *      3-2-4. Order Number 는
 * 4.
 * ** 즉, Arrange는 빈칸을 만드느냐 안만드느냐의 차이다.
 */

/** Ignore Duplicate (시간이 동일한것들 무시)
 * 값과 날짜를 비교 같으면 같은것중아마지막것만 남기기기
 *
 */

const arrangeObject = {
  arrangeType: '',
  sortType: '',
  yData: [],
  xData: [],
}

export function makeArrangedData(arrangeOpt, latestOpt, sortOpt, partData, charData, valueData) {
  const optDBField = [
    { opt: 'PartIdent', dbField: 'v0014' },
    { opt: 'DateTime', dbField: 'v0004' },
    { opt: 'Order', dbField: 'v0053' },
  ]

  /**
   * [arrangedData format]
   */
  const organizedData = []
  charData.map((char) => {
    const targetPart = partData.find((part) => part.part_id === char.part_id)
    const targetValues = valueData.filter((value) => value.part_id === char.part_id && value.char_id === char.char_id)

    organizedData.push({
      part_id: char.part_id,
      char_id: char.char_id,
      partRaw: targetPart,
      charRaw: char,
      valueRaw: targetValues ? targetValues : [], // Only use the Data which have 'Attribute = 0' in Central DB
    })
  })

  /**
   * [ignore duplicate format]
   */

  if (latestOpt) {
    organizedData.forEach((data) => {
      const valueMap = new Map()
      data.valueRaw.forEach((value) => {
        const key = `${value.v0001}_${value.v0004}`
        valueMap.set(key, value)
      })

      // Add valueRawLast to contain only the last entry for each v0001 and v0004 combination
      data.valueRaw = Array.from(valueMap.values())
    })
  }

  let resultData = []
  if (arrangeOpt === 'None') {
    // resultData = organizedData
    const sortedData = organizedData.map((item) => {
      let sortedValueRaw

      if (sortOpt === 'Ascending') {
        sortedValueRaw = [...item.valueRaw].sort((a, b) => a.value_id - b.value_id)
      } else if (sortOpt === 'Descending') {
        sortedValueRaw = [...item.valueRaw].sort((a, b) => b.value_id - a.value_id)
      } else {
        // None
        sortedValueRaw = item.valueRaw
      }

      return {
        ...item,
        valueRaw: sortedValueRaw,
      }
    })

    resultData = sortedData
  } else {
    // arrange
    let fieldAllValue = []
    // let fieldUniqueValue = []
    const arrangedData = []
    const targetField = optDBField.find((item) => item.opt === arrangeOpt)
    fieldAllValue = organizedData.map((data) => data.valueRaw.map((value) => value[targetField.dbField]))

    const fieldUniqueValue = Array.from(new Set(fieldAllValue.flat()))

    const itemCounts = {}

    fieldUniqueValue.forEach((item) => {
      const field = item === null ? 'unknown' : item
      itemCounts[field] = 0 // 초기 카운트를 0으로 설정
      organizedData.forEach((row) => {
        const itemCnt = row.valueRaw.filter((value) => value[targetField.dbField] === item).length
        itemCounts[field] = itemCnt > itemCounts[field] ? itemCnt : itemCounts[field]
      })
    })

    console.log(itemCounts)

    const resultArray = []

    Object.entries(itemCounts).forEach(([key, count]) => {
      for (let i = 0; i < count; i++) {
        resultArray.push(key)
      }
    })

    console.log(resultArray)

    let finalFieldValue
    if (sortOpt === 'Ascending') {
      finalFieldValue = resultArray.flat().sort((a, b) => a - b)
    } else if (sortOpt === 'Descending') {
      finalFieldValue = resultArray.flat().sort((a, b) => b - a)
    } else {
      //None
      finalFieldValue = resultArray.flat()
    }

    organizedData.forEach((data) => {
      data.fields = finalFieldValue
      data.valueRaw = arrangeData(finalFieldValue, data, targetField.dbField, latestOpt)
      arrangedData.push(data)
    })
    resultData = arrangedData
  }

  return resultData
}

function arrangeData(fieldValue, srcData, dbField, latestOpt) {
  const dummyData = {
    part_id: srcData.part_id,
    char_id: srcData.char_id,
    value_id: 9999,
    v0001: 'dummy',
    v0002: 255,
  }

  let arrangeData

  if (latestOpt === true) {
    const arrangeDataTemp = []
    const uniqueFieldValue = [...new Set(fieldValue)]
    uniqueFieldValue.map((value) => {
      const targetValue = srcData.valueRaw.filter((item) => item[dbField] === value)
      // const latestTargetValue = targetValue.reduce((max, current) => (current.value_id > max.value_id ? current : max))
      const maxId = Math.max(...targetValue.map((item) => item.value_id))
      // 해당 value_id를 가진 오브젝트 찾기
      const latestTargetValue = targetValue.find((item) => item.value_id === maxId)
      if (latestTargetValue) {
        arrangeDataTemp.push(latestTargetValue)
      } else {
        arrangeDataTemp.push(dummyData)
      }
    })
    arrangeData = arrangeDataTemp
  } else {
    const arrangeDataTemp = []
    const uniqueFieldValue = [...new Set(fieldValue)]
    uniqueFieldValue.map((value) => {
      const val = value === 'unknown' ? null : value
      const targetValue = srcData.valueRaw.filter((item) => item[dbField] === val)

      if (targetValue.length > 0) {
        targetValue.forEach((value) => {
          arrangeDataTemp.push(value)
        })
      } else {
        const continueDummyCount = fieldValue.filter((item) => item === val).length
        for (let i = 0; i < continueDummyCount; i++) {
          arrangeDataTemp.push(dummyData)
        }
      }
    })
    arrangeData = arrangeDataTemp
  }

  return arrangeData
}
