import React from 'react'
import { GroupItem } from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

const ETCOption = ({ currentFilterMode }) => {
  const dispatch = useDispatch()
  const pageKey = 'database'
  const pageState = useSelector((state) => state.common.pages.database)

  const onCheckBoxIgnoreDuplicatedValueValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.filters[currentFilterMode].others[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  // const onCheckBoxPageCalcInAdvanceValueChanged = (e, dataField) => {
  //   const value = e.value
  //   const next = produce(pageState, (draft) => {
  //     draft.filters.others[dataField] = value
  //   })
  //   dispatch(ps[pageKey].setPageSettingsToRedux(next))
  // }

  return (
    <GroupItem key={`etc-option-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'Etcetera')}>
      {CheckBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'IgnoreDuplicatedValue'),
        labelLocation: 'right',
        name: 'ignoreDuplicatedValue',
        value: pageState.filters[currentFilterMode].others.ignoreDuplicatedValue,
        disable: false,
        onValueChanged: (e) => onCheckBoxIgnoreDuplicatedValueValueChanged(e, 'ignoreDuplicatedValue'),
      })}
      {/* {CheckBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'PageCalculationInAdvance'),
        labelLocation: 'right',
        name: 'pageCalculationInAdvance',
        value: pageState.filters.others.pageCalculationInAdvance,
        disable: false,
        onValueChanged: (e) => onCheckBoxPageCalcInAdvanceValueChanged(e, 'pageCalculationInAdvance'),
      })} */}
    </GroupItem>
  )
}

export default ETCOption
