import { EmptyItem, GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function ThemeSpecificationLimit(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'qualityControlChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onSelectBoxSpecLimitTypeValueChanged = (e, type, dataField) => {
    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      if (!draft.lineChart.markLines[type]) {
        draft.lineChart.markLines[type] = {} // Initialize the key if it doesn't exist
      }
      draft.lineChart.markLines[type][dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxSpecLimitWidthValueChanged = (e, type, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      if (!draft.lineChart.markLines[type]) {
        draft.lineChart.markLines[type] = {} // Initialize the key if it doesn't exist
      }
      draft.lineChart.markLines[type][dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxSpecLimitColorValueChanged = (e, type, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      if (!draft.lineChart.markLines[type]) {
        draft.lineChart.markLines[type] = {} // Initialize the key if it doesn't exist
      }
      draft.lineChart.markLines[type][dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`theme-specification-limit-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'SpecificationLimit')}>
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Type'),
        labelLocation: 'top',
        name: 'shape',
        items: sd.lineTypeDefine(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.lineChart.markLines.specLimit?.type,
        disabled: false,
        onItemClick: (e) => onSelectBoxSpecLimitTypeValueChanged(e, 'specLimit', 'type'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Width'),
        labelLocation: 'top',
        name: 'size',
        min: 1,
        max: 20,
        step: 1,
        value: pageState.lineChart.markLines.specLimit?.width,
        onValueChanged: (e) => onSelectBoxSpecLimitWidthValueChanged(e, 'specLimit', 'width'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'Color'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markLines.specLimit?.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onSelectBoxSpecLimitColorValueChanged(e, 'specLimit', 'color'),
      })}
    </GroupItem>
  )
}
