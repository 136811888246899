import { Grid } from '@mui/material'
import { CheckBox, Form } from 'devextreme-react'
import { Button } from 'devextreme-react/button'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminGroup, updateAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminGroupPrms, makeUpdateAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useApi } from 'src/contexts/api'
import { useAuth } from 'src/contexts/auth'
import DQToast from 'src/functions/DQToast'
import { groupTbDescription as groupTbDesc } from 'src/functions/constants/groupDBFieldDescription'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import './CommonSettings.scss'
import SettingsDisplayValueOption from './components/SettingsDisplayValueOption'
import SettingsOutlierOption from './components/SettingsOutlierOption'
import SettingsProcessCapability from './components/SettingsProcessCapability'
import { makeAdminGroupToDTOFormat } from 'src/dto/DTOImpl'
import { configurationSettingsPrm } from '../../settings/ConfigSettings'

export default function CommonSettings({ pageKey }) {
    const dispatch = useDispatch()

    const { spring } = useApi()
    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const [isDefaultSettings, setIsDefaultSettings] = useState(true)
    const parentRef = useRef(null)
    const toastRef = useRef(null)

    const common = useSelector((state) => state.common)
    const configPage = common.pages.configuration
    const configAll = common.base
    const settings = configPage

    // const catalogs = testData
    const handleSaveButton = async () => {
        const updateGroupSettings = async (element, field, pageValue) => {
            const company = element.company
            const plant = element.plant
            const group = element.group
            const dataJsonStr = JSON.stringify(pageValue)

            const updateData = {
                [field]: dataJsonStr,
            }

            await updateAdminGroup(spring, makeUpdateAdminGroupPrms(company, plant, group, updateData))
        }

        if (isDefaultSettings) {
            const element = {
                company: user.user_company,
                plant: user.user_plant,
                group: user.user_group,
            }

            await updateGroupSettings(element, groupTbDesc.commonSettings, configPage.commonSettings)
        }
        dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'configuration', configPage)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }

    const handleResetButton = async () => {
        const company = user?.user_company
        const plant = user?.user_plant
        const group = user?.user_group

        if (!company || !plant || !group) {
            console.error('company plant group info is invalid')
            return
        }

        const targetGroupInfo = await getAdminGroup(spring, makeGetAdminGroupPrms(company, plant, group))
        const adminGroupDTO = makeAdminGroupToDTOFormat(targetGroupInfo)
        const commonDataStr = adminGroupDTO[groupTbDesc.commonSettings]

        let commonData = null
        if (commonDataStr === '' || commonDataStr === null) {
            console.error('plant commonSettings data is empty')
            commonData = configurationSettingsPrm.commonSettings
        } else {
            commonData = JSON.parse(commonDataStr)
        }
        console.log(`plant commonSettings :  <${commonData}>`)

        const next = produce(configPage, (draft) => {
            draft.commonSettings = commonData
        })

        dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'configuration', next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    const handleCheckboxChange = (e) => {
        setIsDefaultSettings(e.value)
    }

    const items = {
        length: 5,
        // Display Value Option
        list_1: [SettingsDisplayValueOption(pageKey, configPage), null, null, null, null],
        list_2: [SettingsProcessCapability(pageKey, configPage), null, null, null, null],
        list_3: [SettingsOutlierOption(pageKey, configPage), null, null, null, null],
        list_4: [null, null, null, null, null],
        list_5: [null, null, null, null, null],
    }

    return (
        <div className='common-settings-container'>
            <DQLoadPanel open={loading} />
            <DQToast ref={toastRef} />
            <div className='common-settings-control-container'>
                <div className='button-container'>
                    <CheckBox
                        key={'find-distribution-save-check-box'} // key 추가
                        text={dqTranslateMsg('Page_Configuration_' + 'Apply') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Group') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Save')}
                        value={isDefaultSettings} // 권한 상태에 따라 체크 여부 결정
                        width={'100%'}
                        height={'100%'}
                        onValueChanged={handleCheckboxChange} // 상태 변경 핸들러 추가
                    />
                </div>
                <div className='button-container'>
                    <Button
                        icon='save'
                        text={dqTranslateMsg('Page_Configuration_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            {/* <div className="common-settings-content-container"> */}
            <Grid
                container
                spacing={0}
            >
                <Grid
                    item
                    key={'common_settings_1'}
                    style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}
                >
                    <Form
                        id='configuration-form'
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        showColonAfterLabel={false}
                        labelLocation={'top'}
                    >
                        {items.list_1}
                    </Form>
                </Grid>
                <Grid
                    item
                    key={'common_settings_2'}
                    style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}
                >
                    <Form
                        id='configuration-form'
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        showColonAfterLabel={false}
                        labelLocation={'top'}
                    >
                        {items.list_2}
                    </Form>
                </Grid>
                <Grid
                    item
                    key={'common_settings_3'}
                    style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}
                >
                    <Form
                        id='configuration-form'
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        showColonAfterLabel={false}
                        labelLocation={'top'}
                    >
                        {items.list_3}
                    </Form>
                </Grid>
                <Grid
                    item
                    key={'common_settings_4'}
                    style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}
                >
                    <Form
                        id='configuration-form'
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        showColonAfterLabel={false}
                        labelLocation={'top'}
                    >
                        {items.list_4}
                    </Form>
                </Grid>
                <Grid
                    item
                    key={'common_settings_5'}
                    style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}
                >
                    <Form
                        id='configuration-form'
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        showColonAfterLabel={false}
                        labelLocation={'top'}
                    >
                        {items.list_5}
                    </Form>
                </Grid>
            </Grid>
            {/* </div> */}
        </div>
    )
}
