// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-board-display-component-container {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.scroll-board-display-component-container .scroll-board-display-component-content-container {
  width: 100%;
  height: 90%;
}
.scroll-board-display-component-container .scroll-board-display-component-content-container .scroll-board-display-component-content {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.scroll-board-display-component-container .scroll-board-display-component-bottom-nav {
  width: 100%;
  height: 10%;
}`, "",{"version":3,"sources":["webpack://./src/pages/scroll-board/components/main-view/ScrollBoardDisplayComponent.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,WAAA;AACJ;AACI;EAEE,YAAA;EACA,aAAA;EACA,mBAAA;AAAN;AAKE;EACE,WAAA;EACA,WAAA;AAHJ","sourcesContent":[".scroll-board-display-component-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n\r\n  .scroll-board-display-component-content-container {\r\n    width: 100%;\r\n    height: 90%;\r\n\r\n    .scroll-board-display-component-content {\r\n      // width: 100%;\r\n      height: 100%;\r\n      display: flex;\r\n      flex-direction: row;\r\n      // overflow-x: scroll;\r\n    }\r\n  }\r\n\r\n  .scroll-board-display-component-bottom-nav {\r\n    width: 100%;\r\n    height: 10%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
