import ApiClient from './ApiClient'

class JavaApiClient extends ApiClient {
    constructor(baseUrl) {
        super(baseUrl)
    }

    getTimeFormatted(date) {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        return `${year}${month}${day}${hours}${minutes}${seconds}`
    }

    easyLoadStartDate(easyLoadValue, easyLoadType) {
        let fromDate = new Date()
        const now = new Date()
        switch (easyLoadType) {
            case 'hour':
                fromDate = new Date(now.getTime() - easyLoadValue * 60 * 60 * 1000)
                break
            case 'day':
                fromDate = new Date(now.getTime() - easyLoadValue * 24 * 60 * 60 * 1000)
                break
            case 'week':
                fromDate = new Date(now.getTime() - easyLoadValue * 7 * 24 * 60 * 60 * 1000)
                break
        }

        return this.getTimeFormatted(fromDate)
    }

    /**
     * Part APIs (author : brian)
     */

    static flattenFilter(filter) {
        const result = []

        function flattenArray(arr, group = 0) {
            arr.forEach((item, index) => {
                if (Array.isArray(item)) {
                    // Check if the item is a condition array
                    if (Array.isArray(item[0])) {
                        flattenArrayInner(item)
                    } else {
                        result.push([...item, 0])
                    }
                } else if (typeof item === 'string') {
                    // Push logical operators (and/or) directly
                    result.push(item)
                }
            })
        }

        function flattenArrayInner(arr, group = 0) {
            arr.forEach((item, index) => {
                if (Array.isArray(item)) {
                    // Check if the item is a condition array
                    if (Array.isArray(item[0])) {
                        flattenArray(item)
                    } else {
                        const isFirst = index === 0
                        const isLast = index === arr.length - 1
                        result.push([...item, isFirst ? 1 : isLast ? 2 : 0])
                    }
                } else if (typeof item === 'string') {
                    // Push logical operators (and/or) directly
                    result.push(item)
                } else {
                    result.push([...item, 0])
                }
            })
        }

        flattenArray(filter)

        return result
    }

    static isCompositeCondition = (condition) => {
        return Array.isArray(condition) && Array.isArray(condition[0])
    }

    static convertToStructuredFormat(data) {
        const result = []
        if (!this.isCompositeCondition(data)) {
            const item = data
            const [field, equal, value, group] = item
            result.push({
                field: parseInt(field),
                group: 0,
                data: value,
                logic: null,
                equeal: equal === '=' ? 'equeal' : 'contain',
            })
        } else {
            for (let i = 0; i < data.length; i++) {
                const item = data[i]

                if (Array.isArray(item)) {
                    // This is a condition array
                    const [field, equal, value, group] = item
                    const logic = i + 1 < data.length && typeof data[i + 1] === 'string' ? data[i + 1] : null

                    result.push({
                        field: parseInt(field),
                        group: group,
                        data: value,
                        logic: logic,
                        equeal: equal === '=' ? 'equeal' : 'contain',
                    })

                    if (logic !== null) {
                        i++
                    }
                }
            }
        }

        return result
    }

    static convertAdvancedFilter(initialFilter) {
        let result
        let flattened

        if (initialFilter) {
            flattened = this.flattenFilter(initialFilter)
            result = this.convertToStructuredFormat(flattened)

            return result
        }

        return []
    }

    /* Normal Filter */
    static convertFilterData = (value) => {
        switch (value) {
            case '\u0010':
                return null
            case '\u0011':
                return ''
            default:
                return value
        }
    }

    async getPartListFilter(user, dbFilterInfo) {
        const company = user.user_company
        const plant = user.user_plant
        const group = user.user_group

        const filterMode = dbFilterInfo.mode
        const filterList = dbFilterInfo.data
        const { easyLoadValue, dateTimeSort, startDateTime, endDateTime } = filterList.common
        const { arrangement, dateTimeEasySetting, useEasyLoad, easyLoadValueType, ignoreDuplicatedValue, useDateTime, pageCalculationInAdvance } = filterList.others

        function convertNormalFilter(part, char, value) {
            const filters = []

            const addToFilters = (obj, prefix) => {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const field = parseInt(key.replace(prefix, ''))
                        filters.push({
                            field: field,
                            group: 0,
                            data: JavaApiClient.convertFilterData(obj[key]),
                            logic: 'and',
                            equeal: 'contain',
                        })
                    }
                }
            }

            addToFilters(value, 'v')
            addToFilters(part, 'p')
            addToFilters(char, 'c')

            return filters
        }

        const makeReqBody = () => {
            let requestBody = {
                company: company,
                plant: plant,
                group: group,
                dateTimeSort: dateTimeSort.toUpperCase(),
                ...(useDateTime && {
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                }),
                ...(useEasyLoad &&
                    easyLoadValueType === 'count' && {
                        // attribute: 0,
                        dateTimeSort: 'DESCENDING',
                        pagination: `1-${easyLoadValue}`,
                    }),
                ...(useEasyLoad &&
                    easyLoadValueType !== 'count' && {
                        // attribute: 0,
                        startDateTime: this.easyLoadStartDate(easyLoadValue, easyLoadValueType),
                        endDateTime: this.getTimeFormatted(new Date()),
                    }),
            }

            let converted
            if (filterMode === 'quick') {
                const { part, char, value } = filterList.data
                converted = convertNormalFilter(part, char, value)
            } else {
                converted = JavaApiClient.convertAdvancedFilter(filterList.data)
            }
            requestBody = { ...requestBody, filters: converted }

            return requestBody
        }

        const requestBody = makeReqBody()
        const endpoint = 'data/getPartListFilter'
        return this.post(endpoint, requestBody)
    }

    /**
     * Characteristics APIs (author : brian)
     */
    async getCharListFilter(user, ids, dbFilterInfo) {
        const company = user.user_company
        const plant = user.user_plant
        const group = user.user_group

        const filterMode = dbFilterInfo.mode
        const filterList = dbFilterInfo.data
        const { easyLoadValue, dateTimeSort, startDateTime, endDateTime } = filterList.common
        const { arrangement, dateTimeEasySetting, useEasyLoad, easyLoadValueType, ignoreDuplicatedValue, useDateTime, pageCalculationInAdvance } = filterList.others

        function createFilters(part, char, value) {
            const filters = []

            const addToFilters = (obj, prefix) => {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const field = parseInt(key.replace(prefix, ''))
                        filters.push({
                            field: field,
                            group: 0,
                            data: JavaApiClient.convertFilterData(obj[key]),
                            logic: 'and',
                            equeal: 'contain',
                        })
                    }
                }
            }

            addToFilters(value, 'v')
            addToFilters(part, 'p')
            addToFilters(char, 'c')

            return filters
        }
        const makeReqBody = (ids) => {
            let requestBody = {
                company: company,
                plant: plant,
                group: group,
                ids: ids,
                dateTimeSort: dateTimeSort.toUpperCase(),
                ...(useDateTime && {
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                }),
                ...(useEasyLoad &&
                    easyLoadValueType === 'count' && {
                        // attribute: 0,
                        dateTimeSort: 'DESCENDING',
                        pagination: `1-${easyLoadValue}`,
                    }),
                ...(useEasyLoad &&
                    easyLoadValueType !== 'count' && {
                        // attribute: 0,
                        startDateTime: this.easyLoadStartDate(easyLoadValue, easyLoadValueType),
                        endDateTime: this.getTimeFormatted(new Date()),
                    }),
            }

            // const converted = createFilters(part, char, value)
            let converted
            if (filterMode === 'quick') {
                const { part, char, value } = filterList.data
                converted = createFilters(part, char, value)
            } else {
                converted = JavaApiClient.convertAdvancedFilter(filterList.data)
            }
            requestBody = { ...requestBody, filters: converted }

            return requestBody
        }

        const requestBody = makeReqBody(ids)
        const endpoint = 'data/getCharListFilter'
        return this.post(endpoint, requestBody)
    }

    /**
     * Value APIs (author : brian)
     */

    async getValueInfo(user, ids, dbFilterInfo) {
        const company = user.user_company
        const plant = user.user_plant
        const group = user.user_group

        const filterMode = dbFilterInfo.mode
        const filterList = dbFilterInfo.data
        const { easyLoadValue, dateTimeSort, startDateTime, endDateTime } = filterList.common
        const { arrangement, dateTimeEasySetting, useEasyLoad, easyLoadValueType, ignoreDuplicatedValue, useDateTime, pageCalculationInAdvance } = filterList.others

        function createFilters(obj, prefix) {
            const filters = []

            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const field = parseInt(key.replace(prefix, ''))
                    filters.push({
                        field: field,
                        group: 0,
                        data: JavaApiClient.convertFilterData(obj[key]),
                        logic: 'and',
                        equeal: 'contain',
                    })
                }
            }

            if (useEasyLoad && easyLoadValueType === 'count') {
                filters.push({ field: 2, group: 0, data: '0', logic: 'or', equeal: 'contain' })
            }

            return filters
        }
        const makeReqBody = (ids) => {
            let requestBody = {
                company: company,
                plant: plant,
                group: group,
                dateTimeSort: dateTimeSort.toUpperCase(),
                ...(useDateTime && {
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                }),
                ...(useEasyLoad &&
                    easyLoadValueType === 'count' && {
                        // attribute: 0,
                        dateTimeSort: 'DESCENDING',
                        pagination: `1-${easyLoadValue}`,
                    }),
                ...(useEasyLoad &&
                    easyLoadValueType !== 'count' && {
                        // attribute: 0,
                        startDateTime: this.easyLoadStartDate(easyLoadValue, easyLoadValueType),
                        endDateTime: this.getTimeFormatted(new Date()),
                    }),
            }

            let converted
            if (filterMode === 'quick') {
                const { part, char, value } = filterList.data
                converted = createFilters(value, 'v')
            } else {
                converted = JavaApiClient.convertAdvancedFilter(filterList.data)
            }

            requestBody = { ...requestBody, ids, filters: converted }
            return requestBody
        }

        const requestBody = makeReqBody(ids)
        const endpoint = 'data/getValueInfo'
        return this.post(endpoint, requestBody)
    }

    async getValueInfoScrollBoard(user, ids, settings, filters, userFilters) {
        const company = user.user_company
        const plant = user.user_plant
        const group = user.user_group

        const { common } = filters.data
        function createFilters(obj, prefix) {
            const filters = []

            obj.forEach((obj) => {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const field = parseInt(key.replace(prefix, ''))
                        filters.push({
                            field: field,
                            group: 0,
                            // data: obj[key] === 'unknown' ? null : obj[key],
                            data: JavaApiClient.convertFilterData(obj[key]),
                            logic: 'and',
                            equal: 'contain',
                        })
                    }
                }
            })

            if (common.useEasyLoad && common.easyLoadValueType === 'count') {
                filters.push({ field: 2, group: 0, data: '0', logic: 'or', equeal: 'contain' })
            }

            return filters
        }
        const makeReqBody = (ids, filters) => {
            let requestBody = {}

            if (common) {
                requestBody = {
                    company: company,
                    plant: plant,
                    group: group,
                    dateTimeSort: common.dateTimeSort.toUpperCase(),
                    // dateTimeSort: dateTimeSort.toUpperCase(),
                    startDateTime: JavaApiClient.calcStartDateTime(settings.dateRangeIsDayTime, settings.dateRangeDayTime, settings.dateRangeSetting, settings.dateRangeDayOffset),
                    endDateTime: JavaApiClient.calcEndDateTime(settings.dateRangeIsDayTime, settings.dateRangeDayTime),
                }
            }

            const converted = createFilters(filters, 'v')
            // let converted
            // if (filterMode === 'quick') {
            //   const { part, char, value } = filterList.data
            //   converted = createFilters(filters, 'v')
            // } else {
            //   converted = JavaApiClient.convertAdvancedFilter(filterList.data)
            // }

            requestBody = { ...requestBody, ids, filters: converted }
            return requestBody
        }
        const requestBody = makeReqBody(ids, userFilters)
        const endpoint = 'data/getValueInfo'
        return this.post(endpoint, requestBody)
    }
    /**
     * I-BOARD APIs (author: brian)
     */
    static parseDateString(dateString) {
        if (!dateString) return new Date()

        const year = parseInt(dateString.substring(0, 4))
        const month = parseInt(dateString.substring(4, 6)) - 1 // 월은 0부터 시작합니다.
        const day = parseInt(dateString.substring(6, 8))
        const hours = parseInt(dateString.substring(8, 10))
        const minutes = parseInt(dateString.substring(10, 12))
        const seconds = parseInt(dateString.substring(12, 14))

        return new Date(year, month, day, hours, minutes, seconds)
    }

    static formatDatetimeWithOffset(days = 0, weeks = 0, months = 0, dateString = null) {
        const now = this.parseDateString(dateString)

        // 날짜 조정
        now.setDate(now.getDate() - days - weeks * 7)
        now.setMonth(now.getMonth() - months)

        const year = now.getFullYear()
        const month = String(now.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 1을 더해줍니다.
        const day = String(now.getDate()).padStart(2, '0')
        const hours = String(now.getHours()).padStart(2, '0')
        const minutes = String(now.getMinutes()).padStart(2, '0')
        const seconds = String(now.getSeconds()).padStart(2, '0')

        return `${year}${month}${day}${hours}${minutes}${seconds}`
    }

    static daysAgo(dateString, days) {
        return this.formatDatetimeWithOffset(days, 0, 0, dateString)
    }

    static weeksAgo(dateString, weeks) {
        return this.formatDatetimeWithOffset(0, weeks, 0, dateString)
    }

    static monthsAgo(dateString, months) {
        return this.formatDatetimeWithOffset(0, 0, months, dateString)
    }

    static calcStartDateTime(isDateCalender, dateRangeDayTime, dateRange, dateOffset) {
        if (isDateCalender === 1) {
            switch (dateRange) {
                case 10000:
                    return this.daysAgo(dateRangeDayTime, 1)
                case 10001:
                    return this.weeksAgo(dateRangeDayTime, 1)
                case 10002:
                    return this.monthsAgo(dateRangeDayTime, 1)
                default: // 10003
                    return this.daysAgo(dateRangeDayTime, dateOffset)
            }
        } else {
            switch (dateRange) {
                case 10000:
                    return this.daysAgo(null, 1)
                case 10001:
                    return this.weeksAgo(null, 1)
                case 10002:
                    return this.monthsAgo(null, 1)
                default: // 10003
                    return this.daysAgo(null, dateOffset)
            }
        }
    }

    static calcEndDateTime(dateRangeIsDayTime, dateRangeDayTime) {
        if (dateRangeIsDayTime === 1) {
            return dateRangeDayTime
        }
        return this.daysAgo(null, 0)
    }

    async updateIBoardManual(user, userId, settings, filter, convertedStartDateTime, convertedEndDateTime, configPage) {
        const company = user.user_company
        const plant = user.user_plant
        const group = user.user_group

        const makeRequestBody = () => ({
            company: company,
            plant: plant,
            group: group,
            user_id: userId,
            start_datetime: convertedStartDateTime,
            end_datetime: convertedEndDateTime,
            ignore_values_enable: settings.ignoreValuesEnable,
            ignore_values_usl_percent_setting: settings.ignoreValuesUslPercentSetting,
            ignore_values_lsl_percent_setting: settings.ignoreValuesLslPercentSetting,
            // data_range_last_values: settings.dateRangeLastValues,
            order_chart_field: settings.orderChartField,
            order_chart_class: settings.orderChartClass,
            warning_out_percent_setting: settings.warningOutPercentSetting,
            error_out_percent_setting: settings.errorOutPercentSetting,
            part_filters: filter.partFilter,
            use_plausibility_limit: configPage.commonSettings.usePlausibilityFunc,
            use_scrap_limit: configPage.commonSettings.useScrapFunc,
            use_natural_boundary: configPage.commonSettings.useNaturalBoundary,
            use_tolerances: configPage.commonSettings.useAdjustNormalTolerances,
            use_tolerances_percent: configPage.commonSettings.tolerancesPercentage,
        })

        const endpoint = 'data/IboardManualUpdate'
        return this.post(endpoint, makeRequestBody())
    }

    async getIBoardResultData(requestBody) {
        const endpoint = 'data/IboardGetResultData'
        return this.post(endpoint, requestBody)
    }

    async getIBoardResultPartInfo(userId) {
        const makeRequestBody = () => ({
            user_id: userId,
            start_datetime: '2024-01-01 00:00:00',
            end_datetime: '2024-01-01 00:00:00',
        })

        const endpoint = 'data/IboardGetResultPartInfo'
        return this.post(endpoint, makeRequestBody())
    }

    /**
     * ReportPrintMultiFunction APIs (author : brian)
     */
    async getReportTemplates() {
        // const makeRequestBody = () => ({
        //   user_id: userId,
        //   start_datetime: '2024-01-01 00:00:00',
        //   end_datetime: '2024-01-01 00:00:00',
        // })

        // const endpoint = 'data/IboardGetResultPartInfo'
        // return this.post(endpoint, makeRequestBody())

        return [
            { name: 'template1', hint: 'ForTeam', size: 'a4', orientation: 'portrait', data: [] },
            { name: 'template2', hint: 'ForCertification', size: 'a4', orientation: 'landscape', data: [] },
            { name: 'template3', hint: 'ForPartner', size: 'a4', orientation: 'portrait', data: [] },
        ]
    }
    async updateReportTemplates() {
        console.log('updateReportTemplates')
    }

    async deleteReportTemplates() {
        console.log('deleteReportTemplates')
    }

    /**
     * ETC APIs (author : brian)
     */
}

export default JavaApiClient
