import { EmptyItem, GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function ThemeQuantileLimit(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'probabilityPlotChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onSelectBoxSpecLimitTypeValueChanged = (e, type, dataField) => {
    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      if (!draft.lineChart.markLines[type]) {
        draft.lineChart.markLines[type] = {} // Initialize the key if it doesn't exist
      }
      draft.lineChart.markLines[type][dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxSpecLimitWidthValueChanged = (e, type, dataField) => {
    if (e.event === undefined) return
    const value = e.value

    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.lineChart.markLines[type][dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxSpecLimitColorValueChanged = (e, type, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.lineChart.markLines[type][dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`quantile-limit-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'QuantileLimit')}>
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'X99865Type'),
        labelLocation: 'top',
        name: 'shape',
        items: sd.lineTypeDefine(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.lineChart.markLines.x99_865.type,
        disabled: false,
        onItemClick: (e) => onSelectBoxSpecLimitTypeValueChanged(e, 'x99_865', 'type'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'X99865Width'),
        labelLocation: 'top',
        name: 'size',
        min: 1,
        max: 20,
        step: 1,
        value: pageState.lineChart.markLines.x99_865.width,
        onValueChanged: (e) => onSelectBoxSpecLimitWidthValueChanged(e, 'x99_865', 'width'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'X99865Color'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markLines.x99_865.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onSelectBoxSpecLimitColorValueChanged(e, 'x99_865', 'color'),
      })}
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'X0_135Type'),
        labelLocation: 'top',
        name: 'shape',
        items: sd.lineTypeDefine(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.lineChart.markLines.x0_135.type,
        disabled: false,
        onItemClick: (e) => onSelectBoxSpecLimitTypeValueChanged(e, 'x0_135', 'type'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'X0_135Width'),
        labelLocation: 'top',
        name: 'size',
        min: 1,
        max: 20,
        step: 1,
        value: pageState.lineChart.markLines.x0_135.width,
        onValueChanged: (e) => onSelectBoxSpecLimitWidthValueChanged(e, 'x0_135', 'width'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'X0_135Color'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markLines.x0_135.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onSelectBoxSpecLimitColorValueChanged(e, 'x0_135', 'color'),
      })}
      {/* {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'XBarType'),
        labelLocation: 'top',
        name: 'shape',
        items: sd.lineTypeDefine(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.lineChart.markLines.xBar.type,
        disabled: false,
        onItemClick: (e) => onSelectBoxSpecLimitTypeValueChanged(e, 'xBar', 'type'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,label: dqTranslateMsg('SidePanel_' + 'XBarWidth'),
        labelLocation: 'top',
        name: 'size',
        min: 1,
        max: 20,
        step: 1,
        value: pageState.lineChart.markLines.xBar.width,
        onValueChanged: (e) => onSelectBoxSpecLimitWidthValueChanged(e, 'xBar', 'width'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'XBarColor'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markLines.xBar.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onSelectBoxSpecLimitColorValueChanged(e, 'xBar', 'color'),
      })}
      <EmptyItem /> */}
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'X50Type'),
        labelLocation: 'top',
        name: 'shape',
        items: sd.lineTypeDefine(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.lineChart.markLines.x50.type,
        disabled: false,
        onItemClick: (e) => onSelectBoxSpecLimitTypeValueChanged(e, 'x50', 'type'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'X50Width'),
        labelLocation: 'top',
        name: 'size',
        min: 1,
        max: 20,
        step: 1,
        value: pageState.lineChart.markLines.x50.width,
        onValueChanged: (e) => onSelectBoxSpecLimitWidthValueChanged(e, 'x50', 'width'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'X50Color'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markLines.x50.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onSelectBoxSpecLimitColorValueChanged(e, 'x50', 'color'),
      })}
    </GroupItem>
  )
}
