import React from 'react'
import { EmptyItem, GroupItem } from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import { produce } from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { defScrollBoardField, defScrollBoardNoneField } from 'src/default/field/scrollBoard.js'

const ValueChartOption = (pageKey, pageState, configState, othersPage) => {
    const dispatch = useDispatch()
    // const pageKey = 'scrollBoard'
    const sbPageSettings = pageState
    const configPage = configState

    const isUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = isUseFieldListEmpty
        ? { part: [], char: [], value: [] } // Default value if useFieldList is empty
        : othersPage.useFieldList // Use the existing useFieldList if not empty

    const useValueFilterField = useFieldList.value.map((item) => {
        return { ...item, text: dqTranslateMsg('Db_field_Value_' + item.text) }
    })
    const useFieldListValue = [...defScrollBoardNoneField(), ...useValueFilterField]

    const onCheckBoxValueChanged = (e, dataField) => {
        if (e.event === undefined) return
        const value = e.value
        const next = produce(sbPageSettings, (draft) => {
            draft.page[dataField] = value
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const onSelectBoxValueChanged = (e, dataField) => {
        const { value } = e.itemData
        const next = produce(sbPageSettings, (draft) => {
            draft.page[dataField] = []
            draft.page[dataField].push(value)
        })

        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <GroupItem
            key={`value-chart-option-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'ValueChartOption')}
        >
            {CheckBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'PopupMode'),
                labelLocation: 'right',
                name: 'valueChartPopupMode',
                value: sbPageSettings.page.valueChartPopupMode,
                onValueChanged: (e) => onCheckBoxValueChanged(e, 'valueChartPopupMode'),
            })}
            <EmptyItem />
        </GroupItem>
    )
}

export default ValueChartOption
