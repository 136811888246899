import React from 'react'
import { EmptyItem, GroupItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
export function BarChartTheme(props) {
  const { pageKey, pageState, configState, noTitle } = props
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  const sbPageSettings = pageState

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onCheckBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onColorBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.theme[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={'bar-chart-theme-group-item'} colCount={4} caption={!noTitle && dqTranslateMsg('SidePanel_' + 'Common')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'XAxisFontSize'),
        labelLocation: 'top',
        name: 'barChartXFontSize',
        min: 1,
        max: 30,
        step: 1,
        value: sbPageSettings.theme.barChartXFontSize,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'barChartXFontSize'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'YAxisFontSize'),
        labelLocation: 'top',
        name: 'barChartYFontSize',
        min: 1,
        max: 30,
        step: 1,
        value: sbPageSettings.theme.barChartYFontSize,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'barChartYFontSize'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'CountFontSize'),
        labelLocation: 'top',
        name: 'barChartCountFontSize',
        min: 1,
        max: 30,
        step: 1,
        value: sbPageSettings.theme.barChartCountFontSize,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'barChartCountFontSize'),
      })}
      <EmptyItem />
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 4,
        label: dqTranslateMsg('SidePanel_' + 'AxisFontColorCustomEnable'),
        labelLocation: 'right',
        name: 'barChartFontColorCustomize',
        value: sbPageSettings.theme.barChartFontColorCustomize,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'barChartFontColorCustomize'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'XAxisFontColor'),
        labelLocation: 'top',
        name: 'barChartXFontColor',
        value: sbPageSettings.theme.barChartXFontColor,
        disabled: !sbPageSettings.theme.barChartFontColorCustomize,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'barChartXFontColor'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'YAxisFontColor'),
        labelLocation: 'top',
        name: 'barChartYFontColor',
        value: sbPageSettings.theme.barChartYFontColor,
        disabled: !sbPageSettings.theme.barChartFontColorCustomize,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'barChartYFontColor'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 4,
        label: dqTranslateMsg('SidePanel_' + 'CountFontColorCustomEnable'),
        labelLocation: 'right',
        name: 'barChartCountFontColorCustomize',
        value: sbPageSettings.theme.barChartCountFontColorCustomize,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'barChartCountFontColorCustomize'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'CountFontColor'),
        labelLocation: 'top',
        name: 'barChartCountFontColor',
        value: sbPageSettings.theme.barChartCountFontColor,
        disabled: !sbPageSettings.theme.barChartCountFontColorCustomize,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'barChartCountFontColor'),
      })}
    </GroupItem>
  )
}
