import { useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import CPUtils from 'src/functions/CommonPageUtils'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import ScrollBoardDisplayComponent from './ScrollBoardDisplayComponent'
import './ScrollBoardForm.scss'

const updateListOrderChartList = (curPage) => {
  const areLengthsEqual = (fieldData, typeData) => {
    return fieldData.length === typeData.length
  }

  if (curPage.page.orderChartField === '' || curPage.page.orderChartType === '') {
    return []
  }

  const fieldData = curPage.page.orderChartField.split('|')
  const typeData = curPage.page.orderChartType.split('|')

  if (!areLengthsEqual(fieldData, typeData)) {
    return []
  }

  const orderChartList = fieldData.map((field, index) => ({
    id: index,
    field: field,
    type: typeData[index],
  }))
  return orderChartList
}

function ScrollBoardForm({ pageKey, setDisableSidePanel, common, chartRawData }) {
  // For initialRendering
  const isInitialRender = useRef(true)

  const { parts, chars, values } = useMemo(() => CPUtils.loadRefChartData(chartRawData), [chartRawData])

  const { charArray, curPage, configPage, currentSel } = useMemo(() => CPUtils.loadRefOtherData(common, chars, pageKey), [common])

  const pageTypes = updateListOrderChartList(curPage)
  const pageTypesLength = pageTypes.length

  const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)

  const valuesArranged = useMemo(() => CPUtils.removeInvalidInValues(selectedValueWithDummy, curPage.page.showDeletedValue), [selectedValueWithDummy, curPage.page.showDeletedValue])

  if (pageTypes.length > 0) {
    return (
      <div className="dx-theme-background-color">
        <ScrollBoardDisplayComponent pageTypes={pageTypes} pageLength={pageTypesLength} common={common} setDisableSidePanel={setDisableSidePanel} />
      </div>
    )
  }

  return <PageWarningMessage message={'There is no Order Chart Settings'} />
}

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ScrollBoardForm)
