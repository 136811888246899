import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'

export default function SymbolColor(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'overview'

  const ovPageSettings = pageState

  const onColorBoxValueChanged = (e, state, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(ovPageSettings, (draft) => {
      draft.pieChart[state][dataField] = value
      draft.barChart[state][dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`symbol-color-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'QualityStatusColors')}>
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'NG'),
        labelLocation: 'top',
        name: 'errorColor',
        value: ovPageSettings.pieChart.error.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'error', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Warn'),
        labelLocation: 'top',
        name: 'Color',
        value: ovPageSettings.pieChart.warn.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'warn', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Good'),
        labelLocation: 'top',
        name: 'goodColor',
        value: ovPageSettings.pieChart.good.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'good', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'None'),
        labelLocation: 'top',
        name: 'noneColor',
        value: ovPageSettings.pieChart.none.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'none', 'color'),
      })}
    </GroupItem>
  )
}
