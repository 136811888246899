// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.decision-tree-control-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.decision-tree-control-container #diagram {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/chart-decision-tree/components/main-view/DecisionTreeChartForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":[".dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n}\r\n\r\n.decision-tree-control-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow-y: auto;\r\n\r\n  #diagram {\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
