import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as bpImpl from './BoxPlotChartOptionImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'

function roundUp(num, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces)
  return Math.ceil(num * factor) / factor
}
function roundDown(num, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces)
  return Math.floor(num * factor) / factor
}

const formatBoxPlotTooltip = (args, names, settings, configSettings, charData, boxPlotRefData) => {
  const { marker, value } = args

  const refBoxDataArray = boxPlotRefData.boxData[args.dataIndex]
  if (settings.page.normalizationType === true) {
    return (
      `${names[args.name]}<br />` +
      `${marker} ${dqTranslateMsg('Page_BoxPlotChart_' + 'Min')} : ${DNUtils.calcValueDisplay(value[1], configSettings)} (${DNUtils.calcValueDisplay(refBoxDataArray[0], configSettings)})<br />` +
      `${marker} Q1 : ${DNUtils.calcValueDisplay(value[2], configSettings)} (${DNUtils.calcValueDisplay(refBoxDataArray[1], configSettings)})<br />` +
      `${marker} ${dqTranslateMsg('Page_BoxPlotChart_' + 'Median')} : ${DNUtils.calcValueDisplay(value[3], configSettings)} (${DNUtils.calcValueDisplay(refBoxDataArray[2], configSettings)})<br />` +
      `${marker} Q3 : ${DNUtils.calcValueDisplay(value[4], configSettings)} (${DNUtils.calcValueDisplay(refBoxDataArray[3], configSettings)})<br />` +
      `${marker} ${dqTranslateMsg('Page_BoxPlotChart_' + 'Max')} : ${DNUtils.calcValueDisplay(value[5], configSettings)} (${DNUtils.calcValueDisplay(refBoxDataArray[4], configSettings)})<br />`
    )
  } else {
    return (
      `${names[args.name]}<br />` +
      `${marker} ${dqTranslateMsg('Page_BoxPlotChart_' + 'Min')} : ${DNUtils.calcValueDisplay(value[1], configSettings)}<br />` +
      `${marker} Q1 : ${DNUtils.calcValueDisplay(value[2], configSettings)}<br />` +
      `${marker} ${dqTranslateMsg('Page_BoxPlotChart_' + 'Median')} : ${DNUtils.calcValueDisplay(value[3], configSettings)}<br />` +
      `${marker} Q3 : ${DNUtils.calcValueDisplay(value[4], configSettings)} <br />` +
      `${marker} ${dqTranslateMsg('Page_BoxPlotChart_' + 'Max')} : ${DNUtils.calcValueDisplay(value[5], configSettings)}<br />`
    )
  }
}

const formatOutlierTooltip = (args, names, settings, configSettings, charData, boxPlotRefData) => {
  const refOutliersArray = boxPlotRefData.outliers[args.dataIndex]

  const targetCategory = names[args.name]
  const delimiter = '\u000f'
  const splitted = targetCategory.split(delimiter)
  const xAxisName = `${splitted[0]}`
  const partId = parseInt(splitted[1])
  const charId = parseInt(splitted[2])

  const targetChar = charData.find((char) => char.part_id === partId && char.char_id === charId)
  if (settings.page.normalizationType === true) {
    return (
      `${xAxisName} ${args.seriesId}<br />` +
      `${args.marker} ${dqTranslateMsg('Page_BoxPlotChart_' + 'Value')} : ${DNUtils.calcValueDisplay(args.value[1], configSettings)} (${DNUtils.calcValueDisplay(refOutliersArray[1], configSettings)})`
    )
  } else {
    return `${xAxisName} ${args.seriesId}<br />` + `${args.marker} ${dqTranslateMsg('Page_BoxPlotChart_' + 'Value')} : ${DNUtils.calcValueDisplay(args.value[1], configSettings)} `
  }
}

const toolTipBalloonCallback = (args, decimalPlaces, curPage, configSettings, names, charData, boxPlotRefData) => {
  // const data = args[0]
  const data = args
  if (data.seriesId === 'boxplot') {
    return formatBoxPlotTooltip(data, names, curPage, configSettings, charData, boxPlotRefData)
  } else if (data.seriesId === 'outlier') {
    return formatOutlierTooltip(data, names, curPage, configSettings, charData, boxPlotRefData)
  } else {
    return null
  }
}

export const getBoxPlotChartOption = (settings, configSettings, charData, valueData, names, decimalPlacesArray, boxPlotData, boxPlotRefData) => {
  // const { names, decimalPlacesArray, boxPlotData } = bpImpl.makeBoxPlotDataSet(settings, charData, valueData, configSettings)

  // decimalPlaces
  const filteredDp = decimalPlacesArray.filter((item) => item !== null)
  const uniqueDp = [...new Set(filteredDp)]
  const decimalPlaces = uniqueDp.length !== 0 ? Math.max(...uniqueDp) : configSettings.commonSettings.displayDecimalPlaces

  const maxValueBoxData = bpImpl.getMaxInArray(boxPlotData.boxData.flat())
  const maxValueOutliers = bpImpl.getMaxInArray(boxPlotData.outliers.map((innerArray) => innerArray[1]))
  const minValueBoxData = bpImpl.getMinInArray(boxPlotData.boxData.flat())
  const minValueOutliers = bpImpl.getMinInArray(boxPlotData.outliers.map((innerArray) => innerArray[1]))

  const maxValue = maxValueBoxData > maxValueOutliers ? maxValueBoxData : maxValueOutliers
  const minValue = minValueBoxData < minValueOutliers ? minValueBoxData : minValueOutliers

  let calcTop = 0
  let calcBottom = 0
  if (settings.page.normalizationType === true) {
    calcTop = bpImpl.makeYAxisTop(maxValue, minValue) < 1 ? 1 + 1 * 0.1 : bpImpl.makeYAxisTop(maxValue, minValue)
    calcBottom = bpImpl.makeYAxisBottom(maxValue, minValue) > -1 ? -1 - 1 * 0.1 : bpImpl.makeYAxisBottom(maxValue, minValue)
  } else {
    calcTop = bpImpl.makeYAxisTop(maxValue, minValue)
    calcBottom = bpImpl.makeYAxisBottom(maxValue, minValue)
  }

  const yAxisTop = calcTop
  const yAxisBottom = calcBottom

  return {
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    grid: {
      top: '10%',
      bottom: '6%',
      left: '0%',
      right: '5%',
      containLabel: true,
    },
    legend: {
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'item',
      appendToBody: true,
      position: 'right',
      formatter: (args) => toolTipBalloonCallback(args, decimalPlaces, settings, configSettings, names, charData, boxPlotRefData),
      axisPointer: {
        type: 'shadow',
        label: {
          textStyle: {
            fontFamily: 'Arial',
          },
        },
      },
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    xAxis: {
      type: 'category',
      data: boxPlotData.axisData,
      boundaryGap: true,
      nameGap: 30,
      splitArea: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        rotate: 90,
        formatter: function (params) {
          try {
            const delimiter = '\u000f'
            if (params in names) {
              const xAxisName = names[params].split(delimiter)
              return xAxisName[0]
            } else {
              throw new Error('Parameter not found in names')
            }
          } catch (error) {
            console.error('Error processing formatter:', error.message)
            return 'Invalid data'
          }
        },
      },
      axisLine: {
        onZero: false,
      },
    },
    yAxis: {
      type: 'value',
      min: yAxisBottom,
      max: yAxisTop,
      splitArea: {
        show: false,
      },
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        formatter: function (value) {
          return DNUtils.calcValueDisplay(value, configSettings)
        },
      },
      axisLine: {
        onZero: false,
      },
    },
    dataZoom: [
      {
        show: true,
        type: 'slider',
        orient: 'horizontal',
        xAxisIndex: 0,
        height: `3%`,
        bottom: '3%',
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'slider',
        orient: 'vertical',
        width: `3%`,
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'none',
        zoomLock: false, // Allow zooming
        zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
      },
      {
        show: true,
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none',
      },
    ],
    series: [
      {
        id: 'boxplot',
        name: dqTranslateMsg('Page_BoxPlotChart_' + 'BoxPlot'),
        type: 'boxplot',
        data: boxPlotData.boxData,
        colorBy: 'data',
        // datasetIndex: 1,
        itemStyle: {
          color: settings.boxPlotChart.innerColor,
          borderColor: settings.boxPlotChart.borderColor,
        },
        ...(settings.page.normalizationType === true
          ? {
              markLine: {
                animation: false,
                symbol: 'none',
                precision: 10,
                data: [
                  {
                    yAxis: 1, // settings.usl,
                    label: {
                      show: true,
                      position: 'end',
                      formatter: '{b}',
                      fontFamily: 'Arial',
                    },
                    lineStyle: {
                      type: 'solid',
                      width: 1,
                      color: 'red',
                    },
                  },
                  {
                    yAxis: -1, // settings.usl,
                    label: {
                      show: true,
                      position: 'end',
                      formatter: '{b}',
                      fontFamily: 'Arial',
                    },
                    lineStyle: {
                      type: 'solid',
                      width: 1,
                      color: 'red',
                    },
                  },
                ],
              },
            }
          : {}),
      },
      {
        id: 'outlier',
        name: dqTranslateMsg('Page_BoxPlotChart_' + 'Outlier'),
        type: 'scatter',
        data: boxPlotData.outliers,
        symbolSize: settings.scatterChart.symbols.size,
        itemStyle: {
          color: settings.scatterChart.symbols.color,
        },
        // datasetIndex: 2,
      },
    ],
  }
}
