import CCUtils from 'src/functions/CommonCalcUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'

export const toolTipBalloonCallback = (args, settings, configSettings, dist, optionBaseData) => {
  const isExponential = configSettings.commonSettings.isExponentialActive
  const decimalPlaces = DNUtils.getValueDecimalPlaces(settings.decimalPlaces, configSettings)

  let displayStr = ''

  displayStr =
    'Range : ' +
    '<br>' +
    ' - From : ' +
    // DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(optionBaseData.bin_width_start_list[args.dataIndex], decimalPlaces), isExponential) +
    DNUtils.calcValueDisplay(optionBaseData.bin_width_start_list[args.dataIndex], configSettings) +
    '<br>' +
    ' - To : ' +
    // DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(optionBaseData.bin_width_end_list[args.dataIndex], decimalPlaces), isExponential) +
    DNUtils.calcValueDisplay(optionBaseData.bin_width_end_list[args.dataIndex], configSettings) +
    '<br>' +
    'Count : ' +
    args.data +
    '<br>'

  if (settings.page.toolTipUseSpecLimits === true || settings.page.toolTipUseWarnLimits === true || settings.page.toolTipUseCustomLimits === true) {
    displayStr += '<br>' + 'Limits' + '<br>'

    if (settings.page.toolTipUseSpecLimits === true) {
      if (typeof settings.usl === 'number') {
        // displayStr += 'USL : ' + DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(settings.usl, decimalPlaces), isExponential) + '<br>'
        displayStr += 'USL : ' + DNUtils.axisValueDisplay(settings.usl, configSettings) + '<br>'
      }
      if (typeof settings.lsl === 'number') {
        // displayStr += 'LSL : ' + DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(settings.lsl, decimalPlaces), isExponential) + '<br>'
        displayStr += 'LSL : ' + DNUtils.axisValueDisplay(settings.lsl, configSettings) + '<br>'
      }
    }

    if (settings.page.toolTipUseWarnLimits === true) {
      if (typeof settings.uwl === 'number') {
        // displayStr += 'UWL : ' + DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(settings.uwl, decimalPlaces), isExponential) + '<br>'
        displayStr += 'UWL : ' + DNUtils.axisValueDisplay(settings.uwl, configSettings) + '<br>'
      }
      if (typeof settings.lwl === 'number') {
        // displayStr += 'LWL : ' + DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(settings.lwl, decimalPlaces), isExponential) + '<br>'
        displayStr += 'LWL : ' + DNUtils.axisValueDisplay(settings.lwl, configSettings) + '<br>'
      }
    }
    if (settings.page.toolTipUseCustomLimits === true) {
      if (typeof optionBaseData.x99_865 === 'number') {
        displayStr +=
          dist === 1
            ? `X+${configSettings.commonSettings.sigmaDegree}σ`
            : // : 'Xᵘᵖ³' + ' : ' + DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(optionBaseData.x99_865, decimalPlaces), isExponential) + '<br>'
              'Xᵘᵖ³' + ' : ' + DNUtils.calcValueDisplay(optionBaseData.x99_865, configSettings) + '<br>'
      }

      if (typeof optionBaseData.xBar === 'number') {
        // displayStr += `X\u0304` + ' : ' + DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(optionBaseData.xBar, decimalPlaces), isExponential) + '<br>'
        displayStr += `X\u0304` + ' : ' + DNUtils.calcValueDisplay(optionBaseData.xBar, configSettings) + '<br>'
      }

      if (dist !== 0) {
        if (typeof optionBaseData.x50 === 'number') {
          // displayStr += 'X⁵⁰' + ' : ' + DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(optionBaseData.x50, decimalPlaces), isExponential) + '<br>'
          displayStr += 'X⁵⁰' + ' : ' + DNUtils.calcValueDisplay(optionBaseData.x50, configSettings) + '<br>'
        }
      }

      if (typeof optionBaseData.x0_135 === 'number') {
        displayStr +=
          dist === 1
            ? `X-${configSettings.commonSettings.sigmaDegree}σ`
            : // : `Xˡᵒ³` + ' : ' + DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(optionBaseData.x0_135, decimalPlaces), isExponential) + '<br>'
              `Xˡᵒ³` + ' : ' + DNUtils.calcValueDisplay(optionBaseData.x0_135, configSettings) + '<br>'
      }
    }
  }

  return displayStr
}

export const calculateAverage = (data) => {
  if (data.length === 0) {
    return 0 // 빈 배열의 경우 0을 반환하거나 다른 적절한 기본값을 선택할 수 있습니다.
  }
  let sum = 0
  for (const value of data) {
    sum += value
  }
  return sum / data.length
}

// Calculate the median
export const calculateMedian = (data) => {
  const sortedData = data.slice().sort((a, b) => a - b)
  const mid = Math.floor(sortedData.length / 2)

  if (sortedData.length % 2 === 0) {
    return (sortedData[mid - 1] + sortedData[mid]) / 2
  } else {
    return sortedData[mid]
  }
}

// Calculate the standard deviation
export const calculateStandardDeviation = (data) => {
  const average = calculateAverage(data)
  const squaredDifferences = data.map((value) => Math.pow(value - average, 2))
  const sumSquaredDifferences = squaredDifferences.reduce((acc, value) => acc + value, 0)
  const variance = sumSquaredDifferences / data.length
  const standardDeviation = Math.sqrt(variance)

  return standardDeviation
}

// Calculate the mode
export const calculateMode = (data) => {
  const frequency = {}
  let maxFrequency = 0
  let mode = []

  data.forEach((value) => {
    frequency[value] = (frequency[value] || 0) + 1

    if (frequency[value] > maxFrequency) {
      maxFrequency = frequency[value]
      mode = [value]
    } else if (frequency[value] === maxFrequency) {
      mode.push(value)
    }
  })

  return mode
}

// Calculate the maximum value
export const calculateMaxValue = (data) => {
  return CCUtils.getMax(data)
}

// Calculate the minimum value
export const calculateMinValue = (data) => {
  return CCUtils.getMin(data)
}

export const getBinNumber = (valueArray) => {
  const valueCount = valueArray.length
  let binNumber = 0

  if (valueCount <= 30) binNumber = 4
  else if (valueCount <= 50) binNumber = 5
  else if (valueCount <= 100) binNumber = 6
  else if (valueCount <= 200) binNumber = 7
  else if (valueCount <= 500) binNumber = 8
  else if (valueCount <= 1000) binNumber = 9
  else if (valueCount <= 2000) binNumber = 10
  else if (valueCount <= 3000) binNumber = 11
  else if (valueCount <= 4000) binNumber = 12
  else if (valueCount <= 5000) binNumber = 13
  else binNumber = 14

  return binNumber
}

export const getBinRange = (min, max, binWidth) => {
  const binRangeArray = []
  for (let value = min; value <= max; value += binWidth) {
    binRangeArray.push(value)
  }

  return binRangeArray
}

export const makeXAxisRight = (settings, usl, gap, maxValue, maxBellCurveValue) => {
  const rightValue = getMax(usl, maxValue, maxBellCurveValue)
  return Number(rightValue + gap * 0.1)
}
export const makeXAxisLeft = (settings, lsl, gap, minValue, minBellCurveValue) => {
  const leftValue = getMin(lsl, minValue, minBellCurveValue)
  return Number(leftValue - gap * 0.1)
}

export const convertToMarkLine = (data, settings, configSettings) => {
  const position = 'end'
  const fontFamily = 'Arial'
  const markLines = [
    {
      show: settings.usl === null ? false : true,
      xAxis: settings.usl,
      name: 'USL',
      label: {
        position: position,
        formatter: function (args) {
          return args.name
        },
        fontFamily: fontFamily,
        fontSize: 10,
      },
      lineStyle: { color: settings.lineChart.markLines.specLimit?.color },
    },
    {
      show: settings.lsl === null ? false : true,
      xAxis: settings.lsl,
      name: 'LSL',
      label: {
        position: position,
        formatter: function (args) {
          return args.name
        },
        fontFamily: fontFamily,
        fontSize: 10,
      },
      lineStyle: { color: settings.lineChart.markLines.specLimit?.color },
    },
    {
      show: data.x99_865 === null ? false : true,
      xAxis: data.x99_865,
      name: data.dist === 1 ? `x+${configSettings.commonSettings.sigmaDegree}σ` : 'Xᵘᵖ³',
      label: {
        position: position,
        formatter: function (args) {
          return args.name
        },
        fontFamily: 'Arial',
        fontSize: 10,
      },
      lineStyle: { color: settings.lineChart.markLines.x99_865.color },
    },
    {
      show: data.x0_135 === null ? false : true,
      xAxis: data.x0_135,
      name: data.dist === 1 ? `x-${configSettings.commonSettings.sigmaDegree}σ` : `Xˡᵒ³`,
      label: {
        position: position,
        formatter: function (args) {
          return args.name
        },
        fontFamily: fontFamily,
        fontSize: 10,
      },
      lineStyle: { color: settings.lineChart.markLines.x0_135.color },
    },
    {
      show: data.xBar === null ? false : true,
      xAxis: data.xBar,
      name: 'X\u0304',
      label: {
        position: position,
        formatter: function (args) {
          return args.name
        },
        fontFamily: 'Arial',
        fontSize: 10,
      },
      lineStyle: { color: settings.lineChart.markLines.xBar.color },
    },
    ...(data.dist !== 1
      ? [
          {
            show: data.x50 === null ? false : true,
            xAxis: data.x50,
            name: 'X⁵⁰',
            label: {
              position: position,
              formatter: function (args) {
                return args.name
              },
              fontFamily: 'Arial',
              fontSize: 10,
            },
            lineStyle: { color: settings.lineChart.markLines.x50.color },
          },
        ]
      : [{}]),
  ]

  const validLines = markLines.filter((line) => {
    if (line.show === true) {
      const { show, ...rest } = line // Remove the 'show' property
      return rest
    }
  })
  return validLines
}

export const makeSymbol = (value, params, settings) => {
  // const val = params.data.v0001
  const val = params.value[0]
  if (settings.usl === null && settings.lsl === null) {
    return settings.lineChart.symbols.good.shape
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.shape
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.good.shape
    } else {
      return settings.lineChart.symbols.error.lower.shape
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.good.shape
    } else {
      return settings.lineChart.symbols.error.lower.shape
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.shape
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.shape
    } else {
      return settings.lineChart.symbols.good.shape
    }
  }
}

export const makeSymbolSize = (value, params, settings) => {
  // const val = params.data.v0001
  const val = params.value[0]

  if (settings.usl === null && settings.lsl === null) {
    return settings.lineChart.symbols.good.size
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.size
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.size
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.size
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.good.size
    } else {
      return settings.lineChart.symbols.error.lower.size
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.size
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.good.size
    } else {
      return settings.lineChart.symbols.error.lower.size
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.size
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.size
    } else {
      return settings.lineChart.symbols.good.size
    }
  }
}

export const makeSymbolColor = (params, settings) => {
  // const val = params.data.v0001
  const val = params.value[0]
  if (settings.usl === null && settings.lsl === null) {
    return settings.lineChart.symbols.good.color
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.color
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.color
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.color
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.good.color
    } else {
      return settings.lineChart.symbols.error.lower.color
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.color
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.good.color
    } else {
      return settings.lineChart.symbols.error.lower.color
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.color
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.color
    } else {
      return settings.lineChart.symbols.good.color
    }
  }
}

export const convertToChartLine = (target, settings) => {
  const rtnChartLine = {
    type: settings.lineChart.chartLines[target].type,
    width: settings.lineChart.chartLines[target].width,
    color: settings.lineChart.chartLines[target].color,
  }

  return rtnChartLine
}
