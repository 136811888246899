// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-fieldset-header {
  margin: 5px;
  text-align: left;
}

.dx-field {
  margin-bottom: 5px;
  margin-right: 10px;
  width: 100%;
  font-family: Arial;
}

.dx-field-label {
  text-align: center;
}

#side-panel-lr-chart-settings-form .dx-form-group-with-caption {
  padding-top: 5px;
  border-top: 1px solid #515159;
}

#side-panel-lr-chart-settings-form .dx-form-group-content {
  border-top: 0px solid #515159;
  padding: 0px;
  padding-left: 0px;
}

#side-panel-lr-chart-settings-form .dx-field-item {
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
}

#side-panel-lr-chart-settings-form .dx-field-button-item {
  padding-left: 2px;
  padding-right: 2px;
  width: "100%";
  height: "100%";
}

#side-panel-lr-chart-settings-form .dx-field-item-content {
  width: auto;
  flex-grow: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/chart-linear-regression/components/side-panel/SidePanelLinearRegressionChartSettings.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,6BAAA;AACF;;AAEA;EACE,6BAAA;EACA,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".dx-fieldset-header {\r\n  margin: 5px;\r\n  text-align: left;\r\n}\r\n\r\n.dx-field {\r\n  margin-bottom: 5px;\r\n  margin-right: 10px;\r\n  width: 100%;\r\n  font-family: Arial;\r\n}\r\n\r\n.dx-field-label {\r\n  text-align: center;\r\n}\r\n\r\n#side-panel-lr-chart-settings-form .dx-form-group-with-caption {\r\n  padding-top: 5px;\r\n  border-top: 1px solid #515159;\r\n}\r\n\r\n#side-panel-lr-chart-settings-form .dx-form-group-content {\r\n  border-top: 0px solid #515159;\r\n  padding: 0px;\r\n  padding-left: 0px;\r\n}\r\n\r\n#side-panel-lr-chart-settings-form .dx-field-item {\r\n  padding-left: 2px;\r\n  padding-right: 2px;\r\n  padding-top: 3px;\r\n  padding-bottom: 3px;\r\n}\r\n\r\n#side-panel-lr-chart-settings-form .dx-field-button-item {\r\n  padding-left: 2px;\r\n  padding-right: 2px;\r\n  width: '100%';\r\n  height: '100%';\r\n}\r\n\r\n#side-panel-lr-chart-settings-form .dx-field-item-content {\r\n  width: auto;\r\n  flex-grow: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
