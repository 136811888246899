// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav-inner-toolbar {
  width: 100%;
}

#navigation-header {
  background-color: #ff5722;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
#navigation-header .menu-button .dx-icon {
  color: #ffffff;
}
.screen-x-small #navigation-header {
  padding-left: 20px;
}
.dx-theme-generic #navigation-header {
  padding-top: 10px;
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/side-nav-inner-toolbar/side-nav-inner-toolbar.scss","webpack://./src/themes/generated/variables.additional.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EAEE,yBCNY;EDOZ,wEAAA;AAAF;AAEE;EACE,cCTc;ADSlB;AAGE;EACE,kBAAA;AADJ;AAIE;EACE,iBAAA;EACA,oBAAA;AAFJ","sourcesContent":[".side-nav-inner-toolbar {\r\n  width: 100%;\r\n}\r\n\r\n#navigation-header {\r\n  @import '../../themes/generated/variables.additional.scss';\r\n  background-color: $base-accent;\r\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);\r\n\r\n  .menu-button .dx-icon {\r\n    color: $base-text-color;\r\n  }\r\n\r\n  .screen-x-small & {\r\n    padding-left: 20px;\r\n  }\r\n\r\n  .dx-theme-generic & {\r\n    padding-top: 10px;\r\n    padding-bottom: 10px;\r\n  }\r\n}\r\n","$base-accent: #ff5722;\n$base-text-color: #ffffff;\n$base-bg: #363640;\n$base-border-color: #515159;\n$base-border-radius: 4px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
