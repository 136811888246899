import React from 'react'
import { Page, View, Image, Text } from '@react-pdf/renderer'

const ReportLayoutPage = ({ styles, pageSettings, configSettings, valuesArranged, chartDataUrl }) => {
  const { selectedTemplate, debugMode, reportMode } = pageSettings

  return (
    <>
      {valuesArranged.map((values, index) => {
        // const targetStyle = styles[selectedTemplate]
        // const targetStyle = styles[0]
        const targetStyle = styles[1]
        return (
          <React.Fragment key={`report-data-page-index-${index}`}>
            <Page size={targetStyle.page.size} style={targetStyle.page.style} orientation={targetStyle.page.orientation} debug={debugMode}>
              {/* <View style={targetStyle.horizontalContainer}>
                <View style={targetStyle.chartContainer}>{chartDataUrl && <Image style={targetStyle.chartImage} src={chartDataUrl} />}</View>
              </View>
              <View style={targetStyle.verticalContainer}>
                <View style={targetStyle.chartContainer}>{chartDataUrl && <Image style={targetStyle.chartImage} src={chartDataUrl} />}</View>
              </View> */}
              {targetStyle.elements.map((viewElement, viewIndex) => {
                return (
                  <View key={`view-element-${viewIndex}`} style={viewElement.style} debug={debugMode}>
                    {viewElement.columnContent.map((el, elIndex) => {
                      // return <View style={{ width: '100%', height: '100%' }}>{chartDataUrl && <Image style={targetStyle.chartImage} src={chartDataUrl} />}</View>
                      return <Text style={el.style}>{el.content}</Text>
                    })}
                  </View>
                )
              })}
            </Page>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default ReportLayoutPage
