import React, { Fragment } from 'react'
import { EmptyItem, GroupItem, SimpleItem } from 'devextreme-react/form'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import { format, parseISO } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import DateBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/DateBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

function formatDateBoxValue(dateString) {
  const year = parseInt(dateString.substring(0, 4), 10)
  const month = parseInt(dateString.substring(4, 6), 10) - 1 // Month is 0-indexed
  const day = parseInt(dateString.substring(6, 8), 10)
  const hour = parseInt(dateString.substring(8, 10), 10)
  const minute = parseInt(dateString.substring(10, 12), 10)
  const second = parseInt(dateString.substring(12, 14), 10)

  return new Date(year, month, day, hour, minute, second)
}

const dateRange = () => [
  { value: 10000, text: dqTranslateMsg('SidePanel_' + 'Last1Day') },
  { value: 10001, text: dqTranslateMsg('SidePanel_' + 'Last1Week') },
  { value: 10002, text: dqTranslateMsg('SidePanel_' + 'Last1Month') },
  { value: 10003, text: dqTranslateMsg('SidePanel_' + 'DayOffset') },
]

function DataRange(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  const sbPageSettings = pageState

  const onSelectBoxValueChanged = (e, dataField) => {
    const value = e.itemData.value
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onCheckBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value ? 1 : 0
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onDateBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const selectedDate = new Date(value)
    const hours = selectedDate.getHours()
    const minutes = selectedDate.getMinutes()
    const seconds = selectedDate.getSeconds()

    // Get the current date
    const currentDate = new Date()

    // Update current date with extracted time part
    currentDate.setHours(hours, minutes, seconds, 0) // Set milliseconds to 0

    const converted = format(currentDate, 'yyyyMMddHHmmss')
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = converted
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`data-range-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'DataRange')}>
      {/* <SimpleItem key={`data-range-group-item`} colSpan={2} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'DataRange')}> */}
      {SelectBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'DataRange'),
        labelLocation: 'top',
        name: 'dateRangeSetting',
        items: dateRange(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: dateRange().find((item) => item.value === sbPageSettings.page.dateRangeSetting).value,
        disabled: false,
        onItemClick: (e) => onSelectBoxValueChanged(e, 'dateRangeSetting'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'LastDays'),
        labelLocation: 'top',
        name: 'dateRangeDayOffset',
        min: 1,
        max: 100000,
        step: 1,
        value: sbPageSettings.page.dateRangeDayOffset,
        disabled: sbPageSettings.page.dateRangeSetting !== 10003,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'dateRangeDayOffset'),
      })}

      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'DayTimeEnable'),
        labelLocation: 'right',
        name: 'dateRangeIsDayTime',
        value: sbPageSettings.page.dateRangeIsDayTime === 1,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'dateRangeIsDayTime'),
      })}
      {DateBoxSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'DayTime'),
        colSpan: 2,
        labelLocation: 'top',
        name: 'dateRangeDayTime',
        pickerType: 'time',
        displayFormat: 'HH:mm',
        value: formatDateBoxValue(sbPageSettings.page.dateRangeDayTime),
        disabled: sbPageSettings.page.dateRangeIsDayTime !== 1,
        onValueChanged: (e) => onDateBoxValueChanged(e, 'dateRangeDayTime'),
      })}
    </GroupItem>
  )
}

export default DataRange
