import { Form, ScrollView } from 'devextreme-react'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import DQSaveReset from 'src/functions/DQSaveReset'
import DQToast from 'src/functions/DQToast'
import { valueChartSettingsPrm } from 'src/pages/chart-value/components/settings/ValueChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import './SidePanelReportPrintMultiFunctionSettings.scss'
import SettingsReportMode from './components/settings/SettingsReportMode'
import SettingsSelectTemplate from './components/settings/SettingsSelectTemplate'
import SettingsPageLayoutPadding from './components/settings/SettingsPageLayoutPadding'
import SettingsRectElementsList from './components/settings/SettingsRectElementsList'
import SettingsRectElementsTypeAndItemList from './components/settings/SettingsRectElementsTypeAndItemList'
import { reportPrintMultiFunctionSettingsPrm } from '../settings/ReportPrintMultiFunctionSettings'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'

function reportModeBtnDesc(currentReportMode) {
    let nextMode = ''
    if (currentReportMode === 'editor') {
        nextMode = getCommonMsg(msgType.SidePanel, 'User')
    } else {
        nextMode = getCommonMsg(msgType.SidePanel, 'Editor')
    }
    return getCommonMsg(msgType.SidePanel, 'SwitchReportMode') + ' > ' + nextMode
}

function handleSwitchReportMode(curPage, dispatch, pageKey, curMode) {
    const next = produce(curPage, (draft) => {
        draft.page.reportMode = curMode === 'editor' ? 'user' : 'editor'
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
}

export default function SidePanelReportPrintMultiFunctionSettings(props) {
    const { pageKey } = props
    const dispatch = useDispatch()
    const parentRef = useRef(null)
    const toastRef = useRef(null)

    const [scrollPosition, setScrollPosition] = useState(0)
    const { user } = useAuth()
    const common = useSelector((state) => state.common)
    const othersPage = common.pages.others
    const curPage = common.pages.reportPrintMultiFunction
    const configPage = common.pages.configuration
    const curReportMode = common.pages.reportMode

    // Event Callback
    const handleCurPageSettingsSave = (updatePageData) => {
        dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
    }

    const handleSaveButtonClicked = () => {
        const next = produce(curPage, (draft) => {
            Object.keys(curPage).forEach((key) => {
                draft[key] = curPage[key]
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }
    const handleResetButtonClicked = () => {
        const next = produce(curPage, (draft) => {
            Object.entries(reportPrintMultiFunctionSettingsPrm.page).forEach(([key, value]) => {
                draft.page[key] = value
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    const switchModeButton = (
        <Button
            style={{ width: '100%' }}
            text={reportModeBtnDesc(curReportMode)}
            onClick={() => handleSwitchReportMode(curPage, dispatch, pageKey, curReportMode)}
        />
    )
    // const items = [SettingsReportMode(pageKey), SettingsSelectTemplate(pageKey), SettingsPageLayoutPadding(), SettingsRectElementsList(), SettingsRectElementsTypeAndItemList()]
    const editorItems = [SettingsSelectTemplate(pageKey, curPage, configPage, othersPage)]
    const userItems = [SettingsSelectTemplate(pageKey, curPage, configPage, othersPage)]

    return (
        <div
            ref={parentRef}
            style={{ padding: '5px', width: '100%', height: '100%' }}
        >
            <DQToast ref={toastRef} />
            {/* Function Buttons */}
            <div style={{ width: '100%', height: '4%' }}>
                <DQSaveReset
                    saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
                    resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
                    saveCallback={handleSaveButtonClicked}
                    resetCallback={handleResetButtonClicked}
                />
            </div>
            <div style={{ width: '100%', height: '4%', padding: '5px 0px 5px 0px' }}>{switchModeButton}</div>
            <div style={{ width: '100%', height: '92%' }}>
                <ScrollView
                    className='side-panel-tab-container'
                    style={{ padding: 0 }}
                    showScrollbar={'onHover'}
                    useNative={false}
                >
                    <Form
                        id='side-panel-report-multi-function-settings-form'
                        colCount={1}
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        formData={curPage.page}
                        showColonAfterLabel={false}
                    >
                        {curReportMode === 'user' ? userItems : editorItems}
                    </Form>
                </ScrollView>
            </div>
        </div>
    )
}
