import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'

export default function DateTime(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'valueSummaryList'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onRadioGroupResultIconValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`date-time-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DateTimeDisplayFormat')}>
      {RadioGroupSimpleItem({
        ref: null,
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'DateTimeDisplayType'),
        labelLocation: 'top',
        name: 'dateTimeDisplayFormat',
        items: sd.dateTimeDisplayDefine(),
        layout: 'vertical',
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.dateTimeDisplayFormat,
        disabled: false,
        onValueChanged: (e) => onRadioGroupResultIconValueChanged(e, 'dateTimeDisplayFormat'),
      })}
    </GroupItem>
  )
}
