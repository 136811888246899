// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-panel-expand-icon {
  width: "200px";
  height: "200px";
  background-color: "blue";
  color: "white";
  font-size: "18px";
  text-align: "center";
  padding: "10px";
  position: "absolute";
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/functions/HeaderIcon.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,wBAAA;EACA,cAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,oBAAA;EACA,MAAA;EACA,OAAA;AACF","sourcesContent":[".side-panel-expand-icon {\r\n  width: '200px'; // Approximately 30 inches at 80 DPI (common screen DPI)\r\n  height: '200px'; // Approximately 30 inches at 80 DPI\r\n  background-color: 'blue';\r\n  color: 'white';\r\n  font-size: '18px'; // Set font size for text inside the button\r\n  text-align: 'center';\r\n  padding: '10px'; // Add padding for better aesthetics\r\n  position: 'absolute';\r\n  top: 0;\r\n  left: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
