class AxiosCommon {
  static handleAxiosError(error) {
    if (error.response) {
      console.error(
        'Server responded with non-2xx code:',
        error.response.status
      )
      throw new Error(error.message)
    } else if (error.request) {
      console.error('No response received:', error.request)
      throw new Error('No response received')
    } else {
      console.error('Error:', error.message)
      throw new Error(error.message)
    }
  }
}

export default AxiosCommon
