export const histogramChartSettingsPrm = {
  page: {
    layout: {
      column: 3,
      row: 2,
    },
    decimalPlaces: 3,
    sigmaDegree: 3,
    fontSize: 18,
    specificationLimitEnable: true,
    quantileLimitEnable: true,
    nominalLimitEnable: true,
    averageLimitEnable: true,
    plausibilityLimitEnable: true,
    scrapLimitEnable: true,
    acceptanceLimitEnable: true,
    specificationLimitLabelEnable: true,
    quantileLimitLabelEnable: true,
    nominalLimitLabelEnable: true,
    averageLimitLabelEnable: true,
    plausibilityLimitLabelEnable: true,
    scrapLimitLabelEnable: true,
    acceptanceLimitLabelEnable: true,
  },
  barChart: {
    error: {
      color: 'rgba(255, 0, 0, 1)',
    },
    warn: {
      color: 'rgba(255, 255, 0, 1)',
    },
    good: {
      color: 'rgba(0, 127, 207, 1)',
    },
    none: {
      color: 'rgba(207,207,196,1)',
    },
    histogramBar: {
      color: 'rgba(2, 152, 212, 1)',
    },
    bellCurve: {
      color: 'rgba(252, 0, 0, 1)',
    },
  },
  lineChart: {
    symbols: {
      error: {
        upper: {
          shape: 'triangle',
          size: 3,
          color: 'rgba(255, 0, 0, 1)',
        },
        lower: {
          shape: 'path://M 100 100 L 300 100 L 200 300 z',
          size: 3,
          color: 'rgba(255, 0, 0, 1)',
        },
      },
      warn: {
        upper: {
          shape: 'rect',
          size: 3,
          color: 'rgba(255, 255, 0, 1)',
        },
        lower: {
          shape: 'rect',
          size: 3,
          color: 'rgba(255, 255, 0, 1)',
        },
      },
      good: {
        shape: 'circle',
        size: 1,
        color: 'rgba(0, 255, 0, 1)',
      },
    },
    chartLines: {
      type: 'solid',
      width: 1,
      color: 'rgba(0, 255, 0, 1)',
    },
    markLines: {
      precision: 10,
      specLimit: {
        show: true,
        type: 'solid',
        color: 'rgba(255, 0, 0, 1)',
        width: 1,
      },
      x99_865: {
        show: true,
        name: 'x99_865',
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      x0_135: {
        show: true,
        name: 'x0_135',
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      xBar: {
        show: true,
        name: 'XBar',
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      x50: {
        show: true,
        name: 'X50',
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      plausibilityLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      scrapLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
      acceptanceLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 0, 255, 1)',
        width: 1,
      },
    },
    markAreas: {
      error: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 8, 8, 0)',
      },
      warn: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 255, 0, 0)',
      },
      good: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 255, 0, 0)',
      },
    },
  },
}
