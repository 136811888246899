import PCUtils from 'src/functions/ProcessCapabilityUtils'

export function makeRequestParameter(targetChar, targetValue, settings, configSettings, fdSettings) {
  // const useNat = configSettings.commonSettings.useNaturalBoundary
  const isUpperNat = PCUtils.getTypeUpperWarningLimit(targetChar) === 2
  const isLowerNat = PCUtils.getTypeLowerWarningLimit(targetChar) === 2

  // const defineUseDistribution = [
  //   { value: 0, type: 'Auto-Calculation' },
  //   { value: 1, type: 'Normal-distribution' },
  //   { value: 2, type: 'Log-normal-distribution' },
  //   { value: 21, type: 'Folded-normal-distribution' },
  //   { value: 30, type: 'Weibull-distribution' },
  // ]
  const defineUseDistribution = [
    { value: 0, type: 0 },
    { value: 1, type: 1 },
    { value: 2, type: 2 },
    { value: 21, type: 3 },
    { value: 30, type: 4 },
  ]

  // default Distribution
  const useDefaultDistribution = fdSettings.useDefaultDistribution === true ? true : false

  const makeCharacteristics = (targetChar) => {
    const usl = targetChar.c2111 // usl
    const lsl = targetChar.c2110 // lsl

    let result = 0
    if (usl === null && lsl === null) {
      result = 0
    } else if (usl === null || lsl === null) {
      result = 1
    } else {
      // usl !== null && lsl !== null
      if (isUpperNat || isLowerNat) {
        result = 1
      } else {
        result = 2
      }
    }

    return result
  }

  const findDefaultDistributionValue = (defaultDistribution) => {
    let result = 0
    const matchValue = defineUseDistribution.find((item) => item.type === defaultDistribution)

    if (matchValue) {
      result = matchValue.value
    } else {
      result = null
    }
    return result
  }

  // saved distribution (c2011)
  const useSavedDistribution = fdSettings.useSavedDistribution === true ? true : false

  const targetDistribution = defineUseDistribution.find((item) => item.value === targetChar.c2011)
  const savedDistributionType = targetDistribution === undefined ? null : targetDistribution.value

  // measure quantity (c2009)
  const useMeasuredQuantity = fdSettings.useMeasuredQuantity === true ? true : false

  const foundMQ = fdSettings.allowMeasuredQuantity.find((item) => item[0] === targetChar.c2009)
  const measuredQuantityType = foundMQ === undefined ? null : foundMQ[1]

  const characteristics = makeCharacteristics(targetChar)
  const oneSideDefaultDistribution = findDefaultDistributionValue(fdSettings.oneSideDefaultDistribution)
  const twoSideDefaultDistribution = findDefaultDistributionValue(fdSettings.twoSideDefaultDistribution)

  let usl = targetChar.c2111
  if (isUpperNat) {
    usl = null
  }
  let lsl = targetChar.c2110
  if (isLowerNat) {
    lsl = null
  }

  const srcData = targetValue.map((value) => value.v0001)

  const parameters = {
    savedDistribution: {
      use: useSavedDistribution,
      distribution: savedDistributionType,
    },
    measureQuantity: {
      use: useMeasuredQuantity,
      distribution: measuredQuantityType,
    },
    defaultDistribution: {
      use: useDefaultDistribution,
      oneSideDefaultDistribution: oneSideDefaultDistribution,
      twoSideDefaultDistribution: twoSideDefaultDistribution,
    },
    characteristics: characteristics,
    parameters: {
      usl: usl,
      lsl: lsl,
      yAxisType: settings.page.yAxisType === 'Theoretical-Value' ? 0 : 1,
    },
    srcData: srcData,
  }

  return parameters
}
