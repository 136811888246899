const useCustomStateStore = ({ stateKey = 'custom-grid-state', excludedFields = [], excludedOthers = [] }) => {
    const saveState = (state) => {
        const { columns, ...restState } = state
        const customOthers = { ...restState }
        excludedOthers.forEach((field) => {
            delete customOthers[field]
        })

        const customColumns = columns.map((column) => {
            const customColumn = { ...column }
            excludedFields.forEach((field) => {
                delete customColumn[field]
            })
            return customColumn
        })
        const customState = {
            ...customOthers,
            columns: customColumns,
        }
        localStorage.setItem(stateKey, JSON.stringify(customState))
    }

    const loadState = () => {
        const savedState = localStorage.getItem(stateKey)
        if (savedState) {
            const state = JSON.parse(savedState)
            return {
                ...state,
                columns: state.columns.map((column, index) => ({
                    ...column,
                    // Add your logic to restore the excluded fields if necessary
                })),
            }
        }
        return null
    }

    const clearState = () => {
        localStorage.removeItem(stateKey)
    }

    return { saveState, loadState, clearState }
}

export default useCustomStateStore
