import { SelectBox } from 'devextreme-react'
import Button from 'devextreme-react/button'
import { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import { convertUserRoleToString, userRoleLevel } from 'src/functions/constants/authLevelConstants'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './PushSettingsRibbon.scss'
import PushSettingsRibbonDataGrid from './PushSettingsRibbonDataGrid'
import PushSettingsRibbonPreview from './PushSettingsRibbonPreview'
import PushSettingsRibbonTreeList from './PushSettingsRibbonTreeList'
import DQToast from 'src/functions/DQToast'
import { useAuth } from 'src/contexts/auth'
import { defaultRibbon } from 'src/pages/admin/components/settings/AdminSettings'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import produce from 'immer'
import { getAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import { groupTbDescription as groupTbDesc } from 'src/functions/constants/groupDBFieldDescription'
import { useApi } from 'src/contexts/api'
import { makeAdminGroupToDTOFormat } from 'src/dto/DTOImpl'

export default function PushSettingsRibbon(props) {
    const { user } = useAuth()
    const { spring } = useApi()
    const { getTheme } = useTheme()
    const dispatch = useDispatch()
    const configPage = useSelector((state) => state.common.pages.configuration)
    const toastRef = useRef(null)

    const stateAll = props.state
    const state = props.state.ribbon
    const setState = props.setState

    const [savedItems, setSavedItems] = useState([])
    useEffect(() => {
        setSavedItems(state)
    }, [])

    const entireItems = Object.entries(ps).reduce((acc, [key, value], currentIndex) => {
        const iconType = configPage.menuIconType
        const theme = getTheme()
        const menuItem = {
            name: key,
            isDataNeed: value.showWithData,
            icon: value.getPageIcon(iconType, theme),
            parent: '',
            type: value.type,
        }

        acc.push(menuItem)

        return acc
    }, [])

    const handleSaveButton = () => {
        const next = produce(stateAll, (draft) => {
            draft.ribbon = savedItems
        })

        setState(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_AdminSettings_' + 'SavedSettings'))
    }

    const handleResetButton = async () => {
        const next = produce(stateAll, (draft) => {
            draft.ribbon = defaultRibbon.default
        })

        setState(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('Page_AdminSettings_' + 'ResetSettings'))
    }

    return (
        <div className='dx-theme-background-color'>
            <DQToast ref={toastRef} />
            <div className='push-settings-ribbon-control-container'>
                {/* <div style={{ width: '20%', height: '100%' }}>
                            <SelectBox
                                items={userGroupList}
                                value={userGroup}
                                onValueChanged={handleTargetUserGroup}
                                // valueExpr='value'
                                // displayExpr='text'
                                height={'100%'}
                            /> */}
                {/* </div> */}
                <div className='button-container'>
                    <Button
                        icon='save'
                        text={dqTranslateMsg('Page_AdminSettings_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                <div className='button-container'>
                    <Button
                        icon='undo'
                        text={dqTranslateMsg('Page_AdminSettings_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', width: '100%', height: '600px' }}>
                <div style={{ width: '35%', height: '100%', padding: '10px' }}>
                    <PushSettingsRibbonDataGrid
                        title={dqTranslateMsg('Page_AdminSettings_' + 'SelectionMenu')}
                        userRole={user.user_group}
                        savedItems={savedItems}
                        entireItems={entireItems}
                        setSavedItems={setSavedItems}
                    />
                </div>
                <div style={{ width: '35%', height: '100%', padding: '10px' }}>
                    <PushSettingsRibbonTreeList
                        title={dqTranslateMsg('Page_AdminSettings_' + 'MakeHierarchy')}
                        userRole={user.user_group}
                        savedItems={savedItems}
                        entireItems={entireItems}
                        setSavedItems={setSavedItems}
                    />
                </div>
                <div
                    style={{
                        width: '30%',
                        height: '96%',
                        padding: '10px',
                    }}
                >
                    <div style={{ width: '100%', height: '100%' }}>
                        <PushSettingsRibbonPreview
                            title={dqTranslateMsg('Page_AdminSettings_' + 'PreviewDataLoaded')}
                            dataLoaded={true}
                            userRole={user.user_group}
                            savedItems={savedItems}
                            entireItems={entireItems}
                            ribbon={savedItems}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
