export const userRoleLevel = {
  superuser: 0, // superuser
  admin: 1, // admin
  engineer: 2, // engineer
  operator: 3, // operator
  cmm: 4, // cmm
  qsense: 5, // Qsense
  /////////////////////////
  demo: 998, // demo
  /////////////////////////
}

export const convertUserRoleToString = (userRole) => {
  for (const [key, value] of Object.entries(userRoleLevel)) {
    if (value === userRole) {
      return key
    }
  }
  return 'Unknown Role'
}

export const convertUserRoleToNumber = (userRoleString) => {
  // userRoleLevel 객체에서 userRoleString에 해당하는 키의 값을 찾아 반환합니다.
  const userRoleValue = userRoleLevel[userRoleString.toLowerCase()]
  return userRoleValue !== undefined ? userRoleValue : -1 // 찾지 못했다면 기본값으로 -1을 반환합니다.
}
