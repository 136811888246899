// import { backendPath } from './defines/DBInfo'
import { makeRequestParameter } from 'src/pages/chart-histogram/components/main-view/HistogramChartFormImpl'
import { pythonBackendPath } from './defines/PythonDBInfo'
import JwtUtils from 'src/functions/JwtUtils'
const path = pythonBackendPath

export const getHistogram = async (targetChar, targetValue, settings, configSettings, fdConfig) => {
    // const prm = makeRequestParameter(targetChar, targetValue, settings, configSettings, fdConfig)
    const prm = makeRequestParameter(targetChar, targetValue, settings, configSettings, fdConfig)

    const requestBody = prm

    try {
        const accessToken = JwtUtils.loadToken()
        const response = await fetch(pythonBackendPath + 'py/getHistogram', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: JSON.stringify(requestBody),
        })

        const result = await response.json()
        return result
    } catch (error) {
        throw error
    }
}
