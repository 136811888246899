import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom'
import ApiProvider from 'src/contexts/api'
import { LanguageProvider } from 'src/contexts/languages'
import { store } from 'src/store'
import { AuthProvider } from 'src/contexts/auth'
import { ThemeProvider, ThemeContext } from 'src/contexts/theme'
import ScrollBoardValueChartManual from './ScrollBoardValueChartManual'
import 'src/dx-styles.scss'
import 'src/themes/generated/theme.additional.css'

const NewWindowContent = (props) => {
  return (
    <div className="dx-theme-background-color">
      <ScrollBoardValueChartManual {...props} />
    </div>
  )
}

export const openChartInNewWindow = (props) => {
  const chartWindow = window.open('', 'chartWindow', 'width=1200,height=800')

  if (chartWindow) {
    const rootDiv = chartWindow.document.createElement('div')
    rootDiv.id = 'chart-root'
    chartWindow.document.body.appendChild(rootDiv)
    chartWindow.document.body.style.margin = '0'

    // Set the title of the new window
    chartWindow.document.title = 'Value Chart'

    // Set the hash in the address bar
    chartWindow.location.hash = 'Value Chart'

    // Add necessary CSS files to the new window
    const cssFiles = [
      'https://cdn.jsdelivr.net/npm/devextreme@21.2.6/dist/css/dx.light.css',
      // Add other required CSS files here
    ]

    cssFiles.forEach((cssFile) => {
      const link = chartWindow.document.createElement('link')
      link.rel = 'stylesheet'
      link.href = cssFile
      chartWindow.document.head.appendChild(link)
    })

    // Copy all styles from the current document to the new window
    const styleSheets = Array.from(document.styleSheets)
    styleSheets.forEach((styleSheet) => {
      if (styleSheet.href) {
        const link = chartWindow.document.createElement('link')
        link.rel = 'stylesheet'
        link.href = styleSheet.href
        chartWindow.document.head.appendChild(link)
      } else if (styleSheet.cssRules) {
        const style = chartWindow.document.createElement('style')
        Array.from(styleSheet.cssRules).forEach((rule) => {
          style.appendChild(chartWindow.document.createTextNode(rule.cssText))
        })
        chartWindow.document.head.appendChild(style)
      }
    })

    // Render React component in the new window
    const renderContent = () => {
      ReactDOM.render(
        <Router>
          <Provider store={store}>
            <ThemeProvider>
              <LanguageProvider>
                <NewWindowContent {...props} />
              </LanguageProvider>
            </ThemeProvider>
          </Provider>
        </Router>,
        chartWindow.document.getElementById('chart-root')
      )
    }

    if (chartWindow.document.readyState === 'complete') {
      renderContent()
    } else {
      chartWindow.onload = renderContent
    }

    // Add event listener to clean up when the new window is closed
    const cleanup = () => {
      ReactDOM.unmountComponentAtNode(chartWindow.document.getElementById('chart-root'))
      chartWindow.close()
    }

    chartWindow.addEventListener('beforeunload', cleanup)

    // Listen for theme change messages
    window.addEventListener('message', (event) => {
      if (event.data.type === 'theme-change') {
        const newTheme = event.data.theme
        chartWindow.localStorage.setItem('theme', newTheme)
        // Apply the new theme in the new window
        chartWindow.document.documentElement.className = newTheme
        chartWindow.refreshTheme() // Add this line if you have a method to refresh the theme
      }
    })

    // Cleanup function to close the window and remove event listener when the component is unmounted
    return () => {
      chartWindow.removeEventListener('beforeunload', cleanup)
      cleanup()
    }
  }
}
