import React, { useEffect, useRef, useState } from 'react'
import { GroupItem } from 'devextreme-react/form'
import { CheckBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'
import produce from 'immer'
import { batch, useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

const priorities = {
  Unimportant: 0,
  Secondary: 1,
  Standard: 2,
  Important: 3,
  Critical: 4,
}

const CharacteristicsClass = (pageKey, pageState, configState) => {
  const dispatch = useDispatch()
  const sbPageSettings = pageState

  // const unimpCheckRef = useRef(null)
  // const secCheckRef = useRef(null)
  // const stdCheckRef = useRef(null)
  // const impCheckRef = useRef(null)
  // const critCheckRef = useRef(null)

  // const [unimpCheckState, setUnimpCheckState] = useState(false)
  // const [secCheckState, setSecCheckRef] = useState(false)
  // const [stdCheckState, setStdCheckRef] = useState(false)
  // const [impCheckState, setImpCheckRef] = useState(false)
  // const [critCheckState, setCritCheckRef] = useState(false)

  const splitted = sbPageSettings.page.orderChartClass.split('|')

  const unimpCheckState = splitted[priorities.Unimportant] === '1'
  const secCheckState = splitted[priorities.Secondary] === '1'
  const stdCheckState = splitted[priorities.Standard] === '1'
  const impCheckState = splitted[priorities.Important] === '1'
  const critCheckState = splitted[priorities.Critical] === '1'

  const handleValueChanged = () => {
    const unimp = unimpCheckState ? 1 : 0
    const sec = secCheckState ? 1 : 0
    const std = stdCheckState ? 1 : 0
    const imp = impCheckState ? 1 : 0
    const crit = critCheckState ? 1 : 0

    const chartClass = `${unimp}|${sec}|${std}|${imp}|${crit}`
    console.log(chartClass)

    const updatedPage = produce(sbPageSettings, (draft) => {
      draft.page.orderChartClass = chartClass
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(updatedPage))
  }

  // const renderCheckBox = (type, ref) => {
  //   const priorities = {
  //     Unimportant: 0,
  //     Secondary: 1,
  //     Standard: 2,
  //     Important: 3,
  //     Critical: 4,
  //   }

  //   const splitted = sbPageSettings.page.orderChartClass.split('|')
  //   const index = priorities[type]

  //   const value = index !== undefined && index < splitted.length && splitted[index] === '1'

  //   return CheckBoxSimpleItem({
  //     ref: ref,
  //     colSpan: 1,
  //     label: dqTranslateMsg('SidePanel_' + type),
  //     labelLocation: 'right',
  //     name: type,
  //     value: value,
  //     onValueChanged: () => {
  //       const unimp = unimpCheckRef.current.instance.option('value') ? 1 : 0
  //       const sec = secCheckRef.current.instance.option('value') ? 1 : 0
  //       const std = stdCheckRef.current.instance.option('value') ? 1 : 0
  //       const imp = impCheckRef.current.instance.option('value') ? 1 : 0
  //       const crit = critCheckRef.current.instance.option('value') ? 1 : 0

  //       const chartClass = `${unimp}|${sec}|${std}|${imp}|${crit}`
  //       console.log(chartClass)

  //       const updatedPage = produce(sbPageSettings, (draft) => {
  //         draft.page.orderChartClass = chartClass
  //       })

  //       dispatch(ps[pageKey].setPageSettingsToRedux(updatedPage))
  //     },
  //   })
  // }

  return (
    <GroupItem key={`characteristics-class-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'CharacteristicClass')}>
      {/* {renderCheckBox('Unimportant', unimpCheckRef)}
      {renderCheckBox('Secondary', secCheckRef)}
      {renderCheckBox('Standard', stdCheckRef)}
      {renderCheckBox('Important', impCheckRef)}
      {renderCheckBox('Critical', critCheckRef)} */}
      {CheckBoxSimpleItem({
        // ref: ref,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Unimportant'),
        labelLocation: 'right',
        name: 'Unimportant',
        value: unimpCheckState,
        onValueChanged: handleValueChanged,
      })}
      {CheckBoxSimpleItem({
        // ref: ref,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Secondary'),
        labelLocation: 'right',
        name: 'Secondary',
        value: secCheckState,
        onValueChanged: handleValueChanged,
      })}
      {CheckBoxSimpleItem({
        // ref: ref,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Standard'),
        labelLocation: 'right',
        name: 'Standard',
        value: stdCheckState,
        onValueChanged: handleValueChanged,
      })}
      {CheckBoxSimpleItem({
        // ref: ref,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Important'),
        labelLocation: 'right',
        name: 'Important',
        value: impCheckState,
        onValueChanged: handleValueChanged,
      })}
      {CheckBoxSimpleItem({
        // ref: ref,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Critical'),
        labelLocation: 'right',
        name: 'Critical',
        value: critCheckState,
        onValueChanged: handleValueChanged,
      })}
    </GroupItem>
  )
}

export default CharacteristicsClass
