import React from 'react'
import { Button } from 'devextreme-react'
import { SimpleItem, Label } from 'devextreme-react/form'

const ButtonSimpleItem = ({ colSpan, type = 'normal', text, icon, disabled, onClick }) => {
  return (
    <SimpleItem colSpan={colSpan}>
      <Button text={text} icon={icon} type={type} stylingMode={'contained'} onClick={onClick} width="100%" height="100%" disabled={disabled} />
    </SimpleItem>
  )
}

export default ButtonSimpleItem
