import defaultUser from '../utils/default-user'
import { backendPath } from './defines/DBInfo'
import JwtUtils from 'src/functions/JwtUtils'
import ApiStatus from 'src/api/defines/ApiStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'

const path = backendPath
export async function getUserInfo(id, password, accessToken) {
  try {
    // Send request
    console.log(`signIn request ID:${id}, Password:${password}`)
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'user/getUserInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify({
        user_id: id,
      }),
    })

    const result = await response.json()

    if (!ApiStatus.isSuccess(result.status)) {
      alert(getErrCommMsg(result.status))
    }

    let isOk = false
    let userData = {}

    // check Password
    if (password === result.data.user_info.user_password) {
      console.log('signIn success')
      isOk = true
      userData = result.data.user_info
    } else {
      console.log('signIn fail')
      console.log('password is not matched')
      throw new Error('password is not matched')
    }

    return {
      isOk: isOk,
      data: userData,
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    }
  }
}

export async function getUser() {
  try {
    return {
      isOk: true,
      data: defaultUser,
      // data:
    }
  } catch {
    return {
      isOk: false,
    }
  }
}

export async function getUserList(apiClient) {
  try {
    const response = await apiClient.post('user/getUserListInfo', null) // Adjust the endpoint as needed
    if (!ApiStatus.isSuccess(response.status)) {
      throw new Error(getErrCommMsg(response.status))
    }

    return response.data
  } catch (error) {
    throw error
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password)

    return {
      isOk: true,
    }
  } catch (error) {
    throw error
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode)

    return {
      isOk: true,
    }
  } catch (error) {
    throw error
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email)

    return {
      isOk: true,
    }
  } catch {
    return {
      isOk: false,
      message: 'Failed to reset password',
    }
  }
}
