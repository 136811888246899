import React from 'react'
import { GroupItem } from 'devextreme-react/form'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'

const barChartContentOrder = () => [
  { value: 'none', text: dqTranslateMsg('SidePanel_' + 'None') },
  { value: 'asc', text: dqTranslateMsg('SidePanel_' + 'Ascending') },
  { value: 'desc', text: dqTranslateMsg('SidePanel_' + 'Descending') },
]

const BarChartOption = (pageKey, pageState, configState) => {
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  const sbPageSettings = pageState

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onContentOrderValueChanged = (e, dataField) => {
    const value = e.itemData.value
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`bar-chart-option-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'BarChartOption')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'LimitCount'),
        labelLocation: 'top',
        name: 'barChartLimitCount',
        min: 1,
        max: 30,
        step: 1,
        value: sbPageSettings.page.barChartLimitCount,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'barChartLimitCount'),
      })}
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'ContentOrder'),
        labelLocation: 'top',
        name: 'barContentOrder',
        items: barChartContentOrder(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: barChartContentOrder().find((item) => item.value === sbPageSettings.page.barContentOrder).value,
        onItemClick: (e) => onContentOrderValueChanged(e, 'barContentOrder'),
      })}
    </GroupItem>
  )
}

export default BarChartOption
