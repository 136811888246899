import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'

export const makeSeriesGoodShape = (value, params, settings) => {
  return settings.lineChart.symbols.good.shape
}

export const makeSeriesErrorShape = (value, params, settings) => {
  return settings.lineChart.symbols.error.upper.shape
}

export const makeSeriesGoodSize = (value, params, settings) => {
  return settings.lineChart.symbols.good.size
}

export const makeSeriesErrorSize = (value, params, settings) => {
  return settings.lineChart.symbols.error.upper.size
}

export const makeSeriesGoodColor = (params, settings) => {
  return settings.lineChart.symbols.good.color
}

export const makeSeriesErrorColor = (params, settings) => {
  return settings.lineChart.symbols.error.upper.color
}

const calculateYAxisSpace = (settings, usl, lsl, uwl, lwl, maxValue, minValue) => {
  const t_usl = usl ?? maxValue
  const t_lsl = lsl ?? minValue

  const t_uwl = uwl ?? maxValue
  const t_lwl = lwl ?? minValue

  const max = Math.max(t_usl, t_uwl, maxValue)
  const min = Math.min(t_lsl, t_lwl, minValue)

  let space = Math.abs(max - min)
  if (space === 0) space = 1

  const additionalSpace = space * 0.1

  return { max, min, additionalSpace }
}

export const makeXAxisTop = (settings, usl, lsl, uwl, lwl, maxValue, minValue) => {
  const { max, additionalSpace } = calculateYAxisSpace(settings, usl, lsl, uwl, lwl, maxValue, minValue)
  return max + additionalSpace
}

export const makeXAxisBottom = (settings, usl, lsl, uwl, lwl, maxValue, minValue) => {
  const { min, additionalSpace } = calculateYAxisSpace(settings, usl, lsl, uwl, lwl, maxValue, minValue)
  return min - additionalSpace
}

export const makeYAxisTop = (settings, usl, lsl, uwl, lwl, maxValue, minValue) => {
  const { max, additionalSpace } = calculateYAxisSpace(settings, usl, lsl, uwl, lwl, maxValue, minValue)
  return max + additionalSpace
}

export const makeYAxisBottom = (settings, usl, lsl, uwl, lwl, maxValue, minValue) => {
  const { min, additionalSpace } = calculateYAxisSpace(settings, usl, lsl, uwl, lwl, maxValue, minValue)
  return min - additionalSpace
}

export const convertToMarkLine = (settings, configSettings) => {
  const position = 'insideEndTop'
  const markLines = [
    ...[
      settings.x_lsl
        ? {
            show: true,
            xAxis: settings.x_lsl,
            name: 'X_LSL',
            label: {
              show: settings.lineChart.markLines.specLimit?.show,
              position: position,
              formatter: '{b}',
              fontFamily: 'Arial',
              fontSize: ((settings.page.fontSize - 2) / (settings.page.layout.row + settings.page.layout.column)) * 2,
            },
            lineStyle: {
              type: settings.lineChart.markLines.specLimit?.type,
              color: settings.lineChart.markLines.specLimit?.color,
              width: settings.lineChart.markLines.specLimit?.width,
            },
          }
        : null,
    ],
    ...[
      settings.x_usl
        ? {
            show: true,
            xAxis: settings.x_usl,
            name: 'X_USL',
            label: {
              show: settings.lineChart.markLines.specLimit?.show,
              position: position,
              formatter: '{b}',
              fontFamily: 'Arial',
              fontSize: ((settings.page.fontSize - 2) / (settings.page.layout.row + settings.page.layout.column)) * 2,
            },
            lineStyle: {
              type: settings.lineChart.markLines.specLimit?.type,
              color: settings.lineChart.markLines.specLimit?.color,
              width: settings.lineChart.markLines.specLimit?.width,
            },
          }
        : null,
    ],
    ...[
      settings.y_lsl
        ? {
            show: true,
            yAxis: settings.y_lsl,
            name: 'Y_LSL',
            label: {
              show: settings.lineChart.markLines.specLimit?.show,
              position: position,
              formatter: '{b}',
              fontFamily: 'Arial',
              fontSize: ((settings.page.fontSize - 2) / (settings.page.layout.row + settings.page.layout.column)) * 2,
            },
            lineStyle: {
              type: settings.lineChart.markLines.specLimit?.type,
              color: settings.lineChart.markLines.specLimit?.color,
              width: settings.lineChart.markLines.specLimit?.width,
            },
          }
        : null,
    ],
    ...[
      settings.y_usl
        ? {
            show: true,
            yAxis: settings.y_usl,
            name: 'Y_USL',
            label: {
              show: settings.lineChart.markLines.specLimit?.show,
              position: position,
              formatter: '{b}',
              fontFamily: 'Arial',
              fontSize: ((settings.page.fontSize - 2) / (settings.page.layout.row + settings.page.layout.column)) * 2,
            },
            lineStyle: {
              type: settings.lineChart.markLines.specLimit?.type,
              color: settings.lineChart.markLines.specLimit?.color,
              width: settings.lineChart.markLines.specLimit?.width,
            },
          }
        : null,
    ],
  ]

  const validLines = markLines.filter((line) => line !== null)
  return validLines
}

export function makeSeriesTooltip() {
  return {
    position: 'right',
    formatter: (params) => {
      const seriesName = params.seriesName
      const value = params.value

      return seriesName + '<br>' + 'X Axis : ' + value[0] + '<br>' + 'Y Axis : ' + value[1]
    },
  }
}
