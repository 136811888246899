import ReactECharts from 'echarts-for-react'
import produce from 'immer'
import { useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import ApiStatus from 'src/api/defines/ApiStatus'
import { getSummary } from 'src/api/summary-list'
import { useTheme } from 'src/contexts/theme'
import CPUtils from 'src/functions/CommonPageUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as csImpl from './chart-option/CapabilitySummaryChartOption'
import * as csImpl2 from './chart-option/CapabilitySummaryChartOptionImpl'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { useLanguage } from 'src/contexts/languages'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

function CapabilitySummaryChartForm({ pageKey, common, chartRawData }) {
  const { getTheme } = useTheme()
  const { language } = useLanguage()
  // For initialRendering
  const isInitialRender = useRef(true)

  const { parts, chars, values } = useMemo(() => CPUtils.loadRefChartData(chartRawData), [chartRawData])
  const { charArray, curPage, configPage, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)
  const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
  const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)

  let option
  if (valuesArranged.length > 0) {
    option = csImpl.getCapabilitySummaryChartOption(curPage, configPage, charArray, valuesArranged)
  }

  const capabilityChartRefs = useRef(null)

  // set layout
  const widthPerChart = `${100}%`
  const heightPerChart = `${100}%`

  useEffect(() => {
    const processSummaryData = async (valueArray, targetChars, settings, configSettings) => {
      let tableDataArray = []

      try {
        await Promise.all(
          valueArray.map(async (value, index) => {
            // const targetChar = targetChars.find((charObj) => value.part_id === charObj.part_id && valueObj.char_id === charObj.char_id)
            // const targetPart = parts.find((partObj) => value.part_id === partObj.part_id)
            const targetPart = value.partRaw
            const targetChar = value.charRaw
            const targetValue = value.valueRaw

            const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
            const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
            const filteredValue = outlierFilterValues(limits, targetChar, targetValue, configSettings)

            if (filteredValue.length < 2) {
              const tagCpCpkArray = {
                charName: targetChar.c2002,
                cp: null,
                cpk: null,
                part_id: value.part_id,
                char_id: value.char_id,
              }
              tableDataArray.push(tableRow)
              return
            }

            const result = await getSummary(targetChar, filteredValue, settings, configSettings)

            if (!ApiStatus.isSuccess(result.status)) {
              alert(getErrCommMsg(result.status))
            }

            // tableDataArray.push(tableRow(targetPart, targetChar, value, index, summaryResult, settings))
            const cpCpkArray = csImpl2.makeCapabilityIndexDataOne(targetChar, value, result.data)
            const tagCpCpkArray = {
              ...cpCpkArray,
              part_id: value.part_id,
              char_id: value.char_id,
            }
            tableDataArray.push(tagCpCpkArray)
            // tableDataArray.push(tableRow(targetPart, targetChar, value, index, summaryResult, settings))
          })
        )
      } catch (error) {
        console.error('Error processing summary data:', error)
      }

      return tableDataArray
    }

    processSummaryData(valuesArranged, chars, curPage, configPage)
      .then((result) => {
        console.log('Table data array:', result)
        if (capabilityChartRefs.current) {
          const displayType = curPage.page.displayType
          result.sort((a, b) => {
            if (a.part_id === b.part_id) {
              return a.char_id - b.char_id // part_id가 같을 때 char_id로 정렬
            }
            return a.part_id - b.part_id // part_id로 먼저 정렬
          })
          const charNameArray = result.map((item) => item.charName)
          const cpArray = result.map((item) => {
            // return item.cp !== null && item.cp !== undefined ? DNUtils.calcValueDisplay(item.cp, configPage) : null
            return item.cp !== null && item.cp !== undefined ? item.cp : null
          })
          const cpkArray = result.map((item) => {
            // return item.cpk !== null && item.cpk !== undefined ? DNUtils.calcValueDisplay(item.cpk, configPage) : null
            return item.cpk !== null && item.cpk !== undefined ? item.cpk : null
          })

          const seriesArray = csImpl2.makeSeriesData(curPage, displayType, cpArray, cpkArray)

          const capOption = capabilityChartRefs.current.getEchartsInstance().getOption()
          const next = produce(capOption, (draft) => {
            draft.xAxis[0].data = charNameArray
            draft.series = seriesArray
          })
          capabilityChartRefs.current.getEchartsInstance().setOption(next)
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        // Handle errors as needed
      })
  }, [common])

  useEChartsResize(capabilityChartRefs)

  return (
    <div className={'dx-theme-background-color'}>
      {valuesArranged.length > 0 && option !== null && option !== undefined ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: '10px',
            borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          <ReactECharts
            ref={capabilityChartRefs}
            style={{ height: heightPerChart, width: widthPerChart }} // Add space after subtraction operator
            notMerge={true}
            option={option}
            theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
            opts={{
              renderer: 'svg',
              locale: ETCUtils.checkLangForECharts(language),
            }}
          />
        </div>
      ) : (
        <>
          <img src={'/images/color/warning-exclamation.svg'} width="30" height="30" />
          <div style={{ fontSize: '20px' }}>{dqTranslateMsg('Page_CapabilitySummaryChart_' + 'DataIsNotSelected')}</div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(CapabilitySummaryChartForm)
