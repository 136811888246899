const STATUS_CODE = {
  // Common
  SUCCESS: 0,
  FAIL: 1,
  SKIP: 2,

  // Data Process
  PROC_FAIL: 1001,
  NOT_EXIST_VALUE: 1002,
  DEV_ZERO: 1003,

  // Authentication and Authorization
  EXPIRED_TOKEN: 2001,
  INV_LOGIN_PASSWORD: 2002,
  NOT_EXIST_LOGIN_ID: 2003,
  INV_SIGNATURE_KEY: 2004,

  // System Status
  SERV_TIMEOUT: 3001,
  FUNC_NOT_AVAILABLE: 3002,
  RES_OVERHEAD: 3003,

  // Network Status
  NET_NO_CONNECTION: 4001,
  NET_PROTOCOL_ERROR: 4002,
  NET_TIMEOUT: 4003,

  // Parameter Status
  PRM_INVALID_TYPE: 5001,
  PRM_LACK_OF_PARAMETER: 5001,
  PRM_INVALID_RANGE: 5001,

  // DATABASE Status
  DB_NOT_ACCESSIBLE: 6001,
  DB_NO_DATA: 6002,
  DB_CONN_TIMEOUT: 6003,
  DB_DRV_ERROR: 6004,
  DB_FAIL_QUERY: 6005,
  DB_NO_CHAR_DATA: 6006,
  DB_NO_PART_DATA: 6007,
  DB_NO_VALUE_DATA: 6008,
  DB_NO_PART_FILTER: 6009,
  DB_NO_CHAR_FILTER: 6010,
  DB_CATALOG_DATA_ERROR: 6011,
  LOCAL_DB_NO_CONNECTION: 6501,
  LOCAL_DB_NO_SETTINGS_INFO: 6502,
  LOCAL_DB_NO_USER_ID: 6503,
  LOCAL_DB_NO_CHAR_DATA: 6504,
  LOCAL_DB_NO_PART_DATA: 6505,

  API_REQ_TYPE_ERROR: 7001,
  API_NOT_USE_FUNCTION: 7002,
  PY_NO_R_SQUARE: 8001,
  ETC_ERROR: 9999,
}

const STATUS_MSG = {
  SUCCESS: 'Success',
  FAIL: 'Fail',
  SKIP: 'Skip',

  // Data Process
  PROC_FAIL: 'Data - Process fail.',
  NOT_EXIST_VALUE: 'Data - Not exist value.',
  DEV_ZERO: 'Data - Deviation zero.',

  // Authentication and Authorization
  EXPIRED_TOKEN: 'Auth - Expired token.',
  INV_LOGIN_PASSWORD: 'Auth - Invalid login password.',
  NOT_EXIST_LOGIN_ID: 'Auth – Not exist login id.',
  INV_SIGNATURE_KEY: 'Auth – Invalid Signature key.',

  // System Status
  SERV_TIMEOUT: 'System – Service timeout.',
  FUNC_NOT_AVAILABLE: 'System – Function not available.',
  RES_OVERHEAD: 'System – Resource overload.',
}

const STATUS = [
  // Common
  { status: STATUS_CODE.SUCCESS, result: true, msg: STATUS_MSG.SUCCESS },
  { status: STATUS_CODE.FAIL, result: false, msg: STATUS_MSG.FAIL },
  { status: STATUS_CODE.SKIP, result: false, msg: STATUS_MSG.SKIP },
  // Data Process
  { status: STATUS_CODE.PROC_FAIL, result: false, msg: STATUS_MSG.PROC_FAIL },
  {
    status: STATUS_CODE.NOT_EXIST_VALUE,
    result: false,
    msg: STATUS_MSG.NOT_EXIST_VALUE,
  },
  { status: STATUS_CODE.DEV_ZERO, result: false, msg: STATUS_MSG.DEV_ZERO },
  // Authentication and Authorization
  {
    status: STATUS_CODE.EXPIRED_TOKEN,
    result: false,
    msg: STATUS_MSG.EXPIRED_TOKEN,
  },
  {
    status: STATUS_CODE.INV_LOGIN_PASSWORD,
    result: false,
    msg: STATUS_MSG.INV_LOGIN_PASSWORD,
  },
  {
    status: STATUS_CODE.NOT_EXIST_LOGIN_ID,
    result: false,
    msg: STATUS_MSG.NOT_EXIST_LOGIN_ID,
  },
  {
    status: STATUS_CODE.INV_SIGNATURE_KEY,
    result: false,
    msg: STATUS_MSG.INV_SIGNATURE_KEY,
  },
  // System Status
  {
    status: STATUS_CODE.SERV_TIMEOUT,
    result: false,
    msg: STATUS_MSG.SERV_TIMEOUT,
  },
  {
    status: STATUS_CODE.FUNC_NOT_AVAILABLE,
    result: false,
    msg: STATUS_MSG.FUNC_NOT_AVAILABLE,
  },
  {
    status: STATUS_CODE.RES_OVERHEAD,
    result: false,
    msg: STATUS_MSG.RES_OVERHEAD,
  },
]

class ApiStatus {
  static isSuccess(code) {
    const targetStatus = STATUS.find((piece) => piece.status === code)
    if (targetStatus) {
      return targetStatus.result
    }

    return false
  }

  static getStatusMsg(code) {
    const targetStatus = STATUS.find((piece) => piece.status === code)
    if (targetStatus) {
      return targetStatus.msg
    }

    return null
  }
}

export default ApiStatus
