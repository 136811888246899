export const boxPlotChartSettingsPrm = {
  page: {
    normalizationType: true, // min-max, max-abs, robust, standard
    decimalPlaces: 3,
  },
  boxPlotChart: {
    borderColor: 'rgba(0, 0, 0, 1)',
    innerColor: 'rgba(2, 152, 212, 1)',
  },
  scatterChart: {
    symbols: {
      shape: 'circle',
      size: 5,
      color: 'rgba(238, 28, 55, 1)',
    },
    chartLines: {
      type: 'solid',
      width: 2,
      color: 'rgba(0, 255, 0, 1)',
    },
  },
}
