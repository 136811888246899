import { Form, ScrollView, Switch } from 'devextreme-react'
import { Button } from 'devextreme-react/button'
import { Column, DataGrid, Editing, Item, Lookup, Scrolling, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid'
import RadioGroup from 'devextreme-react/radio-group'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useAuth } from 'src/contexts/auth'
import { defMeasuredQuantity } from 'src/default/find-distribution/find-distribution-define'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { setConfiguration } from 'src/store/common'
import { configurationSettingsPrm, defaultFindDistribution } from 'src/pages/config/components/settings/ConfigSettings'
import './PushSettingsFindDistribution.scss'
import { useLanguage } from 'src/contexts/languages'
import { GroupItem, SimpleItem } from 'devextreme-react/form'
import DQToast from 'src/functions/DQToast'

export default function PushSettingsFindDistribution(props) {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const { language } = useLanguage()
    const [loading, setLoading] = useState(false)

    const toastRef = useRef(null)
    const usingMQGridRef = useRef(null)
    const reservedMQGridRef = useRef(null)

    const stateAll = props.state
    const state = props.state.findDistribution
    const setState = props.setState

    // const configPage = useSelector((state) => state.common.pages.configuration)
    // const configAll = common.base
    // const settings = configPage

    const [tempState, setTempState] = useState(state)

    const [allowedMQ, setAllowedMQ] = useState([])
    const [reservedMQ, setReservedMQ] = useState([])

    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        const allowedMQ = defMeasuredQuantity()
            .map((item1) => {
                const matchingItem = tempState.allowMeasuredQuantity.find((item2) => item2[0] === item1.value)

                if (matchingItem) {
                    return { ...item1, DistributionForMQ: matchingItem[1] }
                } else {
                    return null
                }
            })
            .filter((item) => item !== null)
        const reservedMQ = defMeasuredQuantity()
            .map((item) => (tempState.allowMeasuredQuantity.includes(item.value) === false ? item : null))
            .filter((item) => item !== null)

        setAllowedMQ(allowedMQ)
        setReservedMQ(reservedMQ)
    }, [])

    const handleSaveButton = () => {
        const next = produce(stateAll, (draft) => {
            draft.findDistribution = tempState
        })

        setState(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_Configuration_' + 'SavedSettings'))
    }

    const handleResetButton = () => {
        const next = produce(stateAll, (draft) => {
            draft.findDistribution = defaultFindDistribution
        })
        setState(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('Page_Configuration_' + 'ResetSettings'))
    }

    const handleSavedDistributionChange = (target) => {
        if (target.event === undefined) return

        const next = produce(tempState, (draft) => {
            draft.useSavedDistribution = target.value
        })
        setTempState(next)
    }
    const handleMeasuredQuantityChange = (target) => {
        if (target.event === undefined) return

        const next = produce(tempState, (draft) => {
            draft.useMeasuredQuantity = target.value
        })
        setTempState(next)
    }
    const handleDefaultDistributionChange = (target) => {
        if (target.event === undefined) return

        const next = produce(tempState, (draft) => {
            draft.useDefaultDistribution = target.value
        })
        setTempState(next)
    }

    const handleOneSideDefaultDistributionChange = (target) => {
        const next = produce(tempState, (draft) => {
            draft.oneSideDefaultDistribution = target.value
        })

        setTempState(next)
    }
    const handleTwoSideDefaultDistributionChange = (target) => {
        const next = produce(tempState, (draft) => {
            draft.twoSideDefaultDistribution = target.value
        })

        setTempState(next)
    }
    const handleAddMeasureQuantity = () => {
        const selRowData = reservedMQGridRef.current.instance.getSelectedRowsData()

        const next = produce(tempState, (draft) => {
            selRowData.forEach((item2) => {
                if (!draft.findDistribution.allowMeasuredQuantity.some((item1) => item1[0] === item2.value)) {
                    draft.findDistribution.allowMeasuredQuantity.push([item2.value, sd.defaultDistributionSideTypeDetailsDefine()[0].value])
                }
            })
        })

        setTempState(next)
    }

    const handleRemoveMeasureQuantity = () => {
        const selRowData = usingMQGridRef.current.instance.getSelectedRowsData()

        const next = produce(tempState, (draft) => {
            selRowData.forEach((item2) => {
                const index = draft.allowMeasuredQuantity.findIndex((item1) => item1[0] === item2.value)
                if (index !== -1) {
                    draft.allowMeasuredQuantity.splice(index, 1)
                }
            })
        })
        setTempState(next)
    }

    const onRowUpdating = (e) => {
        let is_cancel = true
        const targetMQ = tempState.allowMeasuredQuantity.find((item) => item[0] === e.oldData.value)
        let updatedTargetMQ
        if (targetMQ) {
            // 새로운 배열을 생성하고 변경된 값을 설정
            updatedTargetMQ = [targetMQ[0], e.newData.DistributionForMQ]
            const next = produce(tempState, (draft) => {
                const index = draft.allowMeasuredQuantity.findIndex((item) => item[0] === e.oldData.value)
                if (index !== -1) {
                    draft.allowMeasuredQuantity.splice(index, 1)
                }
                draft.allowMeasuredQuantity.push(updatedTargetMQ)
            })

            setTempState(next)
            is_cancel = false
        }

        e.cancel = is_cancel
    }

    const handleScroll = (e) => {
        if (e.event.hasOwnProperty('isScrollingEvent')) {
            // [BUG FIX]
            e.component.scrollTo({ top: scrollPosition })
            return
        }
        setScrollPosition(e.scrollOffset.top)
    }

    return (
        <div className='push-settings-find-distribution-container'>
            <DQLoadPanel open={loading} />
            <DQToast ref={toastRef} />
            <div className='push-settings-find-distribution-control-container'>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='save'
                        text={dqTranslateMsg('Page_Configuration_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            <div className='push-settings-find-distribution-content-container'>
                <div className='push-settings-find-distribution-base-settings-content'>
                    <div className='push-settings-find-distribution-grid-container'>
                        <DataGrid
                            className='push-settings-find-distribution-datagrid-content-reserved'
                            ref={reservedMQGridRef}
                            id='reservedMQGrid'
                            keyExpr='value'
                            dataSource={reservedMQ}
                            columnResizingMode='widget'
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            // selectedRowKeys={selectedRowKeys}
                            noDataText={dqTranslateMsg('Page_Configuration_' + 'NoData')}
                            paging={{ enabled: false }}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            hoverStateEnabled={true}
                        >
                            <Toolbar>
                                <Item location='before'>
                                    <div
                                        className='grid-title'
                                        style={{ fontSize: '18px' }}
                                    >
                                        {dqTranslateMsg('Page_Configuration_' + 'NotUsingMeasuredQuantity')}
                                    </div>
                                </Item>
                                <Item name='searchPanel' />
                            </Toolbar>
                            <Selection mode='multiple' />
                            <Scrolling
                                mode='standard'
                                rowRenderingMode='standard'
                                useNative={false}
                            />
                            <SearchPanel
                                visible={true}
                                width={100}
                                placeholder={dqTranslateMsg('Page_Configuration_' + 'SearchPlaceHoler')}
                            />
                            <Column
                                dataField={'value'}
                                caption={dqTranslateMsg('Page_Configuration_' + 'Value')}
                                alignment='center'
                                width={'15%'}
                            />
                            <Column
                                dataField={ETCUtils.checkLang(language)}
                                caption={dqTranslateMsg('Page_Configuration_' + 'MeasureQuantity')}
                                alignment='center'
                            />
                        </DataGrid>
                        <div className='push-settings-find-distribution-move-button-container'>
                            <Button
                                className='push-settings-find-distribution-move-button-content'
                                text='>>'
                                type='success'
                                onClick={handleAddMeasureQuantity}
                            />
                            <Button
                                className='push-settings-find-distribution-move-button-content'
                                text='<<'
                                type='danger'
                                onClick={handleRemoveMeasureQuantity}
                            />
                        </div>
                        <DataGrid
                            className='push-settings-find-distribution-datagrid-content-assigned'
                            ref={usingMQGridRef}
                            id='usingMQGrid'
                            keyExpr='value'
                            dataSource={allowedMQ}
                            columnResizingMode='widget'
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            // selectedRowKeys={selectedRowKeys}
                            noDataText={dqTranslateMsg('Page_Configuration_' + 'NoData')}
                            paging={{ enabled: false }}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            hoverStateEnabled={true}
                            onRowUpdating={onRowUpdating}
                        >
                            <Toolbar>
                                <Item location='before'>
                                    <div
                                        className='grid-title'
                                        style={{ fontSize: '18px' }}
                                    >
                                        {dqTranslateMsg('Page_Configuration_' + 'UsingMeasuredQuantity')}
                                    </div>
                                </Item>
                                <Item name='searchPanel' />
                            </Toolbar>
                            <Editing
                                mode='cell'
                                allowUpdating={true}
                            />
                            <Selection mode='multiple' />
                            <Scrolling
                                mode='standard'
                                rowRenderingMode='standard'
                                useNative={false}
                            />
                            <SearchPanel
                                visible={true}
                                width={100}
                                placeholder={dqTranslateMsg('Page_Configuration_' + 'SearchPlaceHoler')}
                            />
                            <Column
                                dataField={'value'}
                                allowEditing={false}
                                caption={dqTranslateMsg('Page_Configuration_' + 'Value')}
                                alignment='center'
                                width={'10%'}
                            />
                            <Column
                                dataField={ETCUtils.checkLang(language)}
                                allowEditing={false}
                                caption={dqTranslateMsg('Page_Configuration_' + 'MeasureQuantity')}
                                alignment='center'
                            />
                            <Column
                                dataField='DistributionForMQ'
                                caption={dqTranslateMsg('Page_Configuration_' + 'DistributionForMQ')}
                                alignment='center'
                            >
                                <Lookup
                                    dataSource={sd.defaultDistributionSideTypeDetailsDefine()}
                                    valueExpr='value'
                                    displayExpr='text'
                                />
                            </Column>
                        </DataGrid>
                    </div>
                    <div
                        className='push-settings-find-distribution-lists-container'
                        overflowY={'auto'}
                    >
                        <ScrollView
                            className='side-panel-tab-container'
                            style={{ padding: '5px' }}
                            showScrollbar={'onHover'}
                            useNative={false} // disabled={true}
                            onScroll={handleScroll}
                            onContentReady={(e) => {
                                e.component.scrollTo({ top: scrollPosition })
                            }}
                        >
                            <Form
                                id='configuration-find-distribution-form'
                                style={{ width: '100%', height: '100%' }}
                            >
                                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'SavedDistribution')}>
                                    <SimpleItem>
                                        <Switch
                                            items={sd.onOffTypeDefine}
                                            value={tempState.useSavedDistribution}
                                            onValueChanged={handleSavedDistributionChange}
                                        />
                                    </SimpleItem>
                                </GroupItem>

                                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'MeasuredQuantity')}>
                                    <SimpleItem>
                                        <Switch
                                            items={sd.onOffTypeDefine}
                                            value={tempState.useMeasuredQuantity}
                                            onValueChanged={handleMeasuredQuantityChange}
                                        />
                                    </SimpleItem>
                                </GroupItem>

                                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'DefaultDistribution')}>
                                    <SimpleItem>
                                        <Switch
                                            items={sd.onOffTypeDefine}
                                            value={tempState.useDefaultDistribution}
                                            onValueChanged={handleDefaultDistributionChange}
                                        />
                                    </SimpleItem>
                                </GroupItem>

                                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'OneSideDefaultDistribution')}>
                                    <SimpleItem>
                                        <RadioGroup
                                            items={sd.defaultDistributionSideTypeDetailsDefine()}
                                            value={tempState.oneSideDefaultDistribution}
                                            onValueChanged={handleOneSideDefaultDistributionChange}
                                            valueExpr='value'
                                            displayExpr='text'
                                        />
                                    </SimpleItem>
                                </GroupItem>

                                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'TwoSideDefaultDistribution')}>
                                    <SimpleItem>
                                        <RadioGroup
                                            items={sd.defaultDistributionSideTypeDetailsDefine()}
                                            value={tempState.twoSideDefaultDistribution}
                                            onValueChanged={handleTwoSideDefaultDistributionChange}
                                            valueExpr='value'
                                            displayExpr='text'
                                        />
                                    </SimpleItem>
                                </GroupItem>
                            </Form>
                        </ScrollView>
                    </div>
                </div>
            </div>
        </div>
    )
}
