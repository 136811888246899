import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as rImpl from './QualityControlRangeChartOptionImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import * as xBarImpl from 'src/pages/chart-quality-control/components/main-view/chart-option/QualityControlXBarChartOptionImpl'
import { getLocationChartSubData, getQccChartSubData } from 'src/functions/QccProcessUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

const formatLimitInfoByAxis = (label, value, char, configSettings) => {
    return value !== undefined && typeof value === 'number' ? `${label} : ${DNUtils.axisValueDisplay(value, char, configSettings)}<br>` : ''
}
const formatLimitInfoByCalc = (label, value, char, configSettings) => {
    return value !== undefined && typeof value === 'number' ? `${label} : ${DNUtils.calcValueDisplay(value, configSettings)}<br>` : ''
}
const toolTipBalloonCallback = (args, settings, configSettings, char) => {
    let displayStr = ''

    if (args[0].seriesName) {
        displayStr += `${args[0].seriesName}<br>`
    }

    if (args[0].data) {
        displayStr += `${dqTranslateMsg('Page_QualityControlChart_' + 'Number')} : ${args[0].data[0]}<br>`
        displayStr += `${dqTranslateMsg('Page_QualityControlChart_' + 'Value')} : ${DNUtils.valueDisplay(args[0].data[1], char, configSettings)}<br>`
    }

    const useControlLimits = settings.page.controlLimitEnable
    const useAverageLimits = settings.page.averageLimitEnable

    if (useControlLimits || useAverageLimits) {
        displayStr += `<br>${dqTranslateMsg('Page_QualityControlChart_' + 'Limits')}<br>`

        if (useControlLimits) {
            displayStr += formatLimitInfoByCalc('UCL', settings.ucl, char, configSettings)
            displayStr += formatLimitInfoByCalc('LCL', settings.lcl, char, configSettings)
        }

        if (useAverageLimits) {
            displayStr += formatLimitInfoByCalc('X\u0304', settings.average, char, configSettings)
        }
    }

    return displayStr
}

export const getRangeChartOptions = (settings, configSettings, targetChar, targetValue) => {
    const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
    const filteredValue = outlierFilterValues(limits, targetChar, targetValue, configSettings)

    const rangeCalcCallback = rImpl.makeQccLowerSeriesData
    const { lcl, ucl, average, seriesData, values } = getQccChartSubData('range', settings, configSettings, targetChar, filteredValue, rangeCalcCallback)
    const maxValue = rImpl.findLargestNumber(values)
    const minValue = rImpl.findSmallestNumber(values)
    const xAxisRight = seriesData.length + 1
    const xAxisLeft = 0

    const topBottomCalcArray = {
        settings,
        usl,
        lsl,
        uwl,
        lwl,
        lcl,
        ucl,
        upl,
        lpl,
        url,
        lrl,
        uacl,
        lacl,
        maxValue,
        minValue,
    }

    const yAxisTop = rImpl.makeYAxisTop(topBottomCalcArray)
    const yAxisBottom = rImpl.makeYAxisBottom(topBottomCalcArray)
    const decimalPlaces = DBUtils.getFieldData(targetChar, 'c2022') ?? configSettings.commonSettings.displayDecimalPlaces

    // chart setting reorganization
    const addedSettings = {
        ...settings,
        lsl,
        usl,
        lwl,
        uwl,
        lcl,
        ucl,
        center: average,
        average,
        nominal,
        // warnOffset,
        xAxisRight,
        xAxisLeft,
        yAxisTop,
        yAxisBottom,
        decimalPlaces,
    }
    return {
        animationDuration: 300,
        toolbox: {
            feature: {
                saveAsImage: {},
            },
            itemSize: (15 / (addedSettings.page.layout.row + addedSettings.page.layout.column)) * 4,
        },
        tooltip: {
            trigger: 'axis',
            appendToBody: true,
            position: function (pos, params, dom, rect, size) {
                let obj = { top: 60 }
                obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
                return obj
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            extraCssText: 'width: 170px',
            formatter: function (args) {
                return toolTipBalloonCallback(args, addedSettings, configSettings, targetChar)
            },
            textStyle: {
                fontFamily: 'Arial',
            },
        },
        animationDuration: 300,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        legend: {
            show: false,
            textStyle: {
                fontFamily: 'Arial',
            },
        },
        grid: {
            top: '15%',
            bottom: '7%',
            left: '0%',
            right: '5%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
            min: xAxisLeft,
            max: xAxisRight,
            minInterval: 1,
            maxInterval: addedSettings.page.xAxisDenseLabel ? 2 : xAxisRight < 10 ? 2 : Math.abs(Math.floor(addedSettings.xAxisRight / 5)),
            axisLabel: {
                rotate: 90,
                fontFamily: 'Arial',
                hideOverlap: true,
                showMinLabel: false,
                showMaxLabel: false,
            },
            splitLine: {
                show: false,
            },
            axisLine: {
                onZero: false,
                hideOverlap: true,
            },
            axisTick: {
                interval: 1,
            },
        },
        yAxis: {
            show: true,
            min: addedSettings.yAxisBottom,
            max: addedSettings.yAxisTop,
            type: 'value',
            axisLabel: {
                fontFamily: 'Arial',
                showMinLabel: false,
                showMaxLabel: false,
                formatter: function (value, index) {
                    let axisMax = addedSettings.yAxisTop
                    let axisMin = addedSettings.yAxisBottom

                    if (value === axisMin || value === axisMax) {
                        return ''
                    }
                    return value
                },
            },
            splitLine: {
                show: true,
            },
            minorTick: {
                show: false,
            },
            axisLine: {
                onZero: false,
            },
        },
        dataZoom: [
            {
                show: true,
                type: 'slider',
                orient: 'horizontal',
                xAxisIndex: 0,
                height: `3%`,
                bottom: '3%',
                brushSelect: true,
                showDataShadow: true,
                showDetail: false,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'slider',
                orient: 'vertical',
                width: `3%`,
                brushSelect: true,
                showDataShadow: true,
                showDetail: false,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'inside',
                yAxisIndex: 0,
                filterMode: 'none',
                zoomLock: false, // Allow zooming
                zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
            },
            {
                show: true,
                type: 'inside',
                xAxisIndex: 0,
                filterMode: 'none',
            },
        ],
        series: [
            {
                type: 'line',
                name: dqTranslateMsg('Page_QualityControlChart_' + 'Range'),
                data: seriesData,
                symbol: (value, params) => rImpl.makeSymbol(value, params, addedSettings),
                symbolSize: (value, params) => rImpl.makeSymbolSize(value, params, addedSettings),
                itemStyle: {
                    color: (params) => rImpl.makeSymbolColor(params, addedSettings),
                },
                lineStyle: rImpl.convertToChartLine(addedSettings),
                markLine: {
                    animation: false,
                    symbol: 'none',
                    precision: 10,
                    emphasis: {
                        disabled: true,
                    },
                    data: rImpl.convertToMarkLine(addedSettings),
                    precision: addedSettings.lineChart.markLines.precision,
                },
                markArea: {
                    type: 'line',
                    name: 'Areas',
                    emphasis: {
                        disabled: true,
                    },
                    data: rImpl.convertToMarkArea(addedSettings),
                },
            },
        ],
    }
}
