// page settings component
export const overviewSettingsPrm = {
  page: {
    display: {
      type: 'single',
      main: 'char',
      sub: 'char',
    },
  },
  pieChart: {
    error: {
      color: 'rgba(255, 0, 0, 1)',
    },
    warn: {
      color: 'rgba(255, 255, 0, 1)',
    },
    good: {
      color: 'rgba(2, 152, 212, 1)',
    },
    none: {
      color: 'rgba(135, 135, 131, 1)',
    },
  },
  barChart: {
    error: {
      color: 'rgba(255, 0, 0, 1)',
    },
    warn: {
      color: 'rgba(255, 255, 0, 1)',
    },
    good: {
      color: 'rgba(2, 152, 212, 1)',
    },
    none: {
      color: 'rgba(135, 135, 131, 1)',
    },
    histogramBar: {
      color: 'rgba(0, 127, 207, 1)',
    },
    bellCurve: {
      color: 'rgba(0, 127, 207, 1)',
    },
  },
}
