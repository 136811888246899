export const correlationChartSettingsPrm = {
  page: {
    displayType: 'all', // top5, all
    relation: {
      level: {
        // abs degree
        strong: 0.8,
      },
    },
    displayMethod: 'barChart',
  },
  barChart: {
    relation: {
      color: {
        strong: 'rgba(238, 28, 55, 1)',
        other: 'rgba(2, 152, 212, 1)',
      },
    },
  },
  heatMap: {
    relation: {
      color: {
        minusStrong: 'rgba(2, 152, 212, 1)',
        plusStrong: 'rgba(238, 28, 55, 1)',
      },
    },
  },
}
