import SidePanelScrollBoardFilter from 'src/pages/scroll-board/components/side-panel/SidePanelScrollBoardFilter'
import './SidePanelFilterForm.scss'
import SidePanelDatabaseFilter from 'src/pages/database/components/side-panel/SidePanelDatabaseFilter'
import { ScrollView } from 'devextreme-react'
export default function SidePanelFilterForm(props) {
  const { pageKey } = props

  return (
    <>
      {pageKey === 'scrollBoard' && <SidePanelScrollBoardFilter pageKey={pageKey} />}
      {pageKey === 'database' && <SidePanelDatabaseFilter pageKey={pageKey} />}
    </>
  )
}
