import { DateBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'

const DateBoxSimpleItem = ({ colSpan = 2, label, labelLocation, name, pickerType, displayFormat, value, disabled, onValueChanged }) => {
  let calendarOptions = {}
  if (displayFormat === 'HH:mm') {
    calendarOptions = {
      visible: false,
    }
  } else {
    calendarOptions = {
      visible: true,
    }
  }

  return (
    <SimpleItem colSpan={colSpan}>
      <Label text={label} location={labelLocation} />
      <DateBox
        name={name}
        // label={label}
        // labelMode={labelLocation}
        displayFormat={displayFormat}
        pickerType={pickerType}
        value={value}
        useNative={true}
        useMaskBehavior={true}
        interval={1}
        type="datetime"
        disabled={disabled}
        onValueChanged={onValueChanged}
        calendarOptions={calendarOptions}
      />
    </SimpleItem>
  )
}

export default DateBoxSimpleItem
