import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function SettingsPageLayoutPadding() {
  const dispatch = useDispatch()
  const pageKey = 'reportPrintMultiFunction'
  const pageState = useSelector((state) => state.common.pages[pageKey])

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page.pagePaddings[dataField] = value === null ? minOption : value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`page-layout-padding-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'Page Paddings')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Top'),
        labelLocation: 'top',
        name: 'top',
        min: 1,
        max: 100,
        step: 1,
        value: pageState.page.pagePaddings.top,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'top'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Bottom'),
        labelLocation: 'top',
        name: 'bottom',
        min: 1,
        max: 100,
        step: 1,
        value: pageState.page.pagePaddings.bottom,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'bottom'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Left'),
        labelLocation: 'top',
        name: 'left',
        min: 1,
        max: 100,
        step: 1,
        value: pageState.page.pagePaddings.left,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'left'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Right'),
        labelLocation: 'top',
        name: 'right',
        min: 1,
        max: 100,
        step: 1,
        value: pageState.page.pagePaddings.right,
        showSpinButtons: true,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'right'),
      })}
    </GroupItem>
  )
}
