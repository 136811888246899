import { backendPath } from './defines/DBInfo'
import JwtUtils from 'src/functions/JwtUtils'
const path = backendPath

export const SUCCESS = 0

export const ERR_NOT_A_MEMBER = 1000

export const ERR_PARAMETER_INVALID = 2000

export const ERR_SAVE_FAIL = 3000

export const ERR_UNDEFINED_ERROR = 9999

export const getPartInfo = async (commonFilter, partFilter) => {
    const makeFilter = (commonFilter, partFilter) => {}
    // const filter = makeFilter(commonFilter, partFilter)
    const filter = []

    try {
        const accessToken = JwtUtils.loadToken()
        const response = await fetch(path + 'data/getPartInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: JSON.stringify({
                pagination: '1-100',
            }),
        })
        const result = await response.json()
        return result
    } catch (error) {
        throw error
    }
}

export const setPartInfo = async (user, updatedData) => {
    const company = user.user_company
    const plant = user.user_plant
    const group = user.user_group

    const requestBody = {
        company: company,
        plant: plant,
        group: group,
        requestMsg: updatedData,
    }

    try {
        const accessToken = JwtUtils.loadToken()
        const response = await fetch(path + 'data/setPartInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: JSON.stringify(requestBody),
        })

        const result = await response.json()
        return result
    } catch (error) {
        throw error
    }
}

// export const setPartInfo = async (requestMsg) => {}

export const deletePart = async (user, part_id) => {
    const company = user.user_company
    const plant = user.user_plant
    const group = user.user_group

    const makeReqBody = (part_id) => {
        let requestBody = { company: company, plant: plant, group: group, part_id: part_id }

        return requestBody
    }

    try {
        const accessToken = JwtUtils.loadToken()
        const requestBody = JSON.stringify(makeReqBody(part_id))

        const response = await fetch(path + 'data/deletePart', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: requestBody,
        })

        const result = await response.json()
        return result
    } catch (error) {
        throw error
    }
}
