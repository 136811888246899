export const valueSummaryListSettingsPrm = {
  page: {
    decimalPlaces: 3,
    dateTimeDisplayFormat: 'date/time/sec', // 'date/time', 'date' (formatting -> yyyy-mm-dd hh:mm:ss)
  },
  theme: {
    iconType: {
      result: 'thumbs',
    },
  },
}
