import TabPanel, { Item } from 'devextreme-react/tab-panel'
import { connect } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './ConfigForm.scss'
import Catalog from './catalog/Catalog'
import CommonSettings from './common-settings/CommonSettings'
import Configuration from './configuration/Configuration'
import FindDistribution from './find-distribution/FindDistribution'
import KeyInfoConfig from './key-info/KeyInfoConfig'
import * as permi from 'src/pages/components/dq-permission/DQPermission'
import { useAuth } from 'src/contexts/auth'

export default function ConfigForm({ pageKey }) {
    const { user } = useAuth()
    return (
        <div className='dx-theme-background-color'>
            <TabPanel
                className='tab-panel-item-container'
                showNavButtons={true}
                repaintChangesOnly={true}
            >
                <Item title={dqTranslateMsg('Page_Configuration_' + 'Encoding')}>
                    <KeyInfoConfig pageKey={pageKey} />
                </Item>
                {permi.checkAdminPermission(user.user_group) ? (
                    <Item title={dqTranslateMsg('Page_Configuration_' + 'FieldUseSelection')}>
                        <Configuration pageKey={pageKey} />
                    </Item>
                ) : null}
                {permi.checkAdminPermission(user.user_group) ? (
                    <Item title={dqTranslateMsg('Page_Configuration_' + 'Catalogs')}>
                        <Catalog pageKey={pageKey} />
                    </Item>
                ) : null}
                <Item title={dqTranslateMsg('Page_Configuration_' + 'CommonSettings')}>
                    <CommonSettings pageKey={pageKey} />
                </Item>
                <Item title={dqTranslateMsg('Page_Configuration_' + 'FindDistribution')}>
                    <FindDistribution pageKey={pageKey} />
                </Item>
            </TabPanel>
        </div>
    )
}
