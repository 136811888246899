import { Column, DataGrid, Scrolling } from 'devextreme-react/data-grid'
import ReactECharts from 'echarts-for-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from 'src/contexts/theme'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './MonitoringDetailForm.scss'
import * as vslImpl from './MonitoringDetailValueSummaryList'
import { getMonitoringValueChartOption } from './chart-option/MonitoringDetailValueChartOption'
import { getMonitoringDeviationChartOption } from './chart-option/MonitoringDeviationChartOption'
import { useLanguage } from 'src/contexts/languages'
import CPUtils from 'src/functions/CommonPageUtils'
import { MONO_BLUE, MONO_RED, COLOR } from 'src/functions/constants/iconPathDefine'

function throttle(func, delay) {
    let inThrottle
    let lastFunc
    let lastRan

    return function () {
        const context = this
        const args = arguments

        if (!inThrottle) {
            func.apply(context, args)
            lastRan = Date.now()
            inThrottle = true
        } else {
            clearTimeout(lastFunc)
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= delay) {
                    func.apply(context, args)
                    lastRan = Date.now()
                }
            }, delay - (Date.now() - lastRan))
        }
    }
}

function getMenuIconPath(type, theme) {
    // const path = type === 'mono' ? '/images/mono' : '/images/color'

    let path
    if (type === MONO_BLUE) {
        path = '/images/monoBlue'
    } else if (type === MONO_RED) {
        path = '/images/monoRed'
    } else {
        path = '/images/color'
    }

    let iconPath
    if (type === 'monoBlue' || type === 'monoRed') {
        const themePath = theme.includes('light') ? '/light' : '/dark'
        iconPath = path + themePath
    } else {
        iconPath = path
    }
    return iconPath
}

export default function MonitoringDetailForm({
    ids: {
        popup: { params, data: localDBData },
        language,
    },
    settings,
    valueChartSettings,
    summaryListSettings,
    configSettings,
    othersPage,
}) {
    const { getTheme } = useTheme()
    // const { language } = useLanguage()
    const [selIds, part_id, char_id, value_id] = [params, params.part_id, params.char_id, params.value_id]

    const valueChartRef = useRef(null)
    const devChartRef = useRef(null)

    if (localDBData.length === 0) {
        return <div>No data available</div>
    }

    const target = localDBData.find((data) => data.part_id === part_id && data.char_id === char_id)
    const targetValue = target.valueRaw.find((data) => data.part_id === part_id && data.char_id === char_id && data.value_id === value_id)
    const dataSource = vslImpl.makeMonitoringValueSummaryTableData(settings, configSettings, target)
    const option = getMonitoringValueChartOption(valueChartSettings, configSettings, othersPage, selIds, target)
    // const handleResize = () => {
    //   if (echartsRef.current) {
    //     echartsRef.current.getEchartsInstance().resize()
    //   }
    // }
    const handleResize = () => {
        const chartInstance = valueChartRef.current.getEchartsInstance()
        if (chartInstance) {
            chartInstance.resize() // Or any other method you need to call
        }
    }

    const handleDevResize = () => {
        const chartInstance = devChartRef.current.getEchartsInstance()
        if (chartInstance) {
            chartInstance.resize() // Or any other method you need to call
        }
    }

    function renderDeviationChart(ref, cellData, language) {
        const uniqueRefKey = cellData.data.no
        let trendChartOption
        if (cellData.data.lsl !== null && cellData.data.usl !== null && cellData.data.deviation !== null) {
            trendChartOption = getMonitoringDeviationChartOption(cellData.data.lsl, cellData.data.usl, cellData.data.nominal, cellData.data.deviation)
            return (
                <ReactECharts
                    ref={(e) => {
                        devChartRef.current = e
                    }}
                    className='value-summary-list-deviation-container'
                    option={trendChartOption}
                    style={{ height: '50px', width: '100%' }}
                    opts={{
                        renderer: 'svg',
                        locale: ETCUtils.checkLangForECharts(language),
                    }}
                    onEvents={{ finished: handleDevResize }}
                />
            )
        } else {
            return (
                <>
                    <img
                        src={'/images/color/warning-exclamation.svg'}
                        width='25'
                        height='25'
                    />
                    {/* <div style={{ fontSize: '10px' }}>Data cannot be evaluated</div> */}
                </>
            )
        }
    }
    function renderNo(cellData) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center', // 세로 중앙 정렬
                    justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                }}
            >
                <p>{cellData.data.no}</p>
            </div>
        )
    }
    function renderDesc(cellData) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center', // 세로 중앙 정렬
                    justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                }}
            >
                <p>{cellData.data.desc}</p>
            </div>
        )
    }
    function renderDateTime(cellData, settings, configSettings) {
        const value = cellData.data.char_time

        let displayStr = ''
        const dateTimeFormat = settings.page.dateTimeDisplayFormat ?? 'date/time/sec'

        displayStr = CPUtils.getDisplayDateTimeSec(dateTimeFormat, value)
        // if (value !== null && value !== '' && value.length === 14) {
        //   const year = value.slice(0, 4)
        //   const month = value.slice(4, 6)
        //   const day = value.slice(6, 8)
        //   const hour = value.slice(8, 10)
        //   const minute = value.slice(10, 12)
        //   const second = value.slice(12, 14)
        //   if (dateTimeFormat === 'date/time/sec') {
        //     displayStr += `${year}-${month}-${day} ${hour}:${minute}:${second}`
        //   } else if (dateTimeFormat === 'date/time') {
        //     displayStr += `${year}-${month}-${day} ${hour}:${minute}`
        //   } else {
        //     displayStr += `${year}-${month}-${day}`
        //   }
        // } else {
        //   displayStr += value
        // }
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center', // 세로 중앙 정렬
                    justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                }}
            >
                <p>{displayStr}</p>
            </div>
        )
    }
    function renderResult(cellData, settings, configSettings, theme) {
        const iconType = settings.theme.iconType.result

        if (iconType === 'arrow') {
            if (cellData.data.result === 1) {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center', // 세로 중앙 정렬
                            justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                        }}
                    >
                        <img
                            src={`${getMenuIconPath(configSettings.menuIconType, theme)}/arrow-good.svg`}
                            width='50'
                            height='50'
                        />
                    </div>
                ) // OK
            } else if (cellData.data.result === 0) {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center', // 세로 중앙 정렬
                            justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                        }}
                    >
                        <img
                            src={`${getMenuIconPath(configSettings.menuIconType, theme)}/arrow-bad.svg`}
                            width='50'
                            height='50'
                        />
                    </div>
                ) // NG
            } else {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center', // 세로 중앙 정렬
                            justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                        }}
                    >
                        <img
                            src={'/images/color/warning-exclamation.svg'}
                            width='50'
                            height='50'
                        />
                        {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
                    </div>
                )
            }
        } else if (iconType === 'smile') {
            if (cellData.data.result === 1) {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center', // 세로 중앙 정렬
                            justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                        }}
                    >
                        <img
                            src={`${getMenuIconPath(configSettings.menuIconType, theme)}/smile-good.svg`}
                            width='50'
                            height='50'
                        />
                    </div>
                ) // OK
            } else if (cellData.data.result === 0) {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center', // 세로 중앙 정렬
                            justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                        }}
                    >
                        <img
                            src={`${getMenuIconPath(configSettings.menuIconType, theme)}/smile-bad.svg`}
                            width='50'
                            height='50'
                        />
                    </div>
                ) // NG
            } else {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center', // 세로 중앙 정렬
                            justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                        }}
                    >
                        <img
                            src={'/images/color/warning-exclamation.svg'}
                            width='50'
                            height='50'
                        />
                        {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
                    </div>
                )
            }
        } else {
            // thumbs
            if (cellData.data.result === 1) {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center', // 세로 중앙 정렬
                            justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                        }}
                    >
                        <img
                            src={`${getMenuIconPath(configSettings.menuIconType, theme)}/thumbs-good.svg`}
                            width='50'
                            height='50'
                        />
                    </div>
                ) // OK
            } else if (cellData.data.result === 0) {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center', // 세로 중앙 정렬
                            justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                        }}
                    >
                        <img
                            src={`${getMenuIconPath(configSettings.menuIconType, theme)}/thumbs-bad.svg`}
                            width='50'
                            height='50'
                        />
                    </div>
                ) // NG
            } else {
                return (
                    <>
                        <img
                            src={'/images/color/warning-exclamation.svg'}
                            width='50'
                            height='50'
                        />
                        {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
                    </>
                )
            }
        }
    }
    function renderNominal(cellData) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center', // 세로 중앙 정렬
                    justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                }}
            >
                <p>{parseFloat(cellData.data.nominal).toFixed(cellData.data.decimalPlaces)}</p>
            </div>
        )
    }
    function renderUsl(cellData) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center', // 세로 중앙 정렬
                    justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                }}
            >
                <p>{parseFloat(cellData.data.usl).toFixed(cellData.data.decimalPlaces)}</p>
            </div>
        )
    }

    function renderLsl(cellData) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center', // 세로 중앙 정렬
                    justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                }}
            >
                <p>{parseFloat(cellData.data.lsl).toFixed(cellData.data.decimalPlaces)}</p>
            </div>
        )
    }

    function renderValue(cellData, summaryListSettings, configSettings, selIds, target) {
        if (cellData.data.v0001 > cellData.data.usl || cellData.data.v0001 < cellData.data.lsl) {
            return (
                <>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center', // 세로 중앙 정렬
                            justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                        }}
                    >
                        <p>{cellData.data.value}</p>
                    </div>
                    <img
                        src={'/images/color/warning-exclamation.svg'}
                        width='25'
                        height='25'
                    />
                </>
            )
        }

        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center', // 세로 중앙 정렬
                    justifyContent: 'center', // 가로 중앙 정렬 (필요한 경우)
                }}
            >
                <p>{cellData.data.value}</p>
            </div>
        )
    }

    function onRowPrepared(e) {
        e.rowElement.style.height = '20px'
        e.rowElement.style.fontSize = '15px'
    }

    const handleDataGridContentReady = () => {
        const devChartInstance = devChartRef.current?.getEchartsInstance() // Assuming instance is directly accessible
        const valueChartInstance = valueChartRef.current?.getEchartsInstance() // Assuming instance is directly accessible
        if (devChartInstance) {
            devChartInstance.resize() // Or any other method you need to call
        }

        if (valueChartInstance) {
            valueChartInstance.resize() // Or any other method you need to call
        }
    }

    useEffect(() => {
        // const devChartInstance = devChartRef.current.getEchartsInstance() // Assuming instance is directly accessible
        const valueChartInstance = valueChartRef.current.getEchartsInstance() // Assuming instance is directly accessible
        // if (devChartInstance) {
        //   devChartInstance.resize() // Or any other method you need to call
        // }

        if (valueChartInstance) {
            valueChartInstance.resize() // Or any other method you need to call
        }
    }, [])

    return (
        <div className='monitoring-mater-detail-container'>
            <div className='monitoring-detail-info'>
                {/* description */}
                <div className='monitoring-detail-info-part'>
                    <div className='monitoring-detail-info-part-number'>{target.partRaw.p1001}</div>
                    <div className='monitoring-detail-info-part-desc'>{target.partRaw.p1002}</div>
                </div>
                <div className='monitoring-detail-info-char'>
                    <div className='monitoring-detail-info-char-desc'>{target.charRaw.c2002}</div>
                    <div className='monitoring-detail-info-char-text'>{target.charRaw.c2092}</div>
                </div>
                <div className='monitoring-detail-info-value'>
                    <div className='monitoring-detail-info-value-ident'>{targetValue.v0014}</div>
                    <div className='monitoring-detail-info-value-order'>{targetValue.v0053}</div>
                    <div className='monitoring-detail-info-value-date'>{targetValue.v0004}</div>
                </div>
            </div>
            <div className='monitoring-detail-value-summary-list-container'>
                <DataGrid
                    className='monitoring-detail-value-summary-list-content'
                    id='monitoring-value-summary-list-datagrid'
                    dataSource={dataSource}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode='widget'
                    filterRow={{ visible: false }}
                    noDataText={dqTranslateMsg('NoData')}
                    paging={{ enabled: false }}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    hoverStateEnabled={true}
                    onRowPrepared={onRowPrepared}
                    onContentReady={handleDataGridContentReady}
                >
                    <Scrolling
                        mode='standard'
                        rowRenderingMode='standard'
                        useNative={false}
                    />
                    <Column
                        dataField='no'
                        width={'10%'}
                        alignment='center'
                        cellRender={(cellData) => renderNo(cellData)}
                    />
                    <Column
                        dataField='desc'
                        width={'20%'}
                        alignment='center'
                        cellRender={(cellData) => renderDesc(cellData)}
                    />
                    <Column
                        dataField='nominal'
                        width={'10%'}
                        alignment='center'
                        cellRender={(cellData) => renderNominal(cellData, configSettings)}
                    />
                    <Column
                        dataField='lsl'
                        caption={dqTranslateMsg('LSL')}
                        width={'10%'}
                        alignment='center'
                        cellRender={(cellData) => renderLsl(cellData)}
                    />
                    <Column
                        dataField='usl'
                        caption={dqTranslateMsg('USL')}
                        width={'10%'}
                        alignment='center'
                        cellRender={(cellData) => renderUsl(cellData)}
                    />
                    <Column
                        dataField='char_time'
                        width={'15%'}
                        alignment='center'
                        cellRender={(cellData) => renderDateTime(cellData, summaryListSettings, configSettings)}
                    />
                    <Column
                        dataField='result'
                        alignment='center'
                        width={'10%'}
                        cellRender={(cellData) => renderResult(cellData, summaryListSettings, configSettings, getTheme())}
                    />
                    <Column
                        dataField='value'
                        width={'10%'}
                        alignment='center'
                        cellRender={(cellData) => renderValue(cellData, summaryListSettings, configSettings, selIds, target)}
                    />
                    <Column
                        dataField='deviation'
                        alignment='center'
                        width={'15%'}
                        cellRender={(cellData) => renderDeviationChart(devChartRef, cellData, language)}
                    />
                    {/* <MasterDetail enabled={true} component={(props) => MonitoringMasterDetail(props, settings, configSettings)} /> */}
                </DataGrid>
                <ReactECharts
                    ref={(e) => {
                        valueChartRef.current = e
                    }}
                    style={{ width: '100%', height: '100%' }}
                    notMerge={true}
                    option={option}
                    theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                    opts={{
                        renderer: 'svg',
                        locale: ETCUtils.checkLangForECharts(language),
                    }}
                    onEvents={{ finished: handleResize }}
                />
            </div>
            {/* valueChart */}
            {/* <div className="monitoring-detail-value-chart-container"></div> */}
        </div>
    )
}
