import CCUtils from 'src/functions/CommonCalcUtils'
import * as dqConvert from 'src/pages/components/dq-convert/DQConvert'
import CPUtils from 'src/functions/CommonPageUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export const makeXAxisLabelFormat = (value, index, valueArray, xAxisLabelItems, settings, configSettings, catalogs) => {
    let xLabel = ''

    if (xAxisLabelItems.length === 0) {
        xLabel = value
    } else {
        if (value < 1 || value > valueArray.length || !/^\d*$/.test(value)) {
            xLabel = ''
        } else {
            xAxisLabelItems.map((item) => {
                if (xLabel === '') {
                    if (item.dataField === 'v0004') {
                        xLabel = CPUtils.getDisplayDateTimeSec(settings.page.dateTimeLabelDisplayFormat, valueArray[value - 1][item.dataField])
                    } else {
                        xLabel = dqConvert.convertByCatalog(item.dataField, `${valueArray[value - 1][item.dataField]}`, catalogs)
                    }
                } else {
                    if (item.dataField === 'v0004') {
                        xLabel = `${xLabel}/` + CPUtils.getDisplayDateTimeSec(settings.page.dateTimeLabelDisplayFormat, valueArray[value - 1][item.dataField])
                    } else {
                        xLabel = `${xLabel}/` + dqConvert.convertByCatalog(item.dataField, `${valueArray[value - 1][item.dataField]}`, catalogs)
                    }
                }
            })
        }
    }
    return xLabel
}

export const makeSymbol = (value, params, settings, selIds, valueData) => {
    // const val = params.data.v0001
    try {
        const val = params.data[1]
        const val_index = params.data[0]

        const targetRefValue = valueData.find((value) => value.part_id === selIds.part_id && value.char_id === selIds.char_id && value.value_id === selIds.value_id)

        if (valueData[val_index - 1].value_id === selIds.value_id) {
            return 'pin'
        }

        if (valueData[val_index - 1].local_status === 1 || valueData[val_index - 1].local_status === 2) {
            if (settings.usl === null && settings.lsl === null) {
                return settings.lineChart.symbols.good.shape
            } else if (settings.usl !== null && settings.lsl !== null) {
                if (val > settings.usl) {
                    return settings.lineChart.symbols.error.upper.shape
                } else if (val > settings.uwl) {
                    return settings.lineChart.symbols.warn.upper.shape
                } else if (val >= settings.lwl) {
                    return settings.lineChart.symbols.good.shape
                } else if (val >= settings.lsl) {
                    return settings.lineChart.symbols.warn.lower.shape
                } else {
                    return settings.lineChart.symbols.error.lower.shape
                }
            } else if (settings.usl == null) {
                if (val >= settings.lwl) {
                    return settings.lineChart.symbols.good.shape
                } else if (val > settings.lsl) {
                    return settings.lineChart.symbols.warn.lower.shape
                } else {
                    return settings.lineChart.symbols.error.lower.shape
                }
            } else {
                // settings.lsl === null case
                if (val > settings.usl) {
                    return settings.lineChart.symbols.error.upper.shape
                } else if (val > settings.uwl) {
                    return settings.lineChart.symbols.warn.upper.shape
                } else {
                    return settings.lineChart.symbols.good.shape
                }
            }
        } else {
            return 'circle'
        }
    } catch (error) {
        console.log('1111')
    }
}

export const makeSymbolSize = (value, params, settings, selIds, valueData) => {
    // const val = params.data.v0001
    const val = params.data[1]
    const val_index = params.data[0]
    const targetRefValue = valueData.find((value) => value.part_id === selIds.part_id && value.char_id === selIds.char_id && value.value_id === selIds.value_id)
    if (valueData[val_index - 1].value_id === selIds.value_id) {
        return 50
    }

    if (valueData[val_index - 1].local_status === 1 || valueData[val_index - 1].local_status === 2) {
        if (settings.usl === null && settings.lsl === null) {
            return settings.lineChart.symbols.good.size
        } else if (settings.usl !== null && settings.lsl !== null) {
            if (val > settings.usl) {
                return settings.lineChart.symbols.error.upper.size
            } else if (val > settings.uwl) {
                return settings.lineChart.symbols.warn.upper.size
            } else if (val >= settings.lwl) {
                return settings.lineChart.symbols.good.size
            } else if (val >= settings.lsl) {
                return settings.lineChart.symbols.warn.lower.size
            } else {
                return settings.lineChart.symbols.error.lower.size
            }
        } else if (settings.usl == null) {
            if (val >= settings.lwl) {
                return settings.lineChart.symbols.good.size
            } else if (val > settings.lsl) {
                return settings.lineChart.symbols.warn.lower.size
            } else {
                return settings.lineChart.symbols.error.lower.size
            }
        } else {
            // settings.lsl === null case
            if (val > settings.usl) {
                return settings.lineChart.symbols.error.upper.size
            } else if (val > settings.uwl) {
                return settings.lineChart.symbols.warn.upper.size
            } else {
                return settings.lineChart.symbols.good.size
            }
        }
    } else {
        return 10
    }
}

export const makeSymbolColor = (params, settings, selIds, valueData) => {
    // const val = params.data.v0001
    const val = params.data[1]
    const val_index = params.data[0]
    const targetRefValue = valueData.find((value) => value.part_id === selIds.part_id && value.char_id === selIds.char_id && value.value_id === selIds.value_id)
    if (valueData[val_index - 1].value_id === selIds.value_id) {
        return 'pink'
    }

    if (valueData[val_index - 1].local_status === 1 || valueData[val_index - 1].local_status === 2) {
        if (settings.usl === null && settings.lsl === null) {
            return settings.lineChart.symbols.good.color
        } else if (settings.usl !== null && settings.lsl !== null) {
            if (val > settings.usl) {
                return settings.lineChart.symbols.error.upper.color
            } else if (val > settings.uwl) {
                return settings.lineChart.symbols.warn.upper.color
            } else if (val >= settings.lwl) {
                return settings.lineChart.symbols.good.color
            } else if (val >= settings.lsl) {
                return settings.lineChart.symbols.warn.lower.color
            } else {
                return settings.lineChart.symbols.error.lower.color
            }
        } else if (settings.usl == null) {
            if (val >= settings.lwl) {
                return settings.lineChart.symbols.good.color
            } else if (val > settings.lsl) {
                return settings.lineChart.symbols.warn.lower.color
            } else {
                return settings.lineChart.symbols.error.lower.color
            }
        } else {
            // settings.lsl === null case
            if (val > settings.usl) {
                return settings.lineChart.symbols.error.upper.color
            } else if (val > settings.uwl) {
                return settings.lineChart.symbols.warn.upper.color
            } else {
                return settings.lineChart.symbols.good.color
            }
        }
    } else {
        return 'blue'
    }
}

// export const makeYAxisTop = (settings, usl, center, maxValue) => {
//   const t_usl = usl ?? maxValue
//   const t_center = center ?? 0

//   const maxValues = [t_usl, maxValue].filter((value) => value !== null)

//   // const value = Math.max(t_usl, maxValue)
//   const value = Math.max(...maxValues)

//   let space = Math.abs(t_usl - t_center)
//   let additionalSpace = space * 0.05
//   if (additionalSpace === 0) {
//     additionalSpace = maxValue * 0.05
//   }

//   return value + additionalSpace
// }
// export const makeYAxisBottom = (settings, lsl, center, minValue) => {
//   const t_lsl = lsl ?? minValue
//   const t_center = center ?? 0

//   const minValues = [t_lsl, minValue].filter((value) => value !== null)
//   // const value = Math.min(t_lsl, minValues)
//   const value = Math.min(...minValues)

//   const space = Math.abs(t_lsl - t_center)
//   let additionalSpace = space * 0.05
//   if (additionalSpace === 0) {
//     additionalSpace = minValue * 0.05
//   }

//   return value - additionalSpace
// }

const calculateYAxisSpace = (usl, lsl, maxValue, minValue) => {
    const t_usl = usl ?? maxValue
    const t_lsl = lsl ?? minValue

    const max = Math.max(t_usl, maxValue)
    const min = Math.min(t_lsl, minValue)

    let space = Math.abs(max - min)
    if (space === 0) space = 1

    const additionalSpace = space * 0.1

    return { max, min, additionalSpace }
}

export const makeYAxisTop = (settings, usl, lsl, maxValue, minValue) => {
    const { max, additionalSpace } = calculateYAxisSpace(usl, lsl, maxValue, minValue)
    return max + additionalSpace
}

export const makeYAxisBottom = (settings, usl, lsl, maxValue, minValue) => {
    const { min, additionalSpace } = calculateYAxisSpace(usl, lsl, maxValue, minValue)
    return min - additionalSpace
}

function isCatalogField(checkField) {
    const catalogField = ['v0005', 'v0007', 'v0008', 'v0010', 'v0011', 'v0012']

    return catalogField.includes(checkField)
}

export const calculateAverage = (data) => {
    const sum = data.reduce((acc, value) => acc + value, 0)

    return sum / data.length
}

// Calculate the median
export const calculateMedian = (data) => {
    const sortedData = data.slice().sort((a, b) => a - b)
    const mid = Math.floor(sortedData.length / 2)

    if (sortedData.length % 2 === 0) {
        return (sortedData[mid - 1] + sortedData[mid]) / 2
    } else {
        return sortedData[mid]
    }
}

// Calculate the standard deviation
export const calculateStandardDeviation = (data) => {
    const average = calculateAverage(data)
    const squaredDifferences = data.map((value) => Math.pow(value - average, 2))

    let sumSquaredDifferences = 0
    for (const value of squaredDifferences) {
        sumSquaredDifferences += value
    }
    // const sumSquaredDifferences = squaredDifferences.reduce((acc, value) => acc + value, 0)
    const variance = sumSquaredDifferences / data.length
    const standardDeviation = Math.sqrt(variance)

    return standardDeviation
}

export const findLargestNumber = (arr) => {
    if (!Array.isArray(arr) || arr.length === 0) {
        return undefined // Return undefined for invalid or empty arrays
    }

    return CCUtils.getMax(arr)
}

export const findSmallestNumber = (arr) => {
    if (!Array.isArray(arr) || arr.length === 0) {
        return undefined // Return undefined for invalid or empty arrays
    }

    return CCUtils.getMin(arr)
}

export const convertToChartLine = (settings) => {
    const rtnChartLine = {
        type: settings.lineChart.chartLines.type,
        width: settings.lineChart.chartLines.width,
        color: settings.lineChart.chartLines.color,
    }

    return rtnChartLine
}

export const convertToMarkLine = (settings) => {
    const position = 'end'
    const fontFamily = 'Arial'

    const markLines = [
        {
            show: settings.lsl === null ? false : true,
            name: 'LSL',
            yAxis: settings.lsl,
            label: {
                position: position,
                formatter: function (args) {
                    // return args.name + '(' + parseFloat(args.value).toFixed(settings.decimalPlaces) + ')'
                    return args.name
                },
                fontFamily: fontFamily,
                fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3,
            },
            lineStyle: {
                type: settings.lineChart.markLines.specLimit?.type,
                color: settings.lineChart.markLines.specLimit?.color,
                width: settings.lineChart.markLines.specLimit?.width,
            },
        },
        {
            show: settings.usl === null ? false : true,
            name: 'USL',
            yAxis: settings.usl,
            label: {
                position: position,
                formatter: function (args) {
                    // return args.name + '(' + parseFloat(args.value).toFixed(settings.decimalPlaces) + ')'
                    return args.name
                },
                fontFamily: fontFamily,
                fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3,
            },
            lineStyle: {
                type: settings.lineChart.markLines.specLimit?.type,
                color: settings.lineChart.markLines.specLimit?.color,
                width: settings.lineChart.markLines.specLimit?.width,
            },
        },
        {
            show: settings.lwl === null ? false : true,
            name: 'LWL',
            yAxis: settings.lwl,
            label: {
                position: position,
                formatter: function (args) {
                    // return args.name + '(' + parseFloat(args.value).toFixed(settings.decimalPlaces) + ')'
                    return args.name
                },
                fontFamily: fontFamily,
                fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3,
            },
            lineStyle: {
                type: settings.lineChart.markLines.warnLimit?.type,
                color: settings.lineChart.markLines.warnLimit?.color,
                width: settings.lineChart.markLines.warnLimit?.width,
            },
        },
        {
            show: settings.uwl === null ? false : true,
            name: 'UWL',
            yAxis: settings.uwl,
            label: {
                position: position,
                formatter: function (args) {
                    // return args.name + '(' + parseFloat(args.value).toFixed(settings.decimalPlaces) + ')'
                    return args.name
                },
                fontFamily: fontFamily,
                fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3,
            },
            lineStyle: {
                type: settings.lineChart.markLines.warnLimit?.type,
                color: settings.lineChart.markLines.warnLimit?.color,
                width: settings.lineChart.markLines.warnLimit?.width,
            },
        },
    ]

    const validLines = markLines.filter((line) => {
        if (line.show === true) {
            const { show, ...rest } = line // Remove the 'show' property
            return rest
        }
    })
    return validLines
}
export const convertToMarkArea = (settings) => {
    const markAreas = [
        [
            {
                show: settings.usl === null || settings.yAxisTop === null ? false : true,
            },
            {
                yAxis: settings.usl,
            },
            {
                yAxis: settings.yAxisTop,
                itemStyle: {
                    color: settings.lineChart.markAreas.error.color,
                },
            },
        ],
        [
            {
                show: settings.lsl === null || settings.yAxisBottom === null ? false : true,
            },
            {
                yAxis: settings.lsl,
            },
            {
                yAxis: settings.yAxisBottom,
                itemStyle: {
                    color: settings.lineChart.markAreas.error.color,
                },
            },
        ],
        [
            {
                show: settings.uwl === null || settings.usl === null ? false : true,
            },
            {
                yAxis: settings.uwl,
            },
            {
                yAxis: settings.usl,
                itemStyle: {
                    color: settings.lineChart.markAreas.warn.color,
                },
            },
        ],
        [
            {
                show: settings.lwl === null || settings.lsl === null ? false : true,
            },
            {
                yAxis: settings.lwl,
            },
            {
                yAxis: settings.lsl,
                itemStyle: {
                    color: settings.lineChart.markAreas.warn.color,
                },
            },
        ],
        [
            {
                show: settings.lwl === null || settings.uwl === null ? false : true,
            },
            {
                yAxis: settings.lwl,
            },
            {
                yAxis: settings.uwl,
                itemStyle: {
                    color: settings.lineChart.markAreas.good.color,
                },
            },
        ],
    ]

    const validAreas = markAreas.filter((array) => array.some((item) => item.show === true))

    const removeShowMarkAreas = validAreas.map((innerArray) => innerArray.filter((item) => !item.show))

    return removeShowMarkAreas
}

export const makeSeries = (selIds, char, valueData, settings, configSettings, othersPage) => {
    let dispValue = []
    let isSplitChart = false
    if (settings.page.splitItem.length > 0) {
        isSplitChart = true
        dispValue = makeSeriesDataSplit(settings, valueData)
    } else {
        const flatValues = valueData.flat()
        dispValue = [
            {
                name: dqTranslateMsg('Page_Monitoring_' + 'ValueInfo'),
                value: flatValues.map((value, index) => {
                    return [index + 1, value.v0001]
                }),
            },
        ]
    }

    let seriesData = []

    seriesData.push({
        type: 'line',
        markLine: {
            animation: false,
            symbol: 'none',
            precision: 10,
            // precision: settings.lineChart.markLines.precision,
            data: convertToMarkLine(settings),
            emphasis: {
                disabled: true,
            },
        },
    })

    seriesData.push({
        type: 'line',
        emphasis: {
            disabled: true,
        },
        markArea: {
            data: convertToMarkArea(settings),
        },
    })

    // splited
    // valueData.map((data) =>
    dispValue.map((data, index) =>
        seriesData.push({
            type: 'line',
            name: isSplitChart === true ? dqConvert.convertByCatalog(settings.page.splitItem[0].dataField, data.name.toString(), othersPage.catalogs) : `${data.name}`,
            data: data.value,
            symbol: (value, params) => makeSymbol(value, params, settings, selIds, valueData[0]),
            symbolSize: (value, params) => makeSymbolSize(value, params, settings, selIds, valueData[0]),
            animation: true,
            itemStyle: {
                color: (params) => makeSymbolColor(params, settings, selIds, valueData[0]),
            },
            emphasis: {
                focus: 'series',
            },
            ...(isSplitChart === false
                ? {
                      lineStyle: convertToChartLine(settings),
                  }
                : null),
        })
    )

    return seriesData
}

export function makeSeriesDataSplit(settings, values) {
    const flatValues = values.flat()
    const listItem = flatValues.flat().map((value) => value[settings.page.splitItem[0].dataField])
    const uniqueList = [...new Set(listItem)]

    const valueData = uniqueList.map((item) => {
        return {
            name: item ?? '',
            value: flatValues
                .map((obj, index) => {
                    if (obj[settings.page.splitItem[0].dataField] === item) {
                        return [index + 1, obj.v0001]
                    }
                })
                .filter((item) => item !== undefined),
        }
    })

    console.log(valueData)

    return valueData
}

export function makeValueChartTooltip() {
    return ''
}
