import { Navigate, Route, Routes } from 'react-router-dom'
import appInfo from './app-info'
import routes from './app-routes'
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts'
import { connect, useSelector } from 'react-redux'
import DevelopmentFunctionForm from './pages/development-functions/main-view/DevelopmentFunctionForm'
import { useTheme } from 'src/contexts/theme'
import { useEffect } from 'react'
import themes from 'devextreme/ui/themes'
import { LoadPanel } from 'devextreme-react'

function Content() {
  return (
    <SideNavBarLayout type={'real'} title={appInfo.title}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path={'*'} element={<Navigate to="/database" />} />
      </Routes>
    </SideNavBarLayout>
  )
}

const mapStateToProps = (state) => ({
  // common: state.common,
})

export default connect(mapStateToProps)(Content)
