import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import DevelopmentFunctionList from '../components/DevelopmentFunctionList'
import DevelopmentFunctionStatusBar from '../components/DevelopmentFunctionStatusBar'
import './DevelopmentFunctionForm.scss'

// Styled components
const StyledDevelopmentFunctionList = styled(DevelopmentFunctionList)`
  width: 100%;
  height: 80%;
`

const StyledDevelopmentFunctionStatusBar = styled(DevelopmentFunctionStatusBar)`
  width: 100%;
  height: 20%;
`

// Component
function DevelopmentFunctionForm({ pageKey, common, chartRawData }) {
  console.log('Development function page rendering')

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <StyledDevelopmentFunctionList />
      <StyledDevelopmentFunctionStatusBar />
    </div>
  )
}

// Redux connection
const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(DevelopmentFunctionForm)
