import { Lookup, SelectBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'
import React, { useEffect, useState } from 'react'
import { dqTranslateMsg } from '../dq-convert/DQLanguage'

const SelectBoxSimpleItem = ({ ref, colSpan, label, labelLocation, name, items, valueExpr, displayExpr, value, disabled = false, visible = true, onItemClick }) => {
  return (
    <SimpleItem visible={visible} colSpan={colSpan}>
      <Label text={label} location={labelLocation} />
      <Lookup
        ref={ref}
        name={name}
        dataSource={items}
        showDropDownButton={true}
        dropDownOptions={{
          title: dqTranslateMsg('SidePanel_' + 'SelectableItems'),
          showTitle: false,
          hideOnOutsideClick: true,
          showCloseButton: false,
          maxHeight: 300,
        }}
        value={value}
        width={'auto'}
        valueExpr={valueExpr}
        displayExpr={displayExpr}
        stylingMode="outlined"
        disabled={disabled}
        useNativeScrolling={false}
        onItemClick={onItemClick}
      />
    </SimpleItem>
  )
}

export default SelectBoxSimpleItem
