// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-info-container {
  width: 100%;
  height: calc(100vh - 50px);
}
.company-info-container .company-info-content-container {
  width: 100%;
  height: 83%;
}
.company-info-container .company-copyright-content-container {
  width: 100%;
  height: 17%;
}`, "",{"version":3,"sources":["webpack://./src/pages/info-company/info-company.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,0BAAA;AACF;AACE;EACE,WAAA;EACA,WAAA;AACJ;AAEE;EACE,WAAA;EACA,WAAA;AAAJ","sourcesContent":[".company-info-container {\r\n  width: 100%;\r\n  height: calc(100vh - 50px);\r\n\r\n  .company-info-content-container {\r\n    width: 100%;\r\n    height: 83%;\r\n  }\r\n\r\n  .company-copyright-content-container {\r\n    width: 100%;\r\n    height: 17%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
