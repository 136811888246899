import React from 'react'
import ConfigForm from './components/main-view/ConfigForm'
import './config.scss'

export default function Config(pageKey) {
  return (
    <React.Fragment>
      <div className="config-container">
        <div className="config-content-container">
          <ConfigForm pageKey={pageKey} />
        </div>
      </div>
    </React.Fragment>
  )
}
