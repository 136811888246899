import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export function SettingsLayout(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'histogramChart'
  // const pageState = useSelector((state) => state.common.pages[pageKey])

  // const [layoutColumn, setLayoutColumn] = useState(pageState.page.layout.column)
  // const [layoutRow, setLayoutRow] = useState(pageState.page.layout.row)

  const onNumberBoxLayoutColumnValueChanged = (e) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    // setLayoutColumn(value === null ? minOption : value)
    const next = produce(pageState, (draft) => {
      draft.page.layout.column = value === null ? minOption : value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxLayoutRowValueChanged = (e) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    // setLayoutRow(value === null ? minOption : value)
    const next = produce(pageState, (draft) => {
      draft.page.layout.row = value === null ? minOption : value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  // const onButtonLayoutSaveClicked = (e) => {
  //   const next = produce(pageState, (draft) => {
  //     draft.page.layout.column = layoutColumn
  //     draft.page.layout.row = layoutRow
  //   })

  //   dispatch(ps[pageKey].setPageSettingsToRedux(next))
  // }

  return (
    <GroupItem key={`layout-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'Layout')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Column'),
        labelLocation: 'top',
        name: 'column',
        min: 1,
        max: 5,
        step: 1,
        value: pageState.page.layout.column,
        disabled: false,
        onValueChanged: (e) => onNumberBoxLayoutColumnValueChanged(e),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Row'),
        labelLocation: 'top',
        name: 'row',
        min: 1,
        max: 5,
        step: 1,
        value: pageState.page.layout.row,
        disabled: false,
        onValueChanged: (e) => onNumberBoxLayoutRowValueChanged(e),
      })}
      {/* {ButtonSimpleItem({
        colSpan: 2,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Apply'),
        icon: 'save',
        width: '100%',
        height: '100%',
        onClick: onButtonLayoutSaveClicked,
      })} */}
    </GroupItem>
  )
}
