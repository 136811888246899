import React, { createContext, useContext, useMemo } from 'react'
import { backendPath } from 'src/api/defines/DBInfo'
import { pythonBackendPath } from 'src/api/defines/PythonDBInfo'
import JavaApiClient from 'src/api/JavaApiClient'
import PythonApiClient from 'src/api/PythonApiClient'

// API 클라이언트 컨텍스트를 생성
const ApiClientContext = createContext({})

export const ApiProvider = ({ children }) => {
  // Spring 서버용 API 클라이언트 인스턴스
  const springApiClient = useMemo(() => new JavaApiClient(backendPath), [])

  // 파이썬 서버용 API 클라이언트 인스턴스
  const pythonApiClient = useMemo(
    () => new PythonApiClient(pythonBackendPath),
    []
  )

  // 두 클라이언트 인스턴스를 컨텍스트로 제공
  const value = useMemo(
    () => ({
      spring: springApiClient,
      python: pythonApiClient,
    }),
    [springApiClient, pythonApiClient]
  )

  return (
    <ApiClientContext.Provider value={value}>
      {children}
    </ApiClientContext.Provider>
  )
}

// API 클라이언트 훅을 사용하여 특정 서버의 API 클라이언트에 접근
export const useApi = () => useContext(ApiClientContext)

export default ApiProvider
