import ReactECharts from 'echarts-for-react'
import { useCallback, useEffect, useMemo, useRef, Suspense, lazy, useState } from 'react'
import { useTheme } from 'src/contexts/theme'
import CPUtils from 'src/functions/CommonPageUtils'
import { batch, connect } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './QualityControlChartForm.scss'
import { getRangeChartOptions } from './chart-option/QualityControlRangeChartOption'
import { getXBarChartOptions } from './chart-option/QualityControlXBarChartOption'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { useLanguage } from 'src/contexts/languages'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import { AutoSizer, Grid, CellMeasurerCache } from 'react-virtualized'
import produce from 'immer'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(QualityControlChartForm)

const ApacheEChartQCCComponent = lazy(() => import('../chart/ApacheEChartQCCComponent'))

const styles = {
  chartContainer: {
    padding: '5px',
  },
  chartInnerContainer: {
    width: '100%',
    height: '100%',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    overflow: 'visible',
  },
}

function QualityControlChartForm({ pageKey, common, chartRawData }) {
  const { getTheme } = useTheme()
  const { language } = useLanguage()
  // For initialRendering
  const [isLoadingPanel, setIsLoadingPanel] = useState(false)
  const [chartOptions, setChartOptions] = useState([])

  const qccChartRefs = useRef(null)

  const gridRef = useRef(null)

  const curPage = common.pages.qualityControlChart
  const configPage = common.pages.configuration
  const currentSel = common.current_sel
  const selectedChar = common.loaded_all[pageKey]

  const columnCount = curPage.page.layout.column
  const rowCount = Math.ceil(chartOptions.length / columnCount)

  useEffect(() => {
    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)
    const numCharts = chars.length
    qccChartRefs.current = new Array(numCharts).fill(null).map(() => ({ element: null, chartElement: null, id: null }))

    const processChartOptions = async () => {
      setIsLoadingPanel(true)

      try {
        let qccChartData = []
        if (valuesArranged.length > 0) {
          await Promise.all(
            valuesArranged.map(async (value) => {
              const targetChar = value.charRaw
              const targetValue = value.valueRaw

              if (targetValue.length < 5) {
                qccChartData.push({
                  info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar },
                  targetChar,
                  targetValue,
                  error: { name: 'DataIsNotEnough', extra: targetValue.length },
                })
                return
              }

              qccChartData.push({ info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar }, targetChar, targetValue, error: { name: null, extra: null } })
            })
          )
          qccChartData.sort((a, b) => {
            if (a.info.part_id !== b.info.part_id) {
              return a.info.part_id - b.info.part_id
            } else {
              return a.info.char_id - b.info.char_id
            }
          })

          const chartOptions = qccChartData.map((data) => {
            const targetChar = data.targetChar
            const targetValue = data.targetValue

            if (data.error.name !== null) {
              return {
                info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar },
                option: { xBar: null, range: null },
                error: { name: data.error.name, extra: data.error.extra },
              }
            }

            const xBar = getXBarChartOptions(curPage, configPage, targetChar, targetValue)
            const range = getRangeChartOptions(curPage, configPage, targetChar, targetValue)

            return { info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar }, option: { xBar, range }, error: { name: null, extra: null } }
          })

          batch(() => {
            setChartOptions(chartOptions)
          })
        } else {
          batch(() => {
            setChartOptions([])
          })
        }
      } catch (error) {
        console.error('Error fetching chart options:', error)
      } finally {
        setIsLoadingPanel(false)
      }
    }

    processChartOptions()
  }, [selectedChar, curPage, configPage])

  useEffect(() => {
    const targetRefId = `${currentSel.part_id}_${currentSel.char_id}`
    const targetIndex = chartOptions.findIndex((chart) => `${chart.info.part_id}_${chart.info.char_id}` === targetRefId)

    if (targetIndex !== -1 && gridRef.current) {
      const rowIndex = Math.floor(targetIndex / columnCount)
      const columnIndex = targetIndex % columnCount

      gridRef.current.scrollToCell({ columnIndex, rowIndex })

      setTimeout(() => {
        const targetElement = qccChartRefs?.current[targetIndex]?.element
        if (targetElement) {
          targetElement.classList.add('target-element')
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })

          // 애니메이션이 끝난 후 클래스를 제거합니다.
          targetElement.addEventListener(
            'animationend',
            () => {
              targetElement.classList.remove('target-element')
            },
            { once: true }
          )
        }
      }, 0)
    }
  }, [currentSel, qccChartRefs, chartOptions, columnCount])

  const renderCell = useCallback(({ columnIndex, rowIndex, style, key, parent }) => {
    const index = rowIndex * columnCount + columnIndex
    if (index >= chartOptions.length) return null

    const chart = chartOptions[index]
    return (
      <div
        key={`${chart.info.part_id}_${chart.info.char_id}`}
        ref={(e) => {
          qccChartRefs.current[index].element = e
          qccChartRefs.current[index].id = `${chart.info.part_id}_${chart.info.char_id}`
        }}
        style={{ ...style, padding: '3px' }} // Apply padding for the gap
      >
        <div style={styles.chartInnerContainer}>
          <Suspense fallback={<div>{dqTranslateMsg('Common_' + 'LoadPanelLoading')}</div>}>
            {chart.option.xBar === null && chart.option.range === null ? (
              <PageWarningMessage
                fontSize={(curPage.page.fontSize / (curPage.page.layout.row + curPage.page.layout.column)) * 5}
                message={
                  `${chart.info.charReference.c2002 ?? 'empty'} P${chart.info.part_id ?? '?'}/C${chart.info.char_id ?? '?'}` +
                  ' - ' +
                  dqTranslateMsg('Page_HistogramChart_' + chart.error.name) +
                  ` (${chart.error.extra})`
                }
              />
            ) : (
              <ApacheEChartQCCComponent key={`${chart.info.part_id}_${chart.info.char_id}_chart`} info={chart.info} option={chart.option} theme={getTheme()} pageKey={pageKey} />
            )}
          </Suspense>
        </div>
      </div>
    )
  })

  return (
    <div className={'dx-theme-background-color'}>
      <DQLoadPanel open={isLoadingPanel} message={dqTranslateMsg('Common_' + 'LoadPanelLoading')} />
      <div className="chart-form-content" style={{ width: '100%', height: '100%' }}>
        {chartOptions.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <Grid
                ref={gridRef} // Assign the ref to the Grid component
                className="custom-scrollbar"
                columnCount={columnCount}
                columnWidth={width / curPage.page.layout.column} // Adjust column width to account for the margin
                height={height}
                rowCount={rowCount}
                rowHeight={height / curPage.page.layout.row} // Adjust row height to account for the margin
                width={width}
                cellRenderer={renderCell}
                style={{ overflowX: 'hidden', overflowY: 'auto' }}
              />
            )}
          </AutoSizer>
        ) : null}
      </div>
    </div>
  )
}
