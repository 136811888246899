import React, { useEffect, useRef } from 'react'
import { EmptyItem, GroupItem } from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DateBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/DateBoxSimpleItem'
import { format, parseISO } from 'date-fns'

function formatDateBoxValue(dateString) {
  const year = parseInt(dateString.substring(0, 4), 10)
  const month = parseInt(dateString.substring(4, 6), 10) - 1 // Month is 0-indexed
  const day = parseInt(dateString.substring(6, 8), 10)
  const hour = parseInt(dateString.substring(8, 10), 10)
  const minute = parseInt(dateString.substring(10, 12), 10)
  const second = parseInt(dateString.substring(12, 14), 10)

  return new Date(year, month, day, hour, minute, second)
}

const DateTimeOption = ({ currentFilterMode }) => {
  const dispatch = useDispatch()
  const pageKey = 'database'
  const pageState = useSelector((state) => state.common.pages.database)

  const onCheckBoxEnableDateTimeValueChanged = (e, dataField) => {
    if (e.event === undefined) return

    try {
      const value = e.value
      const next = produce(pageState, (draft) => {
        draft.filters[currentFilterMode].others[dataField] = value
        // Ensure useEasyLoad is set to false only if value is true
        if (value) {
          draft.filters[currentFilterMode].others.useEasyLoad = false
        }
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    } catch (error) {
      console.error('Error in onCheckBoxEnableDateTimeValueChanged:', error)
    }
  }

  const onDateBoxStartDateTimeValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const selectedDate = new Date(value)
    const converted = format(selectedDate, 'yyyyMMddHHmmss')
    const endTime = parseInt(pageState.filters[currentFilterMode].common.endDateTime)

    if (parseInt(converted) > endTime) {
      // Reset to previous value and show an error
      const oldValue = e.previousValue
      e.component.option('value', oldValue)
      alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgInvalidDateTime'))
    } else {
      const next = produce(pageState, (draft) => {
        draft.filters[currentFilterMode].common[dataField] = converted
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  const onDateBoxEndDateTimeValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const selectedDate = new Date(value)
    const converted = format(selectedDate, 'yyyyMMddHHmmss')
    const startTime = parseInt(pageState.filters[currentFilterMode].common.startDateTime)

    if (startTime > parseInt(converted)) {
      // Show an error if end time is less than start time
      alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgInvalidDateTime'))
      // Optionally reset to previous value if needed
      const oldValue = e.previousValue
      e.component.option('value', oldValue)
    } else {
      const next = produce(pageState, (draft) => {
        draft.filters[currentFilterMode].common[dataField] = converted
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  return (
    <GroupItem key={`date-time-option-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DateTime')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Enable'),
        labelLocation: 'right',
        name: 'useDateTime',
        value: pageState.filters[currentFilterMode].others.useDateTime,
        disable: false,
        onValueChanged: (e) => onCheckBoxEnableDateTimeValueChanged(e, 'useDateTime'),
      })}
      <EmptyItem />
      {DateBoxSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'StartDateTime'),
        labelLocation: 'top',
        name: 'startDateTime',
        pickerType: 'calendar',
        displayFormat: 'yyyy-MM-dd HH:mm:ss',
        value: formatDateBoxValue(pageState.filters[currentFilterMode].common.startDateTime),
        disabled: pageState.filters[currentFilterMode].others.useDateTime === false,
        onValueChanged: (e) => onDateBoxStartDateTimeValueChanged(e, 'startDateTime'),
      })}
      {DateBoxSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'EndDateTime'),
        labelLocation: 'top',
        name: 'endDateTime',
        pickerType: 'calendar',
        displayFormat: 'yyyy-MM-dd HH:mm:ss',
        value: formatDateBoxValue(pageState.filters[currentFilterMode].common.endDateTime),
        disabled: pageState.filters[currentFilterMode].others.useDateTime === false,
        onValueChanged: (e) => onDateBoxEndDateTimeValueChanged(e, 'endDateTime'),
      })}
    </GroupItem>
  )
}

export default DateTimeOption
