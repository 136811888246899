import JwtUtils from 'src/functions/JwtUtils'
import { backendPath } from './defines/DBInfo'

const path = backendPath

// ** UPLOAD DATA APIS
export const uploadFileData = async (user, file) => {
    const company = user.user_company
    const plant = user.user_plant
    const group = user.user_group

    return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('company', company)
        formData.append('plant', plant)
        formData.append('group', group)
        formData.append('file', file.File)
        formData.append('codepage', file.Encoding)

        const accessToken = JwtUtils.loadToken()

        fetch(path + 'data/uploadFileData', {
            method: 'POST',
            headers: {
                Authorization: `${accessToken}`,
            },
            body: formData,
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Network response was not ok.')
                }
            })
            .then((result) => resolve(result))
            .catch((error) => reject(error))
    })
}
