import { batch, useSelector } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import ReactECharts from 'echarts-for-react'
import { useEffect, useRef, useState } from 'react'
import CircleButton from 'src/functions/CircleButton'

const styles = {
  chartContainer: {
    padding: '5px',
  },
  chartInnerContainer: {
    width: '100%',
    height: '100%',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    overflow: 'visible',
  },
}

const getViewPortTheme = (pageKey, theme) => (theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light')

function ScrollBoardValueChartPresentation(props) {
  // const { chartOptions, mode } = props
  const { mode, isPlaying, isValueChartOperating, chartOptions, timerRefValueChart } = props
  const { getTheme } = useTheme()
  const theme = getTheme()
  const pageKey = 'scrollBoard'

  const sbPageSettings = useSelector((state) => state.common.pages.scrollBoard)
  const configSettings = useSelector((state) => state.common.pages.configuration)

  const [cutOptions, setCutOptions] = useState([])
  const [currentBatch, setCurrentBatch] = useState(0)

  const totalCharts = sbPageSettings.page.autoPageValueChartLayoutX * sbPageSettings.page.autoPageValueChartLayoutY
  const totalBatches = Math.ceil(cutOptions.length / totalCharts)

  const width = `${100 / sbPageSettings.page.autoPageValueChartLayoutX}%`
  const height = `${100 / sbPageSettings.page.autoPageValueChartLayoutY}%`

  useEffect(() => {
    const newCutOptions = chartOptions.slice(0, chartOptions.length)
    setCutOptions(newCutOptions)
  }, [chartOptions, sbPageSettings.page.autoPageValueChartLayoutX, sbPageSettings.page.autoPageValueChartLayoutY])

  useEffect(() => {
    if (cutOptions.length > 0) {
      if (timerRefValueChart.current) {
        clearInterval(timerRefValueChart.current)
      }

      isValueChartOperating.current = true
      timerRefValueChart.current = setInterval(() => {
        if (mode === 'manual' || isPlaying === false) {
          isValueChartOperating.current = false
          clearInterval(timerRefValueChart.current)
          setCurrentBatch(0)
          return
        }

        batch(() => {
          setCurrentBatch((prevBatch) => {
            const nextBatch = prevBatch + 1
            if (nextBatch >= totalBatches) {
              isValueChartOperating.current = false
              clearInterval(timerRefValueChart.current)
              // setCurrentBatch(0)
              return prevBatch
            }
            return nextBatch
          })
        })
      }, 1000 * sbPageSettings.page.autoPageUpdateTime)

      return () => {
        if (timerRefValueChart.current) {
          clearInterval(timerRefValueChart.current)
          setCurrentBatch(0)
        }
      }
    }
  }, [cutOptions, isValueChartOperating, sbPageSettings.page.autoPageValueChartLayoutX, sbPageSettings.page.autoPageValueChartLayoutY])

  const getBatchedOptions = () => {
    const totalCharts = sbPageSettings.page.autoPageValueChartLayoutX * sbPageSettings.page.autoPageValueChartLayoutY
    const start = currentBatch * totalCharts
    const end = start + totalCharts
    return cutOptions.slice(start, end)
  }

  const batchedOptions = getBatchedOptions()

  if (batchedOptions.length > 0) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: '95%', padding: '5px' }}>
          {batchedOptions.map((chart, index) => {
            return (
              <div
                key={index}
                style={{
                  ...styles.chartContainer,
                  width,
                  height,
                  padding: '5px',
                }}
              >
                <ReactECharts style={{ width: '100%', height: '100%' }} notMerge={true} option={chart.option} theme={getViewPortTheme(pageKey, theme)} />
              </div>
            )
          })}
        </div>
        <div style={{ width: '100%', height: '5%', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircleButton text={currentBatch + 1} /> <span style={{ fontSize: '20px' }}> / </span> <CircleButton text={totalBatches} />
        </div>
      </div>
    )
  }
}

export default ScrollBoardValueChartPresentation
