// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.value-summary-list-mater-detail-container {
  width: 100%;
  height: 100%;
}
.value-summary-list-mater-detail-container .master-detail-caption {
  padding: 0 0 0px 0px;
  font-size: 14px;
  font-weight: bold;
}
.value-summary-list-mater-detail-container .value-summary-list-master-detail {
  width: 100%;
  height: 100%;
  padding: 0px 0px 0px 0px;
}
.value-summary-list-mater-detail-container .value-summary-list-master-detail-deviation-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.value-summary-list-mater-detail-container .value-summary-list-cell-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#styled-deviation-chart {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/list-value-summary/components/main-view/ValueSummaryListMasterDetail.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,oBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,wBAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;;AAMA;EACE,WAAA;EACA,YAAA;AAHF","sourcesContent":[".value-summary-list-mater-detail-container {\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  .master-detail-caption {\r\n    padding: 0 0 0px 0px;\r\n    font-size: 14px;\r\n    font-weight: bold;\r\n  }\r\n\r\n  .value-summary-list-master-detail {\r\n    width: 100%;\r\n    height: 100%;\r\n    padding: 0px 0px 0px 0px;\r\n  }\r\n\r\n  .value-summary-list-master-detail-deviation-container {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n\r\n  .value-summary-list-cell-container {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n}\r\n\r\n#styled-deviation-chart {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
