// ** Toolkit imports
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
// ** Reducers
import chartRawData from 'src/store/charts-raw-data'
import common from 'src/store/common'

// ** Store
export const store = configureStore({
  reducer: {
    chartRawData,
    common,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
