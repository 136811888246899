import './AdminPushDataType.scss'
import React from 'react'
import { pageName } from 'src/functions/constants/pageName'
import PushSettingsDBInfo from './db-info/PushSettingsDBInfo'
import PushSettingsRibbon from './ribbon/PushSettingsRibbon'
import PushSettingsEncoding from './encoding/PushSettingsEncoding'
import PushSettingsFindDistribution from './find-distribution/PushSettingsFindDistribution'
import PushSettingsCommonSettings from './common-settings/PushSettingsCommonSettings'
import PushSettingsUseFields from './use-fields/PushSettingsUseFields'
import PushSettingsCatalogs from './catalogs/PushSettingsCatalogs'
import PushSettingsLanguage from './language/PushSettingsLanguage'
import PushSettingsPermissions from './permissions/PushSettingsPermissions'

function AdminPushDataType(props) {
    const { type } = props.details

    return (
        <div
            className='dx-theme-background-color'
            // style={{ width: '100%', height: '100%' }}
        >
            {type === 1 && <PushSettingsDBInfo {...props} />}
            {type === 2 && <PushSettingsRibbon {...props} />}
            {/* {type === 3 && <PushSettingsEncoding {...props} />} */}
            {type === 4 && <PushSettingsFindDistribution {...props} />}
            {type === 5 && <PushSettingsCommonSettings {...props} />}
            {type === 6 && <PushSettingsLanguage {...props} />}
            {type === 7 && <PushSettingsPermissions {...props} />}
            {type === 8 && <PushSettingsUseFields {...props} />}
            {type === 9 && <PushSettingsCatalogs {...props} />}
        </div>
    )
}

export default AdminPushDataType
