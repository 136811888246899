import { Grid } from '@mui/material'
import { Form } from 'devextreme-react'
import { Button } from 'devextreme-react/button'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useApi } from 'src/contexts/api'
import { useAuth } from 'src/contexts/auth'
import DQToast from 'src/functions/DQToast'
import { groupTbDescription as groupTbDesc } from 'src/functions/constants/groupDBFieldDescription'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import './PushSettingsCommonSettings.scss'
import SettingsDisplayValueOption from './components/SettingsDisplayValueOption'
import SettingsOutlierOption from './components/SettingsOutlierOption'
import SettingsProcessCapability from './components/SettingsProcessCapability'
import { configurationSettingsPrm, defaultCommonSettings } from 'src/pages/config/components/settings/ConfigSettings'

export default function PushSettingsCommonSettings(props) {
    const dispatch = useDispatch()
    const { spring } = useApi() // Use spring API client from the context
    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const parentRef = useRef(null)
    const toastRef = useRef(null)

    const stateAll = props.state
    const state = props.state.commonSettings
    const setState = props.setState

    const [tempState, setTempState] = useState(state)

    // const catalogs = testData
    const handleSaveButton = () => {
        const next = produce(stateAll, (draft) => {
            draft.commonSettings = tempState
        })
        setState(next)
        // dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'configuration', configPage)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }

    const handleResetButton = async () => {
        const next = produce(stateAll, (draft) => {
            draft.commonSettings = defaultCommonSettings
        })

        setState(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    const items = {
        length: 3,
        // Display Value Option
        list_1: [SettingsDisplayValueOption({ state, stateAll, setState }), null, null, null, null],
        list_2: [SettingsProcessCapability({ state, stateAll, setState }), null, null, null, null],
        list_3: [SettingsOutlierOption({ state, stateAll, setState }), null, null, null, null],
    }

    return (
        <div className='common-settings-container'>
            <DQLoadPanel open={loading} />
            <DQToast ref={toastRef} />
            <div className='common-settings-control-container'>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='save'
                        text={dqTranslateMsg('Page_Configuration_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            {/* <div className="common-settings-content-container"> */}
            <Grid
                container
                spacing={0}
            >
                <Grid
                    item
                    key={'common_settings_1'}
                    style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}
                >
                    <Form
                        id='configuration-form'
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        showColonAfterLabel={false}
                        labelLocation={'top'}
                    >
                        {items.list_1}
                    </Form>
                </Grid>
                <Grid
                    item
                    key={'common_settings_2'}
                    style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}
                >
                    <Form
                        id='configuration-form'
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        showColonAfterLabel={false}
                        labelLocation={'top'}
                    >
                        {items.list_2}
                    </Form>
                </Grid>
                <Grid
                    item
                    key={'common_settings_3'}
                    style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}
                >
                    <Form
                        id='configuration-form'
                        style={{ paddingTop: '5px' }}
                        labelMode={'outside'}
                        showColonAfterLabel={false}
                        labelLocation={'top'}
                    >
                        {items.list_3}
                    </Form>
                </Grid>
            </Grid>
            {/* </div> */}
        </div>
    )
}
