import * as DBUtils from 'src/functions/DatabaseUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'

export const getQccChartSubData = (chartType, settings, configSettings, char, value, calcCallback) => {
  const { takeoverFromDataset, handlingIncompleteSubgroup, locationChartType, calculationType, calculationCount } = settings.page

  const qccLimitMode = takeoverFromDataset
  const natEnable = configSettings.commonSettings.useNaturalBoundary
  let subGroupType, subGroupCount, subGroupHandleType

  subGroupHandleType = handlingIncompleteSubgroup
  if (qccLimitMode) {
    subGroupType = ETCUtils.getValue(DBUtils.getFieldData(char, 'c8501'))
    if (ETCUtils.checkNull(subGroupType)) {
      subGroupType = calculationType
    }
  } else {
    subGroupType = calculationType
  }

  if (qccLimitMode) {
    subGroupCount = ETCUtils.getValue(DBUtils.getFieldData(char, 'c8500'))
    if (ETCUtils.checkNull(subGroupCount)) {
      subGroupCount = calculationCount
    }
  } else {
    subGroupCount = calculationCount
  }

  const seriesData = calcCallback(locationChartType, subGroupType, subGroupCount, subGroupHandleType, value)
  const values = seriesData.map((data) => data[1])
  const { sum, length } = PCUtils.getSum(values)

  let ucl, lcl, average, stdDev
  if (qccLimitMode) {
    if (chartType === 'xBar') {
      average = DBUtils.getFieldData(char, 'c8011') || null
      lcl = DBUtils.getFieldData(char, 'c8012') || null
      ucl = DBUtils.getFieldData(char, 'c8013') || null
      if (!(lcl || ucl)) {
        console.warn('takeover mode, but one of ucl lcl is empty.')
        average = PCUtils.getAverage(sum, length)
        stdDev = PCUtils.getStandardDeviation(values, average)
        ucl = PCUtils.getUcl(average, stdDev)
        lcl = PCUtils.getLcl(average, stdDev)
        ucl = PCUtils.getTypeUpperWarningLimit(char) === 2 ? null : ucl
        lcl = PCUtils.getTypeLowerWarningLimit(char) === 2 ? null : lcl
        // ucl = natEnable && PCUtils.getTypeUpperWarningLimit(char) === 2 ? null : ucl
        // lcl = natEnable && PCUtils.getTypeLowerWarningLimit(char) === 2 ? null : lcl
      }
    } else {
      average = DBUtils.getFieldData(char, 'c8111') || null
      lcl = DBUtils.getFieldData(char, 'c8112') || null
      ucl = DBUtils.getFieldData(char, 'c8113') || null
      if (!(lcl || ucl)) {
        console.warn('takeover mode, but one of ucl lcl is empty.')
        average = PCUtils.getAverage(sum, length)
        stdDev = PCUtils.getStandardDeviation(values, average)
        ucl = PCUtils.getUcl(average, stdDev)
        lcl = PCUtils.getLcl(average, stdDev)
      }
    }
  } else {
    console.warn('normal mode')
    average = PCUtils.getAverage(sum, length)
    stdDev = PCUtils.getStandardDeviation(values, average)
    ucl = PCUtils.getUcl(average, stdDev)
    lcl = PCUtils.getLcl(average, stdDev)
  }

  return { lcl, ucl, average, seriesData, values }
}

export const getScrollBoardQccChartSubData = (chartType, settings, configSettings, char, value, calcCallback) => {
  const { takeoverFromDataset, handlingIncompleteSubgroup, locationChartType, calculationType, calculationCount } = settings.page.qualityControlChart

  const qccLimitMode = takeoverFromDataset
  const natEnable = configSettings.commonSettings.useNaturalBoundary
  let subGroupType, subGroupCount, subGroupHandleType

  subGroupHandleType = handlingIncompleteSubgroup
  if (qccLimitMode) {
    subGroupType = ETCUtils.getValue(DBUtils.getFieldData(char, 'c8501'))
    if (ETCUtils.checkNull(subGroupType)) {
      subGroupType = calculationType
    }
  } else {
    subGroupType = calculationType
  }

  if (qccLimitMode) {
    subGroupCount = ETCUtils.getValue(DBUtils.getFieldData(char, 'c8500'))
    if (ETCUtils.checkNull(subGroupCount)) {
      subGroupCount = calculationCount
    }
  } else {
    subGroupCount = calculationCount
  }

  const seriesData = calcCallback(locationChartType, subGroupType, subGroupCount, subGroupHandleType, value)
  const values = seriesData.map((data) => data[1])
  const { sum, length } = PCUtils.getSum(values)

  let ucl, lcl, average, stdDev
  if (qccLimitMode) {
    if (chartType === 'xBar') {
      average = DBUtils.getFieldData(char, 'c8011') || null
      lcl = DBUtils.getFieldData(char, 'c8012') || null
      ucl = DBUtils.getFieldData(char, 'c8013') || null
      if (!(lcl || ucl)) {
        console.warn('takeover mode, but one of ucl lcl is empty.')
        average = PCUtils.getAverage(sum, length)
        stdDev = PCUtils.getStandardDeviation(values, average)
        ucl = PCUtils.getUcl(average, stdDev)
        lcl = PCUtils.getLcl(average, stdDev)
        ucl = PCUtils.getTypeUpperWarningLimit(char) === 2 ? null : ucl
        lcl = PCUtils.getTypeLowerWarningLimit(char) === 2 ? null : lcl
      }
    } else {
      average = DBUtils.getFieldData(char, 'c8111') || null
      lcl = DBUtils.getFieldData(char, 'c8112') || null
      ucl = DBUtils.getFieldData(char, 'c8113') || null
      if (!(lcl || ucl)) {
        console.warn('takeover mode, but one of ucl lcl is empty.')
        average = PCUtils.getAverage(sum, length)
        stdDev = PCUtils.getStandardDeviation(values, average)
        ucl = PCUtils.getUcl(average, stdDev)
        lcl = PCUtils.getLcl(average, stdDev)
      }
    }
  } else {
    console.warn('normal mode')
    average = PCUtils.getAverage(sum, length)
    stdDev = PCUtils.getStandardDeviation(values, average)
    ucl = PCUtils.getUcl(average, stdDev)
    lcl = PCUtils.getLcl(average, stdDev)
  }

  return { lcl, ucl, average, seriesData, values }
}
