import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import produce from 'immer'
import { databaseSettingsPrm } from 'src/pages/database/components/settings/DatabaseSettings'
import CommonOption from './components/settings/CommonOption'
import DateTimeOption from './components/settings/DateTimeOption'
import LastNFilterOption from './components/settings/LastNFilterOption'
import ETCOption from './components/settings/ETCOption'
import SidePanelTemplate from 'src/template/side-panel/SidePanelTemplate'
import { handleSaveButtonClicked, handleResetButtonClicked } from 'src/template/side-panel/SidePanelTemplateHelper'
import { useRef } from 'react'

export default function SidePanelDatabaseSettings({ pageKey }) {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const toastRef = useRef(null)

  const common = useSelector((state) => state.common)
  const curPage = common.pages[pageKey]

  const saveCallback = () => handleSaveButtonClicked(dispatch, user, pageKey, curPage, curPage, toastRef)
  const resetCallback = () => handleResetButtonClicked(dispatch, user, pageKey, curPage, databaseSettingsPrm, toastRef)

  const currentFilterMode = curPage.page.filterMode
  const formComponents = [CommonOption({ currentFilterMode }), DateTimeOption({ currentFilterMode }), LastNFilterOption({ currentFilterMode }), ETCOption({ currentFilterMode })]

  return <SidePanelTemplate pageKey={pageKey} formComponents={formComponents} saveCallback={saveCallback} resetCallback={resetCallback} toastRef={toastRef} />
}
