// PythonApiClient.js
import ApiClient from './ApiClient'

class PythonApiClient extends ApiClient {
  constructor(baseUrl) {
    super(baseUrl)
  }

  // Python 서버에 특화된 메소드 추가 가능
  async getPythonSpecificData(endpoint) {
    // 특별한 로직 추가
    return this.get(endpoint)
  }
}

export default PythonApiClient
