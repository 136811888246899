import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'

export default function ThemeQCCMarkArea() {
    const dispatch = useDispatch()
    const pageKey = 'scrollBoard'

    const pageState = useSelector((state) => state.common.pages[pageKey])

    const onColorBoxMarkAreaValueChanged = (e, type, dataField) => {
        if (e.event === undefined) return
        const value = e.value
        const next = produce(pageState, (draft) => {
            if (!draft.theme.qualityControlChart.lineChart.markAreas[type]) {
                draft.theme.qualityControlChart.lineChart.markAreas[type] = {} // Initialize the key if it doesn't exist
            }
            draft.theme.qualityControlChart.lineChart.markAreas[type][dataField] = value
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <GroupItem
            key={`theme-mark-area-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'MarkArea')}
        >
            {/* {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'NG'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.theme.qualityControlChart.lineChart.markAreas.error.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxMarkAreaValueChanged(e, 'error', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Warn'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.theme.qualityControlChart.lineChart.markAreas.warn.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxMarkAreaValueChanged(e, 'warn', 'color'),
      })} */}
            {ColorBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'Good'),
                labelLocation: 'top',
                name: 'color',
                value: pageState.theme.qualityControlChart.lineChart.markAreas.good.color,
                disabled: false,
                editAlphaChannel: true,
                onValueChanged: (e) => onColorBoxMarkAreaValueChanged(e, 'good', 'color'),
            })}
            {ColorBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'OutOfControl'),
                labelLocation: 'top',
                name: 'color',
                value: pageState.theme.qualityControlChart.lineChart.markAreas.outOfControl?.color,
                disabled: false,
                editAlphaChannel: true,
                onValueChanged: (e) => onColorBoxMarkAreaValueChanged(e, 'outOfControl', 'color'),
            })}
        </GroupItem>
    )
}
