// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-board-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 50px);
}`, "",{"version":3,"sources":["webpack://./src/pages/scroll-board/scroll-board.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,0BAAA;AACF","sourcesContent":[".scroll-board-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  width: 100%;\r\n  height: calc(100vh - 50px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
