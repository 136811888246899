// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
}
.dx-theme-background-color .admin-account-grid-container {
  width: 60%;
  height: 100%;
}
.dx-theme-background-color .admin-account-grid-container {
  width: 100%;
  height: 100%;
}
.dx-theme-background-color .admin-account-grid-container .dx-header-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  height: 26px;
  font-size: 13px;
  vertical-align: middle;
}
.dx-theme-background-color .admin-account-grid-container .dx-group-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  vertical-align: middle;
}
.dx-theme-background-color .admin-account-grid-container .dx-data-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  height: 26px;
  font-size: 13px;
  vertical-align: middle;
}
.dx-theme-background-color .admin-account-settings-container {
  padding: 5px;
  width: 20%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/account/AdminAccount.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;AACF;AACE;EACE,UAAA;EACA,YAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;AADJ;AAIE;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;AAFJ;AAKE;EACE,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;AAHJ;AAME;EACE,YAAA;EACA,UAAA;EACA,YAAA;AAJJ","sourcesContent":[".dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n  display: flex;\r\n\r\n  .admin-account-grid-container {\r\n    width: calc(100% / 5 * 3);\r\n    height: 100%;\r\n  }\r\n\r\n  .admin-account-grid-container {\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n\r\n  .admin-account-grid-container .dx-header-row td {\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    height: 26px;\r\n    font-size: 13px;\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .admin-account-grid-container .dx-group-row td {\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    font-size: 12px;\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .admin-account-grid-container .dx-data-row td {\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    height: 26px;\r\n    font-size: 13px;\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .admin-account-settings-container {\r\n    padding: 5px;\r\n    width: calc(100% / 5);\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
