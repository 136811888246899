import React from 'react'
import { GroupItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
export function ThemeBoxPlot(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'boxPlotChart'
  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onColorBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.boxPlotChart[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`box-plot-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'Box')}>
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'BorderColor'),
        labelLocation: 'top',
        name: 'borderColor',
        value: pageState.boxPlotChart.borderColor,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'borderColor'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'InnerColor'),
        labelLocation: 'top',
        name: 'innerColor',
        value: pageState.boxPlotChart.innerColor,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxValueChanged(e, 'innerColor'),
      })}
    </GroupItem>
  )
}
