import { defValueField } from 'src/default/field/value'
import CatalogUtils from 'src/functions/CatalogUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import {
    Column,
    ColumnChooser,
    ColumnChooserSearch,
    ColumnChooserSelection,
    DataGrid,
    Editing,
    Export,
    Item,
    Lookup,
    Pager,
    Paging,
    Position,
    Scrolling,
    SearchPanel,
    Toolbar,
} from 'devextreme-react/data-grid'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CPUtils from 'src/functions/CommonPageUtils'

function renderWarning(numericValue, char, configSettings) {
    return (
        <div className='value-list-cp-cpk-container'>
            <div style={{ color: 'red' }}>{DNUtils.valueDisplay(numericValue, char, configSettings)}</div>
            <img
                src={'/images/color/warning-exclamation.svg'}
                width='15'
                height='15'
                alt='Warning'
            />
        </div>
    )
}

function renderValue(numericValue, char, configSettings) {
    return (
        <div className='value-list-cp-cpk-container'>
            <div style={{ fontSize: '12px' }}>{DNUtils.valueDisplay(numericValue, char, configSettings)}</div>
        </div>
    )
}

function handleValueRender(e, dataField, lsl, usl, char, settings, configSettings, catalogs) {
    const value = e.data?.[dataField] // Use optional chaining to safely access the data field

    if (value === undefined) return

    if (!dataField.includes('v0001')) {
        let convertedValue = value

        if (dataField.includes('v0004')) {
            // Use the utility function to convert the value to a date-time format
            convertedValue = CPUtils.getDisplayDateTimeSec(settings.page.dateTimeExtraFieldDisplayFormat, value)
        } else {
            const splitted = dataField.split('_')
            const field = splitted[splitted.length - 1]

            if (CatalogUtils.isCatalogField(field)) {
                // Use the utility function to convert the value based on the catalog
                convertedValue = CatalogUtils.convertByCatalog(field, value, catalogs)
            }
        }

        return (
            <div className='value-list-cp-cpk-container'>
                <div style={{ fontSize: '12px' }}>{convertedValue !== 'NaN' ? convertedValue : null}</div>
            </div>
        )
    }

    const numericValue = Number(value) // Convert the value to a number once

    if (isNaN(numericValue)) {
        // Return an empty container if the value is NaN
        return (
            <div className='value-list-cp-cpk-container'>
                <div style={{ fontSize: '12px' }}></div>
            </div>
        )
    }

    if (lsl === null && usl === null) {
        return renderValue(numericValue, char, configSettings)
    }

    if (lsl !== null && usl !== null) {
        if (numericValue < lsl || numericValue > usl) {
            return renderWarning(numericValue, char, configSettings)
        }
        return renderValue(numericValue, char, configSettings)
    }

    if (lsl !== null && usl === null) {
        if (numericValue < lsl) {
            return renderWarning(numericValue, char, configSettings)
        }
        return renderValue(numericValue, char, configSettings)
    }

    if (usl !== null && numericValue > usl) {
        return renderWarning(numericValue, char, configSettings)
    }

    return renderValue(numericValue, char, configSettings)
}

const handleLookupValueChanged = (e, dataField) => {
    // Ensure the display value is correctly set after a value change
    const { component, value } = e
    const gridInstance = component._options.editing.changes // Get the instance of the grid
    const rowIndex = e.row.rowIndex
    const columnIndex = component.columnIndex

    const displayValue = othersPage.catalogs[dataField].find((item) => item.no === value)?.desc

    if (gridInstance) {
        // Update the grid cell with the display value
        gridInstance[rowIndex][columnIndex] = displayValue
        component.refresh()
    }
}

const makeCharColumn = (groupIndex, char, values, settings, configSettings, othersPage, viewData) => {
    if (values.valueRaw.length > 0) {
        // values 배열이 비어있지 않은 경우에만 접근
        const mainBandKey = `part_id-${char.part_id},char_id-${char.char_id}` // Band를 구분할 고유 키

        return (
            <Column
                key={`group-main-header-${groupIndex}`}
                name={mainBandKey}
                cssClass={groupIndex % 2 === 0 ? 'even-column' : 'odd-column'}
                dataField={char.c2002}
                caption={char.c2002}
                width={'auto'}
                alignment='center'
                isBand={true}
            >
                {viewData.map((view, index) => {
                    const upperFirstLetter = view.charAt(0).toUpperCase() + view.slice(1)

                    const dataField = values.valueRaw[0].part_id + '_' + values.valueRaw[0].char_id + '_' + view

                    const keySplitted = dataField.split('_')
                    const targetField = keySplitted[keySplitted.length - 1]
                    const targetInfo = defValueField().find((item) => item.value === targetField)
                    return (
                        <Column
                            key={`group-sub-header-${groupIndex}-${index}`}
                            name={`group-sub-name-${groupIndex}-${index}`}
                            cssClass={groupIndex % 2 === 0 ? 'even-column' : 'odd-column'}
                            dataField={dataField}
                            caption={dqTranslateMsg('Db_field_' + 'Value_' + upperFirstLetter)}
                            dataType={targetInfo.dataType}
                            visibleIndex={index}
                            minWidth={100}
                            allowReordering={true}
                            // renderAsync={true}
                            ownerBand={mainBandKey}
                            cellRender={(cellData) => handleValueRender(cellData, dataField, char.c2110, char.c2111, char, settings, configSettings, othersPage.catalogs)}
                        >
                            {CatalogUtils.isCatalogField(targetField) ? (
                                <Lookup
                                    dataSource={othersPage.catalogs[targetField]}
                                    valueExpr='no'
                                    displayExpr='desc'
                                    onValueChanged={(e) => handleLookupValueChanged(e, dataField)}
                                />
                            ) : null}
                        </Column>
                    )
                })}
            </Column>
        )
    } else {
        return null
    }
}

export const columns = (charArray, valuesArranged, curPage, configPage, othersPage, viewData) => {
    return charArray
        .map((char, index) => {
            const targetValue = valuesArranged.find((valueArray) => valueArray.part_id === char.part_id && valueArray.char_id === char.char_id)

            if (targetValue) {
                return makeCharColumn(index, char, targetValue, curPage, configPage, othersPage, viewData)
            }
            return null // Explicitly return null for clarity
        })
        .filter((item) => item !== null)
}

export const parseTargetValueItems = (configSettings, char, values, viewData) => {
    let charValues = []
    let decimalPlaces = char.c2022

    if (decimalPlaces === null) {
        decimalPlaces = configSettings.commonSettings.displayDecimalPlaces
    } else if (decimalPlaces < 0) {
        decimalPlaces = 0
    } else if (decimalPlaces > 15) {
        decimalPlaces = 15
    }

    charValues = values.map((value) => {
        let extractedValues = {}

        for (const property of viewData) {
            if (property === 'v0001') {
                extractedValues[value.part_id + '_' + value.char_id + '_' + property] = value[property]
            } else {
                if (CatalogUtils.isCatalogField(property)) {
                    extractedValues[value.part_id + '_' + value.char_id + '_' + property] = value[property]
                } else {
                    extractedValues[value.part_id + '_' + value.char_id + '_' + property] = value[property]
                }
            }
        }

        return extractedValues
    })

    return charValues
}

export const onExporting = (e, valuesArranged) => {
    const arranged = valuesArranged.sort((a, b) => {
        // Ensure both part_id values exist and are numbers
        const partIdA = Number(a.part_id)
        const partIdB = Number(b.part_id)

        if (!Number.isFinite(partIdA) || !Number.isFinite(partIdB)) {
            throw new Error('part_id must be a finite number')
        }

        return partIdA - partIdB
    })

    if (arranged) {
        const fileName = arranged[0].partRaw.p1001 + '_' + arranged[0].partRaw.p1002

        if (e.format === 'xlsx') {
            const workbook = new Workbook()
            const worksheet = workbook.addWorksheet('Main sheet')
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`)
                })
            })
        }
    }
}

export const handleCellPrepared = (e) => {
    if (e && e.rowType === 'header') {
        e.cellElement.style.textAlign = 'center'
    }
}

export const handleSaveState = (datagridRef, saveState) => {
    if (datagridRef.current) {
        const state = datagridRef.current.instance.state()
        saveState(state)
    }
}

export const handleLoadState = (datagridRef, loadState) => {
    if (datagridRef.current) {
        const state = loadState()
        if (state) {
            datagridRef.current.instance.state(state)
        }
    }
}

export const handleClearState = (datagridRef, clearState) => {
    clearState()
    if (datagridRef.current) {
        const emptyState = {
            columns: [],
            sorting: [],
            grouping: [],
            selectionFilter: [],
            pageIndex: 0,
            pageSize: 10,
        }
        datagridRef.current.instance.state(emptyState)
    }
}

export const handleContentReady = (e, currentSel, pageKey, datagridRef) => {}
