// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}
.dx-theme-background-color .chart-form-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

/* Target element with relative positioning */
.target-element {
  position: relative;
  border-radius: 10px; /* 둥근 테두리를 설정합니다. */
  overflow: hidden; /* 가상 요소가 경계를 넘어서지 않도록 합니다. */
}

/* Pseudo-element for background with animation */
.target-element::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lime; /* Initial background color */
  opacity: 0.3; /* Initial opacity */
  animation: highlightBackground 1s ease forwards;
  pointer-events: none; /* Prevents the pseudo-element from capturing mouse events */
}

@keyframes highlightBackground {
  to {
    background-color: transparent; /* Ends with a transparent background */
    opacity: 0; /* Ends with the pseudo-element being fully transparent */
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/chart-histogram/components/main-view/HistogramChartForm.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAGA,6CAAA;AACA;EACE,kBAAA;EACA,mBAAA,EAAA,mBAAA;EACA,gBAAA,EAAA,6BAAA;AAAF;;AAGA,iDAAA;AACA;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA,EAAA,6BAAA;EACA,YAAA,EAAA,oBAAA;EACA,+CAAA;EACA,oBAAA,EAAA,4DAAA;AAAF;;AAGA;EACE;IACE,6BAAA,EAAA,uCAAA;IACA,UAAA,EAAA,yDAAA;EAAF;AACF","sourcesContent":["@charset \"UTF-8\";\n.dx-theme-background-color {\n  width: 100%;\n  height: 100%;\n  padding: 5px;\n  display: flex;\n  flex-wrap: wrap;\n}\n.dx-theme-background-color .chart-form-content {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  overflow-y: auto;\n}\n\n/* Target element with relative positioning */\n.target-element {\n  position: relative;\n  border-radius: 10px; /* 둥근 테두리를 설정합니다. */\n  overflow: hidden; /* 가상 요소가 경계를 넘어서지 않도록 합니다. */\n}\n\n/* Pseudo-element for background with animation */\n.target-element::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: lime; /* Initial background color */\n  opacity: 0.3; /* Initial opacity */\n  animation: highlightBackground 1s ease forwards;\n  pointer-events: none; /* Prevents the pseudo-element from capturing mouse events */\n}\n\n@keyframes highlightBackground {\n  to {\n    background-color: transparent; /* Ends with a transparent background */\n    opacity: 0; /* Ends with the pseudo-element being fully transparent */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
