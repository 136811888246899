import ContextMenu, { Position } from 'devextreme-react/context-menu'
import List from 'devextreme-react/list'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
// import userIcon from '../../../public/images/user-icon.png'
import { useAuth } from '../../contexts/auth'
import './UserPanel.scss'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth()
  const navigate = useNavigate()

  function navigateToProfile() {
    navigate('/profile')
  }
  const menuItems = useMemo(
    () => [
      {
        text: 'Profile',
        icon: 'user',
        onClick: navigateToProfile,
      },
      {
        // text: dqTranslateMsg('Logout'),
        text: 'Logout',
        icon: 'runner',
        onClick: signOut,
      },
    ],
    [signOut]
  )
  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div className={'user-image'} />
        </div>
        <div className={'user-name'}>{user.user_id}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position
            my={{ x: 'center', y: 'top' }}
            at={{ x: 'center', y: 'bottom' }}
          />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List
          className={'dx-toolbar-menu-action'}
          items={menuItems}
          noDataText={dqTranslateMsg('NoData')}
        />
      )}
    </div>
  )
}
