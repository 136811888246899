import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'

export default function ProcessCapabilityLimit() {
  const dispatch = useDispatch()
  const pageKey = 'summaryList'

  const pageState = useSelector((state) => state.common.pages[pageKey])

  const onRadioGroupValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value === null ? minOption : value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`process-capability-limit-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'Criteria')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'CPGood'),
        labelLocation: 'top',
        name: 'cpGood',
        min: 0,
        max: 10,
        step: 0.1,
        value: pageState.page.cpGood,
        disabled: false,
        onValueChanged: (e) => onRadioGroupValueChanged(e, 'cpGood'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'CPKGood'),
        labelLocation: 'top',
        name: 'cpkGood',
        min: 0,
        max: 10,
        step: 0.1,
        value: pageState.page.cpkGood,
        disabled: false,
        onValueChanged: (e) => onRadioGroupValueChanged(e, 'cpkGood'),
      })}
    </GroupItem>
  )
}
