import { useEffect, useState } from 'react'

const useStepper = (stepReference, handleHomePage, handleStepperBackMode, handleStepperNextMode) => {
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    setActiveStep(stepReference.curStep)
  }, [stepReference])

  const handleHome = () => {
    setActiveStep(0)
    handleHomePage()
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0))
    handleStepperBackMode()
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep + 1, 0))
    handleStepperNextMode()
  }

  return {
    activeStep,
    handleHome,
    handleBack,
    handleNext,
    isLastStep: activeStep === stepReference.totalSteps - 1,
    isFirstStep: activeStep === 0,
  }
}

export default useStepper
