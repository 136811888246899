// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  height: 100%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}
.dx-theme-background-color .chart-form-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.dx-theme-background-color .probability-plot-error-center-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dx-theme-background-color .probability-plot-error-center-content .probability-plot-error-icon {
  height: 30px;
  float: right;
}
.dx-theme-background-color .probability-plot-error-center-content .probability-plot-error-text {
  width: 50%;
  height: 30px;
  text-align: left;
  font-family: "Arial";
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/chart-probability-plot/components/main-view/ProbabilityPlotChartForm.scss"],"names":[],"mappings":"AACA;EAEE,YAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAFJ;AAII;EACE,YAAA;EACA,YAAA;AAFN;AAII;EACE,UAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;EACA,eAAA;AAFN","sourcesContent":["// .box-plot-chart-form-container {\r\n.dx-theme-background-color {\r\n  // width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n\r\n  .chart-form-content {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    overflow-y: auto;\r\n  }\r\n\r\n  .probability-plot-error-center-content {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: flex-end;\r\n\r\n    .probability-plot-error-icon {\r\n      height: 30px;\r\n      float: right;\r\n    }\r\n    .probability-plot-error-text {\r\n      width: 50%;\r\n      height: 30px;\r\n      text-align: left;\r\n      font-family: 'Arial';\r\n      font-size: 20px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
