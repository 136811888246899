import React, { useEffect, useState } from 'react'
import { EmptyItem, GroupItem } from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { batch, useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'

const AdvancedLastNFilterOption = (currentFilter, setCurrentFilter) => {
  const isNFilter = currentFilter.others.useEasyLoad
  const nFilterCount = currentFilter.common.easyLoadValue
  const nFilterType = currentFilter.others.easyLoadValueType
  const isSaved = true

  const handleNumberBoxNFilterCountChange = (e) => {
    let count = 0
    if (nFilterType === 'week' && e.value > 10000) {
      count = 10000
    } else if (nFilterType === 'day' && e.value > 10000 * 7) {
      count = 10000 * 7
    } else if (nFilterType === 'hour' && e.value > 10000 * 7 * 24) {
      count = 10000 * 7 * 24
    } else {
      count = e.value
    }
    const minOption = e.component.option('min')

    const next = produce(currentFilter, (draft) => {
      draft.common.easyLoadValue = count === null ? minOption : count
    })
    setCurrentFilter(next)
    // setNFilterCount(count === null ? minOption : count)
  }

  const handleSelectBoxNFilterTypeChange = (e) => {
    const type = e.itemData.value
    let count = 0
    if (type === 'week' && nFilterCount > 10000) {
      count = 10000
    } else if (type === 'day' && nFilterCount > 10000 * 7) {
      count = 10000 * 7
    } else if (type === 'hour' && nFilterCount > 10000 * 7 * 24) {
      count = 10000 * 7 * 24
    } else {
      count = nFilterCount
    }

    batch(() => {
      const next = produce(currentFilter, (draft) => {
        draft.common.easyLoadValue = count
        draft.others.easyLoadValueType = type
      })
      setCurrentFilter(next)
    })
  }

  const handleCheckBoxEnableLastNFilterChange = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value

    batch(() => {
      const next = produce(currentFilter, (draft) => {
        draft.others[dataField] = value
        if (value) {
          draft.others.useDateTime = false
        }
      })
      setCurrentFilter(next)
    })
  }

  return (
    <GroupItem key={`last-n-filter-option-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'LastNFilter')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Enable'),
        labelLocation: 'right',
        name: 'useEasyLoad',
        value: isNFilter,
        disable: false,
        onValueChanged: (e) => handleCheckBoxEnableLastNFilterChange(e, 'useEasyLoad'),
      })}
      <EmptyItem />
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Count'),
        labelLocation: 'top',
        name: 'easyLoadValue',
        min: 1,
        max: 100000000,
        step: 1,
        value: nFilterCount,
        disabled: !isNFilter,
        onValueChanged: handleNumberBoxNFilterCountChange,
      })}
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Type'),
        labelLocation: 'top',
        name: 'easyLoadValueType',
        items: sd.easyLoadTypeDefine(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: nFilterType,
        disabled: !isNFilter,
        onItemClick: handleSelectBoxNFilterTypeChange,
      })}
      {/* {ButtonSimpleItem({
        colSpan: 2,
        type: isSaved ? 'normal' : 'danger',
        text: dqTranslateMsg('SidePanel_' + 'Apply'),
        icon: 'parentfolder',
        disabled: !isNFilter,
        onClick: handleApplyButtonClick,
      })} */}
    </GroupItem>
  )
}

export default AdvancedLastNFilterOption
