import React from 'react'
import { CircularProgress, Backdrop, Typography, Box } from '@mui/material'
import { useTheme } from 'src/contexts/theme'

const getViewPortTheme = (theme) => {
  return theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'
}

export default function DQLoadPanel(props) {
  const { getTheme } = useTheme()
  const theme = getTheme()
  const { open, refContainer = null, message = null } = props

  return (
    <Backdrop
      sx={{
        zIndex: 3000,
        color: '#fff',
      }}
      open={open}
      container={refContainer ? refContainer.current : window}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center', // Center content vertically
          backgroundColor: getViewPortTheme(theme) === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)', // Dark background for the pane
          padding: '20px',
          width: 200,
          height: 130,
          borderRadius: '8px',
        }}
      >
        <CircularProgress
          sx={{
            zIndex: 10001, // Ensure this is higher than other elements
          }}
        />
        {message && (
          <Typography
            variant="body1"
            sx={{
              marginTop: '16px',
              textAlign: 'center', // Center text horizontally
              color: getViewPortTheme(theme) === 'dark' ? 'black' : 'white', // Dark background for the pane
            }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Backdrop>
  )
}
