import { Lookup, SelectBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'
import React, { useEffect, useState } from 'react'
import { dqTranslateMsg } from '../dq-convert/DQLanguage'

const SelectBoxNoValueSimpleItem = ({ ref, label, labelLocation, name, items, valueExpr, displayExpr, showDropDownButton, listWidth = null, defaultValue, onValueChanged }) => {
  return (
    <SimpleItem>
      <Label text={label} location={labelLocation} />
      <Lookup
        ref={ref}
        name={name}
        dataSource={items}
        showDropDownButton={showDropDownButton}
        dropDownOptions={{
          title: dqTranslateMsg('SidePanel_' + 'SelectableItems'),
          showTitle: false,
          hideOnOutsideClick: true,
          showCloseButton: false,
          maxHeight: 300,
          width: listWidth,
        }}
        defaultValue={defaultValue}
        width={'auto'}
        valueExpr={valueExpr}
        displayExpr={displayExpr}
        useNativeScrolling={false}
        onItemClick={onValueChanged}
      />
    </SimpleItem>
  )
}

export default SelectBoxNoValueSimpleItem
