import { Button } from 'devextreme-react/button'
import RadioGroup from 'devextreme-react/radio-group'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { summaryListSettingsPrm } from 'src/pages/list-summary/components/settings/SummaryListSettings'
import { setSummaryListPage } from 'src/store/common'
import * as sd from '../../../components/side-panel-form/component/define/SettingsDefine'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import ResultIcon from './components/theme/ResultIcon'
import OutOfTolerancesIcon from './components/theme/OutOfTolerancesIcon'
import DQToast from 'src/functions/DQToast'
import DQSaveReset from 'src/functions/DQSaveReset'
import { Form, ScrollView } from 'devextreme-react'
import './SidePanelSummaryListTheme.scss'

export default function SidePanelSummaryListTheme(props) {
  const { pageKey } = props
  const dispatch = useDispatch()

  const [scrollPosition, setScrollPosition] = useState(0)
  const { user } = useAuth()
  const parentRef = useRef(null)
  const toastRef = useRef(null)

  const common = useSelector((state) => state.common)
  const curPage = common.pages.summaryList
  const configPage = common.pages.configuration

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }
  const handleSaveButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }
  const handleResetButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.entries(summaryListSettingsPrm.lineChart).forEach(([key, value]) => {
        draft.lineChart[key] = value
      })
      Object.entries(summaryListSettingsPrm.theme).forEach(([key, value]) => {
        draft.theme[key] = value
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  const handleScroll = (e) => {
    setScrollPosition(e.scrollOffset.top)
  }

  const items = (pageState, configState) => [ResultIcon(pageKey, pageState, configState), OutOfTolerancesIcon(pageKey, pageState, configState)]

  return (
    <div ref={parentRef} style={{ padding: '5px', width: '100%', height: '100%' }}>
      <DQToast ref={toastRef} />
      <div style={{ width: '100%', height: '3%' }}>
        <DQSaveReset saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')} resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')} saveCallback={handleSaveButtonClicked} resetCallback={handleResetButtonClicked} />
      </div>
      <div style={{ width: '100%', height: '97%' }}>
        <ScrollView
          className="side-panel-tab-container"
          showScrollbar={'onHover'}
          useNative={false}
          onScroll={handleScroll}
          onContentReady={(e) => {
            e.component.scrollTo({ top: scrollPosition })
          }}
        >
          <Form id="side-panel-summary-list-theme-form" style={{ paddingTop: '5px' }} labelMode={'outside'} formData={curPage.page} showColonAfterLabel={false}>
            {items(curPage, configPage)}
          </Form>
        </ScrollView>
      </div>
    </div>
  )
}
