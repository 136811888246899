// import React from 'react'

// export default function SettingsReportMode() {
//   return <div>SettingsReportMode</div>
// }

import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as reportConstant from '../../../constants/SettingsConstants'

const reportMode = () => [
  { value: 'editor', text: dqTranslateMsg('SidePanel_' + reportConstant.reportModeKeyEditorMode) },
  { value: 'user', text: dqTranslateMsg('SidePanel_' + reportConstant.reportModeKeyUserMode) },
]

export default function SettingsReportMode(pageKey) {
  const dispatch = useDispatch()
  const pageState = useSelector((state) => state.common.pages[pageKey])
  const onRadioGroupCalculationTypeValueChanged = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`report-mode-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + reportConstant.reportModeKey)}>
      {RadioGroupSimpleItem({
        ref: null,
        colSpan: 2,
        label: '',
        labelLocation: 'top',
        name: 'reportMode',
        items: reportMode(),
        layout: 'horizontal',
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.reportMode,
        // renderCustomItem: renderCustomItem,
        // disabled: pageState.page.displayMethod === 'heatMap',
        onValueChanged: (e) => onRadioGroupCalculationTypeValueChanged(e, 'reportMode'),
      })}
    </GroupItem>
  )
}
