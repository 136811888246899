import { EmptyItem, GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import CCUtils from 'src/functions/CommonCalcUtils'

export default function ThemePlausibilityLimit(pageKey, pageState, configState) {
  const dispatch = useDispatch()

  const onSelectBoxLimitTypeValueChanged = (e, type, dataField) => {
    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      if (!draft.lineChart.markLines[type]) {
        draft.lineChart.markLines[type] = {} // Initialize the key if it doesn't exist
      }
      draft.lineChart.markLines[type][dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxLimitWidthValueChanged = (e, type, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      if (!draft.lineChart.markLines[type]) {
        draft.lineChart.markLines[type] = {} // Initialize the key if it doesn't exist
      }
      draft.lineChart.markLines[type][dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxLimitColorValueChanged = (e, type, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      if (!draft.lineChart.markLines[type]) {
        draft.lineChart.markLines[type] = {} // Initialize the key if it doesn't exist
      }
      draft.lineChart.markLines[type][dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`plausibility-limit-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'PlausibilityLimit')}>
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Type'),
        labelLocation: 'top',
        name: 'shape',
        items: sd.lineTypeDefine(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.lineChart.markLines.plausibilityLimit?.type,
        disabled: false,
        onItemClick: (e) => onSelectBoxLimitTypeValueChanged(e, 'plausibilityLimit', 'type'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Width'),
        labelLocation: 'top',
        name: 'size',
        min: 1,
        max: 20,
        step: 1,
        value: pageState.lineChart.markLines.plausibilityLimit?.width,
        onValueChanged: (e) => onSelectBoxLimitWidthValueChanged(e, 'plausibilityLimit', 'width'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'Color'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markLines.plausibilityLimit?.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onSelectBoxLimitColorValueChanged(e, 'plausibilityLimit', 'color'),
      })}
    </GroupItem>
  )
}
