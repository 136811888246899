export const defaultFindDistribution = {
    useSavedDistribution: false,
    useMeasuredQuantity: false,
    useDefaultDistribution: false,
    oneSideDefaultDistribution: 0,
    twoSideDefaultDistribution: 0,
    allowMeasuredQuantity: [[202, 1]],
    reservedMeasuredQuantity: [],
    measuredQuantitySettingPair: [],
    defaultDistributionType: 0,
}

export const defaultUseFieldList = {
    part: [
        {
            ID: 1,
            configuration: true,
            value: 'p1001',
            text: 'P1001',
            visible: true,
            dataType: 'string',
        },
        {
            ID: 2,
            configuration: true,
            value: 'p1002',
            text: 'P1002',
            visible: true,
            dataType: 'string',
        },
    ],
    char: [
        {
            ID: 1,
            configuration: true,
            value: 'c2001',
            text: 'C2001',
            visible: true,
            dataType: 'string',
        },
        {
            ID: 2,
            configuration: true,
            value: 'c2002',
            text: 'C2002',
            visible: true,
            dataType: 'string',
        },
    ],
    value: [
        {
            ID: 1,
            configuration: true,
            value: 'v0001',
            text: 'V0001',
            visible: true,
            dataType: 'number',
        },
        {
            ID: 3,
            configuration: true,
            value: 'v0004',
            text: 'V0004',
            visible: true,
            dataType: 'string',
        },
        {
            ID: 4,
            configuration: true,
            value: 'v0005',
            text: 'V0005',
            visible: true,
            dataType: 'string',
        },
        {
            ID: 5,
            configuration: true,
            value: 'v0006',
            text: 'V0006',
            visible: true,
            dataType: 'string',
        },
        {
            ID: 6,
            configuration: true,
            value: 'v0007',
            text: 'V0007',
            visible: true,
            dataType: 'number',
        },
        {
            ID: 7,
            configuration: true,
            value: 'v0008',
            text: 'V0008',
            visible: true,
            dataType: 'number',
        },
        {
            ID: 9,
            configuration: true,
            value: 'v0010',
            text: 'V0010',
            visible: true,
            dataType: 'number',
        },
        {
            ID: 11,
            configuration: true,
            value: 'v0012',
            text: 'V0012',
            visible: true,
            dataType: 'number',
        },
        {
            ID: 12,
            configuration: true,
            value: 'v0014',
            text: 'V0014',
            visible: true,
            dataType: 'string',
        },
        {
            ID: 15,
            configuration: true,
            value: 'v0053',
            text: 'V0053',
            visible: true,
            dataType: 'string',
        },
    ],
}

export const defaultCatalogs = {
    v0005: [
        {
            no: '1',
            desc: 'Tool Breakage',
        },
        {
            no: '2',
            desc: 'Tool Wear',
        },
        {
            no: '3',
            desc: 'Scheduled Tool Change',
        },
        {
            no: '4',
            desc: 'Machine break',
        },
        {
            no: '999',
            desc: 'Confirmed',
        },
    ],
    v0007: [
        {
            no: 1,
            desc: 'Cavity1',
        },
        {
            no: 2,
            desc: 'Cavity2',
        },
        {
            no: 3,
            desc: 'Cavity3',
        },
    ],
    v0008: [
        {
            no: 1,
            desc: 'Johnny',
        },
        {
            no: 2,
            desc: 'Brian',
        },
        {
            no: 3,
            desc: 'Alex',
        },
    ],
    v0010: [
        {
            no: 1,
            desc: 'Machine1',
        },
        {
            no: 2,
            desc: 'Machine2',
        },
        {
            no: 3,
            desc: 'Machine3',
        },
        {
            no: 4,
            desc: 'Machine4',
        },
        {
            no: 5,
            desc: 'Machine5',
        },
        {
            no: 6,
            desc: 'Machine6',
        },
        {
            no: 7,
            desc: 'Machine7',
        },
    ],
    v0011: [],
    v0012: [
        {
            no: 1,
            desc: 'CMM01',
        },
        {
            no: 2,
            desc: 'CMM02',
        },
        {
            no: 3,
            desc: 'CMM03',
        },
        {
            no: 4,
            desc: 'CMM04',
        },
        {
            no: 5,
            desc: 'CMM05',
        },
        {
            no: 6,
            desc: 'CMM06',
        },
    ],
}

export const defaultCommonSettings = {
    displayFieldNameWithNumber: false,
    warningLimitPercentage: 20,
    warningLimitNatPercentage: 10,
    displayDecimalPlaces: 3,
    calcDisplayDecimalPlaces: 3,
    isExponentialActive: 10,
    sigmaDegree: 3,
    usePlausibilityFunc: false,
    useScrapFunc: false,
    useNaturalBoundary: false,
    useAdjustNormalTolerances: false,
    tolerancesPercentage: 100,
}

export const defaultEncoding = {
    part_key_field: ['p1001', 'p1002'],
    char_key_field: ['c2001', 'c2002'],
}
export const configurationSettingsPrm = {
    encoding: defaultEncoding,
    // useFieldList: defaultUseFieldList,
    // catalogs: defaultCatalogs,
    commonSettings: defaultCommonSettings,
    findDistribution: defaultFindDistribution,
    fontFamily: 'dq-font-1',
    menuIconType: 'monoBlue',
    theme: 'generic.light.compact',
    swatchTheme: 'standard', // linked
    language: 'en',
}

export const configurationSettings = {
    configuration: {
        name: 'configuration',
        label: 'Configuration',
        settings: configurationSettingsPrm,
    },
    // language: 'en',
}
