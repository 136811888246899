import React from 'react'
import { RadioGroup } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'

const RadioGroupSimpleItem = ({ colSpan, label, labelLocation, name, value, items, itemRender, layout, valueExpr, displayExpr, disabled, onValueChanged }) => {
  return (
    <SimpleItem colSpan={colSpan}>
      <Label text={label} location={labelLocation} />
      <RadioGroup
        name={name}
        layout={layout}
        disabled={disabled}
        value={value}
        items={items}
        itemRender={itemRender}
        onValueChanged={onValueChanged}
        valueExpr={valueExpr}
        displayExpr={displayExpr}
        width="100%"
      />
    </SimpleItem>
  )
}

export default RadioGroupSimpleItem
