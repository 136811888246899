import { Form } from 'devextreme-react'
import { GroupItem } from 'devextreme-react/form'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'
import QuickCommonOption from './quick/QuickCommonOption'
import QuickDateTimeOption from './quick/QuickDateTimeOption'
import QuickLastNFilterOption from './quick/QuickLastNFilterOption'
import QuickETCOption from './quick/QuickETCOption'
import QuickOption from './quick/QuickOption'
import QuickSaveFilter from './quick/QuickSaveFilter'

const DBFieldCreatePopupFilter = (
    pageKey,
    pageState,
    configState,
    othersPage,
    isVisibleCreateFilterPopup,
    setIsVisibleCreateFilterPopup,
    popupCreateFilter,
    setPopupCreateFilter,
    partRefPopup,
    charRefPopup,
    valueRefPopup
) => {
    const dispatch = useDispatch()
    const textBoxRef = useRef(null)

    const closePopup = () => {
        setIsVisibleCreateFilterPopup(false)
        setPopupCreateFilter(null)
    }

    const renderFilterContent = () => {
        if (popupCreateFilter === null) {
            return null
        }

        return (
            <Form
                id={`side-panel-form`}
                style={{ width: '100%', height: '100%', paddingTop: '10px' }}
                labelMode={'outside'}
                showColonAfterLabel={false}
            >
                {[
                    QuickCommonOption(popupCreateFilter, setPopupCreateFilter),
                    QuickDateTimeOption(popupCreateFilter, setPopupCreateFilter),
                    QuickLastNFilterOption(popupCreateFilter, setPopupCreateFilter),
                    QuickETCOption(popupCreateFilter, setPopupCreateFilter),
                    QuickOption(
                        popupCreateFilter,
                        setPopupCreateFilter,
                        isVisibleCreateFilterPopup,
                        setIsVisibleCreateFilterPopup,
                        pageKey,
                        pageState,
                        configState,
                        othersPage,
                        partRefPopup,
                        charRefPopup,
                        valueRefPopup
                    ),
                    QuickSaveFilter(popupCreateFilter, setPopupCreateFilter, setIsVisibleCreateFilterPopup, textBoxRef, pageKey, pageState, configState, othersPage),
                ]}
            </Form>
        )
    }

    const title = 'Create Filter'
    const width = '30%'
    const height = 'auto'

    return (
        <GroupItem
            key={`db-field-advanced-create-popup-filter-group-item`}
            colCount={2}
            caption={''}
        >
            {PopupSimpleItem({ visible: isVisibleCreateFilterPopup, onHiding: closePopup, title, width, height, contentRender: renderFilterContent })}
        </GroupItem>
    )
}

export default DBFieldCreatePopupFilter
