export function valueRectColor(lsl, usl, value) {
  if (value > usl || value < lsl) {
    return 'red'
  } else {
    return 'green'
  }
}

export function valueConvertForDisplay(lsl, usl, value) {
  if (value < usl && value > lsl) {
    return value
  } else {
    if (value > usl) {
      return usl
    } else {
      return lsl
    }
  }
}
