import DataGrid, {
    ColumnChooserSearch,
    ColumnChooserSelection,
    Column as DataGridColumn,
    SearchPanel as DataGridSearchPanel,
    Editing,
    Export,
    Item,
    Position,
    Scrolling,
    Toolbar,
} from 'devextreme-react/data-grid'
import TreeList, { ColumnChooser, HeaderFilter, Selection, Column as TreeListColumn, SearchPanel as TreeListSearchPanel } from 'devextreme-react/tree-list'
import produce from 'immer'
import { useAuth } from 'src/contexts/auth'
import { convertLanguageStructureForDatagrid, convertLanguageStructureForTreeList, dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
// import { setLanguageEn, setLanguageJp, setLanguageKr } from 'src/store/common'
import { Button, CheckBox, Toast } from 'devextreme-react'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLanguage } from 'src/contexts/languages.js'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { adminSettingsPrm, defaultLanguage } from '../../settings/AdminSettings.js'
import './AdminLanguage.scss'
import { getAdminGroup } from 'src/api/admin/AdminGroupApi.js'
import { makeGetAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper.js'
import { companyPlantTbDescription as companyPlantTbDesc } from 'src/functions/constants/companyPlantDBfieldDescription'
import { useApi } from 'src/contexts/api.js'
import DQToast from 'src/functions/DQToast'
import { getAdminCompany, updateAdminCompany } from 'src/api/admin/AdminCompanyApi.js'
import { makeGetAdminCompanyPlantListPrms, makeGetAdminCompanyPrms, makeUpdateAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper.js'
import { makeAdminCompanyToDTOFormat } from 'src/dto/DTOImpl.js'
import * as rd from 'src/store/common'

const expandedKeys = []

export default function AdminLanguage({ pageKey }) {
    const { user } = useAuth()
    const { spring } = useApi()
    const { updateLanguage } = useLanguage()
    const dispatch = useDispatch()
    const [isDefaultSettings, setIsDefaultSettings] = useState(true)

    const dataGridRef = useRef(null)
    const toastRef = useRef(null)
    const common = useSelector((state) => state.common)
    const othersPage = common.pages.others
    const adminPage = common.pages.adminSettings
    const settings = adminPage

    const treeListDataSource = convertLanguageStructureForTreeList(common.pages.others.language)
    const dataSource = convertLanguageStructureForDatagrid(common.pages.others.language)

    const handleSaveButton = async () => {
        const updateCompanyPlantSettings = async (element, field, pageValue) => {
            const company = element.company
            const plant = element.plant
            const dataJsonStr = JSON.stringify(pageValue)

            const updateData = {
                [field]: dataJsonStr,
            }

            await updateAdminCompany(spring, makeUpdateAdminCompanyPrms(company, plant, updateData))
        }

        if (isDefaultSettings) {
            const element = {
                company: user.user_company,
                plant: user.user_plant,
            }

            await updateCompanyPlantSettings(element, companyPlantTbDesc.language, othersPage.language)
        }
        dispatch(rd.setLanguageData(othersPage.language))
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }

    const handleResetButton = async () => {
        const company = user?.user_company
        const plant = user?.user_plant
        const group = user?.user_group

        if (!company || !plant || !group) {
            console.error('company plant group info is invalid')
            return
        }
        const targetPlantInfo = await getAdminCompany(spring, makeGetAdminCompanyPrms(company, plant))
        const adminCompanyDTO = makeAdminCompanyToDTOFormat(targetPlantInfo)
        const languageDataStr = adminCompanyDTO[companyPlantTbDesc.language]

        let languageData = null
        if (languageDataStr === '' || languageDataStr === null) {
            console.error('plant language data is empty')
            languageData = defaultLanguage
        } else {
            languageData = JSON.parse(languageDataStr)
        }
        console.log(`plant language :  <${languageData}>`)

        const next = produce(adminPage, (draft) => {
            draft.language = languageData
        })

        updateLanguage(next.language)
        dispatch(rd.setLanguageData(next.language))
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    // const handleResetButton = async () => {
    //     const next = produce(othersPage, (draft) => {
    //         draft.language = defaultLanguage
    //     })

    //     updateLanguage(next.language)
    //     dispatch(rd.setLanguageData(next.language))
    //     // dqsImpl.setCurBaseSettings(dispatch, user, null, 'adminSettings', next)
    //     toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    // }
    const handleCheckboxChange = (e) => {
        setIsDefaultSettings(e.value)
    }

    const handleRowUpdating = (e) => {
        const oldData = e.oldData
        const modified = e.newData
        if (modified != null) {
            const refData = e.key
            const next = produce(othersPage, (draft) => {
                if (refData.page === '-') {
                    Object.entries(modified).forEach((pair) => {
                        draft.language[refData.type].data[pair[0]][refData.key] = pair[1]
                    })
                } else {
                    Object.entries(modified).forEach((pair) => {
                        draft.language[refData.type][refData.page].data[pair[0]][refData.key] = pair[1]
                    })
                }
            })

            console.log(next)
            updateLanguage(next.language)
            dispatch(rd.setLanguageData(next.language))
            // dispatch(ps.adminSettings.setPageSettingsToRedux(next))
        }
    }

    const handleTreeListSelectionChanged = (e) => {
        const selectedData = e.component.getSelectedRowsData('all')

        const dataGrid = dataGridRef.current.instance
        const filter = []

        if (selectedData.length > 0) {
            const selectedItem = e.selectedRowsData[0]
            const node = e.component.getNodeByKey(selectedItem.id)
            if (node && node.parent !== undefined) {
                const parentNode = e.component.getNodeByKey(node.parent.key)
                if (parentNode && parentNode.level !== -1) {
                    // Now you have access to the parent node's data
                    console.log('Parent Node Data:', parentNode.data)
                    filter.push(['type', '=', parentNode.data.text])
                    filter.push('and')
                    filter.push(['page', '=', selectedItem.text])
                } else {
                    filter.push(['type', '=', selectedItem.text])
                }
            }
        }

        dataGrid.filter(filter)
    }

    const cellRenderer = (data) => {
        const getIconClass = (menuType) => {
            if (menuType === 'Header') {
                return 'dx-icon-header'
            } else if (menuType === 'Navigation') {
                return 'dx-icon-menu'
            } else if (menuType === 'SidePanel') {
                return 'dx-icon-pinright'
            } else if (menuType === 'Page') {
                return 'dx-icon-mention'
            } else if (menuType === 'Db_field') {
                return 'dx-icon-fields'
            } else if (menuType === 'CommunicationErrorMsg') {
                return 'dx-icon-info'
            } else if (menuType === 'ProcessErrorMsg') {
                return 'dx-icon-belloutline'
            } else if (menuType === 'Common') {
                return 'dx-icon-mediumiconslayout'
            } else if (menuType === 'Login') {
                return 'dx-icon-login'
            } else {
                return 'dx-icon-to'
            }
        }
        const iconClass = getIconClass(data.data.text)
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <i
                    class={iconClass}
                    style={{
                        marginRight: '8px',
                        width: '15px',
                        height: '15px',
                    }}
                ></i>
                {data.value}
            </div>
        )
    }

    const onExporting = (e) => {
        const fileName = 'language'

        if (e.format === 'xlsx') {
            const workbook = new Workbook()
            const worksheet = workbook.addWorksheet('Main sheet')
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`)
                })
            })
        }
    }

    return (
        <div className='dx-theme-background-color'>
            <DQToast ref={toastRef} />
            <div className='admin-language-control-container'>
                <div className='button-container'>
                    <CheckBox
                        key={'language-save-check-box'} // key 추가
                        text={dqTranslateMsg('Page_Configuration_' + 'Apply') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Plant') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Save')}
                        value={isDefaultSettings} // 권한 상태에 따라 체크 여부 결정
                        width={'100%'}
                        height={'100%'}
                        disabled={true}
                        // onValueChanged={handleCheckboxChange} // 상태 변경 핸들러 추가
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='save'
                        text={dqTranslateMsg('Page_AdminSettings_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                {/* <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_AdminSettings_' + 'Plant') + ' ' + dqTranslateMsg('Page_AdminSettings_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handlePlantResetButton}
                    />
                </div> */}
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_AdminSettings_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            <div className='admin-language-container'>
                <div className='language-tree-list-container'>
                    <div className='tree-list-container'>
                        <TreeList
                            className='tree-list-content'
                            dataSource={treeListDataSource}
                            showBorders={true}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            defaultExpandedRowKeys={expandedKeys}
                            noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
                            keyExpr='id'
                            parentIdExpr='parentId'
                            onSelectionChanged={handleTreeListSelectionChanged}
                        >
                            <TreeListSearchPanel
                                visible={true}
                                width={150}
                                placeholder={dqTranslateMsg('Page_AdminSettings_' + 'SearchPlaceHoler')}
                            />
                            <HeaderFilter visible={true} />
                            <Selection mode='single' />
                            <TreeListColumn
                                dataField='text'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Navigator')}
                                width={'100%'}
                                cellRender={cellRenderer}
                            />
                        </TreeList>
                    </div>
                </div>
                {/* <DataGrid className="language-items-data-grid-container"></DataGrid> */}
                <div className='language-items-data-grid-container'>
                    <div
                        id='langDispDiv'
                        className='en-grid-container'
                    >
                        <DataGrid
                            className='en-grid-content'
                            id='admin-language-en-datagrid'
                            ref={dataGridRef}
                            dataSource={dataSource}
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            columnResizingMode='widget'
                            allowColumnResizing={true}
                            filterRow={{ visible: false }}
                            noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
                            paging={{ enabled: false }}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            hoverStateEnabled={true}
                            onExporting={onExporting}
                            onRowUpdating={handleRowUpdating}
                        >
                            <Toolbar>
                                {/* <Item location="before">
                  <div className="en-grid-title">
                    {dqTranslateMsg('Page_AdminSettings_' + 'English')}
                  </div>
                </Item> */}
                                <Item name='saveButton' />
                                <Item name='searchPanel' />
                                <Item
                                    name='columnChooserButton'
                                    location='after'
                                />
                                <Item
                                    name='exportButton'
                                    location='after'
                                />
                            </Toolbar>
                            <Editing
                                mode='row'
                                useIcons={true}
                                allowUpdating={true}
                            />

                            <ColumnChooser
                                enabled={true}
                                mode={'select'}
                                width={300}
                                height={500}
                                title={dqTranslateMsg('Page_AdminSettings_' + 'ColumnChooserTitle')}
                                emptyPanelText={dqTranslateMsg('Page_AdminSettings_' + 'ColumnChooserEmptyPanelText')}
                            >
                                <Position
                                    my='center'
                                    at='center'
                                    of={document.getElementById('langDispDiv')}
                                />
                                <ColumnChooserSearch
                                    enabled={true}
                                    editorOptions={{
                                        placeholder: dqTranslateMsg('Page_AdminSettings_' + 'ColumnChooserPlaceHoler'),
                                    }}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={false}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <DataGridSearchPanel
                                visible={true}
                                width={150}
                                placeholder={dqTranslateMsg('Page_AdminSettings_' + 'SearchPlaceHoler')}
                            />
                            <Scrolling
                                mode='standard'
                                rowRenderingMode='standard'
                                useNative={false}
                            />

                            <DataGridColumn
                                dataField='id'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'ID')}
                                alignment='center'
                                width={'5%'}
                                allowEditing={false}
                            />
                            <DataGridColumn
                                dataField='type'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Type')}
                                width={'19%'}
                                visible={false}
                                alignment='center'
                                allowEditing={false}
                            />
                            <DataGridColumn
                                dataField='page'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Page')}
                                width={'19%'}
                                alignment='center'
                                allowEditing={false}
                            />
                            <DataGridColumn
                                dataField='key'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Key')}
                                width={'19%'}
                                alignment='center'
                                allowEditing={false}
                            />
                            <DataGridColumn
                                dataField='en'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'English')}
                                width={'19%'}
                                alignment='center'
                            />
                            <DataGridColumn
                                dataField='ja'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Japanese')}
                                width={'19%'}
                                alignment='center'
                            />
                            <DataGridColumn
                                dataField='kr'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Korean')}
                                width={'19%'}
                                alignment='center'
                            />
                            <Export
                                enabled={true}
                                formats={['xlsx']}
                                texts={{
                                    exportAll: dqTranslateMsg('Page_ValueList_' + 'ExportToExcel'),
                                }}
                            />
                        </DataGrid>
                    </div>
                </div>
            </div>
        </div>
    )
}
