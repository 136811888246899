import PCUtils from 'src/functions/ProcessCapabilityUtils'

export const makeSbMarkLine = (mode, settings) => {
  // 마크 라인을 생성하는 함수
  const createMarkLine = (enabled, labelEnabled, labelName, yAxisValue, lineSettings, fontSize, natState) => {
    const position = 'insideEndTop'
    const fontFamily = 'Arial'
    return {
      show: yAxisValue !== null && enabled,
      name: labelEnabled ? labelName : '',
      yAxis: yAxisValue,
      label: {
        position: position,
        formatter: (args) => args.name,
        fontFamily: fontFamily,
        fontSize: fontSize,
      },
      lineStyle: {
        type: natState ? 'dotted' : lineSettings?.type,
        color: lineSettings?.color,
        width: lineSettings?.width,
      },
      labelLayout: { moveOverlap: 'shiftY' },
    }
  }

  // 페이지 및 테마 설정에서 필요한 값 추출
  const { specificationLimitEnable, warningLimitEnable, nominalLimitEnable, averageLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable } = settings.page.valueChart

  const { specificationLimitLabelEnable, warningLimitLabelEnable, nominalLimitLabelEnable, averageLimitLabelEnable, plausibilityLimitLabelEnable, scrapLimitLabelEnable, acceptanceLimitLabelEnable } =
    settings.page.valueChart

  const { specLimit, warnLimit, nominal, average, plausibilityLimit, scrapLimit, acceptanceLimit } = settings.theme.valueChart.lineChart.markLines

  // 자연 경계 설정 여부 확인
  const isUpperNat = PCUtils.getTypeUpperWarningLimit(settings.char) === 2
  const isLowerNat = PCUtils.getTypeLowerWarningLimit(settings.char) === 2

  // 마크 라인 대상 배열
  const targetML = [
    {
      limitEnable: specificationLimitEnable,
      labelEnable: specificationLimitLabelEnable,
      name: 'LSL',
      value: settings.lsl,
      config: specLimit,
      fontSize: specLimit.fontSize,
      natState: isLowerNat,
    },
    {
      limitEnable: specificationLimitEnable,
      labelEnable: specificationLimitLabelEnable,
      name: 'USL',
      value: settings.usl,
      config: specLimit,
      fontSize: specLimit.fontSize,
      natState: isUpperNat,
    },
    {
      limitEnable: warningLimitEnable,
      labelEnable: warningLimitLabelEnable,
      name: 'LWL',
      value: settings.lwl,
      config: warnLimit,
      fontSize: warnLimit.fontSize,
      natState: false,
    },
    {
      limitEnable: warningLimitEnable,
      labelEnable: warningLimitLabelEnable,
      name: 'UWL',
      value: settings.uwl,
      config: warnLimit,
      fontSize: warnLimit.fontSize,
      natState: false,
    },
    {
      limitEnable: nominalLimitEnable,
      labelEnable: nominalLimitLabelEnable,
      name: 'Nominal',
      value: settings.nominal,
      config: nominal,
      fontSize: nominal.fontSize,
      natState: false,
    },
    {
      limitEnable: averageLimitEnable,
      labelEnable: averageLimitLabelEnable,
      name: 'Average',
      value: settings.average,
      config: average,
      fontSize: average.fontSize,
      natState: false,
    },
    {
      limitEnable: plausibilityLimitEnable,
      labelEnable: plausibilityLimitLabelEnable,
      name: 'UPL',
      value: settings.upl,
      config: plausibilityLimit,
      fontSize: plausibilityLimit?.fontSize,
      natState: false,
    },
    {
      limitEnable: plausibilityLimitEnable,
      labelEnable: plausibilityLimitLabelEnable,
      name: 'LPL',
      value: settings.lpl,
      config: plausibilityLimit,
      fontSize: plausibilityLimit?.fontSize,
      natState: false,
    },
    {
      limitEnable: scrapLimitEnable,
      labelEnable: scrapLimitLabelEnable,
      name: 'URL',
      value: settings.url,
      config: scrapLimit,
      fontSize: scrapLimit?.fontSize,
      natState: false,
    },
    {
      limitEnable: scrapLimitEnable,
      labelEnable: scrapLimitLabelEnable,
      name: 'LRL',
      value: settings.lrl,
      config: scrapLimit,
      fontSize: scrapLimit?.fontSize,
      natState: false,
    },
    {
      limitEnable: acceptanceLimitEnable,
      labelEnable: acceptanceLimitLabelEnable,
      name: 'UAcl',
      value: settings.uacl,
      config: acceptanceLimit,
      fontSize: acceptanceLimit?.fontSize,
      natState: false,
    },
    {
      limitEnable: acceptanceLimitEnable,
      labelEnable: acceptanceLimitLabelEnable,
      name: 'LAcl',
      value: settings.lacl,
      config: acceptanceLimit,
      fontSize: acceptanceLimit?.fontSize,
      natState: false,
    },
  ]

  // 마크 라인을 생성하고, 'show' 값이 true인 것만 필터링 후 반환
  const markLines = targetML
    .map((item) => createMarkLine(item.limitEnable, item.labelEnable, item.name, item.value, item.config, item.fontSize, item.natState))
    .filter((line) => line.show)
    .map(({ show, ...rest }) => rest)

  return markLines
}
