import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function SettingsLimitsLabelEnable(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onCheckBoxLimitationLabelEnableValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page.valueChart[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`settings-limits-label-enable-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'LimitationsLabelEnable')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'SpecificationLimitLabel'),
        labelLocation: 'right',
        name: 'specificationLimitLabelEnable',
        value: pageState.page.valueChart.specificationLimitLabelEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationLabelEnableValueChanged(e, 'specificationLimitLabelEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'WarningLimitLabel'),
        labelLocation: 'right',
        name: 'warningLimitLabelEnable',
        value: pageState.page.valueChart.warningLimitLabelEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationLabelEnableValueChanged(e, 'warningLimitLabelEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'AverageLimitLabel'),
        labelLocation: 'right',
        name: 'averageLimitLabelEnable',
        value: pageState.page.valueChart.averageLimitLabelEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationLabelEnableValueChanged(e, 'averageLimitLabelEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'NominalLimitLabel'),
        labelLocation: 'right',
        name: 'nominalLimitLabelEnable',
        value: pageState.page.valueChart.nominalLimitLabelEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationLabelEnableValueChanged(e, 'nominalLimitLabelEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'PlausibilityLimitLabel'),
        labelLocation: 'right',
        name: 'plausibilityLimitLabelEnable',
        value: pageState.page.valueChart.plausibilityLimitLabelEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationLabelEnableValueChanged(e, 'plausibilityLimitLabelEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'ScrapLimitLabel'),
        labelLocation: 'right',
        name: 'scrapLimitLabelEnable',
        value: pageState.page.valueChart.scrapLimitLabelEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationLabelEnableValueChanged(e, 'scrapLimitLabelEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'AcceptanceLimitLabel'),
        labelLocation: 'right',
        name: 'acceptanceLimitLabelEnable',
        value: pageState.page.valueChart.acceptanceLimitLabelEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationLabelEnableValueChanged(e, 'acceptanceLimitLabelEnable'),
      })}
    </GroupItem>
  )
}
