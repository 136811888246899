// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-db-info-container {
  width: 100%;
  height: 100%;
}
.admin-db-info-container .admin-db-info-grid-container {
  width: 100%;
  height: 100%;
}
.admin-db-info-container .admin-db-info-grid-container .dx-group-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  height: 32px;
  vertical-align: middle;
}
.admin-db-info-container .admin-db-info-grid-container .dx-data-row td {
  padding: 0px;
  font-size: 12px;
  height: 32px;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/db-info/AdminDBInfo.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EAEE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;AADJ;AAGE;EACE,YAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;AADJ","sourcesContent":[".admin-db-info-container {\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  .admin-db-info-grid-container {\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n\r\n  .admin-db-info-grid-container .dx-group-row td {\r\n    // background-color: rgba(27, 197, 189, 0.17);\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    font-size: 12px;\r\n    height: 32px;\r\n    vertical-align: middle;\r\n  }\r\n  .admin-db-info-grid-container .dx-data-row td {\r\n    padding: 0px;\r\n    font-size: 12px;\r\n    height: 32px;\r\n    vertical-align: middle;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
