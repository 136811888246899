import { GroupItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'
import { ScrollView } from 'devextreme-react'
import AdvancedCommonOption from './advanced/AdvancedCommonOption'
import AdvancedDateTimeOption from './advanced/AdvancedDateTimeOption'
import AdvancedLastNFilterOption from './advanced/AdvancedLastNFilterOption'
import AdvancedETCOption from './advanced/AdvancedETCOption'
import { Form } from 'devextreme-react'
import AdvancedRealTimeFilter from './advanced/AdvancedRealTimeFilter'
import QuickCommonOption from './quick/QuickCommonOption'
import QuickDateTimeOption from './quick/QuickDateTimeOption'
import QuickLastNFilterOption from './quick/QuickLastNFilterOption'
import QuickETCOption from './quick/QuickETCOption'
import QuickOption from './quick/QuickOption'
import QuickModifyButton from './quick/QuickModifyButton'

const DBFieldPopupFilter = (
    pageKey,
    pageState,
    configState,
    othersPage,
    isVisibleFilterPopup,
    setIsVisibleFilterPopup,
    popupDisplayFilter,
    setPopupDisplayFilter,
    partRefPopup,
    charRefPopup,
    valueRefPopup
) => {
    const dispatch = useDispatch()

    const closePopup = () => {
        setIsVisibleFilterPopup(false)
        setPopupDisplayFilter(null)
    }

    const renderFilterContent = () => {
        if (popupDisplayFilter === null) {
            return null
        }

        return (
            <Form
                id={`side-panel-form`}
                style={{ width: '100%', height: '100%', paddingTop: '10px' }}
                labelMode={'outside'}
                showColonAfterLabel={false}
            >
                {[
                    QuickCommonOption(popupDisplayFilter, setPopupDisplayFilter),
                    QuickDateTimeOption(popupDisplayFilter, setPopupDisplayFilter),
                    QuickLastNFilterOption(popupDisplayFilter, setPopupDisplayFilter),
                    QuickETCOption(popupDisplayFilter, setPopupDisplayFilter),
                    QuickOption(
                        popupDisplayFilter,
                        setPopupDisplayFilter,
                        isVisibleFilterPopup,
                        setIsVisibleFilterPopup,
                        pageKey,
                        pageState,
                        configState,
                        othersPage,
                        partRefPopup,
                        charRefPopup,
                        valueRefPopup
                    ),
                    QuickModifyButton(popupDisplayFilter, setPopupDisplayFilter, isVisibleFilterPopup, setIsVisibleFilterPopup, pageKey, pageState, configState, othersPage),
                ]}
            </Form>
        )
    }

    const title = 'Display Selected Filter'
    const width = '30%'
    const height = 'auto'

    return (
        <GroupItem
            key={`db-field-popup-filter-group-item`}
            colCount={2}
            caption={''}
        >
            {PopupSimpleItem({ visible: isVisibleFilterPopup, onHiding: closePopup, title, width, height, contentRender: renderFilterContent })}
        </GroupItem>
    )
}

export default DBFieldPopupFilter
