import SidePanelBoxPlotChartTheme from 'src/pages/chart-box-plot/components/side-panel/SidePanelBoxPlotChartTheme'
import SidePanelCapabilitySummaryChartTheme from 'src/pages/chart-capability-summary/components/side-panel/SidePanelCapabilitySummaryChartTheme'
import SidePanelCorrelationChartTheme from 'src/pages/chart-correlation/components/side-panel/SidePanelCorrelationChartTheme'
import SidePanelHistogramChartTheme from 'src/pages/chart-histogram/components/side-panel/SidePanelHistogramChartTheme'
import SidePanelLinearRegressionChartTheme from 'src/pages/chart-linear-regression/components/side-panel/SidePanelLinearRegressionChartTheme'
import SidePanelParetoChartTheme from 'src/pages/chart-pareto/components/side-panel/SidePanelParetoChartTheme'
import SidePanelPositionalTolerancesChartTheme from 'src/pages/chart-positional-tolerances/components/side-panel/SidePanelPositionalTolerancesChartTheme'
import SidePanelProbabilityPlotChartTheme from 'src/pages/chart-probability-plot/components/side-panel/SidePanelProbabilityPlotChartTheme'
import SidePanelQualityControlChartTheme from 'src/pages/chart-quality-control/components/side-panel/SidePanelQualityControlChartTheme'
import SidePanelValueChartTheme from 'src/pages/chart-value/components/side-panel/SidePanelValueChartTheme'
import SidePanelSummaryListTheme from 'src/pages/list-summary/components/side-panel/SidePanelSummaryListTheme'
import SidePanelValueSummaryListTheme from 'src/pages/list-value-summary/components/side-panel/SidePanelValueSummaryListTheme'
import SidePanelMainTheme from 'src/pages/monitoring/components/side-panel/SidePanelMonitoringTheme'
import SidePanelOverviewTheme from 'src/pages/overview/components/side-panel/SidePanelOverviewTheme'
import './SidePanelThemeForm.scss'
import SidePanelScrollBoardTheme from 'src/pages/scroll-board/components/side-panel/SidePanelScrollBoardTheme'
import { ScrollView } from 'devextreme-react'
export default function SidePanelThemeForm(props) {
  const { pageKey } = props

  return (
    <>
      {pageKey === 'overview' && <SidePanelOverviewTheme pageKey={pageKey} />}
      {pageKey === 'monitoring' && <SidePanelMainTheme pageKey={pageKey} />}
      {pageKey === 'valueChart' && <SidePanelValueChartTheme pageKey={pageKey} />}
      {pageKey === 'histogramChart' && <SidePanelHistogramChartTheme pageKey={pageKey} />}
      {pageKey === 'positionalTolerancesChart' && <SidePanelPositionalTolerancesChartTheme pageKey={pageKey} />}
      {pageKey === 'boxPlotChart' && <SidePanelBoxPlotChartTheme pageKey={pageKey} />}
      {pageKey === 'linearRegressionChart' && <SidePanelLinearRegressionChartTheme pageKey={pageKey} />}
      {pageKey === 'correlationChart' && <SidePanelCorrelationChartTheme pageKey={pageKey} />}
      {pageKey === 'qualityControlChart' && <SidePanelQualityControlChartTheme pageKey={pageKey} />}
      {pageKey === 'capabilitySummaryChart' && <SidePanelCapabilitySummaryChartTheme pageKey={pageKey} />}
      {pageKey === 'paretoChart' && <SidePanelParetoChartTheme pageKey={pageKey} />}
      {pageKey === 'probabilityPlotChart' && <SidePanelProbabilityPlotChartTheme pageKey={pageKey} />}
      {pageKey === 'scrollBoard' && <SidePanelScrollBoardTheme pageKey={pageKey} />}
      {pageKey === 'summaryList' && <SidePanelSummaryListTheme pageKey={pageKey} />}
      {pageKey === 'valueSummaryList' && <SidePanelValueSummaryListTheme pageKey={pageKey} />}
    </>
  )
}
