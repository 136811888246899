import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'

const calculationType = () => [
  { value: 'fixed', text: dqTranslateMsg('SidePanel_' + 'Fixed') },
  { value: 'moving', text: dqTranslateMsg('SidePanel_' + 'Moving') },
]

const locationChartType = () => [
  { value: 'average', text: dqTranslateMsg('SidePanel_' + 'Average') },
  { value: 'raw', text: dqTranslateMsg('SidePanel_' + 'Raw') },
]

const handlingIncompleteSubgroup = () => [
  { value: 'calculate', text: dqTranslateMsg('SidePanel_' + 'CalculateIncompleteSubgroup') },
  { value: 'delete', text: dqTranslateMsg('SidePanel_' + 'DeleteIncompleteSubgroup') },
]

const SettingsQccOption = (pageKey, pageState, configState) => {
  const dispatch = useDispatch()

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxValueChanged = (e, dataField) => {
    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onRadioGroupValueChanged = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`settings-qcc-option-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'QCCOption')}>
      {NumberBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'CalculationCount'),
        labelLocation: 'top',
        name: 'calculationCount',
        min: 1,
        max: 100,
        step: 1,
        value: pageState.page.calculationCount,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'calculationCount'),
      })}
      {SelectBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'CalculationType'),
        labelLocation: 'top',
        name: 'calculationType',
        items: calculationType(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: calculationType().find((item) => item.value === pageState.page.calculationType)?.value,
        onItemClick: (e) => onSelectBoxValueChanged(e, 'calculationType'),
      })}
      {RadioGroupSimpleItem({
        ref: null,
        colSpan: 4,
        label: dqTranslateMsg('SidePanel_' + 'QccLocationChartType'),
        labelLocation: 'top',
        name: 'locationChartType',
        items: locationChartType(),
        layout: 'horizontal',
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.locationChartType,
        disabled: false,
        onValueChanged: (e) => onRadioGroupValueChanged(e, 'locationChartType'),
      })}
      {RadioGroupSimpleItem({
        ref: null,
        colSpan: 4,
        label: dqTranslateMsg('SidePanel_' + 'HandlingIncompleteSubgroup'),
        labelLocation: 'top',
        name: 'handlingIncompleteSubgroup',
        items: handlingIncompleteSubgroup(),
        layout: 'vertical',
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.handlingIncompleteSubgroup,
        disabled: false,
        onValueChanged: (e) => onRadioGroupValueChanged(e, 'handlingIncompleteSubgroup'),
      })}
    </GroupItem>
  )
}

export default SettingsQccOption
