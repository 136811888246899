import { Form, ScrollView } from 'devextreme-react'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import DQSaveReset from 'src/functions/DQSaveReset'
import DQToast from 'src/functions/DQToast'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { overviewSettingsPrm } from 'src/pages/overview/components/settings/OverviewSettings'
import { setOverviewPage } from 'src/store/common'
import './SidePanelOverviewSettings.scss'
import SymbolColor from './components/theme/SymbolColor.js'
import DisplayType from './components/settings/DisplayType.js'

export const displayType = () => [
    { value: 'single', text: dqTranslateMsg('SidePanel_' + 'Single') },
    { value: 'all', text: dqTranslateMsg('SidePanel_' + 'All') },
]

export const mainDisplay = () => [{ value: 'part', text: dqTranslateMsg('SidePanel_' + 'Part') }]
export const extraDisplay = () => [
    { value: 'char', text: dqTranslateMsg('SidePanel_' + 'Characteristics') },
    { value: 'c2092', text: dqTranslateMsg('Db_field_Characteristics_' + 'C2092') },
]

export default function SidePanelOverviewSettings(props) {
    const { pageKey } = props
    const dispatch = useDispatch()

    const [scrollPosition, setScrollPosition] = useState(0)
    const { user } = useAuth()
    const parentRef = useRef(null)
    const toastRef = useRef(null)

    const common = useSelector((state) => state.common)
    const curPage = common.pages[pageKey]
    const othersPage = common.pages.others
    const configPage = common.pages.configuration

    const handleCurPageSettingsSave = (updatePageData) => {
        dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
    }

    const handleSaveButtonClicked = () => {
        const next = produce(curPage, (draft) => {
            Object.keys(curPage).forEach((key) => {
                draft[key] = curPage[key]
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }
    const handleResetButtonClicked = () => {
        const next = produce(curPage, (draft) => {
            Object.entries(overviewSettingsPrm.page).forEach(([key, value]) => {
                draft.page[key] = value
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    return (
        <div
            ref={parentRef}
            style={{ padding: '5px' }}
        >
            <DQToast ref={toastRef} />
            <div style={{ width: '100%', height: '3%' }}>
                <DQSaveReset
                    saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
                    resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
                    saveCallback={handleSaveButtonClicked}
                    resetCallback={handleResetButtonClicked}
                />
            </div>
            <ScrollView
                className='side-panel-overview-container'
                style={{ padding: 0 }}
                showScrollbar={'onHover'}
                useNative={false}
            >
                <Form
                    // ref={formRef}
                    id='side-panel-overview-settings-form'
                    style={{ paddingTop: '5px' }}
                    labelMode={'outside'}
                    formData={curPage.page}
                    showColonAfterLabel={false}
                    labelLocation={'top'}
                >
                    {/* Devexpress cannot make component type in Form component */}
                    {DisplayType(pageKey, curPage, configPage, othersPage)}
                </Form>
            </ScrollView>
        </div>
    )
}
