import CCUtils from 'src/functions/CommonCalcUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

function capitalizeFirstLetter(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return str // Return the original value if it's not a string or is an empty string
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getOverviewMainBarChartOption = (valueDescList, settings, partNameArray, partErrorArray, partWarnArray, partGoodArray, partNoneArray, totalCnt) => {
  let title = ''
  if (settings.page.display.main === 'part') {
    title = dqTranslateMsg('Db_field_Part_' + 'P1002')
  } else if (settings.page.display.main === 'char') {
    title = dqTranslateMsg('Page_Overview_' + 'Characteristics')
  } else if (settings.page.display.main === 'c2092') {
    title = dqTranslateMsg('Page_Overview_' + 'OperationName')
  } else {
    const target = valueDescList.value.find((item) => item.value === settings.page.display.main)
    title = ''
    if (target) {
      title = dqTranslateMsg('Db_field_Value_' + target.text)
    }
  }

  return {
    title: {
      text: dqTranslateMsg('Page_Overview_' + 'OverviewBy') + `(${title})`,
      textStyle: { fontFamily: 'Arial' },
      left: 'center',
    },
    animationDuration: 300,
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        label: {
          textStyle: {
            fontFamily: 'Arial',
          },
        },
      },
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    legend: {
      orient: 'vertical',
      left: 'left',
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    grid: {
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      min: 0,
      max: CCUtils.getMax(totalCnt),
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
      },
      axisLine: {
        onZero: false,
      },
    },
    yAxis: {
      type: 'category',
      data: partNameArray,
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
      },
      axisTick: {
        alignWithLabel: true,
      },
      axisLine: {
        onZero: false,
      },
    },
    dataZoom: [
      {
        show: true,
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'slider',
        yAxisIndex: 0,
        filterMode: 'none',
      },
    ],
    series: [
      {
        name: dqTranslateMsg('Page_Overview_' + 'Good'),
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          formatter: function (params) {
            if (params.value === 0) {
              return ''
            } else {
              return params.value
            }
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: partGoodArray, // part1 , part2, part3
        itemStyle: {
          color: settings.barChart.good.color,
        },
      },

      {
        name: dqTranslateMsg('Page_Overview_' + 'Warning'),
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          formatter: function (params) {
            if (params.value === 0) {
              return ''
            } else {
              return params.value
            }
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: partWarnArray, // part1 , part2, part3
        itemStyle: {
          color: settings.barChart.warn.color,
        },
      },
      {
        name: dqTranslateMsg('Page_Overview_' + 'NG'),
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          formatter: function (params) {
            if (params.value === 0) {
              return ''
            } else {
              return params.value
            }
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: partErrorArray, // part1 , part2, part3
        itemStyle: {
          color: settings.barChart.error.color,
        },
      },
      {
        name: dqTranslateMsg('Page_Overview_' + 'None'),
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          formatter: function (params) {
            if (params.value === 0) {
              return ''
            } else {
              return params.value
            }
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: partNoneArray, // part1 , part2, part3
        itemStyle: {
          color: settings.barChart.none.color,
        },
      },
    ],
  }
}

export const getOverviewSubBarChartOption = (valueDescList, settings, partNameArray, partErrorArray, partWarnArray, partGoodArray, partNoneArray, totalCnt) => {
  let title = ''
  if (settings.page.display.sub === 'part') {
    title = dqTranslateMsg('Db_field_Part_' + 'P1002')
  } else if (settings.page.display.sub === 'char') {
    title = dqTranslateMsg('Page_Overview_' + 'Characteristics')
  } else if (settings.page.display.sub === 'c2092') {
    title = dqTranslateMsg('Page_Overview_' + 'OperationName')
  } else {
    const target = valueDescList.value.find((item) => item.value === settings.page.display.sub)
    title = ''
    if (target) {
      title = dqTranslateMsg('Db_field_Value_' + target.text)
    }
  }

  return {
    title: {
      text: dqTranslateMsg('Page_Overview_OverviewBy') + `(${title})`,
      textStyle: { fontFamily: 'Arial' },
      left: 'center',
    },
    animationDuration: 300,
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        label: {
          textStyle: {
            fontFamily: 'Arial',
          },
        },
      },
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    legend: {
      orient: 'vertical',
      left: 'left',
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    grid: {
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      min: 0,
      max: CCUtils.getMax(totalCnt),
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
      },
    },
    yAxis: {
      type: 'category',
      data: partNameArray,
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    dataZoom: [
      {
        show: true,
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'slider',
        yAxisIndex: 0,
        filterMode: 'none',
      },
    ],
    series: [
      {
        name: dqTranslateMsg('Page_Overview_' + 'Good'),
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          formatter: function (params) {
            if (params.value === 0) {
              return ''
            } else {
              return params.value
            }
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: partGoodArray, // part1 , part2, part3
        itemStyle: {
          color: settings.barChart.good.color,
        },
      },
      {
        name: dqTranslateMsg('Page_Overview_' + 'Warning'),
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          formatter: function (params) {
            if (params.value === 0) {
              return ''
            } else {
              return params.value
            }
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: partWarnArray, // part1 , part2, part3
        itemStyle: {
          color: settings.barChart.warn.color,
        },
      },

      {
        name: dqTranslateMsg('Page_Overview_' + 'NG'),
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          formatter: function (params) {
            if (params.value === 0) {
              return ''
            } else {
              return params.value
            }
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: partErrorArray, // part1 , part2, part3
        itemStyle: {
          color: settings.barChart.error.color,
        },
      },
      {
        name: dqTranslateMsg('Page_Overview_' + 'None'),
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
          formatter: function (params) {
            if (params.value === 0) {
              return ''
            } else {
              return params.value
            }
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: partNoneArray, // part1 , part2, part3
        itemStyle: {
          color: settings.barChart.none.color,
        },
      },
    ],
  }
}
