class DTOPart {
  constructor({
    p1001 = null,
    p1002 = null,
    p1003 = null,
    p1005 = null,
    p1007 = null,
    p1008 = null,
    p1009 = null,
    p1011 = null,
    p1012 = null,
    p1013 = null,
    p1014 = null,
    p1015 = null,
    p1022 = null,
    p1032 = null,
    p1046 = null,
    p1052 = null,
    p1062 = null,
    p1072 = null,
    p1082 = null,
    p1085 = null,
    p1086 = null,
    p1087 = null,
    p1091 = null,
    p1092 = null,
    p1100 = null,
    p1101 = null,
    p1102 = null,
    p1103 = null,
    p1104 = null,
    p1203 = null,
    p1303 = null,
    p1802 = null,
    p1812 = null,
    p1822 = null,
    p1832 = null,
    p1842 = null,
    p1852 = null,
    p1862 = null,
    p1872 = null,
    p1882 = null,
    p1892 = null,
    p1900 = null,
    part_id = null,
    updated_datetime = null,
    user_id = null,
    user_role = null,
  } = {}) {
    Object.assign(this, {
      p1001,
      p1002,
      p1003,
      p1005,
      p1007,
      p1008,
      p1009,
      p1011,
      p1012,
      p1013,
      p1014,
      p1015,
      p1022,
      p1032,
      p1046,
      p1052,
      p1062,
      p1072,
      p1082,
      p1085,
      p1086,
      p1087,
      p1091,
      p1092,
      p1100,
      p1101,
      p1102,
      p1103,
      p1104,
      p1203,
      p1303,
      p1802,
      p1812,
      p1822,
      p1832,
      p1842,
      p1852,
      p1862,
      p1872,
      p1882,
      p1892,
      p1900,
      part_id,
      updated_datetime,
      user_id,
      user_role,
    })
  }
}

export default DTOPart
