import * as dqConvert from 'src/pages/components/dq-convert/DQConvert'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as CDSUtils from 'src/functions/CheckDataStateUtils'

function makePartDescFactors(partData) {
    const partName = partData.p1002 ?? dqTranslateMsg('NoData')

    return { partName: partName }
}

function makeCharDescFactors(charData) {
    const charName = charData.c2002 ?? dqTranslateMsg('NoData')

    return { name: charName }
}

function makeCalcFactors(charData, configSettings) {
    const lsl = charData.c2110
    const usl = charData.c2111
    // const nominal = (lsl + usl) / 2
    const nominal = charData.c2101 ?? (usl + lsl) / 2
    const gap = usl - nominal
    const warnOffset = (gap * configSettings.commonSettings.warningLimitPercentage) / 100

    return {
        lsl: lsl,
        usl: usl,
        nominal: nominal,
        gap: gap,
        warnOffset: warnOffset,
        lwl: lsl + warnOffset,
        uwl: usl - warnOffset,
    }
}

function checkCountForGoodWarnNgNoneInArray(valueData, calcFactor) {
    let errCnt = 0
    let warnCnt = 0
    let goodCnt = 0
    let noneCnt = 0

    const { usl, lsl, uwl, lwl } = calcFactor

    valueData.map((valueObj) => {
        const val = valueObj.v0001

        const prmProps = { val, usl, lsl, uwl, lwl }
        const valueState = CDSUtils.getOverviewValueState(prmProps)
        if (valueState === CDSUtils.GOOD_STATE) {
            goodCnt++
        } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
            warnCnt++
        } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
            warnCnt++
        } else if (valueState === CDSUtils.NG_UPPER_STATE) {
            errCnt++
        } else if (valueState === CDSUtils.NG_LOWER_STATE) {
            errCnt++
        } else {
            // NONE_STATE
            return noneCnt++
        }
    })

    return {
        goodCnt: goodCnt,
        warnCnt: warnCnt,
        errCnt: errCnt,
        noneCnt: noneCnt,
    }
}

function checkCountForGoodWarnNgNonePerValue(value, calcFactor) {
    const { usl, lsl, uwl, lwl } = calcFactor

    /**
     * good: 0
     * warn: 1
     * error:2
     * none: 3
     */
    let state = 0
    if (lsl === null && usl === null) {
        state = 3
    } else if (lsl !== null && usl !== null) {
        if (lsl > value || usl < value) {
            state = 2
        } else if ((value < usl && value > uwl) || (value > lsl && value < lwl)) {
            state = 1
        } else {
            state = 0
        }
    } else if (lsl !== null && usl === null) {
        if (lsl > value) {
            state = 2
        } else if (value >= lsl && value < lwl) {
            state = 1
        } else {
            state = 0
        }
    } else {
        if (usl < value) {
            state = 2
        } else if (value <= usl && value > uwl) {
            state = 1
        } else {
            state = 0
        }
    }

    return state
}

export function makeStateInfoAll(configPage, values) {
    const dataArray = {
        errCnt: [],
        warnCnt: [],
        goodCnt: [],
        noneCnt: [],
    }

    values
        .map((piece) => {
            const partData = piece.partRaw
            const charData = piece.charRaw
            const valueData = piece.valueRaw

            if (valueData.length > 0) {
                const descItems = makePartDescFactors(partData)
                const calcFactor = makeCalcFactors(charData, configPage)
                const cnts = checkCountForGoodWarnNgNoneInArray(valueData, calcFactor)

                dataArray.errCnt.push(cnts.errCnt)
                dataArray.warnCnt.push(cnts.warnCnt)
                dataArray.goodCnt.push(cnts.goodCnt)
                dataArray.noneCnt.push(cnts.noneCnt)
            }
        })
        .filter((item) => item !== undefined)

    let errCnt = dataArray.errCnt.reduce((acc, curr) => acc + curr, 0)
    let warnCnt = dataArray.warnCnt.reduce((acc, curr) => acc + curr, 0)
    let goodCnt = dataArray.goodCnt.reduce((acc, curr) => acc + curr, 0)
    let noneCnt = dataArray.noneCnt.reduce((acc, curr) => acc + curr, 0)

    return { errCnt, warnCnt, goodCnt, noneCnt }
}

function makeStateInfoByPart(curPage, configPage, parts, chars, values) {
    // by part 계산

    // part가 뭐가 있는지 확인하는 코드
    const uniquePartIds = [...new Set(values.map((item) => item.part_id))]

    const partStateInfo = uniquePartIds.map((id) => {
        const targetValues = values.filter((value) => value.part_id === id)

        const partDataArray = {
            partName: [],
            errCnt: [],
            warnCnt: [],
            goodCnt: [],
            noneCnt: [],
            totalCnt: [],
        }

        targetValues
            .map((piece) => {
                const partData = piece.partRaw
                const charData = piece.charRaw
                const valueData = piece.valueRaw

                if (valueData.length > 0) {
                    const descItems = makePartDescFactors(partData)
                    const calcFactor = makeCalcFactors(charData, configPage)
                    const cnts = checkCountForGoodWarnNgNoneInArray(valueData, calcFactor)

                    partDataArray.partName.push(descItems.partName)
                    partDataArray.errCnt.push(cnts.errCnt)
                    partDataArray.warnCnt.push(cnts.warnCnt)
                    partDataArray.goodCnt.push(cnts.goodCnt)
                    partDataArray.noneCnt.push(cnts.noneCnt)
                    partDataArray.totalCnt.push(cnts.errCnt + cnts.warnCnt + cnts.goodCnt + cnts.noneCnt)
                }
            })
            .filter((item) => item !== undefined)

        let partName = partDataArray.partName[0]
        let errCnt = partDataArray.errCnt.reduce((acc, curr) => acc + curr, 0)
        let warnCnt = partDataArray.warnCnt.reduce((acc, curr) => acc + curr, 0)
        let goodCnt = partDataArray.goodCnt.reduce((acc, curr) => acc + curr, 0)
        let noneCnt = partDataArray.noneCnt.reduce((acc, curr) => acc + curr, 0)
        let totalCnt = partDataArray.totalCnt.reduce((acc, curr) => acc + curr, 0)

        return { partName, errCnt, warnCnt, goodCnt, noneCnt, totalCnt }
    })

    const name = partStateInfo.map((item) => item.partName)
    const errCnt = partStateInfo.map((item) => item.errCnt)
    const warnCnt = partStateInfo.map((item) => item.warnCnt)
    const goodCnt = partStateInfo.map((item) => item.goodCnt)
    const noneCnt = partStateInfo.map((item) => item.noneCnt)
    const totalCnt = partStateInfo.map((item) => item.totalCnt)

    return { name, errCnt, warnCnt, goodCnt, noneCnt, totalCnt }
}

function makeStateInfoByChar(curPage, configPage, subChart, parts, chars, values) {
    const charDataArray = {
        name: [],
        errCnt: [],
        warnCnt: [],
        goodCnt: [],
        noneCnt: [],
        totalCnt: [],
    }

    if (subChart.show) {
        const selectedPart = parts.find((part) => part.part_id === subChart.partId)

        const filteredCharArray = chars
            .map((char) => {
                if (char.part_id === selectedPart.part_id) {
                    return char
                }
            })
            .filter((item) => item !== undefined)

        filteredCharArray
            .map((char) => {
                const targetValue = values.find((value) => value.part_id === char.part_id && value.char_id === char.char_id)
                const partData = targetValue.partRaw
                const charData = targetValue.charRaw
                const valueData = targetValue.valueRaw

                if (valueData.length > 0) {
                    const descItems = makeCharDescFactors(charData)
                    const calcFactor = makeCalcFactors(charData, configPage)
                    const cnts = checkCountForGoodWarnNgNoneInArray(valueData, calcFactor)

                    charDataArray.name.push(descItems.name)
                    charDataArray.errCnt.push(cnts.errCnt)
                    charDataArray.warnCnt.push(cnts.warnCnt)
                    charDataArray.goodCnt.push(cnts.goodCnt)
                    charDataArray.noneCnt.push(cnts.noneCnt)
                    charDataArray.totalCnt.push(cnts.errCnt + cnts.warnCnt + cnts.goodCnt + cnts.noneCnt)
                }
            })
            .filter((item) => item !== undefined)
    } else {
        chars
            .map((char) => {
                const targetValue = values.find((value) => value.part_id === char.part_id && value.char_id === char.char_id)
                const partData = targetValue.partRaw
                const charData = targetValue.charRaw
                const valueData = targetValue.valueRaw

                if (valueData.length > 0) {
                    const descItems = makeCharDescFactors(charData)
                    const calcFactor = makeCalcFactors(charData, configPage)
                    const cnts = checkCountForGoodWarnNgNoneInArray(valueData, calcFactor)

                    charDataArray.name.push(descItems.name)
                    charDataArray.errCnt.push(cnts.errCnt)
                    charDataArray.warnCnt.push(cnts.warnCnt)
                    charDataArray.goodCnt.push(cnts.goodCnt)
                    charDataArray.noneCnt.push(cnts.noneCnt)
                    charDataArray.totalCnt.push(cnts.errCnt + cnts.warnCnt + cnts.goodCnt + cnts.noneCnt)
                }
            })
            .filter((item) => item !== undefined)
    }

    // by characteristics 계산
    const resultByChar = charDataArray

    const name = resultByChar.name
    const errCnt = resultByChar.errCnt
    const warnCnt = resultByChar.warnCnt
    const goodCnt = resultByChar.goodCnt
    const noneCnt = resultByChar.noneCnt
    const totalCnt = resultByChar.totalCnt

    return { name, errCnt, warnCnt, goodCnt, noneCnt, totalCnt }
}

function makeStateInfoByValue(curPage, configPage, othersPage, subChart, parts, chars, values, type) {
    const itemDataArray = {
        name: [],
        errCnt: [],
        warnCnt: [],
        goodCnt: [],
        noneCnt: [],
        totalCnt: [],
    }

    if (subChart.show) {
        const targetPart = parts.find((part) => part.part_id === subChart.partId)

        const filteredValueArray = values
            .map((value) => {
                if (value.part_id === targetPart.part_id) {
                    return value
                }
            })
            .filter((item) => item !== undefined)

        // const flatValues = values.map((value) => value.valueRaw.filter((value) => value.v0002 === 0)).flat()
        const flatValues = filteredValueArray.map((value) => value.valueRaw).flat()
        const listItem = flatValues.flat().map((value) => value[type])
        const uniqueList = [...new Set(listItem)]
        const arrangedByItem = uniqueList.map((item) => {
            return {
                name: item,
                values: flatValues.filter((obj) => obj[type] === item),
            }
        })

        arrangedByItem
            .map((item) => {
                const itemName = item.name
                const valueData = item.values

                if (valueData.length > 0) {
                    let goodCnt = 0
                    let warnCnt = 0
                    let errCnt = 0
                    let noneCnt = 0
                    valueData.map((value) => {
                        const charData = chars.find((char) => char.part_id === value.part_id && char.char_id === value.char_id)
                        const calcFactor = makeCalcFactors(charData, configPage)
                        const state = checkCountForGoodWarnNgNonePerValue(value.v0001, calcFactor)
                        if (state === 0) {
                            goodCnt++
                        } else if (state === 1) {
                            warnCnt++
                        } else if (state === 2) {
                            errCnt++
                        } else {
                            // 3
                            noneCnt++
                        }
                    })

                    itemDataArray.name.push(dqConvert.convertByCatalog(type, itemName, othersPage.catalogs))
                    itemDataArray.errCnt.push(errCnt)
                    itemDataArray.warnCnt.push(warnCnt)
                    itemDataArray.goodCnt.push(goodCnt)
                    itemDataArray.noneCnt.push(noneCnt)
                    itemDataArray.totalCnt.push(errCnt + warnCnt + goodCnt + noneCnt)
                }
            })
            .filter((item) => item !== undefined)
    } else {
        // const flatValues = values.flat()

        const flatValues = values.map((value) => value.valueRaw.filter((value) => value.v0002 === 0)).flat()

        let listItem = []
        if (type === 'v0005') {
            flatValues.forEach((item) => {
                const evtField = item.v0005
                const values = evtField !== null ? evtField.split(',') : [] // 쉼표로 분리
                values.forEach((value) => {
                    if (value !== null) {
                        listItem.push(value.trim()) // 중복 제거 (trim을 사용하여 공백 제거)
                    }
                })
            })
        } else {
            listItem = flatValues.flat().map((value) => value[type])
        }

        const uniqueList = [...new Set(listItem)]
        const arrangedByItem = uniqueList.map((item) => {
            return {
                name: item,
                values: flatValues.filter((obj) => obj[type] === item),
            }
        })

        arrangedByItem
            .map((item) => {
                const itemName = item.name
                const valueData = item.values

                if (valueData.length > 0) {
                    const descItems = itemName

                    let goodCnt = 0
                    let warnCnt = 0
                    let errCnt = 0
                    let noneCnt = 0
                    valueData.map((value) => {
                        const charData = chars.find((char) => char.part_id === value.part_id && char.char_id === value.char_id)
                        const calcFactor = makeCalcFactors(charData, configPage)
                        const state = checkCountForGoodWarnNgNonePerValue(value.v0001, calcFactor)
                        if (state === 0) {
                            goodCnt++
                        } else if (state === 1) {
                            warnCnt++
                        } else if (state === 2) {
                            errCnt++
                        } else {
                            // 3
                            noneCnt++
                        }
                    })

                    itemDataArray.name.push(dqConvert.convertByCatalog(type, itemName, othersPage.catalogs))
                    itemDataArray.errCnt.push(errCnt)
                    itemDataArray.warnCnt.push(warnCnt)
                    itemDataArray.goodCnt.push(goodCnt)
                    itemDataArray.noneCnt.push(noneCnt)
                    itemDataArray.totalCnt.push(errCnt + warnCnt + goodCnt + noneCnt)
                }
            })
            .filter((item) => item !== undefined)
    }

    const name = itemDataArray.name
    const errCnt = itemDataArray.errCnt
    const warnCnt = itemDataArray.warnCnt
    const goodCnt = itemDataArray.goodCnt
    const noneCnt = itemDataArray.noneCnt
    const totalCnt = itemDataArray.totalCnt

    return { name, errCnt, warnCnt, goodCnt, noneCnt, totalCnt }
}

function makeStateInfoByC2092(curPage, configPage, subChart, parts, chars, values) {
    const itemDataArray = {
        name: [],
        errCnt: [],
        warnCnt: [],
        goodCnt: [],
        noneCnt: [],
        totalCnt: [],
    }

    let opStateInfo

    if (subChart.show) {
        const selectedPart = parts.find((part) => part.part_id === subChart.partId)

        const filteredCharArray = chars
            .map((char) => {
                if (char.part_id === selectedPart.part_id) {
                    return char
                }
            })
            .filter((item) => item !== undefined)

        const operationType = 'c2092'
        const opList = filteredCharArray.map((char) => char[operationType]).filter((item) => item !== undefined)
        const uniqueOpList = [...new Set(opList)]

        const arrangedByOp = uniqueOpList.map((uniqueOp) => {
            return {
                name: uniqueOp,
                value: values.filter((item) => item.charRaw.c2092 === uniqueOp),
            }
        })

        const opStateInfoTemp = arrangedByOp.map((op) => {
            const itemName = op.name
            const arrangedByOpValue = op.value

            if (arrangedByOpValue.length > 0) {
                arrangedByOpValue.forEach((value) => {
                    const charData = value.charRaw
                    const valueData = value.valueRaw
                    const calcFactor = makeCalcFactors(charData, configPage)
                    const cnts = checkCountForGoodWarnNgNoneInArray(valueData, calcFactor)

                    itemDataArray.errCnt.push(cnts.errCnt)
                    itemDataArray.warnCnt.push(cnts.warnCnt)
                    itemDataArray.goodCnt.push(cnts.goodCnt)
                    itemDataArray.noneCnt.push(cnts.noneCnt)
                    itemDataArray.totalCnt.push(cnts.errCnt + cnts.warnCnt + cnts.goodCnt + cnts.noneCnt)
                })
            }

            let name = itemName
            let errCnt = itemDataArray.errCnt.reduce((acc, curr) => acc + curr, 0)
            let warnCnt = itemDataArray.warnCnt.reduce((acc, curr) => acc + curr, 0)
            let goodCnt = itemDataArray.goodCnt.reduce((acc, curr) => acc + curr, 0)
            let noneCnt = itemDataArray.noneCnt.reduce((acc, curr) => acc + curr, 0)
            let totalCnt = itemDataArray.totalCnt.reduce((acc, curr) => acc + curr, 0)

            return { name, errCnt, warnCnt, goodCnt, noneCnt, totalCnt }
        })
        opStateInfo = opStateInfoTemp
    } else {
        const operationType = 'c2092'
        const opList = chars.map((char) => char[operationType]).filter((item) => item !== undefined)
        const uniqueOpList = [...new Set(opList)]

        const arrangedByOp = uniqueOpList.map((uniqueOp) => {
            return {
                name: uniqueOp,
                value: values.filter((item) => item.charRaw.c2092 === uniqueOp),
            }
        })

        const opStateInfoTemp = arrangedByOp.map((op) => {
            const itemName = op.name
            const arrangedByOpValue = op.value

            if (arrangedByOpValue.length > 0) {
                arrangedByOpValue.forEach((value) => {
                    const charData = value.charRaw
                    const valueData = value.valueRaw
                    const calcFactor = makeCalcFactors(charData, configPage)
                    const cnts = checkCountForGoodWarnNgNoneInArray(valueData, calcFactor)

                    itemDataArray.errCnt.push(cnts.errCnt)
                    itemDataArray.warnCnt.push(cnts.warnCnt)
                    itemDataArray.goodCnt.push(cnts.goodCnt)
                    itemDataArray.noneCnt.push(cnts.noneCnt)
                    itemDataArray.totalCnt.push(cnts.errCnt + cnts.warnCnt + cnts.goodCnt + cnts.noneCnt)
                })
            }

            const name = itemName
            const errCnt = itemDataArray.errCnt.reduce((acc, curr) => acc + curr, 0)
            const warnCnt = itemDataArray.warnCnt.reduce((acc, curr) => acc + curr, 0)
            const goodCnt = itemDataArray.goodCnt.reduce((acc, curr) => acc + curr, 0)
            const noneCnt = itemDataArray.noneCnt.reduce((acc, curr) => acc + curr, 0)
            const totalCnt = itemDataArray.totalCnt.reduce((acc, curr) => acc + curr, 0)

            return { name, errCnt, warnCnt, goodCnt, noneCnt, totalCnt }
        })

        opStateInfo = opStateInfoTemp
    }

    const name = opStateInfo.map((item) => item.name)
    const errCnt = opStateInfo.map((item) => item.errCnt)
    const warnCnt = opStateInfo.map((item) => item.warnCnt)
    const goodCnt = opStateInfo.map((item) => item.goodCnt)
    const noneCnt = opStateInfo.map((item) => item.noneCnt)
    const totalCnt = opStateInfo.map((item) => item.totalCnt)

    return { name, errCnt, warnCnt, goodCnt, noneCnt, totalCnt }
}

export function makeState(type, subChart, curPage, configPage, parts, chars, values) {
    if (type === 'part') {
        return makeStateInfoByPart(curPage, configPage, parts, chars, values)
    } else if (type === 'char') {
        return makeStateInfoByChar(curPage, configPage, subChart, parts, chars, values)
    } else if (type === 'c2092') {
        return makeStateInfoByC2092(curPage, configPage, subChart, parts, chars, values)
    } else {
        // type value catalog
        return makeStateInfoByValue(curPage, configPage, othersPage, subChart, parts, chars, values, type)
    }
}
