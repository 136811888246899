export const monitoringSettingsPrm = {
  page: {
    easySettingsDateTime: '1hour',
    dateTimeResolution: '1minute',
    startDateTime: '19900507142002',
    endDateTime: '20240507134308',
    customStartDateTime: '19900507142002',
    customEndDateTime: '20240507134308',
    displayType: 'Description',
    monitoringType: 'Automatic',
    valueOrder: 'Oldest',
    charOrder: 'fromError',
    monitoringInterval: {
      type: 'custom',
      commonInterval: 10000,
      customInterval: 20,
      settingInterval: 3000,
    },
    maxDisplayCount: 100,
  },
}
