// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capability-summary-chart-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 50px);
}
.capability-summary-chart-container .chart-container {
  width: 85%;
  height: 100%;
}
.capability-summary-chart-container .side-panel-container {
  width: 15%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/chart-capability-summary/chart-capability-summary.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,0BAAA;AACF;AACE;EACE,UAAA;EACA,YAAA;AACJ;AAEE;EACE,UAAA;EACA,YAAA;AAAJ","sourcesContent":[".capability-summary-chart-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  height: calc(100vh - 50px);\r\n\r\n  .chart-container {\r\n    width: 85%;\r\n    height: 100%;\r\n  }\r\n\r\n  .side-panel-container {\r\n    width: 15%;\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
