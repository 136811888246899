import { useEffect } from 'react'
import debounce from 'lodash.debounce'

const useEChartsResize = (chartRef) => {
  useEffect(() => {
    if (!chartRef.current) return

    const chartInstance = chartRef.current.getEchartsInstance()
    let resizeObserver

    const handleResize = debounce(() => {
      if (chartInstance) {
        chartInstance.resize()
      }
    }, 300)

    if (chartInstance) {
      resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(chartInstance.getDom())
    }

    const handleFocus = () => {
      if (chartInstance) {
        chartInstance.resize()
      }
    }

    window.addEventListener('focus', handleFocus)

    return () => {
      window.removeEventListener('focus', handleFocus)
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
      handleResize.cancel()
    }
  }, [chartRef, 100])
}

export default useEChartsResize
