import axios from 'axios'
import { backendPath as path } from './defines/DBInfo'
import JwtUtils from 'src/functions/JwtUtils'
import ApiStatus from 'src/api/defines/ApiStatus'
import AxiosCommon from 'src/api/common/AxiosCommon'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export const setLocalStatus = (user_id, ids) => {
  const accessToken = JwtUtils.loadToken()
  const url = `${path}local/setLocalStatus`

  return axios
    .post(
      url,
      {
        user_id: user_id,
        ids: ids,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${accessToken}`,
        },
      }
    )
    .then((response) => {
      const result = response.data
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }
      return result.data
    })
    .catch((error) => {
      AxiosCommon.handleAxiosError(error)
      throw error
    })
}

export const connectLocalDB = async (user_id) => {
  const requestBody = {
    user_id: user_id,
  }

  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'local/connectLocalDB', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}

/* 
user_id:”demo”,
pagination:”1-100”,
requestMsg:
{
“part_id”: 1, 
“user_id” : “filteruser”,
“user_role” : “1”,
“p1001” : “p1001 filter data”,
“p1002” : “p1002 filter data”,
},
 */

// getPartInfo

export const getLocalPartInfo = async (user_id, part_id, filters) => {
  const accessToken = JwtUtils.loadToken()
  const url = `${path}local/getPartInfo`

  const config = {
    method: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${accessToken}`,
    },
    data: {
      user_id: user_id,
      requestMsg: {
        part_id: part_id,
        ...filters,
      },
    },
  }

  return axios(config)
    .then((response) => {
      const result = response.data
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }
      return result.data
    })
    .catch((error) => {
      AxiosCommon.handleAxiosError(error)
      throw error
    })
}

export const getLocalCharInfo = async (user_id, ids, filters) => {
  const accessToken = JwtUtils.loadToken()
  const url = `${path}local/getCharInfo`

  return axios({
    method: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${accessToken}`,
    },
    data: {
      user_id: user_id,
      requestMsg: {
        ...ids,
        ...filters,
      },
    },
  })
    .then((response) => {
      const result = response.data
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }
      return result.data
    })
    .catch((error) => {
      AxiosCommon.handleAxiosError(error)
    })
}

export const getLocalValueInfo = async (user_id, dateTimeSort, startDateTime, endDateTime, ids, localState, filters) => {
  const accessToken = JwtUtils.loadToken()
  const url = `${path}local/getValueInfo`
  const config = {
    method: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${accessToken}`,
    },
    data: {
      user_id: user_id,
      dateTimeSort: dateTimeSort,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      ids: ids,
      local_status: localState,
      requestMsg: {
        ...filters,
      },
    },
  }

  // Construct the request body directly within the axios call
  return axios(config)
    .then((response) => {
      const result = response.data
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }
      return result.data // Return the data part of the result
    })
    .catch((error) => {
      // Assuming AxiosCommon.handleAxiosError is a shared utility function for handling errors
      AxiosCommon.handleAxiosError(error)
      // Depending on how AxiosCommon.handleAxiosError is implemented, you might need to re-throw the error or handle it differently here.
      // If it simply logs errors, you might still want to throw an error to ensure the calling function is aware an error occurred.
      throw error // Re-throw after logging/handling to ensure caller is aware of the failure
    })
}
