import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as reportConstant from '../../../constants/SettingsConstants'
import { useCallback, useEffect, useState } from 'react'
import { useApi } from 'src/contexts/api'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import ListSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import TextBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxSimpleItem'

export default function SettingsSelectTemplate(pageKey) {
  const dispatch = useDispatch()
  const { spring } = useApi()
  const pageState = useSelector((state) => state.common.pages[pageKey])
  const configState = useSelector((state) => state.common.pages.configuration)

  const [savedTemplate, setSavedTemplate] = useState([{ name: 'New Template', isDeleteAllowed: false, data: [] }])
  const [selectedTemplate, setSelectedTemplate] = useState([])

  useEffect(() => {
    console.log('useEffect SavedTemplate')
    const fetchTemplates = async () => {
      const templates = await spring.getReportTemplates()
      const templatesWithDeleteFlag = templates.map((template) => ({
        ...template,
        isDeleteAllowed: true, // or some logic to determine if delete is allowed
      }))
      setSavedTemplate((prevTemplates) => [...prevTemplates, ...templatesWithDeleteFlag])
    }
    fetchTemplates()
  }, [])

  const itemRender = (e, index, c) => {
    const item = e
    return (
      <div key={index} style={{ width: '100%', height: '100%', display: 'flex' }}>
        <div style={{ width: '50%', height: '100%' }}>{item.name}</div>
        <div style={{ width: '50%', height: '100%' }}>{item.hint}</div>
      </div>
    )
  }

  const handleExtraLabelDelete = (e) => {
    const next = produce(pageState, (draft) => {
      draft.page.xAxisLabelItems = draft.page.xAxisLabelItems.filter((element) => element !== e.itemData.value)
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxExtraLabelValueChanged = (e, dataField) => {
    const value = e.itemData.value
    const isExist = pageState.page[dataField].some((item) => item === value)
    if (!isExist) {
      const next = produce(pageState, (draft) => {
        draft.page[dataField].push(value)
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  const onRadioGroupDateTimeFormatValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onCheckBoxDenseLabelEnableValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const templateItemRender = (cell) => {
    return <div>{cell.data}</div>
  }

  const handleItemRendered = (e) => {
    if (!e.itemData.isDeleteAllowed) {
      var deleteElement = e.itemElement.getElementsByClassName('dx-list-static-delete-button-container')
      deleteElement[0].style.display = 'none'
    }
  }

  const handleFileNameValueChanged = (e) => {
    setSelectedTemplate((prev) => (prev.fileNAme = e.value))
  }

  const onSelectionChanged = useCallback((e) => {
    // setDefaultSelectedRowKeys(e.selectedRowKeys)
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page.selectedTemplate = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }, [])

  return (
    <GroupItem key={`extra-x-axis-label-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + reportConstant.savedTemplateKey)}>
      {/* <EmptyItem /> */}
      {ListSimpleItem({
        colSpan: 2,
        label: '',
        labelLocation: 'top',
        dataSource: savedTemplate,
        itemRender: itemRender,
        allowItemDeleting: true,
        itemDeleteMode: 'static',
        showSelectionControls: true,
        selectionMode: 'single',
        selectedRowKeys: pageState.page.selectedTemplate,
        noDataText: dqTranslateMsg('SidePanel_' + 'NoData'),
        onItemDeleted: handleExtraLabelDelete,
        onItemRendered: handleItemRendered,
        onSelectionChanged: onSelectionChanged,
        onReorder: null,
        backgroundCondition: true,
      })}
      {ButtonSimpleItem({
        colSpan: 1,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Refresh'),
        icon: 'refresh',
        width: '100%',
        height: '100%',
        // onClick: onButtonLayoutSaveClicked,
      })}
      {ButtonSimpleItem({
        colSpan: 1,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Load'),
        icon: 'importselected',
        width: '100%',
        height: '100%',
        // onClick: onButtonLayoutSaveClicked,
      })}
      {TextBoxSimpleItem({
        colSpan: 2,
        label: 'Template Name',
        labelLocation: 'left',
        value: selectedTemplate.length > 0 ? selectedTemplate[0].fileName : '',
        disabled: false,
        onValueChanged: handleFileNameValueChanged,
      })}
      {/* {TextBoxSimpleItem({
        colSpan: 2,
        label: 'Hint',
        labelLocation: 'left',
        value: selectedTemplate.length > 0 ? selectedTemplate[0].hint : '',
        disabled: false,
        onValueChanged: handleFileHintValueChanged,
      })} */}
    </GroupItem>
  )
}
