// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-panel-database-container {
  width: 100%;
  height: 100%;
}
.side-panel-database-container .side-panel-database-grid-container {
  width: 100%;
  height: 100%;
}
.side-panel-database-container .dx-button {
  width: 14px;
  height: 14px;
  min-width: 14px;
}
.side-panel-database-container .dx-button .dx-icon {
  padding: 0px;
  width: 100%;
  height: 100%;
}
.side-panel-database-container .dx-button-has-icon .dx-button-content {
  padding: 0px;
}
.side-panel-database-container .btn-clicked .dx-icon {
  border-color: red;
  color: red;
}
.side-panel-database-container .btn-not-clicked .dx-icon {
  border-color: black;
  color: gray;
}
.side-panel-database-container .dx-treelist .dx-row > td {
  padding-top: 5px;
  padding-bottom: 5px;
}
.side-panel-database-container .dx-treelist .dx-data-row > td {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  line-height: 16px;
}
.side-panel-database-container .dx-treelist .dx-header-row > td {
  line-height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/side-panel-form/component/side-panel-database-form/SidePanelDatabaseForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,eAAA;AAAJ;AAGE;EACE,YAAA;EACA,WAAA;EACA,YAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,iBAAA;EACA,UAAA;AAHJ;AAKE;EACE,mBAAA;EACA,WAAA;AAHJ;AAME;EACE,gBAAA;EACA,mBAAA;AAJJ;AAOE;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AALJ;AAQE;EACE,iBAAA;AANJ","sourcesContent":[".side-panel-database-container {\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  .side-panel-database-grid-container {\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n\r\n  .dx-button {\r\n    width: 14px;\r\n    height: 14px;\r\n    min-width: 14px;\r\n  }\r\n\r\n  .dx-button .dx-icon {\r\n    padding: 0px;\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n\r\n  .dx-button-has-icon .dx-button-content {\r\n    padding: 0px;\r\n  }\r\n\r\n  .btn-clicked .dx-icon {\r\n    border-color: red;\r\n    color: red;\r\n  }\r\n  .btn-not-clicked .dx-icon {\r\n    border-color: black;\r\n    color: gray;\r\n  }\r\n\r\n  .dx-treelist .dx-row > td {\r\n    padding-top: 5px;\r\n    padding-bottom: 5px;\r\n  }\r\n\r\n  .dx-treelist .dx-data-row > td {\r\n    padding-top: 5px;\r\n    padding-bottom: 5px;\r\n    font-size: 12px;\r\n    line-height: 16px;\r\n  }\r\n\r\n  .dx-treelist .dx-header-row > td {\r\n    line-height: 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
