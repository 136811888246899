import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { msgType } from './table/MessageTypeTable'
import { pageType } from './table/PageTypeTable'

/**
 * API for Normal Massage with Translation
 */

export const getMsg = (pType, mType, key) => {
    let prefix = ''
    let fullKey = ''

    if (mType === null || key === null) {
        console.error('mType or Key cannot be null')
        return 'Msg Load Fail'
    }

    if (pType === null) {
        fullKey = `${mType}_${key}`
    } else {
        prefix = `${pType}`
        fullKey = `${prefix}_${mType}_${key}`
    }

    return dqTranslateMsg(fullKey)
}

/**
 * API For Error Massage (Rest)
 */

const makeErrorKey = (type, input) => {
    try {
        if (typeof type !== 'number' || (type !== 0 && type !== 1)) {
            throw new Error('Invalid type, must be 0 or 1.')
        }

        if (typeof input !== 'number') {
            throw new Error('Invalid input type, must be a number.')
        }

        if (input <= 0 || !Number.isInteger(input)) {
            throw new Error('Input must be a positive integer.')
        }

        let errorCode = 'E' + type + String(input).padStart(4, '0')

        return errorCode
    } catch (error) {
        return `Exception makeErrorKey: ${error.message}`
    }
}

/**
 * API For Error Massage (for Communication Error)
 */

export const getErrCommMsg = (errorNum) => {
    const key = makeErrorKey(1, errorNum)
    return getMsg(null, msgType.Comm, key)
}

/**
 * API For Error Massage (for Processing Error)
 */

export const getErrProcMsg = (errorNum) => {
    return getMsg(null, msgType.Proc, errorNum)
}

/**
 * API For Normal Massage (for Page)
 */

export const getPageMsg = (mType, key) => {
    return getMsg(pageType.Page, mType, key)
}

/**
 * API For Normal Massage (for Common)
 */

export const getCommonMsg = (mType, key) => {
    return getMsg(pageType.Common, mType, key)
}

/**
 * API For DB Massage (for description)
 */

export const getDBFieldDescMsg = (field, key) => {
    const addFieldStr = `${field}_${key}`
    return getMsg(pageType.Common, msgType.DBFieldDesc, addFieldStr)
}

/**
 * API For DB Massage (for field)
 */

export const getDBFieldMsg = (field, key) => {
    const addFieldStr = `_${field}_${key}`
    return getMsg(pageType.Common, msgType.DBField, addFieldStr)
}
