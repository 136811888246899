// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.dx-theme-background-color .summary-list-grid-container {
  width: 100%;
  max-width: none !important;
  height: 100%;
}
.dx-theme-background-color .summary-list-grid-container .dx-header-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  height: 26px;
  font-size: 13px;
  vertical-align: middle;
}
.dx-theme-background-color .summary-list-grid-container .dx-group-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  vertical-align: middle;
}
.dx-theme-background-color .summary-list-grid-container .dx-data-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  vertical-align: middle;
}
.dx-theme-background-color .summary-list-cp-cpk-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

#styled-spark-line {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/list-summary/components/main-view/SummaryListForm.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AAAF;AAEE;EACE,WAAA;EACA,0BAAA;EACA,YAAA;AAAJ;AAGE;EAEE,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;AAFJ;AAKE;EAEE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;AAJJ;AAOE;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;AALJ;AAQE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;AANJ;;AAUA;EACE,WAAA;EACA,YAAA;AAPF","sourcesContent":["// .summary-list-form-container {\r\n.dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n\r\n  .summary-list-grid-container {\r\n    width: 100%;\r\n    max-width: none !important;\r\n    height: 100%;\r\n  }\r\n\r\n  .summary-list-grid-container .dx-header-row td {\r\n    // background-color: rgba(27, 197, 189, 0.7);\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    height: 26px;\r\n    font-size: 13px;\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .summary-list-grid-container .dx-group-row td {\r\n    // background-color: rgba(27, 197, 189, 0.17);\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    font-size: 12px;\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .summary-list-grid-container .dx-data-row td {\r\n    padding-top: 1px;\r\n    padding-bottom: 1px;\r\n    font-size: 12px;\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .summary-list-cp-cpk-container {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n}\r\n\r\n#styled-spark-line {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
