import { EmptyItem, GroupItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'

export default function DisplayCount(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'monitoring'
  const handleNumberBoxMaxDisplayCountChange = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page.monitoring[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`display-count-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'MaxDisplayCount')}>
      <EmptyItem />
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Count'),
        labelLocation: 'top',
        name: 'maxDisplayCount',
        min: 1,
        max: 200,
        step: 1,
        value: pageState.page.maxDisplayCount,
        disabled: pageState.page.monitoringInterval.type !== 'custom',
        onValueChanged: (e) => handleNumberBoxMaxDisplayCountChange(e, 'maxDisplayCount'),
      })}
    </GroupItem>
  )
}
