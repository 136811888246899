// import 'devextreme/dist/css/dx.common.css'
// import './themes/generated/theme.base.css'

import { HashRouter as Router } from 'react-router-dom'

import { AuthProvider } from './contexts/auth'
import { NavigationProvider } from './contexts/navigation'
import './dx-styles.scss'
import './themes/generated/theme.additional.css'
import { useScreenSizeClass } from './utils/media-query'
// ** Theme Imports
import { ThemeProvider } from './contexts/theme'
// ** Store Imports
import * as echarts from 'echarts/core'
import langJA from 'echarts/lib/i18n/langJA'
import langKO from 'echarts/lib/i18n/langKO'
import { Provider } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
import { store } from 'src/store'
import ConnectedApp from './ConnectedApp'
import ApiProvider from './contexts/api'
import { LanguageProvider } from './contexts/languages'

echarts.registerLocale('KO', langKO)
echarts.registerLocale('JA', langJA)
smoothscroll.polyfill()

const object = {
    host: process.env.REACT_APP_HOST,
    password: process.env.REACT_APP_PASSWORD,
    abc: process.env.REACT_APP_ABC,
}

export default function Root() {
    const screenSizeClass = useScreenSizeClass()

    return (
        <Router>
            <Provider store={store}>
                <ApiProvider>
                    <AuthProvider>
                        <LanguageProvider>
                            <ThemeProvider>
                                <NavigationProvider>
                                    <div className={`app ${screenSizeClass}`}>
                                        <ConnectedApp />
                                    </div>
                                </NavigationProvider>
                            </ThemeProvider>
                        </LanguageProvider>
                    </AuthProvider>
                </ApiProvider>
            </Provider>
        </Router>
    )
}
