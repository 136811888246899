import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { authLogin } from 'src/api/jwt'
import { getUserInfo } from 'src/api/user'

import { LoadPanel } from 'devextreme-react'
import { useNavigate } from 'react-router-dom'
import ApiStatus from 'src/api/defines/ApiStatus'
import * as dtoUtils from 'src/dto/DTOImpl'
import JwtUtils from 'src/functions/JwtUtils'
import { convertUserRoleToString } from 'src/functions/constants/authLevelConstants'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as rdCharRowData from 'src/store/charts-raw-data'
import * as rd from 'src/store/common'
import { getCurUser } from 'src/pages/components/dq-settings/DQSettingsImpl'
import { getErrCommMsg, getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { removeUserToLS, saveUserToLS } from 'src/pages/components/dq-settings/dq-local-storage/DQLocalStorageImpl'
import { getAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { companyPlantTbDescription } from 'src/functions/constants/companyPlantDBfieldDescription'
import { groupTbDescription } from 'src/functions/constants/groupDBFieldDescription'
import { defaultCatalogs, defaultEncoding, defaultUseFieldList } from 'src/pages/config/components/settings/ConfigSettings'
import { defaultDBConfig, defaultLanguage, defaultPermissions, defaultRibbon } from 'src/pages/admin/components/settings/AdminSettings'
import { useApi } from './api'
import { getAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
// import { useLanguage } from 'src/contexts/languages'

function AuthProvider({ children }) {
    const { spring } = useApi()
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true)
    // const { language, switchLanguage } = useLanguage()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)

        if (!getCurUser()) {
            setLoading(false)
            return
        }

        const at = JwtUtils.loadToken()
        if (!at) {
            setLoading(false)
            return
        }

        const decoded = JwtUtils.parseJwtToken(at)

        if (JwtUtils.isTokenExpired(decoded)) {
            setUser(undefined)
            setLoading(false)
            JwtUtils.removeAccessToken()
            removeUserToLS()
            alert(getErrProcMsg(500))
            return
        }

        getUserInfo(decoded.userId).then(async (result) => {
            if (!ApiStatus.isSuccess(result.status)) {
                alert(getErrCommMsg(result.status))
                setUser(undefined)
                setLoading(false)
                JwtUtils.removeAccessToken()
                removeUserToLS()
                return
            }

            const userInfo = result.data.user_info
            const uiDTO = dtoUtils.makeUserInfoToDTOFormat(userInfo)
            const updatedUserInfo = {
                ...uiDTO,
                user_role: convertUserRoleToString(uiDTO.user_role),
            }

            // JSON 파싱을 위한 헬퍼 함수
            const parseJSONOrDefault = (data, defaultVal) => (data ? JSON.parse(data) : defaultVal)

            const overviewSet = parseJSONOrDefault(uiDTO.chart_config_1, ps.overview.settings)
            const monitoringSet = parseJSONOrDefault(uiDTO.chart_config_2, ps.monitoring.settings)
            const databaseSet = parseJSONOrDefault(uiDTO.chart_config_3, ps.database.settings)
            const valueChartSet = parseJSONOrDefault(uiDTO.chart_config_4, ps.valueChart.settings)
            const histogramChartSet = parseJSONOrDefault(uiDTO.chart_config_5, ps.histogramChart.settings)
            const positionalTolerancesChartSet = parseJSONOrDefault(uiDTO.chart_config_6, ps.positionalTolerancesChart.settings)
            const boxPlotChartSet = parseJSONOrDefault(uiDTO.chart_config_7, ps.boxPlotChart.settings)
            const linearRegressionChartSet = parseJSONOrDefault(uiDTO.chart_config_8, ps.linearRegressionChart.settings)
            const correlationChartSet = parseJSONOrDefault(uiDTO.chart_config_9, ps.correlationChart.settings)
            const qualityControlChartSet = parseJSONOrDefault(uiDTO.chart_config_10, ps.qualityControlChart.settings)
            const capabilitySummaryChartSet = parseJSONOrDefault(uiDTO.chart_config_11, ps.capabilitySummaryChart.settings)
            const paretoChartSet = parseJSONOrDefault(uiDTO.chart_config_12, ps.paretoChart.settings)
            const probabilityPlotChartSet = parseJSONOrDefault(uiDTO.chart_config_13, ps.probabilityPlotChart.settings)
            const decisionTreeChartSet = parseJSONOrDefault(uiDTO.chart_config_14, ps.decisionTreeChart.settings)
            const valueListSet = parseJSONOrDefault(uiDTO.chart_config_15, ps.valueList.settings)
            const summaryListSet = parseJSONOrDefault(uiDTO.chart_config_16, ps.summaryList.settings)
            const valueSummaryListSet = parseJSONOrDefault(uiDTO.chart_config_17, ps.valueSummaryList.settings)
            const scrollBoardSet = parseJSONOrDefault(uiDTO.chart_config_18, ps.scrollBoard.settings)
            let configurationSet = parseJSONOrDefault(uiDTO.chart_config_19, ps.configuration.settings)
            const adminSettingsSet = parseJSONOrDefault(uiDTO.chart_config_20, ps.adminSettings.settings)
            const reportPrintMultiFunctionSet = parseJSONOrDefault(uiDTO.chart_config_21, ps.reportPrintMultiFunction.settings)
            const savedQuickFilterList = parseJSONOrDefault(uiDTO.chart_config_29, [])
            const savedAdvancedFilterList = parseJSONOrDefault(uiDTO.chart_config_30, [])

            const langType = uiDTO.language || 'en'

            const company = uiDTO?.user_company
            const plant = uiDTO?.user_plant
            const group = uiDTO?.user_group

            const targetPlantInfo = await getAdminCompany(spring, makeGetAdminCompanyPrms(company, plant))
            let adminCompanyDTO = null
            if (!targetPlantInfo) {
                alert('Plant info is not exist')
                return
            }
            adminCompanyDTO = dtoUtils.makeAdminCompanyToDTOFormat(targetPlantInfo)

            const targetGroupInfo = await getAdminGroup(spring, makeGetAdminGroupPrms(company, plant, group))
            let adminGroupDTO = null
            if (!targetGroupInfo) {
                alert('Group info is not exist')
                return
            }
            adminGroupDTO = dtoUtils.makeAdminGroupToDTOFormat(targetGroupInfo)

            const languageDataStr = adminCompanyDTO[companyPlantTbDescription.language]
            let languageData = null
            if (languageDataStr === '' || languageDataStr === null) {
                console.error('plant language data is empty')
                languageData = defaultLanguage
            } else {
                languageData = JSON.parse(languageDataStr)
            }

            let useFieldsData = null
            const useFieldsDataStr = adminCompanyDTO[companyPlantTbDescription.useFields]
            if (useFieldsDataStr === '' || useFieldsDataStr === null) {
                console.error('plant language data is empty')
                useFieldsData = defaultUseFieldList
            } else {
                useFieldsData = JSON.parse(useFieldsDataStr)
            }

            let catalogsData = null
            const catalogsDataStr = adminCompanyDTO[companyPlantTbDescription.catalogs]
            if (catalogsDataStr === '' || catalogsDataStr === null) {
                console.error('plant language data is empty')
                catalogsData = defaultCatalogs
            } else {
                catalogsData = JSON.parse(catalogsDataStr)
            }

            let ribbonData = null
            const ribbonDataStr = adminGroupDTO[groupTbDescription.ribbon]
            if (ribbonDataStr === '' || ribbonDataStr === null) {
                console.warn(`${company} / ${plant} / ${group}  find distribution data is empty`)
                ribbonData = defaultRibbon.default
            } else {
                ribbonData = JSON.parse(ribbonDataStr)
            }

            let permissionsData = null
            const permissionsDataStr = adminGroupDTO[groupTbDescription.permissions]
            if (permissionsDataStr === '' || permissionsDataStr === null) {
                console.warn(`${company} / ${plant} / ${group}  find distribution data is empty`)
                permissionsData = defaultPermissions(company, plant, group)
            } else {
                permissionsData = JSON.parse(permissionsDataStr)
            }

            let dbConfigData = null
            const dbConfigDataStr = adminGroupDTO[groupTbDescription.dbConfig]
            if (dbConfigDataStr === '' || dbConfigDataStr === null) {
                console.warn(`${company} / ${plant} / ${group}  find distribution data is empty`)
                dbConfigData = defaultDBConfig
            } else {
                dbConfigData = JSON.parse(dbConfigDataStr)
            }

            let encodingData = null
            const enCodingDataStr = adminGroupDTO[groupTbDescription.encoding]
            if (enCodingDataStr === '' || enCodingDataStr === null) {
                console.warn(`${company} / ${plant} / ${group}  find distribution data is empty`)
                encodingData = defaultEncoding
            } else {
                encodingData = JSON.parse(enCodingDataStr)
            }

            batch(() => {
                dispatch(rd.setMode('sign-in'))
                dispatch(ps.overview.setPageSettingsToRedux(overviewSet))
                dispatch(ps.monitoring.setPageSettingsToRedux(monitoringSet))
                dispatch(ps.database.setPageSettingsToRedux(databaseSet))
                // dispatch(ps.database.setPageSavedFilterListToRedux(databaseSavedFilterList))
                dispatch(ps.valueChart.setPageSettingsToRedux(valueChartSet))
                dispatch(ps.histogramChart.setPageSettingsToRedux(histogramChartSet))
                dispatch(ps.positionalTolerancesChart.setPageSettingsToRedux(positionalTolerancesChartSet))
                dispatch(ps.boxPlotChart.setPageSettingsToRedux(boxPlotChartSet))
                dispatch(ps.linearRegressionChart.setPageSettingsToRedux(linearRegressionChartSet))
                dispatch(ps.correlationChart.setPageSettingsToRedux(correlationChartSet))
                dispatch(ps.qualityControlChart.setPageSettingsToRedux(qualityControlChartSet))
                dispatch(ps.capabilitySummaryChart.setPageSettingsToRedux(capabilitySummaryChartSet))
                dispatch(ps.paretoChart.setPageSettingsToRedux(paretoChartSet))
                dispatch(ps.probabilityPlotChart.setPageSettingsToRedux(probabilityPlotChartSet))
                dispatch(ps.decisionTreeChart.setPageSettingsToRedux(decisionTreeChartSet))
                dispatch(ps.valueList.setPageSettingsToRedux(valueListSet))
                dispatch(ps.summaryList.setPageSettingsToRedux(summaryListSet))
                dispatch(ps.valueSummaryList.setPageSettingsToRedux(valueSummaryListSet))
                dispatch(ps.scrollBoard.setPageSettingsToRedux(scrollBoardSet))
                dispatch(ps.configuration.setPageSettingsToRedux(configurationSet))
                dispatch(ps.adminSettings.setPageSettingsToRedux(adminSettingsSet))
                dispatch(ps.reportPrintMultiFunction.setPageSettingsToRedux(reportPrintMultiFunctionSet))
                dispatch(rd.setEncoding(encodingData))
                dispatch(rd.setUseFieldList(useFieldsData))
                dispatch(rd.setCatalogs(catalogsData))
                dispatch(rd.setDBConfig(dbConfigData))
                dispatch(rd.setRibbon(ribbonData))
                dispatch(rd.setPermissions(permissionsData))
                dispatch(rd.setLanguageData(languageData))
                dispatch(rd.setDatabaseSavedQuickFilterList(savedQuickFilterList))
                dispatch(rd.setDatabaseSavedAdvancedFilterList(savedAdvancedFilterList))
                dispatch(rd.setLanguage(langType))

                setUser(updatedUserInfo)
                saveUserToLS({
                    user_id: uiDTO.user_id,
                    user_company: uiDTO.user_company,
                    user_plant: uiDTO.user_plant,
                    user_group: uiDTO.user_group,
                })
                setLoading(false)
            })
        })
    }, [])

    const signIn = useCallback(async (email, password) => {
        try {
            let result = await authLogin(email, password)
            if (!ApiStatus.isSuccess(result.status)) {
                alert(getErrCommMsg(result.status))
                return null
            }

            JwtUtils.saveAccessToken(result.data.token)
            console.error(`savedToken : ${result.data.token}`)
            result = await getUserInfo(email, password)
            if (!ApiStatus.isSuccess(result.status)) {
                alert(getErrCommMsg(result.status))
                return null
            }

            const userInfo = result.data.user_info
            const uiDTO = dtoUtils.makeUserInfoToDTOFormat(userInfo)

            return uiDTO
        } catch (error) {
            signOut()
            alert(error)
        }
    }, [])

    const signOut = useCallback(() => {
        dispatch(rdCharRowData.setChartRowInitialization())
        dispatch(rd.setInitialization())
        setUser(undefined)
        setLoading(false)
        JwtUtils.removeAccessToken()
        removeUserToLS()
    }, [])

    if (loading) {
        return <LoadPanel />
    }

    return <AuthContext.Provider value={{ user, setUser, signIn, signOut, loading }}>{children}</AuthContext.Provider>
}
const AuthContext = createContext({ loading: false })
const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
