import React from 'react'
import 'src/functions/HeaderIcon.scss'
import './chart-value.scss'
import ValueChartForm from './components/main-view/ValueChartForm'
import PageContainer from 'src/functions/PageContainer'

function ValueChart(pageKey) {
  return <PageContainer pageKey={pageKey} ChartFormComponent={ValueChartForm} />
}

export default ValueChart
