import { Column, Selection, TreeList } from 'devextreme-react/tree-list'
// import { Column, DataGrid, Editing, ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, SearchPanel, Position, Selection, StateStoring } from 'devextreme-react/data-grid'
import { Grid } from '@mui/material'
import { Button } from 'devextreme-react/button'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useApi } from 'src/contexts/api'
import { useAuth } from 'src/contexts/auth'
import { defCharField } from 'src/default/field/char'
import { defPartField } from 'src/default/field/part'
import { defValueField } from 'src/default/field/value'
import DQToast from 'src/functions/DQToast'
import TextTitle from 'src/functions/TextTitle'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { defaultUseFieldList } from 'src/pages/config/components/settings/ConfigSettings'
import './PushSettingsUseFields.scss'
import { defaultCharField, defaultPartField, defaultValueField } from './defines/default'

const makeUseFieldForDataGrid = (targetFieldArray, useFieldArray, rmFieldArray) => {
    const fields = targetFieldArray

    const removedFields = fields.filter((field) => !rmFieldArray.includes(field.dataField))

    let useFields = []
    if (useFieldArray.length > 0) {
        useFields = removedFields.filter((field) => useFieldArray.includes(field.dataField))
    } else {
        useFields = removedFields
    }

    const dataSource = useFields.map((field, index) => ({
        ID: index + 1,
        ...field,
    }))

    return dataSource
}

const filterPartSelectedKeys = (saved, initList, listAll) => {
    let selectedKeys = []

    if (saved.length > 0) {
        const savedValues = saved.map((item) => item.value)
        const filteredList = listAll.filter((item) => savedValues.includes(item.value))
        selectedKeys = filteredList.map((item) => item.ID).filter((item) => item !== undefined)
    }
    return selectedKeys
}

export default function PushSettingsUseFields(props) {
    const { user } = useAuth()
    const { spring } = useApi()
    const dispatch = useDispatch()
    const common = useSelector((state) => state.common)
    const toastRef = useRef(null)
    const configPartList = useRef(null)
    const configCharList = useRef(null)
    const configValueList = useRef(null)

    // const curPage = common.pages.configuration
    const curPage = common.pages.configuration
    const configAll = common.base

    const stateAll = props.state
    const state = props.state.useFieldList
    const setState = props.setState

    const [tempState, setTempState] = useState(state)
    const [partDataSource, setPartDataSource] = useState([])
    const [charDataSource, setCharDataSource] = useState([])
    const [valueDataSource, setValueDataSource] = useState([])
    const [partSelectedKeys, setPartSelectedKeys] = useState([])
    const [charSelectedKeys, setCharSelectedKeys] = useState([])
    const [valueSelectedKeys, setValueSelectedKeys] = useState([])

    useEffect(() => {
        const useFieldList = tempState

        if (useFieldList) {
            const partDataSource = makeUseFieldForDataGrid(defPartField(), [], [])
            const charDataSource = makeUseFieldForDataGrid(defCharField(), [], [])
            const valueDataSource = makeUseFieldForDataGrid(defValueField(), [], [])

            const partSelectedKeys = filterPartSelectedKeys(useFieldList.part, defaultPartField, partDataSource)
            const charSelectedKeys = filterPartSelectedKeys(useFieldList.char, defaultCharField, charDataSource)
            const valueSelectedKeys = filterPartSelectedKeys(useFieldList.value, defaultValueField, valueDataSource)

            batch(() => {
                setPartDataSource(partDataSource)
                setCharDataSource(charDataSource)
                setValueDataSource(valueDataSource)
                setPartSelectedKeys(partSelectedKeys)
                setCharSelectedKeys(charSelectedKeys)
                setValueSelectedKeys(valueSelectedKeys)
            })
        }
    }, [tempState])

    const handleSaveButton = async () => {
        const selectedPartFieldRow = configPartList.current.instance.getSelectedRowsData()
        const selectedCharFieldRow = configCharList.current.instance.getSelectedRowsData()
        const selectedValueFieldRow = configValueList.current.instance.getSelectedRowsData()

        const next = produce(stateAll, (draft) => {
            draft.useFieldList.part = selectedPartFieldRow
            draft.useFieldList.char = selectedCharFieldRow
            draft.useFieldList.value = selectedValueFieldRow
        })
        setState(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }
    const handleResetButton = async () => {
        const next = produce(stateAll, (draft) => {
            draft.useFieldList = defaultUseFieldList
        })
        setState(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    const translateFieldText = (type, cell) => {
        const translated = dqTranslateMsg('Db_field_' + type + '_' + cell.data.text)
        return <div>{translated}</div>
    }

    const handlePartSelectionChanged = (e) => {
        const selectedRowData = e.selectedRowsData
        const next = produce(tempState, (draft) => {
            draft.part = selectedRowData
        })

        setTempState(next)
    }
    const handleCharSelectionChanged = (e) => {
        const selectedRowData = e.selectedRowsData
        const next = produce(tempState, (draft) => {
            draft.char = selectedRowData
        })
        setTempState(next)
    }
    const handleValueSelectionChanged = (e) => {
        const selectedRowData = e.selectedRowsData
        const next = produce(tempState, (draft) => {
            draft.value = selectedRowData
        })
        setTempState(next)
    }

    return (
        <div className='push-settings-use-fields-container'>
            <DQToast ref={toastRef} />
            <div className='push-settings-use-fields-control-container'>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='save'
                        text={dqTranslateMsg('Page_Configuration_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            <div className='push-settings-use-fields-content-container'>
                <Grid
                    container
                    spacing={0}
                    style={{ width: '100%', height: '600px' }}
                >
                    <Grid
                        item
                        xs={4}
                        style={{ padding: '3px', width: '100%', height: '600px', display: 'flex', flexDirection: 'column' }}
                    >
                        <div className='push-settings-title-container'>
                            <TextTitle
                                text={dqTranslateMsg('Page_Configuration_' + 'PartField')}
                                fontSize={'16px'}
                                textAlign={'left'}
                            />
                        </div>
                        <div className='push-settings-datagrid-content'>
                            <TreeList
                                ref={configPartList}
                                style={{ width: '100%', height: '100%' }}
                                dataSource={partDataSource}
                                showRowLines={true}
                                showBorders={true}
                                columnAutoWidth={true}
                                selectedRowKeys={partSelectedKeys}
                                keyExpr='ID'
                                parentIdExpr='HeadID'
                                scrolling={{
                                    columnRenderingMode: 'standard',
                                    rowRenderingMode: 'standard',
                                    mode: 'standard',
                                    preloadEnabled: false,
                                    renderAsync: false,
                                    showScrollbar: 'onHover',
                                    useNative: false,
                                }}
                                onSelectionChanged={handlePartSelectionChanged}
                            >
                                <Selection
                                    recursive={true}
                                    mode='multiple'
                                />
                                <Column
                                    dataField='text'
                                    caption={dqTranslateMsg('Page_Configuration_' + 'PartField')}
                                />
                                <Column
                                    dataField='text'
                                    caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                                    cellRender={(cell) => translateFieldText('Part', cell)}
                                />
                            </TreeList>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ padding: '3px', width: '100%', height: '600px', display: 'flex', flexDirection: 'column' }}
                    >
                        <div className='push-settings-title-container'>
                            <TextTitle
                                text={dqTranslateMsg('Page_Configuration_' + 'CharacteristicsField')}
                                fontSize={'16px'}
                                textAlign={'left'}
                            />
                        </div>
                        <div className='push-settings-datagrid-content'>
                            <TreeList
                                ref={configCharList}
                                style={{ width: '100%', height: '100%' }}
                                dataSource={charDataSource}
                                showRowLines={true}
                                showBorders={true}
                                columnAutoWidth={true}
                                selectedRowKeys={charSelectedKeys}
                                keyExpr='ID'
                                parentIdExpr='HeadID'
                                scrolling={{
                                    columnRenderingMode: 'standard',
                                    rowRenderingMode: 'standard',
                                    mode: 'standard',
                                    preloadEnabled: false,
                                    renderAsync: false,
                                    showScrollbar: 'onHover',
                                    useNative: false,
                                }}
                                onSelectionChanged={handleCharSelectionChanged}
                            >
                                <Selection
                                    recursive={true}
                                    mode='multiple'
                                />
                                <Column
                                    dataField='text'
                                    caption={dqTranslateMsg('Page_Configuration_' + 'CharacteristicsField')}
                                />
                                <Column
                                    dataField='text'
                                    caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                                    cellRender={(cell) => translateFieldText('Characteristics', cell)}
                                />
                            </TreeList>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ padding: '3px', width: '100%', height: '600px', display: 'flex', flexDirection: 'column' }}
                    >
                        <div className='push-settings-title-container'>
                            <TextTitle
                                text={dqTranslateMsg('Page_Configuration_' + 'ValueField')}
                                fontSize={'16px'}
                                textAlign={'left'}
                            />
                        </div>
                        <div className='push-settings-datagrid-content'>
                            <TreeList
                                ref={configValueList}
                                style={{ width: '100%', height: '100%' }}
                                dataSource={valueDataSource}
                                showRowLines={true}
                                showBorders={true}
                                columnAutoWidth={true}
                                selectedRowKeys={valueSelectedKeys}
                                keyExpr='ID'
                                parentIdExpr='HeadID'
                                scrolling={{
                                    columnRenderingMode: 'standard',
                                    rowRenderingMode: 'standard',
                                    mode: 'standard',
                                    preloadEnabled: false,
                                    renderAsync: false,
                                    showScrollbar: 'onHover',
                                    useNative: false,
                                }}
                                onSelectionChanged={handleValueSelectionChanged}
                            >
                                <Selection
                                    recursive={true}
                                    mode='multiple'
                                />
                                <Column
                                    dataField='text'
                                    caption={dqTranslateMsg('Page_Configuration_' + 'ValueField')}
                                />
                                <Column
                                    dataField='text'
                                    caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                                    cellRender={(cell) => translateFieldText('Value', cell)}
                                />
                            </TreeList>
                        </div>
                    </Grid>
                    {/* </div> */}
                </Grid>
            </div>
        </div>
    )
}
