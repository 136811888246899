// DB Description
export const DBPart = {
  part_id: { type: 'number', name: 'part_id', caption: 'Part ID' },
  user_id: { type: 'string', name: 'user_id', caption: 'User ID' },
  user_role: { type: 'number', name: 'user_role', caption: 'User Role' },
  local_state: { type: 'number', name: 'local_state', caption: 'Local State' },
  p1001: { type: 'string', name: 'p1001', caption: 'P1001' },
  p1002: { type: 'string', name: 'p1002', caption: 'P1002' },
  p1003: { type: 'string', name: 'p1003', caption: 'P1003' },
  p1005: { type: 'string', name: 'p1005', caption: 'P1005' },
  p1007: { type: 'string', name: 'p1007', caption: 'P1007' },
  p1008: { type: 'string', name: 'p1008', caption: 'P1008' },
  p1009: { type: 'string', name: 'p1009', caption: 'P1009' },
  p1011: { type: 'string', name: 'p1011', caption: 'P1011' },
  p1012: { type: 'string', name: 'p1012', caption: 'P1012' },
  p1013: { type: 'string', name: 'p1013', caption: 'P1013' },
  p1014: { type: 'string', name: 'p1014', caption: 'P1014' },
  p1015: { type: 'number', name: 'p1015', caption: 'P1015' },
  p1022: { type: 'string', name: 'p1022', caption: 'P1022' },
  p1032: { type: 'string', name: 'p1032', caption: 'P1032' },
  p1046: { type: 'string', name: 'p1046', caption: 'P1046' },
  p1052: { type: 'string', name: 'p1052', caption: 'P1052' },
  p1062: { type: 'string', name: 'p1062', caption: 'P1062' },
  p1072: { type: 'string', name: 'p1072', caption: 'P1072' },
  p1082: { type: 'string', name: 'p1082', caption: 'P1082' },
  p1085: { type: 'string', name: 'p1085', caption: 'P1085' },
  p1086: { type: 'string', name: 'p1086', caption: 'P1086' },
  p1087: { type: 'string', name: 'p1087', caption: 'P1087' },
  p1091: { type: 'string', name: 'p1091', caption: 'P1091' },
  p1092: { type: 'string', name: 'p1092', caption: 'P1092' },
  p1100: { type: 'string', name: 'p1100', caption: 'P1100' },
  p1101: { type: 'string', name: 'p1101', caption: 'P1101' },
  p1102: { type: 'string', name: 'p1102', caption: 'P1102' },
  p1103: { type: 'string', name: 'p1103', caption: 'P1103' },
  p1104: { type: 'string', name: 'p1104', caption: 'P1104' },
  p1203: { type: 'string', name: 'p1203', caption: 'P1203' },
  p1303: { type: 'string', name: 'p1303', caption: 'P1303' },
  p1802: { type: 'string', name: 'p1802', caption: 'P1802' },
  p1812: { type: 'string', name: 'p1812', caption: 'P1812' },
  p1822: { type: 'string', name: 'p1822', caption: 'P1822' },
  p1832: { type: 'string', name: 'p1832', caption: 'P1832' },
  p1842: { type: 'string', name: 'p1842', caption: 'P1842' },
  p1852: { type: 'string', name: 'p1852', caption: 'P1852' },
  p1862: { type: 'string', name: 'p1862', caption: 'P1862' },
  p1872: { type: 'string', name: 'p1872', caption: 'P1872' },
  p1882: { type: 'string', name: 'p1882', caption: 'P1882' },
  p1892: { type: 'string', name: 'p1892', caption: 'P1892' },
  p1900: { type: 'string', name: 'p1900', caption: 'P1900' },
  updated_datetime: {
    type: 'string',
    name: '',
    caption: 'Update DateTime',
  },
  dt_field: { type: 'string', name: 'dt_field', caption: 'Dt Field' },
}

export const DBChar = {
  part_id: { type: 'number', name: 'part_id', caption: 'Part ID' },
  char_id: { type: 'number', name: 'part_id', caption: 'Char ID' },
  group_index: { type: 'number', name: 'part_id', caption: 'Group Index' },
  c2001: { type: 'string', name: 'c2001', caption: 'C2001' },
  c2002: { type: 'string', name: 'c2002', caption: 'C2002' },
  c2003: { type: 'string', name: 'c2003', caption: 'C2003' },
  c2004: { type: 'number', name: 'c2004', caption: 'C2004' },
  c2005: { type: 'number', name: 'c2005', caption: 'C2005' },
  c2006: { type: 'number', name: 'c2006', caption: 'C2006' },
  c2007: { type: 'number', name: 'c2007', caption: 'C2007' },
  c2008: { type: 'number', name: 'c2008', caption: 'C2008' },
  c2009: { type: 'number', name: 'c2009', caption: 'C2009' },
  c2011: { type: 'number', name: 'c2011', caption: 'C2011' },
  c2015: { type: 'number', name: 'c2015', caption: 'C2015' },
  c2016: { type: 'number', name: 'c2016', caption: 'C2016' },
  c2021: { type: 'string', name: 'c2021', caption: 'C2021' },
  c2022: { type: 'number', name: 'c2022', caption: 'C2022' },
  c2035: { type: 'string', name: 'c2035', caption: 'C2035' },
  c2041: { type: 'number', name: 'c2041', caption: 'C2041' },
  c2042: { type: 'number', name: 'c2042', caption: 'C2042' },
  c2043: { type: 'string', name: 'c2043', caption: 'C2043' },
  c2051: { type: 'string', name: 'c2051', caption: 'C2051' },
  c2091: { type: 'string', name: 'c2091', caption: 'C2091' },
  c2092: { type: 'string', name: 'c2092', caption: 'C2092' },
  c2093: { type: 'string', name: 'c2093', caption: 'C2093' },
  c2100: { type: 'number', name: 'c2100', caption: 'C2100' },
  c2101: { type: 'number', name: 'c2101', caption: 'C2101' },
  c2110: { type: 'number', name: 'c2110', caption: 'C2110' },
  c2111: { type: 'number', name: 'c2111', caption: 'C2111' },
  c2112: { type: 'number', name: 'c2112', caption: 'C2112' },
  c2113: { type: 'number', name: 'c2113', caption: 'C2113' },
  c2114: { type: 'number', name: 'c2114', caption: 'C2114' },
  c2115: { type: 'number', name: 'c2115', caption: 'C2115' },
  c2116: { type: 'number', name: 'c2116', caption: 'C2116' },
  c2117: { type: 'number', name: 'c2117', caption: 'C2117' },
  c2120: { type: 'number', name: 'c2120', caption: 'C2120' },
  c2121: { type: 'number', name: 'c2121', caption: 'C2121' },
  c2130: { type: 'number', name: 'c2130', caption: 'C2130' },
  c2131: { type: 'number', name: 'c2131', caption: 'C2131' },
  c2142: { type: 'string', name: 'c2142', caption: 'C2142' },
  c2161: { type: 'number', name: 'c2161', caption: 'C2161' },
  c2162: { type: 'number', name: 'c2162', caption: 'C2162' },
  c2163: { type: 'number', name: 'c2163', caption: 'C2163' },
  c2202: { type: 'number', name: 'c2202', caption: 'C2202' },
  c2205: { type: 'number', name: 'c2205', caption: 'C2205' },
  c2213: { type: 'number', name: 'c2213', caption: 'C2213' },
  c2220: { type: 'number', name: 'c2220', caption: 'C2220' },
  c2221: { type: 'number', name: 'c2221', caption: 'C2221' },
  c2222: { type: 'number', name: 'c2222', caption: 'C2222' },
  c2225: { type: 'number', name: 'c2225', caption: 'C2225' },
  c2226: { type: 'number', name: 'c2226', caption: 'C2226' },
  c2227: { type: 'number', name: 'c2227', caption: 'C2227' },
  c2243: { type: 'string', name: 'c2243', caption: 'C2243' },
  c2244: { type: 'number', name: 'c2244', caption: 'C2244' },
  c2245: { type: 'number', name: 'c2245', caption: 'C2245' },
  c2246: { type: 'number', name: 'c2246', caption: 'C2246' },
  c2302: { type: 'string', name: 'c2302', caption: 'C2302' },
  c2402: { type: 'string', name: 'c2402', caption: 'C2402' },
  c2410: { type: 'string', name: 'c2410', caption: 'C2410' },
  c2413: { type: 'string', name: 'c2413', caption: 'C2413' },
  c2415: { type: 'string', name: 'c2415', caption: 'C2415' },
  c2802: { type: 'string', name: 'c2802', caption: 'C2802' },
  c2812: { type: 'string', name: 'c2812', caption: 'C2812' },
  c2822: { type: 'string', name: 'c2822', caption: 'C2822' },
  c2832: { type: 'string', name: 'c2832', caption: 'C2832' },
  c2842: { type: 'string', name: 'c2842', caption: 'C2842' },
  c2852: { type: 'string', name: 'c2852', caption: 'C2852' },
  c2862: { type: 'string', name: 'c2862', caption: 'C2862' },
  c2872: { type: 'string', name: 'c2872', caption: 'C2872' },
  c2882: { type: 'string', name: 'c2882', caption: 'C2882' },
  c2892: { type: 'string', name: 'c2892', caption: 'C2892' },
  c2900: { type: 'string', name: 'c2900', caption: 'C2900' },
  c2999: { type: 'number', name: 'c2999', caption: 'C2999' },
  c8006: { type: 'number', name: 'c8006', caption: 'C8006' },
  c8007: { type: 'number', name: 'c8007', caption: 'C8007' },
  c8011: { type: 'number', name: 'c8011', caption: 'C8011' },
  c8012: { type: 'number', name: 'c8012', caption: 'C8012' },
  c8013: { type: 'number', name: 'c8013', caption: 'C8013' },
  c8014: { type: 'number', name: 'c8014', caption: 'C8014' },
  c8015: { type: 'number', name: 'c8015', caption: 'C8015' },
  c8106: { type: 'number', name: 'c8106', caption: 'C8106' },
  c8107: { type: 'number', name: 'c8107', caption: 'C8107' },
  c8111: { type: 'number', name: 'c8111', caption: 'C8111' },
  c8112: { type: 'number', name: 'c8112', caption: 'C8112' },
  c8113: { type: 'number', name: 'c8113', caption: 'C8113' },
  c8114: { type: 'number', name: 'c8114', caption: 'C8114' },
  c8115: { type: 'number', name: 'c8115', caption: 'C8115' },
  c8500: { type: 'number', name: 'c8500', caption: 'C8500' },
  c8501: { type: 'number', name: 'c8501', caption: 'C8501' },
  c8531: { type: 'number', name: 'c8531', caption: 'C8531' },
  c8532: { type: 'number', name: 'c8532', caption: 'C8532' },
  c8540: { type: 'number', name: 'c8540', caption: 'C8540' },
  value_quantity: {
    type: 'number',
    name: 'value_quantity',
    caption: 'Value Quantity',
  },
  dt_field: { type: 'string', name: 'dt_field', caption: 'Dt Field' },
}

export const DBValue = {
  part_id: { type: 'number', name: 'part_id', caption: 'Part ID' },
  char_id: { type: 'number', name: 'char_id', caption: 'Char ID' },
  value_id: { type: 'number', name: 'value_id', caption: 'Value ID' },
  out_of_tol: { type: 'number', name: 'out_of_tol', caption: 'Out Of Tol' },
  local_status: {
    type: 'number',
    name: 'local_status',
    caption: 'Local Status',
  },
  nominal: { type: 'number', name: 'nominal', caption: 'Nominal' },
  v_usl: { type: 'number', name: 'v_usl', caption: 'V_USL' },
  v_lsl: { type: 'number', name: 'v_lsl', caption: 'V_LSL' },
  v0001: { type: 'number', name: 'v0001', caption: 'V0001' },
  v0002: { type: 'number', name: 'v0002', caption: 'V0002' },
  v0004: { type: 'string', name: 'v0004', caption: 'V0004' }, // LocalDateTime
  v0005: { type: 'string', name: 'v0005', caption: 'V0005' }, // many event separated by ','
  v0006: { type: 'string', name: 'v0006', caption: 'V0006' },
  v0007: { type: 'number', name: 'v0007', caption: 'V0007' },
  v0008: { type: 'number', name: 'v0008', caption: 'V0008' },
  v0009: { type: 'string', name: 'v0009', caption: 'V0009' },
  v0010: { type: 'number', name: 'v0010', caption: 'V0010' },
  v0011: { type: 'string', name: 'v0011', caption: 'V0011' },
  v0012: { type: 'number', name: 'v0012', caption: 'V0012' },
  v0014: { type: 'string', name: 'v0014', caption: 'V0014' },
  v0016: { type: 'string', name: 'v0016', caption: 'V0016' },
  v0017: { type: 'string', name: 'v0017', caption: 'V0017' },
  v0053: { type: 'string', name: 'v0053', caption: 'V0053' },
  v0054: { type: 'string', name: 'v0054', caption: 'V0054' },
  v0055: { type: 'string', name: 'v0055', caption: 'V0055' },
  v0056: { type: 'string', name: 'v0056', caption: 'V0056' },
  v0057: { type: 'string', name: 'v0057', caption: 'V0057' },
  v0058: { type: 'string', name: 'v0058', caption: 'V0058' },
  v0059: { type: 'string', name: 'v0059', caption: 'V0059' },
  v0060: { type: 'string', name: 'v0060', caption: 'V0060' },
  v0061: { type: 'string', name: 'v0061', caption: 'V0061' },
  v0062: { type: 'string', name: 'v0062', caption: 'V0062' },
  v0063: { type: 'string', name: 'v0063', caption: 'V0063' },
  dt_field: { type: 'string', name: 'dt_field', caption: 'Dt Field' },
}
