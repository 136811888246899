import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function SettingsLayout(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'paretoChart'
  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onNumberBoxLayoutColumnValueChanged = (e) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page.layout.column = value === null ? minOption : value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxLayoutRowValueChanged = (e) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page.layout.row = value === null ? minOption : value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`layout-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'Layout')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Column'),
        labelLocation: 'top',
        name: 'column',
        min: 1,
        max: 5,
        step: 1,
        value: pageState.page.layout.column,
        disabled: false,
        onValueChanged: (e) => onNumberBoxLayoutColumnValueChanged(e),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Row'),
        labelLocation: 'top',
        name: 'row',
        min: 1,
        max: 5,
        step: 1,
        value: pageState.page.layout.row,
        disabled: false,
        onValueChanged: (e) => onNumberBoxLayoutRowValueChanged(e),
      })}
    </GroupItem>
  )
}
