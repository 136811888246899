import JwtUtils from 'src/functions/JwtUtils'
import { pythonBackendPath } from './defines/PythonDBInfo'

// 파이썬용
export const getArima = async (isAuto, p, d, q, forecastSize, srcData) => {
  const requestBody = {
    p: p,
    d: d,
    q: q,
    steps: forecastSize,
    srcData: srcData,
    is_auto: isAuto,
  }

  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(pythonBackendPath + 'py/getArima', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}
