import * as probImpl from './ReportPrintProbabilityPlotChartOptionImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'

export function getProbabilityPlotChartOption(settings, configSettings, targetChar, valueArray) {
  try {
    const isExponential = configSettings.commonSettings.isExponentialActive

    const titleText = `${targetChar.c2002 ?? 'empty'}P:${targetChar.part_id ?? '?'}/C:${targetChar.char_id ?? '?'}`
    const subTitleText = ''
    const values = valueArray.valueRaw
      .filter((data) => data.v0002 === 0)
      .map((item) => {
        return item.v0001 ?? 0
      })

    const average = probImpl.calculateAverage(values)
    const median = probImpl.calculateMedian(values)
    const stdDev = probImpl.calculateStandardDeviation(values)
    const mode = probImpl.calculateMode(values)
    const minValue = probImpl.calculateMinValue(values)
    const maxValue = probImpl.calculateMaxValue(values)
    const lsl = targetChar.c2110
    const usl = targetChar.c2111
    // let nominal
    // if (lsl === null || usl === null) {
    //   nominal = null
    // } else {
    //   nominal = (lsl + usl) / 2
    // }
    const nominal = targetChar.c2101 ?? (usl + lsl) / 2
    const gap = usl - nominal
    const warnOffset = (gap * configSettings.commonSettings.warningLimitPercentage) / 100
    const lwl = lsl === null ? null : lsl + warnOffset
    const uwl = usl === null ? null : usl - warnOffset

    const x99_865 = average + configSettings.commonSettings.sigmaDegree * stdDev
    const x0_135 = average - configSettings.commonSettings.sigmaDegree * stdDev

    const min = Math.min(minValue, lsl, x0_135)
    const max = Math.max(maxValue, usl, x99_865)
    // const xAxisLeft = min - (max - min) * 0.1
    // const xAxisRight = max + (max - min) * 0.1
    const xAxisLeft = min - (max - min) * 0.1
    const xAxisRight = max + (max - min) * 0.1
    const decimalPlaces = targetChar.c2022 ?? configSettings.commonSettings.displayDecimalPlaces

    // chart setting reorganization
    const addedSettings = {
      ...settings,
      titleText: titleText,
      subTitleText: subTitleText,
      lsl: lsl,
      usl: usl,
      lwl: lwl,
      uwl: uwl,
      x0_135: x0_135,
      x99_865: x99_865,
      nominal: nominal,
      gap: gap,
      warnOffset: warnOffset,
      xAxisLeft: xAxisLeft,
      xAxisRight: xAxisRight,
    }

    return {
      title: {
        left: 'center',
        textStyle: {
          fontFamily: 'Arial',
          fontSize: 12,
        },
      },
      animationDuration: 300,
      backgroundColor: 'rgba(255, 255, 255, 0)',
      grid: {
        top: '17%',
        bottom: '10%',
        left: '3%',
        right: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'value',
          min: addedSettings.xAxisLeft,
          max: addedSettings.xAxisRight,
          boundaryGap: false,
          nameTextStyle: {
            fontFamily: 'Arial',
          },
          axisLabel: {
            show: true,
            fontFamily: 'Arial',
            fontSize: 10,
            rotate: 90,
            formatter: function (value) {
              // return DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(value, decimalPlaces), isExponential)
              return DNUtils.calcValueDisplay(value, configSettings)
            },
          },
          axisLine: {
            onZero: false,
          },
        },
      ],
      yAxis: {
        type: 'value',
        ...(settings.page.yAxisType === 'Theoretical-Value' ? { scale: true } : { min: 0, max: 100 }),
        nameTextStyle: {
          fontFamily: 'Arial',
        },
        axisLabel: {
          fontFamily: 'Arial',
          formatter: function (value) {
            // return DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(value, decimalPlaces), isExponential)
            return DNUtils.calcValueDisplay(value, configSettings)
          },
        },
        axisLine: {
          onZero: false,
        },
      },
      tooltip: {
        trigger: 'item',
        appendToBody: true,
        textStyle: {
          fontFamily: 'Arial',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        formatter: function (arg) {
          let displayStr = ''
          if (arg.componentType === 'series') {
            displayStr += arg.seriesName + '<br>'
            displayStr += `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${arg.color}; border-radius: 50%;"></span> `
            displayStr += arg.name + '&emsp;'
            // displayStr += `<b>${DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(, decimalPlaces), isExponential)}</b>`
            displayStr += `<b>${DNUtils.calcValueDisplay(arg.value[1], configSettings)}</b>`
          } else {
            displayStr += arg.name + '<br>'
            displayStr += `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${arg.borderColor}; border-radius: 50%;"></span> `
            displayStr += '&emsp;'
            // displayStr += `<b>${DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(arg.value, decimalPlaces), isExponential)}</b>`
            displayStr += `<b>${DNUtils.calcValueDisplay(arg.value, configSettings)}</b>`
          }
          return displayStr
        },
      },
      dataZoom: [
        {
          show: true,
          type: 'slider',
          orient: 'horizontal',
          height: `3%`,
          bottom: '5%',
          brushSelect: false,
          showDataShadow: true,
          showDetail: false,
          filterMode: 'none',
        },
        {
          show: true,
          type: 'inside',
          xAxisIndex: 0,
          filterMode: 'none',
        },
      ],
      series: [
        {
          type: 'line',
          name: 'Limits',
          markLine: {
            animation: false,
            symbol: 'none',
            precision: 10,
            emphasis: {
              disabled: true,
            },
          },
        },
      ],
    }
  } catch (error) {
    console.log(error.message)
  }
}
