import * as dqConvert from 'src/pages/components/dq-convert/DQConvert'
import * as pcoImpl from './ParetoChartOptionImpl'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as DBUtils from 'src/functions/DatabaseUtils'

const toolTipBalloonCallback = (args, catalogs) => {
    const data = args[0]
    return data.name + ' ' + '<br>' + data.marker + ' ' + `${dqTranslateMsg('Page_ParetoChart_' + 'Count')} : ` + data.value
    // return dqConvert.convertByCatalog('v0005', args[0].value, catalogs) + ' ' + '<br>' + `${dqTranslateMsg('Page_ParetoChart_' + 'Count')} : ` + args.data
}

export function getParetoChartOption(settings, configSettings, othersPage, char, displayData) {
    let min = 0
    let max = 0
    let seriesOption
    const titleText = `${DBUtils.getFieldData(char, 'c2002') ?? 'empty'} P:${DBUtils.getFieldData(char, 'part_id') ?? '?'} / C:${DBUtils.getFieldData(char, 'char_id') ?? '?'}`

    if (settings.page.displayType === 'event') {
        if (settings.page.displayMode === 'stack') {
            min = 0
            max = displayData.uniqueTypeCnt.reduce((acc, current) => acc + current, 0)
            // max = displayData.uniqueTypeCnt.goodCnt[0] + displayData.uniqueTypeCnt.warnCnt[0] + displayData.uniqueTypeCnt.ngCnt[0]
            seriesOption = pcoImpl.makeSeriesStackData(settings, configSettings, displayData)
        } else {
            min = 0
            // cntArray = [displayData.uniqueTypeCnt.goodCnt[0], displayData.uniqueTypeCnt.warnCnt[0], displayData.uniqueTypeCnt.ngCnt[0]]
            max = Math.max(...displayData.uniqueTypeCnt)
            seriesOption = pcoImpl.makeSeriesNotStackData(settings, configSettings, displayData)
        }
        return {
            title: {
                text: titleText,
                left: 'center',
                textStyle: {
                    fontFamily: 'Arial',
                    fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
                },
            },
            animationDuration: 300,
            grid: {
                top: '17%',
                bottom: '5%',
                left: '0%',
                right: '5%',
                containLabel: true,
            },
            animationDuration: 300,
            backgroundColor: 'rgba(255, 255, 255, 0)',
            toolbox: {
                feature: {
                    saveAsImage: {},
                },
                itemSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
            },
            tooltip: {
                trigger: 'axis',
                appendToBody: true,
                position: 'inside',
                formatter: (args) => toolTipBalloonCallback(args, othersPage.catalogs),
                textStyle: {
                    fontFamily: 'Arial',
                },
                axisPointer: {
                    type: 'shadow',
                    label: {
                        textStyle: {
                            fontFamily: 'Arial',
                        },
                    },
                },
            },
            xAxis: {
                type: 'category',
                nameGap: 30,
                data: displayData.uniqueType,
                axisLabel: {
                    fontFamily: 'Arial',
                    rotate: 90,
                    fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
                },
                axisLine: {
                    onZero: false,
                },
            },
            yAxis: {
                type: 'value',
                min: min,
                max: max,
                minInterval: 1,
                // interval: 'auto',
                splitLine: {
                    show: true,
                },
                nameTextStyle: {
                    fontFamily: 'Arial',
                },
                axisLabel: {
                    fontFamily: 'Arial',
                    fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
                },
            },
            dataZoom: [
                {
                    show: true,
                    type: 'slider',
                    orient: 'horizontal',
                    height: `3%`,
                    bottom: '1%',
                    brushSelect: false,
                    showDataShadow: true,
                    showDetail: false,
                    filterMode: 'none',
                },
                {
                    show: true,
                    type: 'inside',
                    xAxisIndex: 0,
                    filterMode: 'none',
                },
            ],

            // series: pcoImpl.makeSeriesEventData(settings, targetData),
            series: seriesOption,
        }
    } else {
        min = 0
        max = displayData.totalCnt
        const makeXAxisData = (settings) => {
            const xAxisData = []
            if (settings.page.displayDefectUnit.ng === true) {
                xAxisData.push(dqTranslateMsg('Page_ParetoChart_' + 'NGValueInfo'))
            }
            if (settings.page.displayDefectUnit.warn === true) {
                xAxisData.push(dqTranslateMsg('Page_ParetoChart_' + 'WarnValueInfo'))
            }
            if (settings.page.displayDefectUnit.good === true) {
                xAxisData.push(dqTranslateMsg('Page_ParetoChart_' + 'GoodValueInfo'))
            }

            return xAxisData
        }
        const xAxisData = makeXAxisData(settings)
        seriesOption = pcoImpl.makeSeriesLslUslNotStackData(settings, configSettings, displayData)
        return {
            title: {
                text: titleText,
                left: 'center',
                textStyle: {
                    fontFamily: 'Arial',
                    fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
                },
            },
            animationDuration: 300,
            grid: {
                top: '17%',
                bottom: '0%',
                left: '0%',
                right: '5%',
                containLabel: true,
            },
            animationDuration: 300,
            backgroundColor: 'rgba(255, 255, 255, 0)',
            toolbox: {
                feature: {
                    saveAsImage: {},
                },
                itemSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
            },
            tooltip: {
                trigger: 'axis',
                appendToBody: true,
                position: 'inside',
                formatter: (args) => toolTipBalloonCallback(args, othersPage.catalogs),
                textStyle: {
                    fontFamily: 'Arial',
                },
                axisPointer: {
                    type: 'shadow',
                    label: {
                        textStyle: {
                            fontFamily: 'Arial',
                        },
                    },
                },
            },
            xAxis: {
                type: 'category',
                nameGap: 30,
                data: xAxisData,

                axisLabel: {
                    fontFamily: 'Arial',
                    fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
                },
                axisLine: {
                    onZero: false,
                },
            },
            yAxis: {
                type: 'value',
                min: min,
                max: max,
                minInterval: 1,
                // interval: 'auto',
                splitLine: {
                    show: true,
                },
                nameTextStyle: {
                    fontFamily: 'Arial',
                },
                axisLabel: {
                    fontFamily: 'Arial',
                    fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
                },
            },

            // series: pcImpl.makeSeriesEventData(settings, targetData),
            series: seriesOption,
        }
    }
}
