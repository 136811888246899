import CCUtils from 'src/functions/CommonCalcUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'

export const calculateAverage = (data) => {
  if (data.length === 0) {
    return 0 // 빈 배열의 경우 0을 반환하거나 다른 적절한 기본값을 선택할 수 있습니다.
  }
  let sum = 0
  for (const value of data) {
    sum += value
  }
  return sum / data.length
}

// Calculate the median
export const calculateMedian = (data) => {
  const sortedData = data.slice().sort((a, b) => a - b)
  const mid = Math.floor(sortedData.length / 2)

  if (sortedData.length % 2 === 0) {
    return (sortedData[mid - 1] + sortedData[mid]) / 2
  } else {
    return sortedData[mid]
  }
}

// Calculate the standard deviation
export const calculateStandardDeviation = (data) => {
  const average = calculateAverage(data)
  const squaredDifferences = data.map((value) => Math.pow(value - average, 2))
  const sumSquaredDifferences = squaredDifferences.reduce((acc, value) => acc + value, 0)
  const variance = sumSquaredDifferences / data.length
  const standardDeviation = Math.sqrt(variance)

  return standardDeviation
}

// Calculate the mode
export const calculateMode = (data) => {
  const frequency = {}
  let maxFrequency = 0
  let mode = []

  data.forEach((value) => {
    frequency[value] = (frequency[value] || 0) + 1

    if (frequency[value] > maxFrequency) {
      maxFrequency = frequency[value]
      mode = [value]
    } else if (frequency[value] === maxFrequency) {
      mode.push(value)
    }
  })

  return mode
}

// Calculate the maximum value
export const calculateMaxValue = (data) => {
  return CCUtils.getMax(data)
}

// Calculate the minimum value
export const calculateMinValue = (data) => {
  return CCUtils.getMin(data)
}

export const getBinNumber = (valueArray) => {
  const valueCount = valueArray.length
  let binNumber = 0

  if (valueCount <= 30) binNumber = 4
  else if (valueCount <= 50) binNumber = 5
  else if (valueCount <= 100) binNumber = 6
  else if (valueCount <= 200) binNumber = 7
  else if (valueCount <= 500) binNumber = 8
  else if (valueCount <= 1000) binNumber = 9
  else if (valueCount <= 2000) binNumber = 10
  else if (valueCount <= 3000) binNumber = 11
  else if (valueCount <= 4000) binNumber = 12
  else if (valueCount <= 5000) binNumber = 13
  else binNumber = 14

  return binNumber
}

export const getBinRange = (min, max, binWidth) => {
  const binRangeArray = []
  for (let value = min; value <= max; value += binWidth) {
    binRangeArray.push(value)
  }

  return binRangeArray
}

export const makeXAxisRight = (settings, usl, gap, maxValue, maxBellCurveValue) => {
  const rightValue = CCUtils.getMax(usl, maxValue, maxBellCurveValue)
  return Number(rightValue + gap * 0.1)
}
export const makeXAxisLeft = (settings, lsl, gap, minValue, minBellCurveValue) => {
  const leftValue = CCUtils.getMin(lsl, minValue, minBellCurveValue)
  return Number(leftValue - gap * 0.1)
}

export const convertToMarkLine = (data, settings, configSettings, optionBaseData, pageKey) => {
  const dist = data.dist
  const position = 'end'
  const fontFamily = 'Arial'
  const fontSize = pageKey !== 'reportPrint' ? (15 / (settings.page.layout.row + settings.page.layout.column)) * 3 : (14 / 5) * 3

  // const createMarkLine = (limitEnable, xAxisValue, labelName, lineSettings) => ({
  const createMarkLine = (enabled, labelEnabled, labelName, xAxisValue, lineSettings, natState) => ({
    show: xAxisValue !== null && enabled,
    xAxis: xAxisValue,
    name: labelEnabled ? labelName : '',
    label: {
      position: position,
      formatter: (args) => args.name,
      fontFamily: fontFamily,
      fontSize: fontSize,
    },
    lineStyle: {
      type: natState ? 'dotted' : lineSettings?.type,
      width: lineSettings?.width,
      color: lineSettings?.color,
    },
  })

  const { specificationLimitEnable, quantileLimitEnable, nominalLimitEnable, averageLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable } = settings.page
  const { specificationLimitLabelEnable, quantileLimitLabelEnable, nominalLimitLabelEnable, averageLimitLabelEnable, plausibilityLimitLabelEnable, scrapLimitLabelEnable, acceptanceLimitLabelEnable } =
    settings.page
  const { specLimit, nominal, x0_135, x99_865, xBar, x50, plausibilityLimit, scrapLimit, acceptanceLimit } = settings.lineChart.markLines

  const sigmaLevel = configSettings.commonSettings.sigmaDegree
  // const useNat = settings.commonSettings.useNaturalBoundary
  const isUpperNat = PCUtils.getTypeUpperWarningLimit(settings.char) === 2
  const isLowerNat = PCUtils.getTypeLowerWarningLimit(settings.char) === 2

  const targetML = [
    {
      limitEnable: specificationLimitEnable,
      labelEnable: specificationLimitLabelEnable,
      name: 'LSL',
      value: settings.lsl,
      config: specLimit,
      natState: isLowerNat,
    },
    {
      limitEnable: specificationLimitEnable,
      labelEnable: specificationLimitLabelEnable,
      name: 'USL',
      value: settings.usl,
      config: specLimit,
      natState: isUpperNat,
    },
    { limitEnable: nominalLimitEnable, labelEnable: nominalLimitLabelEnable, name: 'Nominal', value: settings.nominal, config: nominal, natState: false },
    { limitEnable: averageLimitEnable, labelEnable: averageLimitLabelEnable, name: 'X\u0304', value: data.xBar, config: xBar, natState: false },
    { limitEnable: quantileLimitEnable, labelEnable: quantileLimitLabelEnable, name: dist === 1 ? `X-${sigmaLevel}σ` : `Xˡᵒ³`, value: data.x0_135, config: x0_135, natState: false },
    { limitEnable: quantileLimitEnable, labelEnable: quantileLimitLabelEnable, name: dist === 1 ? `X+${sigmaLevel}σ` : `Xᵘᵖ³`, value: data.x99_865, config: x99_865, natState: false },
    { limitEnable: dist !== 1 && quantileLimitEnable, labelEnable: quantileLimitLabelEnable, name: 'X⁵⁰', value: data.x50, config: x50, natState: false },
    { limitEnable: plausibilityLimitEnable, labelEnable: plausibilityLimitLabelEnable, name: 'UPL', value: settings.upl, config: plausibilityLimit, natState: false },
    { limitEnable: plausibilityLimitEnable, labelEnable: plausibilityLimitLabelEnable, name: 'LPL', value: settings.lpl, config: plausibilityLimit, natState: false },
    { limitEnable: scrapLimitEnable, labelEnable: scrapLimitLabelEnable, name: 'URL', value: settings.url, config: scrapLimit, natState: false },
    { limitEnable: scrapLimitEnable, labelEnable: scrapLimitLabelEnable, name: 'LRL', value: settings.lrl, config: scrapLimit, natState: false },
    { limitEnable: acceptanceLimitEnable, labelEnable: acceptanceLimitLabelEnable, name: 'UAcl', value: settings.uacl, config: acceptanceLimit, natState: false },
    { limitEnable: acceptanceLimitEnable, labelEnable: acceptanceLimitLabelEnable, name: 'LAcl', value: settings.lacl, config: acceptanceLimit, natState: false },
  ]

  // Create markLines using map
  const markLines = targetML.map((item) => createMarkLine(item.limitEnable, item.labelEnable, item.name, item.value, item.config, item.natState))

  // Filter out mark lines where show is false, and remove 'show' property
  return markLines.filter((line) => line.show).map(({ show, ...rest }) => rest)
}

export const makeSymbol = (value, params, settings) => {
  // const val = params.data.v0001
  const val = params.value[0]
  if (settings.usl === null && settings.lsl === null) {
    return settings.lineChart.symbols.good.shape
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.shape
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.good.shape
    } else {
      return settings.lineChart.symbols.error.lower.shape
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.good.shape
    } else {
      return settings.lineChart.symbols.error.lower.shape
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.shape
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.shape
    } else {
      return settings.lineChart.symbols.good.shape
    }
  }
}

export const makeSymbolSize = (value, params, settings) => {
  // const val = params.data.v0001
  const val = params.value[0]

  if (settings.usl === null && settings.lsl === null) {
    return settings.lineChart.symbols.good.size
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.size
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.size
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.size
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.good.size
    } else {
      return settings.lineChart.symbols.error.lower.size
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.size
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.good.size
    } else {
      return settings.lineChart.symbols.error.lower.size
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.size
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.size
    } else {
      return settings.lineChart.symbols.good.size
    }
  }
}

export const makeSymbolColor = (params, settings) => {
  // const val = params.data.v0001
  const val = params.value[0]
  if (settings.usl === null && settings.lsl === null) {
    return settings.lineChart.symbols.good.color
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.color
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.color
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.color
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.good.color
    } else {
      return settings.lineChart.symbols.error.lower.color
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.color
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.good.color
    } else {
      return settings.lineChart.symbols.error.lower.color
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.color
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.good.color
    } else {
      return settings.lineChart.symbols.good.color
    }
  }
}

export const convertToChartLine = (target, settings) => {
  const rtnChartLine = {
    type: settings.lineChart.chartLines[target].type,
    width: settings.lineChart.chartLines[target].width,
    color: settings.lineChart.chartLines[target].color,
  }

  return rtnChartLine
}
