import React from 'react'
import { Label, SimpleItem } from 'devextreme-react/form'
import { List, Sortable } from 'devextreme-react'
import { Popup } from 'devextreme-react'
import { Position } from 'devextreme-react/popup'
const PopupSimpleItem = ({ visible, onHiding, title, width, height, contentRender }) => (
  <Popup
    visible={visible}
    onHiding={onHiding}
    dragEnabled={false}
    hideOnOutsideClick={true}
    showCloseButton={false}
    showTitle={true}
    title={title}
    container=".dx-viewport"
    // contentRender={contentRender}
    width={width}
    height={height}
  >
    <Position my="center" at="center" of={window} />
    {contentRender()}
  </Popup>
)

export default PopupSimpleItem
