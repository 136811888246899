import { pageName } from 'src/functions/constants/pageName'
import { languageData } from 'src/pages/components/dq-convert/DQLanguage'

export const defaultLanguage = languageData

export const defaultRibbon = {
    dqs: [
        { name: pageName.overview, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.monitoring, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.database, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.charts, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.histogramChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.positionalTolerancesChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.boxPlotChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.linearRegressionChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.correlationChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.qualityControlChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.capabilitySummaryChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.paretoChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.probabilityPlotChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.decisionTreeChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.lists, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        { name: pageName.summaryList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        {
            name: pageName.valueSummaryList,
            isDataNeed: true,
            parent: pageName.lists,
            type: 'page',
        },
        { name: pageName.scrollBoard, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.reportPrint, isDataNeed: false, parent: '', type: 'page' },
        // { name: pageName.reportPrintMultiFunction, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.uploadFile, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.configuration, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.adminSettings, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.companyInfo, isDataNeed: false, parent: '', type: 'page' },
    ],
    superuser: [
        { name: pageName.overview, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.monitoring, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.database, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.charts, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.histogramChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.positionalTolerancesChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.boxPlotChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.linearRegressionChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.correlationChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.qualityControlChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.capabilitySummaryChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.paretoChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.probabilityPlotChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.decisionTreeChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.lists, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        { name: pageName.summaryList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        {
            name: pageName.valueSummaryList,
            isDataNeed: true,
            parent: pageName.lists,
            type: 'page',
        },
        { name: pageName.scrollBoard, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.reportPrint, isDataNeed: false, parent: '', type: 'page' },
        // { name: pageName.reportPrintMultiFunction, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.uploadFile, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.configuration, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.adminSettings, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.companyInfo, isDataNeed: false, parent: '', type: 'page' },
    ],
    admin: [
        { name: pageName.overview, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.monitoring, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.database, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.charts, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.histogramChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.positionalTolerancesChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.boxPlotChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.linearRegressionChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.correlationChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.qualityControlChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.capabilitySummaryChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.paretoChart, isDataNeed: true, parent: pageName.charts },
        {
            name: pageName.probabilityPlotChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.decisionTreeChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.lists, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        { name: pageName.summaryList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        {
            name: pageName.valueSummaryList,
            isDataNeed: true,
            parent: pageName.lists,
            type: 'page',
        },
        // { name: pageName.scrollBoard, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.reportPrint, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.uploadFile, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.configuration, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.adminSettings, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.companyInfo, isDataNeed: false, parent: '', type: 'page' },
    ],
    engineer: [
        { name: pageName.overview, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.monitoring, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.database, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.charts, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.histogramChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.positionalTolerancesChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.boxPlotChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.linearRegressionChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.correlationChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.qualityControlChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.capabilitySummaryChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.paretoChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.probabilityPlotChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.decisionTreeChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.lists, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        { name: pageName.summaryList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        {
            name: pageName.valueSummaryList,
            isDataNeed: true,
            parent: pageName.lists,
            type: 'page',
        },
        { name: pageName.reportPrint, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.uploadFile, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.configuration, isDataNeed: false, parent: '', type: 'page' },
        // { name: pageName.adminSettings, isDataNeed: false, parent: '' },
        { name: pageName.companyInfo, isDataNeed: false, parent: '', type: 'page' },
    ],
    operator: [
        { name: pageName.overview, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.monitoring, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.database, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.charts, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.histogramChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.positionalTolerancesChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.boxPlotChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.linearRegressionChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.correlationChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.qualityControlChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.capabilitySummaryChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.paretoChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.probabilityPlotChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.decisionTreeChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.lists, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        { name: pageName.summaryList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        {
            name: pageName.valueSummaryList,
            isDataNeed: true,
            parent: pageName.lists,
            type: 'page',
        },
        { name: pageName.reportPrint, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.uploadFile, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.configuration, isDataNeed: false, parent: '' },
        // { name: pageName.adminSettings,isDataNeed: false, parent: '' },
        { name: pageName.companyInfo, isDataNeed: false, parent: '', type: 'page' },
    ],
    cmm: [
        { name: pageName.overview, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.monitoring, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.database, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.charts, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.histogramChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        // {
        //   name: pageName.positionalTolerancesChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // { name: pageName.boxPlotChart, isDataNeed: true, parent: pageName.charts },
        // {
        //   name: pageName.linearRegressionChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // {
        //   name: pageName.correlationChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // {
        //   name: pageName.qualityControlChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // {
        //   name: pageName.capabilitySummaryChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // { name: pageName.paretoChart, isDataNeed: true, parent: pageName.charts },
        // {
        //   name: pageName.probabilityPlotChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // {
        //   name: pageName.decisionTreeChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        { name: pageName.lists, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        // { name: pageName.summaryList, isDataNeed: true, parent: pageName.lists },
        // {
        //   name: pageName.valueSummaryList,
        //   isDataNeed: true,
        //   parent: pageName.lists,

        // },
        { name: pageName.reportPrint, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.uploadFile, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.configuration, isDataNeed: false, parent: '' },
        // { name: pageName.adminSettings, isDataNeed: false, parent: '' },
        { name: pageName.companyInfo, isDataNeed: false, parent: '', type: 'page' },
    ],
    qsense: [
        { name: pageName.overview, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.monitoring, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.database, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.charts, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.histogramChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.positionalTolerancesChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.boxPlotChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        // {
        //   name: pageName.linearRegressionChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        {
            name: pageName.correlationChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.qualityControlChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        // {
        //   name: pageName.capabilitySummaryChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // { name: pageName.paretoChart, isDataNeed: true, parent: pageName.charts },
        // {
        //   name: pageName.probabilityPlotChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // {
        //   name: pageName.decisionTreeChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        { name: pageName.lists, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        { name: pageName.summaryList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        {
            name: pageName.valueSummaryList,
            isDataNeed: true,
            parent: pageName.lists,
            type: 'page',
        },
        { name: pageName.reportPrint, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.uploadFile, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.configuration, isDataNeed: false, parent: '' },
        // { name: pageName.adminSettings, isDataNeed: false, parent: '' },
        { name: pageName.companyInfo, isDataNeed: false, parent: '', type: 'page' },
    ],
    // demo spec is same to engineer
    demo: [
        { name: pageName.overview, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.monitoring, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.database, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.charts, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.histogramChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.positionalTolerancesChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.boxPlotChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.linearRegressionChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.correlationChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.qualityControlChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.capabilitySummaryChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.paretoChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.probabilityPlotChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.decisionTreeChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.lists, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        { name: pageName.summaryList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        {
            name: pageName.valueSummaryList,
            isDataNeed: true,
            parent: pageName.lists,
            type: 'page',
        },
        { name: pageName.scrollBoard, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.reportPrint, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.uploadFile, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.configuration, isDataNeed: false, parent: '', type: 'page' },
        // { name: pageName.adminSettings, isDataNeed: false, parent: '' },
        { name: pageName.companyInfo, isDataNeed: false, parent: '', type: 'page' },
    ],
    default: [
        { name: pageName.overview, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.monitoring, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.database, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.charts, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        {
            name: pageName.histogramChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.positionalTolerancesChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        { name: pageName.boxPlotChart, isDataNeed: true, parent: pageName.charts, type: 'page' },
        // {
        //   name: pageName.linearRegressionChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        {
            name: pageName.correlationChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        {
            name: pageName.qualityControlChart,
            isDataNeed: true,
            parent: pageName.charts,
            type: 'page',
        },
        // {
        //   name: pageName.capabilitySummaryChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // { name: pageName.paretoChart, isDataNeed: true, parent: pageName.charts },
        // {
        //   name: pageName.probabilityPlotChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        // {
        //   name: pageName.decisionTreeChart,
        //   isDataNeed: true,
        //   parent: pageName.charts,

        // },
        { name: pageName.lists, isDataNeed: true, parent: '', type: 'directory' },
        { name: pageName.valueList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        { name: pageName.summaryList, isDataNeed: true, parent: pageName.lists, type: 'page' },
        {
            name: pageName.valueSummaryList,
            isDataNeed: true,
            parent: pageName.lists,
            type: 'page',
        },
        { name: pageName.reportPrint, isDataNeed: true, parent: '', type: 'page' },
        { name: pageName.uploadFile, isDataNeed: false, parent: '', type: 'page' },
        { name: pageName.configuration, isDataNeed: false, parent: '' },
        // { name: pageName.adminSettings, isDataNeed: false, parent: '' },
        { name: pageName.companyInfo, isDataNeed: false, parent: '', type: 'page' },
    ],
}

export const defaultDBConfig = [
    {
        nickName: 'default',
        address: 'localhost',
        username: 'dqs',
        password: 'dqs',
        port: 3306,
        db_type: 0,
        db_name: 'qs_qsense',
    },
]

export const defaultPermissions = (company, plant, group) => {
    if (company === 'DQS' && plant === 'DQS' && group === 'DQS') {
        return ['write']
    } else if (group === 'admin' || group === 'superuser') {
        return ['write']
    } else {
        return ['demo']
    }
}

export const adminSettingsPrm = {
    company: ['dynamicQS', 'dynamicGS'],
    license: ['License1', 'License1', 'License3', 'License4', 'License5'],
    accountSettings: {
        password: {
            totalLen: 4,
            minSpecialChar: 1,
            minUpperCaseLetter: 1,
            minLetter: 0,
        },
    },
    // language: languageData,
    // dbConfig: defaultDBConfig,
    // ribbon: defaultRibbon,
}
