class DTOAdminDefault {
    constructor({
        company = null,
        default_config_1 = null,
        default_config_2 = null,
        default_config_3 = null,
        default_config_4 = null,
        default_config_5 = null,
        default_config_6 = null,
        default_config_7 = null,
        default_config_8 = null,
        default_config_9 = null,
        default_config_10 = null,
        default_config_11 = null,
        default_config_12 = null,
        default_config_13 = null,
        default_config_14 = null,
        default_config_15 = null,
        default_config_16 = null,
        default_config_17 = null,
        default_config_18 = null,
        default_config_19 = null,
        default_config_20 = null,
    } = {}) {
        Object.assign(this, {
            company,
            default_config_1,
            default_config_2,
            default_config_3,
            default_config_4,
            default_config_5,
            default_config_6,
            default_config_7,
            default_config_8,
            default_config_9,
            default_config_10,
            default_config_11,
            default_config_12,
            default_config_13,
            default_config_14,
            default_config_15,
            default_config_16,
            default_config_17,
            default_config_18,
            default_config_19,
            default_config_20,
        })
    }
}

export default DTOAdminDefault
