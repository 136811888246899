import { prepareBoxplotData } from 'echarts/extension/dataTool'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

export function getMaxInArray(arr) {
  let len = arr.length
  let max = -Infinity

  while (len--) {
    max = arr[len] > max ? arr[len] : max
  }
  return max
}

export function getMinInArray(arr) {
  let len = arr.length
  let min = Infinity // 초기 최솟값을 양의 무한대로 설정

  while (len--) {
    min = arr[len] < min ? arr[len] : min
  }
  return min
}

const boxPlotNormalizeData = (valueArray) => {
  // scatter array 는 [y] 구조임
  const convertedValueArray = valueArray.map((values) => {
    const minValue = getMinInArray(...values)
    const maxValue = getMaxInArray(...values)

    const convertedValue = values.map((value) => ((value - minValue) / (maxValue - minValue)) * 2 - 1)
    return convertedValue
  })
  return convertedValueArray
}

const scatterNormalizeData = (data) => {
  // scatter array 는 [x, y] 구조임
  const yData = data.map((item) => item[1])

  const minValue = getMinInArray(yData)
  const maxValue = getMaxInArray(yData)

  return data.map((item) => [item[0], ((item[1] - minValue) / (maxValue - minValue)) * 2 - 1])
}

const standardLimitBoxPlotNormalizeData = (settings, lslArray, uslArray, charArray, valueArray) => {
  const convertedValueArray = valueArray.map((values, index) => {
    const lsl = lslArray[index]
    const usl = uslArray[index]

    const convertedValue = values.map((value) => {
      return -1 + (2 * (value - lsl)) / (usl - lsl)
      // return (value - lsl) / (usl - lsl)
    })
    return convertedValue
  })
  return convertedValueArray
}

export const makeBoxPlotDataSet = (settings, charArray, valueArray, configSettings) => {
  let lslArray = []
  let uslArray = []
  let decimalPlacesArray = []
  let dataArray = []
  let nameArray = []
  let scatterArray = []

  valueArray.map((valueData, index) => {
    const targetChar = charArray.find((char) => char.part_id === valueData.part_id && char.char_id === valueData.char_id)

    const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
    const filteredValue = outlierFilterValues(limits, targetChar, valueData.valueRaw, configSettings)

    const objData = filteredValue.filter((data) => data.v0002 === 0)
    const plotData = objData.map((item) => item.v0001)
    const scatterData = objData.map((item) => [index, item.v0001])

    // 이름이 중복된것들이 있을수 있으므로 유니크하게 part_id와 char_id를 ','구분자로 넣어두고 나중에 x축에 표시할때는 떼고 표시한다.

    nameArray.push(targetChar.c2002 + '\u000f' + targetChar.part_id + '\u000f' + targetChar.char_id ?? 'null')
    lslArray.push(targetChar.c2110 ?? 'null')
    uslArray.push(targetChar.c2111 ?? 'null')
    // decimalPlacesArray.push(targetChar.c2022 ?? null)
    decimalPlacesArray.push(DNUtils.getValueDecimalPlaces(targetChar, configSettings))
    dataArray.push(plotData)
    scatterArray.push(scatterData)
  })

  let boxPlotData, boxPlotRefData
  if (settings.page.normalizationType === true) {
    const normalizedData = standardLimitBoxPlotNormalizeData(settings, lslArray, uslArray, charArray, dataArray)
    boxPlotData = prepareBoxplotData(normalizedData)
    boxPlotRefData = prepareBoxplotData(dataArray)
  } else {
    boxPlotData = prepareBoxplotData(dataArray)
    boxPlotRefData = prepareBoxplotData(dataArray)
  }

  return {
    names: nameArray,
    decimalPlacesArray: decimalPlacesArray,
    boxPlotData: boxPlotData,
    boxPlotRefData: boxPlotRefData,
  }
}

export function getMaxInMultiArray(arr) {
  if (arr.length === 0) {
    return null // Handle empty array
  }

  let max = -Infinity // Initialize max with negative infinity

  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      if (!isNaN(arr[i][j]) && arr[i][j] > max) {
        max = arr[i][j]
      }
    }
  }

  return isFinite(max) ? max : NaN // Check if max is still a finite number
}

export function getMinInMultiArray(arr) {
  if (arr.length === 0) {
    return null // Handle empty array
  }

  let min = Infinity // Initialize min with positive infinity

  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      if (!isNaN(arr[i][j]) && arr[i][j] < min) {
        min = arr[i][j]
      }
    }
  }

  return isFinite(min) ? min : NaN // Check if min is still a finite number
}

export const calculateYAxisSpace = (maxValue, minValue) => {
  const max = maxValue
  const min = minValue

  let space = Math.abs(max - min)
  if (space === 0) space = 1

  const additionalSpace = space * 0.1

  return { max, min, additionalSpace }
}

export const makeYAxisTop = (maxValue, minValue) => {
  const { max, additionalSpace } = calculateYAxisSpace(maxValue, minValue)
  return max + additionalSpace
}

export const makeYAxisBottom = (maxValue, minValue) => {
  const { min, additionalSpace } = calculateYAxisSpace(maxValue, minValue)
  return min - additionalSpace
}
