class DTOValue {
  constructor({
    part_id = null,
    char_id = null,
    value_id = null,
    out_of_tol = null,
    local_status = null,
    nominal = null,
    v_usl = null,
    v_lsl = null,
    v0001 = null,
    v0002 = null,
    v0004 = null,
    v0005 = null,
    v0006 = null,
    v0007 = null,
    v0008 = null,
    v0009 = null,
    v0010 = null,
    v0011 = null,
    v0012 = null,
    v0014 = null,
    v0016 = null,
    v0017 = null,
    v0053 = null,
    v0054 = null,
    v0055 = null,
    v0056 = null,
    v0057 = null,
    v0058 = null,
    v0059 = null,
    v0060 = null,
    v0061 = null,
    v0062 = null,
    v0063 = null,
    dt_field = null,
  } = {}) {
    Object.assign(this, {
      part_id,
      char_id,
      value_id,
      out_of_tol,
      local_status,
      nominal,
      v_usl,
      v_lsl,
      v0001,
      v0002,
      v0004,
      v0005,
      v0006,
      v0007,
      v0008,
      v0009,
      v0010,
      v0011,
      v0012,
      v0014,
      v0016,
      v0017,
      v0053,
      v0054,
      v0055,
      v0056,
      v0057,
      v0058,
      v0059,
      v0060,
      v0061,
      v0062,
      v0063,
      dt_field,
    })
  }
}

export default DTOValue
