import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { backendPath } from './defines/DBInfo'
import JwtUtils from 'src/functions/JwtUtils'
const path = backendPath

export const setCharInfo = async (user, updatedData) => {
    const company = user.user_company
    const plant = user.user_plant
    const group = user.user_group

    const requestBody = {
        company: company,
        plant: plant,
        group: group,
        requestMsg: updatedData,
    }

    try {
        const accessToken = JwtUtils.loadToken()
        const response = await fetch(path + 'data/setCharInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: JSON.stringify(requestBody),
        })

        const result = await response.json()
        return result
    } catch (error) {
        throw error
    }
}

export const deleteChar = async (user, part_id, char_id) => {
    const company = user.user_company
    const plant = user.user_plant
    const group = user.user_group

    const makeReqBody = (part_id, char_id) => {
        let requestBody = { company: company, plant: plant, group: group, part_id: part_id, char_id: char_id }

        return requestBody
    }

    try {
        const accessToken = JwtUtils.loadToken()
        const requestBody = JSON.stringify(makeReqBody(part_id, char_id))

        const response = await fetch(path + 'data/deleteChar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: requestBody,
        })

        const result = await response.json()
        return result
    } catch (error) {
        throw error
    }
}
