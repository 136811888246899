import { pythonBackendPath } from './defines/PythonDBInfo'
import { backendPath } from './defines/DBInfo'
import JwtUtils from 'src/functions/JwtUtils'

// ** Fetch Get Current Backend Version
export const getServerCurrentVersion = async () => {
  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(backendPath + 'data/getCurrentVersion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}

// ** Fetch Get Current Backend Version
export const getPyServerCurrentVersion = async () => {
  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(pythonBackendPath + 'py/getCurrentVersion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}
