import { EmptyItem, GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'

const calculationType = () => [
  { value: 'fixed', text: dqTranslateMsg('SidePanel_' + 'Fixed') },
  { value: 'moving', text: dqTranslateMsg('SidePanel_' + 'Moving') },
]

const SettingsQccChartOption = (pageKey, pageState, configState) => {
  const dispatch = useDispatch()

  const onCheckBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page.qualityControlChart[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`settings-qcc-chart-option-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'QCCChartOption')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'TakeoverFromDataset'),
        labelLocation: 'right',
        name: 'takeoverFromDataset',
        value: pageState.page.qualityControlChart.takeoverFromDataset,
        disabled: false,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'takeoverFromDataset'),
      })}
      <EmptyItem />
      <EmptyItem />
    </GroupItem>
  )
}

export default SettingsQccChartOption
