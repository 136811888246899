import { TextBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'
import './TextBoxNoValueSimpleItem.scss' // Import your custom CSS
import React from 'react'

const TextBoxNoValueSimpleItem = ({ colSpan, ref, label, labelLocation, unit, disabled = false }) => {
  return (
    <SimpleItem colSpan={colSpan}>
      <Label text={label} location={labelLocation} />
      <div className="textbox-container">
        <TextBox ref={ref} width={'100%'} /*label={label} labelMode={labelLocation} */ disabled={disabled} />
        {unit && <span>{dqTranslateMsg('SidePanel_' + unit)}</span>}
      </div>
    </SimpleItem>
  )
}

export default TextBoxNoValueSimpleItem
