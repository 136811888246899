export const paretoChartSettingsPrm = {
  page: {
    layout: {
      column: 4,
      row: 4,
    },
    summary: true,
    displayType: 'defectUnit',
    displayMode: 'stack',
    displayDefectUnit: {
      ng: true,
      warn: true,
      good: true,
    },
    fontSize: 18,
    showEmptyCharacteristics: false,
  },
  barChart: {
    error: {
      color: 'rgba(238, 28, 55, 1)',
    },
    warn: {
      color: 'rgba(255, 136, 0, 1)',
    },
    good: {
      color: 'rgba(2, 152, 212, 1)',
    },
  },
  lineChart: {
    symbols: {
      shape: 'triangle',
      size: 3,
      color: 'rgba(255, 0, 0, 1)',
    },
    chartLines: {
      type: 'solid',
      width: 2,
      color: 'rgba(12, 247, 12, 1)',
    },
  },
}
