import PCUtils from 'src/functions/ProcessCapabilityUtils'
import * as CDSUtils from 'src/functions/CheckDataStateUtils'

export const makeSymbol = (value, params, settings) => {
  const val = params.data[1]
  const { lineChart, usl, uwl, lwl, lsl } = settings

  const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
  const symbols = lineChart.symbols

  const prmProps = { val, usl, lsl, uwl, lwl, natMode }
  const valueState = CDSUtils.getValueState(prmProps)

  if (valueState === CDSUtils.GOOD_STATE) {
    return symbols.good.shape
  } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
    return symbols.warn.upper.shape
  } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
    return symbols.warn.lower.shape
  } else if (valueState === CDSUtils.NG_UPPER_STATE) {
    return symbols.error.upper.shape
  } else {
    return symbols.error.lower.shape
  }
}

export const makeSymbolSize = (value, params, settings) => {
  const val = params.data[1]
  const { lineChart, usl, uwl, lwl, lsl } = settings

  const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
  const symbols = lineChart.symbols

  const prmProps = { val, usl, lsl, uwl, lwl, natMode }
  const valueState = CDSUtils.getValueState(prmProps)

  if (valueState === CDSUtils.GOOD_STATE) {
    return symbols.good.size
  } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
    return symbols.warn.upper.size
  } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
    return symbols.warn.lower.size
  } else if (valueState === CDSUtils.NG_UPPER_STATE) {
    return symbols.error.upper.size
  } else {
    return symbols.error.lower.size
  }
}

export const makeSymbolColor = (params, settings) => {
  const val = params.data[1]
  const { lineChart, usl, uwl, lwl, lsl } = settings

  const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
  const symbols = lineChart.symbols

  const prmProps = { val, usl, lsl, uwl, lwl, natMode }
  const valueState = CDSUtils.getValueState(prmProps)

  if (valueState === CDSUtils.GOOD_STATE) {
    return symbols.good.color
  } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
    return symbols.warn.upper.color
  } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
    return symbols.warn.lower.color
  } else if (valueState === CDSUtils.NG_UPPER_STATE) {
    return symbols.error.upper.color
  } else {
    return symbols.error.lower.color
  }
}

// Scroll Board Symbol Apis
export const makeSbSymbol = (value, params, settings) => {
  const valIndex = params.data[0]
  const val = params.data[1]
  const { usl, uwl, lwl, lsl, filteredData } = settings
  const { lineChart } = settings.theme.valueChart

  const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
  const symbols = lineChart.symbols

  const prmProps = { val, usl, lsl, uwl, lwl, natMode }
  const valueState = CDSUtils.getValueState(prmProps)

  // For ScrollBoard
  const referenceValue = filteredData[valIndex - 1]
  const referenceValueEvent = referenceValue.v0005
  const eventSplitArray = referenceValueEvent ? referenceValueEvent.split(',') : []
  if (eventSplitArray.includes('999')) {
    return symbols.good.shape
  }

  if (valueState === CDSUtils.GOOD_STATE) {
    return symbols.good.shape
  } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
    return symbols.warn.upper.shape
  } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
    return symbols.warn.lower.shape
  } else if (valueState === CDSUtils.NG_UPPER_STATE) {
    return symbols.error.upper.shape
  } else {
    return symbols.error.lower.shape
  }
}

export const makeSbSymbolSize = (value, params, settings) => {
  const valIndex = params.data[0]
  const val = params.data[1]
  const { usl, uwl, lwl, lsl, filteredData } = settings
  const { lineChart } = settings.theme.valueChart

  const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
  const symbols = lineChart.symbols

  const prmProps = { val, usl, lsl, uwl, lwl, natMode }
  const valueState = CDSUtils.getValueState(prmProps)

  // For ScrollBoard
  const referenceValue = filteredData[valIndex - 1]
  const referenceValueEvent = referenceValue.v0005
  const eventSplitArray = referenceValueEvent ? referenceValueEvent.split(',') : []
  if (eventSplitArray.includes('999')) {
    return symbols.good.size
  }

  if (valueState === CDSUtils.GOOD_STATE) {
    return symbols.good.size
  } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
    return symbols.warn.upper.size
  } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
    return symbols.warn.lower.size
  } else if (valueState === CDSUtils.NG_UPPER_STATE) {
    return symbols.error.upper.size
  } else {
    return symbols.error.lower.size
  }
}

export const makeSbSymbolColor = (params, settings) => {
  const valIndex = params.data[0]
  const val = params.data[1]
  const { usl, uwl, lwl, lsl, filteredData } = settings
  const { lineChart } = settings.theme.valueChart

  const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
  const symbols = lineChart.symbols

  const prmProps = { val, usl, lsl, uwl, lwl, natMode }
  const valueState = CDSUtils.getValueState(prmProps)

  // For ScrollBoard
  const referenceValue = filteredData[valIndex - 1]
  const referenceValueEvent = referenceValue.v0005
  const eventSplitArray = referenceValueEvent ? referenceValueEvent.split(',') : []
  if (eventSplitArray.includes('999')) {
    return symbols.good.color
  }

  if (valueState === CDSUtils.GOOD_STATE) {
    return symbols.good.color
  } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
    return symbols.warn.upper.color
  } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
    return symbols.warn.lower.color
  } else if (valueState === CDSUtils.NG_UPPER_STATE) {
    return symbols.error.upper.color
  } else {
    //if (valueState === CDSUtils.NG_LOWER_STATE) {
    return symbols.error.lower.color
  }
}
