import PCUtils from 'src/functions/ProcessCapabilityUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

export function makeCorrelationData(target, chars, valuesArranged, displayType, configSettings) {
  const targetValueData = valuesArranged.filter((valueData) => valueData.part_id === target.part_id && valueData.char_id === target.char_id)

  const withoutTargetValueData = valuesArranged.filter((valueData) => valueData.part_id !== target.part_id || valueData.char_id !== target.char_id)

  const targetBaseData = targetValueData.map((valueData) => {
    const targetChar = chars.find((char) => char.part_id === valueData.part_id && char.char_id === valueData.char_id)
    const targetValue = valueData.valueRaw.filter((data) => data.v0002 === 0)

    const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
    let filteredValue = outlierFilterValues(limits, targetChar, targetValue, configSettings)
    filteredValue = filteredValue.map((value) => value.v0001)

    const format = {
      char_number: targetChar.char_id,
      char_name: targetChar.c2002,
      char_decimal_places: targetChar.c2022,
      data: filteredValue,
    }

    return format
  })

  const withoutTargetData = withoutTargetValueData.map((valueData) => {
    const targetChar = chars.find((char) => char.part_id === valueData.part_id && char.char_id === valueData.char_id)

    const targetValue = valueData.valueRaw.filter((data) => data.v0002 === 0).map((value) => value.v0001)

    const format = {
      char_number: targetChar.char_id,
      char_name: targetChar.c2002,
      char_decimal_places: targetChar.c2022,
      data: targetValue,
    }

    return format
  })

  let corrList = []

  withoutTargetData.map((wotd, index) => {
    const corrData = calculateCorrelation(targetBaseData[0].data, wotd.data)

    corrList.push({
      char_number: wotd.char_number,
      char_name: wotd.char_name,
      char_decimal_places: wotd.char_decimal_places,
      correlation_data: corrData,
    })
  })

  const sortedData = corrList.sort((a, b) => Math.abs(b.correlation_data) - Math.abs(a.correlation_data))

  let displayArray
  if (displayType === 'top5') {
    displayArray = sortedData.slice(0, sortedData.length < 5 ? sortedData.length : 5)
  } else if (displayType === 'top10') {
    displayArray = sortedData.slice(0, sortedData.length < 10 ? sortedData.length : 10)
  } else {
    displayArray = sortedData
  }

  return displayArray
}
// (curPage, configPage, charNames, correlationsData)
export function makeHeatMapCorrelationData(parts, chars, valuesArranged, displayType, configSettings) {
  const combinations = chars.map((char1) => chars.map((char2) => [char1, char2])).flat()
  const charNames = chars.map((char) => {
    const part = parts.find((part) => part.part_id === char.part_id)
    return `${part.p1001} / ${char.c2002},(${char.part_id}_${char.char_id})`
  })

  const correlationsData = combinations.map(([char1, char2]) => {
    const value1Part = valuesArranged.find((item) => item.part_id === char1.part_id).partRaw
    const value2Part = valuesArranged.find((item) => item.part_id === char2.part_id).partRaw
    const value1Char = valuesArranged.find((item) => item.part_id === char1.part_id && item.char_id === char1.char_id).charRaw
    const value2Char = valuesArranged.find((item) => item.part_id === char2.part_id && item.char_id === char2.char_id).charRaw
    const value1 = valuesArranged
      .find((item) => item.part_id === char1.part_id && item.char_id === char1.char_id)
      .valueRaw.filter((data) => data.v0002 === 0)
      .map((value) => value.v0001)
    const value2 = valuesArranged
      .find((item) => item.part_id === char2.part_id && item.char_id === char2.char_id)
      .valueRaw.filter((data) => data.v0002 === 0)
      .map((value) => value.v0001)

    // Pearson 상관계수 계산
    let corr
    // if (value1Part.p1001 + ' / ' + value1Char.c2002`${value1Part.p1001} / ${value1Char.c2002}` === value2Part.p1001 + ' / ' + value2Char.c2002) {
    if (`${value1Part.p1001} / ${value1Char.c2002},(${value1Char.part_id}_${value1Char.char_id})` === `${value2Part.p1001} / ${value2Char.c2002},(${value2Char.part_id}_${value2Char.char_id})`) {
      corr = null
    } else {
      corr = calculateCorrelation(value1, value2)
    }

    return [`${value1Part.p1001} / ${value1Char.c2002},(${value1Char.part_id}_${value1Char.char_id})`, `${value2Part.p1001} / ${value2Char.c2002},(${value2Char.part_id}_${value2Char.char_id})`, corr]
  })

  return { charNames: charNames, correlationsData: correlationsData }
}

export function calculateCorrelation(xArray, yArray) {
  const n = Math.min(xArray.length, yArray.length)

  if (n === 0) {
    return 0 // No data, no correlation (or undefined for some edge cases)
  }

  let sumXY = 0
  let sumX = 0
  let sumY = 0
  let sumXSquare = 0
  let sumYSquare = 0

  for (let i = 0; i < n; i++) {
    const x = xArray[i]
    const y = yArray[i]
    sumXY += x * y
    sumX += x
    sumY += y
    sumXSquare += x * x
    sumYSquare += y * y
  }

  const numerator = n * sumXY - sumX * sumY
  const denominator = Math.sqrt((n * sumXSquare - sumX * sumX) * (n * sumYSquare - sumY * sumY))

  if (denominator === 0) {
    return 0 // No correlation (or undefined for some edge cases)
  }

  const correlationCoefficient = numerator / denominator

  return correlationCoefficient
}

export const makeRows = (data) => {
  if (data.length > 0) {
    return data
  } else {
    console.warn('Row input data is not exist')

    return []
  }
}

export const makeColumns = [
  { dataField: 'char_number', caption: 'Char Number', alignment: 'center' },
  { dataField: 'char_name', caption: 'Char Name', alignment: 'center' },
  {
    dataField: 'correlation_data',
    caption: 'Correlation Data',
    alignment: 'center',
  },
]
