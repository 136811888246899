import { TreeList } from 'devextreme-react'
import Button from 'devextreme-react/button'
import { Column, DataGrid, GroupPanel } from 'devextreme-react/data-grid'
import { Item, Selection, Toolbar } from 'devextreme-react/tree-list'
import { useEffect, useRef, useState } from 'react'
import { batch, useSelector } from 'react-redux'
import { getAdminCompanyList, getAdminCompanyPlantList } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyListPrms, makeGetAdminCompanyPlantListPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { getAdminGroupList, updateAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminGroupListPrms, makeUpdateAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import { useApi } from 'src/contexts/api'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { groupTbDescription as groupTbDesc } from 'src/functions/constants/groupDBFieldDescription'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './AdminPushSettingsPermissions.scss'

const makeDataSourceForTreeView = (groups) => {
    const treeDataSource = []
    const expandIDList = []
    let currentId = 100 // Start ID for company, plant, group, and user

    const companyMap = {}
    const plantMap = {}

    groups.forEach((group) => {
        // Handle company with circled C icon
        if (!companyMap[group.company]) {
            const companyNode = {
                id: currentId++,
                name: `Ⓒ ${group.company}`, // Unicode circled C
                type: 'company',
                company: group.company,
                plant: group.plant,
                group: group.group,
                parentId: null,
            }
            companyMap[group.company] = companyNode.id
            treeDataSource.push(companyNode)
        }

        // Handle plant with circled P icon
        const companyId = companyMap[group.company]
        const plantKey = `${group.company}_${group.plant}`
        if (!plantMap[plantKey]) {
            const plantNode = {
                id: currentId++,
                name: `Ⓟ ${group.plant}`, // Unicode circled P
                type: 'plant',
                company: group.company,
                plant: group.plant,
                group: group.group,
                parentId: companyId,
            }
            plantMap[plantKey] = plantNode.id
            treeDataSource.push(plantNode)
            expandIDList.push(companyId)
        }

        // Handle group with circled G icon
        const plantId = plantMap[plantKey]
        const groupNode = {
            id: group.id,
            name: `Ⓖ ${group.group}`, // Unicode circled G
            type: 'group',
            company: group.company,
            plant: group.plant,
            group: group.group,
            parentId: plantId,
        }

        treeDataSource.push(groupNode)
        expandIDList.push(plantId)
    })

    const uniqueExpandIDList = [...new Set(expandIDList)]

    return { treeDataSource, expandIDList: uniqueExpandIDList }
}

const makeDataSourceForDataGrid = () => {
    let settingsList = ['DBInfo', 'Ribbon', 'Encoding', 'FindDistribution']
    let dataSource = settingsList.map((setting, index) => {
        return {
            id: index,
            content: setting,
        }
    })

    return dataSource
}

export default function AdminPushSettingsPermissions({ pageKey }) {
    const { spring } = useApi() // Use spring API client from the context
    const treeListRef = useRef(null)
    const dataGridRef = useRef(null)
    const common = useSelector((state) => state.common)
    const configPage = common.pages.configuration
    const adminPage = common.pages.adminSettings

    const [adminTreeDataSource, setAdminTreeDataSource] = useState(null)
    const [expandedTreeRowKeys, setExpandedTreeRowKeys] = useState([])
    const [selectedTreeRowKeys, setSelectedTreeRowKeys] = useState([])

    const [checkWrite, setCheckWrite] = useState(false)
    const [checkRead, setCheckRead] = useState(false)
    const [checkDemo, setCheckDemo] = useState(false)

    // Define the rows for the grid
    const rows = [
        {
            id: 1,
            permissions: `${getPageMsg(msgType.AdminSettings, 'Write')} ( ${getPageMsg(msgType.AdminSettings, 'Group')} )`,
            value: 'Write',
        },
        {
            id: 2,
            permissions: `${getPageMsg(msgType.AdminSettings, 'Read')} ( ${getPageMsg(msgType.AdminSettings, 'Group')} )`,
            value: 'Read',
        },
        {
            id: 3,
            permissions: `${getPageMsg(msgType.AdminSettings, 'Demo')} ( ${getPageMsg(msgType.AdminSettings, 'Group')} )`,
            value: 'Demo',
        },
    ]

    useEffect(() => {
        const getDataForPushSettings = async () => {
            try {
                const companyList = await getAdminCompanyList(spring, makeGetAdminCompanyListPrms())
                const uniqueCList = [...new Set(companyList)]

                const admCompanyPlantList = await Promise.all(
                    uniqueCList.map(async (company) => {
                        const plantList = await getAdminCompanyPlantList(spring, makeGetAdminCompanyPlantListPrms(company))
                        return { company, plantList }
                    })
                )

                const admDataConverted = []

                await Promise.all(
                    admCompanyPlantList.flatMap(async ({ company, plantList }) => {
                        return Promise.all(
                            plantList.map(async (plant) => {
                                const groupList = await getAdminGroupList(spring, makeGetAdminGroupListPrms(company, plant))

                                groupList.forEach((group) => {
                                    admDataConverted.push({ company, plant, group })
                                })
                            })
                        )
                    })
                )

                // Sort by company name
                const sortedData = admDataConverted.sort((a, b) => a.company.localeCompare(b.company))
                sortedData.forEach((item, index) => {
                    item.id = index + 1
                })

                const { treeDataSource, expandIDList } = makeDataSourceForTreeView(sortedData)

                const allRowTreeKeys = treeDataSource.map((item) => item.id)

                const gridDataSource = makeDataSourceForDataGrid()
                const allRowGridKeys = gridDataSource.map((item) => item.id)

                batch(() => {
                    // Tree
                    setAdminTreeDataSource(treeDataSource)
                    setExpandedTreeRowKeys(expandIDList)
                    // setSelectedTreeRowKeys(allRowTreeKeys)
                })
            } catch (error) {
                console.error('Error fetching push settings data:', error)
            }
        }

        getDataForPushSettings()
    }, [])

    const onTreeListSelectionChanged = (e) => {
        const selectedData = e.component.getSelectedRowsData('leavesOnly')
        setSelectedTreeRowKeys(selectedData)
    }

    const onDataGridSelectionChanged = (e) => {
        // setSelectedGridRowKeys(e.selectedRowKeys)
    }

    const updateGroupSettings = async (element, field, pageValue) => {
        const company = element.company
        const plant = element.plant
        const group = element.group
        const dataJsonStr = JSON.stringify(pageValue)

        const updateData = {
            [field]: dataJsonStr,
        }

        await updateAdminGroup(spring, makeUpdateAdminGroupPrms(company, plant, group, updateData))
    }

    const handleClickGroupPushSettings = (e) => {
        const processPushSettings = async () => {
            console.log('handleClickGroupPushSettings Start')

            const targetGroupAndUser = treeListRef.current.instance.getSelectedRowsData('all')

            const simpleGroup = targetGroupAndUser.map((item) => {
                const { id, name, parentId, type, userId, ...rest } = item
                return rest
            })

            const uniqueGroup = simpleGroup.filter((item, index, self) => index === self.findIndex((t) => t.company === item.company && t.plant === item.plant && t.group === item.group))

            const pushTargetItem = dataGridRef.current.instance.getSelectedRowsData('all')

            if (!uniqueGroup || !pushTargetItem || uniqueGroup.length === 0 || pushTargetItem.length === 0) {
                console.error('not Selected Group Data')
                return
            }

            uniqueGroup.forEach(async (element) => {
                console.log(`Update Group Data : ${element.company} ${element.plant} ${element.group}`)
                const permissionsArray = []
                for (const element of pushTargetItem) {
                    if (element.value === 'Write') {
                        permissionsArray.push('write')
                    }

                    if (element.value === 'Read') {
                        permissionsArray.push('read')
                    }

                    if (element.value === 'Demo') {
                        permissionsArray.push('demo')
                    }
                }
                await updateGroupSettings(element, groupTbDesc.permissions, permissionsArray)
            })
        }

        processPushSettings()
        console.log('handleClickPushSettings End')
    }

    useEffect(() => {
        if (treeListRef.current) {
            treeListRef.current.instance.selectAll()
        }
    }, [])

    return (
        <div className='admin-push-settings-permissions-main-container'>
            <div className='admin-push-settings-permissions-comp-plant-group-container'>
                <TreeList
                    ref={treeListRef}
                    className='admin-push-settings-permissions-inner-content'
                    dataSource={adminTreeDataSource}
                    autoExpandAll={true}
                    expandedRowKeys={expandedTreeRowKeys}
                    // selectedRowKeys={selectedTreeRowKeys}
                    showBorders={true}
                    showRowLines={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    searchPanel={{
                        highlightCaseSensitive: false,
                        highlightSearchText: true,
                        placeholder: dqTranslateMsg('SearchPlaceHoler'),
                        searchVisibleColumnsOnly: false,
                        text: '',
                        visible: true,
                        width: 160,
                    }}
                    // onSelectionChanged={onTreeListSelectionChanged}
                    noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
                    keyExpr='id'
                    parentIdExpr='parentId'
                >
                    <Selection
                        mode='multiple'
                        recursive={true}
                    />
                    <Column dataField='name' />
                </TreeList>
            </div>
            <div className='admin-push-settings-permissions-next-container'>
                <Button
                    className='button-content'
                    text='>>'
                    type='success'
                />
            </div>
            <div className='admin-push-settings-permissions-user-list-container'>
                {/* <p style={{ fontWeight: 'bold' }}>Target Users</p> */}
                <DataGrid
                    ref={dataGridRef}
                    className='admin-push-settings-permissions-inner-content'
                    dataSource={rows}
                    // expandedRowKeys={expandedGridRowKeys}
                    // selectedRowKeys={selectedGridRowKeys}
                    keyExpr='id'
                    width='100%'
                    allowColumnReordering={true}
                    columnAutoWidth={true}
                    columnResizingMode='widget'
                    allowColumnResizing={true}
                    scrolling={{
                        columnRenderingMode: 'standard',
                        mode: 'standard',
                        preloadEnabled: true,
                        renderAsync: true,
                        rowRenderingMode: 'standard',
                        scrollByContent: true,
                        scrollByThumb: false,
                        showScrollbar: 'onHover',
                        useNative: false,
                    }}
                    searchPanel={{
                        highlightCaseSensitive: false,
                        highlightSearchText: true,
                        placeholder: dqTranslateMsg('SearchPlaceHoler'),
                        searchVisibleColumnsOnly: false,
                        text: '',
                        visible: true,
                        width: 160,
                    }}
                    noDataText={dqTranslateMsg('NoData')}
                    paging={{ enabled: false }}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    hoverStateEnabled={true}
                    onSelectionChanged={onDataGridSelectionChanged}
                >
                    <GroupPanel
                        visible={true}
                        emptyPanelText={dqTranslateMsg('Page_Database_' + 'EmptyPanelText')}
                    />
                    <Toolbar>
                        <Item
                            widget='dxButton'
                            options={{
                                icon: 'save',
                                text: 'Apply to Group',
                                elementAttr: {
                                    style: 'min-width: 200px;', // Inline style for min-width
                                },
                                onClick: handleClickGroupPushSettings,
                            }}
                            location='after'
                        />
                    </Toolbar>
                    <Selection
                        mode='multiple'
                        allowSelectAll={true}
                        showCheckBoxesMode={'always'}
                    />
                    <Column
                        dataField='permissions'
                        caption='Setting'
                    />
                    {/* <Column dataField="value" caption="Enabled" cellRender={({ data }) => <CheckBox style={{ width: '100%' }} value={data.value} onValueChanged={data.onValueChanged} />} /> */}
                </DataGrid>
            </div>
        </div>
    )
}
