import React, { useEffect, useRef, useState } from 'react'
import { GroupItem, SimpleItem, ButtonItem, EmptyItem } from 'devextreme-react/form'
import { SelectBox, List, Sortable, Button } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import ListSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import SelectBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxNoValueSimpleItem'
import { defScrollBoardField, defScrollBoardNoneField } from 'src/default/field/scrollBoard.js'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import CPUtils from 'src/functions/CommonPageUtils'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

export default function ExtraXAxisLabel(pageKey, pageState, configState, othersPage) {
    const dispatch = useDispatch()
    // const pageKey = 'valueChart'

    // const pageState = useSelector((state) => state.common.pages[pageKey])
    // const configState = useSelector((state) => state.common.pages.configuration)

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }

    const useNoneValueFilterField = CPUtils.getConfigItemListFromUseField('Value', true, null, useFieldList.value, configState)
    const useValueFilterField = CPUtils.getConfigItemListFromUseField('Value', false, null, useFieldList.value, configState)
    const xAxisLabelItems = CPUtils.makeItemListFromSetting('Value', pageState.page.xAxisLabelItems, useFieldList.value, configState)
    const toolTipInfoItems = CPUtils.makeItemListFromSetting('Value', pageState.page.toolTipInfoItems, useFieldList.value, configState)

    const itemRender = (e, index, c) => {
        const item = e
        return (
            <div
                key={index}
                style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}
            >
                <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>▶️</div>
                <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>{index + 1}</div>
                <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>:</div>
                <div style={{ width: '70%', height: '100%' }}>{item.text}</div>
            </div>
        )
    }

    const onListReorder = (e) => {
        const { fromIndex, toIndex } = e
        const extraFieldList = pageState.page.xAxisLabelItems
        const updatedList = reorderList(extraFieldList, fromIndex, toIndex)
        updateOrderList(updatedList)
    }

    const reorderList = (list, fromIndex, toIndex) => {
        const updatedList = [...list]
        const [movedItem] = updatedList.splice(fromIndex, 1)
        updatedList.splice(toIndex, 0, movedItem)
        return updatedList
    }

    const updateOrderList = (list) => {
        const next = produce(pageState, (draft) => {
            draft.page.xAxisLabelItems = list // Assuming 'list' is the new order
        })

        dispatch(ps[pageKey].setPageSettingsToRedux(next)) // Adjust the action based on your redux setup
    }

    const handleExtraLabelDelete = (e) => {
        const next = produce(pageState, (draft) => {
            draft.page.xAxisLabelItems = draft.page.xAxisLabelItems.filter((element) => element !== e.itemData.value)
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const onSelectBoxExtraLabelValueChanged = (e, dataField) => {
        const value = e.itemData.value
        const isExist = pageState.page[dataField].some((item) => item === value)
        if (!isExist) {
            const next = produce(pageState, (draft) => {
                draft.page[dataField].push(value)
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }
    }

    const onRadioGroupDateTimeFormatValueChanged = (e, dataField) => {
        if (e.event === undefined) return
        const value = e.value
        const next = produce(pageState, (draft) => {
            draft.page[dataField] = value
        })

        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const onCheckBoxDenseLabelEnableValueChanged = (e, dataField) => {
        if (e.event === undefined) return
        const value = e.value
        const next = produce(pageState, (draft) => {
            draft.page[dataField] = value
        })

        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <GroupItem
            key={`extra-x-axis-label-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'ExtraXAxisLabels')}
        >
            {SelectBoxSimpleItem({
                colSpan: 2,
                label: dqTranslateMsg('SidePanel_' + 'Item'),
                labelLocation: 'top',
                name: 'xAxisLabelItems',
                items: useValueFilterField,
                valueExpr: 'value',
                displayExpr: 'text',
                value: useValueFilterField.length > 0 ? useValueFilterField[0].value : null,
                disabled: false,
                onItemClick: (e) => onSelectBoxExtraLabelValueChanged(e, 'xAxisLabelItems'),
            })}
            {/* <EmptyItem /> */}
            {ListSimpleItem({
                colSpan: 2,
                label: dqTranslateMsg('SidePanel_' + 'RegisteredItem'),
                labelLocation: 'top',
                dataSource: xAxisLabelItems,
                itemRender: itemRender,
                allowItemDeleting: true,
                itemDeleteMode: 'static',
                noDataText: dqTranslateMsg('SidePanel_' + 'NoData'),
                onItemDeleted: handleExtraLabelDelete,
                onReorder: onListReorder,
                backgroundCondition: true,
            })}
            {RadioGroupSimpleItem({
                ref: null,
                colSpan: 2,
                label: dqTranslateMsg('SidePanel_' + 'DateTimeDisplayType'),
                labelLocation: 'top',
                name: 'type',
                items: sd.dateTimeDisplayDefine(),
                layout: 'vertical',
                valueExpr: 'value',
                displayExpr: 'text',
                value: pageState.page.dateTimeLabelDisplayFormat,
                disabled: false,
                onValueChanged: (e) => onRadioGroupDateTimeFormatValueChanged(e, 'dateTimeLabelDisplayFormat'),
            })}
            {CheckBoxSimpleItem({
                ref: null,
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'DenseLabel'),
                labelLocation: 'right',
                name: 'xAxisDenseLabel',
                value: pageState.page.xAxisDenseLabel,
                disabled: false,
                onValueChanged: (e) => onCheckBoxDenseLabelEnableValueChanged(e, 'xAxisDenseLabel'),
            })}
        </GroupItem>
    )
}
