import DataGrid, { Column } from 'devextreme-react/data-grid'
import SelectBox from 'devextreme-react/select-box'
import ReactECharts from 'echarts-for-react'
import { useEffect, useState, useMemo, useRef, Fragment } from 'react'
import { batch, useSelector } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './LinearRegressionChartForm.scss'
import { getLinearRegressionChartOption } from './chart-option/LinearRegressionChartOption'
import * as lrImpl from './chart-option/LinearRegressionChartOptionImpl'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import { connect } from 'react-redux'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import { useLanguage } from 'src/contexts/languages'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(LinearRegressionChartForm)

function LinearRegressionChartForm({ pageKey, common, chartRawData }) {
  const { getTheme } = useTheme()
  const { language } = useLanguage()
  const [isLoadingPanel, setIsLoadingPanel] = useState(false)

  // Data parse
  const curPage = common.pages.linearRegressionChart
  const configPage = common.pages.configuration
  const currentSel = common.current_sel
  const selectedChar = common.loaded_all[pageKey]

  const [chartOptions, setChartOptions] = useState(null)
  const [dataGridDataSource, setDataGridDataSource] = useState(null)

  useEffect(() => {
    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)

    const processChartOptions = async () => {
      setIsLoadingPanel(true)

      let xVariables = []
      let yConstant = []
      let variableData = []

      let targetValue
      targetValue = valuesArranged.find((char) => char.part_id === currentSel.part_id && char.char_id === currentSel.char_id)

      if (!targetValue) {
        batch(() => {
          setChartOptions(null)
          setDataGridDataSource(null)
          setIsLoadingPanel(false)
        })
        return
      }

      yConstant = [targetValue.charRaw, targetValue]
      xVariables = valuesArranged
        .map((sel) => {
          if (sel.part_id !== targetValue.part_id || sel.char_id !== targetValue.char_id) {
            return [sel.charRaw, sel]
          }
        })
        .filter((item) => item !== undefined)

      variableData = lrImpl.makeLinRegDataSet(yConstant, xVariables)

      let lrChartOptions = null
      if (yConstant.length > 0 && variableData.length > 0) {
        lrChartOptions = getLinearRegressionChartOption(curPage, configPage, yConstant, variableData)
      }

      // Datagrid DataSource
      const row = variableData.map((variable) => ({
        xData: variable.xData,
        yData: variable.yData,
        ySlope: variable.ySlope,
        yIntercept: variable.yIntercept,
        r2Score: variable.r2Score,
      }))

      // Simulate a delay to allow the loading panel to appear
      await new Promise((resolve) => setTimeout(resolve, 100)) // Adjust delay if needed

      batch(() => {
        // setTargetChar(targetValue)
        setChartOptions(lrChartOptions)
        setDataGridDataSource(row)
        setIsLoadingPanel(false)
      })
    }

    processChartOptions()
  }, [selectedChar, currentSel, curPage, configPage])

  const renderYSlope = (cellData, configSettings) => {
    return <div>{cellData.value ? DNUtils.calcValueDisplay(cellData.value, configSettings) : null}</div>
  }

  const renderYIntercept = (cellData, configSettings) => {
    return <div>{cellData.value ? DNUtils.calcValueDisplay(cellData.value, configSettings) : null}</div>
  }

  const renderR2Score = (cellData, configSettings) => {
    return <div>{cellData.value ? DNUtils.calcValueDisplay(cellData.value, configSettings) : null}</div>
  }

  const render = () => {
    return (
      <Fragment>
        <div className="linear-regression-chart-container" style={{ padding: '3px' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '10px',
              borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <ReactECharts
              style={{ width: '100%', height: '100%' }}
              notMerge={true}
              option={chartOptions}
              theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
              opts={{
                renderer: 'svg',
                locale: ETCUtils.checkLangForECharts(language),
              }}
            />
          </div>
        </div>
        <div className="grid-container" style={{ padding: '3px' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '10px',
              borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <DataGrid
              className="grid-content"
              id="linear-regression-datagrid"
              dataSource={dataGridDataSource}
              allowColumnResizing={true}
              columnAutoWidth={true}
              scrolling={{
                showScrollbar: 'onHover',
                useNative: false,
              }}
              paging={{ enabled: false }}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              rowAlternationEnabled={false}
            >
              <Column dataField="xData" caption={dqTranslateMsg('Page_LinearRegressionChart_' + 'XData')} dataType="string" alignment="center" />
              <Column dataField="yData" caption={dqTranslateMsg('Page_LinearRegressionChart_' + 'YData')} dataType="string" alignment="center" />
              <Column
                dataField="ySlope"
                caption={dqTranslateMsg('Page_LinearRegressionChart_' + 'YSlope')}
                dataType="string"
                alignment="center"
                cellRender={(cellData) => renderYSlope(cellData, configPage)}
              />
              <Column
                dataField="yIntercept"
                caption={dqTranslateMsg('Page_LinearRegressionChart_' + 'YIntercept')}
                dataType="string"
                alignment="center"
                cellRender={(cellData) => renderYIntercept(cellData, configPage)}
              />
              <Column
                dataField="r2Score"
                caption={dqTranslateMsg('Page_LinearRegressionChart_' + 'R2Score')}
                dataType="string"
                alignment="center"
                cellRender={(cellData) => renderR2Score(cellData, configPage)}
              />
            </DataGrid>
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <div className="dx-theme-background-color">
      <DQLoadPanel open={isLoadingPanel} message={dqTranslateMsg('Common_' + 'LoadPanelLoading')} />
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {chartOptions && !isLoadingPanel ? (
          render()
        ) : (
          <div className="linear-regression-control-container" style={{ padding: '3px' }}>
            <PageWarningMessage message={dqTranslateMsg('Page_LinearRegressionChart_' + 'DataIsNotEnough')} />
          </div>
        )}
      </div>
    </div>
  )
}
