import { Popup, SelectBox } from 'devextreme-react'
import Button from 'devextreme-react/button'
import DataGrid, { Column, CustomRule, Editing, Item, Lookup, PatternRule, RequiredRule, Scrolling, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid'
import { ProgressBar } from 'devextreme-react/progress-bar'
import produce from 'immer'
import { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { batch, connect } from 'react-redux'
import { uploadFileData } from 'src/api/uploadFiles'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useAuth } from 'src/contexts/auth'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as permi from 'src/pages/components/dq-permission/DQPermission'
import './UploadFileForm.scss'
import ApiStatus from 'src/api/defines/ApiStatus'
import CryptoJS from 'crypto-js'
import { useLanguage } from 'src/contexts/languages'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'

const encodingTypeList = [
    {
        value: 65001,
        En: 'UTF-8 (Common)',
        Ja: 'UTF-8 (一般)',
        Kr: 'UTF-8 (일반)',
    },
    {
        value: 1200,
        En: 'UTF-16 (Common)',
        Ja: 'UTF-16 (一般)',
        Kr: 'UTF-16 (일반)',
    },
    {
        value: 12000,
        En: 'UTF-32 (Common)',
        Ja: 'UTF-32 (一般)',
        Kr: 'UTF-32 (일반)',
    },
    {
        value: 20127,
        jschardetDesc: 'ascii',
        En: 'US-ASCII (USA)',
        Ja: 'US-ASCII (米国)',
        Kr: 'US-ASCII (미국)',
    },
    {
        value: 932,
        En: 'SHIFT_JIS (Japan)',
        Ja: 'SHIFT_JIS (日本)',
        Kr: 'SHIFT_JIS (일본)',
    },
    {
        value: 949,
        En: 'kS_C_5601-1987 (Korea)',
        Ja: 'kS_C_5601-1987 (韓国)',
        Kr: 'kS_C_5601-1987 (한국)',
    },
]

function convertMillisToCustomFormat(millis) {
    // Create a Date object from the milliseconds
    const date = new Date(millis)

    // Extract components of the date
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // JavaScript months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    // Format the components into the desired format "yyyy-MM-dd hh:mm:ss"
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function makeDataSourceForDataGrid(files) {
    const result = files.map((fileInfo, index) => ({
        ID: index,
        hash: fileInfo.hash,
        File: fileInfo.file,
        FileName: fileInfo.file.name,
        LastModifiedTime: convertMillisToCustomFormat(fileInfo.file.lastModified),
        Encoding: encodingTypeList[0].value,
        Size: fileInfo.file.size / 1000,
        State: false,
    }))

    return result
}

async function generateFileHash(file) {
    const arrayBuffer = await file.arrayBuffer()

    // 타임스탬프 및 임의의 데이터 생성
    const timestamp = new Date().getTime().toString()
    const randomData = CryptoJS.lib.WordArray.random(16) // 16바이트 임의 데이터 생성

    // 타임스탬프와 임의의 데이터를 원래 파일 데이터에 추가
    const fileWordArray = CryptoJS.lib.WordArray.create(arrayBuffer)
    const timestampWordArray = CryptoJS.enc.Utf8.parse(timestamp)
    const combinedWordArray = fileWordArray.concat(timestampWordArray).concat(randomData)

    // 수정된 데이터에 대해 SHA-256 해시 생성
    const hash = CryptoJS.SHA256(combinedWordArray)

    // 해시를 16진수 문자열로 변환
    const hashHex = hash.toString(CryptoJS.enc.Hex)
    return hashHex
}

function UploadFileForm({ pageKey, common, chartRawData }) {
    const { user } = useAuth()
    const { language } = useLanguage()
    const [loadedFileList, setLoadedFileList] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [isFileExtError, setIsFileExtError] = useState(false)
    const dataGridRef = useRef(null)
    const progressBarRef = useRef(null)
    const hasWriteAuth = common.pages.others.permissions.includes('write')
    const hasReadAuth = common.pages.others.permissions.includes('read')
    const hasDemoAuth = common.pages.others.permissions.includes('demo')

    const [isLoadingPanel, setIsLoadingPanel] = useState(false)

    const [isPopupVisible, setIsPopupVisible] = useState(false)
    const [popupContent, setPopupContent] = useState({
        id: 0,
        title: '',
        content: '',
        data: '',
    })

    const showPopup = (id, title, content, data = null) => {
        const contentState = {
            id: id,
            title: title,
            content: content,
            data: data,
        }

        setPopupContent(contentState)
        setIsPopupVisible(true)
    }

    const hidePopup = () => {
        setIsPopupVisible(false)
    }

    const processPopup = (id, data) => {
        if (id === 0) {
            // upload all
            handleUploadAllFiles()
        } else if (id === 1) {
            // upload selected
            handleUploadSelectedFiles()
        } else if (id === 2) {
            handleUploadCurrentRowFile(data)
        } else {
            // clear data list
            handleClearDatagridList()
        }
    }

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const pbInstance = progressBarRef.current.instance
                pbInstance.option('max', 1)
                pbInstance.option('value', 0)

                // Generate unique IDs (hashes) for each file asynchronously
                const generateHashesForFiles = async () => {
                    const filesWithHash = await Promise.all(
                        acceptedFiles.map(async (file) => {
                            const hash = await generateFileHash(file)
                            return {
                                hash: hash,
                                file: file,
                            }
                        })
                    )

                    batch(() => {
                        const files = [...loadedFileList, ...filesWithHash]
                        setLoadedFileList(files)
                        setDataSource(makeDataSourceForDataGrid(files))
                        setIsFileExtError(false)
                    })
                }

                // Call the async function
                generateHashesForFiles()
            }
        },
        [loadedFileList]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'text/csv': ['.csv'], 'application/vnd.daqscribe+dfq': ['.dfq'], 'application/json': ['.json'] },
        // accept: '.csv, .dfq',
    })

    const handleUploadAllFiles = () => {
        setIsLoadingPanel(true)
        const datagridInstance = dataGridRef.current.instance
        const pbInstance = progressBarRef.current.instance

        const selectedRow = datagridInstance.option('dataSource')
        if (selectedRow.length > 0) {
            selectedRow.forEach((row) => {
                row.State = false
            })
            datagridInstance.option('dataSource', selectedRow)

            const countItem = selectedRow.length
            pbInstance.option('max', countItem)
            pbInstance.option('value', 0)

            selectedRow
                .reduce((promiseChain, currentFile, currentIndex) => {
                    return promiseChain.then((chainResults) =>
                        uploadFileData(user, currentFile).then((currentResult) => {
                            if (ApiStatus.isSuccess(currentResult.status)) {
                                pbInstance.option('value', currentIndex + 1)
                                const data = datagridInstance.option('dataSource')
                                data[currentIndex].State = true
                                datagridInstance.option('dataSource', data)
                                return [...chainResults, currentResult]
                            } else {
                                if (result.status === 7001) {
                                    console.error('Upload - File Name Error')
                                }
                                throw new Error(dqTranslateMsg('CommunicationErrorMsg_' + `E${result.status.toString().padStart(4, '0')}`))
                            }
                        })
                    )
                }, Promise.resolve([]))
                .then((arrayOfResults) => {
                    console.log('All files uploaded successfully:', arrayOfResults)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    setIsLoadingPanel(false) // Hide loading panel after uploads (and any errors) are handled
                })
        } else {
            setIsLoadingPanel(false)
        }
    }

    const handleUploadSelectedFiles = () => {
        setIsLoadingPanel(true)
        const datagridInstance = dataGridRef.current.instance
        const pbInstance = progressBarRef.current.instance

        // initialization
        const data = datagridInstance.option('dataSource')
        data.forEach((row) => {
            row.State = false
        })
        datagridInstance.option('dataSource', data)

        const selectedRow = datagridInstance.getSelectedRowsData()

        if (selectedRow.length === 0) {
            setIsLoadingPanel(false)
            alert(dqTranslateMsg('Page_UploadFile_' + 'ErrorMsgEmptyUploadFile'))
            return
        }

        selectedRow.forEach((row) => {
            row.State = false
        })

        const countItem = selectedRow.length
        pbInstance.option('max', countItem)
        pbInstance.option('value', 0)

        selectedRow
            .reduce((promiseChain, currentFile, currentIndex) => {
                return promiseChain.then((chainResults) =>
                    uploadFileData(user, currentFile).then((currentResult) => {
                        if (ApiStatus.isSuccess(currentResult.status)) {
                            pbInstance.option('value', currentIndex + 1)
                            const data = datagridInstance.option('dataSource')
                            // data[currentIndex].State = true
                            const tgIndex = data.findIndex((row) => row.ID === selectedRow[currentIndex].ID)
                            data[tgIndex].State = true
                            datagridInstance.option('dataSource', data)
                            return [...chainResults, currentResult]
                        } else {
                            if (currentResult.status === 7001) {
                                console.error('Upload - File Name Error')
                            }
                            alert(dqTranslateMsg('CommunicationErrorMsg_' + `E${currentResult.status.toString().padStart(4, '0')}`))
                        }
                    })
                )
            }, Promise.resolve([]))
            .then((arrayOfResults) => {
                console.log('All files uploaded successfully:', arrayOfResults)
                // dataGridRef.current.instance
            })
            .finally(() => {
                setIsLoadingPanel(false) // Hide loading panel after uploads (and any errors) are handled
            })
    }

    const handleClearDatagridList = () => {
        setIsLoadingPanel(true)
        const datagridInstance = dataGridRef.current.instance
        datagridInstance.option('dataSource', [])
        const pbInstance = progressBarRef.current.instance
        pbInstance.option('max', 1)
        pbInstance.option('value', 0)
        batch(() => {
            setLoadedFileList([])
            setDataSource([])
        })
        setIsLoadingPanel(false)
    }

    const handleUploadCurrentRowFile = (targetFile) => {
        setIsLoadingPanel(true)

        const pbInstance = progressBarRef.current.instance
        pbInstance.option('max', 1)
        pbInstance.option('value', 0)

        const datagridInstance = dataGridRef.current.instance

        uploadFileData(user, targetFile)
            .then((result) => {
                if (!ApiStatus.isSuccess(result.status)) {
                    alert(getErrCommMsg(result.status))
                }

                const data = datagridInstance.option('dataSource')
                const modiTgItemIndex = data.findIndex((item) => item.File === targetFile.File)
                data[modiTgItemIndex].State = true
                datagridInstance.option('dataSource', data)
                pbInstance.option('max', 1)
                pbInstance.option('value', 1)

                setIsLoadingPanel(false)
            })
            .catch(() => {
                setIsLoadingPanel(false)
            })
    }

    const renderEncodingCell = (e) => {
        const rowData = e.data

        const changeEncodingManual = ({ value }) => {
            // handleEncodingChange(e.rowIndex, value)
            const datagridInstance = dataGridRef.current.instance
            const data = datagridInstance.option('dataSource')
            data[e.rowIndex].Encoding = value
            datagridInstance.option('dataSource', data)
            setDataSource(data)
        }

        return (
            <div style={{ display: 'flex', width: '100%' }}>
                <SelectBox
                    items={encodingTypeList}
                    value={rowData.Encoding}
                    width={'100%'}
                    onValueChanged={(e) => changeEncodingManual(e, rowData)}
                    valueExpr='value'
                    displayExpr={ETCUtils.checkLang(language)}
                />
            </div>
        )
    }

    const renderStateCell = (e) => {
        const state = e.value
        const tgFile = e.data
        const buttonText = state ? dqTranslateMsg('Page_UploadFile_' + 'Uploaded') : dqTranslateMsg('Page_UploadFile_' + 'Upload')

        return (
            <Button
                icon={'upload'}
                width={'100%'}
                text={buttonText}
                type={state ? 'success' : 'normal'}
                stylingMode={state ? 'Contained' : 'Outline'}
                focusStateEnabled={false}
                // onClick={() => handleUploadCurrentRowFile(e, tgFile)}
                onClick={() => showPopup(2, 'Warning', dqTranslateMsg('Page_UploadFile_' + 'UploadConfirmMsg'), tgFile)}
            />
        )
    }

    function statusFormat(ratio) {
        let status = ''
        if (ratio === 1) {
            status = `${dqTranslateMsg('Page_UploadFile_' + 'Completed')}`
        } else {
            status = `${dqTranslateMsg('Page_UploadFile_' + 'Process')}`
        }
        return `${status}: ${ratio * 100}%`
    }

    const checkFileName = (e) => {
        // Extract the FileName value from the current row data
        const FileName = e.value

        // Check if FileName ends with .csv
        if (FileName && FileName.endsWith('.csv')) {
            // Define the pattern for validation
            const pattern = /^[^_]+_.*\.csv$/

            // Check if the FileName matches the pattern
            if (!pattern.test(FileName)) {
                return false
            }
            return true
        }

        return true
    }

    const handleRowRemoving = (e) => {
        console.log(e)
        const datagridInstance = dataGridRef.current.instance
        const data = datagridInstance.option('dataSource')
        const removed = data.filter((row) => row.hash !== e.data.hash)
        datagridInstance.option('dataSource', removed)

        const removedDataSource = dataSource.filter((row) => row.hash !== e.data.hash)
        const removedFileList = loadedFileList.filter((row) => row.hash !== e.data.hash)
        batch(() => {
            setLoadedFileList(removedFileList)
            setDataSource(removedDataSource)
        })
    }

    return (
        <div className='dx-theme-background-color'>
            <div className='upload-file-form-container'>
                <Popup
                    className='upload-file-popup-title'
                    id='uploadFilePopUp'
                    visible={isPopupVisible}
                    onHiding={hidePopup}
                    dragEnabled={true}
                    hideOnOutsideClick={true}
                    showCloseButton={true}
                    title={popupContent.title}
                    titleRender={() => <div style={{ padding: 0 }}>{popupContent.title}</div>}
                    width='auto'
                    height='auto'
                >
                    <div>
                        <p>{popupContent.content}</p>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                text={dqTranslateMsg('Page_UploadFile_' + 'Yes')}
                                onClick={() => {
                                    hidePopup()
                                    processPopup(popupContent.id, popupContent.data)
                                }}
                            />
                            <Button
                                text={dqTranslateMsg('Page_UploadFile_' + 'No')}
                                onClick={hidePopup}
                            />
                        </div>
                    </div>
                </Popup>
                <DQLoadPanel
                    open={isLoadingPanel}
                    message={dqTranslateMsg('Page_UploadFile_' + 'Processing')}
                />
                <div className='upload-file-form-drop-zone'>
                    <div
                        {...getRootProps()}
                        className='upload-file-form-drop-zone-content'
                        style={{
                            border: `2px dashed ${isDragActive ? 'red' : '#ccc'}`,
                        }}
                    >
                        <input {...getInputProps()} />
                        <div style={{ textAlign: 'center' }}>
                            <p style={{ margin: 0 }}>{dqTranslateMsg('Page_UploadFile_' + 'DropZoneDescription')}</p>
                            <p style={{ margin: 0 }}>{dqTranslateMsg('Page_UploadFile_' + 'DropZoneDescriptionExt')}</p>
                        </div>
                        {/* )} */}
                    </div>
                </div>
                <div className='upload-file-form-progress-bar'>
                    <ProgressBar
                        ref={progressBarRef}
                        id='progress-bar-status'
                        className={'complete'}
                        width='100%'
                        // min={0}
                        // max={0}
                        statusFormat={statusFormat}
                        elementAttr={{ 'aria-label': 'Progress Bar' }}
                        // value={0}
                    />
                </div>

                <div className='upload-file-form-file-list'>
                    <DataGrid
                        ref={dataGridRef}
                        className='upload-file-form-file-list-content'
                        // dataSource={loadedFileList}
                        dataSource={dataSource}
                        keyExpr='ID'
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        columnResizingMode='widget'
                        allowColumnResizing={true}
                        filterRow={{ visible: false }}
                        noDataText={dqTranslateMsg('Page_UploadFile_' + 'NoData')}
                        // showCheckBoxesMode="always"
                        paging={{ enabled: true }}
                        hoverStateEnabled={true}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        twoWayBindingEnabled={false}
                        onRowRemoving={handleRowRemoving}
                    >
                        {hasWriteAuth ? (
                            <Editing
                                mode='cell'
                                useIcons={true}
                                allowDeleting={true}
                                allowUpdating={true}
                                startEditAction={'click'}
                            />
                        ) : null}
                        <Scrolling
                            mode='standard'
                            rowRenderingMode='standard'
                        />
                        <Toolbar>
                            <Item location='after'>
                                <Button
                                    icon={'upload'}
                                    width={160}
                                    text={dqTranslateMsg('Page_UploadFile_' + 'UploadAll')}
                                    focusStateEnabled={false}
                                    onClick={() => {
                                        showPopup(0, 'Warning', dqTranslateMsg('Page_UploadFile_' + 'UploadAllConfirmMsg'))
                                    }}
                                />
                                <Button
                                    icon={'upload'}
                                    width={160}
                                    text={dqTranslateMsg('Page_UploadFile_' + 'UploadSelected')}
                                    focusStateEnabled={false}
                                    onClick={() => {
                                        showPopup(1, 'Warning', dqTranslateMsg('Page_UploadFile_' + 'UploadSelectedConfirmMsg'))
                                    }}
                                />
                                <Button
                                    icon={'clear'}
                                    width={160}
                                    text={dqTranslateMsg('Page_UploadFile_' + 'ClearList')}
                                    focusStateEnabled={false}
                                    onClick={() => {
                                        showPopup(3, 'Warning', dqTranslateMsg('Page_UploadFile_' + 'ClearListConfirmMsg'))
                                    }}
                                />
                            </Item>
                        </Toolbar>
                        <Selection
                            recursive={true}
                            mode='multiple'
                            showCheckBoxesMode='always'
                        />
                        <Column
                            dataField='FileName'
                            caption={dqTranslateMsg('Page_UploadFile_' + 'FileName')}
                            width={'35%'}
                            alignment='center'
                            allowEditing={false}
                        >
                            {/* <CustomRule
                message={
                  'CSV file name format: [Part number]_[Part descriPtion].csv'
                }
                validationCallback={checkFileName}
              />
               */}
                            <RequiredRule message='File Name is required' />
                        </Column>
                        <Column
                            dataField='LastModifiedTime'
                            caption={dqTranslateMsg('Page_UploadFile_' + 'FileLastModifiedTime')}
                            width={'15%'}
                            alignment='center'
                            allowEditing={false}
                        ></Column>
                        <Column
                            dataField='Size'
                            caption={dqTranslateMsg('Page_UploadFile_' + 'FileSize')}
                            width={'10%'}
                            alignment='center'
                            allowEditing={false}
                            cellRender={(e) => <div>{`${e.value} KB`}</div>}
                        />
                        <Column
                            dataField='Encoding'
                            caption={dqTranslateMsg('Page_UploadFile_' + 'Encoding')}
                            width={'20%'}
                            alignment='center'
                            // allowEditing={false}
                            // cellRender={(e) => renderEncodingCell(e)}
                        >
                            <Lookup
                                dataSource={encodingTypeList}
                                valueExpr={'value'}
                                // onValueChanged={(e) => changeEncodingManual(e, rowData)}
                                displayExpr={ETCUtils.checkLang(language)}
                            />
                        </Column>
                        <Column
                            dataField='State'
                            caption={dqTranslateMsg('Page_UploadFile_' + 'State')}
                            width={'10%'}
                            alignment='center'
                            allowEditing={false}
                            cellRender={(e) => renderStateCell(e)}
                        />
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(UploadFileForm)
