// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-panel-overview-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.side-panel-overview-container .side-panel-overview-container::-webkit-scrollbar {
  width: 5px;
}
.side-panel-overview-container .side-panel-overview-container::-webkit-scrollbar-thumb {
  background-color: #3e4451;
}
.side-panel-overview-container .side-panel-overview-container::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}`, "",{"version":3,"sources":["webpack://./src/pages/overview/components/side-panel/SidePanelOverviewTheme.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;AACF;AACE;EACE,UAAA;AACJ;AACE;EACE,yBAAA;AACJ;AACE;EACE,wCAAA;AACJ","sourcesContent":[".side-panel-overview-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  overflow-y: auto;\r\n\r\n  .side-panel-overview-container::-webkit-scrollbar {\r\n    width: 5px;\r\n  }\r\n  .side-panel-overview-container::-webkit-scrollbar-thumb {\r\n    background-color: #3e4451;\r\n  }\r\n  .side-panel-overview-container::-webkit-scrollbar-track {\r\n    background-color: rgba(255, 255, 255, 0);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
