/**
 * Check Data Validation
 */

class EtCeteraUtils {
  static getValue(prm) {
    if (prm === null || prm === undefined || isNaN(prm)) {
      return null
    }
    return prm
  }

  static checkNull(prm) {
    if (prm === null) {
      return true
    }

    return false
  }

  static checkUndefined(prm) {
    if (prm === undefined) {
      return true
    }

    return false
  }

  static isValidData(prm) {
    if (prm !== undefined && prm !== null && !isNaN(prm)) {
      return true
    }

    return false
  }

  static isEmpty(prm) {
    if (prm === undefined || prm === null) {
      return true
    }

    return false
  }

  static isNumber(value) {
    return typeof value === 'number' && !isNaN(value)
  }

  static isPositiveInteger(value) {
    const num = Number(value)

    return Number.isInteger(num) && num > 0
  }

  static isNotEmpty(prm) {
    if (prm !== undefined && prm !== null) {
      return true
    }

    return false
  }

  static timestampedLog(message) {
    const now = new Date()
    const timestamp = now.toISOString()
    console.log(`[${timestamp}] ${message}`)
  }

  static checkLang(lang) {
    if (lang === 'en') {
      return 'En'
    } else if (lang === 'ja') {
      return 'Ja'
    } else {
      return 'Kr'
    }
  }

  static checkLangForECharts(lang) {
    if (lang === 'en') {
      return 'EN'
    } else if (lang === 'ja') {
      return 'JA'
    } else {
      return 'KO'
    }
  }

  // toFixed return String, so made this function for returning number
  static toFixedNumber(num, digits, base) {
    const pow = Math.pow(base ?? 10, digits)
    return Math.round(num * pow) / pow
  }
}

export default EtCeteraUtils
