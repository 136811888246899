import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function ThemeHeatMapColor(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'correlationChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onColorBoxSymbolColorValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.heatMap.relation.color[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`heat-map-color-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'RelationColorHeatMap')}>
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Strong') + ' (-1)',
        labelLocation: 'top',
        name: 'minusStrong',
        value: pageState.heatMap.relation.color.minusStrong,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxSymbolColorValueChanged(e, 'minusStrong'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Strong') + ' (+1)',
        labelLocation: 'top',
        name: 'plusStrong',
        value: pageState.heatMap.relation.color.plusStrong,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxSymbolColorValueChanged(e, 'plusStrong'),
      })}
    </GroupItem>
  )
}
