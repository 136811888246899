import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { setMonitoringPage } from 'src/store/common'
import './SidePanelMonitoringSettings.scss'
import { useRef, useState } from 'react'
import DQToast from 'src/functions/DQToast'
import DQSaveReset from 'src/functions/DQSaveReset'
import { Form } from 'devextreme-react'
import DateTime from './settings/DateTime'
import MonitoringInterval from './settings/MonitoringInterval'
import DisplayCount from './settings/DisplayCount'
import { overviewSettingsPrm } from 'src/pages/overview/components/settings/OverviewSettings'
import { monitoringSettingsPrm } from '../settings/MonitoringSettings'

export default function SidePanelMainSettings(props) {
  const { pageKey } = props
  const dispatch = useDispatch()

  const [scrollPosition, setScrollPosition] = useState(0)
  const { user } = useAuth()
  const parentRef = useRef(null)
  const toastRef = useRef(null)

  const common = useSelector((state) => state.common)
  const curPage = common.pages[pageKey]
  const configPage = common.pages.configuration

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }

  const handleSaveButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }
  const handleResetButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.entries(monitoringSettingsPrm.page).forEach(([key, value]) => {
        draft.page[key] = value
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  return (
    <div ref={parentRef} style={{ padding: '5px' }}>
      <DQToast ref={toastRef} />
      <div style={{ width: '100%', height: '3%' }}>
        <DQSaveReset saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')} resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')} saveCallback={handleSaveButtonClicked} resetCallback={handleResetButtonClicked} />
      </div>
      <Form
        // ref={formRef}
        id="side-panel-overview-settings-form"
        style={{ paddingTop: '5px' }}
        labelMode={'outside'}
        formData={curPage.page}
        showColonAfterLabel={false}
        labelLocation={'top'}
      >
        {/* Devexpress cannot make component type in Form component */}
        {DateTime(pageKey, curPage, configPage)}
        {MonitoringInterval(pageKey, curPage, configPage)}
        {DisplayCount(pageKey, curPage, configPage)}
      </Form>
    </div>
  )
}
