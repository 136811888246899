import { Document, Font, Image, PDFViewer, Page, Text, View } from '@react-pdf/renderer'
import { Button } from 'devextreme-react'
import ReactECharts from 'echarts-for-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { batch, useSelector } from 'react-redux'
import { useLanguage } from 'src/contexts/languages'
import { useTheme } from 'src/contexts/theme'
import CCUtils from 'src/functions/CommonCalcUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import * as HCUtils from 'src/functions/HistogramChartUtils'
import * as PPCUtils from 'src/functions/ProbabilityPlotChartUtils'
import * as VCUtils from 'src/functions/ValueChartUtils'
import { getProbabilityPlotChartOption } from 'src/pages/report-print/components/main-view/chart-option/ReportPrintProbabilityPlotChartOption'
import ReportLayoutPage from './ReportLayoutPage'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'
import ApiStatus from 'src/api/defines/ApiStatus'
import { getHistogram } from 'src/api/histogram'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
import { getProbability } from 'src/api/probability-plot'
import produce from 'immer'
import * as histImpl from 'src/pages/chart-histogram/components/main-view/chart-option/HistogramChartOptionImpl'
import * as probImpl from 'src/pages/chart-probability-plot/components/main-view/chart-option/ProbabilityPlotChartOptionImpl'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ValueChartItem from './jsPdf/ValueChartItem'

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
})

function distributionName(dist) {
    const defineUseDistribution = [
        { value: 0, type: 'Auto-calculation' },
        { value: 1, type: 'Normal-distribution' },
        { value: 2, type: 'Log-normal-distribution' },
        { value: 21, type: 'Folded-normal-distribution' },
        { value: 30, type: 'Weibull-distribution' },
    ]

    const targetDistribution = defineUseDistribution.find((item) => item.value === dist)

    return targetDistribution.type
}

const createStyleSheet = (pageState) => {
    const styles = pageState.page.templates.map((item) => {
        const template = {
            name: item.template.name,
            hint: item.template.hint,
        }
        const page = {
            size: item.page.size,
            orientation: item.page.orientation,
            style: {
                flexDirection: 'column',
                width: '100%',
                ...item.page.style,
            },
        }
        const elements = item.elements.map((element) => {
            return {
                name: element.name,
                columnCount: element.columnCount,
                columnContent: element.columnContent,
                style: {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    ...element.style,
                },
            }
        })

        return { template, page, elements }
    })

    return styles
}

const makeArimaOption = async (settings, configSettings, forecastSize, sampleSrc) => {
    try {
        const { auto, pValue, dValue, qValue } = settings.page.arima
        const srcData = sampleSrc
        const result = await getArima(auto, pValue, dValue, qValue, forecastSize, srcData)
        if (!ApiStatus.isSuccess(result.status)) {
            // alert(getErrCommMsg(result.status))
            return null
        }

        const data = result.data
        return data
    } catch (error) {
        console.error('Error in makeHistogramOption:', error)
    }

    return []
}

function makeSettings(curPage, configSettings, targetChar, targetValue) {
    const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
    const filteredValue = outlierFilterValues(limits, targetChar, targetValue, configSettings)
    const { lcl, ucl, average, minValue, maxValue, v0001s } = PCUtils.getCalculatedPrms(filteredValue, configSettings)
    const x0_135 = lcl
    const x99_865 = ucl
    const minValues = [minValue, lsl, x0_135].filter((value) => value !== null)
    const maxValues = [maxValue, usl, x99_865].filter((value) => value !== null)
    const min = Math.min(...minValues)
    const max = Math.max(...maxValues)
    const xAxisLeft = min - (max - min) * 0.1
    const xAxisRight = max + (max - min) * 0.1

    const decimalPlaces = configSettings.commonSettings.calcDisplayDecimalPlaces

    const settings = {
        lsl,
        usl,
        lwl,
        uwl,
        upl,
        lpl,
        url,
        lrl,
        uacl,
        lacl,
        char: targetChar,
        valuesReference: filteredValue,
        x0_135,
        x99_865,
        nominal,
        xAxisLeft,
        xAxisRight,
        decimalPlaces,
        srcData: v0001s,
        ...configSettings,
    }

    return { settings }
}

function makeUpdateOption(data, settings) {
    console.log('data.bin_count_list')
    console.log(data.bin_count_list)
    const bin_count_list = [...data.bin_count_list]

    const bin_density_list = [...data.bin_density_list]
    const bin_width_start_list = [...data.bin_width_start_list]
    const bin_width_center_list = [...data.bin_width_center_list]
    const bin_width_end_list = [...data.bin_width_end_list]
    settings.xAxisLeft = Math.min(settings.xAxisLeft, data.x0_135)
    settings.xAxisRight = Math.max(settings.xAxisRight, data.x99_865)

    const addStartMinArray = []
    const addStartCenterArray = []
    const addStartMaxArray = []
    const addZeroCountMinArray = []
    let dec = bin_width_end_list[0] - bin_width_start_list[0]
    let ii = bin_width_start_list[0]
    do {
        ii = ii - dec
        addStartMinArray.push(ii)
        addStartCenterArray.push(ii + dec / 2)
        addStartMaxArray.push(ii + dec)
        addZeroCountMinArray.push(0)
    } while (ii >= settings.xAxisLeft - dec)

    addStartMinArray.sort((a, b) => a - b)
    addStartCenterArray.sort((a, b) => a - b)
    addStartMaxArray.sort((a, b) => a - b)

    const addEndMaxArray = []
    const addEndCenterArray = []
    const addEndMinArray = []
    const addZeroCountMaxArray = []
    dec = bin_width_end_list[0] - bin_width_start_list[0]
    let i = bin_width_end_list[bin_width_end_list.length - 1]
    do {
        i = i + dec
        addEndMinArray.push(i - dec)
        addEndCenterArray.push(i - dec / 2)
        addEndMaxArray.push(i)
        addZeroCountMaxArray.push(0)
    } while (i <= settings.xAxisRight + dec)

    addEndMaxArray.sort((a, b) => a - b)
    addEndCenterArray.sort((a, b) => a - b)
    addEndMinArray.sort((a, b) => a - b)

    bin_count_list.unshift(...addZeroCountMinArray)
    bin_count_list.push(...addZeroCountMaxArray)

    bin_density_list.unshift(...addZeroCountMinArray)
    bin_density_list.push(...addZeroCountMaxArray)

    bin_width_start_list.unshift(...addStartMinArray)
    bin_width_start_list.push(...addEndMinArray)

    bin_width_center_list.unshift(...addStartCenterArray)
    bin_width_center_list.push(...addEndCenterArray)

    bin_width_end_list.unshift(...addStartMaxArray)
    bin_width_end_list.push(...addEndMaxArray)

    return {
        bin_count_list: bin_count_list,
        bin_density_list: bin_density_list,
        bin_width_start_list: bin_width_start_list,
        bin_width_center_list: bin_width_center_list,
        bin_width_end_list: bin_width_end_list,
        curve_x_values: data.curve_x_values,
        curve_y_values: data.curve_y_values,
        xBar: data.xBar,
        x99_865: data.x99_865,
        x50: data.x50,
        x0_135: data.x0_135,
    }
}
const makeHistogramOption = async (settings, configSettings, targetChar, targetValue, fdConfig) => {
    try {
        const result = await getHistogram(targetChar, targetValue, settings, configSettings, fdConfig)
        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        const data = result.data
        return data
    } catch (error) {
        console.error('Error in makeHistogramOption:', error)
    }

    return []
}

function makeProbSettings(curPage, configSettings, targetChar, targetValue) {
    const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
    const filteredValue = outlierFilterValues(limits, targetChar, targetValue, configSettings)
    const { lcl, ucl, average, minValue, maxValue, v0001s } = PCUtils.getCalculatedPrms(filteredValue, configSettings)

    // const srcData = targetValue.map((value) => value.v0001)
    const srcData = v0001s

    const x99_865 = ucl
    const x0_135 = lcl

    const minValues = [minValue, lsl, x0_135].filter((value) => value !== null)
    const maxValues = [maxValue, usl, x99_865].filter((value) => value !== null)
    const min = Math.min(...minValues)
    const max = Math.max(...maxValues)
    const xAxisLeft = min - (max - min) * 0.1
    const xAxisRight = max + (max - min) * 0.1
    const decimalPlaces = configSettings.commonSettings.calcDisplayDecimalPlaces

    const settings = {
        ...curPage,
        lsl,
        usl,
        lwl,
        uwl,
        upl,
        lpl,
        url,
        lrl,
        uacl,
        lacl,
        x0_135,
        x99_865,
        nominal,
        char: targetChar,
        valuesReference: filteredValue,
        nominal,
        // warnOffset,
        xAxisLeft,
        xAxisRight,
        decimalPlaces,
        srcData,
        ...configSettings,
    }

    // const configSettings = configPage

    return { targetChar, targetValue, settings, configSettings }
}
const makeProbabilityPlotOption = async (settings, configSettings, targetChar, targetValue) => {
    try {
        const result = await getProbability(targetChar, targetValue, settings, configSettings)
        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        const data = result.data
        return data
    } catch (error) {
        console.error('Error in makeHistogramOption:', error)
    }

    return []
}

export default function ReportPrintPage({ pageKey }) {
    const chartRawData = useSelector((state) => state.chartRawData)
    const common = useSelector((state) => state.common)

    const { getTheme } = useTheme()
    const { language } = useLanguage()
    const theme = getTheme()
    // const chartRef = useRef(null)
    const [isLoadingPanel, setIsLoadingPanel] = useState(false)
    const [showLayoutPdfViewer, setShowLayoutPdfViewer] = useState(true)
    const [dataSourceState, setDataSourceState] = useState([])

    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray, curPage, configPage, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)

    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeInvalidInValues(selectedValueWithDummy, curPage.page.showDeletedValue)

    // const curPage = common.pages.reportPrintMultiFunction
    // const configPage = common.pages.configuration
    const othersPage = common.pages.others

    const valuePage = common.pages.valueChart
    const histPage = common.pages.histogramChart
    const probPage = common.pages.probabilityPlotChart
    // const currentSel = common.current_sel
    const selectedChar = common.loaded_all[pageKey]
    const summaryListPage = common.pages.summaryList

    const chartRefs = useRef(null)
    const histRefs = useRef(null)
    const probRefs = useRef(null)

    const styles = createStyleSheet(curPage)
    const pageSettings = curPage.page
    const themeSettings = curPage.theme
    const configSettings = configPage

    const [arimaShowFlag, setArimaShowFlag] = useState(common.pages.valueChart.page.arima.show)
    const [vcCalcData, setVcCalcData] = useState([])
    const [histCalcData, setHistCalcData] = useState([])
    const [calcDataTheoValue, setCalcDataTheoValue] = useState([])
    const [calcDataTheoQualtile, setCalcDataTheoQualtile] = useState([])
    const [vcOptions, setVcOptions] = useState([])
    const [histOptions, setHistOptions] = useState([])
    const [probOptions, setProbOptions] = useState([])

    const [valueImage, setValueImage] = useState([])
    const [histImage, setHistImage] = useState([])
    const [probImage, setProbImage] = useState([])

    useEffect(() => {
        const numCharts = chars.length

        chartRefs.current = new Array(numCharts).fill(null).map(() => ({ element: null, chartElement: null, id: null }))
        histRefs.current = new Array(numCharts).fill(null).map(() => ({ element: null, chartElement: null, id: null }))
        probRefs.current = new Array(numCharts).fill(null).map(() => ({ element: null, chartElement: null, id: null }))

        const processChartOptions = async () => {
            setIsLoadingPanel(true)
            // let tempArimaChartData = []
            // await Promise.all(
            //     valuesArranged.map(async (value) => {
            //         const targetChar = value.charRaw
            //         const targetValue = value.valueRaw

            //         if (targetValue.length < 1) {
            //             tempArimaChartData.push({ targetChar, targetValue, arimaData: null })
            //             return
            //         }

            //         let responseData = null
            //         if (arimaShowFlag === false && valuePage.page.arima.show === true) {
            //             const { sampleSize, forecastSize } = VCUtils.adjustedForecastSampleSize(valuePage.page.arima.forecastSize, valuePage.page.arima.sampleSize, targetValue.length)
            //             const entireSrc = targetValue.map((value) => value.v0001)
            //             const sampleSrc = entireSrc.slice(-sampleSize)
            //             responseData = await makeArimaOption(valuePage, configPage, forecastSize, sampleSrc)
            //             tempArimaChartData.push({ targetChar, targetValue, arimaData: responseData })
            //         } else {
            //             if (valuePage.page.arima.show === true) {
            //                 const savedTarget = vcCalcData.find((data) => data.targetChar.part_id === value.part_id && data.targetChar.char_id === value.char_id)
            //                 if (!savedTarget) {
            //                     const { sampleSize, forecastSize } = VCUtils.adjustedForecastSampleSize(valuePage.page.arima.forecastSize, valuePage.page.arima.sampleSize, targetValue.length)
            //                     const entireSrc = targetValue.map((value) => value.v0001)
            //                     const sampleSrc = entireSrc.slice(-sampleSize)
            //                     responseData = await makeArimaOption(valuePage, configPage, forecastSize, sampleSrc)
            //                     tempArimaChartData.push({ targetChar, targetValue, arimaData: responseData })
            //                 } else {
            //                     tempArimaChartData.push(savedTarget)
            //                 }
            //             } else {
            //                 tempArimaChartData.push({ targetChar, targetValue, arimaData: null })
            //             }
            //         }
            //     })
            // )

            // tempArimaChartData.sort((a, b) => {
            //     if (a.targetChar.part_id !== b.targetChar.part_id) {
            //         return a.targetChar.part_id - b.targetChar.part_id
            //     } else {
            //         return a.targetChar.char_id - b.targetChar.char_id
            //     }
            // })

            // const valueOptions = tempArimaChartData.map((data) => {
            //     const targetChar = data.targetChar
            //     const targetValue = data.targetValue
            //     const arimaData = data.arimaData

            //     const pcSet = VCUtils.makePCData(configPage, targetChar, targetValue)
            //     if (pcSet === null) {
            //         return { targetChar, targetValue, option: null, error: { name: 'PCSettingsError', extra: 'warn offset' } }
            //     }
            //     const chartSet = VCUtils.makeChartData(valuePage, configPage, pcSet, targetChar, targetValue)
            //     if (chartSet === null) {
            //         return { targetChar, targetValue, option: null, error: { name: 'ChartSettingsError', extra: 'chart data' } }
            //     }
            //     let valueChartOption = VCUtils.makeChartOption(pageKey, valuePage, configPage, othersPage, pcSet, chartSet, targetChar, targetValue, getTheme())
            //     if (valueChartOption === null) {
            //         return { targetChar, targetValue, option: null, error: { name: 'MakeChartOptionError', extra: null } }
            //     }

            //     if (valuePage.page.arima.show === true) {
            //         if (arimaData === null) {
            //             return { targetChar, targetValue, option: valueChartOption, error: { name: 'ArimaSettingError', extra: null } }
            //         }
            //         const expectFieldType = ['low_pred', 'pred', 'upper_pred']
            //         const forecastFormat = { expectation: [] }
            //         const minMaxValues = []

            //         expectFieldType.forEach((field) => {
            //             forecastFormat.expectation.push({
            //                 name: field,
            //                 data: arimaData[field],
            //             })
            //             minMaxValues.push(...arimaData[field])
            //         })

            //         const min = Math.min(...minMaxValues)
            //         const max = Math.max(...minMaxValues)
            //         const addDataLength = forecastFormat.expectation[0].data.length

            //         const chartXAxis = valueChartOption.xAxis
            //         let chartXAxisLength = chartXAxis.data.length + addDataLength
            //         const chartOneSeries = valueChartOption.series.find((series) => series.name === dqTranslateMsg('Page_ValueChart_' + 'ValueInfo'))
            //         let charDataLength = chartOneSeries.data.length

            //         const xAxisData = []
            //         for (let i = 1; i <= chartXAxisLength; i++) {
            //             xAxisData.push([i])
            //         }

            //         const next = produce(valueChartOption, (draft) => {
            //             forecastFormat.expectation.forEach((item) => {
            //                 let index = charDataLength + 1
            //                 draft.series.push({
            //                     type: 'line',
            //                     id: item.name,
            //                     name: arimaNameTranslate(item.name),
            //                     data: item.data.map((data) => [index++, data]),
            //                     itemStyle: {
            //                         color: item.name === 'low_pred' || item.name === 'upper_pred' ? valuePage.lineChart.arimaChartLines.outer.color : valuePage.lineChart.arimaChartLines.inner.color,
            //                     },
            //                     lineStyle: {
            //                         color: item.name === 'low_pred' || item.name === 'upper_pred' ? valuePage.lineChart.arimaChartLines.outer.color : valuePage.lineChart.arimaChartLines.inner.color,
            //                     },
            //                 })
            //                 draft.xAxis.data = xAxisData
            //                 draft.xAxis.min = 0
            //                 draft.xAxis.max = xAxisData.length

            //                 draft.yAxis.min = valueChartOption.yAxis.min < min ? draft.yAxis.min : min
            //                 draft.yAxis.max = valueChartOption.yAxis.max > max ? draft.yAxis.max : max
            //             })
            //         })

            //         return { targetChar, targetValue, option: next, error: { name: null, extra: null } }
            //     } else {
            //         return { targetChar, targetValue, option: valueChartOption, error: { name: null, extra: null } }
            //     }
            // })

            let fdData = configPage.findDistribution
            let histogramChartData = []
            await Promise.all(
                valuesArranged.map(async (value) => {
                    const targetChar = value.charRaw
                    const targetValue = value.valueRaw

                    if (targetValue.length < 5) {
                        histogramData.push({
                            info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                            targetChar,
                            targetValue,
                            settings: null,
                            responseData: null,
                            error: { name: 'DataIsNotEnough', extra: targetValue.length },
                        })
                        return
                    }

                    const { settings } = makeSettings(histPage, configPage, targetChar, targetValue)
                    const isAdded = histCalcData.some((data) => data.info.part_id === value.part_id && data.info.char_id === value.char_id)

                    if (!isAdded) {
                        const responseData = await makeHistogramOption(settings, configPage, targetChar, settings.valuesReference, fdData)
                        if (responseData.hasOwnProperty('curve_x_values')) {
                            histogramChartData.push({
                                info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                                targetChar,
                                targetValue,
                                settings,
                                responseData,
                                error: { name: null, extra: null },
                            })
                        } else {
                            histogramChartData.push({
                                info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                                targetChar,
                                targetValue,
                                settings: null,
                                responseData: null,
                                error: { name: 'Deviation', extra: 0 },
                            })
                        }
                    } else {
                        const tgData = histCalcData.find((data) => data.info.part_id === value.part_id && data.info.char_id === value.char_id)
                        histogramChartData.push(tgData)
                    }
                })
            )

            histogramChartData.sort((a, b) => {
                if (a.info.part_id !== b.info.part_id) {
                    return a.info.part_id - b.info.part_id
                } else {
                    return a.info.char_id - b.info.char_id
                }
            })
            const histOptions = histogramChartData.map((data) => {
                const targetChar = data.targetChar
                const targetValue = data.targetValue
                const settings = data.settings
                const responseData = data.responseData
                if (data.error.name !== null) {
                    return {
                        info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar },
                        option: null,
                        error: { name: data.error.name, extra: data.error.extra },
                    }
                }
                const settingsValue = { ...settings, ...histPage }
                const configSettings = configPage

                const options = HCUtils.makeChartOption(pageKey, histPage, configPage, targetChar, targetValue)
                const optionBaseData = makeUpdateOption(responseData, settingsValue)
                const bellCurveData = optionBaseData.curve_x_values.map((x, index) => [x, optionBaseData.curve_y_values[index]])

                // const option = HCUtils.makeChartOption(pageKey, histPage, configPage, char, valueRaw)
                // const optionBaseData = makeUpdateOption(responseData, settingsValue)
                // const bellCurveData = optionBaseData.curve_x_values.map((x, index) => [x, optionBaseData.curve_y_values[index]])

                const next = produce(options, (draft) => {
                    draft.series = []
                    draft.series.push({
                        type: 'bar',
                        name: 'count',
                        // xAxisIndex: 0,
                        yAxisIndex: 0, // Ensure it's linked to the second (right) Y-axis
                        // data: optionBaseData.bin_density_list,
                        data: optionBaseData.bin_count_list,
                        itemStyle: {
                            color: settingsValue.barChart.histogramBar.color,
                        },
                        barWidth: '99%',
                    })
                    draft.series.push({
                        type: 'line',
                        name: 'curve',
                        xAxisIndex: 1,
                        yAxisIndex: 1, // Ensure it's linked to the second (right) Y-axis
                        data: bellCurveData,
                        // data: optionBaseData.curve_y_values,
                        symbol: 'none',
                        itemStyle: {
                            color: settingsValue.barChart.bellCurve.color,
                        },
                        lineStyle: {
                            type: settingsValue.lineChart.chartLines.type,
                            width: settingsValue.lineChart.chartLines.width,
                        },
                    })
                    draft.series.push({
                        type: 'line',
                        name: 'Lines',
                        xAxisIndex: 1,
                        markLine: {
                            animation: false,
                            symbol: 'none',
                            precision: 10,
                            data: histImpl.convertToMarkLine(responseData.dist, settingsValue, configSettings, optionBaseData),
                            emphasis: {
                                disabled: true,
                            },
                        },
                    })

                    draft.xAxis[0].data = optionBaseData.bin_width_center_list
                    draft.xAxis[0].axisLabel.formatter = (value, index) => histImpl.makeXAxisLabelFormat(value, configSettings)

                    draft.xAxis[1].min = optionBaseData.bin_width_center_list[0]
                    draft.xAxis[1].max = optionBaseData.bin_width_center_list[optionBaseData.bin_width_center_list.length - 1]

                    draft.yAxis[0].name = 'count'
                    draft.yAxis[0].position = 'left'

                    draft.yAxis[1].name = 'curve'
                    draft.yAxis[1].position = 'right'

                    draft.tooltip.formatter = function (args) {
                        return histImpl.toolTipBalloonCallback(args, targetChar, settingsValue, configSettings, responseData.dist, optionBaseData)
                    }

                    draft.title.text = `${targetChar.c2002 ?? 'empty'} P${targetChar.part_id ?? '?'}/C${targetChar.char_id ?? '?'}` + ' : ' + distributionName(responseData.dist)
                    draft.title.textStyle.fontSize = (settingsValue.page.fontSize / (settingsValue.page.layout.row + settingsValue.page.layout.column)) * 4
                })
                return { info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar }, option: next, error: { name: null, extra: null } }
            })

            // Fetch all chart options concurrently
            let probPlotData = []
            let targetCalcData = calcDataTheoValue
            let targetSetCalcData = setCalcDataTheoValue

            if (curPage.page.yAxisType === 'Theoretical-Value') {
                targetCalcData = calcDataTheoValue
                targetSetCalcData = setCalcDataTheoValue
            } else {
                targetCalcData = calcDataTheoQualtile
                targetSetCalcData = setCalcDataTheoQualtile
            }

            await Promise.all(
                valuesArranged.map(async (value) => {
                    const targetChar = value.charRaw
                    const targetValue = value.valueRaw

                    if (targetValue.length < 5) {
                        probPlotData.push({
                            info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                            targetChar,
                            targetValue,
                            settings: null,
                            configSettings: null,
                            responseData: null,
                            error: { name: 'DataIsNotEnough', extra: targetValue.length },
                        })
                        return
                    }

                    const { settings } = makeProbSettings(curPage, configPage, targetChar, targetValue)
                    const isAdded = targetCalcData.some((data) => data.info.part_id === value.part_id && data.info.char_id === value.char_id)

                    if (!isAdded) {
                        const responseData = await makeProbabilityPlotOption(settings, configPage, targetChar, settings.valuesReference)
                        if (responseData.hasOwnProperty('line_x_values')) {
                            probPlotData.push({
                                info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                                targetChar,
                                targetValue,
                                settings,
                                // configSettings: configPage,
                                responseData,
                                error: { name: null, extra: null },
                            })
                        } else {
                            probPlotData.push({
                                info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                                targetChar,
                                targetValue,
                                settings: null,
                                // configSettings: null,
                                responseData: null,
                                error: { name: 'Deviation', extra: 0 },
                            })
                        }
                    } else {
                        const tgData = targetCalcData.find((data) => data.info.part_id === value.part_id && data.info.char_id === value.char_id)
                        probPlotData.push(tgData)
                    }
                })
            )

            probPlotData.sort((a, b) => {
                if (a.info.part_id !== b.info.part_id) {
                    return a.info.part_id - b.info.part_id
                } else {
                    return a.info.char_id - b.info.char_id
                }
            })

            const probOptions = probPlotData.map((data) => {
                const targetChar = data.targetChar
                const targetValue = data.targetValue
                const settings = data.settings
                const responseData = data.responseData

                if (data.error.name !== null) {
                    return { info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar }, option: null, error: { name: data.error.name, extra: data.error.extra } }
                }

                const settingsValue = { ...settings, ...probPage }
                const configSettings = configPage

                const valueIntegerLineX = CCUtils.convertExponentialArrayToInt(responseData.line_x_values)
                const valueIntegerLineY = CCUtils.convertExponentialArrayToInt(responseData.line_y_values)
                const valueIntegerPointX = CCUtils.convertExponentialArrayToInt(responseData.point_x_values)
                const valueIntegerPointY = CCUtils.convertExponentialArrayToInt(responseData.point_y_values)

                const valueIntegerX0_135 = CCUtils.convertExponentialValueToInt(responseData.x0_135)
                const valueIntegerX99_869 = CCUtils.convertExponentialValueToInt(responseData.x99_865)

                const lineMin = Math.min(...valueIntegerLineX)
                const lineMax = Math.max(...valueIntegerLineX)
                const pointMin = Math.min(...valueIntegerPointX)
                const pointMax = Math.max(...valueIntegerPointX)
                const dataMin = lineMin < pointMin ? lineMin : pointMin
                const dataMax = pointMax < lineMax ? pointMax : lineMax

                const minValues = [dataMin, settingsValue.lsl].filter((value) => value !== null)
                const maxValues = [dataMax, settingsValue.usl].filter((value) => value !== null)

                const min = Math.min(...minValues, valueIntegerX0_135)
                const max = Math.max(...maxValues, valueIntegerX99_869)
                const xAxisLeft = min - (max - min) * 0.1
                const xAxisRight = max + (max - min) * 0.1
                const yAxisBottom = dataMin - (dataMax - dataMin) * 0.1
                const yAxisTop = dataMax + (dataMax - dataMin) * 0.1

                const probOption = PPCUtils.makeChartOption(pageKey, probPage, configPage, targetChar, targetValue)

                const next = produce(probOption, (draft) => {
                    if (pageKey !== 'reportPrint') {
                        draft.xAxis[0].axisLabel.fontSize = ((settingsValue.page.fontSize - 2) / (settingsValue.page.layout.row + settingsValue.page.layout.column)) * 3
                        draft.yAxis.axisLabel.fontSize = ((settingsValue.page.fontSize - 2) / (settingsValue.page.layout.row + settingsValue.page.layout.column)) * 3
                    } else {
                        draft.xAxis[0].axisLabel.fontSize = 10
                        draft.yAxis.axisLabel.fontSize = 10
                    }
                    draft.series = []
                    draft.series.push({
                        type: 'line',
                        name: 'line',
                        symbol: 'none',
                        // data: responseData.line_x_values.map((x, index) => [x, responseData.line_y_values[index]]),
                        data: valueIntegerLineX.map((x, index) => [x, valueIntegerLineY[index]]),
                        animation: true,
                        lineStyle: {
                            type: settingsValue.lineChart.chartLines.type,
                            width: settingsValue.lineChart.chartLines.width,
                            color: settingsValue.lineChart.chartLines.color,
                        },
                        animation: true,
                    })
                    draft.series.push({
                        type: 'scatter',
                        name: dqTranslateMsg('Page_ProbabilityPlotChart_' + 'ValueInfo'),
                        xAxisIndex: 0,
                        yAxisIndex: 0, // Ensure it's linked to the second (right) Y-axis
                        // data: responseData.point_x_values.map((x, index) => [x, CCUtils.convertExponentialValueToInt(responseData.point_y_values[index])]),
                        data: valueIntegerPointX.map((x, index) => [x, valueIntegerPointY[index]]),
                        symbol: (value, params) => probImpl.makeSymbol(value, params, settingsValue),
                        symbolSize: (value, params) => probImpl.makeSymbolSize(value, params, settingsValue),
                        animation: true,
                        itemStyle: {
                            color: (params) => probImpl.makeSymbolColor(params, settingsValue),
                        },
                    })
                    draft.series.push({
                        type: 'line',
                        name: dqTranslateMsg('Page_ProbabilityPlotChart_' + 'Limits'),
                        yAxisIndex: 0,
                        markLine: {
                            animation: false,
                            symbol: 'none',
                            precision: 10,
                            // precision: settingsValue.lineChart.markLines.precision,
                            data: probImpl.convertToMarkLine(responseData, settingsValue, configSettings),
                            emphasis: {
                                disabled: true,
                            },
                        },
                    })

                    if (pageKey !== 'reportPrint') {
                        draft.title.text = `${targetChar.c2002 ?? 'empty'}P:${targetChar.part_id ?? '?'}/C:${targetChar.char_id ?? '?'}`
                        draft.title.text += ' : ' + distributionName(responseData.dist)
                        draft.title.subtext = 'R² = ' + DNUtils.calcValueDisplay(responseData.r2, configSettings)
                    }

                    draft.xAxis[0].min = xAxisLeft
                    draft.xAxis[0].max = xAxisRight
                    // draft.xAxis[0].axisLabel.formatter = (value, index) => DNUtils.calcValueDisplay(value, configSettings)
                    // draft.yAxis.axisLabel.formatter = (value, index) => DNUtils.calcValueDisplay(value, configSettings)
                    draft.xAxis[0].axisLabel.formatter = function (value) {
                        // return DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(value, decimalPlaces), isExponential)
                        // return DNUtils.calcValueDisplay(values, configSettings)
                        const axisMax = xAxisRight
                        const axisMin = xAxisLeft

                        // 최대값과 최소값은 레이블을 표시하지 않음
                        if (value === axisMin || value === axisMax) {
                            return ''
                        }

                        return value // 그 외의 값은 그대로 표시
                    }

                    draft.yAxis.min = settings.page.yAxisType === 'Theoretical-Value' ? yAxisBottom : 0
                    draft.yAxis.max = settings.page.yAxisType === 'Theoretical-Value' ? yAxisTop : 100
                    draft.yAxis.axisLabel.formatter = function (value) {
                        if (settings.page.yAxisType === 'Theoretical-Value') {
                            const axisMax = yAxisTop
                            const axisMin = yAxisBottom

                            if (value === axisMin || value === axisMax) {
                                return ''
                            }

                            return value
                        } else {
                            return value
                        }
                    }
                })
                return { info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar }, option: next, error: { name: 'DataIsNotEnough', extra: targetValue.length } }
            })

            batch(() => {
                // setArimaShowFlag(valuePage.page.arima.show)
                // setVcCalcData(tempArimaChartData)
                // setVcOptions(valueOptions)
                setHistCalcData(histogramChartData)
                setHistOptions(histOptions)
                targetSetCalcData(probPlotData)
                setProbOptions(probOptions)
            })
        }
        processChartOptions()
    }, [selectedChar, curPage, configPage])

    useEffect(() => {
        const valueImageArrayState = chartRefs.current.map((chartRef, index) => {
            if (chartRef.element) {
                const chartInstance = chartRef.element.getEchartsInstance()
                return chartInstance.getDataURL({
                    type: 'png', // Request the SVG format
                    pixelRatio: 5, // 원하는 해상도를 설정할 수 있습니다.
                    backgroundColor: '#fff', // 배경색을 설정할 수 있습니다.
                })
            }
            return null
        })

        const histImageArrayState = histRefs.current.map((chartRef, index) => {
            if (chartRef.element) {
                const chartInstance = chartRef.element.getEchartsInstance()
                return chartInstance.getDataURL({
                    type: 'png', // Request the SVG format
                    pixelRatio: 5, // 원하는 해상도를 설정할 수 있습니다.
                    backgroundColor: '#fff', // 배경색을 설정할 수 있습니다.
                })
            }
            return null
        })

        const probImageArrayState = probRefs.current.map((chartRef, index) => {
            if (chartRef.element) {
                const chartInstance = chartRef.element.getEchartsInstance()
                return chartInstance.getDataURL({
                    type: 'png', // Request the SVG format
                    pixelRatio: 5, // 원하는 해상도를 설정할 수 있습니다.
                    backgroundColor: '#fff', // 배경색을 설정할 수 있습니다.
                })
            }
            return null
        })

        batch(() => {
            setValueImage(valueImageArrayState) // Save the Base64 encoded SVG data URL
            setHistImage(histImageArrayState) // Save the Base64 encoded SVG data URL
            setProbImage(probImageArrayState) // Save the Base64 encoded SVG data URL
        })
    }, [selectedChar, curPage, configPage])

    return (
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
                {/* {showLayoutPdfViewer && (
                    <div style={{ width: '50%', height: '100%' }}>
                        <PDFViewer
                            key={'layout-pdf-viewer'}
                            style={{ width: '100%', height: '100%', paddingTop: '60px' }}
                            showToolbar={false}
                        >
                            <Document>
                                <ReportLayoutPage
                                    styles={styles}
                                    pageSettings={pageSettings}
                                    configSettings={configSettings}
                                    // valuesArranged={valuesArranged}
                                    theme={theme}
                                    language={language}
                                />
                            </Document>
                        </PDFViewer>
                    </div>
                )} */}
                <Button
                    icon={showLayoutPdfViewer ? 'chevronleft' : 'chevronright'}
                    onClick={() => setShowLayoutPdfViewer(!showLayoutPdfViewer)}
                    type='success'
                    stylingMode='contained'
                    style={{
                        alignSelf: 'center',
                        height: '40px',
                        width: '40px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '10px',
                    }}
                />
                <div style={{ width: showLayoutPdfViewer ? '50%' : '100%', height: '100%' }}>
                    <PDFViewer
                        key={'data-pdf-viewer'}
                        style={{ width: '100%', height: '100%' }}
                        showToolbar={true}
                    >
                        <Document>
                            {valuesArranged.map((values, index) => {
                                // const targetStyle = styles[0]
                                const targetStyle = styles[1]
                                return (
                                    <Page
                                        size={targetStyle.page.size}
                                        style={targetStyle.page.style}
                                        orientation={targetStyle.page.orientation}
                                        debug={false}
                                    >
                                        {targetStyle.elements.map((viewElement, viewIndex) => {
                                            return (
                                                <View
                                                    key={`view-element-${viewIndex}`}
                                                    style={viewElement.style}
                                                    debug={false}
                                                >
                                                    {viewElement.columnContent.map((el, elIndex) => {
                                                        if (el.type === 'text') {
                                                            return <Text style={el.style}>{el.content}</Text>
                                                        } else if (el.type === 'valueChart') {
                                                            return (
                                                                // <Image
                                                                //     style={el.style}
                                                                //     src={valueImage[index]}
                                                                // />
                                                                <ValueChartItem
                                                                    partId={25}
                                                                    charId={1}
                                                                    style={el.style}
                                                                    common={common}
                                                                    valuePage={valuePage}
                                                                    othersPage={othersPage}
                                                                    src={valueImage[index]}
                                                                />
                                                            )
                                                        } else if (el.type === 'histogramChart') {
                                                            return (
                                                                <Image
                                                                    style={el.style}
                                                                    src={histImage[index]}
                                                                />
                                                            )
                                                        } else if (el.type === 'probabilityPlotChart') {
                                                            return (
                                                                <Image
                                                                    style={el.style}
                                                                    src={probImage[index]}
                                                                />
                                                            )
                                                        } else {
                                                            return <Text style={el.style}>Not Use element type</Text>
                                                        }
                                                    })}
                                                </View>
                                            )
                                        })}
                                    </Page>
                                )
                            })}
                        </Document>
                    </PDFViewer>
                </div>
            </div>
            <div style={{ display: 'none' }}>
                {vcOptions.length > 0 && histOptions.length > 0 && probOptions.length > 0
                    ? valuesArranged.map((_, index) => {
                          return (
                              <div key={`${vcOptions[index].part_id}_${vcOptions[index].char_id}`}>
                                  <ReactECharts
                                      ref={(e) => {
                                          chartRefs.current[index].element = e
                                          chartRefs.current[index].id = `${vcOptions[index].part_id}_${vcOptions[index].char_id}`
                                      }}
                                      style={{ width: '300px', height: '200px', display: 'none' }}
                                      option={vcOptions[index].option}
                                      opts={{
                                          renderer: 'png',
                                      }}
                                  />
                                  <ReactECharts
                                      ref={(e) => {
                                          histRefs.current[index].element = e
                                          histRefs.current[index].id = `${histOptions[index].part_id}_${histOptions[index].char_id}`
                                      }}
                                      style={{ width: '300px', height: '200px', display: 'none' }}
                                      option={histOptions[index].option}
                                      opts={{
                                          renderer: 'png',
                                      }}
                                  />
                                  <ReactECharts
                                      ref={(e) => {
                                          probRefs.current[index].element = e
                                          probRefs.current[index].id = `${probOptions[index].part_id}_${probOptions[index].char_id}`
                                      }}
                                      style={{ width: '300px', height: '200px', display: 'none' }}
                                      option={probOptions[index].option}
                                      opts={{
                                          renderer: 'png',
                                      }}
                                  />
                              </div>
                          )
                      })
                    : null}
            </div>
        </div>
    )
}
