import { pageStatusList as ps } from './functions/constants/pageStatus'
import { defaultRibbon } from './pages/admin/components/settings/AdminSettings'
import { MONO_BLUE, MONO_RED, COLOR } from './functions/constants/iconPathDefine'

const getIconPath = (targetPage, mode, theme, swatchTheme) => {
  let iconPath = targetPage.path.icon.color

  if (mode === MONO_BLUE || mode === MONO_RED) {
    iconPath = theme.includes('dark') || theme.includes('contrast') || swatchTheme === 'standard' ? targetPage.path.icon[mode].dark : targetPage.path.icon[mode].light
  }

  return iconPath
}

const generateNavMenu = (navList, mode, theme, swatchTheme, dataLoaded) => {
  // const userRibbon = defaultRibbon[role] || []
  // const userRibbon = [...navList].sort((a, b) => a.orderNo - b.orderNo)
  const userRibbon = [...navList]
  const charts = userRibbon.filter((item) => item.parent === 'charts')
  const lists = userRibbon.filter((item) => item.parent === 'lists')

  // Conditionally include charts and lists based on dataLoaded and role permissions
  const availableCharts = dataLoaded ? charts : []
  const availableLists = dataLoaded ? lists : []

  // Common items should always be included but filtered based on the role's access
  const availableCommon = dataLoaded ? userRibbon.filter((item) => item.parent === '') : userRibbon.filter((item) => item.parent === '' && item.isDataNeed === false)
  const parentArray = Array.from(new Set(userRibbon.map((item) => (item.parent !== '' ? item.parent : null)).filter((result) => result !== null)))
  const availableOther = userRibbon.filter((item) => item.parent !== '')

  const createMenuItemSub = (item) => {
    const itemKey = item.name
    return {
      key: itemKey,
      text: `Navigation_${ps[itemKey]?.label}`,
      path: ps[itemKey]?.path?.page,
      icon: getIconPath(ps[itemKey], mode, theme, swatchTheme),
    }
  }

  const createMenuItem = (item) => {
    const itemKey = item.name
    return {
      key: itemKey,
      text: `Navigation_${ps[itemKey]?.label}`,
      path: ps[itemKey]?.path?.page,
      icon: getIconPath(ps[itemKey], mode, theme, swatchTheme),
    }
  }

  let menuItems = [...availableCommon.map((item) => createMenuItem(item))]

  parentArray.forEach((element) => {
    const subMenu = availableOther.filter((item) => item.parent === element)
    const menuIndex = menuItems.findIndex((item) => item.key === element)

    if (menuIndex !== -1) {
      menuItems[menuIndex] = {
        ...menuItems[menuIndex],
        items: subMenu.map((item) => createMenuItemSub(item)),
      }
    }
  })

  return menuItems
}
export const navMenu = (navList, mode, theme, swatchTheme, dataLoaded) => generateNavMenu(navList, mode, theme, swatchTheme, dataLoaded)
