import ReactECharts from 'echarts-for-react'
import { useCallback, useEffect, useRef } from 'react'
import { useTheme } from 'src/contexts/theme'
import * as VCUtils from 'src/functions/ValueChartUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import styled from 'styled-components'

function throttle(func, delay) {
  let inThrottle
  let lastFunc
  let lastRan

  return function () {
    const context = this
    const args = arguments

    if (!inThrottle) {
      func.apply(context, args)
      lastRan = Date.now()
      inThrottle = true
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= delay) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, delay - (Date.now() - lastRan))
    }
  }
}

// Ensure the ReactECharts component takes full width and height of its container.
const StyledReactECharts = styled(ReactECharts)`
  width: 100%;
  height: 100%;
`

export function DecisionTreeValueChart({ pageKey, pageSettings, configSettings, language, targetData }) {
  const { getTheme } = useTheme()
  const theme = getTheme()
  const echartsRef = useRef(null)

  const pcSet = VCUtils.makePCData(configSettings, targetData.charRaw, targetData.valueRaw)
  // if (pcSet === null) {
  //   return { targetChar, targetValue, option: null, error: { name: 'PCSettingsError', extra: 'warn offset' } }
  // }
  const chartSet = VCUtils.makeChartData(pageSettings, configSettings, pcSet, targetData.charRaw, targetData.valueRaw)
  // if (chartSet === null) {
  //   return { targetChar, targetValue, option: null, error: { name: 'ChartSettingsError', extra: 'chart data' } }
  // }

  // Generate chart options using utility functions.
  const option = VCUtils.makeChartOption(
    pageKey,
    pageSettings,
    configSettings,
    pcSet,
    chartSet,
    targetData.charRaw, // Ensure that charRaw is the intended property. If it's a typo, correct as necessary.
    targetData.valueRaw,
    theme
  )

  // const resizeChart = useCallback(
  //   throttle((event) => {
  //     if (echartsRef.current) {
  //       echartsRef.current.getEchartsInstance().resize()
  //       console.log('chart resizing end')
  //     }
  //   }, 1000),
  //   []
  // )

  // useEffect(() => {
  //   window.addEventListener('resize', resizeChart)

  //   return () => window.removeEventListener('resize', resizeChart)
  // }, []) // Ensure this effect runs only once when the component mounts.

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <ReactECharts
        ref={echartsRef}
        style={{ width: '750px', height: '450px' }}
        notMerge={true} // Prevents merging with previous options.
        option={option} // Chart configuration options.
        theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'} // Dynamic theme based on current theme context.
        opts={{
          // width: 'auto',
          // height: 'auto',
          renderer: 'svg', // Use SVG for better quality at the expense of performance.
          locale: ETCUtils.checkLangForECharts(language), // Ensure language is correctly parsed and passed.
        }}
        // onEvents={{ finished: resizeChart }}
      />
    </div>
  )
}
