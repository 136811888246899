// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.key-info-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.key-info-container .key-info-control-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 4%;
}
.key-info-container .key-info-control-container .button-container {
  width: 8%;
  height: 100%;
}
.key-info-container .catalog-content-container {
  width: 20%;
  height: 96%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/config/components/main-view/key-info/KeyInfoConfig.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,yBAAA;EACA,WAAA;EACA,UAAA;AACJ;AACI;EACE,SAAA;EACA,YAAA;AACN;AAGE;EACE,UAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AADJ","sourcesContent":[".key-info-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 5px;\r\n\r\n  .key-info-control-container {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    width: 100%;\r\n    height: 4%;\r\n\r\n    .button-container {\r\n      width: 8%;\r\n      height: 100%;\r\n    }\r\n  }\r\n\r\n  .catalog-content-container {\r\n    width: calc(100% / 5);\r\n    height: 96%;\r\n    padding: 5px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    padding: 5px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
