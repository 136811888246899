import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

export default function SettingsEtcetera(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'paretoChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onCheckBoxShowEmptyCharValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`etcetera-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'Etcetera')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'EmptyCharacteristics'),
        labelLocation: 'right',
        name: 'showEmptyCharacteristics',
        value: pageState.page.showEmptyCharacteristics,
        disabled: false,
        onValueChanged: (e) => onCheckBoxShowEmptyCharValueChanged(e, 'showEmptyCharacteristics'),
      })}
    </GroupItem>
  )
}
