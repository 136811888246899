import { ColorBox, Form, ScrollView } from 'devextreme-react'
import { Button } from 'devextreme-react/button'
import { NumberBox, Button as NumberBoxButton } from 'devextreme-react/number-box'
import SelectBox from 'devextreme-react/select-box'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { positionalTolerancesChartSettingsPrm } from 'src/pages/chart-positional-tolerances/components/settings/PositionalTolerancesChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import * as sd from '../../../components/side-panel-form/component/define/SettingsDefine'
import DQToast from 'src/functions/DQToast'
import DQSaveReset from 'src/functions/DQSaveReset'
import NGSymbol from './components/theme/NGSymbol'
import WarningSymbol from './components/theme/WarningSymbol'
import GoodSymbol from './components/theme/GoodSymbol'
import ThemePTChart from './components/theme/ThemePTChart'
import ChartLine from './components/theme/ChartLine'
import SpecificationLimit from './components/theme/SpecificationLimit'
import WarningLimit from './components/theme/WarningLimit'
import MarkArea from './components/theme/MarkArea'
import ThemeControlLimit from './components/theme/ThemeControlLimit'
import ThemeAverage from './components/theme/ThemeAverage'
import ThemeNominal from './components/theme/ThemeNominal'

export default function SidePanelPositionalTolerancesChartTheme(props) {
  const { pageKey } = props
  const dispatch = useDispatch()

  const [scrollPosition, setScrollPosition] = useState(0)
  const { user } = useAuth()
  const parentRef = useRef(null)
  const toastRef = useRef(null)

  const common = useSelector((state) => state.common)
  const curPage = common.pages.positionalTolerancesChart
  const configPage = common.pages.configuration

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }
  const handleSaveButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    // handleCurPageSettingsSave(next)
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, next)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }
  const handleResetButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.entries(positionalTolerancesChartSettingsPrm.lineChart).forEach(([key, value]) => {
        draft.lineChart[key] = value
      })
      Object.entries(positionalTolerancesChartSettingsPrm.ptChart).forEach(([key, value]) => {
        draft.ptChart[key] = value
      })
    })

    // handleCurPageSettingsSave(next)
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  const handleScroll = (e) => {
    setScrollPosition(e.scrollOffset.top)
  }

  const items = (pageState, configState) => [
    NGSymbol(pageKey, pageState, configState),
    WarningSymbol(pageKey, pageState, configState),
    GoodSymbol(pageKey, pageState, configState),
    ThemePTChart(pageKey, pageState, configState),
    ChartLine(pageKey, pageState, configState),
    SpecificationLimit(pageKey, pageState, configState),
    WarningLimit(pageKey, pageState, configState),
    ThemeAverage(pageKey, pageState, configState),
    ThemeNominal(pageKey, pageState, configState),
    // ThemeControlLimit(pageKey, pageState, configState), // Uncomment if you want to include this item
    MarkArea(pageKey, pageState, configState),
  ]

  return (
    <div ref={parentRef} style={{ padding: '5px', width: '100%', height: '100%' }}>
      <DQToast ref={toastRef} />
      <div style={{ width: '100%', height: '3%' }}>
        <DQSaveReset
          saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
          resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
          saveCallback={handleSaveButtonClicked}
          resetCallback={handleResetButtonClicked}
        />
      </div>
      <div style={{ width: '100%', height: '97%' }}>
        <ScrollView
          className="side-panel-tab-container"
          showScrollbar={'onHover'}
          useNative={false}
          onScroll={handleScroll}
          onContentReady={(e) => {
            e.component.scrollTo({ top: scrollPosition })
          }}
        >
          <Form id="side-panel-histogram-chart-settings-form" style={{ paddingTop: '5px' }} labelMode={'outside'} formData={curPage.page} showColonAfterLabel={false}>
            {items(curPage, configPage)}
          </Form>
        </ScrollView>
      </div>
    </div>
  )
}
