import { Form, ScrollView } from 'devextreme-react'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import DQSaveReset from 'src/functions/DQSaveReset'
import DQToast from 'src/functions/DQToast'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { valueChartSettingsPrm } from 'src/pages/chart-value/components/settings/ValueChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import ArimaChartLine from './components/theme/ArimaChartLine'
import ChartLine from './components/theme/ChartLine'
import GoodSymbol from './components/theme/GoodSymbol'
import MarkArea from './components/theme/MarkArea'
import NGSymbol from './components/theme/NGSymbol'
import SpecificationLimit from './components/theme/SpecificationLimit'
import WarningLimit from './components/theme/WarningLimit'
import WarningSymbol from './components/theme/WarningSymbol'
import './SidePanelValueChartTheme.scss'
import ThemeAverage from './components/theme/ThemeAverage'
import ThemeNominal from './components/theme/ThemeNominal'
import ThemePlausibilityLimit from './components/theme/ThemePlausibilityLimit'
import ThemeScrapLimit from './components/theme/ThemeScrapLimit'
import ThemeAcceptanceLimit from './components/theme/ThemeAcceptanceLimit'

export default function SidePanelValueChartTheme(props) {
  const { pageKey } = props
  const dispatch = useDispatch()

  const [scrollPosition, setScrollPosition] = useState(0)
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const parentRef = useRef(null)
  const toastRef = useRef(null)

  const common = useSelector((state) => state.common)
  const curPage = common.pages.valueChart
  const configPage = common.pages.configuration

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }

  const handleSaveButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }
  const handleResetButtonClicked = (e) => {
    const next = produce(curPage, (draft) => {
      Object.entries(valueChartSettingsPrm.lineChart).forEach(([key, value]) => {
        draft.lineChart[key] = value
      })
    })

    dqsImpl.setCurPageSettings(dispatch, user, pageKey, next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  const handleScroll = (e) => {
    setScrollPosition(e.scrollOffset.top)
  }

  const items = (pageState, configState) => [
    NGSymbol(pageKey, pageState, configState),
    WarningSymbol(pageKey, pageState, configState),
    GoodSymbol(pageKey, pageState, configState),
    ChartLine(pageKey, pageState, configState),
    SpecificationLimit(pageKey, pageState, configState),
    WarningLimit(pageKey, pageState, configState),
    ThemeAverage(pageKey, pageState, configState),
    ThemeNominal(pageKey, pageState, configState),
    ThemePlausibilityLimit(pageKey, pageState, configState),
    ThemeScrapLimit(pageKey, pageState, configState),
    ThemeAcceptanceLimit(pageKey, pageState, configState),
    MarkArea(pageKey, pageState, configState),
    ArimaChartLine(pageKey, pageState, configState),
  ]

  return (
    <div ref={parentRef} style={{ padding: '5px', width: '100%', height: '100%' }}>
      <DQToast ref={toastRef} />
      <div style={{ width: '100%', height: '3%' }}>
        <DQSaveReset
          saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
          resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
          saveCallback={handleSaveButtonClicked}
          resetCallback={handleResetButtonClicked}
        />
      </div>
      <div style={{ width: '100%', height: '97%' }}>
        <ScrollView
          className="side-panel-tab-container"
          showScrollbar={'onHover'}
          useNative={false}
          onScroll={handleScroll}
          onContentReady={(e) => {
            e.component.scrollTo({ top: scrollPosition })
          }}
        >
          <Form id="side-panel-value-chart-theme-form" style={{ paddingTop: '5px' }} labelMode={'outside'} formData={curPage.page} showColonAfterLabel={false}>
            {items(curPage, configPage)}
          </Form>
        </ScrollView>
      </div>
    </div>
  )
}
