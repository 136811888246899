import TreeView from 'devextreme-react/tree-view'
import * as events from 'devextreme/events'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigation_dq } from 'src/app-navigation-dq'
import { useAuth } from 'src/contexts/auth'
import { useTheme } from 'src/contexts/theme'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { useNavigation } from '../../contexts/navigation'
import { useScreenSize } from '../../utils/media-query'
import '../../themes/generated/variables.additional.scss'
import './SideNavigationMenuDarkSwatchTheme.scss'
import './SideNavigationMenuLightSwatchTheme.scss'
// import './SideNavigationMenuLight.scss'
// import './SideNavigationMenuDark.scss'
import HeaderIcon from 'src/functions/HeaderIcon'
import { useLanguage } from 'src/contexts/languages'
import { LoadPanel } from 'devextreme-react'
import * as permi from 'src/pages/components/dq-permission/DQPermission'
import { groupTbDescription as groupTbDesc } from 'src/functions/constants/groupDBFieldDescription'
import { getAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import { useApi } from 'src/contexts/api'
import { makeAdminGroupToDTOFormat } from 'src/dto/DTOImpl'
import { defaultRibbon } from 'src/pages/admin/components/settings/AdminSettings'

const getViewPortTheme = (pageKey, theme) => {
    if (pageKey !== 'reportPrint') {
        return theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'
    }

    return 'light'
}

export default function SideNavigationMenu(props) {
    const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } = props
    const { spring } = useApi()
    const { user } = useAuth()
    const { getTheme } = useTheme()
    const { language } = useLanguage()
    const { isLarge } = useScreenSize()
    const [navItems, setNavItems] = useState([])
    const chartRawData = useSelector((state) => state.chartRawData)
    // const charInfo = chartRawData.charInfo
    const valueInfo = chartRawData.valueInfo
    const common = useSelector((state) => state.common)
    const theme = common.pages.configuration.theme
    const swatchTheme = common.pages.configuration.swatchTheme
    const userRole = user.user_role
    const [navList, setNavList] = useState([])

    useEffect(() => {
        const processNavList = async () => {
            const company = user?.user_company
            const plant = user?.user_plant
            const group = user?.user_group
            const userId = user?.user_id
            const ribbonData = common.pages.others.ribbon
            if (ribbonData) {
                let navList = null
                if (company === 'DQS' && plant === 'DQS' && group === 'DQS' && userId === 'dqs_admin') {
                    navList = defaultRibbon.dqs
                } else {
                    // navList = ribbonData[group] ? ribbonData[group] : defaultRibbon.default
                    navList = ribbonData ? ribbonData : defaultRibbon.default
                }

                setNavList(navList)
            } else {
                setNavList(defaultRibbon)
            }
        }

        processNavList()
    }, [])

    function normalizePath() {
        let targetNavigation

        const tempIconType = HeaderIcon.menuIconType().find((item) => item.Value === common.pages.configuration.menuIconType)

        let menuIconType
        if (tempIconType === undefined) {
            menuIconType = HeaderIcon.menuIconType()[0]
        } else {
            menuIconType = tempIconType
        }

        const isDataLoaded = valueInfo.length > 0 ? true : false

        targetNavigation = navigation_dq(navList, menuIconType.Path, getTheme(), swatchTheme, isDataLoaded)

        const menu = []
        targetNavigation.forEach((item) => {
            if (item.key === 'adminSettings' && !permi.checkAdminPermission(user.user_group)) {
                return
            }

            if (Array.isArray(item.items)) {
                const updatedItems = item.items.map((subItem) => ({
                    ...subItem,
                    text: dqTranslateMsg(subItem.text),
                }))

                menu.push({
                    ...item,
                    items: updatedItems,
                    text: dqTranslateMsg(item.text),
                    expanded: compactMode ? false : isLarge,
                    path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
                })
            } else {
                menu.push({
                    ...item,
                    text: dqTranslateMsg(item.text),
                    expanded: compactMode ? false : isLarge,
                    path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
                })
            }
        })

        return menu
    }

    const items = useMemo(normalizePath, [navList, theme, swatchTheme, valueInfo, language, common.pages.configuration.menuIconType, , common.pages.adminSettings.language])

    const {
        navigationData: { currentPath },
    } = useNavigation()

    const treeViewRef = useRef(null)
    const wrapperRef = useRef()
    const getWrapperRef = useCallback(
        (element) => {
            const prevElement = wrapperRef.current
            if (prevElement) {
                events.off(prevElement, 'dxclick')
            }

            wrapperRef.current = element
            events.on(element, 'dxclick', (e) => {
                openMenu(e)
            })
        },
        [openMenu]
    )

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance
        if (!treeView) {
            return
        }

        if (currentPath !== undefined) {
            treeView.selectItem(currentPath)
            treeView.expandItem(currentPath)
        }

        if (compactMode) {
            treeView.collapseAll()
        }
        // }, [currentPath, compactMode, common.pages.configuration.menuIconType, common.pages.others.ribbon, common.pages.configuration.language])
    }, [currentPath, compactMode])

    const swatchThemeClass = useMemo(() => {
        const swatchConfig = common.pages.configuration.swatchTheme
        let theme = ''
        if (swatchConfig === 'standard') {
            theme = 'dx-swatch-additional side-navigation-menu-dark'
        } else {
            theme =
                getViewPortTheme('', getTheme()) === 'dark'
                    ? 'dq-custom-dark-swatch-additional dx-theme-background-color side-navigation-menu-dark'
                    : 'dq-custom-light-swatch-additional dx-theme-background-color side-navigation-menu-light'
        }
        return theme
    }, [common.pages.configuration.swatchTheme, common.pages.configuration.theme]) // Add dependencies here

    // Dynamic import of styles based on the theme
    useMemo(() => {
        if (swatchThemeClass.includes('dark')) {
            import('./SideNavigationMenuDark.scss')
        } else {
            import('./SideNavigationMenuLight.scss')
        }
    }, [swatchThemeClass])

    return (
        // <div className={'dx-swatch-additional dx-theme-background-color side-navigation-menu-dark'} ref={getWrapperRef}>
        <div
            className={`${swatchThemeClass}`}
            ref={getWrapperRef}
            style={{ padding: 0 }}
        >
            {children}
            <div className={'menu-container'}>
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    // items={navItems}
                    keyExpr={'path'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    expandEvent={'click'}
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width={'100%'}
                    useNativeScrolling={false}
                />
            </div>
        </div>
    )
}
