export function convertByCatalog(type, value, catalogs) {
  const validCatalog = ['v0005', 'v0007', 'v0008', 'v0010', 'v0011', 'v0012']

  if (!validCatalog.includes(type)) {
    return value
  }

  let targetCatalogList = catalogs[type]
  let numericValue = type === 'v0005' ? parseInt(value) : value

  const target = targetCatalogList.find((item) => item.no === numericValue)

  return target ? target.desc : value
}
