import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { convertUserRoleToNumber } from 'src/functions/constants/authLevelConstants'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'

const displayMethod = (userRole) => [
  {
    value: 'barChart',
    text: dqTranslateMsg('SidePanel_' + 'BarChart'),
  },
  ...(userRole !== 5
    ? [
        {
          value: 'heatMap',
          text: dqTranslateMsg('SidePanel_' + 'HeatMap'),
        },
      ]
    : []),
]

export default function SettingsDisplayType(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  const { user } = useAuth()
  // const pageKey = 'correlationChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onSelectBoxDisplayTypeValueChanged = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`display-type-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DisplayType')}>
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Type'),
        labelLocation: 'top',
        name: 'displayMethod',
        items: displayMethod(convertUserRoleToNumber(user.user_role)),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.displayMethod,
        disabled: false,
        onItemClick: (e) => onSelectBoxDisplayTypeValueChanged(e, 'displayMethod'),
      })}
    </GroupItem>
  )
}
