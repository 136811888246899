import React, { createContext, useContext, useEffect, useState } from 'react'
import { loadMessages, locale } from 'devextreme/localization'
import enMessage from 'src/functions/lang/enMessage.json'
import jpMessage from 'src/functions/lang/jaMessage.json'
import krMessage from 'src/functions/lang/krMessage.json'
import { useDispatch, useSelector } from 'react-redux'
import { convertLanguageStructureForDevLocale } from 'src/pages/components/dq-convert/DQLanguage'
import produce from 'immer'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { useAuth } from 'src/contexts/auth'
import { LoadPanel } from 'devextreme-react'
import { adminSettingsPrm, defaultLanguage } from 'src/pages/admin/components/settings/AdminSettings'
import { loadUserFromLS } from 'src/pages/components/dq-settings/dq-local-storage/DQLocalStorageImpl'
import { getAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { makeAdminCompanyToDTOFormat } from 'src/dto/DTOImpl'
import { companyPlantTbDescription } from 'src/functions/constants/companyPlantDBfieldDescription'
import { useApi } from './api'

const LanguageContext = createContext()

export function useLanguage() {
    return useContext(LanguageContext)
}

export function LanguageProvider({ children }) {
    const { user, loading } = useAuth()

    const dispatch = useDispatch()
    const { spring } = useApi()
    // const rdxLanguageState = useSelector((state) => state.common.pages.adminSettings.language)
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en')
    const [langLoading, setLangLoading] = useState(true)

    const saveRecentLanguage = (lang) => {
        localStorage.setItem('language', lang)
    }

    const switchLanguage = (lang) => {
        setLangLoading(true)
        setLanguage(lang)
        saveRecentLanguage(lang)
        // updateLanguage(lang)
        locale(lang)
        setLangLoading(false)
    }

    const updateLanguage = (languageSetting) => {
        setLangLoading(true)
        const savedLanguage = convertLanguageStructureForDevLocale(languageSetting)

        const loadLang = {
            en: { ...savedLanguage.en, ...enMessage },
            ja: { ...savedLanguage.ja, ...jpMessage },
            kr: { ...savedLanguage.kr, ...krMessage },
        }

        loadMessages(loadLang)

        // const localeAbbr = languageSetting
        // locale(localeAbbr)
        setLangLoading(false)
    }

    useEffect(() => {
        const procLanguage = async () => {
            // Globalize.load(supplemental, deCldrData)
            const initLang = localStorage.getItem('language') || 'en'
            const initUser = loadUserFromLS()

            let languageData = null
            if (initUser) {
                const company = initUser.user_company
                const plant = initUser.user_plant
                const targetPlantInfo = await getAdminCompany(spring, makeGetAdminCompanyPrms(company, plant))
                const adminCompanyDTO = makeAdminCompanyToDTOFormat(targetPlantInfo)
                const languageDataStr = adminCompanyDTO[companyPlantTbDescription.language]

                if (languageDataStr === '' || languageDataStr === null) {
                    console.error('plant language data is empty')
                    languageData = defaultLanguage
                } else {
                    languageData = JSON.parse(languageDataStr)
                }
            } else {
                languageData = defaultLanguage
            }

            // const savedLanguage = convertLanguageStructureForDevLocale(rdxLanguageState)
            const savedLanguage = convertLanguageStructureForDevLocale(languageData)

            const loadLang = {
                en: { ...savedLanguage.en, ...enMessage },
                ja: { ...savedLanguage.ja, ...jpMessage },
                kr: { ...savedLanguage.kr, ...krMessage },
            }

            loadMessages(loadLang)

            const localeAbbr = initLang
            locale(localeAbbr)
            setLangLoading(false)
        }

        procLanguage()
    }, [])

    if (!(loading === false && langLoading === false)) {
        return <LoadPanel />
    }

    return <LanguageContext.Provider value={{ language, switchLanguage, updateLanguage }}>{children}</LanguageContext.Provider>
}
