import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
import ApiStatus from '../defines/ApiStatus'

// Delete Admin Company
export const deleteAdminCompany = async (apiClient, data) => {
    try {
        const response = await apiClient.post('admin/deleteAdminCompany', data) // Adjust the endpoint as needed
        if (!ApiStatus.isSuccess(response.status)) {
            throw new Error(getErrCommMsg(response.status))
        }
        return response.data
    } catch (error) {
        console.error(error.message)
        throw error
    }
}

// Get a specific Admin Company
export const getAdminCompany = async (apiClient, data) => {
    try {
        const response = await apiClient.post('admin/getAdminCompany', data) // Adjust the endpoint as needed
        if (!ApiStatus.isSuccess(response.status)) {
            throw new Error(getErrCommMsg(response.status))
        }
        return response.data
    } catch (error) {
        console.error(error.message)
        throw error
    }
}

// Get the list of Admin Companies
export const getAdminCompanyList = async (apiClient, data) => {
    try {
        const response = await apiClient.post('admin/getAdminCompanyList', data) // Adjust the endpoint as needed
        if (!ApiStatus.isSuccess(response.status)) {
            throw new Error(getErrCommMsg(response.status))
        }
        return response.data
    } catch (error) {
        console.error(error.message)
        throw error
    }
}

// Get the list of Admin Company Plants
export const getAdminCompanyPlantList = async (apiClient, data) => {
    try {
        const response = await apiClient.post('admin/getAdminCompanyPlantList', data) // Adjust the endpoint as needed
        if (!ApiStatus.isSuccess(response.status)) {
            throw new Error(getErrCommMsg(response.status))
        }
        return response.data
    } catch (error) {
        console.error(error.message)
        throw error
    }
}

// Update Admin Company
export const updateAdminCompany = async (apiClient, data) => {
    try {
        const response = await apiClient.post('admin/updateAdminCompany', data) // Adjust the endpoint as needed
        if (!ApiStatus.isSuccess(response.status)) {
            throw new Error(getErrCommMsg(response.status))
        }
        return response.data
    } catch (error) {
        console.error(error.message)
        throw error
    }
}
