function convertToCustomFormat(devExpressDate) {
  const year = devExpressDate.getFullYear()
  const month = (devExpressDate.getMonth() + 1).toString().padStart(2, '0')
  const day = devExpressDate.getDate().toString().padStart(2, '0')
  const hours = devExpressDate.getHours().toString().padStart(2, '0')
  const minutes = devExpressDate.getMinutes().toString().padStart(2, '0')
  const seconds = devExpressDate.getSeconds().toString().padStart(2, '0')

  return `${year}${month}${day}${hours}${minutes}${seconds}`
}

const defaultStartTime = () => {
  const currentDate = new Date()
  currentDate.setHours(currentDate.getHours() - 12)
  return convertToCustomFormat(currentDate)
}

const defaultEndTime = () => {
  return convertToCustomFormat(new Date())
}

export const databaseSettingsPrm = {
  page: {
    partSortType: 'string',
    charSortType: 'string',
    usePartFieldList: [],
    useCharFieldList: [],
    useValueFieldList: [],
    filterMode: 'quick', // advanced
    extraPartFieldList: ['p1001', 'p1002'],
    extraCharFieldList: ['c2001', 'c2002'],
  },
  filters: {
    quick: {
      common: {
        easyLoadValue: 1,
        dateTimeSort: 'Ascending',
        startDateTime: defaultStartTime(),
        endDateTime: defaultEndTime(),
      },
      others: {
        arrangement: 'None',
        dateTimeEasySetting: 'last1m',
        useEasyLoad: false,
        easyLoadValueType: 'count',
        ignoreDuplicatedValue: false,
        useDateTime: false,
        pageCalculationInAdvance: false,
      },
      data: {
        part: {},
        char: {},
        value: {},
      },
      selectedFilterNo: 0,
      isVisibleFilterPopup: false,
      popupDisplayFilter: null,
      savedFilters: [],
    },
    advanced: {
      common: {
        easyLoadValue: 1,
        dateTimeSort: 'Ascending',
        startDateTime: defaultStartTime(),
        endDateTime: defaultEndTime(),
      },
      others: {
        arrangement: 'None',
        dateTimeEasySetting: 'last1m',
        useEasyLoad: false,
        easyLoadValueType: 'count',
        ignoreDuplicatedValue: false,
        useDateTime: false,
        pageCalculationInAdvance: false,
      },
      data: null,
      selectedFilterNo: 0,
      isVisibleFilterPopup: false,
      popupDisplayFilter: null,
      savedFilters: [],
    },
  },
}
