import * as nt from './app-navigation-dq-table'

export const navigation_dq = (navList, mode, theme, swatchTheme, isDataLoaded) => {
  try {
    const navTable = nt.navMenu(navList, mode, theme, swatchTheme, isDataLoaded)
    return navTable
  } catch (error) {
    console.error(error.message)
  }
}
