// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuration-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.configuration-container .configuration-control-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 4%;
}
.configuration-container .configuration-control-container .button-container {
  width: 10%;
  height: 100%;
}
.configuration-container .configuration-content-container {
  width: 100%;
  height: 96%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.configuration-container .configuration-datagrid-container {
  width: 33.3333333333%;
  height: 100%;
  padding: 5px;
}
.configuration-container .configuration-datagrid-container .configuration-title-container {
  width: 100%;
  height: 3%;
  font-family: "Arial";
  font-size: 15px;
}
.configuration-container .configuration-datagrid-container .configuration-datagrid-content {
  width: 100%;
  height: 97%;
}`, "",{"version":3,"sources":["webpack://./src/pages/config/components/main-view/configuration/Configuration.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACI;EACI,aAAA;EACA,yBAAA;EACA,WAAA;EACA,UAAA;AACR;AACQ;EACI,UAAA;EACA,YAAA;AACZ;AAGI;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AADR;AAII;EACI,qBAAA;EACA,YAAA;EACA,YAAA;AAFR;AAIQ;EACI,WAAA;EACA,UAAA;EACA,oBAAA;EACA,eAAA;AAFZ;AAKQ;EACI,WAAA;EACA,WAAA;AAHZ","sourcesContent":[".configuration-container {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    padding: 5px;\r\n\r\n    .configuration-control-container {\r\n        display: flex;\r\n        justify-content: flex-end;\r\n        width: 100%;\r\n        height: 4%;\r\n\r\n        .button-container {\r\n            width: 10%;\r\n            height: 100%;\r\n        }\r\n    }\r\n\r\n    .configuration-content-container {\r\n        width: 100%;\r\n        height: 96%;\r\n        padding: 5px;\r\n        display: flex;\r\n        flex-direction: row;\r\n        padding: 5px;\r\n    }\r\n\r\n    .configuration-datagrid-container {\r\n        width: calc(100% / 3);\r\n        height: 100%;\r\n        padding: 5px;\r\n\r\n        .configuration-title-container {\r\n            width: 100%;\r\n            height: 3%;\r\n            font-family: 'Arial';\r\n            font-size: 15px;\r\n        }\r\n\r\n        .configuration-datagrid-content {\r\n            width: 100%;\r\n            height: 97%;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
