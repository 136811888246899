import React from 'react'
import UploadFileForm from './main-view/UploadFileForm'
import './upload-file.scss'

function UploadFile(pageKey) {
  return (
    <React.Fragment>
      <div className="upload-file-container">
        <div className="upload-file-form-container">
          <UploadFileForm pageKey={pageKey} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default UploadFile
