import React, { useEffect, useState } from 'react'
import { EmptyItem, GroupItem } from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { batch, useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'

const LastNFilterOption = ({ currentFilterMode }) => {
  const dispatch = useDispatch()
  const pageKey = 'database'
  const pageState = useSelector((state) => state.common.pages.database)

  const [isNFilter, setIsNFilter] = useState(pageState.filters[currentFilterMode].others.useEasyLoad)
  const [nFilterCount, setNFilterCount] = useState(pageState.filters[currentFilterMode].common.easyLoadValue)
  const [nFilterType, setNFilterType] = useState(pageState.filters[currentFilterMode].others.easyLoadValueType)
  const [isSaved, setIsSaved] = useState(true)

  useEffect(() => {
    setIsNFilter(pageState.filters[currentFilterMode].others.useEasyLoad)
  }, [pageState.filters[currentFilterMode].others.useEasyLoad])

  useEffect(() => {
    if (nFilterCount !== pageState.filters[currentFilterMode].common.easyLoadValue || nFilterType !== pageState.filters[currentFilterMode].others.easyLoadValueType) {
      setIsSaved(false)
    } else {
      setIsSaved(true)
    }
  }, [nFilterCount, nFilterType, pageState.filters[currentFilterMode].common.easyLoadValue, pageState.filters[currentFilterMode].others.easyLoadValueType])

  const handleNumberBoxNFilterCountChange = (e) => {
    // export const easyLoadTypeDefine = () => [
    //   { value: 'count', text: dqTranslateMsg('Page_Database_' + 'Count') },
    //   { value: 'hour', text: dqTranslateMsg('Page_Database_' + 'Hour') },
    //   { value: 'day', text: dqTranslateMsg('Page_Database_' + 'Day') },
    //   { value: 'week', text: dqTranslateMsg('Page_Database_' + 'Week') },
    // ]

    let count = 0
    if (nFilterType === 'week' && e.value > 10000) {
      count = 10000
    } else if (nFilterType === 'day' && e.value > 10000 * 7) {
      count = 10000 * 7
    } else if (nFilterType === 'hour' && e.value > 10000 * 7 * 24) {
      count = 10000 * 7 * 24
    } else {
      count = e.value
    }
    const minOption = e.component.option('min')
    setNFilterCount(count === null ? minOption : count)
  }

  const handleSelectBoxNFilterTypeChange = (e) => {
    const type = e.itemData.value
    let count = 0
    if (type === 'week' && nFilterCount > 10000) {
      count = 10000
    } else if (type === 'day' && nFilterCount > 10000 * 7) {
      count = 10000 * 7
    } else if (type === 'hour' && nFilterCount > 10000 * 7 * 24) {
      count = 10000 * 7 * 24
    } else {
      count = nFilterCount
    }

    batch(() => {
      setNFilterCount(count)
      setNFilterType(type)
    })
  }

  const handleCheckBoxEnableLastNFilterChange = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value

    batch(() => {
      const next = produce(pageState, (draft) => {
        draft.filters[currentFilterMode].others[dataField] = value
        if (value) {
          draft.filters[currentFilterMode].others.useDateTime = false
        }
      })
      setIsNFilter(value)
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    })
  }

  const handleApplyButtonClick = () => {
    if (!nFilterCount) {
      alert('Count field is empty')
      return
    }

    const nextState = produce(pageState, (draft) => {
      draft.filters[currentFilterMode].others.useEasyLoad = isNFilter
      draft.filters[currentFilterMode].common.easyLoadValue = nFilterCount
      draft.filters[currentFilterMode].others.easyLoadValueType = nFilterType
    })

    setNFilterCount(nFilterCount)
    setNFilterType(nFilterType)
    dispatch(ps[pageKey].setPageSettingsToRedux(nextState))
  }

  return (
    <GroupItem key={`last-n-filter-option-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'LastNFilter')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Enable'),
        labelLocation: 'right',
        name: 'useEasyLoad',
        value: isNFilter,
        disable: false,
        onValueChanged: (e) => handleCheckBoxEnableLastNFilterChange(e, 'useEasyLoad'),
      })}
      <EmptyItem />
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Count'),
        labelLocation: 'top',
        name: 'easyLoadValue',
        min: 1,
        max: 100000000,
        step: 1,
        value: nFilterCount,
        disabled: isNFilter === false,
        onValueChanged: (e) => handleNumberBoxNFilterCountChange(e),
      })}
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Type'),
        labelLocation: 'top',
        name: 'easyLoadValueType',
        items: sd.easyLoadTypeDefine(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: nFilterType,
        disabled: isNFilter === false,
        onItemClick: (e) => handleSelectBoxNFilterTypeChange(e),
      })}
      {ButtonSimpleItem({
        colSpan: 2,
        type: isSaved ? 'normal' : 'danger',
        text: dqTranslateMsg('SidePanel_' + 'Apply'),
        icon: 'parentfolder',
        disabled: isNFilter === false,
        onClick: handleApplyButtonClick,
      })}
    </GroupItem>
  )
}

export default LastNFilterOption
