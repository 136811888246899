import ApiStatus from 'src/api/defines/ApiStatus'
import { getSummary } from 'src/api/summary-list'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export function getMaxInArray(arr) {
    let len = arr.length
    let max = -Infinity

    while (len--) {
        max = arr[len] > max ? arr[len] : max
    }
    return max
}

export function getMinInArray(arr) {
    let len = arr.length
    let min = Infinity // 초기 최솟값을 양의 무한대로 설정

    while (len--) {
        min = arr[len] < min ? arr[len] : min
    }
    return min
}

export const calculateAverage = (data) => {
    let sum = 0
    for (const value of data) {
        sum += value
    }
    return sum / data.length
}
// Calculate the median
export const calculateMedian = (data) => {
    // const sortedData = data.slice().sort((a, b) => a - b)
    const mid = Math.floor(data.length / 2)

    if (data.length % 2 === 0) {
        return (data[mid - 1] + data[mid]) / 2
    } else {
        return data[mid]
    }
}

// standard deviation
const calculateStandardDeviation = (data, average) => {
    // const data = valueObj.map((item) => item.v0001)
    // const average = calculateAverage(data)
    const squaredDifferences = data.map((value) => Math.pow(value - average, 2))
    // const sumSquaredDifferences = squaredDifferences.reduce(
    //   (acc, value) => acc + value,
    //   0
    // )
    let sumSquaredDifferences = 0
    for (const value of squaredDifferences) {
        sumSquaredDifferences += value
    }
    const variance = sumSquaredDifferences / data.length
    const standardDeviation = Math.sqrt(variance)

    return standardDeviation
}

// Make Each Cell Data
export function makeCharDescription(char) {
    return `${char.c2001}/${char.c2002}`
}

export function makeCp(charObj, stdDev) {
    const cp = (charObj.c2111 - charObj.c2110) / (6 * stdDev)

    return cp
}

export function makeCpk(charObj, stdDev, average) {
    // const average = calculateAverage(data)
    const cpu = (charObj.c2111 - average) / (3 * stdDev)
    const cpl = (average - charObj.c2110) / (3 * stdDev)

    // CPK 는 cpu 와 cpl 중 작은 값을 의미
    const cpk = Math.min(cpu, cpl)

    return cpk
}

export function makeResult(cp, cpk, settings) {
    const CP_THRESHOLD = settings.page.cpGood
    const CPK_THRESHOLD = settings.page.cpkGood

    // 1. cp 가 있고 cpk 가 둘다 있으면 둘다 스레스홀드를 넘어야 thumbsup (ok)
    // 2. cp 가 없고 cpk 가 있으면 cpk만 스레스홀드를 넘어도 thumbsup (ok)
    // 3. 둘다 없으면 Not Evaluated

    if (cp && cpk) {
        if (cp > CP_THRESHOLD && cpk > CPK_THRESHOLD) {
            return 1 // OK
        }
        return 0 // NG
    } else if (!cp && cpk) {
        if (cpk > CPK_THRESHOLD) {
            return 1 // OK
        }
        return 0 // NG
    } else {
        return 2 // Not Evaluated
    }
}

export function makeOutOfTolerance(valueObj, lsl, usl) {
    const checkOOTField = (valueObj) => {
        let ngCnt = 0
        valueObj.forEach((valueData) => {
            if (valueData.out_of_tol === null) {
                return false
            }

            if (valueData.out_of_tol !== 0) {
                ngCnt++
            }
        })

        return ngCnt > 0 ? 0 : 1
    }

    const checkLslUsl = (valueObj, lsl, usl) => {
        let ngCnt = 0
        valueObj.forEach((valueData) => {
            if (lsl === null && usl === null) {
                return 1 // lsl usl 이 없으면 정상
            } else if (lsl !== null && valueData.v0001 < lsl) {
                ngCnt++
            } else if (usl !== null && valueData.v0001 > usl) {
                ngCnt++
            } else {
                if (valueData.v0001 < lsl || valueData.v0001 > usl) {
                    ngCnt++
                }
            }
        })

        return ngCnt > 0 ? 0 : 1
    }

    let result
    // result = checkOOTField(valueObj)
    // if (result === false ) {
    result = checkLslUsl(valueObj, lsl, usl)
    // }

    return result
}

export function makeTrend(value) {
    return value
}

export function makeSummaryTableData(settings, configSettings, targetParts, targetChars, valueArray) {
    const tableRow = (part, char, values, index, info) => {
        const lsl = char.c2110
        const usl = char.c2111
        const valueArray = values.valueRaw.filter((data) => data.v0002 === 0).map((item) => item.v0001)
        const min = info.minimum //getMinInArray(valueArray)
        const max = info.maximum //getMaxInArray(valueArray)
        const partName = part.p1002
        const charDesc = makeCharDescription(char)
        const median = calculateMedian(valueArray)
        const average = info.mean //calculateAverage(valueArray)
        const stdDev = info.stdev //calculateStandardDeviation(valueArray, average)
        const cp = info.cp //makeCp(char, stdDev)
        const cpk = info.cpk //makeCpk(char, stdDev, average)
        const result = makeResult(cp, cpk, settings)
        const outOfTol = makeOutOfTolerance(
            values.valueRaw.filter((data) => data.v0002 === 0),
            lsl,
            usl
        )

        // extra data
        const decimalPlaces = char.c2022 ?? configSettings.commonSettings.displayDecimalPlaces

        const convertedValue = values.valueRaw
            .filter((data) => data.v0002 === 0)
            .map((value) => ({
                value_id: value.value_id,
                v0001: value.v0001,
            }))
        const trendData = makeTrend(convertedValue)

        return {
            no: index + 1,
            part_name: partName,
            desc: charDesc,
            average: average,
            median: median,
            stdDev: stdDev,
            min: min,
            max: max,
            cp: cp,
            cpk: cpk,
            result: result,
            out_of_tol: outOfTol,
            trend: trendData,
            // extra data
            decimalPlaces: decimalPlaces,
        }
    }

    if (targetChars.length == 0 || valueArray.length == 0) {
        return false
    }

    // let tableDataArray = []

    // const tableDataArray = valueArray.map((valueObj, index) => {
    //   const targetChar = targetChars.find((charObj) => {
    //     if (valueObj.part_id == charObj.part_id && valueObj.char_id == charObj.char_id) {
    //       return charObj
    //     }
    //   })

    //   const targetPart = targetParts.find((partObj) => {
    //     if (valueObj.part_id == partObj.part_id) {
    //       return partObj
    //     }
    //   })

    //   const info = getSummary(targetChar, valueObj.valueRaw, settings, configSettings)
    //   // tableDataArray.push(tableRow(targetPart, targetChar, valueObj, index, info))
    //   return tableRow(targetPart, targetChar, valueObj, index, info)
    // })

    const processSummaryData = async (valueArray, targetChars, settings, configSettings) => {
        let tableDataArray = []

        try {
            await Promise.all(
                valueArray.map(async (valueObj, index) => {
                    const targetChar = targetChars.find((charObj) => valueObj.part_id === charObj.part_id && valueObj.char_id === charObj.char_id)
                    const targetPart = targetParts.find((partObj) => valueObj.part_id === partObj.part_id)
                    const result = await getSummary(targetChar, valueObj.valueRaw, settings, configSettings)
                    if (!ApiStatus.isSuccess(result.status)) {
                        alert(getErrCommMsg(result.status))
                    }
                    tableDataArray.push(tableRow(targetPart, targetChar, valueObj, index, result.data))
                })
            )
        } catch (error) {
            console.error('Error processing summary data:', error)
        }

        return tableDataArray
    }

    processSummaryData(valueArray, targetChars, settings, configSettings)
        .then((result) => {
            console.log('Table data array:', result)
            return result
        })
        .catch((error) => {
            console.error('Error:', error)
            // Handle errors as needed
        })
}
