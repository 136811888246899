export function makeMonitoringDataSource(
  curPage,
  configPage,
  parts,
  chars,
  valuesArray,
  startDateTime,
  endDateTime
) {
  // try {
  let updateDataSource = []
  let dataSourceRawData = valuesArray
  let idx = 0

  const from = parseInt(startDateTime, 10)
  const currentTime = parseInt(endDateTime, 10)
  const startDate = from //new Date(from)
  const endDate = currentTime //new Date(currentTime)

  const splicedArray = []

  // 주어진 배열 순회
  for (const row of valuesArray) {
    const filteredRow = row.valueRaw
      .filter((data) => data.v0002 === 0)
      .filter((item) => {
        const itemDateStr = item.v0004
        const itemDate = parseInt(itemDateStr, 10) // new Date(itemDateStr)
        return itemDate >= startDate && itemDate <= endDate
      })
    splicedArray.push(filteredRow)
  }

  // 1. 각 어래이에 시간추출
  const timeOfEveryArray = splicedArray.map((values) =>
    values.map((value) => value.v0004)
  )
  // 2. 각 어레이에 시간들을 합치고 중복제거 & 내림차순
  const uniqueTime = [...new Set([].concat(...timeOfEveryArray))]
  const sortedData = uniqueTime
    .map((item) => parseInt(item, 10))
    .sort((a, b) => a - b)
    .map(String)
  // .map((item) => item.original)

  const arrangedValueByTime = splicedArray.map((values) => {
    if (values.length > 0) {
      let subValueByTime = {
        part_id: values[0].part_id,
        char_id: values[0].char_id,
        values: [],
      }
      // const { currentTime, strCurrentTime } = getCurrentDate() // str "19920507142102"
      // const from = getStartDate('test', currentTime) //"19920507134308"

      sortedData.forEach((data) => {
        const found = values.find((value, index) => value.v0004 === data)

        subValueByTime.values.push(found)
      })
      return subValueByTime
    }
  })

  valuesArray.forEach((valueData) => {
    // const targetPart = parts.find((part) => part.part_id === char.part_id)
    // const targetValues = valuesArray.find((values) => values.part_id === char.part_id && values.char_id === char.char_id)
    // const dataValues = arrangedValueByTime
    //   .filter((arrangedValue) => arrangedValue !== undefined)
    //   .find((arrangedValue) => arrangedValue.part_id === char.part_id && arrangedValue.char_id === char.char_id)

    let totalCnt = 0
    let goodCnt = 0
    let warnCnt = 0
    let ngCnt = 0

    const lsl = valueData.charRaw.c2110
    const usl = valueData.charRaw.c2111
    // const nominal = (lsl + usl) / 2
    const nominal = valueData.charRaw.c2101 ?? (usl + lsl) / 2
    const gap = usl - nominal
    const warnOffset =
      (gap * configPage.commonSettings.warningLimitPercentage) / 100
    const lwl = lsl + warnOffset
    const uwl = usl - warnOffset

    if (valueData !== undefined) {
      const numToKeep =
        valueData.valueRaw.length > curPage.page.maxDisplayCount
          ? curPage.page.maxDisplayCount
          : valueData.valueRaw.length
      valueData.valueRaw = valueData.valueRaw.slice(-numToKeep)

      // 에러카운트는 데이터하고 구분되어진다.
      // const filteredTargetValue = valueData.valueRaw.filter((data) => data.v0002 === 0)
      valueData.valueRaw.forEach((value) => {
        totalCnt++
        if (lsl === null && usl === null) {
          goodCnt++
        } else if (lsl !== null && usl !== null) {
          if (lsl > value.v0001 || usl < value.v0001) {
            ngCnt++
          } else if (
            (value.v0001 < usl && value.v0001 > uwl) ||
            (value.v0001 > lsl && value.v0001 < lwl)
          ) {
            warnCnt++
          } else {
            goodCnt++
          }
        } else if (lsl !== null && usl === null) {
          if (lsl > value.v0001) {
            ngCnt++
          } else if (value.v0001 >= lsl && value.v0001 < lwl) {
            warnCnt++
          } else {
            goodCnt++
          }
        } else {
          // if (lsl === null && usl !== null) {
          if (usl < value.v0001) {
            ngCnt++
          } else if (value.v0001 <= usl && value.v0001 > uwl) {
            warnCnt++
          } else {
            goodCnt++
          }
        }
        // }
      })

      const value_v0004 =
        valueData.valueRaw[valueData.valueRaw.length - 1].v0004
      const value_v0014 =
        valueData.valueRaw[valueData.valueRaw.length - 1].v0014
      const value_v0053 =
        valueData.valueRaw[valueData.valueRaw.length - 1].v0053

      updateDataSource.push({
        ID: idx,
        part_id: valueData.part_id,
        char_id: valueData.char_id,
        lsl: lsl,
        usl: usl,
        lwl: lwl,
        uwl: uwl,
        part: valueData.partRaw.p1002,
        characteristics: valueData.charRaw.c2002,
        data:
          valueData !== undefined
            ? valueData.valueRaw.map((value, index) => {
                if (value === undefined) {
                  return [index, null]
                } else {
                  return [index, value.v0001]
                }
              })
            : [],

        referenceData:
          valueData !== undefined
            ? valueData.valueRaw.map((value, index) => {
                let color = ''
                if (value !== undefined) {
                  if (value.local_status === 1 || value.local_status === 2) {
                    if (value.v0001 < lsl || value.v0001 > usl) {
                      color = 'red'
                    } else if (value.v0001 < lwl || value.v0001 > uwl) {
                      color = 'yellow'
                    } else {
                      color = 'green'
                    }
                  } else {
                    color = 'blue'
                  }
                  return {
                    index: index,
                    part_id: value.part_id,
                    char_id: value.char_id,
                    value_id: value.value_id,
                    local_status: value.local_status,
                    dateTime: value.v0004,
                    value: value.v0001,
                    barColor: color,
                  }
                }
              })
            : [],
        v0004: value_v0004,
        v0014: value_v0014,
        v0053: value_v0053,
        totalCnt: totalCnt,
        goodCnt: goodCnt,
        warnCnt: warnCnt,
        ngCnt: ngCnt,
      })
    } else {
      console.log('targetValues is undefined')
    }
    idx++
  })

  return { updateDataSource, dataSourceRawData }
  // } catch (error) {
  //   console.log(error)
  // }
}
