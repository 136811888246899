import { EmptyItem, GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'

const calculationType = () => [
  { value: 'fixed', text: dqTranslateMsg('SidePanel_' + 'Fixed') },
  { value: 'moving', text: dqTranslateMsg('SidePanel_' + 'Moving') },
]

const SettingsQccChartOption = (pageKey, pageState, configState) => {
  const dispatch = useDispatch()

  const onCheckBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxItemClicked = (e, dataField) => {
    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  // const onRadioGroupValueChanged = (e, dataField) => {
  //   if (e.event === undefined) return

  //   const value = e.value
  //   const next = produce(pageState, (draft) => {
  //     draft.page[dataField] = value
  //   })

  //   dispatch(ps[pageKey].setPageSettingsToRedux(next))
  // }

  return (
    <GroupItem key={`settings-qcc-chart-option-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'QCCChartOption')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'TakeoverFromDataset'),
        labelLocation: 'right',
        name: 'takeoverFromDataset',
        value: pageState.page.takeoverFromDataset,
        disabled: false,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'takeoverFromDataset'),
      })}
      <EmptyItem />
      <EmptyItem />
      {/* {NumberBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'DefaultSubgroupCount'),
        labelLocation: 'top',
        name: 'subGroupCount',
        min: 1,
        max: 20,
        step: 1,
        disabled: !pageState.page.takeoverFromDataset,
        value: pageState.page.subGroupCount,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'subGroupCount'),
      })}
      {SelectBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'DefaultSubgroupType'),
        labelLocation: 'top',
        name: 'subGroupType',
        items: calculationType(),
        valueExpr: 'value',
        displayExpr: 'text',
        disabled: !pageState.page.takeoverFromDataset,
        value: calculationType().find((item) => item.value === pageState.page.subGroupType)?.value,
        onItemClick: (e) => onSelectBoxItemClicked(e, 'subGroupType'),
      })} */}
    </GroupItem>
  )
}

export default SettingsQccChartOption
