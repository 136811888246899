import React from 'react'
import { GroupItem } from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

const VisibleSettings = (pageKey, pageState, configState) => {
    const dispatch = useDispatch()
    // const pageKey = 'scrollBoard'
    const sbPageSettings = pageState

    const onCheckBoxValueChanged = (e, dataField) => {
        if (e.event === undefined) return

        const value = e.value
        const next = produce(sbPageSettings, (draft) => {
            draft.page[dataField] = value ? 1 : 0
        })

        if (!next.page.chartGoodVisible && !next.page.chartWarningVisible && !next.page.chartBadVisible) {
            alert('Cannot disable all state')
            return
        }

        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <GroupItem
            key={`visible-settings-group-item`}
            colCount={3}
            caption={dqTranslateMsg('SidePanel_' + 'CharacteristicsVisible')}
        >
            {CheckBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'Good'),
                labelLocation: 'right',
                name: 'chartGoodVisible',
                value: sbPageSettings.page.chartGoodVisible === 1,
                onValueChanged: (e) => onCheckBoxValueChanged(e, 'chartGoodVisible'),
            })}
            {CheckBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'Warn'),
                labelLocation: 'right',
                name: 'chartWarningVisible',
                value: sbPageSettings.page.chartWarningVisible === 1,
                onValueChanged: (e) => onCheckBoxValueChanged(e, 'chartWarningVisible'),
            })}
            {CheckBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'Bad'),
                labelLocation: 'right',
                name: 'chartBadVisible',
                value: sbPageSettings.page.chartBadVisible === 1,
                onValueChanged: (e) => onCheckBoxValueChanged(e, 'chartBadVisible'),
            })}
        </GroupItem>
    )
}

export default VisibleSettings
