import React from 'react'
import { Label, SimpleItem } from 'devextreme-react/form'
import { List, Sortable } from 'devextreme-react'

const ListSimpleItem = ({
  colSpan,
  label,
  labelLocation,
  dataSource,
  itemRender,
  allowItemDeleting,
  itemDeleteMode,
  showSelectionControls = false,
  selectionMode = 'none',
  noDataText,
  onItemClick = null,
  onItemDeleted = null,
  onItemDeleting = null,
  onItemRendered = null,
  onSelectionChanged = null,
  onReorder,
  selectedItemKeys = [],
  selectByClick = false,
  onOptionChanged = null,
  keyExpr = undefined,
  displayExpr = undefined,
  backgroundCondition,
}) => (
  <SimpleItem colSpan={colSpan}>
    <Label text={label} location={labelLocation} />
    <Sortable filter=".dx-list-item" onReorder={onReorder}>
      <List
        style={{
          border: backgroundCondition ? 'none' : '2px dashed orange', // Apply border style based on error condition
          width: '100%', // Ensure the list takes the full width of its container
        }}
        dataSource={dataSource}
        itemRender={itemRender}
        allowItemDeleting={allowItemDeleting}
        itemDeleteMode={itemDeleteMode}
        selectionMode={selectionMode}
        noDataText={noDataText}
        onItemClick={onItemClick}
        onItemDeleted={onItemDeleted}
        onItemDeleting={onItemDeleting}
        onItemRendered={onItemRendered}
        onSelectionChanged={onSelectionChanged}
        showSelectionControls={showSelectionControls}
        selectedItemKeys={selectedItemKeys}
        selectByClick={selectByClick}
        keyExpr={keyExpr}
        displayExpr={displayExpr}
        onOptionChanged={onOptionChanged}
      />
    </Sortable>
  </SimpleItem>
)

export default ListSimpleItem
