import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

export default function SettingsXAxis(pageKey, pageState, configState) {
  const dispatch = useDispatch()

  const onCheckBoxDenseLabelEnableValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`settings-x-axis-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'XAxisSettings')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'DenseLabel'),
        labelLocation: 'right',
        name: 'xAxisDenseLabel',
        value: pageState.page.xAxisDenseLabel,
        disabled: false,
        onValueChanged: (e) => onCheckBoxDenseLabelEnableValueChanged(e, 'xAxisDenseLabel'),
      })}
    </GroupItem>
  )
}
