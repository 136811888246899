import styled from 'styled-components'
import { Button, RadioGroup } from 'devextreme-react'
import { useState } from 'react'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export default function ModeSelector(props) {
  const { className, value, items, setValue, handleModeChanged, disabled } = props

  const onValueChanged = (e) => {
    const changedMode = e.value
    handleModeChanged(changedMode)
  }

  return (
    <div className={className}>
      <RadioGroup value={value} items={items} onValueChanged={onValueChanged} valueExpr={'value'} displayExpr={'text'} disabled={disabled} />
    </div>
  )
}
