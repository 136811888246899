import * as mdcImpl from './MonitoringDeviationChartOptionImpl'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'

export const getMonitoringDeviationChartOption = (
  lslPrm,
  uslPrm,
  nominalPrm,
  valuePrm
) => {
  let lsl = ETCUtils.isValidData(lslPrm) ? lslPrm : null
  let usl = ETCUtils.isValidData(uslPrm) ? uslPrm : null
  const nominal = ETCUtils.isValidData(nominalPrm) ? nominalPrm : null
  const value = ETCUtils.isValidData(valuePrm) ? valuePrm : null
  const gap = PCUtils.getUslLslGap(usl, lsl)

  const xAxisTop = Math.max(usl === null ? lsl + 2 * gap : usl, nominal)
  const xAxisBottom = Math.min(lsl === null ? usl - 2 * gap : lsl, nominal)
  const yAxisTop = usl === null ? lsl + 2 * gap : usl
  const yAxisBottom = lsl === null ? usl - 2 * gap : lsl

  return {
    grid: {
      top: '0%',
      bottom: '0%',
      left: '0%',
      right: '0%',
    },
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    xAxis: {
      show: false,
      type: 'value',
      position: 'bottom',
      min: yAxisBottom,
      max: yAxisTop,
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
      },
    },
    yAxis: {
      type: 'value',
      min: yAxisBottom,
      max: yAxisTop,
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      data: ['ten'],
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
      },
    },

    series: [
      {
        name: 'Cost',
        type: 'bar',
        stack: 'Total',
        label: {
          show: true,
          position: 'center',
          formatter: '{b}',
        },
        markLine: {
          symbol: 'none',
          animation: false,
          precision: 10,
          emphasis: {
            disabled: true,
          },
          data: [
            ...(lsl !== null
              ? [
                  {
                    xAxis: lsl,
                    lineStyle: {
                      width: 2,
                      type: 'solid',
                      color: 'red',
                    },
                    label: { fontFamily: 'Arial' },
                  },
                ]
              : null),
            ...(usl !== null
              ? [
                  {
                    xAxis: usl,
                    lineStyle: {
                      width: 2,
                      type: 'solid',
                      color: 'red',
                    },
                    label: { fontFamily: 'Arial' },
                  },
                ]
              : null),
            {
              xAxis: nominal,
              lineStyle: {
                width: 1,
                type: 'solid',
                color: 'gray',
              },
              label: { fontFamily: 'Arial' },
            },
          ],
        },
        markArea: {
          label: { show: false, position: 'center' },
          data: [
            [
              { name: value, xAxis: nominal, yAxis: lsl + (usl - lsl) / 4 }, //(usl - lsl) / 4 } 은 1/4크기만큼안씀
              {
                xAxis: mdcImpl.valueConvertForDisplay(lsl, usl, value), //value,
                // yAxis: usl - (usl - lsl) / 4, //(usl - lsl) / 4 } 은 1/4크기만큼안씀
                yAxis: usl - (usl - lsl) / 4, //(usl - lsl) / 4 } 은 1/4크기만큼안씀
                itemStyle: {
                  color: mdcImpl.valueRectColor(lsl, usl, value),
                  opacity: '1',
                },
              },
            ],
          ],
        },
      },
    ],
  }
}
