import PCUtils from 'src/functions/ProcessCapabilityUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

export const makeValueSummaryRows = (data) => {
  if (data.length > 0) {
    return data
  } else {
    console.warn('Row input data is not exist')

    return []
  }
}

function makeCharDescription(charObj) {
  return `${charObj.c2001}/${charObj.c2002}`
}
function makeUSL(charObj) {
  return charObj.c2111 ?? null
}
function makeLSL(charObj) {
  return charObj.c2110 ?? null
}

function makeResult(usl, lsl, valueObj) {
  const outOfTol = valueObj.outOfTol
  const value = valueObj.v0001

  if (typeof outOfTol === 'undefined' || outOfTol === null) {
    if (lsl === null && usl === null) {
      return 1
    } else if (lsl !== null && usl !== null) {
      if (lsl > value || usl < value) {
        return 0
      } else {
        return 1
      }
    } else if (lsl !== null && usl === null) {
      if (lsl > value) {
        return 0
      } else {
        return 1
      }
    } else {
      if (usl < value) {
        return 0
      } else {
        return 1
      }
    }
  } else {
    if (outOfTol == 0) {
      return 1
    } else {
      return 0 // 상한 하한 공차 아웃
    }
  }
}

function findLastData(dataArray) {
  return dataArray[0]
}

function findLastHistoryData(dataArray, historyCnt) {
  return dataArray.slice(0, historyCnt)
}

export function makeValueSummaryTableData(settings, configSettings, targetParts, targetChars, valueArray) {
  const tableRow = (part, char, values, index) => {
    const tableRowHistoryFormat = (nominal, usl, lsl, decimalPlaces, historyData, char) => {
      return historyData.map((data) => ({
        nominal: nominal,
        usl: usl,
        lsl: lsl,
        char_time: data.v0004,
        result: makeResult(usl, lsl, data),
        value: data.v0001,
        v0014: data.v0014,
        v0053: data.v0053,
        deviation: data.v0001,
        // extra data
        char: char,
        decimalPlaces: decimalPlaces,
      }))
    }

    const tableRowFormat = (part, char, values, index) => {
      const partName = part.p1002
      const cpValueArray = [...values.filter((data) => data.v0002 === 0)]
      cpValueArray.sort((a, b) => Number(b.v0004) - Number(a.v0004))
      const lastData = findLastData(cpValueArray)

      const historyCnt = 10
      const lastHistoryData = findLastHistoryData(cpValueArray, historyCnt)

      const usl = makeUSL(char)
      const lsl = makeLSL(char)
      const nominal = char.c2101 ?? (usl + lsl) / 2

      // extra data

      const decimalPlaces = char.c2022 ?? configSettings.commonSettings.displayDecimalPlaces

      return {
        no: index + 1,
        part_name: partName,
        desc: makeCharDescription(char),
        nominal: nominal,
        usl: usl === null ? null : usl,
        lsl: lsl === null ? null : lsl,
        char_time: lastData.v0004,
        result: makeResult(usl, lsl, lastData),
        value: lastData.v0001,
        v0014: lastData.v0014,
        v0053: lastData.v0053,
        deviation: lastData.v0001,
        subRows: tableRowHistoryFormat(nominal, usl, lsl, decimalPlaces, lastHistoryData, char),
        // extra data
        char: char,
        decimalPlaces: decimalPlaces,
      }
    }

    return tableRowFormat(part, char, values, index)
  }

  let tableDataArray = []
  valueArray.map((valueObj, index) => {
    const targetChar = targetChars.find((charObj) => {
      if (valueObj.part_id == charObj.part_id && valueObj.char_id == charObj.char_id) {
        return charObj
      }
    })

    const targetPart = targetParts.find((partObj) => {
      if (valueObj.part_id == partObj.part_id) {
        return partObj
      }
    })

    const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
    const filteredValue = outlierFilterValues(limits, targetChar, valueObj.valueRaw, configSettings)

    // tableDataArray.push(tableRow(targetCharObj, valueObj, index))
    tableDataArray.push(tableRow(targetPart, targetChar, filteredValue, index))
  })

  return tableDataArray
}
