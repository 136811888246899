import { GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

const displayMode = () => [
  { value: 'stack', text: dqTranslateMsg('SidePanel_' + 'Stack') },
  { value: 'notStack', text: dqTranslateMsg('SidePanel_' + 'NotStack') },
]
export default function SettingsEventOption(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'paretoChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onSelectBoxDisplayTypeValueChanged = (e, dataField) => {
    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`event-option-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'EventOption')}>
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Type'),
        labelLocation: 'top',
        name: 'displayMode',
        items: displayMode(),
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.displayMode,
        disabled: pageState.page.displayType !== 'event',
        onItemClick: (e) => onSelectBoxDisplayTypeValueChanged(e, 'displayMode'),
      })}
    </GroupItem>
  )
}
