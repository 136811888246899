import { EmptyItem, GroupItem } from 'devextreme-react/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function ThemeBarColor(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'histogramChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])
  const onColorBoxBarColorValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.barChart.histogramBar[dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }
  return (
    <GroupItem key={`bar-color-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'HistogramBar')}>
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'Color'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.barChart.histogramBar.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxBarColorValueChanged(e, 'color'),
      })}
    </GroupItem>
  )
}
