export const pageName = {
    // leaves
    overview: 'overview',
    monitoring: 'monitoring',
    database: 'database',
    valueChart: 'valueChart',
    histogramChart: 'histogramChart',
    positionalTolerancesChart: 'positionalTolerancesChart',
    boxPlotChart: 'boxPlotChart',
    linearRegressionChart: 'linearRegressionChart',
    correlationChart: 'correlationChart',
    qualityControlChart: 'qualityControlChart',
    capabilitySummaryChart: 'capabilitySummaryChart',
    paretoChart: 'paretoChart',
    probabilityPlotChart: 'probabilityPlotChart',
    decisionTreeChart: 'decisionTreeChart',
    valueList: 'valueList',
    summaryList: 'summaryList',
    valueSummaryList: 'valueSummaryList',
    scrollBoard: 'scrollBoard',
    configuration: 'configuration',
    adminSettings: 'adminSettings',
    reportPrint: 'reportPrint',
    uploadFile: 'uploadFile',
    companyInfo: 'companyInfo',
    reportPrintMultiFunction: 'reportPrintMultiFunction',

    // parents
    charts: 'charts',
    lists: 'lists',
}
