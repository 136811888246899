import CCUtils from 'src/functions/CommonCalcUtils'
import * as vcImpl from './MonitoringDetailValueChartOptionImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import CPUtils from 'src/functions/CommonPageUtils'

const toolTipBalloonCallback = (args, settings, valueArray) => {
    let displayStr = ''

    args.forEach((element) => {
        if (element.seriesName) {
            displayStr += element.seriesName + ' ' + '<br>'
        }

        if (element.data) {
            displayStr += 'Number : ' + element.data[0] + '<br>'
            console.log(element.data[1])
            if (element.data[1] !== 'NaN') {
                displayStr += 'Value : ' + element.data[1].toFixed(settings.decimalPlaces) + '<br>'
            }
        }
    })

    settings.page.toolTipInfoItems.forEach((item) => {
        if (item.dataField === 'v0004') {
            displayStr += CPUtils.getDisplayDateTimeSec(settings.page.dateTimeTooltipFormat, valueArray[args[0].dataIndex][item.dataField])
            // const year = valueArray[args[0].dataIndex][item.dataField].slice(0, 4)
            // const month = valueArray[args[0].dataIndex][item.dataField].slice(4, 6)
            // const day = valueArray[args[0].dataIndex][item.dataField].slice(6, 8)
            // const hour = valueArray[args[0].dataIndex][item.dataField].slice(8, 10)
            // const minute = valueArray[args[0].dataIndex][item.dataField].slice(10, 12)
            // const second = valueArray[args[0].dataIndex][item.dataField].slice(12, 14)
            // if (settings.page.dateTimeTooltipFormat === 'date/time/sec') {
            //   displayStr +=
            //     item.En +
            //     ' : ' +
            //     `${year}-${month}-${day} ${hour}:${minute}:${second}` +
            //     '<br>'
            // } else if (settings.page.dateTimeTooltipFormat === 'date/time') {
            //   displayStr +=
            //     item.En + ' : ' + `${year}-${month}-${day} ${hour}:${minute}` + '<br>'
            // } else {
            //   displayStr += item.En + ' : ' + `${year}-${month}-${day}` + '<br>'
            // }
        } else {
            displayStr += item.En + ' : ' + valueArray[args[0].dataIndex][item.dataField] + '<br>'
        }
    })

    if (settings.page.toolTipUseSpecLimits === true || settings.page.toolTipUseWarnLimits === true) {
        displayStr += '<br>' + 'Limits' + '<br>'

        if (settings.page.toolTipUseSpecLimits === true) {
            if (typeof settings.usl === 'number') {
                displayStr += 'USL : ' + settings.usl.toFixed(settings.decimalPlaces) + '<br>'
            }
            if (typeof settings.lsl === 'number') {
                displayStr += 'LSL : ' + settings.lsl.toFixed(settings.decimalPlaces) + '<br>'
            }
        }

        if (settings.page.toolTipUseWarnLimits === true) {
            if (typeof settings.uwl === 'number') {
                displayStr += 'UWL : ' + settings.uwl.toFixed(settings.decimalPlaces) + '<br>'
            }
            if (typeof settings.lwl === 'number') {
                displayStr += 'LWL : ' + settings.lwl.toFixed(settings.decimalPlaces) + '<br>'
            }
        }
    }

    return displayStr
}

export function getMonitoringValueChartOption(settings, configSettings, othersPage, selIds, target) {
    const lsl = PCUtils.getLsl(target.charRaw)
    const usl = PCUtils.getUsl(target.charRaw)
    const nominal = PCUtils.getNominal(target.charRaw)
    const gap = PCUtils.getUslLslGap(usl, lsl)
    const warnOffset = PCUtils.getWarningOffset(configSettings.commonSettings.warningLimitPercentage)
    const lwl = PCUtils.getLwl(lsl, gap, warnOffset)
    const uwl = PCUtils.getUwl(usl, gap, warnOffset)

    // make value base constants
    const values = target.valueRaw.map((item) => {
        return item.v0001
    })
    const { sum, length } = PCUtils.getSum(values)
    const center = PCUtils.getAverage(sum, length)
    const stdDev = PCUtils.getStandardDeviation(values, center)
    const ucl = PCUtils.getUcl(center, stdDev)
    const lcl = PCUtils.getLcl(center, stdDev)
    const maxValue = CCUtils.getMax(values)
    const minValue = CCUtils.getMin(values)

    const titleText = `${target.charRaw.c2002 ?? 'empty'}P:${target.charRaw.part_id ?? '?'}/C:${target.charRaw.char_id ?? '?'}`

    const xAxisRight = values.length + 1
    const xAxisLeft = 0
    // const yAxisTop = vcImpl.makeYAxisTop(settings, usl, center, maxValue)
    // const yAxisBottom = vcImpl.makeYAxisBottom(settings, lsl, center, minValue)
    const yAxisTop = vcImpl.makeYAxisTop(settings, usl, lsl, maxValue, minValue)
    const yAxisBottom = vcImpl.makeYAxisBottom(settings, usl, lsl, maxValue, minValue)
    const decimalPlaces = target.charRaw.c2022 ?? configSettings.commonSettings.displayDecimalPlaces

    // chart Split check
    // const filter = new Set(valueArray.valueRaw.filter((item) => item.v0002 === 0).map((value) => value[settings.page.splitItem]))
    const filter = new Set(target.valueRaw.map((value) => value[settings.page.splitItem]))
    const arrayFilter = Array.from(filter)
    // const filteredData = arrayFilter.map((item) => valueArray.valueRaw.filter((item) => item.v0002 === 0).filter((value) => value[settings.page.splitItem] === item))
    const filteredData = arrayFilter.map((item) => target.valueRaw.filter((value) => value[settings.page.splitItem] === item))

    // chart setting reorganization
    const addedSettings = {
        ...settings,
        titleText: titleText,
        lsl: lsl,
        usl: usl,
        lwl: lwl,
        uwl: uwl,
        lcl: lcl,
        ucl: ucl,
        center: center,
        nominal: nominal,
        gap: gap,
        warnOffset: warnOffset,
        xAxisRight: xAxisRight,
        xAxisLeft: xAxisLeft,
        yAxisTop: yAxisTop,
        yAxisBottom: yAxisBottom,
        decimalPlaces: decimalPlaces,
    }

    return {
        title: {
            text: addedSettings.titleText,
            left: 'center',
            textStyle: {
                fontFamily: 'Arial',
                fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
            },
        },
        animationDuration: 300,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        grid: {
            top: '15%',
            bottom: 50,
            left: '3%',
            right: '10%',
            containLabel: true,
        },
        legend: settings.page.layout.column > 3 ? null : { top: '8%', orient: 'horizontal' },
        tooltip: {
            trigger: 'axis',
            appendToBody: true,
            position: function (pos, params, dom, rect, size) {
                let obj = { top: 60 }
                obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
                return obj
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            extraCssText: 'width: auto',
            formatter: function (args) {
                return toolTipBalloonCallback(args, addedSettings, target.valueRaw)
            },
            textStyle: {
                fontFamily: 'Arial',
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        xAxis: {
            type: 'value',
            min: xAxisLeft,
            max: xAxisRight,
            minInterval: 1,
            maxInterval: 1,
            // data: valueArray.valueRaw.filter((data) => data.v0002 === 0).map((value, index) => [index + 1]),
            data: target.valueRaw.map((value, index) => [index + 1]),
            axisLabel: {
                rotate: 90,
                fontFamily: 'Arial',
                hideOverlap: true,
                formatter: (values, index) =>
                    vcImpl.makeXAxisLabelFormat(
                        values,
                        index,
                        // valueArray.valueRaw.filter((data) => data.v0002 === 0),
                        target.valueRaw,
                        addedSettings.page.xAxisLabelItems,
                        settings,
                        configSettings,
                        otherPage.catalogs
                    ),
            },
            axisLine: {
                onZero: false,
                hideOverlap: true,
            },
            splitLine: {
                show: false,
            },
            axisTick: {
                interval: 1,
            },
        },
        yAxis: {
            min: addedSettings.yAxisBottom,
            max: addedSettings.yAxisTop,
            splitLine: {
                show: false,
            },
            nameTextStyle: {
                fontFamily: 'Arial',
            },
            axisLabel: {
                fontFamily: 'Arial',
                formatter: function (value) {
                    return parseFloat(value).toFixed(decimalPlaces)
                },
            },
            axisLine: {
                onZero: false,
            },
        },
        dataZoom: [
            {
                show: true,
                type: 'slider',
                orient: 'horizontal',
                height: `3%`,
                brushSelect: true,
                showDataShadow: true,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'slider',
                orient: 'vertical',
                width: `2%`,
                brushSelect: true,
                showDataShadow: true,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'inside',
                filterMode: 'none',
            },
        ],
        series: vcImpl.makeSeries(selIds, target.charRaw, filteredData, addedSettings, configSettings, othersPage),
    }
}
