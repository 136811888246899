// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.value-summary-list-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 50px);
}
.value-summary-list-layout .value-summary-list-container {
  width: 85%;
  height: 100%;
}
.value-summary-list-layout .value-summary-list-container .histogram-chart::-webkit-scrollbar {
  width: 5px;
}
.value-summary-list-layout .value-summary-list-container .histogram-chart::-webkit-scrollbar-thumb {
  background-color: #3e4451;
}
.value-summary-list-layout .value-summary-list-container .histogram-chart::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
.value-summary-list-layout .side-panel-container {
  width: 15%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/list-value-summary/list-value-summary.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,0BAAA;AACF;AACE;EACE,UAAA;EACA,YAAA;AACJ;AACI;EACE,UAAA;AACN;AACI;EACE,yBAAA;AACN;AACI;EACE,wCAAA;AACN;AAGE;EACE,UAAA;EACA,YAAA;AADJ","sourcesContent":[".value-summary-list-layout {\r\n  display: flex;\r\n  flex-direction: row;\r\n  width: 100%;\r\n  height: calc(100vh - 50px);\r\n\r\n  .value-summary-list-container {\r\n    width: 85%;\r\n    height: 100%;\r\n\r\n    .histogram-chart::-webkit-scrollbar {\r\n      width: 5px;\r\n    }\r\n    .histogram-chart::-webkit-scrollbar-thumb {\r\n      background-color: #3e4451;\r\n    }\r\n    .histogram-chart::-webkit-scrollbar-track {\r\n      background-color: rgba(255, 255, 255, 0);\r\n    }\r\n  }\r\n\r\n  .side-panel-container {\r\n    width: 15%;\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
