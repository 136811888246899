import { ColorBox, Form, ScrollView } from 'devextreme-react'
import { Button } from 'devextreme-react/button'
import { NumberBox, Button as NumberBoxButton } from 'devextreme-react/number-box'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { boxPlotChartSettingsPrm } from 'src/pages/chart-box-plot/components/settings/BoxPlotChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { setBoxPlotChartPage } from 'src/store/common'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import DQToast from 'src/functions/DQToast'
import DQSaveReset from 'src/functions/DQSaveReset'
import { ThemeBoxPlot } from './components/theme/ThemeBoxPlot'
import ThemeOutlier from './components/theme/ThemeOutlier'
import './SidePanelBoxPlotChartTheme.scss'

export default function SidePanelBoxPlotChartTheme(props) {
  const { pageKey } = props
  const dispatch = useDispatch()

  const [scrollPosition, setScrollPosition] = useState(0)
  const { user } = useAuth()
  const parentRef = useRef(null)
  const toastRef = useRef(null)

  const common = useSelector((state) => state.common)
  const curPage = common.pages.boxPlotChart
  const configPage = common.pages.configuration

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }

  const handleSaveButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }
  const handleResetButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.entries(boxPlotChartSettingsPrm.boxPlotChart).forEach(([key, value]) => {
        draft.boxPlotChart[key] = value
      })
      Object.entries(boxPlotChartSettingsPrm.scatterChart).forEach(([key, value]) => {
        draft.scatterChart[key] = value
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  const handleScroll = (e) => {
    setScrollPosition(e.scrollOffset.top)
  }

  const items = (pageState, configState) => [ThemeBoxPlot(pageKey, pageState, configState), ThemeOutlier(pageKey, pageState, configState)]

  return (
    <div ref={parentRef} style={{ padding: '5px', width: '100%', height: '100%' }}>
      <DQToast ref={toastRef} />
      <div style={{ width: '100%', height: '3%' }}>
        <DQSaveReset saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')} resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')} saveCallback={handleSaveButtonClicked} resetCallback={handleResetButtonClicked} />
      </div>
      <div style={{ width: '100%', height: '97%' }}>
        <ScrollView
          className="side-panel-tab-container"
          showScrollbar={'onHover'}
          useNative={false}
          onScroll={handleScroll}
          onContentReady={(e) => {
            e.component.scrollTo({ top: scrollPosition })
          }}
        >
          <Form id="side-panel-box-plot-chart-theme-form" style={{ paddingTop: '5px' }} labelMode={'outside'} formData={curPage.page} showColonAfterLabel={false}>
            {items(curPage, configPage)}
          </Form>
        </ScrollView>
      </div>
    </div>
  )
}
