import { Column, Selection, TreeList } from 'devextreme-react/tree-list'
// import { Column, DataGrid, Editing, ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, SearchPanel, Position, Selection, StateStoring } from 'devextreme-react/data-grid'
import { Button } from 'devextreme-react/button'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useAuth } from 'src/contexts/auth'
import { defCharField } from 'src/default/field/char'
import { defPartField } from 'src/default/field/part'
import { defValueField } from 'src/default/field/value'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import './Configuration.scss'
import { defaultCharField, defaultPartField, defaultValueField } from './defines/default'
import { configurationSettingsPrm, defaultUseFieldList } from '../../settings/ConfigSettings'
import { connect } from 'react-redux'
import TextTitle from 'src/functions/TextTitle'
import { useLanguage } from 'src/contexts/languages'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import DQToast from 'src/functions/DQToast'
import { companyPlantTbDescription as companyPlantTbDesc } from 'src/functions/constants/companyPlantDBfieldDescription'
import { useApi } from 'src/contexts/api'
import { getAdminCompany, updateAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyPrms, makeUpdateAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { makeAdminCompanyToDTOFormat, makeAdminDefaultToDTOFormat } from 'src/dto/DTOImpl'
import * as rd from 'src/store/common'
import { CheckBox } from 'devextreme-react'

const mapStateToProps = (state) => ({
    common: state.common,
})

export default connect(mapStateToProps)(Configuration)

const makeUseFieldForDataGrid = (targetFieldArray, useFieldArray, rmFieldArray) => {
    const fields = targetFieldArray

    const removedFields = fields.filter((field) => !rmFieldArray.includes(field.dataField))

    let useFields = []
    if (useFieldArray.length > 0) {
        useFields = removedFields.filter((field) => useFieldArray.includes(field.dataField))
    } else {
        useFields = removedFields
    }

    const dataSource = useFields.map((field, index) => ({
        ID: index + 1,
        ...field,
    }))

    return dataSource
}

const filterPartSelectedKeys = (saved, initList, listAll) => {
    let selectedKeys = []

    if (saved.length > 0) {
        const savedValues = saved.map((item) => item.value)
        const filteredList = listAll.filter((item) => savedValues.includes(item.value))
        selectedKeys = filteredList.map((item) => item.ID).filter((item) => item !== undefined)
    }
    return selectedKeys
}

function Configuration({ pageKey, common }) {
    const { user } = useAuth()
    const { spring } = useApi()
    const dispatch = useDispatch()
    const toastRef = useRef(null)
    const configPartList = useRef(null)
    const configCharList = useRef(null)
    const configValueList = useRef(null)

    const [partDataSource, setPartDataSource] = useState([])
    const [charDataSource, setCharDataSource] = useState([])
    const [valueDataSource, setValueDataSource] = useState([])
    const [partSelectedKeys, setPartSelectedKeys] = useState([])
    const [charSelectedKeys, setCharSelectedKeys] = useState([])
    const [valueSelectedKeys, setValueSelectedKeys] = useState([])

    const [isDefaultSettings, setIsDefaultSettings] = useState(true)

    const curPage = common.pages.configuration
    const othersPage = common.pages.others
    const configPage = curPage
    const configAll = common.base

    const useFieldList = common.pages.others.useFieldList

    useEffect(() => {
        const partDataSource = makeUseFieldForDataGrid(defPartField(), [], [])
        const charDataSource = makeUseFieldForDataGrid(defCharField(), [], [])
        const valueDataSource = makeUseFieldForDataGrid(defValueField(), [], [])

        const partSelectedKeys = filterPartSelectedKeys(useFieldList.part, defaultPartField, partDataSource)
        const charSelectedKeys = filterPartSelectedKeys(useFieldList.char, defaultCharField, charDataSource)
        const valueSelectedKeys = filterPartSelectedKeys(useFieldList.value, defaultValueField, valueDataSource)

        batch(() => {
            setPartDataSource(partDataSource)
            setCharDataSource(charDataSource)
            setValueDataSource(valueDataSource)
            setPartSelectedKeys(partSelectedKeys)
            setCharSelectedKeys(charSelectedKeys)
            setValueSelectedKeys(valueSelectedKeys)
        })
    }, [useFieldList])

    const handleSaveButton = async () => {
        const updateCompanyPlantSettings = async (element, field, pageValue) => {
            const company = element.company
            const plant = element.plant
            const dataJsonStr = JSON.stringify(pageValue)

            const updateData = {
                [field]: dataJsonStr,
            }

            await updateAdminCompany(spring, makeUpdateAdminCompanyPrms(company, plant, updateData))
        }

        const selectedPartFieldRow = configPartList.current.instance.getSelectedRowsData()
        const selectedCharFieldRow = configCharList.current.instance.getSelectedRowsData()
        const selectedValueFieldRow = configValueList.current.instance.getSelectedRowsData()

        const next = produce(othersPage, (draft) => {
            draft.useFieldList.part = selectedPartFieldRow
            draft.useFieldList.char = selectedCharFieldRow
            draft.useFieldList.value = selectedValueFieldRow
        })

        if (isDefaultSettings) {
            const element = {
                company: user.user_company,
                plant: user.user_plant,
            }

            await updateCompanyPlantSettings(element, companyPlantTbDesc.useFields, next.useFieldList)
        }

        dispatch(rd.setUseFieldList(next.useFieldList))
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }

    const handleResetButton = async () => {
        const company = user?.user_company
        const plant = user?.user_plant
        const group = user?.user_group

        if (!company || !plant || !group) {
            console.error('company plant group info is invalid')
            return
        }
        const targetPlantInfo = await getAdminCompany(spring, makeGetAdminCompanyPrms(company, plant))
        const adminCompanyDTO = makeAdminCompanyToDTOFormat(targetPlantInfo)
        const useFieldsDataStr = adminCompanyDTO[companyPlantTbDesc.useFields]

        let useFieldsData = null
        if (useFieldsDataStr === '' || useFieldsDataStr === null) {
            console.error('plant language data is empty')
            useFieldsData = defaultUseFieldList
        } else {
            useFieldsData = JSON.parse(useFieldsDataStr)
        }
        console.log(`plant language :  <${useFieldsData}>`)

        const next = produce(othersPage, (draft) => {
            draft.useFieldList = useFieldsData
        })

        dispatch(rd.setUseFieldList(next.useFieldList))
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    // const handleResetButton = async () => {
    //     const next = produce(othersPage, (draft) => {
    //         draft.useFieldList = defaultUseFieldList
    //     })
    //     // dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'configuration', next)
    //     dispatch(rd.setUseFieldList(next.useFieldList))
    //     toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    // }

    const translateFieldText = (type, cell) => {
        const translated = dqTranslateMsg('Db_field_' + type + '_' + cell.data.text)
        return <div>{translated}</div>
    }

    const handlePartSelectionChanged = (e) => {
        const selectedRowData = e.selectedRowsData
        const next = produce(othersPage, (draft) => {
            draft.useFieldList.part = selectedRowData
        })
        dispatch(rd.setUseFieldList(next.useFieldList))
    }
    const handleCharSelectionChanged = (e) => {
        const selectedRowData = e.selectedRowsData
        const next = produce(othersPage, (draft) => {
            draft.useFieldList.char = selectedRowData
        })
        dispatch(rd.setUseFieldList(next.useFieldList))
    }
    const handleValueSelectionChanged = (e) => {
        const selectedRowData = e.selectedRowsData
        const next = produce(othersPage, (draft) => {
            draft.useFieldList.value = selectedRowData
        })
        dispatch(rd.setUseFieldList(next.useFieldList))
    }

    return (
        <div className='configuration-container'>
            <DQToast ref={toastRef} />
            <div className='configuration-control-container'>
                <div className='button-container'>
                    <CheckBox
                        key={'find-distribution-save-check-box'} // key 추가
                        text={dqTranslateMsg('Page_Configuration_' + 'Apply') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Plant') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Save')}
                        value={isDefaultSettings} // 권한 상태에 따라 체크 여부 결정
                        width={'100%'}
                        height={'100%'}
                        disabled={true}
                        // onValueChanged={handleCheckboxChange} // 상태 변경 핸들러 추가
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='save'
                        text={dqTranslateMsg('Page_Configuration_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            <div className='configuration-content-container'>
                <div className='configuration-datagrid-container'>
                    <TextTitle
                        text={dqTranslateMsg('Page_Configuration_' + 'PartField')}
                        width='50%'
                        fontSize={'16px'}
                        textAlign={'left'}
                    />
                    <TreeList
                        ref={configPartList}
                        className='configuration-datagrid-content'
                        id='configuration-char-treelist'
                        dataSource={partDataSource}
                        showRowLines={true}
                        showBorders={true}
                        columnAutoWidth={true}
                        selectedRowKeys={partSelectedKeys}
                        keyExpr='ID'
                        parentIdExpr='HeadID'
                        scrolling={{
                            columnRenderingMode: 'standard',
                            rowRenderingMode: 'standard',
                            mode: 'standard',
                            preloadEnabled: false,
                            renderAsync: false,
                            showScrollbar: 'onHover',
                            useNative: false,
                        }}
                        onSelectionChanged={handlePartSelectionChanged}
                    >
                        <Selection
                            recursive={true}
                            mode='multiple'
                        />
                        <Column
                            dataField='text'
                            caption={dqTranslateMsg('Page_Configuration_' + 'PartField')}
                        />
                        <Column
                            dataField='text'
                            caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                            cellRender={(cell) => translateFieldText('Part', cell)}
                        />
                    </TreeList>
                </div>
                <div className='configuration-datagrid-container'>
                    <TextTitle
                        text={dqTranslateMsg('Page_Configuration_' + 'CharacteristicsField')}
                        width='50%'
                        fontSize={'16px'}
                        textAlign={'left'}
                    />
                    <TreeList
                        ref={configCharList}
                        className='configuration-datagrid-content'
                        id='configuration-char-treelist'
                        dataSource={charDataSource}
                        showRowLines={true}
                        showBorders={true}
                        columnAutoWidth={true}
                        selectedRowKeys={charSelectedKeys}
                        keyExpr='ID'
                        parentIdExpr='HeadID'
                        scrolling={{
                            columnRenderingMode: 'standard',
                            rowRenderingMode: 'standard',
                            mode: 'standard',
                            preloadEnabled: false,
                            renderAsync: false,
                            showScrollbar: 'onHover',
                            useNative: false,
                        }}
                        onSelectionChanged={handleCharSelectionChanged}
                    >
                        <Selection
                            recursive={true}
                            mode='multiple'
                        />
                        <Column
                            dataField='text'
                            caption={dqTranslateMsg('Page_Configuration_' + 'CharacteristicsField')}
                        />
                        <Column
                            dataField='text'
                            caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                            cellRender={(cell) => translateFieldText('Characteristics', cell)}
                        />
                    </TreeList>
                </div>
                <div className='configuration-datagrid-container'>
                    <TextTitle
                        text={dqTranslateMsg('Page_Configuration_' + 'ValueField')}
                        width='50%'
                        fontSize={'16px'}
                        textAlign={'left'}
                    />
                    <TreeList
                        ref={configValueList}
                        className='configuration-datagrid-content'
                        id='configuration-value-treelist'
                        dataSource={valueDataSource}
                        showRowLines={true}
                        showBorders={true}
                        columnAutoWidth={true}
                        selectedRowKeys={valueSelectedKeys}
                        keyExpr='ID'
                        parentIdExpr='HeadID'
                        scrolling={{
                            columnRenderingMode: 'standard',
                            rowRenderingMode: 'standard',
                            mode: 'standard',
                            preloadEnabled: false,
                            renderAsync: false,
                            showScrollbar: 'onHover',
                            useNative: false,
                        }}
                        onSelectionChanged={handleValueSelectionChanged}
                    >
                        <Selection
                            recursive={true}
                            mode='multiple'
                        />
                        <Column
                            dataField='text'
                            caption={dqTranslateMsg('Page_Configuration_' + 'ValueField')}
                        />
                        <Column
                            dataField='text'
                            caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                            cellRender={(cell) => translateFieldText('Value', cell)}
                        />
                    </TreeList>
                </div>
            </div>
        </div>
    )
}
