import React from 'react'
import './chart-decision-tree.scss'
import DecisionTreeChartForm from './components/main-view/DecisionTreeChartForm'
import PageContainer from 'src/functions/PageContainer'

function DecisionTreeChart(pageKey) {
  return <PageContainer pageKey={pageKey} ChartFormComponent={DecisionTreeChartForm} />
}

export default DecisionTreeChart
