import { CheckBox } from 'devextreme-react'
import React, { useEffect, useRef, useState } from 'react'
import DQToast from 'src/functions/DQToast'
import { Button } from 'devextreme-react'
import './PushSettingsPermissions.scss'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import produce from 'immer'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'

function PushSettingsPermissions(props) {
    // 권한 유형 배열
    const permissionTypes = [
        { type: 'write', text: getPageMsg(msgType.AdminSettings, 'Write') },
        // { type: 'read', text: getPageMsg(msgType.AdminSettings, 'Read') },
        { type: 'demo', text: getPageMsg(msgType.AdminSettings, 'Demo') },
    ]

    // Toast를 표시하기 위한 ref
    const toastRef = useRef(null)

    // props
    const stateAll = props.state
    const state = props.state.permissions
    const setState = props.setState

    // 권한 상태 관리
    const [permissions, setPermissions] = useState(state || [])

    // 저장 버튼 핸들러
    const handleSaveButton = () => {
        const next = produce(stateAll, (draft) => {
            draft.permissions = permissions
        })
        setState(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_AdminSettings_SavedSettings'))
    }

    // 초기화 버튼 핸들러
    const handleResetButton = () => {
        setPermissions(state || {}) // 초기화
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('Page_AdminSettings_ResetSettings'))
    }

    // 컴포넌트 마운트 시 권한 상태 설정
    useEffect(() => {
        setPermissions(state || {})
    }, [state])

    // 체크박스 렌더링
    const renderPermissionCheckbox = (item) => {
        return (
            <CheckBox
                key={item.type} // key 추가
                text={item.text}
                value={permissions.includes(item.type) || false} // 권한 상태에 따라 체크 여부 결정
                onValueChanged={(e) => handleCheckboxChange(item.type, e.value)} // 상태 변경 핸들러 추가
                style={{ width: '30%', height: '50px' }}
            />
        )
    }

    const handleCheckboxChange = (type, value) => {
        if (value) {
            if (!permissions.includes(type)) {
                const temp = [...permissions]
                temp.push(type)
                setPermissions(temp)
            }
        } else {
            const temp = permissions.filter((permission) => permission !== type)
            setPermissions(temp)
        }
    }
    return (
        <div className='dx-theme-background-color'>
            <DQToast ref={toastRef} />
            <div className='push-settings-permissions-control-container'>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='save'
                        text={dqTranslateMsg('Page_Configuration_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            <div className='push-settings-permissions-control-container'>{permissionTypes.map(renderPermissionCheckbox)}</div>
        </div>
    )
}

export default PushSettingsPermissions
