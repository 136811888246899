import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function Arima(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'valueChart'
  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onCheckBoxArimaValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page.arima[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }
  const onNumberBoxArimaValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.page.arima[dataField] = value === null ? minOption : value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`arima-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'ARIMA')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Show'),
        labelLocation: 'right',
        name: 'show',
        value: pageState.page.arima.show,
        disabled: pageState.page.splitItem.length > 0,
        onValueChanged: (e) => onCheckBoxArimaValueChanged(e, 'show'),
      })}
      {/* <EmptyItem /> */}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Auto'),
        labelLocation: 'right',
        name: 'auto',
        value: pageState.page.arima.auto,
        disabled: pageState.page.arima.show === true || pageState.page.splitItem.length > 0,
        onValueChanged: (e) => onCheckBoxArimaValueChanged(e, 'auto'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'pValue'),
        labelLocation: 'top',
        name: 'pValue',
        min: 0,
        max: 5,
        step: 1,
        value: pageState.page.arima.pValue,
        disabled: pageState.page.arima.show === true || pageState.page.arima.auto === true || pageState.page.splitItem.length > 0,
        onValueChanged: (e) => onNumberBoxArimaValueChanged(e, 'pValue'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'dValue'),
        labelLocation: 'top',
        name: 'dValue',
        min: 0,
        max: 10,
        step: 1,
        value: pageState.page.arima.dValue,
        disabled: pageState.page.arima.show === true || pageState.page.arima.auto === true || pageState.page.splitItem.length > 0,
        onValueChanged: (e) => onNumberBoxArimaValueChanged(e, 'dValue'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'qValue'),
        labelLocation: 'top',
        name: 'qValue',
        min: 0,
        max: 10,
        step: 1,
        value: pageState.page.arima.qValue,
        disabled: pageState.page.arima.show === true || pageState.page.arima.auto === true || pageState.page.splitItem.length > 0,
        onValueChanged: (e) => onNumberBoxArimaValueChanged(e, 'qValue'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'ForecastSize'),
        labelLocation: 'top',
        name: 'ForecastSize',
        min: 1,
        max: 9997,
        step: 1,
        value: pageState.page.arima.forecastSize,
        disabled: pageState.page.arima.show === true || pageState.page.splitItem.length > 0,
        onValueChanged: (e) => onNumberBoxArimaValueChanged(e, 'forecastSize'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'SampleSize'),
        labelLocation: 'top',
        name: 'SampleSize',
        min: 1,
        max: 10000,
        step: 1,
        value: pageState.page.arima.sampleSize,
        disabled: pageState.page.arima.show === true || pageState.page.splitItem.length > 0,
        onValueChanged: (e) => onNumberBoxArimaValueChanged(e, 'sampleSize'),
      })}
    </GroupItem>
  )
}
