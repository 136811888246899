import Button from 'devextreme-react/button'
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnChooserSearch,
    ColumnChooserSelection,
    Editing,
    Item,
    Position,
    SearchPanel,
    Selection,
    StateStoring,
    GroupPanel,
    Toolbar,
    Popup,
    Form,
    Sorting,
    LoadPanel,
} from 'devextreme-react/data-grid'
import produce from 'immer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import * as dbCharApi from 'src/api/char'
import ApiStatus from 'src/api/defines/ApiStatus'
import * as dbPartApi from 'src/api/part'
import { useAuth } from 'src/contexts/auth'
import { defPartField } from 'src/default/field/part'
import * as dtoUtils from 'src/dto/DTOImpl'
import * as DBUtils from 'src/functions/DatabaseUtils'

import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as permi from 'src/pages/components/dq-permission/DQPermission'
import { setAllDataInfo, setPartInfoAll, setPartInfo } from 'src/store/charts-raw-data'
import './DatabasePartForm.scss'
import { convertUserRoleToString, userRoleLevel } from 'src/functions/constants/authLevelConstants'
import { connect } from 'react-redux'
import TextTitle from 'src/functions/TextTitle'
import { useApi } from 'src/contexts/api'
import { compareAlphaNumAsc, compareAlphaNumDesc, compareNumAsc, compareNumDesc, comparisonAlphanumericAsc, comparisonAlphanumericDesc } from 'src/functions/SortComparison'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import useCustomStateStore from 'src/hook/devexpress-custom-state-storing/useDevStateStoring'
import { getErrCommMsg, getErrMsg, getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})
const mapDispatchToProps = {
    dispatchSetAllDataInfo: setAllDataInfo,
    dispatchSetPartInfoAll: setPartInfoAll,
    dispatchSetPartInfo: setPartInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(DatabasePartForm)

export const dataSource = []

const selectFilter = (currentFilter, savedFilter, selectedNo, setSelectedFilter) => {
    let filterData
    if (selectedNo === 0) {
        filterData = currentFilter
    } else {
        filterData = savedFilter[selectedNo - 1]
    }

    setSelectedFilter(filterData)
}

function DatabasePartForm({ common, chartRawData, dispatchSetAllDataInfo, dispatchSetPartInfoAll, dispatchSetPartInfo, dispatchClearAllDataInfo }) {
    const { user } = useAuth()
    const dataGridRef = useRef(null)
    const dispatch = useDispatch()
    const { saveState, loadState, clearState } = useCustomStateStore({ stateKey: 'database-datagrid-part', excludedFields: [], excludedOthers: ['selectedRowKeys'] }) // Pass your custom state key here
    const [isLoadingPanel, setIsLoadingPanel] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [collapsedRow, setCollapsedRow] = useState([])
    const [expandAll, setExpandAll] = useState(true)
    const { spring } = useApi()

    // const chartRawData = useSelector((state) => state.chartRawData)
    const parts = chartRawData.partInfo

    // const common = useSelector((state) => state.common)
    const pages = common.pages
    const othersPage = common.pages.others
    const curPage = common.pages.database
    const configPage = common.pages.configuration
    const hasWriteAuth = common.pages.others.permissions.includes('write')
    const hasReadAuth = common.pages.others.permissions.includes('read')
    const hasDemoAuth = common.pages.others.permissions.includes('demo')

    // const dbFilterInfo = DBUtils.getCurrentFilterData(pages, othersPage)

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0
    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }

    const defaultOpenColumns = ['p1001', 'p1002']
    const columns = useFieldList.part.map((columnContent, index) => ({
        dataField: columnContent.value,
        caption: columnContent.text,
        dataType: columnContent.dataType,
        visible: defaultOpenColumns.includes(columnContent.value),
        minWidth: 100,
        alignment: 'center',
    }))

    const makePartGridDataSource = (partListInfo) => {
        const gridDataSource = partListInfo.map((part, index) => ({
            ID: index + 1,
            ...part,
        }))
        return gridDataSource
    }

    function handleLoadButtonClicked() {
        const selectedRow = dataGridRef.current.instance.getSelectedRowsData()

        if (selectedRow.length === 0) {
            alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgEmptyPart'))
            return
        }

        const dbFilterInfo = DBUtils.getCurrentFilterData(pages, othersPage)
        setIsLoadingPanel(true)

        const selectedPartInfo = selectedRow.map((target) => chartRawData.partInfoAll.find((item) => item.part_id === target.part_id))

        if (selectedPartInfo.length === 0) {
            setIsLoadingPanel(false)
            return
        }

        const ids = selectedPartInfo.reduce((acc, { part_id }) => {
            acc.push({ part_id })
            return acc
        }, [])

        spring
            // .getCharListFilter(ids, filters)
            .getCharListFilter(user, ids, dbFilterInfo)
            .then((result) => {
                if (!ApiStatus.isSuccess(result.status)) {
                    alert(getErrCommMsg(result.status))
                }

                console.log('<CharListFilter Load Success>')
                const data = result.data
                const flatData = data.flat()

                if (flatData.length !== 0) {
                    const convertedCharDTO = dtoUtils.makeCharInfoToDTOFormat(flatData, configPage)

                    if (DBUtils.checkCharValidation(convertedCharDTO)) {
                        const selectedPartInfo = []
                        ids.forEach((id) => {
                            selectedPartInfo.push(chartRawData.partInfoAll.find((part) => part.part_id === id.part_id))
                        })

                        const next = produce(chartRawData, (draft) => {
                            draft.partInfo = selectedPartInfo
                            draft.charInfoAll = convertedCharDTO
                            draft.charInfo = []
                            draft.valueInfo = []
                        })
                        dispatchSetAllDataInfo(next)
                        // setCharListAll(convertedCharDTO)
                    } else {
                        alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgNotMatchCharField'))
                    }
                } else {
                    alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgEmptyCharData'))
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally(() => {
                setIsLoadingPanel(false)
            })
    }

    const handleRowUpdated = (e) => {
        console.log('updated')

        const { ID, ...partData } = e.data
        const dt_field = Object.keys(partData).filter((key) => partData[key] === null || partData[key] === '')

        const updatedData = {
            ...partData,
            dt_field,
        }
        dbPartApi.setPartInfo(user, updatedData)

        const selAllIndex = chartRawData.partInfoAll.findIndex((item) => item.part_id === partData.part_id)

        let nextInfoAll
        if (selAllIndex !== -1) {
            nextInfoAll = produce(chartRawData.partInfoAll, (draft) => {
                draft[selAllIndex] = partData
            })
        }

        const selInfoIndex = chartRawData.partInfo.findIndex((item) => item.part_id === partData.part_id)

        let nextInfo
        if (selInfoIndex !== -1) {
            nextInfo = produce(chartRawData.partInfo, (draft) => {
                draft[selInfoIndex] = partData
            })
        }

        if (selAllIndex !== -1 || selInfoIndex !== -1) {
            batch(() => {
                if (selAllIndex !== -1) {
                    dispatchSetPartInfoAll(nextInfoAll)
                }

                if (selInfoIndex !== -1) {
                    dispatchSetPartInfo(nextInfo)
                }
            })
        }
    }

    const handleRowRemoved = (e) => {
        console.log('removed')
        const d = e.data
        const { ID, ...partData } = e.data

        dbPartApi.deletePart(user, d.part_id)
    }

    const sortByLocation = (rowData, dataField) => {
        console.log(rowData[dataField])
        return rowData[dataField]
    }

    const handleCustomSort = (e) => {
        if (e && e.rowType === 'header') {
            e.cellElement.style.textAlign = 'center'

            e.cellElement.addEventListener('click', function (args) {
                const sortOrder = e.column.sortOrder
                const dataField = e.column.dataField

                const setSortOptions = (order, sortingMethod) => {
                    e.component.columnOption(e.column.index, 'sortOrder', order)
                    if (e.column.dataType === 'string') {
                        e.component.columnOption(e.column.index, 'calculateSortValue', (rowData) => sortByLocation(rowData, dataField))
                        e.component.columnOption(e.column.index, 'sortingMethod', sortingMethod)
                    } else {
                        e.component.columnOption(e.column.index, 'calculateSortValue', null)
                        e.component.columnOption(e.column.index, 'sortingMethod', sortingMethod)
                    }
                }

                if (!e.column.type && sortOrder === undefined) {
                    console.log('Added ascending sort')
                    setSortOptions('asc', e.column.dataType === 'string' ? compareAlphaNumAsc : compareNumAsc)
                } else if (!e.column.type && sortOrder === 'asc') {
                    console.log('Added descending sort')
                    setSortOptions('desc', e.column.dataType === 'string' ? compareAlphaNumDesc : compareNumDesc)
                } else {
                    console.log('Cleared sort')
                    setSortOptions(undefined, null)
                }

                console.log(`e.column.index: ${e.column.index}`)
                args.preventDefault()
                args.stopPropagation()
            })
        }
    }

    useEffect(() => {
        const dbFilterInfo = DBUtils.getCurrentFilterData(pages, othersPage)
        const filtersAuth = produce(dbFilterInfo, (draft) => {
            if (draft.mode === 'quick') {
                // if (hasDemoAuth) {
                if (user.user_group === 'demo') {
                    draft.data = draft.data || {} // data가 없으면 빈 객체로 초기화
                    draft.data.data = draft.data.data || {} // part가 없으면 빈 객체로 초기화
                    draft.data.data.part = draft.data.data.part || {} // part가 없으면 빈 객체로 초기화
                    draft.data.data.part.p1104 = 'demo' // p1104 값 설정
                }
            } else {
                // if (hasDemoAuth) {
                if (user.user_group === 'demo') {
                    draft.data.data = ['1104', 'contain', 'demo']
                }
            }
        })
        setIsLoadingPanel(true)
        if (dataGridRef) {
            dataGridRef.current.instance.deselectAll()
        }
        spring
            .getPartListFilter(user, filtersAuth)
            .then((result) => {
                if (!ApiStatus.isSuccess(result.status)) {
                    alert(getErrCommMsg(result.status))
                }

                if (result.data.length !== 0) {
                    const convertedPartDTO = dtoUtils.makePartInfoToDTOFormat(result.data)
                    if (DBUtils.checkPartValidation(convertedPartDTO)) {
                        const dataSource = makePartGridDataSource(convertedPartDTO)
                        batch(() => {
                            dispatchSetPartInfoAll(convertedPartDTO)
                            setDataSource(dataSource)
                        })
                    } else {
                        batch(() => {
                            dispatchSetPartInfoAll([])
                            setDataSource([])
                        })
                        alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgNotMatchPartField'))
                    }
                } else {
                    batch(() => {
                        dispatchSetPartInfoAll([])
                        setDataSource([])
                    })
                    alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgEmptyPartData'))
                }
            })
            .catch((error) => {
                batch(() => {
                    dispatchSetPartInfoAll([])
                    setDataSource([])
                })
                console.error(error)
                alert(error)
            })
            .finally(() => {
                setIsLoadingPanel(false)
            })
    }, [curPage, othersPage])

    const handleColumnVisibilityChange = useCallback((e) => {
        if (e.component && e.fullName.startsWith('columns[') && e.fullName.endsWith('].visible')) {
            const columnIndex = parseInt(e.fullName.match(/\d+/)[0], 10)
            const isVisible = e.value
            const gridInstance = e.component

            if (gridInstance && typeof isVisible !== 'undefined') {
                if (isVisible) {
                    const visibleColumns = gridInstance.getVisibleColumns()
                    const lastIndex = visibleColumns.length - 1
                    gridInstance.columnOption(columnIndex, 'visibleIndex', lastIndex)
                }
            }
        }
    }, [])

    const handleSaveState = () => {
        if (dataGridRef.current) {
            const state = dataGridRef.current.instance.state()
            saveState(state)
        }
    }

    const handleLoadState = () => {
        if (dataGridRef.current) {
            const state = loadState()
            if (state) {
                dataGridRef.current.instance.state(state)
            }
        }
    }

    const handleClearState = () => {
        clearState()
        if (dataGridRef.current) {
            const emptyState = {
                columns: [],
                sorting: [],
                grouping: [],
                selectionFilter: [],
                pageIndex: 0,
                pageSize: 10,
            }
            dataGridRef.current.instance.state(emptyState)
        }
    }

    const handleKeyDown = (e) => {
        if (dataGridRef) {
            // Example shortcut: Shift+L to perform a specific action
            if (e.event.shiftKey && e.event.key === 'L') {
                // e.preventDefault() // Prevent the default action if any
                handleLoadButtonClicked() // Your specific action function
            }
            if (e.event.key === 'Escape') {
                dataGridRef.current.instance.hideColumnChooser()
            }
        }
    }

    const handleExpandAllClicked = (value) => {
        setExpandAll(value)
        if (value === true) {
            // dataGridRef.current.instance.expandAll()
            dataGridRef.current.instance.expandAll()
            setCollapsedRow([]) // 모든 행이 확장되었으므로 빈 배열로 초기화
        } else {
            const allRowKeys = dataGridRef.current.instance
                .getVisibleRows()
                .filter((row) => row.rowType === 'group') // 그룹 행만 선택
                .map((row) => row.key[0]) // 각 그룹의 키 가져오기
            allRowKeys.forEach((key) => dataGridRef.current.instance.collapseRow([key]))
            setCollapsedRow(allRowKeys) // 전체 축소 시 collapsedRow에 모든 그룹 키를 저장합니다.
        }
    }

    useEffect(() => {
        handleLoadState()
    }, [])

    return (
        // <div className="dx-theme-background-color">
        <div style={{ width: '100%', height: '100%' }}>
            <DQLoadPanel
                open={isLoadingPanel}
                message={dqTranslateMsg('Common_' + 'LoadPanelLoading')}
            />
            <div className='settings-container'>
                <TextTitle
                    text={dqTranslateMsg('Page_Database_' + 'PartList')}
                    width='50%'
                    fontSize={'16px'}
                    textAlign={'left'}
                />
                <div className='part-layout-button-container'>
                    <Button
                        id='layoutButton'
                        style={{ width: '100%' }}
                        icon='save'
                        text={dqTranslateMsg('Page_Database_' + 'SaveLayout')}
                        onClick={handleSaveState}
                    />
                </div>
                <div className='part-layout-button-container'>
                    <Button
                        id='layoutButton'
                        style={{ width: '100%' }}
                        icon='clear'
                        text={dqTranslateMsg('Page_Database_' + 'ClearLayout')}
                        onClick={handleClearState}
                    />
                </div>
                <div className='part-load-button-container'>
                    <Button
                        id='partLoadButton'
                        icon='download'
                        onClick={handleLoadButtonClicked}
                    />
                </div>
            </div>
            <div
                id='partDiv'
                className='database-part-grid-container'
            >
                <DataGrid
                    ref={dataGridRef}
                    className='database-part-grid-content'
                    id='partDataGrid'
                    keyExpr='ID'
                    dataSource={dataSource}
                    columnResizingMode='widget'
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    scrolling={{
                        columnRenderingMode: 'standard',
                        rowRenderingMode: 'standard',
                        mode: 'standard',
                        preloadEnabled: false,
                        renderAsync: false,
                        showScrollbar: 'onHover',
                        useNative: false,
                        scrollByContent: true,
                        scrollByThumb: true,
                    }}
                    width='100%'
                    filterRow={{ visible: false }}
                    // repaintChangesOnly={false}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    // columnMaxWidth={300}
                    noDataText={dqTranslateMsg('Page_Database_' + 'NoData')}
                    paging={{ enabled: false }}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    hoverStateEnabled={true}
                    onKeyDown={handleKeyDown}
                    onRowCollapsing={(e) => {
                        const colRow = [...collapsedRow]
                        const isExist = colRow.some((item) => item === e.key[0])
                        if (!isExist) {
                            colRow.push(e.key[0])
                            setCollapsedRow(colRow)
                        }
                    }}
                    onRowExpanding={(e) => {
                        const colRow = [...collapsedRow]
                        const index = colRow.indexOf(e.key[0])
                        if (index !== -1) {
                            colRow.splice(index, 1)
                        }
                        setCollapsedRow(colRow)
                    }}
                    onRowUpdated={handleRowUpdated}
                    onRowRemoved={handleRowRemoved}
                    onCellPrepared={handleCustomSort}
                    onOptionChanged={handleColumnVisibilityChange}
                    onContentReady={(e) => {
                        for (let i = 0; i < collapsedRow.length; i++) {
                            e.component.collapseRow([collapsedRow[i]])
                        }
                    }}
                >
                    {/* <LoadPanel enabled /> */}
                    <Selection
                        mode='multiple'
                        showCheckBoxesMode={'always'}
                    />
                    <Sorting mode='single' />
                    <Column
                        type='selection'
                        fixed={true}
                        showInColumnChooser={false}
                        visible={true}
                        fixedPosition='left'
                        width={80}
                    />
                    {/* {hasWriteAuth ? ( */}
                    <Column
                        type='buttons'
                        fixed={true}
                        showInColumnChooser={false}
                        visible={hasWriteAuth ? true : false}
                        fixedPosition='right'
                        buttons={['edit']}
                        width={50}
                    />
                    {/* ) : null} */}
                    {/* {hasWriteAuth ? ( */}
                    <Editing
                        mode='popup'
                        useIcons={true}
                        allowUpdating={hasWriteAuth ? true : false}
                        allowDeleting={hasWriteAuth ? true : false}
                    >
                        <Popup
                            width={'auto'}
                            height={'auto'}
                            maxWidth={'80%'}
                            maxHeight={'80%'}
                            hideOnOutsideClick={true}
                            showCloseButton={true}
                        />
                        <Form>
                            <Item
                                key={'database-part-editing-item'}
                                itemType='group'
                                colCount={2}
                                colSpan={2}
                            >
                                {useFieldList.part.length > 0
                                    ? useFieldList.part.map((item, index) => {
                                          if (item.displayType === 'plain') {
                                              return (
                                                  <Item
                                                      key={`database-part-editing-item-${index}`}
                                                      editorType={'dxTextBox'}
                                                      dataField={item.value}
                                                      editorOptions={{
                                                          disabled: item.configuration === false,
                                                      }}
                                                  />
                                              )
                                          } else {
                                              return (
                                                  <Item
                                                      key={`database-part-editing-item-${index}`}
                                                      dataField={item.value}
                                                      editorType='dxSelectBox'
                                                      editorOptions={{
                                                          dataSource: item.listValue,
                                                          valueExpr: 'value', // 데이터의 값 필드 설정
                                                          displayExpr: 'text', // 데이터의 표시 필드 설정
                                                      }}
                                                  />
                                              )
                                          }
                                      })
                                    : null}
                            </Item>
                        </Form>
                    </Editing>
                    {/* ) : null} */}
                    {/* <StateStoring enabled={true} type="localStorage" storageKey="database-datagrid-part" savingTimeout={500} /> */}
                    {columns.map((column, index) => {
                        let captionCondition = ''
                        if (configPage.commonSettings.displayFieldNameWithNumber) {
                            captionCondition += `[ ${column.caption} ] `
                        }
                        captionCondition += dqTranslateMsg('Db_field_' + 'Part_' + column.caption)

                        return (
                            <Column
                                key={`part-column-${index}`}
                                dataField={column.dataField}
                                caption={captionCondition}
                                visible={column.visible}
                                minWidth={100}
                                // alignment="left"
                                dataType={column.dataType}
                            />
                        )
                    })}
                    <Toolbar>
                        <Item
                            key={'database-part-toolbar-group-panel'}
                            name='groupPanel'
                            location='before'
                        />
                        <Item
                            key={'database-part-toolbar-search-panel'}
                            name='searchPanel'
                            location='after'
                        />
                        <Item
                            key={'database-part-toolbar-button'}
                            location='after'
                        >
                            <Button
                                icon={'remove'}
                                width={'auto'}
                                text={dqTranslateMsg('Page_Database_' + 'DeleteSelected')}
                                focusStateEnabled={false}
                                onClick={async () => {
                                    const dgInstance = dataGridRef.current.instance
                                    const selectedRow = dgInstance.getSelectedRowsData()

                                    let deleteMessage = `${dqTranslateMsg('Page_Database_ConfirmMessage')}\n\n[${getPageMsg(msgType.Database, 'DeleteItems')}]\n`
                                    deleteMessage += selectedRow.map((row) => `${row.p1001} / ${row.p1002}`).join('\n')
                                    let result = confirm(deleteMessage)
                                    if (result) {
                                        if (selectedRow.length === 0) {
                                            alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgEmptyPart'))
                                            return
                                        }

                                        const selectedPartInfo = selectedRow.map((target) => chartRawData.partInfoAll.find((item) => item.part_id === target.part_id))

                                        console.log(selectedPartInfo)

                                        if (selectedPartInfo.length > 0) {
                                            const deletePromises = selectedPartInfo.map((part) => dbPartApi.deletePart(user, part.part_id))

                                            await Promise.all(deletePromises)

                                            batch(() => {
                                                const removeIds = new Set(selectedPartInfo.map((item) => item.part_id))
                                                const currentData = dgInstance.option('dataSource')
                                                const removedData = currentData.filter((item) => !removeIds.has(item.part_id))
                                                dgInstance.option('dataSource', removedData)
                                                setDataSource(removedData)

                                                const removedPartList = chartRawData.partInfoAll.filter((item) => !removeIds.has(item.part_id))
                                                dispatchSetPartInfoAll(removedPartList)
                                                console.log('removed')
                                            })
                                        }
                                    }
                                }}
                            />
                        </Item>
                        <Item location='after'>
                            <Button
                                icon={expandAll === true ? 'chevrondown' : 'chevronright'}
                                onClick={() => handleExpandAllClicked(expandAll === true ? false : true)}
                            />
                        </Item>
                        <Item
                            key={'database-part-toolbar-column-chooser'}
                            name='columnChooserButton'
                            locateInMenu='never'
                            location='after'
                        />
                    </Toolbar>
                    <ColumnChooser
                        id='part-column-chooser-button'
                        enabled={true}
                        mode={'select'}
                        width={350}
                        height={500}
                        title={dqTranslateMsg('Page_Database_' + 'PartColumnChooserTitle')}
                        emptyPanelText={dqTranslateMsg('Page_Database_' + 'ColumnChooserEmptyPanelText')}
                    >
                        <Position
                            my='center'
                            at='center'
                            of={document.getElementById('partDiv')}
                        />
                        <ColumnChooserSearch
                            enabled={true}
                            editorOptions={{
                                placeholder: dqTranslateMsg('Page_Database_' + 'ColumnChooserPlaceHoler'),
                            }}
                        />

                        <ColumnChooserSelection
                            allowSelectAll={true}
                            selectByClick={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                    <SearchPanel
                        visible={true}
                        // maxWidth={'200px'}
                        placeholder={dqTranslateMsg('Page_Database_' + 'SearchPlaceHoler')}
                    />
                    <GroupPanel
                        visible={true}
                        emptyPanelText={dqTranslateMsg('Page_Database_' + 'EmptyPanelText')}
                    />
                </DataGrid>
            </div>
        </div>
    )
}
