import { FallbackProps } from 'react-error-boundary'
import { Button } from 'devextreme-react'

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className=" flex flex-col items-center justify-center px-3 pt-52">
      {error.message}
      <Button onClick={() => resetErrorBoundary()}>reset</Button>
    </div>
  )
}

export default ErrorFallback
