import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export function makeCapabilityIndexDataOne(char, value, info) {
  // Check if the value is truthy
  if (value) {
    return {
      charName: char.c2002,
      cp: info.cp,
      cpk: info.cpk,
    }
  }

  // Return default values if value is falsy
  return {
    charName: '-',
    cp: null,
    cpk: null,
  }
}
export const makeSymbol = (value, params, settings) => {
  // Retrieve the value from params
  const val = params.value

  // Determine the symbol size based on the importanceLine threshold
  if (val > settings.page.importanceLine) {
    return settings.lineChart.symbols.good.shape
  } else {
    return settings.lineChart.symbols.error.shape
  }
}

export const makeSymbolSize = (value, params, settings) => {
  // Retrieve the value from params
  const val = params.value

  // Determine the symbol size based on the importanceLine threshold
  if (val > settings.page.importanceLine) {
    return settings.lineChart.symbols.good.size
  } else {
    return settings.lineChart.symbols.error.size
  }
}

export const makeSymbolColor = (params, settings) => {
  // Retrieve the value from params
  const val = params.value

  // Determine the symbol color based on the importanceLine threshold
  if (val > settings.page.importanceLine) {
    return settings.lineChart.symbols.good.color
  } else {
    return settings.lineChart.symbols.error.color
  }
}

export const convertToChartLine = (target, settings) => {
  // Accessing the specific chart line settings using the target key
  const chartLineSettings = settings.lineChart.chartLines[target]

  // Constructing the chart line object
  const rtnChartLine = {
    type: chartLineSettings.type,
    width: chartLineSettings.width,
    color: chartLineSettings.color,
  }

  return rtnChartLine
}

export function makeSeriesData(settings, displayType, cpArray, cpkArray) {
  let seriesData = []

  if (displayType === 'barChart') {
    seriesData.push({
      type: 'bar',
      name: dqTranslateMsg('Page_CapabilitySummaryChart_' + 'CapabilityCp'),
      data: cpArray,
      itemStyle: {
        color: (params) => {
          if (params.value > settings.page.importanceLine) {
            return settings.barChart.cp?.good?.color
          } else {
            return settings.barChart.cp?.error?.color
          }
        },
        // color: 'rgba(100,100,100,1)',
      },
      emphasis: {
        focus: 'series',
      },
    })
    seriesData.push({
      type: 'bar',
      name: dqTranslateMsg('Page_CapabilitySummaryChart_' + 'CapabilityCpk'),
      data: cpkArray,
      itemStyle: {
        color: (params) => {
          if (params.value > settings.page.importanceLine) {
            return settings.barChart.cpk?.good?.color
          } else {
            return settings.barChart.cpk?.error?.color
          }
        },
      },
      emphasis: {
        focus: 'series',
      },
    })
  } else {
    seriesData.push({
      type: 'line',
      name: dqTranslateMsg('Page_CapabilitySummaryChart_' + 'CapabilityCp'),
      data: cpArray,
      symbol: (value, params) => makeSymbol(value, params, settings),
      symbolSize: (value, params) => makeSymbolSize(value, params, settings),
      animation: true,
      itemStyle: {
        color: (params) => makeSymbolColor(params, settings),
      },
      lineStyle: convertToChartLine('cp', settings),
    })
    seriesData.push({
      type: 'line',
      name: dqTranslateMsg('Page_CapabilitySummaryChart_' + 'CapabilityCpk'),
      data: cpkArray,
      symbol: (value, params) => makeSymbol(value, params, settings),
      symbolSize: (value, params) => makeSymbolSize(value, params, settings),
      animation: true,
      itemStyle: {
        color: (params) => makeSymbolColor(params, settings),
      },
      lineStyle: convertToChartLine('cpk', settings),
    })
  }

  seriesData.push({
    type: 'line',
    name: dqTranslateMsg('Page_CapabilitySummaryChart_' + 'Criteria'),
    markLine: {
      show: true,
      label: 'Criteria',
      precision: 10,
      animation: false,
      symbol: 'none',
      data: [
        {
          yAxis: settings.page.importanceLine,
          lineStyle: {
            type: settings.markLine.type,
            width: settings.markLine.width,
            color: settings.markLine.color,
          },
        },
      ],
    },
  })

  return seriesData
}
