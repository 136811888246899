import { CheckBox } from 'devextreme-react'
import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateUserInfoForSettings } from 'src/api/user'
import { useAuth } from 'src/contexts/auth'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ListSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItem'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'
import * as rd from 'src/store/common'

const DBFieldSavedFilter = (pageKey, pageState, configState, othersPage, setIsVisibleFilterPopup, setPopupDisplayFilter) => {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const savedFilters = othersPage.databaseSavedQuickFilterList
    const selectedFilterNo = [pageState.filters.quick.selectedFilterNo]

    const makeDataSourceSavedFilters = () => {
        const baseItem = { id: 0, filterName: 'Not Selected' }

        const filterListDataSource = savedFilters.map((item, index) => {
            return {
                id: index + 1,
                ...item,
            }
        })

        filterListDataSource.unshift(baseItem)

        return filterListDataSource
    }

    const handleSavedFiltersDelete = async (e) => {
        const deleteFilter = e.itemData
        if (deleteFilter.id === 0) {
            e.cancel = true
            return
        }

        const savedFilters = othersPage.databaseSavedQuickFilterList
        const selectedFilterNo = pageState.filters.quick.selectedFilterNo
        const filteredFilter = savedFilters.filter((filter) => filter.filterName !== deleteFilter.filterName)

        const next = produce(pageState, (draft) => {
            if (selectedFilterNo > filteredFilter.length) {
                draft.filters.quick.selectedFilterNo = 0
            }
        })

        dispatch(ps[pageKey].setPageSettingsToRedux(next))
        dispatch(rd.setDatabaseSavedQuickFilterList(filteredFilter))
        await updateUserInfoForSettings(user.user_id, 'chart_config_29', JSON.stringify(filteredFilter))
    }

    const handleFilterClicked = (e) => {
        console.log('Filter Clicked')
        const clickedFilter = e.itemData
        if (clickedFilter.id === 0) {
            return
        }
        setIsVisibleFilterPopup(true)
        setPopupDisplayFilter(clickedFilter)
    }

    const onSelectionChanged = (e) => {
        if (e.addedItems.length > 0) {
            const selectedItem = e.addedItems[0]
            // setSelectedItemKeys(value)
            const next = produce(pageState, (draft) => {
                draft.filters.quick.selectedFilterNo = selectedItem.id
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }
    }

    const onSelectedItemKeysChange = ({ name, value }) => {
        if (name === 'selectedItemKeys') {
            // setSelectedItemKeys(value)
            const next = produce(pageState, (draft) => {
                draft.filters.quick.selectedFilterNo = value[0].id
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }
    }

    const renderSavedFilters = (itemData) => {
        const filterName = itemData.filterName
        const checkBoxValue = selectedFilterNo === itemData.id

        const handleCheckBoxClicked = (e) => {
            console.log(e)

            const next = produce(pageState, (draft) => {
                draft.filters.advanced.selectedFilterNo = itemData.id
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }

        return (
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '5px' }}>
                <div style={{ width: '10%', height: '100%', textAlign: 'center' }}></div>
                <CheckBox
                    value={checkBoxValue}
                    onValueChanged={handleCheckBoxClicked}
                />
                {/* <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>{itemData.id + 1}</div> */}
                <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>:</div>
                <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>{filterName}</div>
            </div>
        )
    }

    return (
        <GroupItem
            key={`db-field-quick-saved-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'SavedFilterList')}
        >
            {ListSimpleItem({
                colSpan: 2,
                label: '',
                labelLocation: 'top',
                dataSource: makeDataSourceSavedFilters(),
                itemRender: null,
                allowItemDeleting: true,
                onItemClick: handleFilterClicked,
                itemDeleteMode: 'static',
                noDataText: dqTranslateMsg('SidePanel_' + 'NoData'),
                // onItemDeleted: handleSavedFiltersDelete,
                onItemDeleting: handleSavedFiltersDelete,
                onReorder: null,
                showSelectionControls: true,
                selectionMode: 'single',
                selectedItemKeys: selectedFilterNo,
                selectByClick: false,
                keyExpr: 'id',
                displayExpr: 'filterName',
                // onOptionChanged: onSelectedItemKeysChange,
                onSelectionChanged: onSelectionChanged,
                backgroundCondition: true,
            })}
        </GroupItem>
    )
}

export default DBFieldSavedFilter
