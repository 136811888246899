// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}
.dx-theme-background-color .company-info-image-container {
  width: 100%;
  height: 100%;
  padding: 100px;
}`, "",{"version":3,"sources":["webpack://./src/pages/info-company/components/main-view/CompanyInfoForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,cAAA;AACJ","sourcesContent":[".dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n\r\n  .company-info-image-container {\r\n    width: 100%;\r\n    height: 100%;\r\n    padding: 100px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
