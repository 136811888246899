// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { companySettings } from 'src/pages/admin/components/settings/CompanySettings'
import { licenseSettings } from 'src/pages/admin/components/settings/LicenseSettings'
import { configurationSettings } from 'src/pages/config/components/settings/ConfigSettings'
import { baseSettings } from 'src/pages/settings/BaseSettings'
import { pageSettings } from 'src/pages/settings/PageSettings'
const initialState = {
    mode: '',
    states: {
        database: {
            selectedDataList: {
                partInfo: [],
                charInfo: [],
            },
        },
    },
    loaded_all: {
        overview: undefined,
        main: undefined,
        valueChart: undefined,
        histogramChart: undefined,
        positionalTolerancesChart: undefined,
        boxPlotChart: undefined,
        linearRegressionChart: undefined,
        correlationChart: undefined,
        qualityControlChart: undefined,
        capabilitySummaryChart: undefined,
        probabilityPlotChart: undefined,
        paretoChart: undefined,
        valueList: undefined,
        valueSummaryList: undefined,
        summaryList: undefined,
        reportPrint: undefined,
        decisionTreeChart: undefined,
        reportPrintMultiFunction: undefined,
    },
    decisionAddedArray: {
        data: [],
    },
    current_sel: {},
    company: companySettings,
    base: baseSettings,
    pages: pageSettings,
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setInitialization: (state, action) => {
            return initialState
        },
        setMode: (state, action) => {
            state.mode = action.payload
        },
        setLoadedAll: (state, action) => {
            state.loaded_all = action.payload
        },
        setDecisionTreeAddArray: (state, action) => {
            state.decisionAddedArray = action.payload
        },
        setCurrentSel: (state, action) => {
            state.current_sel = action.payload
        },
        setLicense: (state, action) => {
            state.pages = action.payload
        },
        setCompany: (state, action) => {
            state.company = action.payload
        },
        setConfiguration: (state, action) => {
            state.pages.configuration = action.payload
        },
        setLanguage: (state, action) => {
            state.base.language = action.payload
        },
        setBaseTheme: (state, action) => {
            state.pages.configuration.theme = action.payload
        },
        setDatabasePage: (state, action) => {
            state.pages.database = action.payload
        },
        setOverviewPage: (state, action) => {
            state.pages.overview = action.payload
        },
        setMonitoringPage: (state, action) => {
            state.pages.monitoring = action.payload
        },
        setValueChartPage: (state, action) => {
            state.pages.valueChart = action.payload
        },
        setHistogramChartPage: (state, action) => {
            state.pages.histogramChart = action.payload
        },
        setPTChartPage: (state, action) => {
            state.pages.positionalTolerancesChart = action.payload
        },
        setBoxPlotChartPage: (state, action) => {
            state.pages.boxPlotChart = action.payload
        },
        setLRChartPage: (state, action) => {
            state.pages.linearRegressionChart = action.payload
        },
        setCorrChartPage: (state, action) => {
            state.pages.correlationChart = action.payload
        },
        setQCChartPage: (state, action) => {
            state.pages.qualityControlChart = action.payload
        },
        setCSChartPage: (state, action) => {
            state.pages.capabilitySummaryChart = action.payload
        },
        setParetoChartPage: (state, action) => {
            state.pages.paretoChart = action.payload
        },
        setProbPlotChartPage: (state, action) => {
            state.pages.probabilityPlotChart = action.payload
        },
        setDecisionTreeChartPage: (state, action) => {
            state.pages.decisionTreeChart = action.payload
        },
        setSummaryListPage: (state, action) => {
            state.pages.summaryList = action.payload
        },
        setValueListPage: (state, action) => {
            state.pages.valueList = action.payload
        },
        setScrollBoardPage: (state, action) => {
            state.pages.scrollBoard = action.payload
        },
        setReportPrintPage: (state, action) => {
            state.pages.reportPrint = action.payload
        },
        setVSListPage: (state, action) => {
            state.pages.valueSummaryList = action.payload
        },
        setAdminSettingsPage: (state, action) => {
            state.pages.adminSettings = action.payload
        },
        setReportMultiFunctionPage: (state, action) => {
            state.pages.reportPrintMultiFunction = action.payload
        },
        setLanguageObj: (state, action) => {
            state.pages.adminSettings.language = action.payload
        },
        setQSense: (state, action) => {
            state.pages.configuration.encoding = action.payload
        },
        setEncoding: (state, action) => {
            state.pages.others.encoding = action.payload
        },
        setUseFieldList: (state, action) => {
            state.pages.others.useFieldList = action.payload
        },
        setCatalogs: (state, action) => {
            state.pages.others.catalogs = action.payload
        },
        setDBConfig: (state, action) => {
            state.pages.others.dbConfig = action.payload
        },
        setRibbon: (state, action) => {
            state.pages.others.ribbon = action.payload
        },
        setPermissions: (state, action) => {
            state.pages.others.permissions = action.payload
        },
        setLanguageData: (state, action) => {
            state.pages.others.language = action.payload
        },
        setAllPages: (state, action) => {
            state.pages = action.payload
        },
        setAllBase: (state, action) => {
            state.base = action.payload
        },
        setDataBaseStateDataList: (state, action) => {
            state.pages.database.selectedDataList = action.payload
        },
        setDatabaseSavedQuickFilterList: (state, action) => {
            state.pages.others.databaseSavedQuickFilterList = action.payload
        },
        setDatabaseSavedAdvancedFilterList: (state, action) => {
            state.pages.others.databaseSavedAdvancedFilterList = action.payload
        },
    },
})

export const {
    // setPage,
    setInitialization,
    setMode,
    setLoadedAll,
    setUpdateFlag,
    setDecisionTreeAddArray,
    setCurrentSel,
    setUsePartFieldList,
    setUseCharFieldList,
    setUseValueFieldList,
    setLicense,
    setCompany,
    setConfiguration,
    setLanguage,
    setBaseTheme,
    setDatabasePage,
    setOverviewPage,
    setMonitoringPage,
    setValueChartPage,
    setHistogramChartPage,
    setPTChartPage,
    setBoxPlotChartPage,
    setLRChartPage,
    setCorrChartPage,
    setQCChartPage,
    setCSChartPage,
    setParetoChartPage,
    setProbPlotChartPage,
    setDecisionTreeChartPage,
    setSummaryListPage,
    setValueListPage,
    setScrollBoardPage,
    setReportPrintPage,
    setVSListPage,
    setAdminSettingsPage,
    setReportMultiFunctionPage,
    setLanguageObj,
    setQSense,
    setEncoding,
    setUseFieldList,
    setCatalogs,
    setDBConfig,
    setRibbon,
    setPermissions,
    setLanguageData,
    setAllPages,
    setAllBase,
    setDataBaseStateDataList,
    setDatabaseSavedQuickFilterList,
    setDatabaseSavedAdvancedFilterList,
} = commonSlice.actions

export default commonSlice.reducer
