export { default as ProfilePage } from './profile/profile'
export { default as TasksPage } from './tasks/tasks'

// DQ Added Page
export { default as OverviewPage } from './overview/overview'
export { default as MonitoringPage } from './monitoring/monitoring'
export { default as ListValueSummaryPage } from './list-value-summary/list-value-summary'
export { default as ListValuePage } from './list-value/list-value'
export { default as ListSummaryPage } from './list-summary/list-summary'
export { default as InfoCompanyPage } from './info-company/info-company'
export { default as DatabasePage } from './database/database'
export { default as ChartValuePage } from './chart-value/chart-value'
export { default as ChartQCCPage } from './chart-quality-control/chart-quality-control'
export { default as ChartPositionalTolerancesPage } from './chart-positional-tolerances/chart-positional-tolerances'
export { default as ChartLinearRegressionPage } from './chart-linear-regression/chart-linear-regression'
export { default as ChartHistogramPage } from './chart-histogram/chart-histogram'
export { default as ChartCorrelationPage } from './chart-correlation/chart-correlation'
export { default as ChartBoxPlotPage } from './chart-box-plot/chart-box-plot'
export { default as ProbabilityPlotChartPage } from './chart-probability-plot/chart-probability-plot'
export { default as ParetoChartPage } from './chart-pareto/chart-pareto'
export { default as CapabilitySummaryChartPage } from './chart-capability-summary/chart-capability-summary'
export { default as DecisionTreeChartPage } from './chart-decision-tree/chart-decision-tree'
export { default as ScrollBoardPage } from './scroll-board/scroll-board'
export { default as ReportPrintPage } from './report-print/report-print'
export { default as ReportPrintMultiFunctionPage } from './report-print-multi-function/report-print-multi-function'
export { default as UploadFilePage } from './upload-file/upload-file'
export { default as AdminPage } from './admin/admin'
export { default as ConfigPage } from './config/config'
export { default as DevelopmentFunctionPage } from './development-functions/development-functions'
