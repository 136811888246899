import Cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'
import { Button, Form, LoadPanel, Popup, TextArea, Toast } from 'devextreme-react'
import { Label, NumericRule, RequiredRule, SimpleItem } from 'devextreme-react/form'
import ReactECharts from 'echarts-for-react'
import produce from 'immer'
import { useEffect, useMemo, useRef, useState } from 'react'
import CytoscapeComponent from 'react-cytoscapejs'
import { batch } from 'react-redux'
import { getDecisionTree } from 'src/api/decisionTree'
import ApiStatus from 'src/api/defines/ApiStatus'
import { useLanguage } from 'src/contexts/languages'
import { useTheme } from 'src/contexts/theme'
import CCUtils from 'src/functions/CommonCalcUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './WorkFlowCytoscapeChart.scss'
import { makeOption } from './WorkFlowCytoscapeChartImpl'
import { DecisionTreeValueChart } from './WorkFlowValueChart'
import { svg_string } from './tests/NodeSvg'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
import DQToast from 'src/functions/DQToast'

Cytoscape.use(dagre)

export const WorkflowCytoscapeChart = (props) => {
    const { getTheme } = useTheme()
    const { language } = useLanguage()
    const cartRef = useRef(null)
    const rfChartRef = useRef(null)
    const formRef = useRef(null)
    const toastRef = useRef(null)

    // For initialRendering
    const isInitialRender = useRef(true)

    const { pageKey, common, chartRawData } = props

    const [isLoadingPanel, setIsLoadingPanel] = useState(false)
    const [importanceChartOption, setImportanceChartOption] = useState({
        language: language,
        popup: {
            show: false,
            params: {
                part_id: 0,
                char_id: 0,
            },
            data: [],
        },
    })
    const [importance, setImportance] = useState({
        importance: [],
        rf_importance: [],
    })
    const [columnsName, setColumnsName] = useState([])
    const [targetName, setTargetName] = useState('')
    const [elements, setElements] = useState([])
    const [targetInfo, setTargetInfo] = useState(null)
    const [resultInfo, setResultInfo] = useState('')

    const decisionAddCharArray = common.decisionAddedArray.data

    const { parts, chars, values } = useMemo(() => CPUtils.loadRefChartData(chartRawData), [chartRawData])

    const { charArray, curPage, configPage, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)

    const configSettings = configPage
    const valueChartSettings = common.pages.valueChart

    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)

    const selectedAddedValueWithDummy = useMemo(() => CPUtils.checkMatchCharsAndValues(decisionAddCharArray, values), [values, charArray])
    const valuesAddedArranged = useMemo(() => CPUtils.removeDummyInValues(selectedAddedValueWithDummy), [selectedValueWithDummy])

    let data = 'data:image/svg+xml;utf8,' + encodeURIComponent(svg_string)

    function findHighestNode(cy) {
        // Assuming a tree structure, the root is the node with no incoming edges
        return cy.nodes().filter((node) => node.indegree() === 0)[0]
    }

    function findPathToHighestNodeByNominal(cy, nominalValue, highestNode) {
        let closestNode = null

        let currentNode = highestNode

        let pathNodes = cy.collection() // Collection to store the traversed nodes
        let pathEdges = cy.collection() // Collection to store the traversed edges

        while (currentNode.length) {
            pathNodes = pathNodes.add(currentNode) // Add current node to path
            let nextDepthNodes = currentNode.outgoers().nodes()
            let foundCloserNode = false
            let minDifference = Infinity

            nextDepthNodes.forEach((node) => {
                let nodeNominal = node.data('mean') // Assuming 'nominal' data attribute
                let difference = Math.abs(nominalValue - nodeNominal)

                if (difference < minDifference) {
                    minDifference = difference
                    closestNode = node
                    foundCloserNode = true
                }
            })

            if (!foundCloserNode) break

            // Add the edge leading to the closest node to the path
            let connectingEdge = currentNode.edgesTo(closestNode)
            pathEdges = pathEdges.add(connectingEdge)

            currentNode = closestNode // Move to the closer node for the next iteration
        }

        return {
            nodes: pathNodes,
            edges: pathEdges,
        }
    }

    function findPathToHighestNodeByClick(cy, clickedNode, highestNode) {
        let pathNodes = cy.collection()
        let pathEdges = cy.collection()

        let currentNode = clickedNode

        // Traverse up the tree from the clicked node to the root
        while (currentNode !== highestNode) {
            pathNodes = pathNodes.add(currentNode)

            // Get the edge connecting to the parent and add to the path
            let connectingEdge = currentNode.connectedEdges(function (edge) {
                return edge.target().same(currentNode)
            })
            pathEdges = pathEdges.add(connectingEdge)

            // Move to the parent node
            currentNode = connectingEdge.source()[0]
        }

        // Add the highest node to the path
        pathNodes = pathNodes.add(highestNode)

        return { nodes: pathNodes, edges: pathEdges }
    }

    function highlightPath(pathNodes, pathEdges) {
        pathNodes.style('border-color', 'red') // Example style
        pathEdges.style('line-color', 'red') // Example style
    }

    function removeEntireHighlight(cy) {
        cy.nodes().style('border-color', 'black')
        cy.edges().style('line-color', 'black')
    }

    // useEffect(() => {
    function handleStartCartProc() {
        const convertToCytoscapeElements = (cartPrms) => {
            const before = cartPrms
            const after = []
            before.forEach((prms) => {
                // node
                if (prms.isLeaves) {
                    after.push({
                        data: {
                            no: prms.no,
                            id: prms.label,
                            label: `Terminal Node ${prms.no + 1}` + '\n\n' + `Mean = ${DNUtils.calcValueDisplay(prms.value[0][0], configSettings)}` + '\n\n' + `Count = ${prms.n_node_samples}`,
                            isLeaves: prms.isLeaves,
                            mean: prms.value[0][0],
                            feature: prms.feature,
                            threshold: prms.threshold,
                        },
                    })
                } else {
                    after.push({
                        data: {
                            no: prms.no,
                            id: prms.label,
                            label: `Node ${prms.no + 1}` + '\n\n' + `Mean = ${DNUtils.calcValueDisplay(prms.value[0][0], configSettings)}` + '\n\n' + `Count = ${prms.n_node_samples}`,
                            isLeaves: prms.isLeaves,
                            mean: prms.value[0][0],
                            isLeft: prms.childrenLeft ? true : false,
                            feature: prms.feature,
                            threshold: prms.threshold,
                        },
                    })
                    if (prms.childrenLeft > 0) {
                        after.push({
                            data: {
                                id: `Edge${prms.childrenLeft}`,
                                source: prms.label,
                                target: `Node${prms.childrenLeft}`,
                                label: `X${prms.feature + 1} <= ${prms.threshold}`,
                            },
                        })
                    }
                    if (prms.childrenRight > 0) {
                        after.push({
                            data: {
                                id: `Edge${prms.childrenRight}`,
                                source: prms.label,
                                target: `Node${prms.childrenRight}`,
                                label: `X${prms.feature + 1} > ${prms.threshold}`,
                            },
                        })
                    }
                }
            })
            return after
        }
        const makeCartParameter = (data) => {
            /**
             * [data]
             * feature / is_leaves / left / right / n_nodes / node_depth / right
             * threshold / values /
             */
            // Assuming clf has all the tree attributes as properties
            // node info
            let n_nodes = data.n_nodes
            let n_node_samples = data.n_node_samples
            let children_left = data.children_left
            let children_right = data.children_right
            let feature = data.feature
            let threshold = data.threshold
            let values = data.values
            let node_depth = data.node_depth
            let is_leaves = data.is_leaves
            let criterion = data.criterion
            let impurity = data.impurity

            // result
            let ct_mse = data.mse
            let ct_r_mse = data.r_mse
            let ct_r_squared = data.r_squared
            let rf_mse = data.rf_mse
            let rf_r_mse = data.rf_r_mse
            let rf_r_squared = data.rf_r_squared

            let stack = [[0, 0]] // Start with the root node id (0) and its depth (0)

            while (stack.length > 0) {
                let nodeInfo = stack.pop()
                let node_id = nodeInfo[0]
                let depth = nodeInfo[1]
                node_depth[node_id] = depth
                let is_split_node = children_left[node_id] != children_right[node_id]
                if (is_split_node) {
                    stack.push([children_right[node_id], depth + 1])
                    stack.push([children_left[node_id], depth + 1])
                } else {
                    is_leaves[node_id] = true
                }
            }

            let el = []
            for (let i = 0; i < n_nodes; i++) {
                el.push({
                    no: i,
                    label: `Node${i}`, // start from 1
                    isLeaves: is_leaves[i],
                    value: values[i],
                    threshold: threshold[i],
                    feature: feature[i],
                    childrenLeft: children_left[i],
                    childrenRight: children_right[i],
                    impurity: impurity[i],
                    n_node_samples: n_node_samples[i],
                    criterion: criterion,
                })
            }

            const info = {
                common: {
                    n_nodes: n_nodes,
                    isLeave: is_leaves,
                    children_left: children_left,
                    feature: feature,
                    threshold: threshold,
                },
                cart: {
                    mse: ct_mse,
                    r_mse: ct_r_mse,
                    r_squared: ct_r_squared,
                },
                randomForest: {
                    mse: rf_mse,
                    r_mse: rf_r_mse,
                    r_squared: rf_r_squared,
                },
            }
            return { el, info }
        }

        const makeSrcData = (valuesAddedArranged, selectedValue, decisionAddCharArray, targetValue, isOriTarget) => {
            // Helper function to append shiftUpField to the charRaw.c2002 value if needed
            const appendShiftUpField = (char) => `${char.c2002}(${char.dataField})`

            // Initial structure combining selectedValue names and data
            let columns = selectedValue.map((value) => ({
                name: value.charRaw.c2002,
                data: value.valueRaw.map((val) => val.v0001),
            }))

            // Add decisionAddCharArray data, subtracting from existing columns if names match
            decisionAddCharArray.forEach((char, index) => {
                const columnName = appendShiftUpField(char)
                const tgValue = values.find((value) => value.part_id === char.adjustedPartId && value.char_id === char.adjustedCharId)
                const columnData = tgValue.valueRaw.map((value) => value[char.dataField])

                const existingColumnIndex = columns.findIndex((column) => column.name === columnName)
                if (existingColumnIndex !== -1) {
                    // Subtract columnData from existing data if column names match
                    columns[existingColumnIndex].data = columns[existingColumnIndex].data
                } else {
                    columns.push({ name: columnName, data: columnData })
                }
            })

            // Optionally adding targetValue as an additional column
            let targetColumnName
            let targetColumnData
            if (!isOriTarget) {
                targetColumnName = appendShiftUpField(targetValue)
                const tgValue = values.find((value) => value.part_id === targetValue.adjustedPartId && value.char_id === targetValue.adjustedCharId)
                targetColumnData = tgValue.valueRaw.map((value) => value[targetValue.dataField])
                columns.push({ name: targetColumnName, data: targetColumnData })
            }

            // Filter function to handle columns with the same name, if needed
            columns = columns.reduce((acc, current) => {
                const existingColumnIndex = acc.findIndex((col) => col.name === current.name)
                if (existingColumnIndex !== -1) {
                    // Remove the existing column if found
                    acc.splice(existingColumnIndex, 1)
                }
                // Add the current column (either it's a new name or replacing a duplicate)
                acc.push(current)
                return acc
            }, [])

            // Separating names and data for the return structure
            const columnsName = columns.map((column) => column.name)
            // const columnsData = columns.map((column) => column.data)
            const columnsData = columns.map((column) => {
                return column.data.filter((item) => item !== null)
            })

            // Target column name and data based on isOriTarget flag
            const targetName = isOriTarget ? targetValue.charRaw.c2002 : targetColumnName
            let targetData
            if (isOriTarget) {
                const nullable = targetValue.valueRaw.map((val) => val.v0001)
                targetData = nullable.filter((item) => item !== null)
            } else {
                targetData = targetColumnData.filter((item) => item !== null)
            }

            return { columnsData, columnsName, targetData, targetName }
        }

        const makeResultStr = (data, nominal) => {
            let bestClassifiedNode = 0
            let strBestClassifiedNode = ''
            const findBestClassifiedNode = (data, nominal) => {
                const { n_nodes, feature, threshold, children_left, children_right, values } = data

                let currentNode = 0 // Start at the root node
                while (currentNode !== -1) {
                    if (feature[currentNode] === -2) {
                        break // Stop the loop; we've found our leaf node
                    }

                    const leftDifference = Math.abs(nominal - values[children_left[currentNode]][0])
                    const rightDifference = Math.abs(nominal - values[children_right[currentNode]][0])
                    if (leftDifference <= rightDifference) {
                        // Go left
                        console.log(`Terminal Node ${currentNode + 1}`)
                        currentNode = children_left[currentNode]
                    } else {
                        // Go right
                        console.log(`Terminal Node ${currentNode + 1}`)
                        currentNode = children_right[currentNode]
                    }
                }
                strBestClassifiedNode = `Terminal Node ${currentNode + 1}`
                return currentNode
            }

            const makeStrBestExpression = (data, bestClassifiedNode) => {
                const { feature, threshold, children_left, children_right } = data

                // 노드의 부모를 찾기 위한 맵 생성
                let nodeParents = {}
                for (let i = 0; i < feature.length; i++) {
                    if (children_left[i] !== -1) {
                        nodeParents[children_left[i]] = i
                    }
                    if (children_right[i] !== -1) {
                        nodeParents[children_right[i]] = i
                    }
                }

                // bestClassifiedNode부터 루트까지 역추적하여 경로 생성
                let path = []
                let currentNode = bestClassifiedNode
                while (nodeParents[currentNode] !== undefined) {
                    const parentNode = nodeParents[currentNode]
                    const direction = children_left[parentNode] === currentNode ? 'left' : 'right'
                    path.unshift({ node: parentNode, direction: direction })
                    currentNode = parentNode
                }

                // 경로를 따라 조건 수집
                let conditions = {}
                path.forEach(({ node, direction }) => {
                    const f = feature[node]
                    const th = threshold[node]
                    const featureKey = `X${f + 1}`

                    if (!conditions[featureKey]) {
                        conditions[featureKey] = []
                    }
                    conditions[featureKey].push({ direction, threshold: th })
                })

                // 같은 feature에 대한 조건 병합
                let mergedConditions = []
                Object.keys(conditions).forEach((key) => {
                    const cond = conditions[key]
                    let min = null,
                        max = null

                    cond.forEach(({ direction, threshold }) => {
                        if (direction === 'left' && (min === null || parseFloat(threshold) < parseFloat(min))) {
                            min = DNUtils.calcValueDisplay(threshold, configSettings)
                        }
                        if (direction === 'right' && (max === null || parseFloat(threshold) > parseFloat(max))) {
                            max = DNUtils.calcValueDisplay(threshold, configSettings)
                        }
                    })

                    if (min !== null && max !== null) {
                        mergedConditions.push(`${max} < ${key} <= ${min}`)
                    } else if (min !== null) {
                        mergedConditions.push(`${key} <= ${min}`)
                    } else if (max !== null) {
                        mergedConditions.push(`${key} > ${max}`)
                    }
                })

                let str = ''
                mergedConditions.forEach((item) => {
                    str += `  ${item}` + '\n'
                })

                return str
            }

            let str = ''
            str += '[Cart Regressor]' + '\n'
            str += '  MSE : ' + DNUtils.calcValueDisplay(data.mse, configSettings) + '\n'
            str += '  RMSE : ' + DNUtils.calcValueDisplay(data.r_mse, configSettings) + '\n'
            str += '  R-squared : ' + DNUtils.calcValueDisplay(data.r_squared, configSettings) + '\n\n'

            str += '[Random Forest Regressor]' + '\n'
            str += '  MSE : ' + DNUtils.calcValueDisplay(data.rf_mse, configSettings) + '\n'
            str += '  RMSE : ' + DNUtils.calcValueDisplay(data.rf_r_mse, configSettings) + '\n'
            str += '  R-squared : ' + DNUtils.calcValueDisplay(data.rf_r_squared, configSettings) + '\n\n'

            // "Best Classified node"와 "Best Expression" 섹션을 추가합니다.
            str += '[Best Classified node]' + '\n'
            bestClassifiedNode = findBestClassifiedNode(data, nominal)
            str += strBestClassifiedNode + '\n\n'
            str += '[Best Expression]' + '\n'
            str += makeStrBestExpression(data, bestClassifiedNode) + '\n'

            return str
        }

        const makeParametersStr = (columns, target) => {
            let str = ''
            str += '[Feature Parameters]' + '\n'

            const featureList = columns.filter((column) => column !== target)

            featureList.forEach((feature, index) => {
                str += `  X${index + 1} : ${feature}` + '\n'
            })

            str += '\n'
            str += '[Target Parameter]' + '\n'
            str += `  ${target}` + '\n'

            return str
        }

        // check target data validation
        if (!formRef.current.instance.validate().isValid) {
            dqTranslateMsg('Page_DecisionTreeChart_' + 'ErrorMsgInvalidNominalValue')
            return
        }

        const oriTargetValue = valuesArranged.find((value) => value.part_id === currentSel.part_id && value.char_id === currentSel.char_id)

        const addTargetValue = decisionAddCharArray.find((value) => {
            if (value.Part_ID === currentSel.part_id && value.Char_ID === currentSel.char_id) {
                return value
            }
        })

        const targetValue = oriTargetValue ? oriTargetValue : addTargetValue

        setIsLoadingPanel(true)

        if (targetValue) {
            const srcData = makeSrcData(valuesAddedArranged, valuesArranged, decisionAddCharArray, targetValue, oriTargetValue ? true : false)
            const cartSet = curPage.page
            if (srcData.columnsName.length > 1 && srcData.columnsData.length > 1 && srcData.targetName && cartSet) {
                getDecisionTree(srcData, cartSet).then((result) => {
                    console.log(result)
                    if (!ApiStatus.isSuccess(result.status)) {
                        alert(getErrCommMsg(result.status))
                    }

                    const recvData = result.data
                    const { el, info } = makeCartParameter(recvData)
                    const newElements = convertToCytoscapeElements(el)

                    if (cartRef.current) {
                        // cart flow chart
                        cartRef.current.elements().remove()
                        cartRef.current.add(newElements)

                        const cy = cartRef.current
                        const highestNode = findHighestNode(cy)
                        const path = findPathToHighestNodeByNominal(cy, targetInfo.nominal, highestNode)
                        removeEntireHighlight(cy)
                        highlightPath(path.nodes, path.edges)
                        cartRef.current.on('tap', 'node', function (event) {
                            let clickedNode = event.target

                            const cy = cartRef.current
                            let highestNode = findHighestNode(cy)
                            let path = findPathToHighestNodeByClick(cy, clickedNode, highestNode)

                            removeEntireHighlight(cy)
                            highlightPath(path.nodes, path.edges)
                        })
                        cartRef.current.layout({ name: 'dagre' }).run()

                        const importance = {
                            importance: result.data.importance,
                            rf_importance: result.data.rf_importance,
                        }

                        const targetName = srcData.targetName
                        const columnsName = srcData.columnsName.filter((item) => item !== srcData.targetName)

                        // random forest
                        if (rfChartRef && rfChartRef.current) {
                            const newChartOption = makeOption(importance, columnsName, targetName, curPage, configSettings)

                            const rfChartInstance = rfChartRef.current.getEchartsInstance()

                            rfChartInstance.setOption(newChartOption)

                            batch(() => {
                                setElements(newElements)
                                setImportance(importance)
                                setColumnsName(columnsName)
                                setTargetName(targetName)
                                setResultInfo(makeResultStr(recvData, targetInfo.nominal) + makeParametersStr(srcData.columnsName, srcData.targetName))
                            })
                        }
                    }

                    setIsLoadingPanel(false)
                })
            } else {
                alert(dqTranslateMsg('Page_DecisionTreeChart_' + 'ErrorMsgRackOfChar'))
                setIsLoadingPanel(false)
            }
        } else {
            alert(dqTranslateMsg('Page_DecisionTreeChart_' + 'ErrorMsgInvalidTarget'))
            setIsLoadingPanel(false)
        }
    }

    const layout = {
        name: 'dagre',
        animate: true, // whether to transition the node positions
        animationDuration: 300, // duration of animation in ms if enabled
    }

    const cytoscapeStylesheet = [
        {
            selector: 'node',
            style: {
                'background-color': '#1976d2',
                'background-image': 'url(' + data + ')',
                width: 142,
                height: 91,
                shape: 'rectangle',
                color: '#828282',
            },
        },
        {
            selector: 'node[label]',
            style: {
                label: 'data(label)',
                'font-size': '12',
                // 'text-margin-y': 25,
                color: 'black',
                'text-halign': 'center',
                'text-valign': 'center',
                'text-wrap': 'wrap',
                'text-overflow-wrap': 'anywhere',
                'text-justification': 'center',
                'border-width': 3,
                'border-color': 'black',
            },
        },
        {
            selector: 'edge',
            style: {
                'curve-style': 'bezier',
                'target-arrow-shape': 'triangle',
                width: 1,
                color: 'black',
                'border-width': 3,
                'border-color': 'black',
                'line-color': 'black',
            },
        },
        {
            selector: 'edge[label]',
            style: {
                label: 'data(label)',
                'font-size': '8',
                'text-background-color': 'white',
                'text-background-opacity': 1,
                'text-background-padding': '2px',
                'text-margin-y': -4,
                // so the transition is selected when its label/name is selected
                'text-events': 'yes',
                'border-width': 3,
                'border-color': 'black',
                'line-color': 'black',
            },
        },
    ]

    function handleFitChart() {
        if (cartRef.current) {
            cartRef.current.fit()
            // cartRef.current.resize()
        }
    }

    function handleRedraw() {
        if (cartRef.current) {
            cartRef.current.elements().remove()
            cartRef.current.add(elements)
            cartRef.current.layout({ name: 'dagre' }).run()
        }
    }

    useEffect(() => {
        const target = values.find((values) => values.part_id === currentSel.part_id && values.char_id === currentSel.char_id)
        const addTarget = values.find((values) => values.part_id === currentSel.part_id - currentSel.index * 1000000 && values.char_id === currentSel.char_id - currentSel.index * 1000000)

        if (!target && !addTarget) {
            alert(dqTranslateMsg('Page_DecisionTreeChart_' + 'ErrorMsgChooseTarget'))
            return
        }

        const charRaw = addTarget ? addTarget.charRaw : target.charRaw
        const charName = charRaw.c2002
        const tgValueRaw = addTarget ? addTarget.valueRaw : target.valueRaw
        const tgValueField = addTarget ? currentSel.dataField : 'v0001'
        const v0001s = tgValueRaw.map((value) => value[tgValueField])
        // const decimalPlaces = charRaw.c2022 ? charRaw.c2022 : configSettings.commonSettings.calcDisplayDecimalPlaces
        const decimalPlaces = DNUtils.getValueDecimalPlaces(charRaw, configSettings)

        const lsl = DNUtils.calcValueDisplay(PCUtils.getLsl(charRaw), configSettings)
        const usl = DNUtils.calcValueDisplay(PCUtils.getUsl(charRaw), configSettings)
        const nominal = DNUtils.calcValueDisplay(PCUtils.getNominal(charRaw), configSettings)
        const min = DNUtils.calcValueDisplay(CCUtils.getMin(v0001s), configSettings)
        const max = DNUtils.calcValueDisplay(CCUtils.getMax(v0001s), configSettings)
        const { sum, length } = PCUtils.getSum(v0001s)
        const average = DNUtils.calcValueDisplay(PCUtils.getAverage(sum, length), configSettings)

        const targetInfo = {
            name: charName,
            usl: usl,
            lsl: lsl,
            nominal: nominal,
            min: min,
            max: max,
            average: average,
        }
        setTargetInfo(targetInfo)
    }, [common])

    const option = makeOption(importance, columnsName, targetName, curPage, configSettings)

    function handleImportanceChartClick(chartData) {
        const tgValue = valuesArranged.find((value) => value.charRaw.c2002 === chartData.name)

        if (tgValue) {
            const next = produce(importanceChartOption, (draft) => {
                draft.popup.show = true
                draft.popup.params.part_id = tgValue.charRaw.part_id
                draft.popup.params.char_id = tgValue.charRaw.char_id
                draft.popup.data = tgValue
            })

            setImportanceChartOption(next)
        }
    }
    const hide = () => {
        const next = produce(importanceChartOption, (draft) => {
            draft.popup.show = false
            draft.popup.params.part_id = 0
            draft.popup.params.char_id = 0
            draft.popup.data = []
        })

        setImportanceChartOption(next)
    }

    // useEChartsResize(rfChartRef)
    return (
        <div className='dx-theme-background-color'>
            <DQLoadPanel
                open={isLoadingPanel}
                message={dqTranslateMsg('Common_' + 'LoadPanelLoading')}
            />
            <DQToast ref={toastRef} />
            {importanceChartOption.popup.show ? (
                <Popup
                    visible={importanceChartOption.popup.show}
                    onHiding={hide}
                    hideOnOutsideClick={true}
                    showCloseButton={true}
                    title='Value Chart'
                    width={800}
                    height={500}
                >
                    <DecisionTreeValueChart
                        pageKey={pageKey}
                        pageSettings={valueChartSettings}
                        configSettings={configSettings}
                        language={language}
                        targetData={importanceChartOption.popup.data}
                    />
                </Popup>
            ) : null}
            <div
                className='workflow-cytoscape-header-container'
                style={{ padding: '5px' }}
            >
                <div className='workflow-left-button'>
                    <Button
                        // className="workflow-button-fit-content"
                        icon='repeat'
                        text={dqTranslateMsg('Page_DecisionTreeChart_' + 'FitContent')}
                        hoverStateEnabled={false}
                        onClick={handleFitChart}
                    />
                    <Button
                        // className="workflow-button-fit-content"
                        icon='pulldown'
                        text={dqTranslateMsg('Page_DecisionTreeChart_' + 'Redraw')}
                        hoverStateEnabled={false}
                        onClick={handleRedraw}
                    />
                </div>
                <div></div>
            </div>
            <div className='workflow-cytoscape-decision-tree-container'>
                <div
                    className='workflow-cytoscape-cart-container'
                    style={{ padding: '3px' }}
                >
                    <CytoscapeComponent
                        panningEnabled={true} // false: pan cannot move
                        userPanningEnabled={true} // false: pan cannot move
                        boxSelectionEnabled={true}
                        zoomingEnabled={true} // false: zoom to mouse scroll
                        userZoomingEnabled={true} // false: zoom to mouse scroll
                        // autoungrabify={true} // node cannot move
                        cy={(cy) => {
                            cartRef.current = cy
                        }}
                        elements={elements}
                        layout={layout}
                        style={{
                            borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                            width: '100%',
                            height: '100%',
                        }}
                        stylesheet={cytoscapeStylesheet}
                    />
                </div>
                <div className='workflow-cytoscape-others-container'>
                    <div
                        className='workflow-cytoscape-random-forest-content'
                        style={{ padding: '3px' }}
                    >
                        <ReactECharts
                            ref={rfChartRef}
                            style={{
                                height: '100%',
                                width: '100%',
                                padding: '10px',
                                borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                            }} // Add space after subtraction operator
                            notMerge={true}
                            option={option}
                            theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                            opts={{
                                renderer: 'svg',
                                locale: ETCUtils.checkLangForECharts(language),
                            }}
                            onEvents={{
                                click: (chartData) => handleImportanceChartClick(chartData),
                            }}
                        />
                    </div>
                    <div
                        className='workflow-cytoscape-result-container'
                        style={{ padding: '3px' }}
                    >
                        <div
                            className='workflow-cytoscape-target-info-content'
                            style={{
                                padding: '10px',
                                marginRight: '3px',
                                borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            {/* <p style={{ fontSize: '15px' }}>
                {dqTranslateMsg(
                  'Page_DecisionTreeChart_' + 'TargetInformation'
                )}
              </p> */}
                            <Form
                                id='form'
                                ref={formRef}
                                labelMode={'static'}
                                formData={targetInfo}
                                showColonAfterLabel={true}
                                minColWidth={300}
                                colCount={1}
                                width={'100%'}
                                height={'100%'}
                            >
                                <SimpleItem
                                    dataField='name'
                                    editorType='dxTextBox'
                                >
                                    <Label text={dqTranslateMsg('Page_DecisionTreeChart_' + 'Name')} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='usl'
                                    editorType='dxNumberBox'
                                >
                                    <Label text={dqTranslateMsg('Page_DecisionTreeChart_' + 'USL')} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='lsl'
                                    editorType='dxNumberBox'
                                >
                                    <Label text={dqTranslateMsg('Page_DecisionTreeChart_' + 'LSL')} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='nominal'
                                    editorType='dxNumberBox'
                                >
                                    <Label text={dqTranslateMsg('Page_DecisionTreeChart_' + 'Nominal')} />
                                    <RequiredRule message={dqTranslateMsg('Page_DecisionTreeChart_' + 'NoData')} />
                                    <NumericRule message={dqTranslateMsg('Page_DecisionTreeChart_' + 'NominalValueError')} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='min'
                                    editorType='dxNumberBox'
                                >
                                    <Label text={dqTranslateMsg('Page_DecisionTreeChart_' + 'Min')} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='max'
                                    editorType='dxNumberBox'
                                >
                                    <Label text={dqTranslateMsg('Page_DecisionTreeChart_' + 'Max')} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='average'
                                    editorType='dxNumberBox'
                                >
                                    <Label text={dqTranslateMsg('Page_DecisionTreeChart_' + 'Average')} />
                                </SimpleItem>
                            </Form>
                        </div>
                        <div
                            className='workflow-cytoscape-result-info-content'
                            style={{
                                padding: '10px',
                                marginLeft: '3px',
                                borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <TextArea
                                readOnly
                                width={'100%'}
                                height={'100%'}
                                value={resultInfo}
                                label={dqTranslateMsg('Page_DecisionTreeChart_' + 'Result')}
                                stylingMode='outlined'
                            />
                        </div>
                    </div>
                    <div
                        className='workflow-cytoscape-button-container'
                        style={{ padding: '3px' }}
                    >
                        <Button
                            className='workflow-button-fit-content'
                            icon='login'
                            text={dqTranslateMsg('Page_DecisionTreeChart_' + 'Proceed')}
                            hoverStateEnabled={false}
                            stylingMode='contained'
                            type='success'
                            onClick={handleStartCartProc}
                            width={'100%'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
