import { DataGrid, SelectBox, TreeList } from 'devextreme-react'
import { Editing, Lookup, GroupPanel, Item, Toolbar, RequiredRule } from 'devextreme-react/data-grid'
import { Column as DataGridColumn } from 'devextreme-react/data-grid'
import { Column as TreeListColumn } from 'devextreme-react/tree-list'
import { batch, connect } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './AdminEndUsers.scss'
import TextTitle from 'src/functions/TextTitle'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { useEffect, useRef, useState } from 'react'
import { deleteAdminCompany, getAdminCompanyList, getAdminCompanyPlantList, updateAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyListPrms, makeGetAdminCompanyPlantListPrms, makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { useApi } from 'src/contexts/api'
import { makeGetAdminGroupListPrms, makeGetAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import { deleteAdminGroup, getAdminGroupList, updateAdminGroup } from 'src/api/admin/AdminGroupApi'
import { getUserList } from 'src/api/auth'
import * as dtoUtils from 'src/dto/DTOImpl'

const makeDataSourceForTreeView = (groups) => {
    const treeDataSource = []
    const expandIDList = []
    let currentId = 100 // Start ID for company, plant, group, and user

    const companyMap = {}
    const plantMap = {}

    groups.forEach((group) => {
        // Handle company with circled C icon
        if (!companyMap[group.company]) {
            const companyNode = {
                id: currentId++,
                name: `Ⓒ ${group.company}`, // Unicode circled C
                type: 'company',
                parentId: null,
            }
            companyMap[group.company] = companyNode.id
            treeDataSource.push(companyNode)
        }

        // Handle plant with circled P icon
        const companyId = companyMap[group.company]
        const plantKey = `${group.company}_${group.plant}`
        if (!plantMap[plantKey]) {
            const plantNode = {
                id: currentId++,
                name: `Ⓟ ${group.plant}`, // Unicode circled P
                type: 'plant',
                parentId: companyId,
            }
            plantMap[plantKey] = plantNode.id
            treeDataSource.push(plantNode)
            expandIDList.push(companyId)
        }

        // Handle group with circled G icon
        const plantId = plantMap[plantKey]
        const groupNode = {
            id: group.id,
            name: `Ⓖ ${group.group}`, // Unicode circled G
            type: 'group',
            parentId: plantId,
        }

        treeDataSource.push(groupNode)
        expandIDList.push(plantId)
    })

    const uniqueExpandIDList = [...new Set(expandIDList)]

    return { treeDataSource, expandIDList: uniqueExpandIDList }
}

function AdminEndUsers({ pageKey, common, chartRawData }) {
    const { spring } = useApi()

    const treeListRef = useRef(null)
    const dataGridRef = useRef(null)

    const [adminUserList, setAdminUserList] = useState([])
    const [companyList, setCompanyList] = useState([])
    const [plantList, setPlantList] = useState([])
    const [companyPlantList, setCompanyPlantList] = useState([])
    const [cpDataSource, setCPDataSource] = useState([])
    const [groupDataSource, setGroupDataSource] = useState([])
    const [treeDataSource, setTreeDataSource] = useState([])
    const [expandIDList, setExpandIDList] = useState([])

    // For Company DataGrid
    const onRowCompanyInserting = (e) => {
        const rowData = e.data
        let is_cancel = true

        const processRowCompanyInserting = async () => {
            try {
                const company = rowData.company
                const plant = rowData.plant
                await updateAdminCompany(spring, makeGetAdminCompanyPrms(company, plant))
            } catch (error) {
                console.error(error)
                e.cancel = is_cancel
            }
        }

        processRowCompanyInserting()
    }

    const onRowCompanyRemoving = (e) => {
        const rowData = e.data
        let is_cancel = true

        const processRowCompanyRemoving = async () => {
            try {
                const company = rowData.company
                const plant = rowData.plant

                const isCompanyPlantDataExist = groupDataSource.find((item) => item.company === company && item.plant === plant)
                if (isCompanyPlantDataExist) {
                    alert(getPageMsg(msgType.AdminSettings, 'CompanyDeleteErrMsg'))
                    e.cancel = is_cancel
                    return
                } else {
                    await deleteAdminCompany(spring, makeGetAdminCompanyPrms(company, plant))
                }
            } catch (error) {
                console.error(error)
                e.cancel = is_cancel
            }
        }

        processRowCompanyRemoving()
    }

    const onExpandedRowKeysChange = (newExpandedRowKeys) => {
        setExpandIDList(newExpandedRowKeys)
    }

    // For Group DataGrid
    const onRowGroupInserting = (e) => {
        const rowData = e.data
        let is_cancel = true

        const processRowGroupInserting = async () => {
            try {
                const company = rowData.company
                const plant = rowData.plant
                const group = rowData.group

                await updateAdminGroup(spring, makeGetAdminGroupPrms(company, plant, group))
            } catch (error) {
                console.error(error)
                e.cancel = is_cancel
            }
        }

        processRowGroupInserting()
    }

    const onRowGroupRemoving = (e) => {
        const rowData = e.data
        let is_cancel = true

        const processRowGroupRemoving = async () => {
            try {
                const company = rowData.company
                const plant = rowData.plant
                const group = rowData.group

                // const isCompanyPlantDataExist = groupDataSource.find((item) => item.company === company && item.plant === plant)
                const isUserDataExist = adminUserList.find((item) => item.user_company === company && item.user_plant === plant && item.user_group === group)
                if (isUserDataExist) {
                    alert(getPageMsg(msgType.AdminSettings, 'CompanyDeleteErrMsg'))
                    e.cancel = is_cancel
                    return
                } else {
                    await deleteAdminGroup(spring, makeGetAdminGroupPrms(company, plant, group))
                }
            } catch (error) {
                console.error(error)
                e.cancel = is_cancel
            }
        }

        processRowGroupRemoving()
    }

    useEffect(() => {
        const getDataForCompanyPlant = async () => {
            const companyList = await getAdminCompanyList(spring, makeGetAdminCompanyListPrms())
            const filteredC = companyList.filter((item) => item !== 'DQS')
            const uniqueCList = [...new Set(filteredC)]

            const companyPlantList = await Promise.all(
                uniqueCList.map(async (company) => {
                    const plantList = await getAdminCompanyPlantList(spring, makeGetAdminCompanyPlantListPrms(company))
                    return { company, plantList }
                })
            )

            const companyPlantListFlat = companyPlantList.flatMap(({ company, plantList }) => plantList.map((plant) => ({ company, plant })))
            const filteredCP = companyPlantListFlat.filter((item) => item.company !== 'DQS' && item.plant !== 'DQS')

            const plantList = filteredCP.map((item) => item.plant)
            const resultWithNo = companyPlantListFlat.map((item, index) => ({
                no: index + 1,
                ...item,
            }))

            console.log(resultWithNo)

            const admDataConverted = []
            await Promise.all(
                companyPlantList.flatMap(async ({ company, plantList }) => {
                    return Promise.all(
                        plantList.map(async (plant) => {
                            const groupList = await getAdminGroupList(spring, makeGetAdminGroupListPrms(company, plant))

                            groupList.forEach((group) => {
                                admDataConverted.push({ company, plant, group })
                            })
                        })
                    )
                })
            )

            // user
            const userList = await getUserList(spring)
            const convertedUserDTO = userList.user_infos.map((info) => {
                return dtoUtils.makeUserInfoToDTOFormat(info)
            })

            const filteredCPG = admDataConverted.filter((item) => item.company !== 'DQS' && item.plant !== 'DQS' && item.group !== 'DQS')
            const sortedData = filteredCPG.sort((a, b) => a.company.localeCompare(b.company))
            sortedData.forEach((item, index) => {
                item.id = index + 1
            })
            const { treeDataSource, expandIDList } = makeDataSourceForTreeView(sortedData)

            batch(() => {
                setAdminUserList(convertedUserDTO)
                setCompanyList(uniqueCList)
                setPlantList(plantList)
                setCompanyPlantList(companyPlantList)
                // setCPDataSource(resultWithNo)
                setCPDataSource(filteredCP)
                // setGroupDataSource(admDataConverted)
                setGroupDataSource(filteredCPG)
                setTreeDataSource(treeDataSource)
                setExpandIDList(expandIDList)
            })
        }

        getDataForCompanyPlant()
    }, [])

    return (
        <div className='dx-theme-background-color'>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '40%', height: '100%', padding: '5px', display: 'flex', flexDirection: 'column' }}>
                    <div className='end-users-title-container'>
                        <TextTitle
                            text={getPageMsg(msgType.AdminSettings, 'Company') + ' / ' + getPageMsg(msgType.AdminSettings, 'Plant')}
                            width='100%'
                            fontSize={'16px'}
                            textAlign={'left'}
                        />
                    </div>
                    <div className='end-users-content-container'>
                        <DataGrid
                            // className="end-users-data-grid-tree-list-inner-content"
                            style={{ height: '100%' }}
                            dataSource={cpDataSource}
                            showRowLines
                            showColumnLines
                            showBorders
                            onRowInserting={onRowCompanyInserting}
                            onRowRemoving={onRowCompanyRemoving}
                        >
                            <GroupPanel
                                visible={true}
                                emptyPanelText={dqTranslateMsg('Page_Database_' + 'EmptyPanelText')}
                            />
                            <Editing
                                mode='row'
                                useIcons={true}
                                allowAdding={true}
                                allowDeleting={true}
                            />
                            <Toolbar>
                                <Item
                                    name='groupPanel'
                                    location='before'
                                />
                                <Item name='saveButton' />
                                <Item name='addRowButton' />
                                <Item name='revertButton' />
                            </Toolbar>
                            {/* <DataGridColumn dataField="no" caption={dqTranslateMsg('Page_AdminSettings_' + 'Number')} width={'10%'} alignment="center" /> */}
                            <DataGridColumn
                                dataField='company'
                                caption={getPageMsg(msgType.AdminSettings, 'Company')}
                                width={'45%'}
                                alignment='center'
                            >
                                <RequiredRule />
                            </DataGridColumn>
                            <DataGridColumn
                                dataField='plant'
                                caption={getPageMsg(msgType.AdminSettings, 'Plant')}
                                width={'45%'}
                                alignment='center'
                            >
                                <RequiredRule />
                            </DataGridColumn>
                        </DataGrid>
                    </div>
                </div>
                {/* 등록된 Company Plant 기반으로  Group 추가 */}
                <div style={{ width: '40%', height: '100%', padding: '5px', display: 'flex', flexDirection: 'column' }}>
                    <div className='end-users-title-container'>
                        <TextTitle
                            text={getPageMsg(msgType.AdminSettings, 'Group')}
                            width='100%'
                            fontSize={'16px'}
                            textAlign={'left'}
                        />
                    </div>
                    <div className='end-users-content-container'>
                        <DataGrid
                            className='end-users-data-grid-tree-list-inner-content'
                            dataSource={groupDataSource} // Data source for group management
                            showRowLines
                            showColumnLines
                            showBorders
                            onRowInserting={onRowGroupInserting}
                            onRowRemoving={onRowGroupRemoving}
                        >
                            <GroupPanel
                                visible={true}
                                emptyPanelText={dqTranslateMsg('Page_Database_' + 'EmptyPanelText')}
                            />
                            <Editing
                                mode='row'
                                useIcons={true}
                                allowAdding={true}
                                allowDeleting={true}
                            />
                            <Toolbar>
                                <Item
                                    name='groupPanel'
                                    location='before'
                                />
                                <Item name='saveButton' />
                                <Item name='addRowButton' />
                                <Item name='revertButton' />
                            </Toolbar>
                            <DataGridColumn
                                dataField='company'
                                caption={getPageMsg(msgType.AdminSettings, 'Company')}
                                width={'35%'}
                                alignment='center'
                                editCellRender={(cell) => {
                                    const handleInputChange = (e) => {
                                        if (e.event === undefined) return
                                        cell.setValue(e.value) // Update the value when the plant is selected
                                    }

                                    return (
                                        <SelectBox
                                            defaultValue={cell.value}
                                            {...cell.column.lookup}
                                            onValueChanged={handleInputChange}
                                        />
                                    )
                                }}
                            >
                                <RequiredRule />
                                <Lookup dataSource={companyList} />
                            </DataGridColumn>
                            <DataGridColumn
                                dataField='plant'
                                caption={getPageMsg(msgType.AdminSettings, 'Plant')}
                                width={'20%'}
                                alignment='center'
                                editCellRender={(cell) => {
                                    const handleInputChange = (e) => {
                                        if (e.event === undefined) return
                                        cell.setValue(e.value) // Update the value when the plant is selected
                                    }

                                    return (
                                        <SelectBox
                                            defaultValue={cell.value}
                                            {...cell.column.lookup}
                                            onValueChanged={handleInputChange}
                                        />
                                    )
                                }}
                            >
                                <RequiredRule />
                                <Lookup dataSource={plantList} />
                            </DataGridColumn>
                            <DataGridColumn
                                dataField='group'
                                caption={getPageMsg(msgType.AdminSettings, 'Group')}
                                width={'30%'}
                                alignment='center'
                                allowEditing={true} // Allow direct input for the group
                            >
                                <RequiredRule />
                            </DataGridColumn>
                        </DataGrid>
                    </div>
                </div>
                <div style={{ width: '40%', height: '100%', padding: '5px', display: 'flex', flexDirection: 'column' }}>
                    <div className='end-users-title-container'>
                        <TextTitle
                            text={getPageMsg(msgType.AdminSettings, 'Preview')}
                            width='100%'
                            fontSize={'16px'}
                            textAlign={'left'}
                        />
                    </div>
                    <div className='end-users-content-container'>
                        <TreeList
                            ref={treeListRef}
                            className='end-users-data-grid-tree-list-inner-content'
                            dataSource={treeDataSource}
                            // autoExpandAll={true}
                            expandedRowKeys={expandIDList}
                            showBorders={true}
                            showRowLines={true}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            searchPanel={{
                                highlightCaseSensitive: false,
                                highlightSearchText: true,
                                placeholder: dqTranslateMsg('SearchPlaceHoler'),
                                searchVisibleColumnsOnly: false,
                                text: '',
                                visible: true,
                                width: 160,
                            }}
                            noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
                            keyExpr='id'
                            parentIdExpr='parentId'
                            onExpandedRowKeysChange={onExpandedRowKeysChange}
                        >
                            {/* <Selection mode="single" recursive={false} /> */}
                            {/* <Grouping autoExpandAll={true}/>   */}
                            <TreeListColumn dataField='name' />
                        </TreeList>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminEndUsers
