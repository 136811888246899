import { Column, DataGrid } from 'devextreme-react/data-grid'
import SelectBox from 'devextreme-react/select-box'
import ReactECharts from 'echarts-for-react'
import { useEffect, useRef, useState, useMemo, Fragment } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './CorrelationChartForm.scss'
import { getCorrelationBarChartOption, getCorrelationHeatMapChartOption } from './chart-option/CorrelationChartOption'
import * as cImpl from './chart-option/CorrelationChartOptionImpl'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import { connect } from 'react-redux'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import { useLanguage } from 'src/contexts/languages'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(CorrelationChartForm)

function CorrelationChartForm({ pageKey, common, chartRawData }) {
  const { getTheme } = useTheme()
  const { language } = useLanguage()
  const [isLoadingPanel, setIsLoadingPanel] = useState(false)
  // ** State
  // For initialRendering
  const isInitialRender = useRef(true)
  const corrChartRef = useRef(null)

  const dispatch = useDispatch()

  const curPage = common.pages.correlationChart
  const configPage = common.pages.configuration
  const currentSel = common.current_sel
  const selectedChar = common.loaded_all[pageKey]

  const [chartOptions, setChartOptions] = useState(null)
  const [dataGridDataSource, setDataGridDataSource] = useState(null)
  useEffect(() => {
    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)

    if (valuesArranged < 2) {
      console.error('Not enough data')
      // setTargetChar(null)
      setChartOptions(null)
      setDataGridDataSource(null)
      return
    }

    const processChartOptions = async () => {
      setIsLoadingPanel(true)

      let displayArray
      let option
      let defCorrNameList
      let defCorrDataList
      let defCorrDpList
      let column
      let row

      let targetValue
      targetValue = valuesArranged.find((char) => char.part_id === currentSel.part_id && char.char_id === currentSel.char_id)

      if (!targetValue) {
        batch(() => {
          setChartOptions(null)
          setDataGridDataSource(null)
          setIsLoadingPanel(false)
        })
        return
      }

      if (curPage.page.displayMethod !== 'barChart') {
        if (valuesArranged.length > 100) {
          alert('Make characteristics below 100 (HeatMap mode)')
          return
        }
      }

      let corrChartOption = null
      let dataGridColumn = null
      if (curPage.page.displayMethod === 'barChart') {
        displayArray = cImpl.makeCorrelationData(currentSel, charArray, valuesArranged, curPage.page.displayType, configPage)

        defCorrNameList = displayArray.map((item) => item.char_name)
        defCorrDataList = displayArray.map((item) => item.correlation_data)
        defCorrDpList = displayArray.map((item) => item.char_decimal_places)
        corrChartOption = getCorrelationBarChartOption(curPage, configPage, defCorrNameList, defCorrDataList, defCorrDpList, targetValue)
        // dataGridColumn = cImpl.makeColumns
        dataGridColumn = cImpl.makeRows(displayArray)
      } else {
        const { charNames, correlationsData } = cImpl.makeHeatMapCorrelationData(parts, charArray, valuesArranged, curPage.page.displayType, configPage)
        corrChartOption = getCorrelationHeatMapChartOption(curPage, configPage, charNames, correlationsData)
      }
      // }
      // }
      // Simulate a delay to allow the loading panel to appear
      await new Promise((resolve) => setTimeout(resolve, 100)) // Adjust delay if needed

      batch(() => {
        // setTargetChar(targetValue)
        setChartOptions(corrChartOption)
        setDataGridDataSource(dataGridColumn)
        setIsLoadingPanel(false)
      })
    }
    processChartOptions()
  }, [selectedChar, currentSel, curPage, configPage])
  // load settings

  const handleTargetCharSelect = (e) => {
    setTargetChar(e.itemData)
  }

  function renderCorrelationData(cellData, configSettings) {
    // return <div>{DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(cellData.value, calcDecimalPlaces), isExponential)}</div>
    return <div>{DNUtils.calcValueDisplay(cellData.value, configSettings)}</div>
  }

  const barChartRender = () => {
    return (
      <Fragment>
        <div className="correlation-chart-form-item-container" style={{ padding: '3px' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '10px',
              borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <ReactECharts
              ref={corrChartRef}
              style={{ width: '100%', height: '100%' }}
              notMerge={true}
              option={chartOptions}
              theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
              opts={{
                renderer: 'svg',
                locale: ETCUtils.checkLangForECharts(language),
              }}
            />
          </div>
        </div>
        <div className="description-grid-container" style={{ padding: '3px' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '10px',
              borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <DataGrid
              className="description-grid-content"
              id="correlation-datagrid"
              dataSource={dataGridDataSource}
              allowColumnResizing={true}
              columnAutoWidth={true}
              scrolling={{
                showScrollbar: 'onHover',
                useNative: false,
              }}
              paging={{ enabled: false }}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              rowAlternationEnabled={false}
            >
              <Column dataField="char_number" caption={dqTranslateMsg('Page_CorrelationChart_' + 'ChartNumber')} alignment="center"></Column>
              <Column dataField="char_name" caption={dqTranslateMsg('Page_CorrelationChart_' + 'ChartName')} alignment="center"></Column>
              <Column
                dataField="correlation_data"
                caption={dqTranslateMsg('Page_CorrelationChart_' + 'CorrelationData')}
                alignment="center"
                cellRender={(cellData) => renderCorrelationData(cellData, configPage)}
              ></Column>
            </DataGrid>
          </div>
        </div>
      </Fragment>
    )
  }

  const heatMapChartRender = () => {
    return (
      <div className="correlation-chart-form-heat-map-container">
        <ReactECharts
          ref={corrChartRef}
          style={{ width: '100%', height: '100%' }}
          notMerge={true}
          option={chartOptions}
          theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
          opts={{
            renderer: 'svg',
            locale: ETCUtils.checkLangForECharts(language),
          }}
        />
      </div>
    )
  }

  const render = (mode) => {
    if (mode === 'barChart') {
      return barChartRender()
    } else {
      return heatMapChartRender()
    }
  }

  return <div className={'dx-theme-background-color'}>{chartOptions && !isLoadingPanel ? render(curPage.page.displayMethod) : null}</div>
}
