import { Button } from 'devextreme-react'
import { Fragment } from 'react'

function DQSaveReset(props) {
  const { saveBtnText, resetBtnText, saveCallback, resetCallback } = props

  return (
    <Fragment>
      <div style={{ display: 'flex', gap: '5px' }}>
        <Button icon="save" text={saveBtnText} width={'50%'} onClick={saveCallback} />
        <Button icon="undo" text={resetBtnText} width={'50%'} onClick={resetCallback} />
      </div>
    </Fragment>
  )
}

export default DQSaveReset
