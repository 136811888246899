import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

export default function SettingsToolTip() {
  const dispatch = useDispatch()
  const pageKey = 'histogramChart'

  const pageState = useSelector((state) => state.common.pages[pageKey])

  const onCheckBoxLimitEnableValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`tooltip-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'ToolTip')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'SpecificationLimit'),
        labelLocation: 'right',
        name: 'toolTipUseSpecLimits',
        value: pageState.page.toolTipUseSpecLimits,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitEnableValueChanged(e, 'toolTipUseSpecLimits'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'QuantileLimit'),
        labelLocation: 'right',
        name: 'toolTipUseQuantileLimits',
        value: pageState.page.toolTipUseQuantileLimits,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitEnableValueChanged(e, 'toolTipUseQuantileLimits'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'AverageLimit'),
        labelLocation: 'right',
        name: 'toolTipUseAverageLimits',
        value: pageState.page.toolTipUseAverageLimits,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitEnableValueChanged(e, 'toolTipUseAverageLimits'),
      })}
    </GroupItem>
  )
}
