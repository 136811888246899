/**
 * @typedef {Object} LoginTable
 * @property {string} M0001 - 'SignIn'
 * @property {string} M0002 - 'ID'
 * @property {string} M0003 - 'Password'
 */

/**
 * @type {LoginTable}
 */

export const loginTable = {
  M0001: 'SignIn',
  M0002: 'ID',
  M0003: 'Password',
  M0004: 'Reserved',
  M0005: 'Reserved',
  M0006: 'Reserved',
  M0007: 'Reserved',
  M0008: 'Reserved',
  M0009: 'Reserved',
  M0010: 'Reserved',
  M0011: 'Reserved',
  M0012: 'Reserved',
  M0013: 'Reserved',
  M0014: 'Reserved',
  M0015: 'Reserved',
  M0016: 'Reserved',
  M0017: 'Reserved',
  M0018: 'Reserved',
  M0019: 'Reserved',
  M0020: 'Reserved',
  M0021: 'Reserved',
  M0022: 'Reserved',
  M0023: 'Reserved',
  M0024: 'Reserved',
  M0025: 'Reserved',
  M0026: 'Reserved',
  M0027: 'Reserved',
  M0028: 'Reserved',
  M0029: 'Reserved',
  M0030: 'Reserved',
  M0031: 'Reserved',
  M0032: 'Reserved',
  M0033: 'Reserved',
  M0034: 'Reserved',
  M0035: 'Reserved',
  M0036: 'Reserved',
  M0037: 'Reserved',
  M0038: 'Reserved',
  M0039: 'Reserved',
  M0040: 'Reserved',
  M0041: 'Reserved',
  M0042: 'Reserved',
  M0043: 'Reserved',
  M0044: 'Reserved',
  M0045: 'Reserved',
  M0046: 'Reserved',
  M0047: 'Reserved',
  M0048: 'Reserved',
  M0049: 'Reserved',
  M0050: 'Reserved',
  M0051: 'Reserved',
  M0052: 'Reserved',
  M0053: 'Reserved',
  M0054: 'Reserved',
  M0055: 'Reserved',
  M0056: 'Reserved',
  M0057: 'Reserved',
  M0058: 'Reserved',
  M0059: 'Reserved',
  M0060: 'Reserved',
  M0061: 'Reserved',
  M0062: 'Reserved',
  M0063: 'Reserved',
  M0064: 'Reserved',
  M0065: 'Reserved',
  M0066: 'Reserved',
  M0067: 'Reserved',
  M0068: 'Reserved',
  M0069: 'Reserved',
  M0070: 'Reserved',
  M0071: 'Reserved',
  M0072: 'Reserved',
  M0073: 'Reserved',
  M0074: 'Reserved',
  M0075: 'Reserved',
  M0076: 'Reserved',
  M0077: 'Reserved',
  M0078: 'Reserved',
  M0079: 'Reserved',
  M0080: 'Reserved',
  M0081: 'Reserved',
  M0082: 'Reserved',
  M0083: 'Reserved',
  M0084: 'Reserved',
  M0085: 'Reserved',
  M0086: 'Reserved',
  M0087: 'Reserved',
  M0088: 'Reserved',
  M0089: 'Reserved',
  M0090: 'Reserved',
  M0091: 'Reserved',
  M0092: 'Reserved',
  M0093: 'Reserved',
  M0094: 'Reserved',
  M0095: 'Reserved',
  M0096: 'Reserved',
  M0097: 'Reserved',
  M0098: 'Reserved',
  M0099: 'Reserved',
  M0100: 'Reserved',
}
