export const userTbDescription = {
    dbSavedFilter: 'db_config',
    overview: 'chart_config_1',
    monitoring: 'chart_config_2',
    database: 'chart_config_3',
    valueChart: 'chart_config_4',
    histogramChart: 'chart_config_5',
    positionalTolerancesChart: 'chart_config_6',
    boxPlotChart: 'chart_config_7',
    linearRegressionChart: 'chart_config_8',
    correlationChart: 'chart_config_9',
    qualityControlChart: 'chart_config_10',
    capabilitySummaryChart: 'chart_config_11',
    paretoChart: 'chart_config_12',
    probabilityPlotChart: 'chart_config_13',
    decisionTreeChart: 'chart_config_14',
    valueList: 'chart_config_15',
    summaryList: 'chart_config_16',
    valueSummaryList: 'chart_config_17',
    scrollBoard: 'chart_config_18',
    configuration: 'chart_config_19',
    adminSettings: 'chart_config_20',
    reportPrintMultiFunction: 'chart_config_21',
}
