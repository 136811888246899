// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  display: flex;
  flex-wrap: wrap;
}
.dx-theme-background-color .overview-pie-chart-container {
  width: 35%;
  height: 100%;
}
.dx-theme-background-color .overview-bar-chart-container {
  width: 65%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/overview/components/main-view/OverviewForm.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,eAAA;AAAF;AAEE;EACE,UAAA;EACA,YAAA;AAAJ;AAGE;EACE,UAAA;EACA,YAAA;AADJ","sourcesContent":["// .overview-form-container {\r\n.dx-theme-background-color {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n\r\n  .overview-pie-chart-container {\r\n    width: 35%;\r\n    height: 100%;\r\n  }\r\n\r\n  .overview-bar-chart-container {\r\n    width: 65%;\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
