const bodyFormat = {
  requestMsg: {},
}

export const makeGetAdminGroupPrms = (company, plant, group) => {
  let body = { ...bodyFormat }
  body.requestMsg = {
    company: company,
    plant: plant,
    group: group,
  }
  return body
}

export const makeDeleteAdminGroupPrms = (company, plant, group) => {
  let body = { ...bodyFormat }
  body.requestMsg = {
    company: company,
    plant: plant,
    group: group,
  }
  return body
}

export const makeGetAdminGroupListPrms = (company, plant) => {
  let body = { ...bodyFormat }
  body.requestMsg = {
    company: company,
    plant: plant,
  }
  return body
}

export const makeUpdateAdminGroupPrms = (company, plant, group, updateData) => {
  let body = { ...bodyFormat }
  body.requestMsg = {
    company: company,
    plant: plant,
    group: group,
    ...updateData,
  }
  return body
}
