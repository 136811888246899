export const procErrTable = {
  E00001: 'E00001',
  E00002: 'E00002',
  E00003: 'E00003',
  E00004: 'E00004',
  E00005: 'E00005',
  E00006: 'E00006',
  E00007: 'E00007',
  E00008: 'E00008',
  E00009: 'E00009',
  E00010: 'E00010',
  E00011: 'E00011',
  E00012: 'E00012',
  E00013: 'E00013',
  E00014: 'E00014',
  E00015: 'E00015',
  E00016: 'E00016',
  E00017: 'E00017',
  E00018: 'E00018',
  E00019: 'E00019',
  E00020: 'E00020',
  E00021: 'E00021',
  E00022: 'E00022',
  E00023: 'E00023',
  E00024: 'E00024',
  E00025: 'E00025',
  E00026: 'E00026',
  E00027: 'E00027',
  E00028: 'E00028',
  E00029: 'E00029',
  E00030: 'E00030',
  E00031: 'E00031',
  E00032: 'E00032',
  E00033: 'E00033',
  E00034: 'E00034',
  E00035: 'E00035',
  E00036: 'E00036',
  E00037: 'E00037',
  E00038: 'E00038',
  E00039: 'E00039',
  E00040: 'E00040',
  E00041: 'E00041',
  E00042: 'E00042',
  E00043: 'E00043',
  E00044: 'E00044',
  E00045: 'E00045',
  E00046: 'E00046',
  E00047: 'E00047',
  E00048: 'E00048',
  E00049: 'E00049',
  E00050: 'E00050',
  E00051: 'E00051',
  E00052: 'E00052',
  E00053: 'E00053',
  E00054: 'E00054',
  E00055: 'E00055',
  E00056: 'E00056',
  E00057: 'E00057',
  E00058: 'E00058',
  E00059: 'E00059',
  E00060: 'E00060',
  E00061: 'E00061',
  E00062: 'E00062',
  E00063: 'E00063',
  E00064: 'E00064',
  E00065: 'E00065',
  E00066: 'E00066',
  E00067: 'E00067',
  E00068: 'E00068',
  E00069: 'E00069',
  E00070: 'E00070',
  E00071: 'E00071',
  E00072: 'E00072',
  E00073: 'E00073',
  E00074: 'E00074',
  E00075: 'E00075',
  E00076: 'E00076',
  E00077: 'E00077',
  E00078: 'E00078',
  E00079: 'E00079',
  E00080: 'E00080',
  E00081: 'E00081',
  E00082: 'E00082',
  E00083: 'E00083',
  E00084: 'E00084',
  E00085: 'E00085',
  E00086: 'E00086',
  E00087: 'E00087',
  E00088: 'E00088',
  E00089: 'E00089',
  E00090: 'E00090',
  E00091: 'E00091',
  E00092: 'E00092',
  E00093: 'E00093',
  E00094: 'E00094',
  E00095: 'E00095',
  E00096: 'E00096',
  E00097: 'E00097',
  E00098: 'E00098',
  E00099: 'E00099',
  E00100: 'E00100',
}
