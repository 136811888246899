import PageContainer from 'src/functions/PageContainer'
import './development-functions.scss'
import DevelopmentFunctionForm from './main-view/DevelopmentFunctionForm'
import styled from 'styled-components'

export default function DevelopmentFunction(props) {
  return (
    <PageContainer
      pageKey="developmentFunction"
      ChartFormComponent={DevelopmentFunctionForm}
    />
  )
}
