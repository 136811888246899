import * as lrImpl from './LinearRegressionChartOptionImpl'
import * as VCUtils from 'src/functions/ValueChartUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import CCUtils from 'src/functions/CommonCalcUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

export const getLinearRegressionChartOption = (settings, configSettings, yConstant, variableData) => {
  const titleText = `${DBUtils.getFieldData(yConstant[0], 'c2002') ?? 'empty'} P:${DBUtils.getFieldData(yConstant[0], 'part_id') ?? ''}/C:${DBUtils.getFieldData(yConstant[0], 'char_id') ?? ''}`
  // const lsl = PCUtils.getLsl(yConstant[0])
  // const usl = PCUtils.getUsl(yConstant[0])
  // // const nominal = (lsl + usl) / 2
  // const nominal = PCUtils.getNominal(yConstant[0])
  // const gapUslLsl = PCUtils.getUslLslGap(usl, lsl)
  // const warnOffset = PCUtils.getWarningOffset(configSettings.commonSettings.warningLimitPercentage)
  // const lwl = PCUtils.getLwl(lsl, gapUslLsl, warnOffset)
  // const uwl = PCUtils.getUwl(usl, gapUslLsl, warnOffset)

  // const v0001s = yConstant[1].valueRaw.map((item) => {
  //   return item.v0001
  // })

  // const maxValue = CCUtils.getMax(v0001s)
  // const minValue = CCUtils.getMin(v0001s)

  const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(yConstant[0], configSettings)
  const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
  const filteredValue = outlierFilterValues(limits, yConstant[1].charRaw, yConstant[1].valueRaw, configSettings)
  const { lcl, ucl, average, minValue, maxValue, v0001s } = PCUtils.getCalculatedPrms(filteredValue, configSettings)

  const topBottomCalcArray = {
    settings,
    usl,
    lsl,
    uwl,
    lwl,
    upl,
    lpl,
    url,
    lrl,
    uacl,
    lacl,
    maxValue,
    minValue,
  }

  const yAxisTop = lrImpl.makeYAxisTop(topBottomCalcArray)
  const yAxisBottom = lrImpl.makeYAxisBottom(topBottomCalcArray)
  // const yAxisTop = VCUtils.makeYAxisTop(settings, usl, lsl, maxValue, minValue)
  // const yAxisBottom = VCUtils.makeYAxisBottom(settings, usl, lsl, maxValue, minValue)

  const constantDp = DNUtils.getValueDecimalPlaces(yConstant[0], configSettings)
  const variableDpArray = variableData.map((variable) => variable.decimalPlaces)
  const filteredDp = variableDpArray.filter((item) => item !== null)
  const uniqueDp = [...new Set(filteredDp)]
  const variableDp = uniqueDp.length === 0 ? configSettings.commonSettings.displayDecimalPlaces : Math.max(...uniqueDp)
  // const decimalPlaces = variableDp > constantDp ? variableDp : constantDp

  const seriesData = []
  variableData.map((variable) => {
    let linRegData
    if (settings.page.compareType === 'scatter+slope') {
      // 'slope', 'scatter'
      linRegData = lrImpl.makeLinearRegressionLine(variable.variableData, variable.ySlope, variable.yIntercept)

      seriesData.push({
        type: 'scatter',
        name: `${variable.xData ?? 'empty'}`,
        data: variable.variableData,
      })
      seriesData.push({
        type: 'line',
        name: `${variable.xData ?? 'empty'}`,
        data: linRegData,
        symbol: 'none',
        lineStyle: {
          type: 'solid',
        },
      })
    } else if (settings.page.compareType === 'slope') {
      linRegData = lrImpl.makeLinearRegressionLineSlope(variable.variableData, variable.ySlope, variable.yIntercept)

      seriesData.push({
        type: 'line',
        name: `${variable.xData ?? 'empty'}`,
        data: linRegData,
        symbol: 'none',
        lineStyle: {
          type: 'solid',
        },
        endLabel: {
          show: true,
          formatter: (params) => {
            return `${params.seriesName}`
          },
          distance: 20,
          textStyle: {
            fontFamily: 'Arial',
          },
        },
        labelLayout: {
          hideOverlap: true,
        },
      })
    } else {
      seriesData.push({
        type: 'scatter',
        name: `${variable.xData ?? 'empty'}`,
        data: variable.variableData,
      })
    }
  })

  // chart setting reorganization
  const addedSettings = {
    ...settings,
    titleText: titleText,
    lsl: lsl,
    usl: usl,
    lwl: lwl,
    uwl: uwl,
    nominal: nominal,
    // gap: gapUslLsl,
    // warnOffset: warnOffset,
    yAxisTop: yAxisTop,
    yAxisBottom: yAxisBottom,
  }

  return {
    title: {
      text: addedSettings.titleText,
      left: 'center',
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    animationDuration: 300,
    grid: {
      top: '13%', //'8%',
      bottom: 40,
      left: '0%',
      right: '10%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'item',
      appendToBody: true,
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    legend: {
      type: 'scroll',
      show: true,
      top: '5%',
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    backgroundColor: 'rgba(255, 255, 255, 0)',
    xAxis: {
      type: 'value',
      scale: true,
      splitLine: {
        show: false,
      },
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
      },
      axisLine: {
        onZero: false,
      },
      axisLabel: {
        fontFamily: 'Arial',
        formatter: function (value) {
          // return DNUtils.valueDisplay(value, yConstant[0], configSettings)
          return DNUtils.fixedValueDisplay(value, variableDp)
        },
      },
    },
    yAxis: {
      type: 'value',
      scale: true,
      splitLine: {
        show: true,
      },
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        formatter: function (value) {
          return DNUtils.valueDisplay(value, yConstant[0], configSettings)
          // return value
        },
      },
      axisLine: {
        onZero: false,
      },
    },
    dataZoom: [
      {
        show: true,
        type: 'slider',
        orient: 'horizontal',
        xAxisIndex: 0,
        height: `3%`,
        bottom: '3%',
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'slider',
        orient: 'vertical',
        width: `3%`,
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'none',
        zoomLock: false, // Allow zooming
        zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
      },
      {
        show: true,
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none',
      },
    ],
    series: seriesData,
  }
}
