// export default function DevelopmentFunctionList({ className }) {
//   console.log('development function List rendering')
//   return <div className={className}>development function List</div>
// }
import React from 'react'
import styled from 'styled-components'

// Define the base style for the list component
const ListContainer = styled.div`
  width: 100%;
  height: 100%; // Adjust based on your content or leave it flexible
  padding: 20px; // Consider some padding for aesthetic spacing
  // Add more styles as needed
`

// Functional component
const DevelopmentFunctionList = ({ className }) => {
  console.log('Development function List rendering')
  // Utilize the className prop for any additional class-based styling
  return (
    <ListContainer className={className}>
      Development Function List
    </ListContainer>
  )
}

export default DevelopmentFunctionList
