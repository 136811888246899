import { Button } from 'devextreme-react/button'
import { Column, Editing, Item, Selection, Toolbar, TreeList } from 'devextreme-react/tree-list'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'

import { Toast } from 'devextreme-react/toast'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useAuth } from 'src/contexts/auth'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import './Catalog.scss'
import { connect } from 'react-redux'
import { configurationSettingsPrm, defaultCatalogs } from '../../settings/ConfigSettings'
import TextTitle from 'src/functions/TextTitle'
import { Grid } from '@mui/material'
import { CheckBox, DataGrid } from 'devextreme-react'
import DQToast from 'src/functions/DQToast'
import { companyPlantTbDescription as companyPlantTbDesc } from 'src/functions/constants/companyPlantDBfieldDescription'
import { useApi } from 'src/contexts/api'
import { getAdminCompany, updateAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyPrms, makeUpdateAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { makeAdminCompanyToDTOFormat, makeAdminDefaultToDTOFormat } from 'src/dto/DTOImpl'
import * as rd from 'src/store/common'

const mapStateToProps = (state) => ({
    common: state.common,
})

export default connect(mapStateToProps)(Catalog)

const makeUseCatalogForDataGrid = (type, catalogArray) => {
    let dataSource = []
    if (catalogArray.length > 0) {
        if (type === 'v0005') {
            dataSource = catalogArray.map((catalogItem, index) => {
                if (catalogItem.no === '999') {
                    return {
                        ID: index + 1,
                        no: Number(catalogItem.no),
                        desc: catalogItem.desc,
                        visible: false,
                    }
                } else {
                    return {
                        ID: index + 1,
                        no: Number(catalogItem.no),
                        desc: catalogItem.desc,
                        visible: true,
                    }
                }
            })
        } else {
            dataSource = catalogArray.map((catalogItem, index) => {
                return {
                    ID: index + 1,
                    no: Number(catalogItem.no),
                    desc: catalogItem.desc,
                    visible: true,
                }
            })
        }
    }

    console.log(dataSource)
    return dataSource
}

const convertCatalogData = (type, catalog) => {
    let converted = []
    if (type === 'v0005') {
        if (catalog.length > 0) {
            converted = catalog.map((item) => ({
                no: String(item.no), // Convert to string
                desc: item.desc,
            }))
            // converted.push({ no: String(999), desc: 'Confirmed' })
            if (!converted.some((item) => item.no === '999')) {
                converted.push({ no: String(999), desc: 'Confirmed' })
            }
        }
    } else if (type === 'v0011') {
        if (catalog.length > 0) {
            converted = catalog.map((item) => ({
                no: String(item.no), // Convert to string
                desc: item.desc,
            }))
        }
    } else {
        if (catalog.length > 0) {
            converted = catalog.map((item) => ({
                no: item.no,
                desc: item.desc,
            }))
        }
    }
    return converted
}

function Catalog({ pageKey, common }) {
    const { user } = useAuth()
    const { spring } = useApi()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const toastRef = useRef(null)
    const othersPage = common.pages.others
    const configPage = common.pages[pageKey]
    const configAll = common.base
    const catalogs = common.pages.others.catalogs

    const v0005Ref = useRef(null)
    const v0007Ref = useRef(null)
    const v0008Ref = useRef(null)
    const v0010Ref = useRef(null)
    const v0011Ref = useRef(null)
    const v0012Ref = useRef(null)

    const [isDefaultSettings, setIsDefaultSettings] = useState(true)

    const [v0005DataSource, setV0005DataSource] = useState([])
    const [v0007DataSource, setV0007DataSource] = useState([])
    const [v0008DataSource, setV0008DataSource] = useState([])
    const [v0010DataSource, setV0010DataSource] = useState([])
    const [v0011DataSource, setV0011DataSource] = useState([])
    const [v0012DataSource, setV0012DataSource] = useState([])

    useEffect(() => {
        batch(() => {
            setV0005DataSource(makeUseCatalogForDataGrid('v0005', catalogs.v0005)) // string
            setV0007DataSource(makeUseCatalogForDataGrid('v0007', catalogs.v0007)) // int
            setV0008DataSource(makeUseCatalogForDataGrid('v0008', catalogs.v0008)) // int
            setV0010DataSource(makeUseCatalogForDataGrid('v0010', catalogs.v0010)) // int
            setV0011DataSource(makeUseCatalogForDataGrid('v0011', catalogs.v0011)) // string
            setV0012DataSource(makeUseCatalogForDataGrid('v0012', catalogs.v0012)) // int
        })
    }, [catalogs])

    const handleSaveButton = async () => {
        const updateCompanyPlantSettings = async (element, field, pageValue) => {
            const company = element.company
            const plant = element.plant
            const dataJsonStr = JSON.stringify(pageValue)

            const updateData = {
                [field]: dataJsonStr,
            }

            await updateAdminCompany(spring, makeUpdateAdminCompanyPrms(company, plant, updateData))
        }

        const catalog_v0005 = convertCatalogData('v0005', v0005DataSource)
        const catalog_v0007 = convertCatalogData('v0007', v0007DataSource)
        const catalog_v0008 = convertCatalogData('v0008', v0008DataSource)
        const catalog_v0010 = convertCatalogData('v0010', v0010DataSource)
        const catalog_v0011 = convertCatalogData('v0011', v0011DataSource)
        const catalog_v0012 = convertCatalogData('v0012', v0012DataSource)

        const next = produce(othersPage, (draft) => {
            draft.catalogs.v0005 = catalog_v0005
            draft.catalogs.v0007 = catalog_v0007
            draft.catalogs.v0008 = catalog_v0008
            draft.catalogs.v0010 = catalog_v0010
            draft.catalogs.v0011 = catalog_v0011
            draft.catalogs.v0012 = catalog_v0012
        })

        if (isDefaultSettings) {
            const element = {
                company: user.user_company,
                plant: user.user_plant,
            }

            await updateCompanyPlantSettings(element, companyPlantTbDesc.catalogs, next.catalogs)
        }

        dispatch(rd.setCatalogs(next.catalogs))
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }

    const handleResetButton = async () => {
        const company = user?.user_company
        const plant = user?.user_plant
        const group = user?.user_group

        if (!company || !plant || !group) {
            console.error('company plant group info is invalid')
            return
        }
        const targetPlantInfo = await getAdminCompany(spring, makeGetAdminCompanyPrms(company, plant))
        const adminCompanyDTO = makeAdminCompanyToDTOFormat(targetPlantInfo)
        const catalogsDataStr = adminCompanyDTO[companyPlantTbDesc.catalogs]

        let catalogsData = null
        if (catalogsDataStr === '' || catalogsDataStr === null) {
            console.error('plant catalogs data is empty')
            catalogsData = defaultCatalogs
        } else {
            catalogsData = JSON.parse(catalogsDataStr)
        }
        console.log(`plant language :  <${catalogsData}>`)

        const next = produce(othersPage, (draft) => {
            draft.catalogs = catalogsData
        })

        dispatch(rd.setCatalogs(next.catalogs))
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    // const handleResetButton = async () => {
    //     const next = produce(othersPage, (draft) => {
    //         draft.catalogs = defaultCatalogs
    //     })
    //     // dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'configuration', next)
    //     dispatch(rd.setCatalogs(next.catalogs))
    //     toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    // }

    const handleDownloadCatalogTemplate = () => {
        // 설명 내용
        const description = `
// Template JSON File
// no: number, description: description mapped with no
`

        // 템플릿 JSON 내용 정의
        const template = {
            v0005: [
                { no: 0, desc: '' },
                { no: 1, desc: '' },
            ],
            v0006: [
                { no: 0, desc: '' },
                { no: 1, desc: '' },
            ],
            v0007: [
                { no: 0, desc: '' },
                { no: 1, desc: '' },
            ],
            v0008: [
                { no: 0, desc: '' },
                { no: 1, desc: '' },
            ],
            v0010: [
                { no: 0, desc: '' },
                { no: 1, desc: '' },
            ],
            v0011: [
                { no: 0, desc: '' },
                { no: 1, desc: '' },
            ],
            v0012: [
                { no: 0, desc: '' },
                { no: 1, desc: '' },
            ],
        }

        // JSON 객체를 문자열로 변환
        const jsonString = JSON.stringify(template, null, 2)

        // 설명 내용과 JSON 문자열 결합
        const combinedContent = description + jsonString

        // JSON 문자열을 Blob으로 변환
        const blob = new Blob([combinedContent], { type: 'application/json' })

        // 링크 요소 생성
        const link = document.createElement('a')

        // Blob을 위한 URL 생성 및 href 설정
        const url = URL.createObjectURL(blob)
        link.href = url

        // 다운로드 파일명 설정
        link.download = 'catalog_template.json'

        // 링크를 문서에 추가하고 클릭 이벤트 트리거, 이후 링크 제거
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const handleExportClick = () => {
        const v0005DataSourceWithoutID = v0005DataSource.map(({ ID, ...rest }) => rest)
        const v0007DataSourceWithoutID = v0007DataSource.map(({ ID, ...rest }) => rest)
        const v0008DataSourceWithoutID = v0008DataSource.map(({ ID, ...rest }) => rest)
        const v0010DataSourceWithoutID = v0010DataSource.map(({ ID, ...rest }) => rest)
        const v0011DataSourceWithoutID = v0011DataSource.map(({ ID, ...rest }) => rest)
        const v0012DataSourceWithoutID = v0012DataSource.map(({ ID, ...rest }) => rest)

        const catalogData = {
            v0005: v0005DataSourceWithoutID,
            v0007: v0007DataSourceWithoutID,
            v0008: v0008DataSourceWithoutID,
            v0010: v0010DataSourceWithoutID,
            v0011: v0011DataSourceWithoutID,
            v0012: v0012DataSourceWithoutID,
        }

        const jsonString = JSON.stringify(catalogData, null, 2)
        const blob = new Blob([jsonString], { type: 'application/json' })
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.href = url
        link.download = 'exported_catalog_data.json'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const fileInputRef = useRef(null)

    const handleImportClick = () => {
        fileInputRef.current.click()
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                const content = e.target.result
                try {
                    const json = JSON.parse(content)
                    setV0005DataSource(json['v0005'] || [])
                    setV0007DataSource(json['v0007'] || [])
                    setV0008DataSource(json['v0008'] || [])
                    setV0010DataSource(json['v0010'] || [])
                    setV0011DataSource(json['v0011'] || [])
                    setV0012DataSource(json['v0012'] || [])
                } catch (error) {
                    alert(`File Format is invalid`)
                    console.error('Error parsing JSON:', error)
                }
            }
            reader.readAsText(file)
        }
    }

    const onSaving = (e) => {
        const changes = e.changes

        // Get the current data from the DataGrid instance
        const dataGrid = e.component
        const existingData = dataGrid.option('dataSource').map((item) => item.no)

        for (let i = 0; i < changes.length; i++) {
            const change = changes[i]
            if (change.type === 'insert' || change.type === 'update') {
                if (change.data.no === 0) {
                    e.cancel = true
                    alert('The "ID" Value cannot be 0')
                    return
                }
                if (existingData.includes(change.data.no)) {
                    e.cancel = true
                    alert('The "ID" Value must be unique')
                    return
                }
            }
        }
    }

    return (
        <div className='catalog-container'>
            <DQLoadPanel open={loading} />
            <DQToast ref={toastRef} />
            <input
                type='file'
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept='.json'
                onChange={handleFileChange}
            />
            <div className='catalog-control-container'>
                <div className='catalog-left-control-container'>
                    <div className='short-button-container'>
                        <Button
                            icon='import'
                            text={dqTranslateMsg('Page_Configuration_' + 'Import')}
                            width={'100%'}
                            height={'100%'}
                            onClick={handleImportClick}
                        />
                    </div>
                    <div className='short-button-container'>
                        <Button
                            icon='export'
                            text={dqTranslateMsg('Page_Configuration_' + 'Export')}
                            width={'100%'}
                            height={'100%'}
                            onClick={handleExportClick}
                        />
                    </div>
                    <div className='long-button-container'>
                        <Button
                            icon='download'
                            text={dqTranslateMsg('Page_Configuration_' + 'TemplateDownload')}
                            width={'100%'}
                            height={'100%'}
                            onClick={handleDownloadCatalogTemplate}
                        />
                    </div>
                </div>
                <div className='catalog-right-control-container'>
                    <div className='button-container'>
                        <CheckBox
                            key={'find-distribution-save-check-box'} // key 추가
                            text={dqTranslateMsg('Page_Configuration_' + 'Apply') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Plant') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Save')}
                            value={isDefaultSettings} // 권한 상태에 따라 체크 여부 결정
                            width={'100%'}
                            height={'100%'}
                            disabled={true}
                            // onValueChanged={handleCheckboxChange} // 상태 변경 핸들러 추가
                        />
                    </div>
                    <div className='button-container'>
                        <Button
                            icon='save'
                            text={dqTranslateMsg('Page_Configuration_' + 'Save')}
                            width={'100%'}
                            height={'100%'}
                            onClick={handleSaveButton}
                        />
                    </div>
                    {/* <div className='button-container'>
                        <Button
                            icon='undo'
                            text={dqTranslateMsg('Page_Configuration_' + 'Plant') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Reset')}
                            width={'100%'}
                            height={'100%'}
                            onClick={handlePlantResetButton}
                        />
                    </div> */}
                    <div className='button-container'>
                        <Button
                            icon='undo'
                            text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
                            width={'100%'}
                            height={'100%'}
                            onClick={handleResetButton}
                        />
                    </div>
                </div>
            </div>
            <div
                className='catalog-content-container'
                style={{ paddingTop: '25px' }}
            >
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        xs={4}
                        style={{ border: '0px solid black', width: '100%', height: '50%' }}
                    >
                        <DataGrid
                            ref={v0005Ref}
                            style={{ width: '100%', height: '100%' }}
                            dataSource={v0005DataSource}
                            showRowLines={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            keyExpr='no'
                            scrolling={{
                                columnRenderingMode: 'standard',
                                rowRenderingMode: 'standard',
                                mode: 'standard',
                                preloadEnabled: false,
                                renderAsync: false,
                                showScrollbar: 'onHover',
                                useNative: false,
                            }}
                            onSaving={onSaving}
                        >
                            <Editing
                                allowUpdating={true}
                                useIcons={true}
                                allowDeleting={true}
                                allowAdding={true}
                                mode='row'
                            />
                            <Column
                                dataField='no'
                                caption={dqTranslateMsg('Page_Configuration_' + 'ID')}
                                alignment='center'
                            />
                            <Column
                                dataField='desc'
                                caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                                alignment='center'
                            />
                            <Toolbar>
                                <Item location='before'>
                                    <TextTitle
                                        text={dqTranslateMsg('Db_field_Value_' + 'V0005') + '(V0005)'}
                                        width='100%'
                                        fontSize={'16px'}
                                        textAlign={'left'}
                                    />
                                </Item>
                                <Item name='addRowButton' />
                                <Item name='saveButton' />
                                <Item name='revertButton' />
                            </Toolbar>
                        </DataGrid>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ border: '0px solid black', width: '100%', height: '50%' }}
                    >
                        <DataGrid
                            ref={v0007Ref}
                            style={{ width: '100%', height: '100%' }}
                            dataSource={v0007DataSource}
                            showRowLines={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            keyExpr='no'
                            scrolling={{
                                columnRenderingMode: 'standard',
                                rowRenderingMode: 'standard',
                                mode: 'standard',
                                preloadEnabled: false,
                                renderAsync: false,
                                showScrollbar: 'onHover',
                                useNative: false,
                            }}
                            onSaving={onSaving}
                        >
                            <Editing
                                allowUpdating={true}
                                useIcons={true}
                                allowDeleting={true}
                                allowAdding={true}
                                mode='row'
                            />

                            <Column
                                dataField='no'
                                caption={dqTranslateMsg('Page_Configuration_' + 'ID')}
                                alignment='center'
                            />
                            <Column
                                dataField='desc'
                                caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                                alignment='center'
                            />
                            <Toolbar>
                                <Item location='before'>
                                    <TextTitle
                                        text={dqTranslateMsg('Db_field_Value_' + 'V0007') + '(V0007)'}
                                        width='100%'
                                        fontSize={'16px'}
                                        textAlign={'left'}
                                    />
                                </Item>
                                <Item name='addRowButton' />
                                <Item name='saveButton' />
                                <Item name='revertButton' />
                            </Toolbar>
                        </DataGrid>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ border: '0px solid black', width: '100%', height: '50%' }}
                    >
                        <DataGrid
                            ref={v0008Ref}
                            style={{ width: '100%', height: '100%' }}
                            dataSource={v0008DataSource}
                            showRowLines={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            keyExpr='no'
                            scrolling={{
                                columnRenderingMode: 'standard',
                                rowRenderingMode: 'standard',
                                mode: 'standard',
                                preloadEnabled: false,
                                renderAsync: false,
                                showScrollbar: 'onHover',
                                useNative: false,
                            }}
                            onSaving={onSaving}
                        >
                            <Editing
                                allowUpdating={true}
                                useIcons={true}
                                allowDeleting={true}
                                allowAdding={true}
                                mode='row'
                            />

                            <Column
                                dataField='no'
                                caption={dqTranslateMsg('Page_Configuration_' + 'ID')}
                                alignment='center'
                            />
                            <Column
                                dataField='desc'
                                caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                                alignment='center'
                            />
                            <Toolbar>
                                <Item location='before'>
                                    <TextTitle
                                        text={dqTranslateMsg('Db_field_Value_' + 'V0008') + '(V0008)'}
                                        width='100%'
                                        fontSize={'16px'}
                                        textAlign={'left'}
                                    />
                                </Item>
                                <Item name='addRowButton' />
                                <Item name='saveButton' />
                                <Item name='revertButton' />
                            </Toolbar>
                        </DataGrid>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ border: '0px solid black', width: '100%', height: '50%' }}
                    >
                        <DataGrid
                            ref={v0010Ref}
                            style={{ width: '100%', height: '100%' }}
                            dataSource={v0010DataSource}
                            showRowLines={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            keyExpr='no'
                            scrolling={{
                                columnRenderingMode: 'standard',
                                rowRenderingMode: 'standard',
                                mode: 'standard',
                                preloadEnabled: false,
                                renderAsync: false,
                                showScrollbar: 'onHover',
                                useNative: false,
                            }}
                            onSaving={onSaving}
                        >
                            <Editing
                                allowUpdating={true}
                                useIcons={true}
                                allowDeleting={true}
                                allowAdding={true}
                                mode='row'
                            />

                            <Column
                                dataField='no'
                                caption={dqTranslateMsg('Page_Configuration_' + 'ID')}
                                alignment='center'
                            />
                            <Column
                                dataField='desc'
                                caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                                alignment='center'
                            />
                            <Toolbar>
                                <Item location='before'>
                                    <TextTitle
                                        text={dqTranslateMsg('Db_field_Value_' + 'V0010') + '(V0010)'}
                                        width='100%'
                                        fontSize={'16px'}
                                        textAlign={'left'}
                                    />
                                </Item>
                                <Item name='addRowButton' />
                                <Item name='saveButton' />
                                <Item name='revertButton' />
                            </Toolbar>
                        </DataGrid>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ border: '0px solid black', width: '100%', height: '50%' }}
                    >
                        <DataGrid
                            ref={v0011Ref}
                            style={{ width: '100%', height: '100%' }}
                            dataSource={v0011DataSource}
                            showRowLines={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            keyExpr='no'
                            scrolling={{
                                columnRenderingMode: 'standard',
                                rowRenderingMode: 'standard',
                                mode: 'standard',
                                preloadEnabled: false,
                                renderAsync: false,
                                showScrollbar: 'onHover',
                                useNative: false,
                            }}
                            onSaving={onSaving}
                        >
                            <Editing
                                allowUpdating={true}
                                useIcons={true}
                                allowDeleting={true}
                                allowAdding={true}
                                mode='row'
                            />

                            <Column
                                dataField='no'
                                caption={dqTranslateMsg('Page_Configuration_' + 'ID')}
                                alignment='center'
                            />
                            <Column
                                dataField='desc'
                                caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                                alignment='center'
                            />
                            <Toolbar>
                                <Item location='before'>
                                    <TextTitle
                                        text={dqTranslateMsg('Db_field_Value_' + 'V0011') + '(V0011)'}
                                        width='100%'
                                        fontSize={'16px'}
                                        textAlign={'left'}
                                    />
                                </Item>
                                <Item name='addRowButton' />
                                <Item name='saveButton' />
                                <Item name='revertButton' />
                            </Toolbar>
                        </DataGrid>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{ border: '0px solid black', width: '100%', height: '50%' }}
                    >
                        <DataGrid
                            ref={v0012Ref}
                            style={{ width: '100%', height: '100%' }}
                            dataSource={v0012DataSource}
                            showRowLines={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            keyExpr='no'
                            scrolling={{
                                columnRenderingMode: 'standard',
                                rowRenderingMode: 'standard',
                                mode: 'standard',
                                preloadEnabled: false,
                                renderAsync: false,
                                showScrollbar: 'onHover',
                                useNative: false,
                            }}
                            onSaving={onSaving}
                        >
                            <Editing
                                allowUpdating={true}
                                useIcons={true}
                                allowDeleting={true}
                                allowAdding={true}
                                mode='row'
                            />

                            <Column
                                dataField='no'
                                caption={dqTranslateMsg('Page_Configuration_' + 'ID')}
                                alignment='center'
                            />
                            <Column
                                dataField='desc'
                                caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
                                alignment='center'
                            />
                            <Toolbar>
                                <Item location='before'>
                                    <TextTitle
                                        text={dqTranslateMsg('Db_field_Value_' + 'V0012') + '(V0012)'}
                                        width='100%'
                                        fontSize={'16px'}
                                        textAlign={'left'}
                                    />
                                </Item>
                                <Item name='addRowButton' />
                                <Item name='saveButton' />
                                <Item name='revertButton' />
                            </Toolbar>
                        </DataGrid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
