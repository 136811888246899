import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import JavaApiClient from 'src/api/JavaApiClient'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

const formattedTime = (time) => {
  return time.toLocaleTimeString()
}

function convertDateFormat(dateString) {
  // Create a Date object
  const year = dateString.substring(0, 4)
  const month = dateString.substring(4, 6)
  const day = dateString.substring(6, 8)
  const hour = dateString.substring(8, 10)
  const minute = dateString.substring(10, 12)
  const second = dateString.substring(12, 14)

  // Format to a more readable string
  const readableDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`

  return readableDate
}

const ScrollBoardTimeDisplay = () => {
  const [currentTime, setCurrentTime] = useState(new Date())
  const sbPageSettings = useSelector((state) => state.common.pages.scrollBoard)

  const updatedTime = sbPageSettings.page.updatedTime
  const updatedCount = sbPageSettings.page.updatedCount

  const fromDate = sbPageSettings.page.savedDataRangeStartDateTime
  const toDate = sbPageSettings.page.savedDataRangeEndDateTime

  // Function to update the current time
  const updateCurrentTime = () => {
    setCurrentTime(new Date())
  }

  // Use useEffect to update the current time every second
  useEffect(() => {
    const intervalId = setInterval(updateCurrentTime, 1000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <div style={{ width: '100%', height: '100%', padding: '5px', display: 'flex' }}>
      <div style={{ flex: 1, textAlign: 'center' }}>
        {dqTranslateMsg('Page_ScrollBoard_' + 'CurrentTime')}
        {formattedTime(currentTime)}
      </div>
      <div style={{ flex: 1, textAlign: 'center' }}>
        {dqTranslateMsg('Page_ScrollBoard_' + 'UpdatedTime')}
        {updatedTime ? formattedTime(new Date(updatedTime)) : ''}
      </div>
      <div style={{ flex: 2, textAlign: 'center' }}>
        {dqTranslateMsg('Page_ScrollBoard_' + 'DataPeriod')}
        {fromDate ? convertDateFormat(fromDate) : ''} ~ {toDate ? convertDateFormat(toDate) : ''}
      </div>
      <div style={{ flex: 1, textAlign: 'center' }}>
        {dqTranslateMsg('Page_ScrollBoard_' + 'UpdatedCount')}
        {updatedCount}
      </div>
    </div>
  )
}

export default ScrollBoardTimeDisplay
