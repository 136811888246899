import Form, { GroupItem, Tab, TabbedItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import HeaderIcon from 'src/functions/HeaderIcon'
import IL from 'src/functions/IconLoader'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { TopOrderTheme } from 'src/pages/scroll-board/components/side-panel/components/theme/common/TopOrderTheme'
import { GraphicsTheme } from 'src/pages/scroll-board/components/side-panel/components/theme/common/GraphicsTheme'
import { BarChartTheme } from 'src/pages/scroll-board/components/side-panel/components/theme/bar-chart/BarChartTheme'
import { QccChartTheme } from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/QccChartTheme'
import { RectChartTheme } from 'src/pages/scroll-board/components/side-panel/components/theme/tree-map/RectChartTheme'
import { ValueChartTheme } from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/ValueChartTheme'
import WarningSymbol from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/WarningSymbol'
import GoodSymbol from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/GoodSymbol'
import ChartLine from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/ChartLine'
import SpecificationLimit from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/SpecificationLimit'
import WarningLimit from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/WarningLimit'
import MarkArea from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/MarkArea'
import NGSymbol from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/NGSymbol'
import ThemeAverage from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/ThemeAverage'
import ThemeNominal from 'src/pages/scroll-board/components/side-panel/components/theme/value-chart/ThemeNominal'
import ThemeQCCSpecificationLimit from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/ThemeQCCSpecificationLimit'
import ThemeQCCWarningLimit from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/ThemeQCCWarningLimit'
import ThemeQCCControlLimit from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/ThemeQCCControlLimit'
import ThemeQCCAverageLimit from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/ThemeQCCAverageLimit'
import ThemeQCCNominalLimit from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/ThemeQCCNominalLimit'
import ThemeQCCNGSymbol from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/ThemeQCCNGSymbol'
import ThemeQCCWarningSymbol from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/ThemeQCCWarningSymbol'
import ThemeQCCGoodSymbol from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/ThemeQCCGoodSymbol'
import ThemeQCCChartLine from 'src/pages/scroll-board/components/side-panel/components/theme/qcc-chart/ThemeQCCChartLine'
import ThemeQCCPlausibilityLimit from './qcc-chart/ThemeQCCPlausibilityLimit'
import ThemeQCCScrapLimit from './qcc-chart/ThemeQCCScrapLimit'
import ThemeQCCAcceptanceLimit from './qcc-chart/ThemeQCCAcceptanceLimit'
import ThemeQCCMarkArea from './qcc-chart/ThemeQCCMarkArea'

const tabIconPath = (data, type, menuIconType, theme, title) => {
    const mode = HeaderIcon.getIconMenuPath(menuIconType)
    const themeType = theme

    let path = ''
    switch (type) {
        case 'Configuration':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.CONFIGURATION)
            break
        case 'BarChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.HISTOGRAM_CHART)
            break
        case 'Diagram':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.SUMMARY_LIST)
            break
        case 'TreeMap':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_LIST)
            break
        case 'ValueChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_CHART)
            break
        case 'QccChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.QUALITY_CONTROL_CHART)
            break
        default:
            break
    }

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {path !== '' && (
                <img
                    src={path}
                    style={{ width: '15px', height: '15px', transform: type === 'BarChart' ? 'rotate(270deg) scaleY(-1)' : 'none' }}
                />
            )}
            <span style={{ width: '100%', height: '50%' }}>{dqTranslateMsg('SidePanel_' + title)}</span>
        </div>
    )
}

const ThemeTabContainer = ({ pageKey, targetSettingMenu, pageState, configState }) => {
    const dispatch = useDispatch()
    const { getTheme } = useTheme()
    const theme = getTheme()
    // const pageKey = 'scrollBoard'
    // const pageState = useSelector((state) => state.common.pages[pageKey])
    const configSettings = configState

    const noTitle = false

    const commonItems = (pageState, configState) => [TopOrderTheme(pageKey, pageState, configState), GraphicsTheme(pageKey, pageState, configState)]
    const vChartItems = (pageState, configState) => [
        ValueChartTheme({ pageKey, pageState, configState, noTitle: noTitle }),
        NGSymbol({ pageKey, pageState, configState, noTitle: noTitle }),
        WarningSymbol({ pageKey, pageState, configState, noTitle: noTitle }),
        GoodSymbol({ pageKey, pageState, configState, noTitle: noTitle }),
        ChartLine({ pageKey, pageState, configState, noTitle: noTitle }),
        SpecificationLimit({ pageKey, pageState, configState, noTitle: noTitle }),
        WarningLimit({ pageKey, pageState, configState, noTitle: noTitle }),
        ThemeAverage(pageKey, pageState, configState),
        ThemeNominal(pageKey, pageState, configState),
        MarkArea({ pageKey, pageState, configState, noTitle: noTitle }),
    ]
    const bChartItems = (pageState, configState) => [BarChartTheme({ pageKey, pageState, configState, noTitle: noTitle })]
    const qChartItems = (pageState, configState) => [
        QccChartTheme({ pageKey, pageState, configState, noTitle: noTitle }),
        ThemeQCCNGSymbol(pageKey, pageState, configState),
        // ThemeQCCWarningSymbol(pageKey, pageState, configState),
        ThemeQCCGoodSymbol(pageKey, pageState, configState),
        ThemeQCCChartLine(pageKey, pageState, configState),
        ThemeQCCSpecificationLimit(pageKey, pageState, configState),
        ThemeQCCWarningLimit(pageKey, pageState, configState),
        ThemeQCCControlLimit(pageKey, pageState, configState),
        ThemeQCCAverageLimit(pageKey, pageState, configState),
        ThemeQCCNominalLimit(pageKey, pageState, configState),
        ThemeQCCPlausibilityLimit(pageKey, pageState, configState),
        ThemeQCCScrapLimit(pageKey, pageState, configState),
        ThemeQCCAcceptanceLimit(pageKey, pageState, configState),
        ThemeQCCMarkArea(pageKey, pageState, configState),
    ]
    const tMapItems = (pageState, configState) => [RectChartTheme({ pageKey, pageState, configState, noTitle: noTitle })]

    // const tabStyle = {
    //   width: '100%',
    //   height: '100%',
    //   overflowY: 'auto',
    // }

    return (
        <Form
            id='side-panel-scroll-board-theme-form'
            style={{ width: '100%', height: '100%', padding: '5px' }}
            labelMode={'outside'}
            formData={pageState.page}
            showColonAfterLabel={false}
        >
            {targetSettingMenu === 0 && commonItems(pageState, configState)}
            {targetSettingMenu === 1 && vChartItems(pageState, configState)}
            {targetSettingMenu === 2 && bChartItems(pageState, configState)}
            {targetSettingMenu === 3 && qChartItems(pageState, configState)}
            {targetSettingMenu === 4 && tMapItems(pageState, configState)}
        </Form>
    )
}

export default ThemeTabContainer
