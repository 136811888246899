import { Button } from 'devextreme-react/button'
import List from 'devextreme-react/list'
import SelectBox from 'devextreme-react/select-box'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useAuth } from 'src/contexts/auth'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { setQSense } from 'src/store/common'
import './PushSettingsEncoding.scss'
import { connect } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { configurationSettingsPrm } from 'src/pages/config/components/settings/ConfigSettings'
import Form, { GroupItem, SimpleItem } from 'devextreme-react/form'
import DQToast from 'src/functions/DQToast'

const partKey = ['p1001', 'p1002']
const charKey = ['c2001', 'c2002']

export default function PushSettingsEncoding(props) {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const common = useSelector((state) => state.common)

    const toastRef = useRef(null)

    // const common = useSelector((state) => state.common)
    const curPage = common.pages[pageKey]
    const useField = common.pages.others.useFieldList
    const usePartFilterField = useField.part.map((field) => field.value)
    const useCharFilterField = useField.char.map((field) => field.value)

    const handleSaveButton = () => {
        dqsImpl.setCurQSenseSettings(dispatch, user, curPage, pageKey, curPage)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_Configuration_' + 'SavedSettings'))
    }
    const handleResetButton = () => {
        const next = produce(curPage, (draft) => {
            draft.encoding = configurationSettingsPrm.encoding
        })

        dqsImpl.setCurQSenseSettings(dispatch, user, curPage, pageKey, next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('Page_Configuration_' + 'ResetSettings'))
    }
    const handlePartKeySelect = (e) => {
        const isExist = curPage.encoding.part_key_field.some((item) => item === e.itemData)

        if (!isExist) {
            const next = produce(curPage, (draft) => {
                draft.encoding.part_key_field.push(e.itemData)
            })
            dispatch(ps.configuration.setPageSettingsToRedux(next))
        }
    }
    const handleCharKeySelect = (e) => {
        const isExist = curPage.encoding.char_key_field.some((item) => item === e.itemData)

        if (!isExist) {
            const next = produce(curPage, (draft) => {
                draft.encoding.char_key_field.push(e.itemData)
            })
            dispatch(ps.configuration.setPageSettingsToRedux(next))
        }
    }
    const handlePartKeyDelete = (e) => {
        const next = produce(curPage, (draft) => {
            draft.encoding.part_key_field = draft.encoding.part_key_field.filter((element) => element !== e.itemData)
        })
        dispatch(dispatch(ps.configuration.setPageSettingsToRedux(next)))
    }
    const handleCharKeyDelete = (e) => {
        const next = produce(curPage, (draft) => {
            draft.encoding.char_key_field = draft.encoding.char_key_field.filter((element) => element !== e.itemData)
        })
        dispatch(dispatch(ps.configuration.setPageSettingsToRedux(next)))
    }

    return (
        <div className='key-info-container'>
            <DQLoadPanel open={loading} />
            <DQToast ref={toastRef} />
            <div className='key-info-control-container'>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='save'
                        text={dqTranslateMsg('Page_Configuration_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            <div
                className='catalog-content-container'
                style={{ paddingRight: '20px', borderRight: `1px solid #888  ` }}
            >
                <Form>
                    <GroupItem caption={dqTranslateMsg('Page_Configuration_KeyFields')}>
                        <GroupItem caption={dqTranslateMsg('Page_Configuration_PartKey')}>
                            <SimpleItem>
                                <SelectBox
                                    items={usePartFilterField}
                                    defaultValue={curPage.encoding.part_key_field[0]}
                                    onItemClick={handlePartKeySelect}
                                />
                            </SimpleItem>
                        </GroupItem>

                        <GroupItem caption={dqTranslateMsg('Page_Configuration_PartKeyList')}>
                            <SimpleItem>
                                <List
                                    dataSource={[...curPage.encoding.part_key_field]}
                                    allowItemDeleting={true}
                                    itemDeleteMode={'static'}
                                    noDataText={dqTranslateMsg('Page_Configuration_NoData')}
                                    onItemDeleted={handlePartKeyDelete}
                                />
                            </SimpleItem>
                        </GroupItem>

                        <GroupItem caption={dqTranslateMsg('Page_Configuration_CharacteristicKey')}>
                            <SimpleItem>
                                <SelectBox
                                    items={useCharFilterField}
                                    defaultValue={curPage.encoding.char_key_field[0]}
                                    onItemClick={handleCharKeySelect}
                                />
                            </SimpleItem>
                        </GroupItem>

                        <GroupItem caption={dqTranslateMsg('Page_Configuration_CharacteristicKeyList')}>
                            <SimpleItem>
                                <List
                                    dataSource={[...curPage.encoding.char_key_field]}
                                    allowItemDeleting={true}
                                    itemDeleteMode={'static'}
                                    noDataText={dqTranslateMsg('Page_Configuration_NoData')}
                                    onItemDeleted={handleCharKeyDelete}
                                />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>
                </Form>
            </div>
        </div>
    )
}
