import './admin.scss'
import AdminForm from './components/main-view/AdminForm'

export default function Admin(pageKey) {
  return (
    <div className="admin-container">
      <div className="admin-content-container">
        <AdminForm pageKey={pageKey} />
      </div>
    </div>
  )
}
