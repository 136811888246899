import Form, { Item, GroupItem, Label, EmptyItem, ValidationRule } from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDatabaseInfo, getDatabaseList, setDatabaseInfo } from 'src/api/db'
import ApiStatus from 'src/api/defines/ApiStatus'
import { useAuth } from 'src/contexts/auth'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './PushSettingsDBInfo.scss'
import { Toast } from 'devextreme-react'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
import DQToast from 'src/functions/DQToast'

const dbTypeDefine = () => [
    {
        value: 0,
        text: dqTranslateMsg('Page_AdminSettings_' + 'MySQL'),
    },
    {
        value: 1,
        text: dqTranslateMsg('Page_AdminSettings_' + 'MsSQL'),
    },
    {
        value: 2,
        text: dqTranslateMsg('Page_AdminSettings_' + 'MongoDB'),
    },
]

// const newDBInfo = {
// 	db_info_key: formData.db_info_key,
// 	db_address: formData.db_address,
// 	db_password: formData.db_password,
// 	db_id: formData.db_id,
// 	db_port: formData.db_port,
// 	db_type: formData.db_type,
// 	db_name: formData.db_name,
// }
const convertDBInfoForForm = (dbInfo) => {
    if (dbInfo && dbInfo.length > 0) {
        const info = dbInfo[0]
        const converted = {
            db_info_key: info.db_info_key,
            db_address: info.db_address,
            db_id: info.db_id,
            db_password: info.db_password,
            db_port: info.db_port,
            db_type: info.db_type, // 0 : MySQL, 1: MsSQL, 2: MongoDB
            db_name: info.db_name,
        }

        return converted
    } else {
        return []
    }
}

export default function PushSettingsDBInfo(props) {
    const formRef = useRef(null) // Form 컴포넌트에 대한 참조 생성
    const selectNameRef = useRef(null) // Form 컴포넌트에 대한 참조 생성
    const selectTypeRef = useRef(null) // Form 컴포넌트에 대한 참조 생성

    const stateAll = props.state
    const state = props.state.dbConfig
    const setState = props.setState

    const [DBInfo, setDBInfo] = useState(null) // For UI
    const [DBList, setDBList] = useState(null) // For UI

    const toastRef = useRef(null)

    const handleGetDatabaseList = () => {
        selectNameRef.current.open()
    }
    const handleSetDatabaseInfo = () => {
        const formData = formRef.current.instance.option('formData')

        // Save for UI
        const newDBInfo = {
            db_info_key: formData.db_info_key,
            db_address: formData.db_address,
            db_password: formData.db_password,
            db_id: formData.db_id,
            db_port: formData.db_port,
            db_type: formData.db_type,
            db_name: formData.db_name,
        }
        const info = [newDBInfo]
        setDBInfo(info)

        // Save Data Temporary
        const newStateInfo = {
            nickName: formData.db_info_key,
            address: formData.db_address,
            password: formData.db_password,
            username: formData.db_id,
            port: formData.db_port,
            db_type: formData.db_type,
            db_name: formData.db_name,
        }
        const newState = { ...stateAll }
        newState.dbConfig = [newStateInfo]
        setState(newState)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }

    const buttonSaveEditorOptions = {
        stylingMode: 'outlined',
        text: dqTranslateMsg('Page_AdminSettings_' + 'Save'),
        // type: 'success',
        width: '100%',
        onClick: (e) => {
            console.log('handleSetDatabaseInfo')
            handleSetDatabaseInfo()
        },
    }

    const textKeyEditorOptions = {
        stylingMode: 'filled',
        format: 'fixedPoint',
        readOnly: true,
    }
    const textEditorOptions = {
        stylingMode: 'filled',
        format: 'fixedPoint',
    }

    const changePasswordMode = useCallback((name) => {
        const editor = formRef.current.instance.getEditor(name)
        editor.option('mode', editor.option('mode') === 'text' ? 'password' : 'text')
    }, [])

    const textPasswordEditorOptions = {
        stylingMode: 'filled',
        // showSpinButtons: true,
        mode: 'password',
        format: 'fixedPoint',
        showClearButton: true,
        buttons: [
            {
                name: 'password',
                location: 'after',
                options: {
                    stylingMode: 'text',
                    icon: 'eyeopen',
                    onClick: () => changePasswordMode('Password'),
                },
            },
        ],
    }

    const numberEditorOptions = {
        stylingMode: 'filled',
        showSpinButtons: true,
        mode: 'number',
        // format: 'fixedPoint',
    }

    const handleNameInitialized = (e) => {
        selectNameRef.current = e.component
    }

    const handleTypeInitialized = (e) => {
        selectTypeRef.current = e.component
    }
    const handleFocusIn = () => {
        console.log('handleFocusIn')
        const formData = formRef.current.instance.option('formData')

        const req = {
            address: formData.db_address,
            port: formData.db_port,
            username: formData.db_id,
            password: formData.db_password,
        }

        getDatabaseList(req).then((result) => {
            if (result.length > 0) {
                formRef.current.instance.option('formData', {})
                selectNameRef.current.option('items', result)
                selectNameRef.current.option('value', formData.db_name)
                // // 성공 메시지 표시
            } else {
                // 결과가 없는 경우도 DBInfo를 업데이트
                formRef.current.instance.option('formData', {
                    ...formData,
                })

                selectNameRef.current.option('items', result)
                selectNameRef.current.option('value', formData.db_name)
            }
        })
    }

    const selectTypeEditorOptions = (dbType) => {
        return {
            stylingMode: 'filled',
            items: dbTypeDefine(),
            searchEnabled: false,
            value: dbType !== null ? dbType : '',
            valueExpr: 'value',
            displayExpr: 'text',
        }
    }

    const selectNameEditorOptions = (dbList, dbName) => {
        return {
            stylingMode: 'filled',
            items: dbList ? dbList : [],
            searchEnabled: false,
            value: dbName ? dbName : '',
            onFocusIn: handleFocusIn,
            onInitialized: handleNameInitialized,
            onOpened: handleGetDatabaseList,
        }
    }

    function LabelTemplate(iconName) {
        return function template(data) {
            return <div>{data.text}</div>
        }
    }

    useEffect(() => {
        const processDBSettings = async () => {
            if (state && state.length > 0) {
                const newDBInfo = {
                    db_info_key: state[0].nickName,
                    db_address: state[0].address,
                    db_id: state[0].username,
                    db_password: state[0].password,
                    db_port: state[0].port,
                    db_type: state[0].db_type || 0,
                    db_name: state[0].db_name,
                }

                // get DBList
                const req = {
                    address: newDBInfo.db_address,
                    port: newDBInfo.db_port,
                    username: newDBInfo.db_id,
                    password: newDBInfo.db_password,
                }
                const dbNameList = await getDatabaseList(req)

                if (dbNameList) {
                    setDBList(dbNameList)
                }
                setDBInfo(newDBInfo)
            }
        }

        processDBSettings()
    }, [])

    return (
        <div
            className='push-settings-db-info-container'
            style={
                {
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                }
            }
        >
            <DQToast ref={toastRef} />
            {DBInfo && (
                <Form
                    ref={formRef} // Form 컴포넌트에 ref 연결
                    // id="form"
                    labelMode={'outside'}
                    formData={DBInfo}
                    readOnly={false}
                    showColonAfterLabel={false}
                    labelLocation={'top'}
                    minColWidth={300}
                    colCount={2}
                >
                    <Item
                        dataField={'db_info_key'}
                        editorType={'dxTextBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Key') }}
                        editorOptions={textKeyEditorOptions}
                    >
                        <Label render={LabelTemplate('column')} />
                    </Item>
                    <Item
                        dataField={'db_address'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Address') }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                            ...textEditorOptions,
                            validationMessageMode: 'always', // This ensures the validation message is shown immediately
                        }}
                    >
                        <Label render={LabelTemplate('Address')} />
                        <ValidationRule
                            type='pattern'
                            pattern='^[a-zA-Z0-9._-]*$' // This pattern allows English letters, digits, periods, hyphens, and underscores
                            message='Only English letters, digits, periods, hyphens, and underscores are allowed.'
                        />
                    </Item>
                    <Item
                        dataField={'db_id'}
                        editorType={'dxTextBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'ID') }}
                        editorOptions={textEditorOptions}
                    >
                        <Label render={LabelTemplate('row')} />
                    </Item>
                    <Item
                        dataField={'db_password'}
                        // editorType={'dxNumberBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Password') }}
                        editorOptions={textPasswordEditorOptions}
                    >
                        <Label render={LabelTemplate('column')} />
                    </Item>
                    <Item
                        dataField={'db_port'}
                        editorType={'dxNumberBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Port') }}
                        editorOptions={numberEditorOptions}
                    ></Item>
                    <Item
                        dataField={'db_type'}
                        editorType={'dxSelectBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Type') }}
                        editorOptions={selectTypeEditorOptions(DBInfo.db_type)}
                    >
                        <Label render={LabelTemplate('row')} />
                    </Item>
                    <Item
                        dataField={'db_name'}
                        editorType={'dxSelectBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'DBName') }}
                        editorOptions={selectNameEditorOptions(DBList, DBInfo.db_name)}
                    ></Item>

                    <EmptyItem />
                    <Item
                        editorType={'dxButton'}
                        colSpan={2}
                        editorOptions={buttonSaveEditorOptions}
                    >
                        {/* <Label render={LabelTemplate('row')} /> */}
                    </Item>
                </Form>
            )}
        </div>
    )
}
