// import { backendPath } from './defines/DBInfo'
import { pythonBackendPath } from './defines/PythonDBInfo'
import { makeRequestParameter } from 'src/pages/chart-probability-plot/components/main-view/ProbabilityPlotChartFormImpl'
import JwtUtils from 'src/functions/JwtUtils'

export const getProbability = async (targetChar, targetValue, settings, configSettings) => {
  const prm = makeRequestParameter(targetChar, targetValue, settings, configSettings, configSettings.findDistribution)

  const requestBody = prm

  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(pythonBackendPath + 'py/getProbability', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}
