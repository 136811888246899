import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import './polyfills'
import reportWebVitals from './reportWebVitals'
// import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './functions/error/ErrorBoundaryFallBack'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={(details) => {
      //Reset the state of your app so the error doesn't happen again
    }}
  >
    <App />
  </ErrorBoundary>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
