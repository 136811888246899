import produce from 'immer'
import { getArima } from 'src/api/arima'
import ApiStatus from 'src/api/defines/ApiStatus'
import * as VCUtils from 'src/functions/ValueChartUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

// class ValueChartArima {
export const arimaNameTranslate = (itemName) => {
  if (itemName === 'low_pred') {
    return dqTranslateMsg('Page_ValueChart_' + 'LowPred')
  } else if (itemName === 'upper_pred') {
    return dqTranslateMsg('Page_ValueChart_' + 'UpperPred')
  } else {
    return dqTranslateMsg('Page_ValueChart_' + 'Pred')
  }
}

export const updateArimaCharts = (
  chartRefs,
  curPage,
  selectedChars
  // loadingPanelFunc
) => {
  const forecastPromises = chartRefs.map((chartRef, index) => {
    if (chartRef && chartRef.current && chartRef.current.chartElement) {
      const chartInstance = chartRef.current.chartElement.getEchartsInstance()
      const chartOptions = chartInstance.getOption()
      const seriesData = chartOptions.series.find((series) => series.name === dqTranslateMsg('Page_ValueChart_' + 'ValueInfo')).data

      const { sampleSize, forecastSize } = VCUtils.adjustedForecastSampleSize(curPage.page.arima.forecastSize, curPage.page.arima.sampleSize, seriesData.length)

      const srcData = seriesData.slice(-sampleSize).map((xy) => xy[1])
      return this.fetchArimaForecast(chartRef, index, srcData, curPage, forecastSize)
    }
  })
}

export const updateArimaChart = (
  chartRef,
  curPage,
  selectedChars,
  option
  // loadingPanelFunc
) => {
  if (chartRef && chartRef.current) {
    const chartInstance = chartRef.current.getEchartsInstance()
    // const chartOptions = chartInstance.getOption()
    const chartOptions = option
    const seriesData = chartOptions.series.find((series) => series.name === dqTranslateMsg('Page_ValueChart_' + 'ValueInfo')).data

    const { sampleSize, forecastSize } = VCUtils.adjustedForecastSampleSize(curPage.page.arima.forecastSize, curPage.page.arima.sampleSize, seriesData.length)

    const srcData = seriesData.slice(-sampleSize).map((xy) => xy[1])
    return this.fetchArimaForecast(
      chartRef,
      // index,
      srcData,
      curPage,
      forecastSize,
      option
    )
  }
}

// Fetch ARIMA forecast data and update chart
export const fetchArimaForecast = async (
  chartRef,
  // chartIndex,
  srcData,
  curPage,
  forecastSize,
  option
) => {
  const { auto, pValue, dValue, qValue } = curPage.page.arima

  let fOption = option
  const response = await getArima(auto, pValue, dValue, qValue, forecastSize, srcData)

  if (chartRef && chartRef.current) {
    const chartInstance = chartRef.current.getEchartsInstance()
    const chartOptions = option
    fOption = this.updateChartWithForecast(curPage, response, chartOptions, chartInstance)
  }

  return fOption
}

// Update chart with ARIMA forecast data
export const updateChartWithForecast = (curPage, forecastData, chartOptions, chartInstance) => {
  const expectFieldType = ['low_pred', 'pred', 'upper_pred']
  const forecastFormat = { expectation: [] }
  const minMaxValues = []

  expectFieldType.forEach((field) => {
    forecastFormat.expectation.push({
      name: field,
      data: forecastData[field],
    })
    minMaxValues.push(...forecastData[field])
  })

  const min = Math.min(...minMaxValues)
  const max = Math.max(...minMaxValues)
  const addDataLength = forecastFormat.expectation[0].data.length

  const chartXAxis = chartOptions.xAxis
  let chartXAxisLength = chartXAxis.data.length + addDataLength
  const chartOneSeries = chartOptions.series.find((series) => series.name === dqTranslateMsg('Page_ValueChart_' + 'ValueInfo'))
  let charDataLength = chartOneSeries.data.length

  const xAxisData = []
  for (let i = 1; i <= chartXAxisLength; i++) {
    xAxisData.push([i])
  }

  const addedArimaOption = this.produceNewChartOptions(curPage, chartOptions, forecastFormat, min, max, xAxisData, charDataLength)

  return addedArimaOption
  // chartInstance.setOption(newChartOptions)
}

// Function to produce new chart options
export const produceNewChartOptions = (curPage, chartOptions, forecastFormat, min, max, xAxisData, charDataLength) => {
  const next = produce(chartOptions, (draft) => {
    forecastFormat.expectation.forEach((item) => {
      let index = charDataLength + 1
      draft.series.push({
        type: 'line',
        name: this.arimaNameTranslate(item.name),
        data: item.data.map((data) => [index++, data]),
        itemStyle: {
          color: item.name === 'low_pred' || item.name === 'upper_pred' ? curPage.lineChart.arimaChartLines.outer.color : curPage.lineChart.arimaChartLines.inner.color,
        },
        lineStyle: {
          color: item.name === 'low_pred' || item.name === 'upper_pred' ? curPage.lineChart.arimaChartLines.outer.color : curPage.lineChart.arimaChartLines.inner.color,
        },
      })
      draft.xAxis.data = xAxisData
      draft.xAxis.min = 0
      draft.xAxis.max = xAxisData.length

      draft.yAxis.min = chartOptions.yAxis.min < min ? draft.yAxis.min : min
      draft.yAxis.max = chartOptions.yAxis.max > max ? draft.yAxis.max : max
    })
  })

  return next
}
// }

// export default ValueChartArima
