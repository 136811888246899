// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-card .dx-card {
  width: 100%;
  margin: 0;
  flex-grow: 0;
}
.single-card .dx-card .header .title {
  color: gray;
  line-height: 50px;
  font-weight: 500;
  font-size: 24px;
}
.single-card .dx-card .header .description {
  color: rgba(255, 255, 255, 0.7);
  line-height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/layout-form/TitleFunctionCard.scss"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,SAAA;EACA,YAAA;AAFJ;AAOM;EACE,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AALR;AAQM;EACE,+BAAA;EACA,iBAAA;AANR","sourcesContent":["@import '../../themes/generated/variables.base.scss';\r\n\r\n.single-card {\r\n  .dx-card {\r\n    width: 100%;\r\n    margin: 0;\r\n    flex-grow: 0;\r\n\r\n    .header {\r\n      // margin-bottom: 30px;\r\n\r\n      .title {\r\n        color: darken($base-text-color, 50);\r\n        line-height: 50px;\r\n        font-weight: 500;\r\n        font-size: 24px;\r\n      }\r\n\r\n      .description {\r\n        color: rgba($base-text-color, alpha($base-text-color) * 0.7);\r\n        line-height: 18px;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
