import { pythonBackendPath } from './defines/PythonDBInfo'
import JwtUtils from 'src/functions/JwtUtils'
const path = pythonBackendPath

// 파이썬용
export const getDecisionTree = async (srcData, cartSet) => {
  const useAutoCalc = cartSet.useAutoCalc
  const useMaxDepth = cartSet.advanced.useMaxDepth
  const useRandomState = cartSet.advanced.useRandomState

  const requestBody = {
    srcColumnsData: srcData.columnsData,
    srcColumns: srcData.columnsName,
    srcTargetData: srcData.targetData,
    srcTarget: srcData.targetName,
    ...(useAutoCalc && {
      useAutoCalc: true,
      shuffle: cartSet.advanced.shuffle,
    }),
    ...(!useAutoCalc && {
      useAutoCalc: false,
      trainSize: cartSet.advanced.trainSize,
      ...(useMaxDepth && { maxDepth: cartSet.advanced.maxDepth }),
      ...(useRandomState && { randomState: cartSet.advanced.randomState }),
      criterion: cartSet.advanced.criterion,
      shuffle: cartSet.advanced.shuffle,
    }),
  }

  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'py/getDecisionTree', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}
