import React from 'react'
import CompanyCopyRightForm from 'src/pages/info-company/components/main-view/CompanyCopyRightForm'
import CompanyInfoForm from 'src/pages/info-company/components/main-view/CompanyInfoForm'
import './info-company.scss'

export default function CompanyInfo() {
  return (
    <React.Fragment>
      <div className="company-info-container">
        <div className="company-info-content-container">
          <CompanyInfoForm />
        </div>
        <div className="company-copyright-content-container">
          <CompanyCopyRightForm />
        </div>
      </div>
    </React.Fragment>
  )
}
