import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as qccXBar from './ScrollBoardQCCXBarChartOptionImpl'
import * as qccRange from './ScrollBoardQCCRangeChartOptionImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import { getQccChartSubData, getScrollBoardQccChartSubData } from 'src/functions/QccProcessUtils'

const formatLimitInfo = (label, value, char, configSettings) => {
    return value !== undefined && typeof value === 'number' ? `${label} : ${DNUtils.axisValueDisplay(value, char, configSettings)}<br>` : ''
}

const toolTipBalloonCallback = (args, settings, configSettings, char) => {
    let displayStr = ''

    if (args[0].seriesName) {
        displayStr += `${args[0].seriesName}<br>`
    }

    if (args[0].data) {
        displayStr += `${dqTranslateMsg('Page_QualityControlChart_' + 'Number')} : ${args[0].data[0]}<br>`
        displayStr += `${dqTranslateMsg('Page_QualityControlChart_' + 'Value')} : ${DNUtils.valueDisplay(args[0].data[1], char, configSettings)}<br>`
    }

    const useSpecLimits = settings.page.qualityControlChart.specificationLimitEnable
    const useWarnLimits = settings.page.qualityControlChart.warningLimitEnable
    const useControlLimits = settings.page.qualityControlChart.controlLimitEnable
    const useAverageLimits = settings.page.qualityControlChart.averageLimitEnable
    const useNominalLimits = settings.page.qualityControlChart.nominalLimitEnable

    if (useSpecLimits || useWarnLimits || useControlLimits || useAverageLimits || useNominalLimits) {
        displayStr += `<br>${dqTranslateMsg('Page_QualityControlChart_' + 'Limits')}<br>`

        if (useSpecLimits) {
            displayStr += formatLimitInfo('USL', settings.usl, char, configSettings)
            displayStr += formatLimitInfo('LSL', settings.lsl, char, configSettings)
        }

        if (useWarnLimits) {
            displayStr += formatLimitInfo('UWL', settings.uwl, char, configSettings)
            displayStr += formatLimitInfo('LWL', settings.lwl, char, configSettings)
        }

        if (useControlLimits) {
            displayStr += formatLimitInfo('UCL', settings.ucl, char, configSettings)
            displayStr += formatLimitInfo('LCL', settings.lcl, char, configSettings)
        }

        if (useAverageLimits) {
            displayStr += formatLimitInfo('X\u0304', settings.average, char, configSettings)
        }

        if (useNominalLimits) {
            displayStr += formatLimitInfo('Nominal', settings.nominal, char, configSettings)
        }
    }

    return displayStr
}

export const getQccChartXBarOptionArray = (settings, configSettings, targetChar, targetValue) => {
    const titleText = `${DBUtils.getFieldData(targetChar, 'c2002') ?? 'empty'} P:${DBUtils.getFieldData(targetChar, 'part_id') ?? '?'} / C:${
        DBUtils.getFieldData(targetChar, 'char_id') ?? '?'
    } / X-Bar / R Chart)`

    const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
    const filteredValue = targetValue

    const xBarCalcCallback = qccXBar.makeQccUpperSeriesData
    const { lcl, ucl, average, seriesData, values } = getScrollBoardQccChartSubData('xBar', settings, configSettings, targetChar, filteredValue, xBarCalcCallback)

    const maxValue = qccXBar.findLargestNumber(values)
    const minValue = qccXBar.findSmallestNumber(values)
    const xAxisRight = seriesData.length + 1
    const xAxisLeft = 0

    const topBottomCalcArray = {
        settings,
        usl,
        lsl,
        uwl,
        lwl,
        ucl,
        lcl,
        upl,
        lpl,
        url,
        lrl,
        uacl,
        lacl,
        maxValue,
        minValue,
    }

    const yAxisTop = qccXBar.makeYAxisTop(topBottomCalcArray)
    const yAxisBottom = qccXBar.makeYAxisBottom(topBottomCalcArray)
    const decimalPlaces = DBUtils.getFieldData(targetChar, 'c2022') ?? configSettings.commonSettings.displayDecimalPlaces

    // chart setting reorganization
    const addedSettings = {
        ...settings,
        configSettings,
        titleText,
        lsl,
        usl,
        lwl,
        uwl,
        upl,
        lpl,
        url,
        lrl,
        lacl,
        uacl,
        lcl,
        ucl,
        center: average,
        average,
        nominal,
        // warnOffset,
        xAxisRight,
        xAxisLeft,
        yAxisTop,
        yAxisBottom,
        decimalPlaces,
        char: targetChar,
    }

    return {
        title: {
            text: addedSettings.titleText,
            left: 'center',
            textStyle: {
                fontFamily: 'Arial',
                fontSize: (settings.theme.qccChartTitleFontSize / (1 + 1)) * 4,
            },
        },
        animationDuration: 300,
        grid: {
            top: '15%',
            bottom: '7%',
            left: '0%',
            right: '5%',
            containLabel: true,
        },
        animationDuration: 300,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        toolbox: {
            feature: {
                saveAsImage: {},
            },
            itemSize: 15,
        },
        tooltip: {
            trigger: 'axis',
            appendToBody: true,
            position: function (pos, params, dom, rect, size) {
                let obj = { top: 60 }
                obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
                return obj
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            extraCssText: 'width: 170px; text-align: left;',
            formatter: function (args) {
                return toolTipBalloonCallback(args, addedSettings, configSettings, targetChar)
            },
            textStyle: {
                fontFamily: 'Arial',
            },
        },
        xAxis: {
            show: true,
            type: 'value',
            min: xAxisLeft,
            max: xAxisRight,
            minInterval: 1,
            maxInterval: addedSettings.page.qualityControlChart.xAxisDenseLabel ? 1 : xAxisRight < 10 ? 1 : Math.abs(Math.floor(addedSettings.xAxisRight / 5)),
            axisLabel: {
                rotate: 90,
                fontFamily: 'Arial',
                hideOverlap: true,
                showMinLabel: false,
                showMaxLabel: false,
                fontSize: (settings.theme.qccChartXAxisFontSize / (1 + 1)) * 3,
            },
            splitLine: {
                show: false,
            },
            axisLine: {
                onZero: false,
                hideOverlap: true,
            },
            axisTick: {
                interval: 1,
            },
        },
        yAxis: {
            show: true,
            min: addedSettings.yAxisBottom,
            max: addedSettings.yAxisTop,
            type: 'value',
            axisLabel: {
                fontFamily: 'Arial',
                // formatter: function (value) {
                //   return DNUtils.axisValueDisplay(value, targetChar, configSettings)
                // },
                formatter: function (value, index) {
                    let axisMax = addedSettings.yAxisTop
                    let axisMin = addedSettings.yAxisBottom

                    if (value === axisMin || value === axisMax) {
                        return ''
                    }
                    return value
                },
                fontSize: (settings.theme.qccChartYAxisFontSize / (1 + 1)) * 3,
            },
            nameTextStyle: {
                fontFamily: 'Arial',
            },
            splitLine: {
                show: true,
            },
            minorTick: {
                show: false,
            },
            axisLine: {
                onZero: false,
            },
        },
        legend: {
            show: false,
            textStyle: {
                fontFamily: 'Arial',
            },
        },
        dataZoom: [
            {
                show: true,
                type: 'slider',
                orient: 'horizontal',
                height: '3%',
                bottom: '3%',
                brushSelect: false,
                showDataShadow: true,
                showDetail: false,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'slider',
                orient: 'vertical',
                width: '2%',
                brushSelect: true,
                showDataShadow: true,
                showDetail: false,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'inside',
                xAxisIndex: 0,
                filterMode: 'none',
                zoomLock: false, // Allow zooming
                zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
            },
            {
                show: true,
                type: 'inside',
                xAxisIndex: 0,
                filterMode: 'none',
            },
            // {
            //   show: true,
            //   type: 'slider',
            //   orient: 'horizontal',
            //   xAxisIndex: 0,
            //   height: `3%`,
            //   bottom: '3%',
            //   brushSelect: true,
            //   showDataShadow: true,
            //   showDetail: false,
            //   filterMode: 'none',
            // },
            // {
            //   show: true,
            //   type: 'slider',
            //   orient: 'vertical',
            //   width: `3%`,
            //   brushSelect: true,
            //   showDataShadow: true,
            //   showDetail: false,
            //   filterMode: 'none',
            // },
            // {
            //   show: true,
            //   type: 'inside',
            //   yAxisIndex: 0,
            //   filterMode: 'none',
            //   zoomLock: false, // Allow zooming
            //   zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
            // },
            // {
            //   show: true,
            //   type: 'inside',
            //   xAxisIndex: 0,
            //   filterMode: 'none',
            // },
        ],
        series: [
            {
                type: 'line',
                name: 'X\u0304',
                data: seriesData,
                symbol: (value, params) => qccXBar.makeSymbol(value, params, addedSettings),
                symbolSize: (value, params) => qccXBar.makeSymbolSize(value, params, addedSettings),
                itemStyle: {
                    color: (params) => qccXBar.makeSymbolColor(params, addedSettings),
                },
                lineStyle: qccXBar.convertToChartLine(settings),
                markLine: {
                    animation: false,
                    symbol: 'none',
                    precision: 10,
                    emphasis: {
                        disabled: true,
                    },
                    data: qccXBar.convertToMarkLine(addedSettings),
                },
                markArea: {
                    type: 'line',
                    name: 'Areas',
                    emphasis: {
                        disabled: true,
                    },
                    data: qccXBar.convertToMarkArea(addedSettings),
                },
            },
        ],
    }
}

const toolTipRangeBalloonCallback = (args, settings, configSettings, char) => {
    let displayStr = ''

    if (args[0].seriesName) {
        displayStr += `${args[0].seriesName}<br>`
    }

    if (args[0].data) {
        displayStr += `${dqTranslateMsg('Page_QualityControlChart_' + 'Number')} : ${args[0].data[0]}<br>`
        displayStr += `${dqTranslateMsg('Page_QualityControlChart_' + 'Value')} : ${DNUtils.valueDisplay(args[0].data[1], char, configSettings)}<br>`
    }

    const useControlLimits = settings.page.controlLimitEnable
    const useAverageLimits = settings.page.averageLimitEnable

    if (useControlLimits || useAverageLimits) {
        displayStr += `<br>${dqTranslateMsg('Page_QualityControlChart_' + 'Limits')}<br>`

        if (useControlLimits) {
            displayStr += formatLimitInfo('UCL', settings.ucl, char, configSettings)
            displayStr += formatLimitInfo('LCL', settings.lcl, char, configSettings)
        }

        if (useAverageLimits) {
            displayStr += formatLimitInfo('X\u0304', settings.average, char, configSettings)
        }
    }

    return displayStr
}

export const getQccChartRangeOptionArray = (settings, configSettings, targetChar, targetValue) => {
    const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
    const filteredValue = targetValue

    const rangeCalcCallback = qccRange.makeQccLowerSeriesData
    const { lcl, ucl, average, seriesData, values } = getScrollBoardQccChartSubData('range', settings, configSettings, targetChar, filteredValue, rangeCalcCallback)

    const maxValue = qccRange.findLargestNumber(values)
    const minValue = qccRange.findSmallestNumber(values)
    const xAxisRight = seriesData.length + 1
    const xAxisLeft = 0

    const topBottomCalcArray = {
        settings,
        usl,
        lsl,
        uwl,
        lwl,
        ucl,
        lcl,
        upl,
        lpl,
        url,
        lrl,
        uacl,
        lacl,
        maxValue,
        minValue,
    }

    const yAxisTop = qccRange.makeYAxisTop(topBottomCalcArray)
    const yAxisBottom = qccRange.makeYAxisBottom(topBottomCalcArray)
    const decimalPlaces = DBUtils.getFieldData(targetChar, 'c2022') ?? configSettings.commonSettings.displayDecimalPlaces

    const addedSettings = {
        ...settings,
        configSettings,
        lsl,
        usl,
        lwl,
        uwl,
        lcl,
        ucl,
        center: average,
        average,
        nominal,
        // warnOffset,
        xAxisRight,
        xAxisLeft,
        yAxisTop,
        yAxisBottom,
        decimalPlaces,
    }

    return {
        animationDuration: 300,
        toolbox: {
            feature: {
                saveAsImage: {},
            },
            itemSize: 15,
        },
        tooltip: {
            trigger: 'axis',
            appendToBody: true,
            position: function (pos, params, dom, rect, size) {
                let obj = { top: 60 }
                obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
                return obj
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            extraCssText: 'width: 170px; text-align: left;',
            formatter: function (args) {
                return toolTipRangeBalloonCallback(args, addedSettings, configSettings, targetChar)
            },
            textStyle: {
                fontFamily: 'Arial',
            },
            axisPointer: { type: 'cross', z: 9999 },
        },
        animationDuration: 300,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        legend: {
            show: false,
            textStyle: {
                fontFamily: 'Arial',
            },
        },
        grid: {
            top: '15%',
            bottom: '7%',
            left: '0%',
            right: '5%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
            min: xAxisLeft,
            max: xAxisRight,
            minInterval: 1,
            maxInterval: addedSettings.page.qualityControlChart.xAxisDenseLabel ? 1 : xAxisRight < 10 ? 1 : Math.abs(Math.floor(addedSettings.xAxisRight / 5)),
            axisLabel: {
                rotate: 90,
                fontFamily: 'Arial',
                hideOverlap: true,
                showMinLabel: false,
                showMaxLabel: false,
                fontSize: (settings.theme.qccChartXAxisFontSize / (1 + 1)) * 3,
            },
            splitLine: {
                show: false,
            },
            axisLine: {
                onZero: false,
                hideOverlap: true,
            },
            axisTick: {
                interval: 1,
            },
        },
        yAxis: {
            show: true,
            min: addedSettings.yAxisBottom,
            max: addedSettings.yAxisTop,
            type: 'value',
            axisLabel: {
                fontFamily: 'Arial',
                // formatter: function (value) {
                //   return DNUtils.axisValueDisplay(value, targetChar, configSettings)
                // },
                formatter: function (value, index) {
                    let axisMax = addedSettings.yAxisTop
                    let axisMin = addedSettings.yAxisBottom

                    if (value === axisMin || value === axisMax) {
                        return ''
                    }
                    return value
                },
                fontSize: (settings.theme.qccChartYAxisFontSize / (1 + 1)) * 3,
            },
            splitLine: {
                show: true,
            },
            minorTick: {
                show: false,
            },
            axisLine: {
                onZero: false,
            },
        },
        dataZoom: [
            {
                show: true,
                type: 'slider',
                orient: 'horizontal',
                height: '3%',
                bottom: '3%',
                brushSelect: false,
                showDataShadow: true,
                showDetail: false,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'slider',
                orient: 'vertical',
                width: '2%',
                brushSelect: true,
                showDataShadow: true,
                showDetail: false,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'inside',
                xAxisIndex: 0,
                filterMode: 'none',
                zoomLock: false, // Allow zooming
                zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
            },
            {
                show: true,
                type: 'inside',
                xAxisIndex: 0,
                filterMode: 'none',
            },
        ],
        series: [
            {
                type: 'line',
                name: dqTranslateMsg('Page_QualityControlChart_' + 'Range'),
                data: seriesData,
                symbol: (value, params) => qccRange.makeSymbol(value, params, addedSettings),
                symbolSize: (value, params) => qccRange.makeSymbolSize(value, params, addedSettings),
                itemStyle: {
                    color: (params) => qccRange.makeSymbolColor(params, addedSettings),
                },
                lineStyle: qccRange.convertToChartLine(addedSettings),
                markLine: {
                    animation: false,
                    symbol: 'none',
                    precision: 10,
                    emphasis: {
                        disabled: true,
                    },
                    data: qccRange.convertToMarkLine(addedSettings),
                    precision: addedSettings.theme.qualityControlChart.lineChart.markLines.precision,
                },
                markArea: {
                    type: 'line',
                    name: 'Areas',
                    emphasis: {
                        disabled: true,
                    },
                    data: qccRange.convertToMarkArea(addedSettings),
                },
            },
        ],
    }
}
