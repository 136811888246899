import Form, { GroupItem, Tab, TabbedItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import HeaderIcon from 'src/functions/HeaderIcon'
import IL from 'src/functions/IconLoader'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DataRange from './DataRange'
import IgnoreValue from './IgnoreValue'
import CharacteristicsClass from './CharacteristicsClass'
import OrderChart from './OrderChart'
import VisibleSettings from './VisibleSettings'
import AutoPageOption from './AutoPageOption'
import ValueChartOption from './ValueChartOption'
import BarChartOption from './BarChartOption'
import QCCChartOption from './QCCChartOption'
import SettingsQCCLimitsEnable from './SettingsQCCLimitsEnable'
import SettingsQCCLimitsLabelEnable from './SettingsQCCLimitsLabelEnable'
import SettingsLimitsEnable from './SettingsLimitsEnable'
import SettingsLimitsLabelEnable from './SettingsLimitsLabelEnable'
import SettingsExtraXAxisLabel from './SettingsExtraXAxisLabel'
import SettingsToolTip from './SettingsToolTip'
import SettingsQccChartOption from './SettingsQccChartOption'
import SettingsXAxis from './SettingsXAxis'
import React, { Fragment } from 'react'
import SettingsValueListOptions from './SettingsValueListOptions'

const tabIconPath = (data, type, menuIconType, theme, title) => {
    const mode = HeaderIcon.getIconMenuPath(menuIconType)
    const themeType = theme

    let path = ''
    switch (type) {
        case 'Configuration':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.CONFIGURATION)
            break
        case 'BarChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.HISTOGRAM_CHART)
            break
        case 'Diagram':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.SUMMARY_LIST)
            break
        case 'TreeMap':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_LIST)
            break
        case 'ValueChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_CHART)
            break
        case 'QccChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.QUALITY_CONTROL_CHART)
            break
        default:
            break
    }

    return null
}

const SettingsTabContainer = ({ pageKey, targetSettingMenu, pageState, configState, othersPage }) => {
    const dispatch = useDispatch()
    const { getTheme } = useTheme()
    const theme = getTheme()

    const commonItems = (pageState, configState) => [
        DataRange(pageKey, pageState, configState),
        IgnoreValue(pageKey, pageState, configState),
        CharacteristicsClass(pageKey, pageState, configState),
        OrderChart(pageKey, pageState, configState, othersPage),
        VisibleSettings(pageKey, pageState, configState),
        AutoPageOption(pageKey, pageState, configState),
    ]
    const vChartItems = (pageState, configState) => [
        ValueChartOption(pageKey, pageState, configState, othersPage),
        SettingsExtraXAxisLabel(pageKey, pageState, configState, othersPage),
        SettingsToolTip(pageKey, pageState, configState, othersPage),
        SettingsLimitsEnable(pageKey, pageState, configState),
        SettingsLimitsLabelEnable(pageKey, pageState, configState),
    ]
    const bChartItems = (pageState, configState) => [BarChartOption(pageKey, pageState, configState)]
    const qChartItems = (pageState, configState) => [
        QCCChartOption(pageKey, pageState, configState),
        SettingsQccChartOption(pageKey, pageState, configState),
        SettingsXAxis(pageKey, pageState, configState),
        SettingsQCCLimitsEnable(pageKey, pageState, configState),
        SettingsQCCLimitsLabelEnable(pageKey, pageState, configState),
    ]
    const vListItems = (pageState, configState) => [SettingsValueListOptions(pageKey, pageState, configState)]

    return (
        <Form
            id='side-panel-scroll-board-settings-form'
            style={{ paddingTop: '5px' }}
            labelMode={'outside'}
            formData={pageState.page}
            showColonAfterLabel={false}
        >
            {/* {renderContent(selected)} */}
            {targetSettingMenu === 0 && commonItems(pageState, configState)}
            {targetSettingMenu === 1 && vChartItems(pageState, configState)}
            {targetSettingMenu === 2 && bChartItems(pageState, configState)}
            {targetSettingMenu === 3 && qChartItems(pageState, configState)}
            {targetSettingMenu === 4 && vListItems(pageState, configState)}
        </Form>
    )
}

export default SettingsTabContainer
