// Utility functions
const isNumeric = (str) => !isNaN(str) && !isNaN(parseFloat(str))

const splitAlphaNum = (str) => {
  if (str === undefined || str === null) {
    return {
      num: null,
      alpha: null,
    }
  }
  const numPart = str.match(/\d+/)
  const alphaPart = str.match(/[a-zA-Z]+/)
  return {
    num: numPart ? parseInt(numPart[0], 10) : null,
    alpha: alphaPart ? alphaPart[0] : str,
  }
}

// Function to handle sorting of '' and null
const handleEmptyAndNull = (a, b) => {
  if (a === null && b === null) return 0
  if (a === '') return -1
  if (b === '') return 1
  if (a === null) return 1
  if (b === null) return -1
  if (a === undefined) return 1
  if (b === undefined) return -1
  return 0
}

// Function to compare alphanumeric strings in ascending order
export const compareAlphaNumAsc = (a, b) => {
  // Handle '' and null
  const emptyNullResult = handleEmptyAndNull(a, b)
  if (emptyNullResult !== 0) return emptyNullResult

  try {
    const aParts = splitAlphaNum(a)
    const bParts = splitAlphaNum(b)

    if (aParts.num !== null && bParts.num !== null) {
      if (aParts.num !== bParts.num) return aParts.num - bParts.num
    }

    if (aParts.alpha !== bParts.alpha) {
      return aParts.alpha.localeCompare(bParts.alpha)
    }

    return a.localeCompare(b)
  } catch (error) {
    console.error(`Error comparing ${a} and ${b}:`, error)
    return 0
  }
}

// Function to compare alphanumeric strings in descending order
export const compareAlphaNumDesc = (a, b) => {
  // Handle '' and null
  const emptyNullResult = handleEmptyAndNull(a, b)
  if (emptyNullResult !== 0) return emptyNullResult

  try {
    const aParts = splitAlphaNum(a)
    const bParts = splitAlphaNum(b)

    if (aParts.num !== null && bParts.num !== null) {
      if (aParts.num !== bParts.num) return bParts.num - aParts.num
    }

    if (aParts.alpha !== bParts.alpha) {
      return bParts.alpha.localeCompare(aParts.alpha)
    }

    return b.localeCompare(a)
  } catch (error) {
    console.error(`Error comparing ${a} and ${b}:`, error)
    return 0
  }
}

// Function to compare numeric values in ascending order
export const compareNumAsc = (a, b) => {
  const emptyNullResult = handleEmptyAndNull(a, b)
  if (emptyNullResult !== 0) return emptyNullResult

  try {
    const aNum = parseInt(a, 10)
    const bNum = parseInt(b, 10)
    return aNum - bNum
  } catch (error) {
    console.error(`Error comparing ${a} and ${b}:`, error)
    return 0
  }
}

// Function to compare alphabetical strings in descending order
export const compareNumDesc = (a, b) => {
  const emptyNullResult = handleEmptyAndNull(a, b)
  if (emptyNullResult !== 0) return emptyNullResult

  try {
    return b.localeCompare(a)
  } catch (error) {
    console.error(`Error comparing ${a} and ${b}:`, error)
    return 0
  }
}

// Utility function to extract value for sorting
export const sortByLocation = (rowData, dataField) => {
  const value = rowData[dataField]
  const parts = splitAlphaNum(value)
  return parts.num !== null ? parts.num : value
}
