import { MONO_BLUE, MONO_RED, COLOR } from './constants/iconPathDefine'

class IconLoader {
  /**
   * Constants
   */
  static MODES = {
    MONO_BLUE: 0,
    MONO_RED: 1,
    COLOR: 2,
  }

  static THEMES = {
    LIGHT: 0,
    DARK: 1,
  }

  static TYPES = {
    ARROW: 0,
    SMILE: 1,
    THUMBS: 2,
  }

  static STATE = {
    GOOD: 0,
    BAD: 1,
  }

  static NAV = {
    MONITORING: 10,
    DATABASE: 11,
    REPORT_PRINT: 12,
    CONFIGURATION: 13,
    ADMIN_CONFIGURATION: 14,
    COMPANY_INFO: 15,
    OVERVIEW: 16,
    SCROLL_BOARD: 17,
    UPLOAD_FILE: 18,
    CHARTS: 20,
    VALUE_CHART: 21,
    HISTOGRAM_CHART: 22,
    POSITIONAL_TOLERANCES_CHART: 23,
    LINEAR_REGRESSION_CHART: 24,
    CORRELATION_CHART: 25,
    QUALITY_CONTROL_CHART: 26,
    CAPABILITY_SUMMARY_CHART: 27,
    PARETO_CHART: 28,
    PROBABILITY_PLOT_CHART: 29,
    BOX_PLOT_CHART: 30,
    DECISION_TREE_CHART: 31,
    RANDOM_FOREST_CHART: 32,
    LISTS: 50,
    VALUE_LIST: 51,
    SUMMARY_LIST: 52,
    VALUE_SUMMARY_LIST: 53,
  }

  /**
   * Common
   */
  static IMAGES_PATH_DEF = '/images'
  static MODES_DEF = [
    { id: this.MODES.MONO_BLUE, path: MONO_BLUE },
    { id: this.MODES.MONO_RED, path: MONO_RED },
    { id: this.MODES.COLOR, path: COLOR },
  ]
  static THEMES_DEF = [
    { id: this.THEMES.LIGHT, path: 'light' },
    { id: this.THEMES.DARK, path: 'dark' },
  ]
  /**
   * Result icon (Not exposed)
   * arrow / smile / thumbs
   */

  static TYPES_DEF = [
    { id: this.TYPES.ARROW, path: 'arrow' },
    { id: this.TYPES.SMILE, path: 'smile' },
    { id: this.TYPES.THUMBS, path: 'thumbs' },
  ]
  static STATE_DEF = [
    { id: this.STATE.GOOD, path: 'good' },
    { id: this.STATE.BAD, path: 'bad' },
  ]
  static EXT_DEF = {
    SVG: 'svg',
    PNG: 'png',
  }

  /**
   * @param {String} mode
   * @param {String} type
   * @param {String} theme
   * @param {String} state
   * @returns String or null
   */

  static getImgPath(modeId, typeId, themeId, stateId) {
    // const modeInfo = this.MODES_DEF.find((item) => item.id === modeId)
    const modeInfo = modeId
    let themeInfo = this.THEMES_DEF.find((item) => item.id === themeId)
    const typeInfo = this.TYPES_DEF.find((item) => item.id === typeId)
    const stateInfo = this.STATE_DEF.find((item) => item.id === stateId)

    if (!modeInfo || !typeInfo || !stateInfo) {
      console.error('cannot find icon info parameter')
      return null
    }

    if (!themeInfo) {
      themeInfo = { id: this.THEMES.LIGHT, path: 'light' }
    }

    let path = ''
    if (modeInfo === 'color') {
      path = `${this.IMAGES_PATH_DEF}/${modeInfo}/${typeInfo.path}-${stateInfo.path}.${this.EXT_DEF.SVG}`
    } else {
      path = `${this.IMAGES_PATH_DEF}/${modeInfo}/${themeInfo.path}/${typeInfo.path}-${stateInfo.path}.${this.EXT_DEF.SVG}`
    }

    return path
  }

  /**
   * Navigation Menu icon
   */
  static NAV_DEF = [
    { id: this.NAV.MONITORING, external: true, path: 'monitoring' },
    { id: this.NAV.DATABASE, external: true, path: 'database' },
    { id: this.NAV.SCROLL_BOARD, external: true, path: 'scroll-board' },
    { id: this.NAV.REPORT_PRINT, external: true, path: 'report-print' },
    { id: this.NAV.UPLOAD_FILE, external: false, path: 'upload' },
    { id: this.NAV.CONFIGURATION, external: true, path: 'configuration' },
    {
      id: this.NAV.ADMIN_CONFIGURATION,
      external: true,
      path: 'admin-configuration',
    },
    { id: this.NAV.COMPANY_INFO, external: true, path: 'company-info' },
    { id: this.NAV.OVERVIEW, external: true, path: 'overview' },
    { id: this.NAV.VALUE_CHART, external: true, path: 'value-chart' },
    { id: this.NAV.HISTOGRAM_CHART, external: true, path: 'histogram' },
    {
      id: this.NAV.POSITIONAL_TOLERANCES_CHART,
      external: true,
      path: 'positional-tolerances',
    },
    {
      id: this.NAV.LINEAR_REGRESSION_CHART,
      external: true,
      path: 'linear-regression',
    },
    { id: this.NAV.CORRELATION_CHART, external: true, path: 'correlation' },
    {
      id: this.NAV.QUALITY_CONTROL_CHART,
      external: true,
      path: 'quality-control-chart',
    },
    {
      id: this.NAV.CAPABILITY_SUMMARY_CHART,
      external: true,
      path: 'capability-summary',
    },
    { id: this.NAV.PARETO_CHART, external: true, path: 'pareto' },
    {
      id: this.NAV.PROBABILITY_PLOT_CHART,
      external: true,
      path: 'probability-plot',
    },
    { id: this.NAV.BOX_PLOT_CHART, external: true, path: 'box-plot-chart' },
    { id: this.NAV.DECISION_TREE_CHART, external: true, path: 'cart' },
    { id: this.NAV.VALUE_LIST, external: true, path: 'value-list' },
    { id: this.NAV.SUMMARY_LIST, external: true, path: 'summary-list' },
    {
      id: this.NAV.VALUE_SUMMARY_LIST,
      external: true,
      path: 'value-summary-list',
    },
    { id: this.NAV.CHARTS, external: true, path: 'charts' },
    { id: this.NAV.LISTS, external: true, path: 'lists' },
  ]
  static getNavImgPath(mode, theme, navId) {
    //
    const modeInfo = this.MODES_DEF.find((item) => item.path === mode)
    let themeInfo = this.THEMES_DEF.find((item) => theme.includes(item.path))
    //
    const navInfo = this.NAV_DEF.find((item) => item.id === navId)

    if (!modeInfo || !navInfo) {
      console.error('cannot find nav icon info parameter')
      return null
    }

    if (!themeInfo) {
      themeInfo = { id: this.THEMES.LIGHT, path: 'light' }
    }

    const isExtIcon = navInfo.external

    let path = ''

    if (isExtIcon) {
      if (modeInfo.path === 'color') {
        path = `${this.IMAGES_PATH_DEF}/${modeInfo.path}/${navInfo.path}.${this.EXT_DEF.SVG}`
      } else {
        path = `${this.IMAGES_PATH_DEF}/${modeInfo.path}/${themeInfo.path}/${navInfo.path}.${this.EXT_DEF.SVG}`
      }
    } else {
      path = `${navInfo.path}`
    }

    return path
  }
}

export default IconLoader
