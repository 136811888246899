function simpleLinearRegression(x, y) {
  if (x.length === 0 || y.length === 0) {
    throw new Error('Input arrays cannot be empty.')
  }

  const n = Math.min(x.length, y.length)
  let sumX = 0
  let sumY = 0
  let sumXY = 0
  let sumX2 = 0

  for (let i = 0; i < n; i++) {
    sumX += x[i]
    sumY += y[i]
    sumXY += x[i] * y[i]
    sumX2 += x[i] * x[i]
  }

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX)
  const intercept = (sumY - slope * sumX) / n

  return { slope, intercept }
}
function calculateR2Score(xValues, yValues, slope, intercept) {
  if (xValues.length < 2 || slope === null || intercept === null) {
    return null
  }

  const meanY = yValues.reduce((acc, val) => acc + val, 0) / yValues.length

  let ssr = 0 // Sum of Squared Residuals
  let tss = 0 // Total Sum of Squares

  // need to set small array
  const compLength = Math.min(xValues.length, yValues.length)
  for (let i = 0; i < compLength; i++) {
    const predictedY = slope * xValues[i] + intercept
    ssr += Math.pow(yValues[i] - predictedY, 2)
    tss += Math.pow(yValues[i] - meanY, 2)
  }

  return 1 - ssr / tss
}
export function makeLinRegDataSet(yConstant, xVariables) {
  if (!Array.isArray(yConstant) || !Array.isArray(xVariables)) {
    throw new Error('Input data must be arrays')
  }
  const dataset = []

  if (xVariables.length > 0) {
    const yValues = yConstant[1].valueRaw.filter((data) => data.v0002 === 0).map((item) => item.v0001)
    xVariables.map((variable) => {
      const xValues = variable[1].valueRaw.filter((data) => data.v0002 === 0).map((item) => item.v0001)
      const minLength = Math.min(xValues.length, yValues.length)

      // Calculate regression parameters (slope and intercept)
      const { slope, intercept } = minLength > 1 ? simpleLinearRegression(xValues, yValues) : { slope: null, intercept: null }

      // if (slope !== null && intercept !== null) {
      const xyPairs = []
      for (let i = 0; i < minLength; i++) {
        xyPairs.push([xValues[i], yValues[i]])
      }

      // Calculate R² score
      const r2Score = calculateR2Score(xValues, yValues, slope, intercept)

      dataset.push({
        xData: variable[0].c2002,
        yData: yConstant[0].c2002,
        ySlope: slope,
        yIntercept: intercept,
        decimalPlaces: variable[0].c2022,
        // constantData: yValues,
        variableData: xyPairs,
        r2Score: r2Score,
      })
      // }
    })
  }

  return dataset
}

export function linearRegression(data) {
  const n = data.length
  let sumX = 0
  let sumY = 0
  let sumXY = 0
  let sumX2 = 0

  for (let i = 0; i < n; i++) {
    const [x, y] = data[i]
    sumX += x
    sumY += y
    sumXY += x * y
    sumX2 += x * x
  }

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX)
  const intercept = (sumY - slope * sumX) / n

  return [slope, intercept]
}

export function makeLinearRegressionLine(data, slope, intercept) {
  const regressionLine = []
  for (let i = 0; i < data.length; i++) {
    const x = data[i][0]
    const y = slope * x + intercept
    regressionLine.push([x, y])
  }

  return regressionLine
}

export function makeLinearRegressionLineSlope(data, slope, intercept) {
  const regressionLine = []

  const xStart = 0
  const yStart = slope * xStart
  regressionLine.push([xStart, yStart])

  const xEnd = 100
  const yEnd = slope * xEnd
  regressionLine.push([xEnd, yEnd])

  return regressionLine
}

export const calculateYAxisSpace = (props) => {
  const { settings, usl, lsl, uwl, lwl, upl, lpl, url, lrl, uacl, lacl, maxValue, minValue } = props
  const {
    page: { specificationLimitEnable, warningLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable },
  } = settings

  const t_usl = specificationLimitEnable ? usl ?? maxValue : maxValue
  const t_lsl = specificationLimitEnable ? lsl ?? minValue : minValue

  const t_uwl = warningLimitEnable ? uwl ?? maxValue : maxValue
  const t_lwl = warningLimitEnable ? lwl ?? minValue : minValue

  const t_upl = plausibilityLimitEnable ? upl ?? maxValue : maxValue
  const t_lpl = plausibilityLimitEnable ? lpl ?? minValue : minValue

  const t_url = scrapLimitEnable ? url ?? maxValue : maxValue
  const t_lrl = scrapLimitEnable ? lrl ?? minValue : minValue

  const t_uacl = acceptanceLimitEnable ? uacl ?? maxValue : maxValue
  const t_lacl = acceptanceLimitEnable ? lacl ?? minValue : minValue

  const max = Math.max(t_usl, t_uwl, t_upl, t_url, t_uacl, maxValue)
  const min = Math.min(t_lsl, t_lwl, t_lpl, t_lrl, t_lacl, minValue)

  let space = Math.abs(max - min)
  if (space === 0) space = 1

  const additionalSpace = space * 0.1

  return { max, min, additionalSpace }
}

export const makeYAxisTop = (props) => {
  const { max, additionalSpace } = calculateYAxisSpace(props)
  return max + additionalSpace
}

export const makeYAxisBottom = (props) => {
  const { min, additionalSpace } = calculateYAxisSpace(props)
  return min - additionalSpace
}

export const makeSymbol = (value, params, settings) => {
  // const val = params.data.v0001
  const val = params.data[1]
  if (settings.usl === null && settings.lsl === null) {
    return 'none'
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.shape
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.shape
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.warn.lower.shape
    } else {
      return settings.lineChart.symbols.error.lower.shape
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.warn.lower.shape
    } else {
      return settings.lineChart.symbols.error.lower.shape
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.shape
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.shape
    } else {
      return settings.lineChart.symbols.good.shape
    }
  }
}

export const makeSymbolSize = (value, params, settings) => {
  // const val = params.data.v0001
  const val = params.data[1]

  if (settings.usl === null && settings.lsl === null) {
    return 0
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.size
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.size
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.size
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.warn.lower.size
    } else {
      return settings.lineChart.symbols.error.lower.size
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.size
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.warn.lower.size
    } else {
      return settings.lineChart.symbols.error.lower.size
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.size
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.size
    } else {
      return settings.lineChart.symbols.good.size
    }
  }
}

export const makeSymbolColor = (params, settings) => {
  // const val = params.data.v0001
  const val = params.data[1]

  if (settings.usl === null && settings.lsl === null) {
    return 'rgba(255,255,255,0)'
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.color
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.color
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.color
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.warn.lower.color
    } else {
      return settings.lineChart.symbols.error.lower.color
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.color
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.warn.lower.color
    } else {
      return settings.lineChart.symbols.error.lower.color
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.color
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.color
    } else {
      return settings.lineChart.symbols.good.color
    }
  }
}
export const setTitle = (option, targetChar) => {
  const next = produce(option, (draft) => {
    draft.title = { text: targetChar.c2002 }
  })

  return next
}

export const setLegend = (option, props) => {
  const next = produce(option, (draft) => {
    draft.legend = props
  })

  return next
}

const setTooltip = (option, props) => {
  const next = produce(option, (draft) => {
    draft.tooltip = props
  })

  return next
}

const setDataset = (option, valueData) => {
  const setConvertedSource = (valueData) => {
    console.log('setConvertedSource')

    const keys = Object.keys(valueData[0])
    const data = [keys, ...valueData.map((obj) => Object.values(obj))]

    return data
  }

  const next = produce(option, (draft) => {
    draft.dataset = {
      source: setConvertedSource(valueData),
    }
  })

  return next
}

const setXAxis = (option, props) => {
  const next = produce(option, (draft) => {
    draft.xAxis = props
  })

  return next
}

export const setYAxis = (option, props) => {
  const next = produce(option, (draft) => {
    draft.yAxis = props
  })

  return next
}

export const setDataZoom = (option, props) => {
  const next = produce(option, (draft) => {
    draft.dataZoom = props
  })

  return next
}

export const setSeries = (option, props) => {
  const next = produce(option, (draft) => {
    draft.series = props
  })

  return next
}
