import { NumberBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'
import './NumberBoxSimpleItem.scss' // Import your custom CSS
import { dqTranslateMsg } from '../dq-convert/DQLanguage'

const NumberBoxSimpleItem = ({ label, colSpan = 1, labelLocation, name, min, max, step, format, value, unit, disabled = false, onValueChanged }) => {
  return (
    <SimpleItem colSpan={colSpan}>
      <Label text={label} location={labelLocation} />
      <div className="numberbox-container">
        <NumberBox
          name={name}
          // label={label}
          // labelMode={labelLocation}
          min={min}
          max={max}
          step={step}
          format={format}
          value={value}
          disabled={disabled}
          showSpinButtons={true}
          onValueChanged={onValueChanged}
          width={'100%'}
        />
        {unit && <span>{dqTranslateMsg('SidePanel_' + unit)}</span>}
      </div>
    </SimpleItem>
  )
}

export default NumberBoxSimpleItem
