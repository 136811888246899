// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-panel-dq-settings-container {
  width: 100%;
  height: 100%;
}
.side-panel-dq-settings-container .button-container {
  width: 100%;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/components/side-panel-form/component/side-panel-dq-settings-form/SidePanelDQSettingsForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":[".side-panel-dq-settings-container {\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  .button-container {\r\n    width: 100%;\r\n    height: 30px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
