import React, { useEffect, useRef, useState } from 'react'
import { GroupItem, SimpleItem, ButtonItem, EmptyItem } from 'devextreme-react/form'
import { SelectBox, List, Sortable, Button } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import ListSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import SelectBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxNoValueSimpleItem'
import { defScrollBoardField, defScrollBoardNoneField } from 'src/default/field/scrollBoard.js'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import CPUtils from 'src/functions/CommonPageUtils'

export default function Analyze(pageKey, pageState, configState, othersPage) {
    const dispatch = useDispatch()
    // const pageKey = 'decisionTreeChart'

    // const pageState = useSelector((state) => state.common.pages[pageKey])
    // const configState = useSelector((state) => state.common.pages.configuration)

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }

    const useValueFilterField = CPUtils.getConfigItemListFromUseField('Value', false, null, useFieldList.value, configState)
    const shiftUpField = CPUtils.makeItemListFromSetting('Value', pageState.page.shiftUpField, useFieldList.value, configState)

    const handleExtraLabelDelete = (e) => {
        const value = e.itemData.value
        const next = produce(pageState, (draft) => {
            draft.page.shiftUpField = draft.page.shiftUpField.filter((element) => element !== value)
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const onSelectBoxAnalyzeFieldValueChanged = (e, dataField) => {
        const value = e.itemData.value
        const isExist = pageState.page[dataField].some((item) => item === value)
        if (!isExist) {
            const next = produce(pageState, (draft) => {
                draft.page[dataField].push(value)
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }
    }

    return (
        <GroupItem
            key={`analyze-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'Analyze')}
        >
            {SelectBoxSimpleItem({
                colSpan: 1,
                label: dqTranslateMsg('SidePanel_' + 'ToolTipAddedInfo'),
                labelLocation: 'top',
                name: 'shiftUpField',
                items: useValueFilterField,
                valueExpr: 'value',
                displayExpr: 'text',
                value: useValueFilterField.length > 0 ? useValueFilterField[0].value : null,
                disabled: false,
                onItemClick: (e) => onSelectBoxAnalyzeFieldValueChanged(e, 'shiftUpField'),
            })}
            <EmptyItem />
            {ListSimpleItem({
                colSpan: 2,
                label: dqTranslateMsg('SidePanel_' + 'RegisteredItem'),
                labelLocation: 'top',
                dataSource: shiftUpField,
                itemRender: null,
                allowItemDeleting: true,
                itemDeleteMode: 'static',
                noDataText: dqTranslateMsg('SidePanel_' + 'NoData'),
                onItemDeleted: handleExtraLabelDelete,
                onReorder: null,
                backgroundCondition: true,
            })}
        </GroupItem>
    )
}
