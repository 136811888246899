import { DataGrid } from 'devextreme-react/data-grid'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

import './AdminLicense.scss'

const data = [
  {
    meta: 'unlimited',
    title: 'Q-Report',
    value: 'Q-Report',
    isSelected: true,

    // content: 'Get 1 project with 1 team member.'
  },
  {
    meta: 'unlimited',
    title: 'Q-Collector',
    value: 'Q-Collector',

    // content: 'Get 5 projects with 5 team members.'
  },
  {
    meta: 'limited 10days left',
    title: 'Q-Board',
    value: 'Q-Board',

    // content: 'Get 5 projects with 5 team members.'
  },
]
export default function AdminLicense() {
  return (
    <div className="admin-license-container">
      <DataGrid
        className="grid-container"
        id="admin-license-datagrid"
        allowColumnReordering={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        allowColumnResizing={true}
        scrolling={{
          columnRenderingMode: 'standard',
          mode: 'standard',
          preloadEnabled: true,
          renderAsync: true,
          rowRenderingMode: 'standard',
          scrollByContent: true,
          scrollByThumb: false,
          showScrollbar: 'onHover',
          useNative: false,
        }}
        editing={{
          allowUpdating: true,
          mode: 'cell',
          startEditAction: 'dblClick',
        }}
        searchPanel={{
          highlightCaseSensitive: false,
          highlightSearchText: true,
          placeholder: dqTranslateMsg('SearchPlaceHoler'),
          searchVisibleColumnsOnly: false,
          text: '',
          visible: true,
          width: 160,
        }}
        noDataText={dqTranslateMsg('NoData')}
        paging={{ enabled: false }}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        rowAlternationEnabled={false}
        hoverStateEnabled={true}
        // onSelectionChanged={handleSelectionChanged}
      ></DataGrid>
    </div>
  )
}
