import { LoadPanel } from 'devextreme-react/load-panel'

export default function DQLoadPanelWithProgress(props) {
  const { open, message } = props
  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position={'center'}
      visible={open}
      showIndicator={true}
      message={message}
      shading={true}
      showPane={true}
      width={300}
      height={100}
    />
  )
}
