import { TreeList } from 'devextreme-react'
import Button from 'devextreme-react/button'
import { Column, DataGrid, GroupPanel, Item, MasterDetail, Toolbar } from 'devextreme-react/data-grid'
import { Selection } from 'devextreme-react/tree-list'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { batch, useSelector } from 'react-redux'
import { getAdminCompanyList, getAdminCompanyPlantList, updateAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyListPrms, makeGetAdminCompanyPlantListPrms, makeUpdateAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { getAdminGroupList, updateAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminGroupListPrms, makeUpdateAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import { getUserList } from 'src/api/auth'
import { updateUserInfoForSettings } from 'src/api/user'
import { useApi } from 'src/contexts/api'
import * as dtoUtils from 'src/dto/DTOImpl'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { companyPlantTbDescription as companyPlantTbDesc } from 'src/functions/constants/companyPlantDBfieldDescription'
import { groupTbDescription as groupTbDesc } from 'src/functions/constants/groupDBFieldDescription'
import { userTbDescription as userTbDesc } from 'src/functions/constants/userDBFieldDescription'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { configurationSettingsPrm } from 'src/pages/config/components/settings/ConfigSettings'
import { adminSettingsPrm } from '../../../settings/AdminSettings'
import './AdminPushSettingsData.scss'
import AdminPushDataType from './components/AdminPushDataType'
import DQToast from 'src/functions/DQToast'

const makeDataSourceForTreeView = (groups, users) => {
    const treeDataSource = []
    const expandIDList = []
    let currentId = 100 // Start ID for company, plant, group, and user

    const companyMap = {}
    const plantMap = {}

    groups.forEach((group) => {
        // Handle company with circled C icon
        if (!companyMap[group.company]) {
            const companyNode = {
                id: currentId++,
                name: `Ⓒ ${group.company}`, // Unicode circled C
                type: 'company',
                company: group.company,
                plant: group.plant,
                group: group.group,
                userId: null,
                parentId: null,
            }
            companyMap[group.company] = companyNode.id
            treeDataSource.push(companyNode)
        }

        // Handle plant with circled P icon
        const companyId = companyMap[group.company]
        const plantKey = `${group.company}_${group.plant}`
        if (!plantMap[plantKey]) {
            const plantNode = {
                id: currentId++,
                name: `Ⓟ ${group.plant}`, // Unicode circled P
                type: 'plant',
                company: group.company,
                plant: group.plant,
                group: group.group,
                userId: null,
                parentId: companyId,
            }
            plantMap[plantKey] = plantNode.id
            treeDataSource.push(plantNode)
            expandIDList.push(companyId)
        }

        // Handle group with circled G icon
        const plantId = plantMap[plantKey]
        const groupNode = {
            id: group.id,
            name: `Ⓖ ${group.group}`, // Unicode circled G
            type: 'group',
            company: group.company,
            plant: group.plant,
            group: group.group,
            userId: null,
            parentId: plantId,
        }

        treeDataSource.push(groupNode)
        expandIDList.push(plantId)

        // Attach users to the group they belong to, with circled U icon for user
        const filteredUsers = users.filter((user) => user.user_company === group.company && user.user_plant === group.plant && user.user_group === group.group)

        filteredUsers.forEach((user) => {
            treeDataSource.push({
                id: currentId++,
                name: `Ⓤ ${user.user_id}`, // Unicode circled U
                type: 'user',
                company: group.company,
                plant: group.plant,
                group: group.group,
                userId: user.user_id,
                parentId: group.id,
            })
            expandIDList.push(group.id)
        })
    })

    const uniqueExpandIDList = [...new Set(expandIDList)]

    return { treeDataSource, expandIDList: uniqueExpandIDList }
}

const makeDataSourceForDataGrid = (userList, treeDataSource) => {
    const uniqueData = treeDataSource.filter((item, index, self) => index === self.findIndex((t) => t.company === item.company && t.plant === item.plant && t.group === item.group))

    const converted = userList.map((user) => ({
        user_id: user.user_id,
        user_company: user.user_company,
        user_plant: user.user_plant,
        user_group: user.user_group,
    }))

    // groups에 user_id 추가
    let idx = 0
    const result = uniqueData.flatMap((groupItem) => {
        const { id, ...rest } = groupItem
        const filtered = converted
            .filter((user) => user.user_company === groupItem.company && user.user_plant === groupItem.plant && user.user_group === groupItem.group)
            .map((user) => ({
                ...rest,
                user_id: user.user_id,
                id: idx++,
            }))
        return filtered
    })

    return result
}

export default function AdminPushSettingsData({ pageKey }) {
    const { spring } = useApi()
    const treeListRef = useRef(null)
    const dataGridRef = useRef(null)
    const toastRef = useRef(null)

    const common = useSelector((state) => state.common)
    const othersPage = common.pages.others
    const configPage = common.pages.configuration
    const adminPage = common.pages.adminSettings

    const [adminUserList, setAdminUserList] = useState([])
    const [adminCompanyPlantGroupList, setAdminCompanyPlantGroupList] = useState([])

    const [adminTreeDataSource, setAdminTreeDataSource] = useState([])
    const [expandedTreeRowKeys, setExpandedTreeRowKeys] = useState([])
    const [selectedTreeRowKeys, setSelectedTreeRowKeys] = useState([])

    const [adminGridDataSource, setAdminGridDataSource] = useState([])
    const [expandedGridRowKeys, setExpandedGridRowKeys] = useState([])
    const [selectedGridRowKeys, setSelectedGridRowKeys] = useState([])

    // temporary settings state
    const [tempConfigState, setTempConfigState] = useState({
        dbConfig: othersPage.dbConfig,
        ribbon: othersPage.ribbon,
        encoding: configPage.encoding,
        findDistribution: configPage.findDistribution,
        commonSettings: configPage.commonSettings,
        language: othersPage.language,
        permissions: othersPage.permissions,
        useFieldList: othersPage.useFieldList,
        catalogs: othersPage.catalogs,
    })

    // master detail state
    const [dbInfo, setDBInfo] = useState([])

    // State to track which row is expanded
    const [expandedRowId, setExpandedRowId] = useState(null)

    useEffect(() => {
        const getDataForPushSettings = async () => {
            try {
                const companyList = await getAdminCompanyList(spring, makeGetAdminCompanyListPrms())
                const uniqueCList = [...new Set(companyList)]

                const companyPlantList = await Promise.all(
                    uniqueCList.map(async (company) => {
                        const plantList = await getAdminCompanyPlantList(spring, makeGetAdminCompanyPlantListPrms(company))
                        return { company, plantList }
                    })
                )

                const admDataConverted = []
                await Promise.all(
                    companyPlantList.flatMap(async ({ company, plantList }) => {
                        return Promise.all(
                            plantList.map(async (plant) => {
                                const groupList = await getAdminGroupList(spring, makeGetAdminGroupListPrms(company, plant))

                                groupList.forEach((group) => {
                                    admDataConverted.push({ company, plant, group })
                                })
                            })
                        )
                    })
                )

                const dataForTreeList = []
                const userListTemp = await getUserList(spring)

                const filteredCPG = admDataConverted.filter((item) => item.company !== 'DQS' && item.plant !== 'DQS' && item.group !== 'DQS')
                const sortedData = filteredCPG.sort((a, b) => a.company.localeCompare(b.company))
                sortedData.forEach((item, index) => {
                    item.id = index + 1
                })

                const { treeDataSource, expandIDList } = makeDataSourceForTreeView(sortedData, userListTemp.user_infos)

                // user
                const userList = await getUserList(spring)
                const convertedUserDTO = userList.user_infos.map((info) => {
                    return dtoUtils.makeUserInfoToDTOFormat(info)
                })

                let gridDataSource = []
                if (userList && userList.user_infos && userList.user_infos.length > 0) {
                    gridDataSource = makeDataSourceForDataGrid(userList.user_infos, treeDataSource)
                    // allRowGridKeys = gridDataSource.map((item) => item.id)
                }

                batch(() => {
                    // User List
                    setAdminUserList(convertedUserDTO)
                    setAdminCompanyPlantGroupList(admDataConverted)
                    // Tree
                    setAdminTreeDataSource(treeDataSource)
                    setExpandedTreeRowKeys(expandIDList)

                    // Grid
                    setAdminGridDataSource(gridDataSource)
                })
            } catch (error) {
                console.error('Error fetching push settings data:', error)
            }
        }

        getDataForPushSettings()
    }, [])

    useEffect(() => {
        if (treeListRef && treeListRef.current) {
            const selectedData = treeListRef.current.instance.getSelectedRowsData('leavesOnly')
            const gridDataSource = makeDataSourceForDataGrid(adminUserList, selectedData)
            setAdminGridDataSource(gridDataSource)
        }
    }, [selectedTreeRowKeys])

    const onDataGridSelectionChanged = (e) => {}

    /**
     * Button Proc
     */

    const updateCompanyPlantSettings = async (element, field, pageValue) => {
        const company = element.company
        const plant = element.plant
        const dataJsonStr = JSON.stringify(pageValue)

        const updateData = {
            [field]: dataJsonStr,
        }

        await updateAdminCompany(spring, makeUpdateAdminCompanyPrms(company, plant, updateData))
    }

    const updateGroupSettings = async (element, field, pageValue) => {
        const company = element.company
        const plant = element.plant
        const group = element.group
        const dataJsonStr = JSON.stringify(pageValue)

        const updateData = {
            [field]: dataJsonStr,
        }

        await updateAdminGroup(spring, makeUpdateAdminGroupPrms(company, plant, group, updateData))
    }

    const updateUserSettings = async (element, field, draftField, defaultSettings, pageValue) => {
        const targetUser = adminUserList.find((user) => user.user_id === element.userId)
        if (!targetUser) {
            console.error('User is not exist')
            return
        }

        const settingsDataStr = targetUser[field]
        const settingsData = settingsDataStr ? JSON.parse(settingsDataStr) : defaultSettings

        const next = produce(settingsData, (draft) => {
            draft[draftField] = pageValue
        })

        await updateUserInfoForSettings(element.userId, field, JSON.stringify(next))
    }

    /**
     * plant setting event proc
     */
    const handleClickPlantPushSettings = (e) => {
        const processPushSettings = async () => {
            console.log('handleClickPlantPushSettings Start')

            const targetGroupAndUser = treeListRef.current.instance.getSelectedRowsData('all')

            const simplePlant = targetGroupAndUser.map((item) => {
                const { id, name, parentId, type, userId, group, ...rest } = item
                return rest
            })

            const uniquePlant = simplePlant.filter((item, index, self) => index === self.findIndex((t) => t.company === item.company && t.plant === item.plant))
            const pushTargetItem = dataGridRef.current.instance.getSelectedRowsData('all')

            if (!uniquePlant || !pushTargetItem || uniquePlant.length === 0 || pushTargetItem.length === 0) {
                console.error('not Selected Plant Data')
                if (uniquePlant.length === 0 || pushTargetItem.length === 0) {
                    const isPlantExist = uniquePlant.length !== 0 ? true : false
                    const isTargetItemExist = pushTargetItem.length !== 0 ? true : false

                    let desc = ''
                    desc += isPlantExist === false ? 'Plants' : ''
                    desc += isPlantExist === false && isTargetItemExist === false ? ' / ' : ''
                    desc += isTargetItemExist === false ? 'Settings Items' : ''

                    alert(`Please Select <${desc}>`)
                }
                if (toastRef) {
                    toastRef.current.handleToastDisplay('error', dqTranslateMsg('Page_AdminSettings_' + 'FailedSettings'))
                }
                return
            }

            uniquePlant.forEach(async (element) => {
                console.log(`Update Plant Data : ${element.company} ${element.plant} `)
                for (const setting of pushTargetItem) {
                    if (setting.value === 'License') {
                        // await updateCompanyPlantSettings(element, companyPlantTbDesc.license, adminPage.license)
                    }
                    if (setting.value === 'Language') {
                        await updateCompanyPlantSettings(element, companyPlantTbDesc.language, tempConfigState.language)
                    }
                    if (setting.value === 'UseFields') {
                        await updateCompanyPlantSettings(element, companyPlantTbDesc.useFields, tempConfigState.useFieldList)
                    }
                    if (setting.value === 'Catalogs') {
                        await updateCompanyPlantSettings(element, companyPlantTbDesc.catalogs, tempConfigState.catalogs)
                    }
                }
            })

            if (toastRef) {
                toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_AdminSettings_' + 'SavedSettings'))
            }
            console.log('handleClickPlantPushSettings End')
        }

        processPushSettings()
    }

    /**
     * group setting event proc
     */
    const handleClickGroupPushSettings = (e) => {
        const processPushSettings = async () => {
            console.log('handleClickGroupPushSettings Start')

            const targetGroupAndUser = treeListRef.current.instance.getSelectedRowsData('all')

            const simpleGroup = targetGroupAndUser.map((item) => {
                const { id, name, parentId, type, userId, ...rest } = item
                return rest
            })
            const uniqueGroup = simpleGroup.filter((item, index, self) => index === self.findIndex((t) => t.company === item.company && t.plant === item.plant && t.group === item.group))
            const pushTargetItem = dataGridRef.current.instance.getSelectedRowsData('all')

            if (!uniqueGroup || !pushTargetItem || uniqueGroup.length === 0 || pushTargetItem.length === 0) {
                console.error('not Selected Group Data')
                if (uniqueGroup.length === 0 || pushTargetItem.length === 0) {
                    const isGroupExist = uniqueGroup.length !== 0 ? true : false
                    const isTargetItemExist = pushTargetItem.length !== 0 ? true : false

                    let desc = ''
                    desc += isGroupExist === false ? 'Groups' : ''
                    desc += isGroupExist === false && isTargetItemExist === false ? ' / ' : ''
                    desc += isTargetItemExist === false ? 'Settings Items' : ''

                    alert(`Please Select <${desc}>`)
                }
                if (toastRef) {
                    toastRef.current.handleToastDisplay('error', dqTranslateMsg('Page_AdminSettings_' + 'FailedSettings'))
                }
                return
            }

            uniqueGroup.forEach(async (element) => {
                console.log(`Update Group Data : ${element.company} ${element.plant} ${element.group}`)
                for (const setting of pushTargetItem) {
                    if (setting.value === 'DBInfo') {
                        await updateGroupSettings(element, groupTbDesc.dbConfig, tempConfigState.dbConfig)
                    }
                    if (setting.value === 'Ribbon') {
                        await updateGroupSettings(element, groupTbDesc.ribbon, tempConfigState.ribbon)
                    }
                    if (setting.value === 'Encoding') {
                        await updateGroupSettings(element, groupTbDesc.encoding, tempConfigState.encoding)
                    }
                    if (setting.value === 'FindDistribution') {
                        await updateGroupSettings(element, groupTbDesc.findDistribution, tempConfigState.findDistribution)
                    }
                    if (setting.value === 'CommonSettings') {
                        await updateGroupSettings(element, groupTbDesc.commonSettings, tempConfigState.commonSettings)
                    }
                    if (setting.value === 'Permissions') {
                        await updateGroupSettings(element, groupTbDesc.permissions, tempConfigState.permissions)
                    }
                }
            })

            if (toastRef) {
                toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_AdminSettings_' + 'SavedSettings'))
            }
            console.log('handleClickGroupPushSettings End')
        }

        processPushSettings()
    }

    /**
     * group & user setting event proc
     */
    const handleClickGroupUserPushSettings = (e) => {
        const processPushSettings = async () => {
            const updateGroupSettings = async (element, field, pageValue) => {
                const company = element.company
                const plant = element.plant
                const group = element.group
                const dataJsonStr = JSON.stringify(pageValue)

                const updateData = {
                    [field]: dataJsonStr,
                }

                await updateAdminGroup(spring, makeUpdateAdminGroupPrms(company, plant, group, updateData))
            }

            console.log('handleClickGroupUserPushSettings Start')

            const targetGroupAndUser = treeListRef.current.instance.getSelectedRowsData('all')
            const simpleGroupAndUser = targetGroupAndUser.map((item) => {
                const { id, name, parentId, type, ...rest } = item
                return rest
            })
            const simpleGroup = targetGroupAndUser.map((item) => {
                const { id, name, parentId, type, userId, ...rest } = item
                return rest
            })
            const uniqueGroupAndUser = simpleGroupAndUser.filter((item) => item.userId !== null || item.userId === '')
            const uniqueGroup = simpleGroup.filter((item, index, self) => index === self.findIndex((t) => t.company === item.company && t.plant === item.plant && t.group === item.group))
            const pushTargetItem = dataGridRef.current.instance.getSelectedRowsData('all')

            if (!uniqueGroupAndUser || !uniqueGroup || !pushTargetItem || uniqueGroupAndUser.length === 0 || uniqueGroup.length === 0 || pushTargetItem.length === 0) {
                console.error('not Selected Group & User Data')
                if (uniqueGroup.length === 0 || pushTargetItem.length === 0) {
                    const isGroupExist = uniqueGroup.length !== 0 ? true : false
                    const isTargetItemExist = pushTargetItem.length !== 0 ? true : false

                    let desc = ''
                    desc += isGroupExist === false ? 'Group & User' : ''
                    desc += isGroupExist === false && isTargetItemExist === false ? ' / ' : ''
                    desc += isTargetItemExist === false ? 'Settings Items' : ''

                    alert(`Please Select <${desc}>`)
                }
                if (toastRef) {
                    toastRef.current.handleToastDisplay('error', dqTranslateMsg('Page_AdminSettings_' + 'FailedSettings'))
                }
                return
            }

            uniqueGroupAndUser.forEach(async (element) => {
                console.log(`Update Group & User  Data : ${element.company} ${element.plant} ${element.group}  ${element.userId}`)
                for (const setting of pushTargetItem) {
                    if (setting.value === 'DBInfo') {
                        await updateGroupSettings(element, groupTbDesc.dbConfig, tempConfigState.dbConfig)
                    }
                    if (setting.value === 'Ribbon') {
                        await updateGroupSettings(element, groupTbDesc.ribbon, tempConfigState.ribbon)
                    }
                    if (setting.value === 'Encoding') {
                        await updateGroupSettings(element, groupTbDesc.encoding, tempConfigState.encoding)
                    }
                    if (setting.value === 'FindDistribution') {
                        await updateGroupSettings(element, groupTbDesc.findDistribution, tempConfigState.findDistribution)
                        await updateUserSettings(element, userTbDesc.configuration, 'findDistribution', configurationSettingsPrm, tempConfigState.findDistribution)
                    }
                    if (setting.value === 'CommonSettings') {
                        await updateGroupSettings(element, groupTbDesc.commonSettings, tempConfigState.commonSettings)
                        await updateUserSettings(element, userTbDesc.configuration, 'commonSettings', configurationSettingsPrm, tempConfigState.commonSettings)
                    }
                    if (setting.value === 'Permissions') {
                        await updateGroupSettings(element, groupTbDesc.permissions, tempConfigState.permissions)
                    }
                }
            })

            if (toastRef) {
                toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_AdminSettings_' + 'SavedSettings'))
            }
            console.log('handleClickGroupUserPushSettings End')
        }

        processPushSettings()
    }

    const onExpandedRowKeysChange = (newExpandedRowKeys) => {
        setExpandedTreeRowKeys(newExpandedRowKeys)
    }

    useEffect(() => {
        if (treeListRef.current) {
            treeListRef.current.instance.selectAll()
        }
        if (dataGridRef.current) {
            dataGridRef.current.instance.selectAll()
        }
    }, [])

    // Define the rows for the grid
    const rows = [
        {
            id: 1,
            setting: `${getPageMsg(msgType.AdminSettings, 'DBInfo')} ( ${getPageMsg(msgType.AdminSettings, 'Group')} )`,
            value: 'DBInfo',
            details: {
                type: 1,
                label: 'DBInfo',
                value: othersPage.dbConfig,
                saveData: dbInfo,
                setSaveData: setDBInfo,
            },
        },
        {
            id: 2,
            setting: `${getPageMsg(msgType.AdminSettings, 'Ribbon')} ( ${getPageMsg(msgType.AdminSettings, 'Group')} )`,
            value: 'Ribbon',
            details: { type: 2, label: 'Ribbon', value: othersPage.ribbon },
        },
        // {
        //     id: 3,
        //     setting: `${getPageMsg(msgType.AdminSettings, 'Encoding')} ( ${getPageMsg(msgType.AdminSettings, 'Group')} )`,
        //     value: 'Encoding',
        //     details: { type: 3, label: 'Encoding', value: configPage.encoding },
        // },
        {
            id: 4,
            setting: `${getPageMsg(msgType.AdminSettings, 'FindDistribution')} ( ${getPageMsg(msgType.AdminSettings, 'Group')} / ${getPageMsg(msgType.AdminSettings, 'User')} )`,
            value: 'FindDistribution',
            details: {
                type: 4,
                label: 'Find Distribution',
                value: configPage.findDistribution,
            },
        },
        {
            id: 5,
            setting: `${getPageMsg(msgType.AdminSettings, 'CommonSettings')} ( ${getPageMsg(msgType.AdminSettings, 'Group')} / ${getPageMsg(msgType.AdminSettings, 'User')} )`,
            value: 'CommonSettings',
            details: {
                type: 5,
                label: 'Common Settings',
                value: configPage.commonSettings,
            },
        },
        {
            id: 6,
            setting: `${getPageMsg(msgType.AdminSettings, 'Language')} ( ${getPageMsg(msgType.AdminSettings, 'Plant')} )`,
            value: 'Language',
            details: {
                type: 6,
                label: 'Language',
                value: othersPage.language,
            },
        },
        {
            id: 7,
            setting: `${getPageMsg(msgType.AdminSettings, 'Permissions')} ( ${getPageMsg(msgType.AdminSettings, 'Group')} )`,
            value: 'Permissions',
            details: {
                type: 7,
                label: 'Permissions',
                value: othersPage.permissions,
            },
        },
        {
            id: 8,
            setting: `${getPageMsg(msgType.AdminSettings, 'UseFields')} ( ${getPageMsg(msgType.AdminSettings, 'Plant')} )`,
            value: 'UseFields',
            details: {
                type: 8,
                label: 'UseFields',
                value: othersPage.useFieldList,
            },
        },
        {
            id: 9,
            setting: `${getPageMsg(msgType.AdminSettings, 'Catalogs')} ( ${getPageMsg(msgType.AdminSettings, 'Plant')} )`,
            value: 'Catalogs',
            details: {
                type: 9,
                label: 'Catalogs',
                value: othersPage.catalogs,
            },
        },
    ]

    const renderDetailRow = ({ data }) => {
        // Render the custom component and pass data.details to it
        return (
            <AdminPushDataType
                details={data.details}
                companyPlantGroupList={adminCompanyPlantGroupList}
                state={tempConfigState}
                setState={setTempConfigState}
            />
        )
    }
    const handleRowExpanding = (e) => {
        dataGridRef.current.instance.collapseRow(expandedRowId)
        setExpandedRowId(e.key)
    }

    return (
        <div className='admin-push-settings-data-main-container'>
            <DQToast ref={toastRef} />
            <div className='admin-push-settings-data-comp-plant-group-container'>
                <TreeList
                    ref={treeListRef}
                    className='admin-push-settings-data-inner-content'
                    dataSource={adminTreeDataSource}
                    autoExpandAll={true}
                    expandedRowKeys={expandedTreeRowKeys}
                    // selectedRowKeys={selectedTreeRowKeys}
                    showBorders={true}
                    showRowLines={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    searchPanel={{
                        highlightCaseSensitive: false,
                        highlightSearchText: true,
                        placeholder: dqTranslateMsg('SearchPlaceHoler'),
                        searchVisibleColumnsOnly: false,
                        text: '',
                        visible: true,
                        width: 160,
                    }}
                    // onSelectionChanged={onTreeListSelectionChanged}
                    noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
                    keyExpr='id'
                    parentIdExpr='parentId'
                    onExpandedRowKeysChange={onExpandedRowKeysChange}
                >
                    <Selection
                        mode='multiple'
                        recursive={true}
                    />
                    <Column dataField='name' />
                </TreeList>
            </div>
            <div className='admin-push-settings-data-next-container'>
                <Button
                    className='button-content'
                    text='>>'
                    type='success'
                />
            </div>
            <div className='admin-push-settings-data-user-list-container'>
                {/* <p style={{ fontWeight: 'bold' }}>Target Users</p> */}
                <DataGrid
                    ref={dataGridRef}
                    className='admin-push-settings-data-inner-content'
                    id='view-settings-grid'
                    dataSource={rows}
                    expandedRowKeys={expandedGridRowKeys}
                    // selectedRowKeys={selectedGridRowKeys}
                    keyExpr='id'
                    width='100%'
                    allowColumnReordering={true}
                    columnAutoWidth={true}
                    columnResizingMode='widget'
                    allowColumnResizing={true}
                    scrolling={{
                        columnRenderingMode: 'standard',
                        mode: 'standard',
                        preloadEnabled: true,
                        renderAsync: true,
                        rowRenderingMode: 'standard',
                        scrollByContent: true,
                        scrollByThumb: false,
                        showScrollbar: 'onHover',
                        useNative: false,
                    }}
                    searchPanel={{
                        highlightCaseSensitive: false,
                        highlightSearchText: true,
                        placeholder: dqTranslateMsg('SearchPlaceHoler'),
                        searchVisibleColumnsOnly: false,
                        text: '',
                        visible: true,
                        width: 160,
                    }}
                    noDataText={dqTranslateMsg('NoData')}
                    paging={{ enabled: false }}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    hoverStateEnabled={true}
                    onRowExpanding={handleRowExpanding}
                    onSelectionChanged={onDataGridSelectionChanged}
                >
                    <GroupPanel
                        visible={true}
                        emptyPanelText={dqTranslateMsg('Page_Database_' + 'EmptyPanelText')}
                    />
                    <Toolbar>
                        <Item
                            widget='dxButton'
                            options={{
                                icon: 'save',
                                // text: 'Apply to Plant Default',
                                text: getPageMsg(msgType.AdminSettings, 'ApplyToPlant'),
                                elementAttr: {
                                    style: 'min-width: 200px;', // Inline style for min-width
                                },
                                onClick: handleClickPlantPushSettings,
                            }}
                            location='after'
                        />
                        <Item
                            widget='dxButton'
                            options={{
                                icon: 'save',
                                text: 'Apply to Group Default',
                                text: getPageMsg(msgType.AdminSettings, 'ApplyToGroup'),
                                elementAttr: {
                                    style: 'min-width: 200px;', // Inline style for min-width
                                },
                                onClick: handleClickGroupPushSettings,
                            }}
                            location='after'
                        />
                        <Item
                            widget='dxButton'
                            options={{
                                icon: 'save',
                                text: getPageMsg(msgType.AdminSettings, 'ApplyToGroupUser'),
                                elementAttr: {
                                    style: 'min-width: 200px;', // Inline style for min-width
                                },
                                onClick: handleClickGroupUserPushSettings,
                            }}
                            location='after'
                        />
                    </Toolbar>
                    <Selection
                        mode='multiple'
                        allowSelectAll={true}
                        showCheckBoxesMode={'always'}
                    />
                    <Column
                        dataField='setting'
                        caption='Setting'
                    />
                    {/* <Column dataField="value" caption="Enabled" cellRender={({ data }) => <CheckBox style={{ width: '100%' }} value={data.value} onValueChanged={data.onValueChanged} />} /> */}
                    <MasterDetail
                        enabled={true}
                        render={renderDetailRow} // Renders the details section when expanded
                    />
                </DataGrid>
            </div>
        </div>
    )
}
