class CommonCalcUtils {
  static getMax(arr) {
    let len = arr.length
    let max = -Infinity

    while (len--) {
      max = arr[len] > max ? arr[len] : max
    }
    return max
  }

  static getMin(arr) {
    let len = arr.length
    let min = Infinity // 초기 최솟값을 양의 무한대로 설정

    while (len--) {
      min = arr[len] < min ? arr[len] : min
    }
    return min
  }

  static isPrime(num) {
    if (num <= 1) return false // 1 or less are not prime
    if (num % 1 !== 0) return false // Non-integers can't be prime
    for (let i = 2; i <= Math.sqrt(num); i++) {
      if (num % i === 0) return false // Divisible by any number other than 1 and itself
    }
    return true
  }

  // Function to convert exponential numbers to integers and check prime
  static convertExponentialArrayToInt(arr) {
    const numberArray = arr.map((num) => {
      if (typeof num === 'number') {
        // Check if number is a prime or an integer, if so, return it as an integer
        if (this.isPrime(num) || Number.isInteger(num)) {
          return Math.round(num) // Convert to integer (rounding)
        }
      }
      return num // If it's not prime or integer, return it as is
    })

    return numberArray
  }

  static convertExponentialValueToInt(val) {
    if (typeof val === 'number') {
      // Check if number is a prime or an integer, if so, return it as an integer
      if (this.isPrime(val) || Number.isInteger(val)) {
        return Math.round(val) // Convert to integer (rounding)
      }
    }
    return val // If it's not prime or integer, return it as is
  }
}

export default CommonCalcUtils
