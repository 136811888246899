import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Form, { GroupItem, SimpleItem, ButtonItem } from 'devextreme-react/form'
import { SelectBox, List, Sortable, Button, Lookup } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import ListSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import SelectBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxNoValueSimpleItem'
import { defScrollBoardField, defScrollBoardNoneField } from 'src/default/field/scrollBoard.js'
import CPUtils from 'src/functions/CommonPageUtils'

const orderChartDefine = () => [
    { value: 'BarChart', type: 'common', text: dqTranslateMsg('SidePanel_' + 'BarChart') },
    { value: 'TreeMap', type: 'common', text: dqTranslateMsg('SidePanel_' + 'TreeMap') },
    // { value: 'Diagram', text: dqTranslateMsg('SidePanel_' + 'Diagram') },
    { value: 'ValueChart', type: 'value', text: dqTranslateMsg('SidePanel_' + 'ValueChart') },
    // { dataField: 'Pie', caption: 'PieCaption' },
]

const orderChartType = (type) => {
    if (type === 'all') {
        return orderChartDefine()
    } else {
        return orderChartDefine().filter((item) => item.type === type)
    }
}

const OrderChart = (pageKey, pageState, configState, othersPage) => {
    const dispatch = useDispatch()
    const sbPageSettings = pageState
    const configPage = configState

    const selectBoxFieldRef = useRef(null)
    const selectBoxTypeRef = useRef(null)

    const isUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = isUseFieldListEmpty
        ? { part: [], char: [], value: [] } // Default value if useFieldList is empty
        : othersPage.useFieldList // Use the existing useFieldList if not empty

    // const useFieldItems = CPUtils.getConfigItemListFromAllField(false, null, useFieldList, configState)
    const useFieldItems = CPUtils.getConfigItemListBySelection(false, true, false, true, useFieldList, configState)
    const useScrollBoardFieldItems = [...useFieldItems, ...defScrollBoardField()]

    // const usePartFilterField = useFieldList.part.map((item) => {
    //     let captionCondition = ''
    //     if (configPage.commonSettings.displayFieldNameWithNumber) {
    //         captionCondition += `[ ${item.text} ] `
    //     }
    //     captionCondition += dqTranslateMsg('Db_field_Part_' + item.text)

    //     return { ...item, text: captionCondition }
    // })
    // const useCharFilterField = useFieldList.char.map((item) => {
    //     let captionCondition = ''
    //     if (configPage.commonSettings.displayFieldNameWithNumber) {
    //         captionCondition += `[ ${item.text} ] `
    //     }
    //     captionCondition += dqTranslateMsg('Db_field_Characteristics_' + item.text)

    //     return {
    //         ...item,
    //         text: captionCondition,
    //     }
    // })
    // const useValueFilterField = useFieldList.value.map((item) => {
    //     let captionCondition = ''
    //     if (configPage.commonSettings.displayFieldNameWithNumber) {
    //         captionCondition += `[ ${item.text} ] `
    //     }
    //     captionCondition += dqTranslateMsg('Db_field_Value_' + item.text)

    //     return { ...item, text: captionCondition }
    // })
    // const useFieldListAll = [...usePartFilterField, ...useCharFilterField, ...useValueFilterField, ...defScrollBoardField()]
    const useFieldListAll = useScrollBoardFieldItems

    const [orderList, setOrderList] = useState({
        // orderChartListOrderValid: false,
        orderChartField: '',
        orderChartType: '',
    })

    useEffect(() => {
        setOrderList({
            // orderChartListOrderValid: sbPageSettings.page.orderChartListOrderValid,
            orderChartField: sbPageSettings.page.orderChartField,
            orderChartType: sbPageSettings.page.orderChartType,
        })
    }, [sbPageSettings.page.orderChartField, sbPageSettings.page.orderChartType])

    const checkOrderListApplyCondition = () => {
        if (orderList.orderChartField !== sbPageSettings.page.orderChartField || orderList.orderChartType !== sbPageSettings.page.orderChartType) {
            return true
        }
        return false
    }

    const updatePageSettings = () => {
        const listState = checkOrderListApplyCondition()
        const next = produce(sbPageSettings, (draft) => {
            draft.page.orderChartListOrderValid = isValidListCondition(updateListOrderChartList())
            draft.page.orderChartField = orderList.orderChartField
            draft.page.orderChartType = orderList.orderChartType
            if (listState) {
                draft.page.dataUpdated = false
            }
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const updateListOrderChartList = () => {
        if (orderList.orderChartField === '' || orderList.orderChartType === '') {
            return []
        }
        const fieldData = orderList.orderChartField.split('|')
        const typeData = orderList.orderChartType.split('|')

        if (fieldData.length !== typeData.length) {
            return []
        }
        return fieldData.map((field, index) => ({
            id: index,
            field,
            type: typeData[index],
        }))
    }

    const onAddButtonClick = () => {
        if (canAddMoreItems()) {
            addNewItem()
        }
    }

    const canAddMoreItems = () => {
        const maxItems = 7
        return orderList.orderChartField.split('|').length < maxItems && orderList.orderChartType.split('|').length < maxItems
    }

    const addNewItem = () => {
        const curChartField = selectBoxFieldRef.current.instance.option('value')
        const curChartType = selectBoxTypeRef.current.instance.option('value')
        const numericPart = curChartField.substring(1)
        const fieldNum = parseInt(numericPart, 10)

        const newOrderList = {
            orderChartField: appendContent(orderList.orderChartField, fieldNum),
            orderChartType: appendContent(orderList.orderChartType, curChartType),
        }
        setOrderList(newOrderList)
    }

    const appendContent = (content, value) => (content ? `${content}|${value}` : `${value}`)

    const onApplyButtonClick = () => {
        updatePageSettings()
    }

    const isValidListCondition = (updatedList) => {
        /**
         * Spec
         * - If `v0001` exists, `c2000` must also exist.
         * - `c2000` must be immediately before `v0001`.
         * - `c2000` must be immediately followed by `v0001`.
         * - There must be nothing after `v0001`.
         */
        const v0001Index = updatedList.findIndex((item) => item.field === '1')
        const c2000Index = updatedList.findIndex((item) => item.field === '2000')
        if (v0001Index === -1) return true

        if (c2000Index === -1 || c2000Index !== v0001Index - 1 || v0001Index !== c2000Index + 1 || v0001Index !== updatedList.length - 1) {
            return false
        }
        return true
    }

    const onListReorder = (e) => {
        const { fromIndex, toIndex } = e
        const updatedList = reorderList(updateListOrderChartList(), fromIndex, toIndex)
        updateOrderList(updatedList)
    }

    const reorderList = (list, fromIndex, toIndex) => {
        const updatedList = [...list]
        const [movedItem] = updatedList.splice(fromIndex, 1)
        updatedList.splice(toIndex, 0, movedItem)
        return updatedList
    }

    const updateOrderList = (list) => {
        const fieldData = list.map((item) => item.field).join('|')
        const typeData = list.map((item) => item.type).join('|')
        setOrderList({ orderChartField: fieldData, orderChartType: typeData })

        // Update Redux state directly
        const listState = checkOrderListApplyCondition()
        const next = produce(sbPageSettings, (draft) => {
            draft.page.orderChartListOrderValid = listState
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const handleExtraFieldDelete = async (e) => {
        const extraFieldList = updateListOrderChartList()
        extraFieldList.splice(e.itemIndex, 1)
        updateOrderList(extraFieldList)
    }

    const renderOrderChartList = (itemData) => {
        const addPrefixField = (field) => {
            const quotient = Math.trunc(Number(field) / 1000)
            const formatted = field.toString().padStart(4, '0')
            const prefixMap = { 0: 'v', 1: 'p', 2: 'c' }
            return `${prefixMap[quotient] || ''}${formatted}`
        }

        const getItems = () => {
            let splitted = orderList.orderChartType.split('|')
            return splitted[itemData.id] === 'ValueChart' ? orderChartType('value') : orderChartType('common')
        }
        const handleContentReady = (e) => {
            // Access the popup content via the DevExtreme API
            const popupContent = e.component.content()

            // Find the list items in the popup content
            const items = popupContent.querySelectorAll('.dx-list-item')
            let maxWidth = 0

            // Measure the width of each item
            items.forEach((item) => {
                const itemWidth = item.offsetWidth // Get the width of the item
                if (itemWidth > maxWidth) {
                    maxWidth = itemWidth
                }
            })

            // Set the width of the popup to match the widest item
            e.component.option('dropDownOptions.width', maxWidth + 30) // Add padding if needed
        }

        return (
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '5px' }}>
                <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>▶️</div>
                <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>{itemData.id + 1}</div>
                <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>:</div>
                <Lookup
                    dataSource={useFieldListAll}
                    defaultValue={addPrefixField(itemData.field)}
                    valueExpr='value'
                    displayExpr='text'
                    dropDownOptions={{
                        showTitle: false,
                        hideOnOutsideClick: true,
                        showCloseButton: false,
                        maxHeight: 300,
                        width: '300px',
                        // onContentReady: handleContentReady,
                    }}
                    width={'100%'}
                    useNativeScrolling={false}
                    onItemClick={(e) => {
                        const { value } = e.itemData

                        let splittedField = orderList.orderChartField.split('|')
                        let woPrefix = value.slice(1)
                        let result = parseInt(woPrefix, 10).toString()
                        splittedField[itemData.id] = result
                        let joinedField = splittedField.join('|')

                        let joinedType = orderList.orderChartType
                        if (result === '1') {
                            let splittedType = orderList.orderChartType.split('|')
                            splittedType[itemData.id] = 'ValueChart'
                            joinedType = splittedType.join('|')
                        } else {
                            let splittedType = orderList.orderChartType.split('|')
                            splittedType[itemData.id] = 'BarChart'
                            joinedType = splittedType.join('|')
                        }

                        setOrderList((prev) => ({
                            ...prev,
                            orderChartField: joinedField,
                            orderChartType: joinedType,
                        }))
                    }}
                />
                <Lookup
                    dataSource={getItems()}
                    disabled={itemData.field === '1'}
                    defaultValue={itemData.type}
                    valueExpr='value'
                    displayExpr='text'
                    dropDownOptions={{
                        showTitle: false,
                        hideOnOutsideClick: true,
                        showCloseButton: false,
                        maxHeight: 300,
                        width: '200px',
                    }}
                    width={'100%'}
                    onItemClick={(e) => {
                        const { value } = e.itemData

                        let splitted = orderList.orderChartType.split('|')
                        splitted[itemData.id] = value
                        let joined = splitted.join('|')

                        setOrderList((prev) => ({
                            ...prev,
                            orderChartType: joined,
                        }))
                    }}
                />
            </div>
        )
    }

    return (
        <GroupItem
            key={`order-chart-group-item`}
            colCount={2}
            caption={dqTranslateMsg('SidePanel_' + 'OrderChart')}
        >
            {SelectBoxNoValueSimpleItem({
                label: dqTranslateMsg('SidePanel_' + 'Field'),
                labelLocation: 'top',
                ref: selectBoxFieldRef,
                items: useFieldListAll,
                valueExpr: 'value',
                displayExpr: 'text',
                defaultValue: useFieldListAll[0].value,
                showDropDownButton: true,
                onValueChanged: (e) => {
                    if (e.itemData.value === 'v0001') {
                        selectBoxTypeRef.current.instance.option('disabled', true)
                        selectBoxTypeRef.current.instance.option('value', 'ValueChart')
                    } else {
                        selectBoxTypeRef.current.instance.option('disabled', false)
                        selectBoxTypeRef.current.instance.option('value', 'BarChart')
                    }
                },
            })}
            {SelectBoxNoValueSimpleItem({
                label: dqTranslateMsg('SidePanel_' + 'Type'),
                labelLocation: 'top',
                ref: selectBoxTypeRef,
                items: orderChartType('all'),
                valueExpr: 'value',
                displayExpr: 'text',
                showDropDownButton: true,
                defaultValue: orderChartType('all')[0].value,
            })}
            {ButtonSimpleItem({
                colSpan: 2,
                type: 'normal',
                text: dqTranslateMsg('SidePanel_' + 'Add'),
                icon: 'plus',
                width: '100%',
                height: '100%',
                onClick: onAddButtonClick,
            })}
            {ListSimpleItem({
                colSpan: 2,
                label: dqTranslateMsg('SidePanel_' + 'RegisteredItem'),
                labelLocation: 'top',
                dataSource: updateListOrderChartList(),
                itemRender: renderOrderChartList,
                allowItemDeleting: true,
                itemDeleteMode: 'static',
                noDataText: dqTranslateMsg('SidePanel_' + 'NoData'),
                onItemDeleted: handleExtraFieldDelete,
                onReorder: onListReorder,
                backgroundCondition: isValidListCondition(updateListOrderChartList()),
            })}
            {ButtonSimpleItem({
                colSpan: 2,
                text: dqTranslateMsg('SidePanel_' + 'Apply'),
                icon: 'parentfolder',
                type: checkOrderListApplyCondition() === true ? 'danger' : 'normal',
                width: '100%',
                height: '100%',
                disabled: !isValidListCondition(updateListOrderChartList()),
                onClick: onApplyButtonClick,
            })}
        </GroupItem>
    )
}

export default OrderChart
