import { CheckBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'

const CheckBoxSimpleItem = ({ ref, colSpan, label, labelLocation, name, value, disabled, onValueChanged }) => {
  return (
    <SimpleItem colSpan={colSpan} disabled={disabled}>
      <Label text={label} location={labelLocation} />
      <CheckBox ref={ref} name={name} value={value} onValueChanged={onValueChanged} />
    </SimpleItem>
  )
}

export default CheckBoxSimpleItem
