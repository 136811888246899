import React from 'react'
import { EmptyItem, GroupItem } from 'devextreme-react/form'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

function IgnoreValue(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  const sbPageSettings = pageState

  const onCheckBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value ? 1 : 0
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(sbPageSettings, (draft) => {
      draft.page[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`ignore-value-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'IgnoreValue')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 4,
        label: dqTranslateMsg('SidePanel_' + 'Enable'),
        labelLocation: 'right',
        name: 'ignoreValuesEnable',
        value: sbPageSettings.page.ignoreValuesEnable === 1,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'ignoreValuesEnable'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'UslPercent'),
        labelLocation: 'top',
        name: 'ignoreValuesUslPercentSetting',
        min: 101,
        max: 1000,
        step: 1,
        format: '#0',
        value: sbPageSettings.page.ignoreValuesUslPercentSetting,
        disabled: sbPageSettings.page.ignoreValuesEnable === 0,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'ignoreValuesUslPercentSetting'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'LslPercent'),
        labelLocation: 'top',
        name: 'ignoreValuesLslPercentSetting',
        min: 101,
        max: 1000,
        step: 1,
        format: '#0',
        value: sbPageSettings.page.ignoreValuesLslPercentSetting,
        disabled: sbPageSettings.page.ignoreValuesEnable === 0,
        onValueChanged: (e) => onNumberBoxValueChanged(e, 'ignoreValuesLslPercentSetting'),
      })}
    </GroupItem>
  )
}

export default IgnoreValue
