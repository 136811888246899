import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import SidePanelTemplate from 'src/template/side-panel/SidePanelTemplate'
import { handleSaveButtonClicked, handleResetButtonClicked, handleSwitchFilterMode, filterButtonDescription } from 'src/template/side-panel/SidePanelTemplateHelper'
import CommonOption from './components/settings/CommonOption'
import DateTimeOption from './components/settings/DateTimeOption'
import LastNFilterOption from './components/settings/LastNFilterOption'
import ETCOption from './components/settings/ETCOption'
import DBFieldOption from './components/settings/DBFieldOption'
import produce from 'immer'

import { Button } from 'devextreme-react'
import { useRef, useState } from 'react'
import { DBFieldAdvancedOption } from './components/settings/DBFieldAdvancedOption'
import DBFieldAdvancedRealTimeFilter from './components/settings/DBFieldAdvancedRealTimeFilter'
import DBFieldAdvancedSaveFilter from './components/settings/DBFieldAdvancedSaveFilter'
import DBFieldAdvancedSavedFilter from './components/settings/DBFieldAdvancedSavedFilter'
import DBFieldAdvancedPreviewFilter from './components/settings/DBFieldAdvancedPreviewFilter'
import { databaseSettingsPrm } from '../settings/DatabaseSettings'
import DBFieldAdvancedPopupFilter from './components/settings/DBFieldAdvancedPopupFilter'
import DBFieldSaveFilter from './components/settings/DBFieldSaveFilter'
import DBFieldSavedFilter from './components/settings/DBFieldSavedFilter'
import DBFieldPopupFilter from './components/settings/DBFieldPopupFilter'

import DBFieldAdvancedCreatePopupFilter from './components/settings/DBFieldAdvancedCreatePopupFilter'
import DBFieldAdvancedCreate from './components/settings/DBFieldAdvancedCreateButton'
import DBFieldAdvancedCreateButton from './components/settings/DBFieldAdvancedCreateButton'
import DBFieldCreateButton from './components/settings/DBFieldCreateButton'
import DBFieldCreatePopupFilter from './components/settings/DBFieldCreatePopupFilter'

export default function SidePanelDatabaseFilter({ pageKey }) {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const toastRef = useRef(null)

    const common = useSelector((state) => state.common)
    const curPage = common.pages[pageKey]
    const othersPage = common.pages.others
    const configPage = common.pages.configuration
    const currentFilterMode = curPage.page.filterMode

    const partRef = useRef(null)
    const charRef = useRef(null)
    const valueRef = useRef(null)
    const partRefPopup = useRef(null)
    const charRefPopup = useRef(null)
    const valueRefPopup = useRef(null)

    const [isVisibleFilterPopup, setIsVisibleFilterPopup] = useState(false)
    const [popupDisplayFilter, setPopupDisplayFilter] = useState(null)
    const [isVisibleCreateFilterPopup, setIsVisibleCreateFilterPopup] = useState(false)
    const [popupCreateFilter, setPopupCreateFilter] = useState(null)

    const saveCallback = () => handleSaveButtonClicked(dispatch, user, pageKey, curPage, curPage, toastRef)
    const resetCallback = () => {
        let next
        if (currentFilterMode === 'quick') {
            next = produce(curPage, (draft) => {
                Object.entries(databaseSettingsPrm.filters.quick).forEach(([key, value]) => {
                    draft.filters.quick[key] = value
                })
            })
        } else {
            next = produce(curPage, (draft) => {
                Object.entries(databaseSettingsPrm.filters.advanced).forEach(([key, value]) => {
                    draft.filters.advanced[key] = value
                })
            })
        }
        handleResetButtonClicked(dispatch, user, pageKey, next, {}, toastRef)
    }

    const switchFilterModeButton = (
        <Button
            style={{ width: '100%' }}
            text={filterButtonDescription(currentFilterMode)}
            onClick={() => handleSwitchFilterMode(curPage, dispatch, pageKey, currentFilterMode)}
        />
    )
    const settingsComponent = [CommonOption({ currentFilterMode }), DateTimeOption({ currentFilterMode }), LastNFilterOption({ currentFilterMode }), ETCOption({ currentFilterMode })]

    const dbFieldOptionJSX = [
        DBFieldOption(pageKey, curPage, configPage, othersPage, partRef, charRef, valueRef),
        DBFieldCreateButton(pageKey, curPage, configPage, isVisibleCreateFilterPopup, setIsVisibleCreateFilterPopup, popupCreateFilter, setPopupCreateFilter, currentFilterMode),
        // DBFieldSaveFilter(pageKey, curPage, configPage),
        DBFieldSavedFilter(pageKey, curPage, configPage, othersPage, setIsVisibleFilterPopup, setPopupDisplayFilter),
        DBFieldCreatePopupFilter(
            pageKey,
            curPage,
            configPage,
            othersPage,
            isVisibleCreateFilterPopup,
            setIsVisibleCreateFilterPopup,
            popupCreateFilter,
            setPopupCreateFilter,
            partRefPopup,
            charRefPopup,
            valueRefPopup
        ),
        DBFieldPopupFilter(
            pageKey,
            curPage,
            configPage,
            othersPage,
            isVisibleFilterPopup,
            setIsVisibleFilterPopup,
            popupDisplayFilter,
            setPopupDisplayFilter,
            partRefPopup,
            charRefPopup,
            valueRefPopup
        ),
    ]

    const dbFieldAdvancedOptionJSX = [
        DBFieldAdvancedRealTimeFilter(pageKey, curPage, configPage, othersPage),
        DBFieldAdvancedCreateButton(pageKey, curPage, configPage, isVisibleCreateFilterPopup, setIsVisibleCreateFilterPopup, popupCreateFilter, setPopupCreateFilter, currentFilterMode),
        DBFieldAdvancedSavedFilter(pageKey, curPage, configPage, othersPage, setIsVisibleFilterPopup, setPopupDisplayFilter),
        DBFieldAdvancedCreatePopupFilter(pageKey, curPage, configPage, othersPage, isVisibleCreateFilterPopup, setIsVisibleCreateFilterPopup, popupCreateFilter, setPopupCreateFilter),
        DBFieldAdvancedPopupFilter(pageKey, curPage, configPage, othersPage, isVisibleFilterPopup, setIsVisibleFilterPopup, popupDisplayFilter, setPopupDisplayFilter),
    ]

    const currentFilter = currentFilterMode === 'advanced' ? dbFieldAdvancedOptionJSX : dbFieldOptionJSX

    const formComponents = [...settingsComponent, currentFilter]

    return (
        <SidePanelTemplate
            pageKey={pageKey}
            formComponents={formComponents}
            saveCallback={saveCallback}
            resetCallback={resetCallback}
            extraButtons={switchFilterModeButton}
            toastRef={toastRef}
        />
    )
}
