import React, { ReactElement, useState, useEffect } from 'react'
import { WorkflowCytoscapeChart } from './WorkFlowCytoscapeChart'
import { getCart } from 'src/api/decisionTree'
import ApiStatus from 'src/api/defines/ApiStatus'
// import elements from './tests/basic-workflow-elements'
// import elements from "./tests/const-water-leak-elements";
// import elements from "./tests/valve-reading-elements";

export const WorkFlowSimpleChartContainer = (props) => {
  const { pageKey, common, chartRawData } = props

  return (
    <WorkflowCytoscapeChart
      pageKey={pageKey}
      common={common}
      chartRawData={chartRawData}
    />
  )
}
