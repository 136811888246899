// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
}
.dx-theme-background-color .database-part-container,
.dx-theme-background-color .database-char-container {
  height: 100%;
  overflow: auto;
}
.dx-theme-background-color .divider {
  background-color: #ccc;
  cursor: col-resize;
  z-index: 10;
  position: relative;
  transition: background-color 0.2s ease;
}
.dx-theme-background-color .divider:hover {
  background-color: #888;
}`, "",{"version":3,"sources":["webpack://./src/pages/database/components/main-view/DatabaseForm.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;;EAEI,YAAA;EACA,cAAA;AACR;AAEI;EACI,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,sCAAA;AAAR;AAGI;EACI,sBAAA;AADR","sourcesContent":[".dx-theme-background-color {\r\n    width: 100%;\r\n    height: 100%;\r\n    padding: 5px;\r\n    display: flex;\r\n    flex-direction: row; // 가로 방향 레이아웃을 명시적으로 설정\r\n\r\n    .database-part-container,\r\n    .database-char-container {\r\n        height: 100%;\r\n        overflow: auto; // 내용이 넘칠 경우 스크롤 가능하게 설정\r\n    }\r\n\r\n    .divider {\r\n        background-color: #ccc;\r\n        cursor: col-resize;\r\n        z-index: 10;\r\n        position: relative;\r\n        transition: background-color 0.2s ease; // 부드러운 색상 전환 추가\r\n    }\r\n\r\n    .divider:hover {\r\n        background-color: #888; // 드래그바에 마우스 오버 시 색상 변경\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
