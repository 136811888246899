// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.push-settings-db-info-container {
  width: 100%;
  height: 400px;
  padding: 50px;
}
.push-settings-db-info-container .push-settings-db-info-grid-container {
  width: 100%;
  height: 100%;
}
.push-settings-db-info-container .push-settings-db-info-grid-container .dx-group-row td {
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  height: 32px;
  vertical-align: middle;
}
.push-settings-db-info-container .push-settings-db-info-grid-container .dx-data-row td {
  padding: 0px;
  font-size: 12px;
  height: 32px;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/push-settings/data/components/db-info/PushSettingsDBInfo.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;AAEI;EAEI,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;AADR;AAGI;EACI,YAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;AADR","sourcesContent":[".push-settings-db-info-container {\r\n    width: 100%;\r\n    height: 400px;\r\n    padding: 50px;\r\n\r\n    .push-settings-db-info-grid-container {\r\n        width: 100%;\r\n        height: 100%;\r\n    }\r\n\r\n    .push-settings-db-info-grid-container .dx-group-row td {\r\n        // background-color: rgba(27, 197, 189, 0.17);\r\n        padding-top: 1px;\r\n        padding-bottom: 1px;\r\n        font-size: 12px;\r\n        height: 32px;\r\n        vertical-align: middle;\r\n    }\r\n    .push-settings-db-info-grid-container .dx-data-row td {\r\n        padding: 0px;\r\n        font-size: 12px;\r\n        height: 32px;\r\n        vertical-align: middle;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
