import TabPanel, { Item } from 'devextreme-react/tab-panel'
import { useEffect, useState } from 'react'
import { batch, useSelector } from 'react-redux'
import * as SBVCUtils from 'src/functions/ScrollBoardValueChartUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { openChartInNewWindow } from './ScrollBoardNewBrowserValueChart'
import ScrollBoardProcessCapability from './ScrollBoardProcessCapability'
import ScrollBoardQCCChart from './ScrollBoardQCCChart'
import { getQccChartRangeOptionArray, getQccChartXBarOptionArray } from './ScrollBoardQCCChartImpl'
import { extractIdsInfo } from './ScrollBoardValueChartImpl'
import ScrollBoardValueChartManual from './ScrollBoardValueChartManual'
import ScrollBoardValueChartPresentation from './ScrollBoardValueChartPresentation'
import ScrollBoardValueList from './ScrollBoardValueList'
import produce from 'immer'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import * as CDSUtils from 'src/functions/CheckDataStateUtils'

const makeOrderValueArray = (orderType, valueArray) => {
    const ordered = valueArray.map((arr) => {
        let orderedValueRaw = [...arr.valueRaw] // 초기 복사

        // 정렬 조건에 따라 orderedValueRaw 재정렬
        switch (orderType) {
            case 'date-time-asc':
                orderedValueRaw.sort((a, b) => a.v0004.localeCompare(b.v0004))
                break
            case 'date-time-desc':
                orderedValueRaw.sort((a, b) => b.v0004.localeCompare(a.v0004))
                break
            case 'part-ident-asc':
                orderedValueRaw.sort((a, b) => a.v0014.localeCompare(b.v0014))
                break
            case 'part-ident-desc':
                orderedValueRaw.sort((a, b) => b.v0014.localeCompare(a.v0014))
                break
            // 기본적으로 정렬하지 않음
        }

        // debug
        const data = orderedValueRaw.map((item) => item.v0014)
        console.log('SC order Data : ')
        console.log(data)

        const rtn = {
            ...arr,
            valueRaw: orderedValueRaw,
        }

        // 결과 배열 반환
        return rtn
    })

    return ordered
}

function ScrollBoardValueChartContainer(props) {
    const { mode, isPlaying, isValueChartOperating, stepReference, setStepReference, sbPageSettings, configSettings, othersPage, timerRefValueChart } = props

    const curStep = stepReference.curStep
    const stepTotalLen = stepReference.totalSteps
    const tgStep = stepReference.steps.find((item) => item.step === curStep)

    // const charArray = tgStep.data.char
    // const valueArray = tgStep.data.value

    // const sbPageSettings = useSelector((state) => state.common.pages.scrollBoard)
    // const configSettings = useSelector((state) => state.common.pages.configuration)
    const popupMode = sbPageSettings.page.valueChartPopupMode

    const [manualValueChartOptions, setManualValueChartOptions] = useState([])
    const [valueChartOptions, setValueChartOptions] = useState([])
    const [charArray, setCharArray] = useState([])
    const [valueArray, setValueArray] = useState([])

    useEffect(() => {
        // if (charArray && valueArray) {
        if (tgStep.data.char && tgStep.data.value) {
            // filter ignore values settings
            const ignoreEnable = sbPageSettings.page.ignoreValuesEnable
            const uslLimit = sbPageSettings.page.ignoreValuesUslPercentSetting
            const lslLimit = sbPageSettings.page.ignoreValuesLslPercentSetting

            let filteredValueArray
            if (true) {
                filteredValueArray = tgStep.data.value.map((values) => {
                    return {
                        ...values,
                        valueRaw: [...values.valueRaw].sort((a, b) => a.v0004.localeCompare(b.v0004)),
                    }
                })
            }

            if (ignoreEnable) {
                filteredValueArray = filteredValueArray.map((values) => {
                    return {
                        ...values,
                        valueRaw: values.valueRaw.filter((value) => {
                            const lsl = PCUtils.getLsl(values.charRaw)
                            const usl = PCUtils.getUsl(values.charRaw)

                            if (lsl !== null && usl !== null) {
                                const gapUslLsl = PCUtils.getUslLslGap(usl, lsl)
                                const lslIgnoreValue = lsl - (gapUslLsl * (lslLimit - 100)) / 100
                                const uslIgnoreValue = usl + (gapUslLsl * (uslLimit - 100)) / 100
                                const lslCondition = value.v0001 > lslIgnoreValue
                                const uslCondition = value.v0001 < uslIgnoreValue

                                return uslCondition && lslCondition
                            }
                            return true
                        }),
                    }
                })
            }

            // filter except 0
            filteredValueArray = filteredValueArray.map((item) => ({
                ...item,
                valueRaw: item.valueRaw.filter((value) => value.v0002 !== 255),
            }))

            // content order
            const orderedValueArray = makeOrderValueArray(sbPageSettings.page.valueListContentOrder, filteredValueArray)

            const manualValueChartOptions = SBVCUtils.makeChartOptionArray('manual', 'scrollBoard', tgStep.data.char, orderedValueArray, sbPageSettings, configSettings, othersPage, mode)
            const valueChartOptions = SBVCUtils.makeChartOptionArray(null, 'scrollBoard', tgStep.data.char, orderedValueArray, sbPageSettings, configSettings, othersPage, mode)

            batch(() => {
                setCharArray(tgStep.data.char)
                setValueArray(orderedValueArray)
                setManualValueChartOptions(manualValueChartOptions)
                setValueChartOptions(valueChartOptions)
            })
        }
    }, [stepReference, sbPageSettings, configSettings, tgStep])

    if (mode === 'presentation') {
        if (valueChartOptions.length > 0) {
            console.log('ScrollBoardValueChartContainer Auto Slide')
            return (
                <div style={{ width: '100%', height: '100%' }}>
                    <ScrollBoardValueChartPresentation
                        mode={mode}
                        isPlaying={isPlaying}
                        isValueChartOperating={isValueChartOperating}
                        chartOptions={valueChartOptions}
                        charArray={charArray}
                        values={valueArray}
                        timerRefValueChart={timerRefValueChart}
                    />
                </div>
            )
        }
    } else {
        if (manualValueChartOptions.length > 0) {
            // mode === 'manual'
            console.log('ScrollBoardValueChartContainer Manual')
            const targetID = extractIdsInfo(typeof tgStep.key === 'string' ? tgStep.key : tgStep.key[0].result_key)
            // const targetID = extractPresentationIds(tgStep.key)
            const tgChar = charArray.find((value) => value.part_id === targetID.part_id && value.char_id === targetID.char_id)
            const tgValue = valueArray.find((value) => value.part_id === targetID.part_id && value.char_id === targetID.char_id)
            if (popupMode === true) {
                console.log('ScrollBoardValueChartContainer Manual popup')
                openChartInNewWindow({
                    ...{
                        ...props,
                        chartOptions: manualValueChartOptions,
                        mode,
                    },
                })
                return <div>Popup Mode</div> // 새 창에서 렌더링할 경우, 현재 컴포넌트에서는 아무것도 렌더링하지 않음
            } else {
                let xBarChartOption = null
                let rangeChartOption = null
                if (valueArray[0].valueRaw.length > 5) {
                    xBarChartOption = getQccChartXBarOptionArray(sbPageSettings, configSettings, charArray[0], valueArray[0].valueRaw)
                    rangeChartOption = getQccChartRangeOptionArray(sbPageSettings, configSettings, charArray[0], valueArray[0].valueRaw)
                }
                return (
                    // <div className="dx-theme-background-color">
                    <TabPanel
                        style={{ width: '100%', height: '100%' }}
                        showNavButtons={true}
                        repaintChangesOnly={true}
                    >
                        <Item title={dqTranslateMsg('Page_ScrollBoard_' + 'ValueChart')}>
                            <ScrollBoardValueChartManual
                                chartOptions={manualValueChartOptions}
                                mode={mode}
                                char={tgChar}
                                value={tgStep.data.value}
                                sbPageSettings={sbPageSettings}
                                configSettings={configSettings}
                                othersPage={othersPage}
                                stepReference={stepReference}
                                setStepReference={setStepReference}
                            />
                        </Item>
                        <Item title={dqTranslateMsg('Page_ScrollBoard_' + 'ValueList')}>
                            {/* <ScrollBoardValueList
                                char={tgChar}
                                value={tgValue}
                                mode={mode}
                            /> */}
                            <ScrollBoardValueList
                                char={charArray[0]}
                                value={valueArray[0]}
                                mode={mode}
                            />
                        </Item>
                        <Item title={dqTranslateMsg('Page_ScrollBoard_' + 'QualityControlChart')}>
                            {xBarChartOption && rangeChartOption ? (
                                <ScrollBoardQCCChart
                                    xBarChartOption={xBarChartOption}
                                    rangeChartOption={rangeChartOption}
                                    mode={mode}
                                />
                            ) : (
                                <>
                                    <img
                                        src={'/images/color/warning-exclamation.svg'}
                                        width='30'
                                        height='30'
                                    />
                                    <div style={{ fontSize: '20px' }}>{dqTranslateMsg('Page_QualityControlChart_' + 'DataIsNotEnough')}</div>
                                </>
                            )}
                        </Item>
                        <Item title={dqTranslateMsg('Page_ScrollBoard_' + 'ProcessCapability')}>
                            <ScrollBoardProcessCapability
                                char={tgChar}
                                value={valueArray[0].valueRaw}
                                mode={mode}
                            />
                        </Item>
                    </TabPanel>
                    // </div>
                )
            }
        }
    }
}

export default ScrollBoardValueChartContainer
