import { ColorBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'

const ColorBoxSimpleItem = ({ colSpan, label, labelLocation, name, value, disabled, onValueChanged }) => {
  return (
    <SimpleItem colSpan={colSpan}>
      <Label text={label} location={labelLocation} />
      <ColorBox
        // className="colorbox-element"
        name={name}
        value={value}
        // label={label}
        // labelMode={labelLocation}
        disabled={disabled}
        editAlphaChannel={true}
        onValueChanged={onValueChanged}
        focusStateEnabled={true}
      />
    </SimpleItem>
  )
}

export default ColorBoxSimpleItem
