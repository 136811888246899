import DataGrid, {
    ColumnChooserSearch,
    ColumnChooserSelection,
    Column as DataGridColumn,
    SearchPanel as DataGridSearchPanel,
    Editing,
    Export,
    Item,
    Position,
    Scrolling,
    Toolbar,
} from 'devextreme-react/data-grid'
import TreeList, { ColumnChooser, HeaderFilter, Selection, Column as TreeListColumn, SearchPanel as TreeListSearchPanel } from 'devextreme-react/tree-list'
import produce from 'immer'
import { useAuth } from 'src/contexts/auth'
import { convertLanguageStructureForDatagrid, convertLanguageStructureForTreeList, dqTranslateMsg, languageData } from 'src/pages/components/dq-convert/DQLanguage'
// import { setLanguageEn, setLanguageJp, setLanguageKr } from 'src/store/common'
import { Button } from 'devextreme-react'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminCompany } from 'src/api/admin/AdminCompanyApi.js'
import { makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper.js'
import { useApi } from 'src/contexts/api.js'
import { useLanguage } from 'src/contexts/languages.js'
import { makeAdminCompanyToDTOFormat } from 'src/dto/DTOImpl.js'
import DQToast from 'src/functions/DQToast'
import { companyPlantTbDescription as companyPlantTbDesc } from 'src/functions/constants/companyPlantDBfieldDescription'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { adminSettingsPrm } from 'src/pages/admin/components/settings/AdminSettings'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import './PushSettingsLanguage.scss'
import { Grid } from '@mui/material'

const expandedKeys = []

export default function PushSettingsLanguage(props) {
    const { user } = useAuth()
    const { spring } = useApi()
    const { updateLanguage } = useLanguage()
    const dispatch = useDispatch()
    const dataGridRef = useRef(null)
    const toastRef = useRef(null)

    const stateAll = props.state
    const state = props.state.language
    const setState = props.setState

    const [tempState, setTempState] = useState(state)
    const [treeListDataSource, setTreeListDataSource] = useState([])
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        const treeListDataSource = convertLanguageStructureForTreeList(tempState)
        const dataSource = convertLanguageStructureForDatagrid(tempState)

        setTreeListDataSource(treeListDataSource)
        setDataSource(dataSource)
    }, [])

    const handleSaveButton = () => {
        const next = produce(stateAll, (draft) => {
            draft.language = tempState
        })

        setState(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
    }

    const handleResetButton = async () => {
        const next = produce(stateAll, (draft) => {
            draft.language = languageData
        })

        setState(next)
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
    }

    const handleRowUpdating = (e) => {
        const oldData = e.oldData
        const modified = e.newData
        if (modified != null) {
            const refData = e.key
            const next = produce(tempState, (draft) => {
                if (refData.page === '-') {
                    Object.entries(modified).forEach((pair) => {
                        draft[refData.type].data[pair[0]][refData.key] = pair[1]
                    })
                } else {
                    Object.entries(modified).forEach((pair) => {
                        draft[refData.type][refData.page].data[pair[0]][refData.key] = pair[1]
                    })
                }
            })

            console.log(next)
            setTempState(next)
        }
    }

    const handleTreeListSelectionChanged = (e) => {
        const selectedData = e.component.getSelectedRowsData('all')

        const dataGrid = dataGridRef.current.instance
        const filter = []

        if (selectedData.length > 0) {
            const selectedItem = e.selectedRowsData[0]
            const node = e.component.getNodeByKey(selectedItem.id)
            if (node && node.parent !== undefined) {
                const parentNode = e.component.getNodeByKey(node.parent.key)
                if (parentNode && parentNode.level !== -1) {
                    // Now you have access to the parent node's data
                    console.log('Parent Node Data:', parentNode.data)
                    filter.push(['type', '=', parentNode.data.text])
                    filter.push('and')
                    filter.push(['page', '=', selectedItem.text])
                } else {
                    filter.push(['type', '=', selectedItem.text])
                }
            }
        }

        dataGrid.filter(filter)
    }

    const cellRenderer = (data) => {
        const getIconClass = (menuType) => {
            if (menuType === 'Header') {
                return 'dx-icon-header'
            } else if (menuType === 'Navigation') {
                return 'dx-icon-menu'
            } else if (menuType === 'SidePanel') {
                return 'dx-icon-pinright'
            } else if (menuType === 'Page') {
                return 'dx-icon-mention'
            } else if (menuType === 'Db_field') {
                return 'dx-icon-fields'
            } else if (menuType === 'CommunicationErrorMsg') {
                return 'dx-icon-info'
            } else if (menuType === 'ProcessErrorMsg') {
                return 'dx-icon-belloutline'
            } else if (menuType === 'Common') {
                return 'dx-icon-mediumiconslayout'
            } else if (menuType === 'Login') {
                return 'dx-icon-login'
            } else {
                return 'dx-icon-to'
            }
        }
        const iconClass = getIconClass(data.data.text)
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <i
                    class={iconClass}
                    style={{
                        marginRight: '8px',
                        width: '15px',
                        height: '15px',
                    }}
                ></i>
                {data.value}
            </div>
        )
    }

    const onExporting = (e) => {
        const fileName = 'language'

        if (e.format === 'xlsx') {
            const workbook = new Workbook()
            const worksheet = workbook.addWorksheet('Main sheet')
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`)
                })
            })
        }
    }

    return (
        <div className='dx-theme-background-color'>
            <DQToast ref={toastRef} />
            <div className='push-settings-language-control-container'>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='save'
                        text={dqTranslateMsg('Page_Configuration_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>
                <div className='button-container'>
                    <Button
                        id='partLoadButton'
                        icon='undo'
                        text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            <div className='push-settings-language-container'>
                <Grid
                    container
                    spacing={0}
                    style={{ width: '100%', height: '400px' }}
                >
                    <Grid
                        item
                        xs={3}
                        style={{ padding: '3px', width: '100%', height: '400px', display: 'flex', flexDirection: 'column' }}
                    >
                        <TreeList
                            style={{ width: '100%', height: '100%' }}
                            dataSource={treeListDataSource}
                            showBorders={true}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            defaultExpandedRowKeys={expandedKeys}
                            noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
                            keyExpr='id'
                            parentIdExpr='parentId'
                            onSelectionChanged={handleTreeListSelectionChanged}
                        >
                            <TreeListSearchPanel
                                visible={true}
                                width={150}
                                placeholder={dqTranslateMsg('Page_AdminSettings_' + 'SearchPlaceHoler')}
                            />
                            <HeaderFilter visible={true} />
                            <Selection mode='single' />
                            <TreeListColumn
                                dataField='text'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Navigator')}
                                width={'100%'}
                                cellRender={cellRenderer}
                            />
                        </TreeList>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        style={{ padding: '3px', width: '100%', height: '400px', display: 'flex', flexDirection: 'column' }}
                    >
                        {/* <div id='langDispDiv'> */}
                        <DataGrid
                            ref={dataGridRef}
                            id='langDispDiv'
                            style={{ width: '100%', height: '100%' }}
                            dataSource={dataSource}
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            columnResizingMode='widget'
                            allowColumnResizing={true}
                            filterRow={{ visible: false }}
                            noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
                            paging={{ enabled: false }}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            hoverStateEnabled={true}
                            onExporting={onExporting}
                            onRowUpdating={handleRowUpdating}
                        >
                            <Toolbar>
                                <Item name='saveButton' />
                                <Item name='searchPanel' />
                                <Item
                                    name='columnChooserButton'
                                    location='after'
                                />
                                <Item
                                    name='exportButton'
                                    location='after'
                                />
                            </Toolbar>
                            <Editing
                                mode='row'
                                useIcons={true}
                                allowUpdating={true}
                            />

                            <ColumnChooser
                                enabled={true}
                                mode={'select'}
                                width={300}
                                height={500}
                                title={dqTranslateMsg('Page_AdminSettings_' + 'ColumnChooserTitle')}
                                emptyPanelText={dqTranslateMsg('Page_AdminSettings_' + 'ColumnChooserEmptyPanelText')}
                            >
                                <Position
                                    my='center'
                                    at='center'
                                    of={document.getElementById('langDispDiv')}
                                />
                                <ColumnChooserSearch
                                    enabled={true}
                                    editorOptions={{
                                        placeholder: dqTranslateMsg('Page_AdminSettings_' + 'ColumnChooserPlaceHoler'),
                                    }}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={false}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <DataGridSearchPanel
                                visible={true}
                                width={150}
                                placeholder={dqTranslateMsg('Page_AdminSettings_' + 'SearchPlaceHoler')}
                            />
                            <Scrolling
                                mode='standard'
                                rowRenderingMode='standard'
                                useNative={false}
                            />

                            <DataGridColumn
                                dataField='id'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'ID')}
                                alignment='center'
                                width={'5%'}
                                allowEditing={false}
                            />
                            <DataGridColumn
                                dataField='type'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Type')}
                                width={'19%'}
                                visible={false}
                                alignment='center'
                                allowEditing={false}
                            />
                            <DataGridColumn
                                dataField='page'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Page')}
                                width={'19%'}
                                alignment='center'
                                allowEditing={false}
                            />
                            <DataGridColumn
                                dataField='key'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Key')}
                                width={'19%'}
                                alignment='center'
                                allowEditing={false}
                            />
                            <DataGridColumn
                                dataField='en'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'English')}
                                width={'19%'}
                                alignment='center'
                            />
                            <DataGridColumn
                                dataField='ja'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Japanese')}
                                width={'19%'}
                                alignment='center'
                            />
                            <DataGridColumn
                                dataField='kr'
                                caption={dqTranslateMsg('Page_AdminSettings_' + 'Korean')}
                                width={'19%'}
                                alignment='center'
                            />
                            <Export
                                enabled={true}
                                formats={['xlsx']}
                                texts={{
                                    exportAll: dqTranslateMsg('Page_ValueList_' + 'ExportToExcel'),
                                }}
                            />
                        </DataGrid>
                        {/* </div> */}
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
