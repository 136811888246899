import React from 'react'
import styled from 'styled-components'
import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Export,
} from 'devextreme-react/pie-chart'
import 'devexpress-diagram/dist/dx-diagram.min.css' // need to add manually

export const areasArray = [
  [
    {
      country: 'Russia',
      area: 12,
    },
    {
      country: 'Canada',
      area: 7,
    },
    {
      country: 'USA',
      area: 7,
    },
  ],
  [
    {
      country: 'Russia',
      area: 12,
    },
    {
      country: 'Canada',
      area: 7,
    },
    {
      country: 'USA',
      area: 7,
    },
  ],
  [
    {
      country: 'Russia',
      area: 12,
    },
    {
      country: 'Canada',
      area: 7,
    },
    {
      country: 'USA',
      area: 7,
    },
  ],
  [
    {
      country: 'Russia',
      area: 12,
    },
    {
      country: 'Canada',
      area: 7,
    },
    {
      country: 'USA',
      area: 7,
    },
  ],
  [
    {
      country: 'Russia',
      area: 12,
    },
    {
      country: 'Canada',
      area: 7,
    },
    {
      country: 'USA',
      area: 7,
    },
  ],
]

const StyledPieChart = styled(PieChart)`
  width: 100%;
  height: 100%;
`

function customizeTooltip(arg) {
  return {
    text: `${arg.seriesName} Count : ${arg.valueText}`,
  }
}

function convertDataSource(data) {
  console.log(data)
  let converted = []
  data.map((item, index) => {
    converted.push({
      id: index,
      result_id: item.result_id,
      result_key: item.result_key,
      result_field: item.result_field,
      result_user_id: item.result_user_id,
      result_key: item.result_key,
      result_good_count: item.result_good_count,
      result_warning_count: item.result_warning_count,
      result_ng_count: item.result_ng_count,
      result_total_count: item.result_total_count,
    })
  })

  return converted
}

export default function ScrollBoardPieChart({ id, style, page, data }) {
  const dataSource = convertDataSource(data)

  const totalItems = areasArray.length
  let numColumns = Math.ceil(Math.sqrt(totalItems))
  let numRows = Math.ceil(totalItems / numColumns)

  if (numColumns * (numRows - 1) >= totalItems) {
    numRows--
  }

  const widthPerChart = `${100 / numColumns}%`
  const heightPerChart = `${100 / numRows}%`

  const makePieChart = areasArray.map((area, index) => {
    return (
      <div
        style={{
          width: widthPerChart,
          height: heightPerChart,
          padding: '5px',
        }}
      >
        <PieChart
          key={index}
          id={`pie-${index}`}
          dataSource={area}
          palette="Bright"
          onPointClick={mode === 'presentation' ? null : pointClickHandler}
          onLegendClick={mode === 'presentation' ? null : legendClickHandler}
          size={{
            width: `calc(100% / ${numColumns})`,
            height: `calc(100% / ${numRows})`,
          }} // Use CSS calc to determine width and height
        >
          <Series argumentField="country" valueField="area">
            <Label visible={true}>
              <Connector visible={true} width={1} />
            </Label>
          </Series>
          <Export enabled={true} />
        </PieChart>
      </div>
    )
  })

  return (
    <div
      id={id}
      style={{
        ...style,
        display: 'grid',
        gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
        gridGap: '10px',
        height: '100%',
      }}
    >
      {makePieChart}
    </div>
  )
}

function pointClickHandler(e) {
  toggleVisibility(e.target)
}

function legendClickHandler(e) {
  const arg = e.target
  const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0]
  toggleVisibility(item)
}

function toggleVisibility(item) {
  item.isVisible() ? item.hide() : item.show()
}
