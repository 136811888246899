// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 50px);
}
.page-container .chart-container {
  width: 85%;
  height: 100%;
}
.page-container .chart-container .chart-form-content::-webkit-scrollbar {
  width: 5px;
}
.page-container .chart-container .chart-form-content::-webkit-scrollbar-thumb {
  background-color: #3e4451;
}
.page-container .chart-container .chart-form-content::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
.page-container .side-panel-container {
  width: 15%;
  height: 100%;
}
.page-container .divider {
  background-color: #ccc;
  cursor: col-resize;
  z-index: 10;
  position: relative;
  transition: background-color 0.2s ease;
}
.page-container .divider:hover {
  background-color: #888;
}`, "",{"version":3,"sources":["webpack://./src/functions/PageContainer.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,0BAAA;AACJ;AACI;EACI,UAAA;EACA,YAAA;AACR;AAEQ;EACI,UAAA;AAAZ;AAEQ;EACI,yBAAA;AAAZ;AAEQ;EACI,wCAAA;AAAZ;AAII;EACI,UAAA;EACA,YAAA;AAFR;AAMI;EACI,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,sCAAA;AAJR;AAOI;EACI,sBAAA;AALR","sourcesContent":[".page-container {\r\n    display: flex;\r\n    flex-direction: row;\r\n    width: 100%;\r\n    height: calc(100vh - 50px);\r\n\r\n    .chart-container {\r\n        width: 85%;\r\n        height: 100%;\r\n        // z-index: 1; // 뒤로 보내기 위해 낮은 z-index 설정\r\n\r\n        .chart-form-content::-webkit-scrollbar {\r\n            width: 5px;\r\n        }\r\n        .chart-form-content::-webkit-scrollbar-thumb {\r\n            background-color: #3e4451;\r\n        }\r\n        .chart-form-content::-webkit-scrollbar-track {\r\n            background-color: rgba(255, 255, 255, 0);\r\n        }\r\n    }\r\n\r\n    .side-panel-container {\r\n        width: 15%;\r\n        height: 100%;\r\n        // z-index: 2; // 뒤로 보내기 위해 낮은 z-index 설정\r\n    }\r\n\r\n    .divider {\r\n        background-color: #ccc;\r\n        cursor: col-resize;\r\n        z-index: 10;\r\n        position: relative;\r\n        transition: background-color 0.2s ease; // 부드러운 색상 전환 추가\r\n    }\r\n\r\n    .divider:hover {\r\n        background-color: #888; // 드래그바에 마우스 오버 시 색상 변경\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
