export const summaryListSettingsPrm = {
  page: {
    // iconType: {
    //   result: 'arrow',
    //   outOfTolerances: 'arrow',
    // },
    decimalPlaces: 3,
    cpGood: 1.33,
    cpkGood: 1.33,
  },
  theme: {
    iconType: {
      result: 'thumbs',
      outOfTolerances: 'thumbs',
    },
  },
  lineChart: {
    symbols: {
      error: {
        upper: {
          shape: 'triangle',
          size: 3,
          color: 'rgba(255, 0, 0, 1)',
        },
        lower: {
          shape: 'path://M 100 100 L 300 100 L 200 300 z',
          size: 3,
          color: 'rgba(255, 0, 0, 1)',
        },
      },
      warn: {
        upper: {
          shape: 'rect',
          size: 3,
          color: 'rgba(255, 255, 0, 1)',
        },
        lower: {
          shape: 'rect',
          size: 3,
          color: 'rgba(255, 255, 0, 1)',
        },
      },
      good: {
        shape: 'circle',
        size: 1,
        color: 'rgba(0, 255, 0, 1)',
      },
    },
    chartLines: {
      type: 'solid',
      width: 2,
      color: 'rgba(0, 255, 0, 1)',
    },
    markLines: {
      precision: 10,
      specLimit: {
        show: true,
        type: 'solid',
        color: 'rgba(255, 0, 0, 1)',
        width: 1,
      },
      warnLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(255, 234, 3, 1)',
        width: 1,
      },
      controlLimit: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 127, 207, 1)',
        width: 1,
      },
      center: {
        show: true,
        type: 'dashed',
        color: 'rgba(0, 127, 207, 1)',
        width: 1,
      },
    },
    markAreas: {
      error: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 8, 8, 0)',
      },
      warn: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 255, 0, 0)',
      },
      good: {
        show: true,
        min: 0,
        max: 0,
        color: 'rgba(255, 255, 0, 0)',
      },
    },
  },
}
