import Form, { Item, GroupItem, Label, EmptyItem, ValidationRule } from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDatabaseInfo, getDatabaseList, setDatabaseInfo } from 'src/api/db'
import ApiStatus from 'src/api/defines/ApiStatus'
import { useAuth } from 'src/contexts/auth'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './AdminDBInfo.scss'
import { Toast } from 'devextreme-react'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
import DQToast from 'src/functions/DQToast'

const dbTypeDefine = () => [
    {
        value: 0,
        text: dqTranslateMsg('Page_AdminSettings_' + 'MySQL'),
    },
    {
        value: 1,
        text: dqTranslateMsg('Page_AdminSettings_' + 'MsSQL'),
    },
    {
        value: 2,
        text: dqTranslateMsg('Page_AdminSettings_' + 'MongoDB'),
    },
]

const convertDBInfoForForm = (dbInfo) => {
    if (dbInfo.length > 0) {
        const info = dbInfo[0]
        const converted = [
            {
                Address: info.db_address,
                ID: info.db_id,
                Password: info.db_password,
                Key: info.db_info_key,
                DB_Name: info.db_name,
                Port: info.db_port,
                Type: info.db_type,
            },
        ]

        return converted[0]
    } else {
        return []
    }
}

export default function AdminDBInfo({ pageKey }) {
    const formRef = useRef(null) // Form 컴포넌트에 대한 참조 생성
    const selectRef = useRef(null) // Form 컴포넌트에 대한 참조 생성
    const dataGridRef = useRef(null)
    const toastRef = useRef(null)

    const { user } = useAuth()
    const dispatch = useDispatch()
    const common = useSelector((state) => state.common)
    const curPage = common.pages.adminSettings
    const [dbInfo, setDBInfo] = useState([])
    const [dbList, setDBList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getDatabaseInfo().then((result) => {
            if (!ApiStatus.isSuccess(result.status)) {
                alert(getErrCommMsg(result.status))
            }

            const databaseInfo = result.data
            const req = {
                address: databaseInfo[0].db_address,
                port: databaseInfo[0].db_port,
                username: databaseInfo[0].db_id,
                password: databaseInfo[0].db_password,
            }

            getDatabaseList(req)
                .then((result) => {
                    setDBInfo(databaseInfo)
                    setDBList(result)
                })
                .catch(() => {
                    console.log('111')
                })
        })
    }, [])

    const handleSetDatabaseInfo = () => {
        const formData = formRef.current.instance.option('formData')
        const req = {
            address: formData.Address,
            port: formData.Port,
            username: formData.ID,
            password: formData.Password,
            db_name: formData.DB_Name,
        }
        const newDBInfo = {
            db_address: req.address,
            db_port: req.port,
            db_id: req.username,
            db_password: req.password,
            db_name: req.db_name,
        }
        const info = [
            {
                ...dbInfo[0],
                ...newDBInfo,
            },
        ]
        setDatabaseInfo(req).then((result) => {
            if (!ApiStatus.isSuccess(result.status)) {
                alert(getErrCommMsg(result.status))
            }

            setDBInfo(info)
            toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_AdminSettings_' + 'SavedSettings'))
        })
    }
    const handleGetDatabaseInfo = () => {
        getDatabaseInfo().then((result) => {
            if (!ApiStatus.isSuccess(result.status)) {
                alert(getErrCommMsg(result.status))
            }
            setDBInfo(result.data)
        })
    }
    const handleGetDatabaseList = () => {
        selectRef.current.open()
    }

    const textKeyEditorOptions = {
        stylingMode: 'filled',
        // showSpinButtons: true,
        // mode: 'number',
        format: 'fixedPoint',
        readOnly: true,
    }
    const textEditorOptions = {
        stylingMode: 'filled',
        // showSpinButtons: true,
        // mode: 'number',
        format: 'fixedPoint',
    }

    const changePasswordMode = useCallback((name) => {
        const editor = formRef.current.instance.getEditor(name)
        editor.option('mode', editor.option('mode') === 'text' ? 'password' : 'text')
    }, [])

    const textPasswordEditorOptions = {
        stylingMode: 'filled',
        // showSpinButtons: true,
        mode: 'password',
        format: 'fixedPoint',
        showClearButton: true,
        buttons: [
            {
                name: 'password',
                location: 'after',
                options: {
                    stylingMode: 'text',
                    icon: 'eyeopen',
                    onClick: () => changePasswordMode('Password'),
                },
            },
        ],
    }

    const numberEditorOptions = {
        stylingMode: 'filled',
        showSpinButtons: true,
        mode: 'number',
        // format: 'fixedPoint',
    }

    const [selectTypeEditorOptions, setSelectTypeEditorOptions] = useState({
        stylingMode: 'filled',
        items: dbTypeDefine(),
        searchEnabled: false,
        value: '',
        valueExpr: 'value',
        displayExpr: 'text',
    })

    useEffect(() => {
        // dbList가 업데이트되면 SelectBox의 items 옵션 업데이트
        setSelectTypeEditorOptions((prevOptions) => ({
            ...prevOptions,
            value: dbInfo.length > 0 ? dbInfo[0].db_type : '',
        }))
    }, [dbList, dbInfo])

    const handleInitialized = (e) => {
        // 로딩이 완료된 경우에만 실행
        selectRef.current = e.component
    }

    const handleFocusIn = () => {
        console.log('handleFocusIn')
        const formData = formRef.current.instance.option('formData')
        const req = {
            address: formData.Address,
            port: formData.Port,
            username: formData.ID,
            password: formData.Password,
        }

        getDatabaseList(req).then((result) => {
            const newDBInfo = {
                db_address: req.address,
                db_port: req.port,
                db_id: req.username,
                db_password: req.password,
            }
            if (result.length > 0) {
                // 결과를 formRef를 사용하여 Form의 formData 직접 업데이트
                formRef.current.instance.option('formData', {
                    ...formData,
                    ...newDBInfo,
                })
                selectRef.current.option('items', result)
                selectRef.current.option('value', formData.DB_Name)
                // // 성공 메시지 표시
            } else {
                // 결과가 없는 경우도 DBInfo를 업데이트
                formRef.current.instance.option('formData', {
                    ...formData,
                    ...newDBInfo,
                })

                selectRef.current.option('items', result)
                selectRef.current.option('value', formData.DB_Name)
                // 실패 메시지 표시
                // handleToastDisplay(
                //   'warning',
                //   dqTranslateMsg('Page_AdminSettings_' + 'FailUpdate')
                // )
            }
        })
    }
    const [selectNameEditorOptions, setSelectNameEditorOptions] = useState({
        stylingMode: 'filled',
        items: [],
        searchEnabled: false,
        value: '',
        onFocusIn: handleFocusIn,
        onInitialized: handleInitialized,
        onOpened: handleGetDatabaseList,
    })

    useEffect(() => {
        // dbList가 업데이트되면 SelectBox의 items 옵션 업데이트
        setSelectNameEditorOptions((prevOptions) => ({
            ...prevOptions,
            items: dbList,
            value: dbInfo.length > 0 ? dbInfo[0].db_name : '',
        }))
    }, [dbList, dbInfo])

    const buttonNameEditorOptions = {
        stylingMode: 'outlined',
        icon: 'arrowleft',
        text: dqTranslateMsg('Page_AdminSettings_' + 'UpdateDBName'),
        // type: 'success',
        width: '100%',
        onClick: (e) => {
            console.log('btnopclick')
            handleGetDatabaseList()
        },
    }
    const buttonSaveEditorOptions = {
        stylingMode: 'outlined',
        text: dqTranslateMsg('Page_AdminSettings_' + 'Save'),
        // type: 'success',
        width: '100%',
        onClick: (e) => {
            console.log('handleSetDatabaseInfo')
            handleSetDatabaseInfo()
        },
    }

    function LabelTemplate(iconName) {
        return function template(data) {
            return (
                <div>
                    {/* <i className={`dx-icon dx-icon-${iconName}`}></i> */}
                    {data.text}
                </div>
            )
        }
    }

    return (
        <div className='dx-theme-background-color'>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <DQToast ref={toastRef} />
                <Form
                    ref={formRef} // Form 컴포넌트에 ref 연결
                    // id="form"
                    labelMode={'outside'}
                    formData={convertDBInfoForForm(dbInfo)}
                    readOnly={false}
                    showColonAfterLabel={false}
                    labelLocation={'top'}
                    minColWidth={300}
                    colCount={2}
                    width={600}
                    height={500}
                >
                    <Item
                        dataField={'Key'}
                        editorType={'dxTextBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Key') }}
                        editorOptions={textKeyEditorOptions}
                    >
                        <Label render={LabelTemplate('column')} />
                    </Item>
                    {/* <Item
            dataField={'Address'}
            // editorType={'dxNumberBox'}
            label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Address') }}
            editorOptions={textEditorOptions}
          >
            <Label render={LabelTemplate('Address')} />
          </Item> */}
                    <Item
                        dataField={'Address'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Address') }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                            ...textEditorOptions,
                            validationMessageMode: 'always', // This ensures the validation message is shown immediately
                        }}
                    >
                        <Label render={LabelTemplate('Address')} />
                        <ValidationRule
                            type='pattern'
                            pattern='^[a-zA-Z0-9._-]*$' // This pattern allows English letters, digits, periods, hyphens, and underscores
                            message='Only English letters, digits, periods, hyphens, and underscores are allowed.'
                        />
                    </Item>
                    <Item
                        dataField={'ID'}
                        editorType={'dxTextBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'ID') }}
                        editorOptions={textEditorOptions}
                    >
                        <Label render={LabelTemplate('row')} />
                    </Item>
                    <Item
                        dataField={'Password'}
                        // editorType={'dxNumberBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Password') }}
                        editorOptions={textPasswordEditorOptions}
                    >
                        <Label render={LabelTemplate('column')} />
                    </Item>
                    <Item
                        dataField={'Port'}
                        editorType={'dxNumberBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Port') }}
                        editorOptions={numberEditorOptions}
                    ></Item>
                    <Item
                        dataField={'Type'}
                        editorType={'dxSelectBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'Type') }}
                        editorOptions={selectTypeEditorOptions}
                    >
                        <Label render={LabelTemplate('row')} />
                    </Item>
                    <Item
                        dataField={'DB_Name'}
                        editorType={'dxSelectBox'}
                        label={{ text: dqTranslateMsg('Page_AdminSettings_' + 'DBName') }}
                        editorOptions={selectNameEditorOptions}
                    ></Item>
                    {/* <Item
            // dataField={'DB_Name'}
            editorType={'dxButton'}
            editorOptions={buttonNameEditorOptions}
          >
            <Label render={LabelTemplate('row')} />
          </Item> */}
                    <EmptyItem />
                    <Item
                        // dataField={'DB_Name'}
                        editorType={'dxButton'}
                        colSpan={2}
                        editorOptions={buttonSaveEditorOptions}
                    >
                        {/* <Label render={LabelTemplate('row')} /> */}
                    </Item>
                </Form>
            </div>
        </div>
    )
}
