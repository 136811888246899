// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.push-settings-use-fields-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.push-settings-use-fields-container .push-settings-use-fields-control-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 4%;
}
.push-settings-use-fields-container .push-settings-use-fields-control-container .button-container {
  width: 8%;
  height: 100%;
}
.push-settings-use-fields-container .push-settings-use-fields-content-container {
  width: 100%;
  height: 96%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.push-settings-use-fields-container .push-settings-title-container {
  width: 100%;
  height: 5%;
  font-family: "Arial";
  font-size: 15px;
}
.push-settings-use-fields-container .push-settings-datagrid-content {
  width: 100%;
  height: 95%;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/push-settings/data/components/use-fields/PushSettingsUseFields.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACI;EACI,aAAA;EACA,yBAAA;EACA,WAAA;EACA,UAAA;AACR;AACQ;EACI,SAAA;EACA,YAAA;AACZ;AAGI;EACI,WAAA;EACA,WAAA;EAEA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AAFR;AAKI;EACI,WAAA;EACA,UAAA;EACA,oBAAA;EACA,eAAA;AAHR;AAMI;EACI,WAAA;EACA,WAAA;AAJR","sourcesContent":[".push-settings-use-fields-container {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    padding: 5px;\r\n\r\n    .push-settings-use-fields-control-container {\r\n        display: flex;\r\n        justify-content: flex-end;\r\n        width: 100%;\r\n        height: 4%;\r\n\r\n        .button-container {\r\n            width: 8%;\r\n            height: 100%;\r\n        }\r\n    }\r\n\r\n    .push-settings-use-fields-content-container {\r\n        width: 100%;\r\n        height: 96%;\r\n        // max-height: 600px;\r\n        padding: 5px;\r\n        display: flex;\r\n        flex-direction: row;\r\n        padding: 5px;\r\n    }\r\n\r\n    .push-settings-title-container {\r\n        width: 100%;\r\n        height: 5%;\r\n        font-family: 'Arial';\r\n        font-size: 15px;\r\n    }\r\n\r\n    .push-settings-datagrid-content {\r\n        width: 100%;\r\n        height: 95%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
