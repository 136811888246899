import { useState } from 'react'
import DatabaseCharForm from './database-char-form/DatabaseCharForm'
import DatabaseFilterForm from './database-filter-form/DatabaseFilterForm'
import DatabasePartForm from './database-part-form/DatabasePartForm'
import './DatabaseForm.scss'

function DatabaseForm({ pageKey }) {
    const [partWidth, setPartWidth] = useState(50) // DatabasePartForm의 초기 너비 (%)
    const dividerWidth = 5 // divider의 너비

    const handleMouseDown = (e) => {
        e.preventDefault()
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseMove = (e) => {
        const newPartWidth = (e.clientX / window.innerWidth) * 100
        setPartWidth(Math.min(Math.max(newPartWidth, 30), 70))
    }

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
    }
    try {
        return (
            <div className='dx-theme-background-color'>
                {/* 드래그바 */}
                <div
                    className='database-part-container'
                    style={{ width: `calc(${partWidth}% - ${dividerWidth / 2}px)` }} // divider 고려
                >
                    <DatabasePartForm />
                </div>
                {/* 드래그바 */}
                {/* <div
                    className='divider'
                    style={{ width: `${dividerWidth}px` }} // divider의 실제 너비 설정
                    onMouseDown={handleMouseDown}
                ></div> */}
                <div
                    className='database-char-container'
                    style={{ width: `calc(${100 - partWidth}% - ${dividerWidth / 2}px)` }} // divider 고려
                >
                    <DatabaseCharForm />
                </div>
            </div>
        )
    } catch (e) {
        console.log(e)
    }
}

export default DatabaseForm
