export const msgType = {
    Proc: 'ProcessErrorMsg',
    Comm: 'CommunicationErrorMsg',
    Login: 'Login',
    FindDistribution: 'FindDistribution',
    SidePanel: 'SidePanel',
    Database: 'Database',
    ScrollBoard: 'ScrollBoard',
    AdminSettings: 'AdminSettings',
    DBField: 'Db_field',
    DBFieldDesc: 'DBFieldDesc',
}
