import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function SettingsLimitsEnable(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'probabilityPlotChart'
  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onCheckBoxLimitationEnableValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`settings-limits-enable-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'LimitationsEnable')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'SpecificationLimit'),
        labelLocation: 'right',
        name: 'specificationLimitEnable',
        value: pageState.page.specificationLimitEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationEnableValueChanged(e, 'specificationLimitEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'QuantileLimit'),
        labelLocation: 'right',
        name: 'quantileLimitEnable',
        value: pageState.page.quantileLimitEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationEnableValueChanged(e, 'quantileLimitEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Average'),
        labelLocation: 'right',
        name: 'specificationLimitEnable',
        value: pageState.page.averageLimitEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationEnableValueChanged(e, 'averageLimitEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Nominal'),
        labelLocation: 'right',
        name: 'nominalLimitEnable',
        value: pageState.page.nominalLimitEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationEnableValueChanged(e, 'nominalLimitEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'PlausibilityLimit'),
        labelLocation: 'right',
        name: 'plausibilityLimitEnable',
        value: pageState.page.plausibilityLimitEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationEnableValueChanged(e, 'plausibilityLimitEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'ScrapLimit'),
        labelLocation: 'right',
        name: 'scrapLimitEnable',
        value: pageState.page.scrapLimitEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationEnableValueChanged(e, 'scrapLimitEnable'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'AcceptanceLimit'),
        labelLocation: 'right',
        name: 'acceptanceLimitEnable',
        value: pageState.page.acceptanceLimitEnable,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitationEnableValueChanged(e, 'acceptanceLimitEnable'),
      })}
    </GroupItem>
  )
}
