import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'

export const displayType = () => [
  { value: 'top5', text: dqTranslateMsg('SidePanel_' + 'Top5') },
  { value: 'top10', text: dqTranslateMsg('SidePanel_' + 'Top10') },
  { value: 'all', text: dqTranslateMsg('SidePanel_' + 'All') },
]

export default function SettingsDisplayCount(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'correlationChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])
  // const itemWidth = 100 / 3
  const onRadioGroupCalculationTypeValueChanged = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const renderCustomItem = (item) => {
    return <div style={{ flex: 1, textAlign: 'center' }}>{data.text}</div>
  }

  return (
    <GroupItem key={`display-count-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DisplayCount')}>
      {RadioGroupSimpleItem({
        ref: null,
        colSpan: 2,
        label: '',
        labelLocation: 'top',
        name: 'displayType',
        items: displayType(),
        layout: 'horizontal',
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.displayType,
        // renderCustomItem: renderCustomItem,
        disabled: pageState.page.displayMethod === 'heatMap',
        onValueChanged: (e) => onRadioGroupCalculationTypeValueChanged(e, 'displayType'),
      })}
    </GroupItem>
  )
}
