import React from 'react'
import ReactECharts from 'echarts-for-react'
import { getRangeChartOptions, getXBarChartOptions } from './ScrollBoardQCCChartImpl'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { useTheme } from 'src/contexts/theme'
import { useLanguage } from 'src/contexts/languages'

const getViewPortTheme = (pageKey, theme) => {
  if (pageKey !== 'reportPrint') {
    return theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'
  }

  return 'light'
}

function ScrollBoardQCCChart(props) {
  const { xBarChartOption, rangeChartOption, mode } = props
  const { getTheme } = useTheme()
  const { language } = useLanguage()

  const widthPerChart = `${100 / 1}%`
  const heightPerChart = `${100 / 1}%`

  return (
    <div className={'dx-theme-background-color'} style={{ width: '100%', height: '100%' }}>
      <div
        style={{
          width: widthPerChart,
          height: heightPerChart,
          padding: '5px',
        }}
      >
        <div
          className="inner-quality-control-chart"
          style={{
            width: '100%',
            height: '100%',
            padding: '10px',
            borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            overflow: 'visible',
          }}
        >
          <ReactECharts
            style={{ width: '100%', height: '50%' }}
            option={xBarChartOption}
            theme={getViewPortTheme('scrollBoard', getTheme())}
            opts={{
              renderer: 'svg',
              locale: ETCUtils.checkLangForECharts(language),
            }}
          />
          <ReactECharts
            style={{ width: '100%', height: '50%' }}
            option={rangeChartOption}
            theme={getViewPortTheme('scrollBoard', getTheme())}
            opts={{
              renderer: 'svg',
              locale: ETCUtils.checkLangForECharts(language),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ScrollBoardQCCChart
