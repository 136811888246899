import React, { useEffect, useRef, useState } from 'react'
import { GroupItem, SimpleItem, ButtonItem, EmptyItem } from 'devextreme-react/form'
import { SelectBox, List, Sortable, Button } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import ListSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import SelectBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxNoValueSimpleItem'
import { defScrollBoardField, defScrollBoardNoneField } from 'src/default/field/scrollBoard.js'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import CPUtils from 'src/functions/CommonPageUtils'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

const displayType = () => [
  { value: 'single', text: dqTranslateMsg('SidePanel_' + 'Single') },
  { value: 'all', text: dqTranslateMsg('SidePanel_' + 'All') },
]

export default function SettingsToolTipPT() {
  const dispatch = useDispatch()
  const pageKey = 'positionalTolerancesChart'

  const pageState = useSelector((state) => state.common.pages[pageKey])
  const configState = useSelector((state) => state.common.pages.configuration)

  const onCheckBoxLimitEnableValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`tooltip-pt-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'ToolTip') + '(PT)'}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: 'X-' + dqTranslateMsg('SidePanel_' + 'SpecificationLimit'),
        labelLocation: 'right',
        name: 'xAxisPTToolTipUseSpecLimits',
        value: pageState.page.xAxisPTToolTipUseSpecLimits,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitEnableValueChanged(e, 'xAxisPTToolTipUseSpecLimits'),
      })}
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: 'Y-' + dqTranslateMsg('SidePanel_' + 'SpecificationLimit'),
        labelLocation: 'right',
        name: 'yAxisPTToolTipUseSpecLimits',
        value: pageState.page.yAxisPTToolTipUseSpecLimits,
        disabled: false,
        onValueChanged: (e) => onCheckBoxLimitEnableValueChanged(e, 'yAxisPTToolTipUseSpecLimits'),
      })}
    </GroupItem>
  )
}
