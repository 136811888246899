export default function TextTitle({
  text = '',
  width = '40%',
  fontSize = '12px',
  textAlign = 'center',
  childrenWidth = '60%',
  children = null,
}) {
  return (
    <div className="dx-field">
      <div
        className="dx-field-label"
        style={{ width: width, fontSize: fontSize, textAlign: textAlign }}
      >
        {text}
      </div>
      {children ? (
        <div className="dx-field-value" style={{ width: childrenWidth }}>
          {children}
        </div>
      ) : null}
    </div>
  )
}
