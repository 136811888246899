class DTOAdminGroup {
    constructor({
        company = null,
        plant = null,
        group = null,
        group_admin_config_1 = null,
        group_admin_config_2 = null,
        group_admin_config_3 = null,
        group_admin_config_4 = null,
        group_admin_config_5 = null,
        group_admin_config_6 = null,
        group_admin_config_7 = null,
        group_admin_config_8 = null,
        group_admin_config_9 = null,
        group_admin_config_10 = null,
        group_admin_config_11 = null,
        group_admin_config_12 = null,
        group_admin_config_13 = null,
        group_admin_config_14 = null,
        group_admin_config_15 = null,
        group_admin_config_16 = null,
        group_admin_config_17 = null,
        group_admin_config_18 = null,
        group_admin_config_19 = null,
        group_admin_config_20 = null,
    } = {}) {
        Object.assign(this, {
            company,
            plant,
            group,
            group_admin_config_1,
            group_admin_config_2,
            group_admin_config_3,
            group_admin_config_4,
            group_admin_config_5,
            group_admin_config_6,
            group_admin_config_7,
            group_admin_config_8,
            group_admin_config_9,
            group_admin_config_10,
            group_admin_config_11,
            group_admin_config_12,
            group_admin_config_13,
            group_admin_config_14,
            group_admin_config_15,
            group_admin_config_16,
            group_admin_config_17,
            group_admin_config_18,
            group_admin_config_19,
            group_admin_config_20,
        })
    }
}

export default DTOAdminGroup
