import * as vsldcImpl from './ValueSummaryListDeviationChartOptionImpl'

export const getDeviationChartOption = (
  lslPrm,
  uslPrm,
  nominalPrm,
  valuePrm
) => {
  let lsl = lslPrm
  let usl = uslPrm
  const value = valuePrm
  const nominal = nominalPrm

  let gap = 0
  if (lsl === null) {
    gap = Math.abs(usl - nominal)
  } else if (usl === null) {
    gap = Math.abs(nominal - lsl)
  } else {
    gap = Math.abs(usl - nominal)
  }

  // const yAxisTop = (usl === null ? lsl + 2 * gap : usl) + gap * 0.1
  // const yAxisBottom = (lsl === null ? usl - 2 * gap : lsl) - gap * 0.1
  const xAxisTop = Math.max(usl === null ? lsl + 2 * gap : usl, nominal)
  const xAxisBottom = Math.min(lsl === null ? usl - 2 * gap : lsl, nominal)
  const yAxisTop = usl === null ? lsl + 2 * gap : usl
  const yAxisBottom = lsl === null ? usl - 2 * gap : lsl

  return {
    grid: {
      top: '0%',
      bottom: '0%',
      left: '1%',
      right: '1%',
    },
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    xAxis: {
      show: false,
      type: 'value',
      position: 'bottom',
      min: xAxisBottom,
      max: xAxisTop,
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
      },
    },
    yAxis: {
      type: 'value',
      min: 0, //yAxisBottom,
      max: 10, //yAxisTop,
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      data: ['ten'],
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
      },
    },

    series: [
      {
        name: 'Cost',
        type: 'bar',
        stack: 'Total',
        label: {
          show: true,
          position: 'center',
          formatter: '{b}',
        },
        markLine: {
          symbol: 'none',
          animation: false,
          precision: 10,
          emphasis: {
            disabled: true,
          },
          data: [
            ...(lsl !== null
              ? [
                  {
                    xAxis: lsl,
                    lineStyle: {
                      width: 2,
                      type: 'solid',
                      color: 'red',
                    },
                    label: { fontFamily: 'Arial' },
                  },
                ]
              : []),
            ...(usl !== null
              ? [
                  {
                    xAxis: usl,
                    lineStyle: {
                      width: 2,
                      type: 'solid',
                      color: 'red',
                    },
                    label: { fontFamily: 'Arial' },
                  },
                ]
              : []),
            {
              xAxis: nominal,
              lineStyle: {
                width: 1,
                type: 'solid',
                color: 'gray',
              },
              label: { fontFamily: 'Arial' },
            },
          ],
        },
        markArea: {
          label: { show: false, position: 'center' },
          data: [
            [
              { name: value, xAxis: nominal, yAxis: 10 - 1 }, //(usl - lsl) / 4 } 은 1/4크기만큼안씀
              {
                xAxis: value,
                yAxis: 1,
                itemStyle: {
                  color: vsldcImpl.valueRectColor(lsl, usl, value),
                  opacity: '1',
                },
              },
            ],
          ],
        },
      },
    ],
  }
}
