import { ScrollView } from 'devextreme-react'
import { Fragment } from 'react'
import SideNavigationMenuPreview from 'src/components/side-navigation-menu/SideNavigationMenuPreview'

const PushSettingsRibbonPreview = ({ title, dataLoaded, userRole, savedItems, entireItems, ribbon }) => {
    return (
        <Fragment>
            <div style={{ width: '100%', height: '4%', fontSize: '20px' }}>
                {/* {dqTranslateMsg('Page_Configuration_' + 'PartField')} */}
                {title}
            </div>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    paddingRight: '5px',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        padding: '5px',
                        borderRadius: '10px',
                        backgroundColor: 'rgba(40,40,40,1)',
                    }}
                >
                    <ScrollView useNative={false}>
                        <SideNavigationMenuPreview
                            dataLoaded={dataLoaded}
                            targetUserRole={userRole}
                            ribbon={savedItems}
                        ></SideNavigationMenuPreview>
                    </ScrollView>
                </div>
            </div>
        </Fragment>
    )
}

export default PushSettingsRibbonPreview
