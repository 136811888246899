import { backendPath } from './defines/DBInfo'
// import userDBField from './defines/UserDBField'

const path = backendPath

// ** USER APIS
export const authLogin = async (id, password) => {
  const requestBody = {
    userId: id,
    password: password,
  }

  try {
    const response = await fetch(path + 'data/authLogin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}
