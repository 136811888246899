import * as probImpl from './ProbabilityPlotChartOptionImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'

export const makeChartOption = (pageKey, pageSet, configSettings, char, values) => {
  const lsl = PCUtils.getLsl(char)
  const usl = PCUtils.getUsl(char)
  const nominal = PCUtils.getNominal(char)
  const gapUslLsl = PCUtils.getUslLslGap(usl, lsl)
  const warnOffset = PCUtils.getWarningOffset(configSettings.commonSettings.warningLimitPercentage)
  const lwl = PCUtils.getLwl(lsl, gapUslLsl, warnOffset)
  const uwl = PCUtils.getUwl(usl, gapUslLsl, warnOffset)
  const v0001s = values.map((item) => {
    return item.v0001
  })
  const { sum, length } = PCUtils.getSum(v0001s)
  const average = PCUtils.getAverage(sum, length)
  const stdDev = PCUtils.getStandardDeviation(v0001s, average)

  const ucl = PCUtils.getUcl(average, stdDev)
  const lcl = PCUtils.getLcl(average, stdDev)

  const titleText = `${char.c2002 ?? 'empty'}P:${char.part_id ?? '?'} / C:${char.char_id ?? '?'}`
  const subTitleText = ''

  const mode = probImpl.calculateMode(values)
  const minValue = probImpl.calculateMinValue(values)
  const maxValue = probImpl.calculateMaxValue(values)

  const min = Math.min(minValue, lsl, lcl)
  const max = Math.max(maxValue, usl, ucl)
  const xAxisLeft = min - (max - min) * 0.1
  const xAxisRight = max + (max - min) * 0.1

  // chart setting reorganization
  const settings = {
    ...pageSet,
    ...configSettings,
    titleText,
    subTitleText,
    lsl,
    usl,
    lwl,
    uwl,
    lcl,
    ucl,
    nominal,
    // gap,
    // warnOffset,
    xAxisLeft,
    xAxisRight,
  }

  return {
    title: {
      text:
        pageKey !== 'reportPrint'
          ? {
              text: settings.titleText,
            }
          : null,
      subtext: settings.subTitleText,
      left: 'center',
      itemGap: 2,
      padding: [0, 0, 0, 0],
      textStyle: {
        fontFamily: 'Arial',
        fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 5,
      },
      subtextStyle: {
        fontFamily: 'Arial',
        fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
      },
    },
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    grid: {
      top: '15%',
      bottom: '5%',
      left: '1%',
      right: '5%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'value',
        // min: settings.xAxisLeft,
        // max: settings.xAxisRight,
        boundaryGap: false,
        nameTextStyle: {
          fontFamily: 'Arial',
        },
        axisLabel: {
          show: true,
          fontFamily: 'Arial',
          fontSize: pageKey !== 'reportPrint' ? ((settings.page.fontSize - 2) / (settings.page.layout.row + settings.page.layout.column)) * 4 : 10,
          rotate: 90,
          formatter: function (value) {
            // return DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(value, decimalPlaces), isExponential)
            return DNUtils.calcValueDisplay(value, configSettings)
          },
        },
        axisLine: {
          onZero: false,
        },
      },
    ],
    yAxis: {
      type: 'value',
      ...(settings.page.yAxisType === 'Theoretical-Value' ? { scale: false } : { min: 0, max: 100 }),
      nameTextStyle: {
        fontFamily: 'Arial',
      },
      axisLabel: {
        fontFamily: 'Arial',
        fontSize: pageKey !== 'reportPrint' ? ((settings.page.fontSize - 2) / (settings.page.layout.row + settings.page.layout.column)) * 4 : 10,
      },
      axisLine: {
        onZero: false,
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
      itemSize: (15 / (settings.page.layout.row + settings.page.layout.column)) * 4,
    },
    tooltip: {
      trigger: 'item',
      appendToBody: true,
      textStyle: {
        fontFamily: 'Arial',
      },
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      formatter: function (arg) {
        let displayStr = ''
        if (arg.componentType === 'series') {
          displayStr += arg.seriesName + '<br>'
          displayStr += `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${arg.color}; border-radius: 50%;"></span> `
          displayStr += arg.name + '&emsp;'
          // displayStr += `<b>${DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(, decimalPlaces), isExponential)}</b>`
          displayStr += `<b>${DNUtils.calcValueDisplay(arg.value[1], configSettings)}</b>`
        } else {
          displayStr += arg.name + '<br>'
          displayStr += `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${arg.borderColor}; border-radius: 50%;"></span> `
          displayStr += '&emsp;'
          // displayStr += `<b>${DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(arg.value, decimalPlaces), isExponential)}</b>`
          displayStr += `<b>${DNUtils.calcValueDisplay(arg.value, configSettings)}</b>`
        }
        return displayStr
      },
    },
    dataZoom: [
      {
        show: true,
        type: 'slider',
        orient: 'horizontal',
        xAxisIndex: 0,
        height: `3%`,
        bottom: '3%',
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'slider',
        orient: 'vertical',
        width: `3%`,
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'none',
        zoomLock: false, // Allow zooming
        zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
      },
      {
        show: true,
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none',
      },
    ],
    series: [
      {
        type: 'line',
        name: 'Limits',
        markLine: {
          animation: false,
          symbol: 'none',
          precision: 10,
          emphasis: {
            disabled: true,
          },
        },
      },
    ],
  }
}
export function getProbabilityPlotChartOption(
  // settings,
  // configSettings,
  // targetChar,
  // valueArray
  pageKey,
  chars,
  values,
  pageSet,
  configSettings
) {
  try {
    return values.map((value, index) => {
      const char = chars.find((c) => c.part_id === value.part_id && c.char_id === value.char_id)
      const info = {
        part_id: char.part_id,
        char_id: char.char_id,
      }

      const option = makeChartOption(pageKey, pageSet, configSettings, char, value)

      return { option, info }
    })
    // })
  } catch {}
}
