// import { backendPath } from './defines/DBInfo'
import { makeRequestParameter } from 'src/pages/chart-histogram/components/main-view/HistogramChartFormImpl'
import JwtUtils from 'src/functions/JwtUtils'
import { backendPath } from './defines/DBInfo'
import ApiStatus from './defines/ApiStatus'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
const path = backendPath

export const getDatabaseInfo = async () => {
    const requestBody = {
        db_info_key: 'default',
    }

    try {
        const accessToken = JwtUtils.loadToken()
        const response = await fetch(path + 'data/getDatabaseInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: JSON.stringify(requestBody),
        })

        const result = await response.json()
        return result
    } catch (error) {
        throw error
    }
}

export const setDatabaseInfo = async (dbConfig) => {
    try {
        const requestBody = dbConfig
        const accessToken = JwtUtils.loadToken()
        const response = await fetch(path + 'data/setDatabaseInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: JSON.stringify(requestBody),
        })

        const result = await response.json()
        return result
    } catch (error) {
        throw error
    }
}

export const getDatabaseList = async (dbConfig) => {
    try {
        const requestBody = dbConfig
        const accessToken = JwtUtils.loadToken()
        const response = await fetch(path + 'data/getDatabaseList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: accessToken,
            },
            body: JSON.stringify(requestBody),
        })

        const result = await response.json()
        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        return result.data
    } catch (error) {
        console.error(error)
        return []
    }
}
